import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import ColorPicker from '../misc/ColorPicker';
import Store from '../../stores/Store';

interface ProfileColorsProps {
  store?: Store;
}

interface ProfileColorsState {
  colorsEditMode: boolean;
}


@inject('store') @observer
export default class ProfileColors extends React.Component<ProfileColorsProps, ProfileColorsState> {
  constructor(props: ProfileColorsProps) {
    super(props);
    this.startColorsEdit = this.startColorsEdit.bind(this);
    this.cancelColorsEdit = this.cancelColorsEdit.bind(this);
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.handleTextColorChange = this.handleTextColorChange.bind(this);
    this.handleLinkColorChange = this.handleLinkColorChange.bind(this);
    this.saveColors = this.saveColors.bind(this);
    this.state = {
      colorsEditMode: false
    };
  }
  startColorsEdit() {
    this.setState({
      colorsEditMode: true
    });
  }
  @action
  cancelColorsEdit() {
    this.props.store.profile.user.backgroundColor = this.props.store.profile.user.oldBackgroundColor;
    this.props.store.profile.user.textColor = this.props.store.profile.user.oldTextColor;
    this.props.store.profile.user.linkColor = this.props.store.profile.user.oldLinkColor;
    this.setState({
      colorsEditMode: false
    });
  }
  @action
  handleBackgroundColorChange(newVal: string) {
    this.props.store.profile.user.backgroundColor = newVal;
  }
  @action
  handleTextColorChange(newVal: string) {
    this.props.store.profile.user.textColor = newVal;
  }
  @action
  handleLinkColorChange(newVal: string) {
    this.props.store.profile.user.linkColor = newVal;
  }
  saveColors(e: any) {
    e.preventDefault();
    const profile = this.props.store.profile;
    this.props.store.updateProfileColors(profile.id, profile.user.backgroundColor, profile.user.textColor,
      profile.user.linkColor).then((d: any) => {
        this.setState({
          colorsEditMode: false
        });
      });
  }
  render() {
    const profile = this.props.store.profile.user;
    return (
      <div style={{ marginTop: '15px' }}>
        {this.state.colorsEditMode ?
          <div className="ui segment" style={{ paddingBottom: '40px', marginBottom: '20px' }}>
            <form
              className="ui form"
              onSubmit={this.saveColors}
              >
              <div className="ui message warning">
                Enjolivez votre page de profil avec une bannière personnalisée.
              </div>
              <ColorPicker
                color={profile.backgroundColor}
                handleColorPickerChange={this.handleBackgroundColorChange}
                defaultColor="#42586E"
                colorPickerLabel="Couleur du fond"
                />
              <ColorPicker
                color={profile.textColor}
                handleColorPickerChange={this.handleTextColorChange}
                defaultColor="#FAFAFA"
                colorPickerLabel="Couleur du texte"
                />
              <ColorPicker
                color={profile.linkColor}
                handleColorPickerChange={this.handleLinkColorChange}
                defaultColor="#F0AD4E"
                colorPickerLabel="Couleur des liens"
                />
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelColorsEdit}
                  >
                  Annuler
              </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveColors}
                  type="submit"
                  >
                  Enregistrer
            </button>
              </div>
            </form>
          </div>
          :
          <button className="ui orange label" onClick={this.startColorsEdit}>
            Modifier les couleurs de la page
          </button>
        }
      </div>
    );
  }
}
