import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
const Accordion = require('semantic-ui-react/dist/commonjs/modules/Accordion').default;
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

declare var Notification: any;
declare const __CLIENT__: any;

interface SettingsPageNotificationsProps {
  store: Store;
}

interface SettingsPageNotificationsState {
  enableComponents: boolean;
}

@inject('store') @observer
export default class SettingsPageNotifications extends React.Component<SettingsPageNotificationsProps, SettingsPageNotificationsState> {
  static fetchData(store: Store) {
    return store.loadSettingsNotifications();
  }
  constructor(props: SettingsPageNotificationsProps) {
    super(props);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeDesktop = this.handleChangeDesktop.bind(this);
    this.postForm = this.postForm.bind(this);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  @action handleChangeEmail() {
    this.props.store.mainSettings.notifications.email =
      !this.props.store.mainSettings.notifications.email;
  }
  @action handleChangeDesktop() {
    this.props.store.mainSettings.notifications.desktop =
      !this.props.store.mainSettings.notifications.desktop;
  }
  postForm(event: any) {
    event.preventDefault();
    this.props.store.saveSettingsNotifications().then((r: any) => {
      this.props.store.connected.desktopNotifications = this.props.store.mainSettings.notifications.desktop;
    });
  }
  render() {
    const form = this.props.store.mainSettings.notifications;
    return (
      <div>
        {this.props.store.mainSettings.loading ?
          <Loader message="Chargement..." />
          :
          <form onSubmit={this.postForm} className="ui equal width form">
            <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.email}
                onClick={this.handleChangeEmail}
                label="Emails"
              />
            </div>
            {/* <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.desktop}
                onClick={this.handleChangeDesktop}
                label="Bureau - Navigateur"
              />
              <div className="ui small message">
                Cochez cette case pour que votre navigateur Internet vous envoie des notifications même lorsque vous n'avez pas d'onglet Atelier des Auteurs ouvert.
              </div>
              {this.state.enableComponents ?
                <div>
                  {'Notification' in window && Notification.permission === 'denied' && form.desktop ?
                    <div className="ui small warning message">
                      <div className="header">Votre navigateur bloque les notifications provenant de l'Atelier des Auteurs.</div>
                      <div>
                        Comment les activer ?
                    <Accordion>
                          <Accordion.Title>
                            Google Chrome
                      </Accordion.Title>
                          <Accordion.Content>
                            <ul>
                              <li>Cliquez sur le petit cadenas situé à gauche de l'adresse de l'Atelier des Auteurs, en haut</li>
                              <li>Dans "Notifications", sélectionnez "Toujours autoriser" sur ce site</li>
                            </ul>
                          </Accordion.Content>
                          <Accordion.Title>
                            Mozilla Firefox
                      </Accordion.Title>
                          <Accordion.Content>
                            <li>Dans le menu du navigateur, cliquez sur "Paramètres".</li>
                            <li>Cliquez sur "Paramètres du contenu".</li>
                            <li>Descendez jusqu'au sous-menu "Notifications". Cliquez sur "Gérer les exceptions"</li>
                            <li>Recherchez la ligne correspondant à l'Atelier des Auteurs. Dans la colonne de droite, sélectionnez "Autoriser"</li>
                          </Accordion.Content>
                        </Accordion>
                      </div>
                    </div>
                    : undefined}
                </div>
                : undefined}
            </div> */}
            <button className="ui submit primary right floated button"
              type="submit" style={{ marginTop: '25px' }}>
              Sauvegarder les modifications
            </button>
          </form>
        }
      </div>
    );
  }
}
