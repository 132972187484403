import * as React from 'react';
const CompactPicker = require('react-color/lib/components/compact/Compact').default;

interface ColorPickerProps {
  color: string;
  defaultColor: string;
  colorPickerLabel: string;
  handleColorPickerChange: Function;
}

interface ColorPickerState {
  displayColorPicker: boolean;
}

export default class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  constructor(props: ColorPickerProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResetColorPicker = this.handleResetColorPicker.bind(this);
    this.state = {
      displayColorPicker: false,
    };
  }
  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }
  handleClose() {
    this.setState({ displayColorPicker: false });
  }
  handleChange(color: any) {
    this.props.handleColorPickerChange(color.hex);
  }
  handleResetColorPicker() {
    this.props.handleColorPickerChange(this.props.defaultColor);
  }
  render() {
    return (
      <div className="inline field">
        <label>
          {this.props.colorPickerLabel}
        </label>
        <div
          className="color-swatch"
          style={{ marginBottom: '-7px' }}
          onClick={this.handleClick}
          >
          <div
            className="color-main"
            style={{ background: `${this.props.color}` }}
            />
        </div>
        {this.state.displayColorPicker ?
          <div className="color-popover">
            <div
              className="color-cover"
              onClick={this.handleClose}
              />
            <CompactPicker
              color={this.props.color}
              onChange={this.handleChange}
              />
          </div>
          : null}
        <button
          className="ui mini icon button"
          title="Réinitialiser"
          style={{ marginBottom: '15px' }}
          onClick={this.handleResetColorPicker}
          type="button"
          >
          <i className="fa fa-undo" />
        </button>
      </div>
    );
  }
}
