import * as React from 'react';
import Title from './misc/Title';
import { inject, observer } from 'mobx-react';
const withCookies = require('react-cookie').withCookies;
const ReactGA = require('react-ga');
import Footer from './footer/Footer';
import Store from '../stores/Store';
import AlertsSystem from './misc/AlertsSystem';
import ChatPopups from './chat/ChatPopups';
import SearchBox from './misc/SearchBox';
import AlertAltruiste from './misc/AlertAltruiste';
import NewBadges from './misc/NewBadges';
import CookieBanner from './misc/CookieBanner';
const cookie = new (require('react-cookie')).Cookies();

interface AppProps {
  store?: Store;
  topbar: any;
  main: any;
  footer?: any;
  location: { pathname: string };
}
interface AppState {
  showSidebar: boolean;
}

declare const __CLIENT__: any;

require('font-awesome/css/font-awesome.min.css');

@inject('store') @observer
class App extends React.Component<AppProps, AppState> {

  refs: {
    [string: string]: any;
    sidebar: any;
  };

  constructor(props: AppProps, context: any) {
    super(props, context);
    this.state = { showSidebar: false };
    this.bindBeforeInstallPrompt = this.bindBeforeInstallPrompt.bind(this);
  }
  // componentWillMount() {
  //   if (__CLIENT__ && !this.props.store.socket && this.props.store.connected && this.props.store.connected.user
  //     && this.props.store.connected.user.id) {
  //     this.props.store.initSocketConnection();
  //   }
  // }
  bindBeforeInstallPrompt(e: any) {
    const cookieAudience = cookie && cookie.get && cookie.get('user-has-accepted-cookies-audience');
    if (cookieAudience) {
      e.userChoice.then((choiceResult: any) => {
        ReactGA.event({
          category: 'A2H',
          action: choiceResult.outcome
        });
      });
    }
  }
  componentDidMount() {
    if (__CLIENT__) {
      window.addEventListener('beforeinstallprompt', this.bindBeforeInstallPrompt);
    }
  }
  componentWillUnmount() {
    if (__CLIENT__) {
      window.removeEventListener('beforeinstallprompt', this.bindBeforeInstallPrompt);
    }
  }
  notLandingPage() {
    return this.props.location.pathname !== '/'
      || this.props.store.isAuthenticated();
  }
  editorPage() {
    const reg1 = /(^\/text.*\/edit$)/g;
    const reg2 = /(^\/write\/training$)/g;
    const reg3 = /(^\/text.*\/statistics)/g;
    return reg1.test(this.props.location.pathname)
      || reg2.test(this.props.location.pathname)
      || reg3.test(this.props.location.pathname);
  }
  chapterPage() {
    const reg = /(^\/text.*\/chapter\/)/g;
    return reg.test(this.props.location.pathname);
  }
  render() {
    const connected = this.props.store.connected;
    const premium = connected ? connected.premium : undefined;
    const premiumUi = premium ? premium.premiumUI : undefined;
    return (
      <div id="outer-container">
        <Title />
        {this.props.topbar}
        <div>
          <div id="page-wrap" className="page-wrap" style={{ backgroundColor: '#f8f8f8'}}>
            {!this.editorPage() && !this.chapterPage() && !premiumUi ?
              <div className="hide-tablet-large pusher-menu-mobile">
                {this.props.store.isAuthenticated() ?
                  <SearchBox mobile={true} />
                  : undefined}
              </div>
              : <div className="fill-mobile" />}
            <div className={this.notLandingPage() ? 'pusher' : undefined}>
              {this.props.main}
            </div>
          </div>
        </div>
        <AlertsSystem />
        {this.props.store.isAuthenticated() ?
          <ChatPopups pathname={this.props.location.pathname} />
          : undefined}
        {this.props.store.isAuthenticated() && !this.editorPage() && !this.chapterPage() ?
          <AlertAltruiste />
          : undefined
        }
        {this.props.store.isAuthenticated() ?
          <NewBadges />
          : undefined
        }
        {this.props.footer ? this.props.footer : premiumUi ? <Footer premiumUi={true} /> : <Footer  premiumUi={false} />}
{/*         <CookieBanner />
 */}      </div>
    );
  }
}

export default withCookies(App);

