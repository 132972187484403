import { observable } from 'mobx';

export default class CommentModel {
  id: number;
  userId: number;
  userIdentity: string;
  userUrl: string;
  userAvatar: string;
  userAdmin: boolean;
  @observable title: string;
  @observable text: string;
  chapterName: string;
  chapterTitle: string;
  chapterUrl: string;
  @observable ago: string;
  @observable childs: CommentModel[];
  @observable score: number;
  @observable voted: boolean;
  @observable isNew: boolean;

  // temp values
  @observable answerMode: boolean;
  @observable editMode: boolean;
  @observable shownAnswers: boolean;
  @observable textEdit: string;

  constructor(id: number, userId: number, userIdentity: string, userUrl: string,
    userAvatar: string, userAdmin: boolean, title: string, text: string,
    chapterName: string, chapterTitle: string, chapterUrl: string, ago: string,
    childs: CommentModel[], score: number, voted: boolean, answerMode: boolean,
    editMode: boolean, shownAnswers: boolean, textEdit: string, isNew: boolean) {
    this.id = id;
    this.userId = userId;
    this.userIdentity = userIdentity;
    this.userUrl = userUrl;
    this.userAvatar = userAvatar;
    this.userAdmin = userAdmin;
    this.title = title;
    this.text = text;
    this.chapterName = chapterName;
    this.chapterTitle = chapterTitle;
    this.chapterUrl = chapterUrl;
    this.ago = ago;
    this.childs = childs.map(c => new CommentModel(c.id, c.userId, c.userIdentity,
      c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
      c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode, c.shownAnswers,
      c.textEdit, c.isNew));
    this.score = score;
    this.voted = voted;
    this.isNew = isNew;
    this.answerMode = answerMode;
    this.editMode = editMode;
    this.shownAnswers = shownAnswers;
    this.textEdit = textEdit;
  }

  setAnswerMode(answerMode: boolean) {
    this.answerMode = answerMode;
  }

  enableEdit(textEdit: string) {
    this.textEdit = textEdit;
    this.editMode = true;
  }

  disableEdit() {
    this.textEdit = '';
    this.editMode = false;
  }

  validEdit(newText: string) {
    this.text = newText;
    this.textEdit = '';
    this.editMode = false;
  }

  upVote() {
    if (!this.voted) {
      this.score = this.score + 1;
      this.voted = true;
    }
  }

  unUpVote() {
    if (this.voted) {
      this.score = this.score - 1;
      this.voted = false;
    }
  }

  showAnswers() {
    this.shownAnswers = true;
  }

  hideAnswers() {
    this.shownAnswers = false;
  }

  toJS(): any {
    return {
      id: this.id,
      userId: this.userId,
      userIdentity: this.userIdentity,
      userUrl: this.userUrl,
      userAvatar: this.userAvatar,
      userAdmin: this.userAdmin,
      title: this.title,
      text: this.text,
      chapterName: this.chapterName,
      chapterTitle: this.chapterTitle,
      chapterUrl: this.chapterUrl,
      ago: this.ago,
      childs: this.childs.map(c2 => c2.toJS()),
      score: this.score,
      voted: this.voted,
      isNew: this.isNew,
      answerMode: this.answerMode,
      editMode: this.editMode,
      shownAnswers: this.shownAnswers,
      textEdit: this.textEdit,
    };
  }

  static fromJS(object: any): CommentModel {
    const childs: CommentModel[] = object.childs.map((child: any) => CommentModel.fromJS(child));
    return new CommentModel(object.id, object.userId, object.userIdentity, object.userUrl,
      object.userAvatar, object.userAdmin, object.title, object.text, object.chapterName,
      object.chapterTitle, object.chapterUrl, object.ago, childs, object.score,
      object.voted, object.answerMode, object.editMode, object.shownAnswers, object.textEdit, object.isNew);
  }
}
