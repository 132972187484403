import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { Link, browserHistory } from 'react-router';
import FullTextModel from '../../models/FullTextModel';
import ReadingListForm from './ReadingListForm';
import ReadingListRemoveText from './ReadingListRemoveText';
import ReadingListRemove from './ReadingListRemove';
import ReadingListList from './ReadingListList';
import Loader from '../misc/Loader';
import Store from '../../stores/Store';
import ListParams from './ListParams';

interface ListPageProps {
  store?: Store;
  params: ListParams;
}

interface ListPageState {
  activeEdit: boolean;
  activeRemoveText: boolean;
  activeRemoveList: boolean;
  selectedTextToRemove: number;
}

@inject('store') @observer
export default class ListPage extends React.Component<ListPageProps, ListPageState> {
  static fetchData(store: Store, params: ListParams) {
    const p: any[] = [];
    p.push(store.getReadingList(params.listId));
    p.push(store.loadReadingListTexts(params.listId));
    p.push(store.loadReadingListSimilar(params.listId));
    p.push(store.loadReadingListSameUser(params.listId));
    return Promise.all(p);
  }
  constructor(props: ListPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.editList = this.editList.bind(this);
    this.cancelEditList = this.cancelEditList.bind(this);
    this.removeText = this.removeText.bind(this);
    this.confirmRemoveText = this.confirmRemoveText.bind(this);
    this.cancelRemoveText = this.cancelRemoveText.bind(this);
    this.removeList = this.removeList.bind(this);
    this.confirmRemoveList = this.confirmRemoveList.bind(this);
    this.cancelRemoveList = this.cancelRemoveList.bind(this);
    this.upText = this.upText.bind(this);
    this.downText = this.downText.bind(this);

    this.state = {
      activeEdit: false,
      activeRemoveText: false,
      activeRemoveList: false,
      selectedTextToRemove: undefined
    };
  }
  loadMore() {
    this.props.store.loadReadingListTexts(this.props.params.listId);
  }
  editList() {
    this.setState({
      activeEdit: true,
      activeRemoveText: false,
      activeRemoveList: false,
      selectedTextToRemove: undefined
    });
  }
  cancelEditList() {
    this.props.store.getReadingList(this.props.params.listId).then((l: any) => {
      this.props.store.loadReadingListTexts(this.props.params.listId).then((d: any) => {
        this.setState({
          activeEdit: false,
          activeRemoveText: false,
          activeRemoveList: false,
          selectedTextToRemove: undefined
        });
      });
    });
  }
  removeText(textId: number) {
    this.setState({
      activeEdit: false,
      activeRemoveText: true,
      activeRemoveList: false,
      selectedTextToRemove: textId
    });
  }
  cancelRemoveText() {
    this.setState({
      activeEdit: false,
      activeRemoveText: false,
      activeRemoveList: false,
      selectedTextToRemove: undefined
    });
  }
  @action
  confirmRemoveText() {
    this.props.store.removeTextFromReadingList(
      this.props.params.listId, this.state.selectedTextToRemove
    ).then((d: any) => {
      this.props.store.mainReadingList.texts =
        this.props.store.mainReadingList.texts.filter(t => t.id !== this.state.selectedTextToRemove);
      this.setState({
        activeEdit: false,
        activeRemoveText: false,
        activeRemoveList: false,
        selectedTextToRemove: undefined
      });
    });
  }
  removeList() {
    this.setState({
      activeEdit: false,
      activeRemoveText: false,
      activeRemoveList: true,
      selectedTextToRemove: undefined
    });
  }
  cancelRemoveList() {
    this.setState({
      activeEdit: false,
      activeRemoveText: false,
      activeRemoveList: false,
      selectedTextToRemove: undefined
    });
  }
  confirmRemoveList() {
    this.props.store.removeReadingList(
      this.props.params.listId
    ).then((d: any) => {
      browserHistory.push({
        pathname: '/'
      });
    });
  }
  @action upText(textId: number) {
    this.props.store.upTextInReadingList(this.props.params.listId, textId).then((d: any) => {
      const texts = this.props.store.mainReadingList.texts;
      const text = texts.filter(t => t.id === textId)[0];
      const textIndex = texts.map(t => t.id).indexOf(textId);
      if (textIndex - 1 >= 0) {
        const textToSwap = texts[textIndex - 1];
        texts[textIndex] = textToSwap;
        texts[textIndex - 1] = text;
      }
    });
  }
  @action downText(textId: number) {
    this.props.store.downTextInReadingList(this.props.params.listId, textId).then((d: any) => {
      const texts = this.props.store.mainReadingList.texts;
      const text = texts.filter(t => t.id === textId)[0];
      const textIndex = texts.map(t => t.id).indexOf(textId);
      if (textIndex + 1 < texts.length) {
        const textToSwap = texts[textIndex + 1];
        texts[textIndex + 1] = text;
        texts[textIndex] = textToSwap;
      }
    });
  }
  render() {
    const mainReadingList = this.props.store.mainReadingList;
    const list = mainReadingList.currentList;
    const connected = this.props.store.connected;
    let isAuthor: boolean = false;
    if (connected && connected.user && connected.user.id
      && list && list.user && list.user.id
      && connected.user.id === list.user.id) {
      isAuthor = true;
    }
    const texts = mainReadingList.texts.map((text: FullTextModel, i: number) => {
      return (
        <div key={Math.random() * 1000} className="item">
          <div className="ui tiny image">
            {list.ordered ?
              <div className="ui ribbon label">
                {i + 1}
              </div>
              : undefined}
            <Link to={`/text/${text.id}/${text.urlText}`}>
              <img
                src={text.cover}
                alt={`Image de couverture de ${text.title}`}
                style={{ width: '100px' }}
              />
            </Link>
          </div>
          <div className="content">
            <div className="header">
              <Link to={`/text/${text.id}/${text.urlText}`} style={{ color: 'inherit' }}>
                {text.title}
              </Link>
            </div>
            <div className="meta">
              <Link to={`/author/${text.author.id}/${text.author.urlIdentity}`}>
                {text.author.identity}
              </Link>
            </div>
            {text.summary ?
              <div className="description">
                <Link
                  to={`/text/${text.id}/${text.urlText}`} style={{ color: 'inherit' }}
                  dangerouslySetInnerHTML={{ __html: '<div>' + text.summary.substring(0, Math.min(text.summary.length, 450)) + (text.summary.length > 450 ? '...' : '') + '</div>' }}
                />
              </div>
              : undefined}
            {isAuthor ?
              <div className="extra">
                <button
                  title="Supprimer"
                  className="ui right floated mini red icon button"
                  data-tooltip="Supprimer" data-inverted="" data-position="bottom center"
                  onClick={() => this.removeText(text.id)}
                >
                  <i className="fa fa-remove" />
                </button>
                {i > 0 ?
                  <button
                    title="Monter"
                    className="ui right floated mini blue icon button"
                    data-tooltip="Monter" data-inverted="" data-position="bottom center"
                    onClick={() => this.upText(text.id)}
                  >
                    <i className="fa fa-arrow-up" />
                  </button>
                  : undefined
                }
                {i < (mainReadingList.texts.length - 1) ?
                  <button
                    title="Descendre"
                    className="ui right floated mini blue icon button"
                    data-tooltip="Descendre" data-inverted="" data-position="bottom center"
                    onClick={() => this.downText(text.id)}
                  >
                    <i className="fa fa-arrow-down" />
                  </button>
                  : undefined
                }
              </div>
              : undefined
            }
          </div>
        </div>
      );
    });

    return (
      <div>
        {list && list.id ?
          <ReadingListForm
            list={list}
            active={this.state.activeEdit}
            hideModal={this.cancelEditList}
          />
          : undefined}
        {list && list.id ?
          <ReadingListRemoveText
            active={this.state.activeRemoveText}
            confirmRemove={this.confirmRemoveText}
            hideModal={this.cancelRemoveText}
          />
          : undefined}
        {list && list.id ?
          <ReadingListRemove
            active={this.state.activeRemoveList}
            confirmRemove={this.confirmRemoveList}
            hideModal={this.cancelRemoveList}
          />
          : undefined}
        <div className="ui two column grid stackable" style={{ marginTop: '-30px' }}>
          <div className="ten wide column" style={{ backgroundColor: '#fff', paddingTop: '50px', paddingBottom: '80px' }}>
            {list && list.id ?
              <div className="ui grid centered stackable">
                <div className="twelve wide column">
                  <Title title={`Liste ${list.name}, par ${list.user.identity}`} />
                  <div className="ui basic segment">
                    <div className="ui huge header">
                      {list.name}
                      <div className="sub header" style={{ marginTop: '10px' }}>
                        Une liste de <Link
                          to={`/author/${list.user.id}/${list.user.urlIdentity}`}>{list.user.identity}
                        </Link>
                      </div>
                    </div>
                    {isAuthor ?
                      <div>
                        <button
                          className="ui small orange button"
                          onClick={this.editList}
                        >
                          Modifier
                      </button>
                        <button
                          className="ui small red button"
                          onClick={this.removeList}
                        >
                          Supprimer
                      </button>
                      </div>
                      : undefined}
                  </div>
                  <div className="ui divider" />
                  <div className="ui basic segment">
                    <div className="ui divided items">
                      {texts}
                    </div>
                  </div>
                </div>
              </div>
              : <Loader message="Chargement..." />}
          </div>
          <div className="six wide right floated grey column" style={{ paddingTop: '50px', minHeight: '1000px' }}>
            <div className="ui grid centered stackable">
              <div className="twelve wide column">
                <Link to="/lists" className="ui fluid button">
                  Toutes mes listes
                </Link>
                {mainReadingList.similar.length > 0 ?
                  <div className="ui basic segment">
                    <h3 className="ui horizontal divider">Listes similaires</h3>
                    <ReadingListList lists={mainReadingList.similar} withAuthor />
                  </div>
                  : undefined}
                {mainReadingList.sameUser.length > 0 ?
                  <div className="ui basic segment">
                    <h3 className="ui horizontal divider">Du même auteur</h3>
                    <ReadingListList lists={mainReadingList.sameUser} />
                  </div>
                  : undefined}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
