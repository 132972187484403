import { observable } from 'mobx';
import EditorModel from './EditorModel';
import EditorFormModel from './EditorFormModel';

export default class MainEditorModel {

  @observable allEditors: EditorModel[];
  @observable form: EditorFormModel;

  constructor(allEditors: EditorModel[], form: EditorFormModel) {
    this.allEditors = allEditors || [];
    this.form = form || new EditorFormModel(undefined, undefined, false, undefined, undefined);
  }

  setAllEditors(allEditors: EditorModel[]) {
    this.allEditors = allEditors;
  }

  toJS() {
    return {
      allEditors: this.allEditors.map(p => p.toJS()),
      form: this.form.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const allEditors = object.allEditors.map((p: any) => EditorModel.fromJS(p));
      return new MainEditorModel(allEditors, EditorFormModel.fromJS(object.form));
    }
    return undefined;
  }
}
