import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
const ReactChatView = require('react-chatview');
import Store from '../../stores/Store';

interface ChatViewProps {
  store?: Store;
  popup?: boolean;
}

@inject('store') @observer
export default class ChatView extends React.Component<ChatViewProps, {}> {
  shouldScrollBottom: boolean;
  constructor(props: ChatViewProps) {
    super(props);
    this.shouldScrollBottom = false;
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
  }
  componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this);
    if (node) {
      this.shouldScrollBottom = node.scrollTop + node.clientHeight === node.scrollHeight;
    }
  }
  componentDidUpdate() {
    if (this.shouldScrollBottom) {
      const node = ReactDOM.findDOMNode(this);
      if (node) {
        node.scrollTop = node.scrollHeight;
      }
    }
  }
  loadMoreMessages() {
    if (this.props.store.chat.discussion) {
      const discussionId = this.props.store.chat.discussion.id;
      return this.props.store.loadDiscussion(discussionId, false);
    }
  }
  render() {
    const messages = this.props.store.chat.discussion.messages.map(message => {
        const key = Math.random() * 1000 + '-' + (this.props.popup ? 'popup' : 'panel');
        return (
          <div key={key} className="comment" style={{ padding: '10px' }}>
            <Link to={`/author/${message.user.id}/${message.user.urlIdentity}`}
              className="avatar">
              <img src={message.user.avatarUrl} alt={`Image de profil de ${message.user.identity}`} />
            </Link>
            <div className="content">
              <Link to={`/author/${message.user.id}/${message.user.urlIdentity}`} className="author">
                {message.user.identity}
              </Link>
              <div className="metadata">
                {message.user.admin
                  ? <span className="sp orange">(administrateur) </span>
                  : null}
                <span className="date">{message.date}</span>
              </div>
              <div className="text" dangerouslySetInnerHTML={{ __html: message.message }} />
            </div>
          </div>
        );
      });
    return (
      <ReactChatView
        ref="chat"
        flipped={true}
        className={'ui items comments chatview' + (this.props.popup ? ' chatview-small' : '')}
        scrollLoadTreshold={10}
        onInfiniteLoad={this.loadMoreMessages}
      >
        {messages}
      </ReactChatView>
    );
  }
}
