import * as React from 'react';

import { Link } from 'react-router';
import SimpleNotificationModel from '../../models/SimpleNotificationModel';
import NotificationModel from '../../models/notification/NotificationModel';

interface NotificationProps {
  userId: number;
  notif: SimpleNotificationModel | NotificationModel;
}

export default class Notification extends React.Component<NotificationProps, {}> {
  render() {
    const notif = this.props.notif;

    let targetUserLink: JSX.Element;
    if (notif.targetUser) {
      targetUserLink = (
        <Link
          className="user"
          to={`/author/${notif.targetUser.id}/${notif.targetUser.urlIdentity}`}
        >
          {notif.targetUser.identity}
        </Link>
      );
    }

    let textLink: JSX.Element;
    if (notif.text) {
      textLink = (
        <Link
          to={`/text/${notif.text.id}/${notif.text.urlText}`}
        >
          {notif.text.title}
        </Link>
      );
    }

    let chapterLinkWithTitle: JSX.Element;
    let chapterLinkWithoutTitle: JSX.Element;
    if (notif.text && notif.chapter) {
      chapterLinkWithoutTitle = (
        <Link
          to={`/text/${notif.text.id}/${notif.text.urlText}/chapter/${notif.chapter.id}` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
        >
          {notif.text.title}
        </Link>
      );
      chapterLinkWithTitle = (
        <Link
          to={`/text/${notif.text.id}/${notif.text.urlText}/chapter/${notif.chapter.id}`}
        >
          {notif.chapter.title}
        </Link>
      );
    }

    let defiLink: JSX.Element;
    if (notif.defi) {
      const url = `/defis/defi/${notif.defi.id}/${notif.defi.urlTitle}` + (notif.anchorId ? `#comment_${notif.anchorId}` : '');
      defiLink = (
        <Link to={url}>
          {notif.defi.title}
        </Link>
      );
    }

    let talkLink: JSX.Element;
    if (notif.talk) {
      if (notif.text) {
        const url = `/text/${notif.text.id}/${notif.text.urlText}/talks/${notif.talk.id}/${notif.talk.urlTitle}` + (notif.anchorId ? `#comment_${notif.anchorId}` : '');
        talkLink = (
          <Link to={url}>
            {notif.talk.title}
          </Link>
        );
      } else if (notif.parcours) {
        const url = `/write/parcours/${notif.parcours.id}/talks/${notif.talk.id}/${notif.talk.urlTitle}` + (notif.anchorId ? `#comment_${notif.anchorId}` : '');
        talkLink = (
          <Link to={url}>
            {notif.talk.title}
          </Link>
        );
      } else {
        const url = `/talks/${notif.talk.id}/${notif.talk.urlTitle}` + (notif.anchorId ? `#comment_${notif.anchorId}` : '');
        talkLink = (
          <Link to={url}>
            {notif.talk.title}
          </Link>
        );
      }
    }

    let parcoursLink: JSX.Element;
    if (notif.parcours) {
      parcoursLink = (
        <span>
          {notif.parcours.name}
        </span>
      );
    }

    let avatar: JSX.Element;
    if (notif.targetUser) {
      avatar = (<img
        className="ui mini rounded image"
        src={notif.targetUser.avatarUrl}
        alt={`Image de profil de ${notif.targetUser.identity}`}
      />);
    }

    let body: JSX.Element;
    let image: JSX.Element;
    switch (notif.notificationType) {
      case 'NewFollower':
        body = (
          <span>
            {targetUserLink} vous suit.
          </span>
        );
        image = avatar;
        break;
      case 'TextCommented':
        body = (
          <span>
            {targetUserLink} a commenté le chapitre {chapterLinkWithTitle} de votre œuvre <b>{notif.text.title}</b>.
          </span>
        );
        image = avatar;
        break;
      case 'DefiCommented':
        body = (
          <span>
            {targetUserLink} a commenté votre défi {defiLink}.
          </span>
        );
        image = avatar;
        break;
      case 'TextAnnoted':
        const annotations = notif.annotations.map(a => {
          return (
            <tr key={`ann_${a.id}`}>
              <td style={{ width: '150px' }}>{a.date}</td>
              <td><span dangerouslySetInnerHTML={{ __html: a.message }} /></td>
              <td style={{ width: '40px' }}>
                <Link
                  to={`/text/${notif.text.id}/${notif.text.urlText}/chapter/${notif.chapter.id}?version=${notif.chapterVersionId}#annId_${a.id}`}
                >
                  Voir
                </Link>
              </td>
            </tr>
          );
        });
        body = (
          <div>
            {targetUserLink} a annoté le chapitre <b>{notif.chapter.title}</b> de votre œuvre {textLink}.
              <table className="ui small celled table">
              <tbody>
                {annotations}
              </tbody>
            </table>
          </div>
        );
        image = avatar;
        break;
      case 'TextFavorite':
        body = notif.chapter ? (
          <span>
            {targetUserLink} aime le chapitre {chapterLinkWithTitle} de votre œuvre <b>{notif.text.title}</b>.
          </span>
        ) : (
            <span>
              {targetUserLink} aime votre œuvre {textLink}.
          </span>
          );
        image = avatar;
        break;
      case 'DefiRepondu':
        body = (
          <span>
            {targetUserLink} a répondu à votre défi {defiLink} avec l'œuvre {textLink}.
            Il n'attend plus que votre avis !
          </span>
        );
        image = avatar;
        break;
      case 'DefiOtherReponse':
        body = (
          <span>
            {targetUserLink} a répondu au défi {defiLink} (que vous avez vous aussi relevé !) avec l'œuvre {textLink}.
          </span>
        );
        image = avatar;
        break;
      case 'DeadlineExceeded':
        body = (
          <span>
            Le temps assigné pour le défi {defiLink} est écoulé.
          </span>
        );
        image = (
          <img
            className="ui mini rounded image"
            src="https://s3-eu-west-1.amazonaws.com/scribay/assets/deadline.png"
            alt="Temps écoulé"
          />
        );
        break;
      case 'DefiProposeAbandonne':
        body = (
          <span>
            Votre défi {defiLink} a été abandonné par {targetUserLink}.
          </span>
        );
        image = avatar;
        break;
      case 'DefiProposeAccepte':
        body = (
          <span>
            Votre défi {defiLink} a été accepté par {targetUserLink}.
          </span>
        );
        image = avatar;
        break;
      case 'DefiProposeDeadlineExceeded':
        body = (
          <span>
            {targetUserLink} n'a pas répondu à temps à votre défi {defiLink}.
          </span>
        );
        image = avatar;
        break;
      case 'CommentAnswered':
        body = (
          <span>
            {targetUserLink} a répondu à votre commentaire posté en réponse à l'œuvre {chapterLinkWithoutTitle}.
          </span>
        );
        image = avatar;
        break;
      case 'DefiCommentAnswered':
        body = (
          <span>
            {targetUserLink} a répondu à votre commentaire posté en réponse au défi {defiLink}.
          </span>
        );
        image = avatar;
        break;
      case 'CommentLiked':
        body = (
          <span>
            {targetUserLink} a aimé votre commentaire posté en réponse à l'œuvre {chapterLinkWithoutTitle}.
          </span>
        );
        image = avatar;
        break;
      case 'DefiCommentLiked':
        body = (
          <span>
            {targetUserLink} a aimé votre commentaire posté en réponse au défi {defiLink}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInComment':
        body = (
          <span>
            {targetUserLink} vous a mentionné dans son commentaire posté en réponse à l'œuvre {chapterLinkWithoutTitle}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInDefiComment':
        body = (
          <span>
            {targetUserLink} vous a mentionné dans son commentaire posté en réponse au défi {defiLink}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInDiary':
        if (notif.targetUser.id === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} vous a mentionné dans une&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                note de son journal
              </Link>.
            </span>
          );
        } else if (this.props.userId === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} vous a mentionné dans une&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                note de votre journal
              </Link>.
            </span>
          );
        } else {
          body = (
            <span>
              {targetUserLink} vous a mentionné dans une&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                note du journal de {notif.targetUser2.identity}
              </Link>.
            </span>
          );
        }
        image = avatar;
        break;
      case 'DiaryAnswered':
        if (notif.targetUser.id === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} a répondu à&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans son journal
              </Link>.
            </span>
          );
        } else if (this.props.userId === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} a répondu à&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans votre journal
              </Link>.
            </span>
          );
        } else {
          body = (
            <span>
              {targetUserLink} a répondu à&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans le journal de {notif.targetUser2.identity}
              </Link>.
            </span>
          );
        }
        image = avatar;
        break;
      case 'DiaryLiked':
        if (notif.targetUser.id === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} aime&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans son journal
              </Link>.
            </span>
          );
        } else if (this.props.userId === notif.targetUser2.id) {
          body = (
            <span>
              {targetUserLink} aime&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans votre journal
              </Link>.
            </span>
          );
        } else {
          body = (
            <span>
              {targetUserLink} aime&nbsp;
              <Link
                className="user"
                to={`/author/${notif.targetUser2.id}/${notif.targetUser2.urlIdentity}/diary` + (notif.anchorId ? `#comment_${notif.anchorId}` : '')}
              >
                votre commentaire dans le journal de {notif.targetUser2.identity}
              </Link>.
            </span>
          );
        }
        image = avatar;
        break;
      case 'Thank':
        body = (
          <span>
            {targetUserLink} vous remercie pour l'aide apportée sur son œuvre {textLink}.
            <div><i>{notif.message}</i></div>
          </span>
        );
        image = avatar;
        break;
      case 'NewChapter':
        body = (
          <span>
            {targetUserLink} a publié un <Link
              to={`/text/${notif.text.id}/${notif.text.urlText}/chapter/${notif.chapter.id}`}
            >
              nouveau chapitre
            </Link> à l'œuvre <b>{notif.text.title}</b>.
          </span>
        );
        image = avatar;
        break;
      case 'NewText':
        body = (
          <span>
            {targetUserLink} a publié une nouvelle œuvre : {textLink}.
          </span>
        );
        image = avatar;
        break;
      case 'TalkAnswered':
        body = (
          <span>
            {targetUserLink} vous a répondu dans la discussion {talkLink}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInTalk':
        body = (
          <span>
            {targetUserLink} vous a mentionné dans un message de la discussion {talkLink}.
          </span>
        );
        image = avatar;
        break;
      case 'TalkLiked':
        body = (
          <span>
            {targetUserLink} a aimé votre message dans la discussion {talkLink}.
          </span>
        );
        image = avatar;
        break;
      case 'TextTalkAnswered':
        body = (
          <span>
            {targetUserLink} vous a répondu dans la discussion {talkLink} au sein de l'œuvre {textLink}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInTextTalk':
        body = (
          <span>
            {targetUserLink} vous a mentionné dans un message de la discussion {talkLink} au sein de l'œuvre {textLink}.
          </span>
        );
        image = avatar;
        break;
      case 'TextTalkLiked':
        body = (
          <span>
            {targetUserLink} a aimé votre message dans la discussion {talkLink} au sein de l'œuvre {textLink}.
          </span>
        );
        image = avatar;
        break;
      case 'ParcoursTalkAnswered':
        body = (
          <span>
            {targetUserLink} vous a répondu dans la discussion {talkLink} au sein du parcours {parcoursLink}.
          </span>
        );
        image = avatar;
        break;
      case 'MentionedInParcoursTalk':
        body = (
          <span>
            {targetUserLink} vous a mentionné dans un message de la discussion {talkLink} au sein du parcours {parcoursLink}.
          </span>
        );
        image = avatar;
        break;
      case 'ParcoursTalkLiked':
        body = (
          <span>
            {targetUserLink} a aimé votre message dans la discussion {talkLink} au sein du parcours {parcoursLink}.
          </span>
        );
        image = avatar;
        break;
      case 'Communication':
        const message = notif.message.split('<####>');
        body = (
          <div className="ui info message" style={{ marginTop: 0 }}>
            <div className="header">{message[0]}</div>
            <div>En savoir plus : <a href={message[1]}>{message[1]}</a></div>
          </div>
        );
        image = (
          <img
            className="ui mini rounded image"
            src="https://s3-eu-west-1.amazonaws.com/scribay/avatars/SY4YDZacZP.jpg"
            alt="Logo Ada"
          />
        );
        break;
      default:
        break;
    }

    const date = (notif as SimpleNotificationModel).ago || (notif as NotificationModel).hour;
    return (
      <div key={Math.random() * 1000} className="event">
        <div style={{ marginRight: '10px' }}>
          {image}
        </div>
        <div className="content">
          <div className="summary">
            <div className="date">
              {date}
            </div>
            {body}
          </div>
        </div>
      </div>
    );
  }
}
