import { observable } from 'mobx';
import FeedListModel from '../feed/FeedListModel';
import FeedModel from '../feed/FeedModel';
import CurrentTextModel from './CurrentTextModel';
import CurrentDefiModel from './CurrentDefiModel';
import CurrentTalkModel from './CurrentTalkModel';
import CurrentParcoursModel from './CurrentParcoursModel';
import ReadingListTextModel from '../readinglist/ReadingListTextModel';
import SuggestionModel from './SuggestionModel';
import HomeStatsModel from './HomeStatsModel';
import ExploreModel from './ExploreModel';

export default class HomeModel {

  @observable feed: FeedListModel;
  @observable currentTexts: CurrentTextModel[];
  @observable currentDefis: CurrentDefiModel[];
  @observable currentTalks: CurrentTalkModel[];
  @observable currentParcours: CurrentParcoursModel;
  @observable lastListsTexts: ReadingListTextModel[];
  @observable allListsTexts: ReadingListTextModel[];
  @observable suggestions: SuggestionModel[];
  @observable loading: boolean;
  @observable pageListsTexts: number;
  @observable endListsTexts: boolean;
  @observable homeStats: HomeStatsModel;
  @observable explore: ExploreModel;

  @observable lastListsTextsLoading: boolean;

  constructor(feed: FeedListModel, currentTexts: CurrentTextModel[], currentDefis: CurrentDefiModel[],
    currentTalks: CurrentTalkModel[], currentParcours: CurrentParcoursModel, lastListsTexts: ReadingListTextModel[], allListsTexts: ReadingListTextModel[],
    suggestions: SuggestionModel[], pageListsTexts: number, endListsTexts: boolean,
    homeStats: HomeStatsModel, explore: ExploreModel) {
    this.feed = feed;
    this.currentTexts = currentTexts || [];
    this.currentDefis = currentDefis || [];
    this.currentTalks = currentTalks || [];
    this.currentParcours = currentParcours;
    this.lastListsTexts = lastListsTexts || [];
    this.allListsTexts = allListsTexts || [];
    this.suggestions = suggestions || [];
    this.loading = false;
    this.pageListsTexts = pageListsTexts;
    this.endListsTexts = endListsTexts;
    this.homeStats = homeStats;
    this.explore = explore;
  }

  setFeed(feed: FeedListModel) {
    this.feed = new FeedListModel(feed.elements, feed.empty, feed.beforeDate);
  }

  fillFeed(feed: FeedListModel) {
    this.feed.elements = this.feed.elements.concat(feed.elements.map(e => new FeedModel(e.newsfeed, e.diary)));
    this.feed.empty = feed.empty;
    this.feed.beforeDate = feed.beforeDate;
  }

  setCurrentTexts(currentTexts: CurrentTextModel[]) {
    this.currentTexts = currentTexts;
  }

  setCurrentDefis(currentDefis: CurrentDefiModel[]) {
    this.currentDefis = currentDefis;
  }

  setCurrentTalks(currentTalks: CurrentTalkModel[]) {
    this.currentTalks = currentTalks;
  }

  setCurrentParcours(currentParcours: CurrentParcoursModel) {
    this.currentParcours = currentParcours;
  }

  setLastListsTexts(lastListsTexts: ReadingListTextModel[]) {
    this.lastListsTexts = lastListsTexts;
  }

  setAllListsTexts(allListsTexts: ReadingListTextModel[]) {
    if (this.pageListsTexts === 0) {
      this.allListsTexts = allListsTexts;
    } else {
      this.allListsTexts = this.allListsTexts.concat(allListsTexts);
    }
    this.pageListsTexts = this.pageListsTexts + 1;
    this.endListsTexts = allListsTexts.length === 0;
    this.loading = false;
  }

  setSuggestions(suggestions: SuggestionModel[]) {
    this.suggestions = suggestions.map(s => new SuggestionModel(s.user, s.count, false));
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      feed: this.feed.toJS(),
      currentTexts: this.currentTexts.map(t => t.toJS()),
      currentDefis: this.currentDefis.map(d => d.toJS()),
      currentTalks: this.currentTalks.map(d => d.toJS()),
      currentParcours: this.currentParcours.toJS(),
      lastListsTexts: this.lastListsTexts.map(l => l.toJS()),
      allListsTexts: this.allListsTexts.map(l => l.toJS()),
      suggestions: this.suggestions.map(s => s.toJS()),
      pageListsTexts: this.pageListsTexts,
      endListsTexts: this.endListsTexts,
      homeStats: this.homeStats,
      explore: this.explore
    };
  }

  static fromJS(object: any) {
    if (object) {
      const feed = FeedListModel.fromJS(object.feed);
      const texts = object.currentTexts.map((t: any) => CurrentTextModel.fromJS(t));
      const defis = object.currentDefis.map((d: any) => CurrentDefiModel.fromJS(d));
      const talks = object.currentTalks.map((d: any) => CurrentTalkModel.fromJS(d));
      const parcours = CurrentParcoursModel.fromJS(object.currentParcours);
      const lastListsTexts = object.lastListsTexts.map((t: any) => ReadingListTextModel.fromJS(t));
      const allListsTexts = object.allListsTexts.map((t: any) => ReadingListTextModel.fromJS(t));
      const suggestions = object.suggestions.map((s: any) => SuggestionModel.fromJS(s));

      return new HomeModel(feed, texts, defis, talks, parcours, lastListsTexts,
        allListsTexts, suggestions, object.pageListsTexts, object.endListsTexts, object.homeStats,
        object.explore);
    }
    return undefined;
  }
}
