import * as React from 'react';

export default class H1Icon extends React.Component<{}, {}> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <title>H1</title>
        <path d="M5 4v3h5.5v12h3V7H19V4z" fill="currentColor" />
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="m19.025 18.958h-1.3492v-8.5015l-2.0237 0.81653v-1.3609l3.1705-1.3609h0.20237z" fill="currentColor" />
      </svg>
    );
  }
}
