import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface EndWelcomeModalProps {
  active: boolean;
  hide: Function | any;
}

export default class EndWelcomeModal extends React.Component<EndWelcomeModalProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Voilà ! Vous pouvez partir à l’aventure !</div>
        <div className="content">
          Nous espérons que vous pourrez écrire l’œuvre de votre vie parmi nous et que vous ferez de très belles rencontres littéraires.
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.hide}>Bien reçu !</button>
        </div>
      </Modal>
    );
  }
}
