import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../stores/Store';

interface SettingsPageAdminSearchProps {
  store: Store;
}

@inject('store') @observer
export default class SettingsPageAdminSearch extends React.Component<SettingsPageAdminSearchProps, {}> {
  constructor(props: SettingsPageAdminSearchProps) {
    super(props);
    this.goToSearchPage = this.goToSearchPage.bind(this);
  }
  goToSearchPage() {
    browserHistory.push({
      pathname: '/search_page'
    });
  }
  @action handleChangeBoughtAParcoursNews() {
    this.props.store.mainSettings.emails.boughtAParcoursNews =
      !this.props.store.mainSettings.emails.boughtAParcoursNews;
  }
  render() {
    const connected = this.props.store.connected;
    return (
      <div>
        {connected && connected.user && connected.user.admin ?
          <div>
            <div className="ui success message">
              <div className="header">
                Vous êtes administrateur.
              </div>
            </div>
            <button className="ui submit primary right floated button"
              type="submit" onClick={this.goToSearchPage} style={{ marginTop: '25px' }}>
              Aller à la page de recherche
            </button>
            </div>
            :
            <div className="ui error message">
              <div className="header">
                Accès non autorisé
            </div>
          </div>
        }
      </div>
    );
  }
}
