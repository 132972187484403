import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import InstructionsModel from '../../../../../models/write/InstructionsModel';

interface ChapterNewChapterProps {
  showNewChapter: boolean;
  instructions: InstructionsModel;
  chapterTitle: string;
  hide: Function | any;
}

export default class ChapterNewChapter
  extends React.Component<ChapterNewChapterProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.showNewChapter}
        onClose={this.props.hide}
        size="small"
        >
        <div className="header">{this.props.chapterTitle}</div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: this.props.instructions.chapterInstructions }} />
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal>
    );
  }
}
