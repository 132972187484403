import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import ColorPicker from '../misc/ColorPicker';
import Store from '../../stores/Store';

interface TextColorsProps {
  store?: Store;
}

interface TextColorsState {
  colorsEditMode: boolean;
}


@inject('store') @observer
export default class TextColors extends React.Component<TextColorsProps, TextColorsState> {
  constructor(props: TextColorsProps) {
    super(props);
    this.startColorsEdit = this.startColorsEdit.bind(this);
    this.cancelColorsEdit = this.cancelColorsEdit.bind(this);
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.handleTextColorChange = this.handleTextColorChange.bind(this);
    this.handleLinkColorChange = this.handleLinkColorChange.bind(this);
    this.saveColors = this.saveColors.bind(this);
    this.state = {
      colorsEditMode: false
    };
  }
  startColorsEdit() {
    this.setState({
      colorsEditMode: true
    });
  }
  @action
  cancelColorsEdit() {
    this.props.store.text.meta.backgroundColor = this.props.store.text.meta.oldBackgroundColor;
    this.props.store.text.meta.textColor = this.props.store.text.meta.oldTextColor;
    this.props.store.text.meta.linkColor = this.props.store.text.meta.oldLinkColor;
    this.setState({
      colorsEditMode: false
    });
  }
  @action
  handleBackgroundColorChange(newVal: string) {
    this.props.store.text.meta.backgroundColor = newVal;
  }
  @action
  handleTextColorChange(newVal: string) {
    this.props.store.text.meta.textColor = newVal;
  }
  @action
  handleLinkColorChange(newVal: string) {
    this.props.store.text.meta.linkColor = newVal;
  }
  saveColors(e: any) {
    e.preventDefault();
    this.props.store.saveTextColors().then((d: any) => {
      this.setState({
        colorsEditMode: false
      });
    });
  }
  render() {
    const meta = this.props.store.text.meta;
    return (
      <div style={{ marginTop: '15px' }}>
        {this.state.colorsEditMode ?
          <div className="ui segment" style={{ paddingBottom: '40px', marginBottom: '20px' }}>
            <form
              className="ui form"
              onSubmit={this.saveColors}
              >
              <div className="ui message warning">
                Enjolivez la page de présentation de votre œuvre avec une bannière personnalisée.
              </div>
              <ColorPicker
                color={meta.backgroundColor}
                handleColorPickerChange={this.handleBackgroundColorChange}
                defaultColor="#42586E"
                colorPickerLabel="Couleur du fond"
                />
              <ColorPicker
                color={meta.textColor}
                handleColorPickerChange={this.handleTextColorChange}
                defaultColor="#FAFAFA"
                colorPickerLabel="Couleur du texte"
                />
              <ColorPicker
                color={meta.linkColor}
                handleColorPickerChange={this.handleLinkColorChange}
                defaultColor="#F0AD4E"
                colorPickerLabel="Couleur des liens"
                />
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelColorsEdit}
                  >
                  Annuler
              </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveColors}
                  type="submit"
                  >
                  Enregistrer
            </button>
              </div>
            </form>
          </div>
          :
          <button className="ui fluid button" onClick={this.startColorsEdit}>
            Modifier les couleurs
          </button>
        }
      </div>
    );
  }
}
