import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import NewsfeedModel from '../../models/feed/NewsfeedModel';
import BadgeModal from '../misc/BadgeModal';

interface NewsfeedEventProps {
  event: NewsfeedModel;
}

interface NewsfeedEventState {
  activeBadgeModal: boolean;
  activeAltruisteModal: boolean;
  showStatus: boolean;
}

export default class NewsfeedEvent extends React.Component<NewsfeedEventProps, NewsfeedEventState> {
  constructor(props: NewsfeedEventProps) {
    super(props);
    this.state = {
      activeBadgeModal: false,
      activeAltruisteModal: false,
      showStatus: false
    };
    this.showBadgeModal = this.showBadgeModal.bind(this);
    this.hideBadgeModal = this.hideBadgeModal.bind(this);
    this.showAltruisteModal = this.showAltruisteModal.bind(this);
    this.hideAltruisteModal = this.hideAltruisteModal.bind(this);
    this.showStatus = this.showStatus.bind(this);
    this.hideStatus = this.hideStatus.bind(this);
  }
  showBadgeModal(event: any) {
    this.setState({
      activeBadgeModal: true,
      activeAltruisteModal: this.state.activeAltruisteModal
    });
  }
  hideBadgeModal() {
    this.setState({
      activeBadgeModal: false,
      activeAltruisteModal: this.state.activeAltruisteModal
    });
  }
  showAltruisteModal(event: any) {
    this.setState({
      activeBadgeModal: this.state.activeBadgeModal,
      activeAltruisteModal: true
    });
  }
  hideAltruisteModal() {
    this.setState({
      activeBadgeModal: this.state.activeBadgeModal,
      activeAltruisteModal: false
    });
  }
  showStatus() {
    this.setState({
      showStatus: true
    });
  }
  hideStatus() {
    this.setState({
      showStatus: false
    });
  }
  render() {
    const event = this.props.event;
    const user = this.props.event.user;

    const userLink = (
      <Link
        className="user"
        to={`/author/${event.user.id}/${event.user.urlIdentity}`}
      >
        {event.user.identity}
      </Link>
    );

    let textLink: any;
    let extraCover: any;
    let extraTalk: any;
    if (event.text) {
      textLink = (
        <Link
          to={`/text/${event.text.id}/${event.text.urlText}`}
        >
          {event.text.title}
        </Link>
      );

      extraCover = (
        <div className="item">
          {event.text.cover ?
            <div className="ui tiny image">
              <Link to={`/text/${event.text.id}/${event.text.urlText}`}>
                <img
                  src={event.text.cover}
                  style={{ width: '100px' }}
                  alt={`Couverture de ${event.text.title}`}
                />
              </Link>
            </div>
            : ''
          }
          <div className="content">
            <div className="header">
              <Link to={`/text/${event.text.id}/${event.text.urlText}`} style={{ color: 'inherit' }}>
                {event.text.title}
              </Link>
            </div>
            {event.text.summary ?
              <div className="description">
                <Link to={`/text/${event.text.id}/${event.text.urlText}`} style={{ color: 'inherit' }}>
                  <p dangerouslySetInnerHTML={{
                    __html: '<div>' + event.text.summary.substring(0, Math.min(event.text.summary.length, 140)) + '...</div>'
                  }} />
                </Link>
              </div>
              : undefined}
          </div>
        </div>
      );
    }

    let defiLink: any;
    if (event.defi) {
      defiLink = (
        <Link
          to={`/defis/defi/${event.defi.id}/${event.defi.urlTitle}`}
        >
          {event.defi.title}
        </Link>
      );
    }

    let talkLink: any;
    if (event.talk) {
      const url = event.text ?
        `/text/${event.text.id}/${event.text.urlText}/talks/${event.talk.id}/${event.talk.urlTitle}` + (event.anchorId ? `#comment_${event.anchorId}` : '')
        : `/talks/${event.talk.id}/${event.talk.urlTitle}` + (event.anchorId ? `#comment_${event.anchorId}` : '');
      talkLink = (
        <Link to={url} >
          {event.talk.title}
        </Link>
      );
      extraTalk = (
        <div className="item">
          <div className="content">
            <div className="header">
              <Link to={url} style={{ color: 'inherit' }}>
                {event.talk.title}
              </Link>
            </div>
          </div>
        </div>
      );
    }

    let body: any;
    let extra: any;
    switch (event.newsType) {
      case 'NewText':
        body = (
          event.pinnedNew ?
            <span>
              {userLink} a fait ses débuts sur l'Atelier des auteurs en publiant :
          </span>
            : <span>
              {userLink} a publié une nouvelle œuvre.
          </span>
        );
        extra = extraCover;
        break;
      case 'TextFavorite':
        body = (
          <span>
            {userLink} a aimé l'œuvre {textLink}.
          </span>
        );
        break;
      case 'NewChapter':
        const urlNewChapter = (event.chapter ?
          `/text/${event.text.id}/${event.text.urlText}/chapter/${event.chapter.id}`
          : `/text/${event.text.id}/${event.text.urlText}`);
        body = (
          <span>
            {userLink} a publié un <Link to={urlNewChapter}>nouveau chapitre</Link> à l'œuvre {textLink}.
          </span>
        );
        break;
      case 'NewVersion':
        body = event.chapter ? (
          <span>
            {userLink} a publié une&nbsp;
            <Link
              to={`/text/${event.text.id}/${event.text.urlText}/chapter/${event.chapter.id}`}
            >
              nouvelle version
            </Link>
            &nbsp;au chapitre <Link
              to={`/text/${event.text.id}/${event.text.urlText}/chapter/${event.chapter.id}`}
            >
              {event.chapter.title}
            </Link> de l'œuvre {textLink}.
          </span>
        ) : (
            <span>
              {userLink} a publié une nouvelle version à un chapitre de l'œuvre {textLink}.
          </span>
          );
        break;
      case 'NewDefi':
        body = (
          <span>
            {userLink} a relevé avec succès le défi {defiLink} avec l'œuvre {textLink}.
          </span>
        );
        break;
      case 'NewDefiPropose':
        body = (
          <span>
            {userLink} a proposé le défi {defiLink}.
          </span>
        );
        break;
      case 'Comment':
        const urlComment = (event.chapter ?
          `/text/${event.text.id}/${event.text.urlText}/chapter/${event.chapter.id}` + (event.anchorId ? `#comment_${event.anchorId}` : '')
          : `/text/${event.text.id}/${event.text.urlText}`);

        body = (
          <span>
            {userLink} a commenté l'œuvre <Link to={urlComment}>{event.text.title}</Link>.
          </span>
        );
        break;
      case 'DefiComment':
        body = (
          <span>
            {userLink} a commenté le défi&nbsp;
            <Link
              to={`/defis/defi/${event.defi.id}/${event.defi.urlTitle}` + (event.anchorId ? `#comment_${event.anchorId}` : '')}
            >
              {event.defi.title}
            </Link>.
          </span>
        );
        break;
      case 'Annotation':
        const annLink = (
          <Link
            to={`/text/${event.text.id}/${event.text.urlText}/chapter/${event.chapter.id}?version=${event.chapterVersionId}#annId_${event.anchorId}`}
          >
            {event.text.title}
          </Link>
        );
        body = (
          <span>
            {userLink} a annoté l'œuvre {annLink}.
          </span>
        );
        break;
      case 'Badge':
        body = (
          <span>
            <BadgeModal
              badge={event.badge}
              active={this.state.activeBadgeModal}
              hideModal={this.hideBadgeModal}
            />
            {userLink} a remporté le badge&nbsp;
            <span
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
              onClick={this.showBadgeModal}
            >
              <FormattedMessage
                id={`badge.${event.badge.name}.name`}
                defaultMessage={`${event.badge.name}`}
              />
            </span>
          </span>
        );
        break;
      case 'NewTalkParent':
        body = event.text ?
          <span>
            {userLink} a créé une nouvelle discussion au sein de l'œuvre {textLink} :
          </span>
          :
          <span>
            {userLink} a créé une nouvelle discussion :
          </span>
          ;
        extra = extraTalk;
        break;
      case 'NewTalk':
        body = event.text ?
          <span>
            {userLink} a publié un nouveau message dans la discussion {talkLink} au sein de l'œuvre {textLink}.
          </span>
          :
          <span>
            {userLink} a publié un nouveau message dans la discussion {talkLink}.
          </span>
          ;
        break;
      default:
        break;
    }

    let className: string;
    let trigger: JSX.Element;
    let trigger2: JSX.Element;
    let corner: JSX.Element;

    if (event.pinnedAltruiste || event.pinnedNew) {
      className = event.pinnedAltruiste ?
        'event ui basic pinned altruiste segment'
        : 'event ui basic pinned new segment';

      trigger = event.pinnedNew ?
        <span className="ui top right attached olive label">
          À accueillir !
          </span>
        : undefined;

      trigger2 = event.pinnedAltruiste ?
        <button className="ui top right attached teal label"
          onClick={this.showAltruisteModal}>
          Auteur altruiste
        </button>
        : undefined;

      corner = event.pinnedAltruiste ?
      
      <Popup
        trigger={trigger2}
        content="Cliquer pour en savoir plus"
      />
        : (event.pinnedNew ?
          <Popup
            trigger={trigger}
            content="Cet auteur est nouveau !"
          />
          : undefined
        )
        ;
    } else {
      if (event.newsType === 'NewText') {
        className = 'event ui basic pinned newtext segment';
      } else {
        className = 'event';
      }
    }

    return (
      <div key={event.id} className={className} onMouseEnter={this.showStatus} onMouseLeave={this.hideStatus}>
        {event.pinnedAltruiste ?
          <Modal
            open={this.state.activeAltruisteModal}
            onClose={this.hideAltruisteModal}
            size="small"
          >
            <div className="content">
              Cet auteur s'est distingué par son investissement auprès de la communauté !
              <p>
                Sur l'Atelier des auteurs, nous encourageons et rendons plus visibles les membres aidant les autres auteurs à progresser.
              </p>
            </div>
            <div className="actions">
              <button className="ui cancel button" onClick={this.hideAltruisteModal}>Fermer</button>
            </div>
          </Modal>
          : undefined}
        {corner}
        <Link to={`/author/${user.id}/${user.urlIdentity}`} className="label">
          <img
            src={user.avatarUrl}
            alt={`Image de profil de ${user.identity}`}
          />
        </Link>
        <div className="content">
          <div className="summary">
            {body}
            {!event.pinnedAltruiste && !event.pinnedNew ? <div className="date">{event.ago}</div> : undefined}
            {!event.pinnedAltruiste && !event.pinnedNew && event.chapter && event.chapter.step
              && ((event.newsType === 'NewText' || event.newsType === 'NewDefi' || event.newsType === 'NewChapter')
                || this.state.showStatus) ?
              <div className="date">
                {event.chapter.step === 1 ?
                  <div className="ui small basic blue label">
                    Premier jet
                </div>
                  : (event.chapter.step === 2 ?
                    <div className="ui small basic violet label">
                      Correction / Relecture
                </div> :
                    <div className="ui small basic purple label">
                      Texte final
                </div>)
                }
              </div>
              : undefined}
          </div>
          {extra ?
            <div className="ui extra link items" >
              {extra}
            </div>
            : undefined}
        </div>
      </div>
    );
  }
}
