import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface TextTitleProps {
  store?: Store;
  strangerMode: boolean;
}

interface TextTitleState {
  titleEditMode: boolean;
  titleEdit: string;
}

@inject('store') @observer
export default class TextTitle extends React.Component<TextTitleProps, TextTitleState> {
  constructor(props: TextTitleProps) {
    super(props);
    this.startTitleEdit = this.startTitleEdit.bind(this);
    this.cancelTitleEdit = this.cancelTitleEdit.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.saveTitleEdit = this.saveTitleEdit.bind(this);
    this.state = {
      titleEditMode: false,
      titleEdit: '',
    };
  }
  startTitleEdit() {
    this.setState({
      titleEditMode: true,
      titleEdit: this.props.store.text.meta.title
    });
  }
  cancelTitleEdit() {
    this.setState({
      titleEditMode: false,
      titleEdit: this.state.titleEdit
    });
  }
  handleTitleChange(e: any) {
    this.setState({
      titleEdit: e.target.value,
      titleEditMode: this.state.titleEditMode
    });
  }
  saveTitleEdit(e: any) {
    e.preventDefault();
    this.props.store.saveTextTitle(this.state.titleEdit).then((e: any) => {
      this.setState({
        titleEditMode: false,
        titleEdit: this.state.titleEdit
      });
    }, (err: string) => {
      this.setState({
        titleEditMode: false,
        titleEdit: this.state.titleEdit
      });
    });
  }
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected;
    const meta = text.meta;
    const isAuthor = text && text.meta && text.meta.author
      && connected && connected.user && connected.user.id
      && connected.user.id === text.meta.author.id;
    return (
      <span className="ui large header">
        {isAuthor && this.state.titleEditMode && !this.props.strangerMode ?
          <form
            className="ui one column stackable center aligned grid form"
            style={{ marginBottom: '5px', marginTop: '10px' }}
            onSubmit={this.saveTitleEdit}
          >
            <div className="inline fields">
              <div className="field">
                <input
                  ref="titleField"
                  type="text"
                  onChange={this.handleTitleChange}
                  value={this.state.titleEdit}
                  style={{ width: '300px' }}
                  autoFocus={true}
                />
              </div>
              <div className="field">
                <button
                  className="ui blue mini button"
                  onClick={this.saveTitleEdit}
                  type="submit"
                  disabled={!this.state.titleEdit || this.state.titleEdit.trim().length === 0}
                >
                  Enregistrer
                  </button>
              </div>
              <div className="field">
                <button
                  type="button"
                  className="ui mini button"
                  onClick={this.cancelTitleEdit}
                >
                  Annuler
                    </button>
              </div>
            </div>
          </form>
          :
          <span>
            <h1 className="ui huge header" style={{ color: meta.textColor }}>
              {meta.title}
              {isAuthor && !this.props.strangerMode ?
                <button className="ui small orange label" onClick={this.startTitleEdit}>
                  Modifier
                </button>
                : undefined
              }
            </h1>
          </span>
        }
      </span>
    );
  }
}
