import { observable } from 'mobx';
import LightChapterModel from './LightChapterModel';

export default class DraftModel {

  @observable id: number;
  @observable title: string;
  @observable urlText: string;
  @observable chapters: LightChapterModel[];
  @observable lastModificationDate: string;

  constructor(id: number, title: string, urlText: string, chapters: LightChapterModel[],
    lastModificationDate: string) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.chapters = chapters;
    this.lastModificationDate = lastModificationDate;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      chapters: this.chapters,
      lastModificationDate: this.lastModificationDate
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new DraftModel(object.id, object.title, object.urlText, object.chapters,
        object.lastModificationDate);
    }
    return undefined;
  }
}
