import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface AlertNewDefiProps {
  active: boolean;
  hide: Function | any;
}

export default class AlertNewDefi
  extends React.Component<AlertNewDefiProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
        size="small"
      >
        <div className="header">Félicitations !</div>
        <div className="content">
          Le compte a rebours a commencé, vous avez 30 jours pour publier un texte en réponse à ce défi !
          <br /> Vous pouvez retrouver les consignes du défi en cliquant sur "Défi associé", en haut à droite.
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal>
    );
  }
}
