import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../../../stores/Store';
import PreventAccess from './PreventAccess';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const cookie = new (require('react-cookie')).Cookies();

interface TextTitleButtonState {
  activeModal: boolean;
  title: string;
}

interface TextTitleButtonProps {
  store?: Store;
  title: string;
}

@inject('store') @observer
export default class TextTitleButton extends React.Component<TextTitleButtonProps, TextTitleButtonState> {
  constructor(props: TextTitleButtonProps) {
    super(props);
    this.state = {
      activeModal: false,
      title: this.props.title
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.saveTitleEdit = this.saveTitleEdit.bind(this);
  }
  showModal() {
    this.setState({
      activeModal: true
    });
  }
  hideModal() {
    this.setState({
      activeModal: false
    });
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  saveTitleEdit(e: any) {
    e.preventDefault();
    this.props.store.saveParcoursTextTitle(this.state.title).then((e: any) => {
      this.setState({
        activeModal: false
      });
    });
  }
  render() {
    return(
          <div>
          <button onClick={this.showModal} className="ui item basic button"
            title="Modifier le titre">
            <i className="fa fa-header" style={{ marginRight: '5px' }} />
            Titre du roman
          </button>
            <Modal
            open={this.state.activeModal}
            size="small"
            onClose={this.hideModal}
            >
            <div className="header">Modification du titre</div>
            <div className="content" >
                <div className="ui input">
                    <input
                        ref="titleField"
                        type="text"
                        onChange={this.handleTitleChange}
                        value={this.state.title}
                        style={{ width: '300px' }}
                        autoFocus={true}
                        onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                    />
                </div>
            </div>
            <div className="actions">
               
                <button className="ui primary button" onClick={this.saveTitleEdit}>
                    Enregistrer
                </button>
                
                <button className="ui cancel button" onClick={this.hideModal}>Fermer</button>
            </div>
            </Modal>
        </div>
    );
  }
}
