import * as React from 'react';

export default class AlignCenterIcon extends React.Component<{}, {}> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <title>AlignCenter</title>
        <path d="M7 15v2h10v-2H7zm-4 6h18v-2H3v2zm0-8h18v-2H3v2zm4-6v2h10V7H7zM3 3v2h18V3H3z" fill="currentColor" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
