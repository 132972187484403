import * as React from 'react';
import { inject, observer } from 'mobx-react';
const Waypoint = require('react-waypoint');
import { Link } from 'react-router';
import Store from '../../stores/Store';
import Chapter from './Chapter';
import ChapterLike from './ChapterLike';
import ChapterVersions from './ChapterVersions';
import ChapterAnnotations from './ChapterAnnotations';
import Loader from '../misc/Loader';
import CommentBox from '../comment/CommentBox';
import TextCarousel from '../misc/text/TextCarousel';
import ReadingListsTextModal from '../readinglist/ReadingListsTextModal';
import AlertModel from '../../models/AlertModel';

interface ChapterSectionProps {
  store?: Store;
}

interface ChapterSectionState {
  readingListModalActive: boolean;
  enableComponents: boolean;
  blockRead: boolean;
  commentsLoaded: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class ChapterSection extends React.Component<ChapterSectionProps, ChapterSectionState> {
  constructor(props: ChapterSectionProps) {
    super(props);
    this.loadComments = this.loadComments.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.showReadingListModal = this.showReadingListModal.bind(this);
    this.hideReadingListModal = this.hideReadingListModal.bind(this);

    this.state = {
      readingListModalActive: false,
      enableComponents: false,
      blockRead: false,
      commentsLoaded: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        readingListModalActive: false,
        enableComponents: true,
        blockRead: false,
        commentsLoaded: false
      });
    }
  }
  loadComments() {
    if (!this.state.commentsLoaded && !this.props.store.comments.loading && this.props.store.text
      && this.props.store.text.meta && this.props.store.text.meta.id
      && this.props.store.text.chapter && this.props.store.text.chapter.id) {
      this.props.store.loadComments(this.props.store.text.meta.id, this.props.store.text.chapter.id).then((d: any) => {
        this.setState({
          commentsLoaded: true
        });
      });
    }
  }
  showReadingListModal() {
    const textId = this.props.store.text.meta.id;
    this.props.store.loadMyReadingListsForText(textId).then((d: any) => {
      this.setState({
        readingListModalActive: true,
        enableComponents: this.state.enableComponents,
        blockRead: this.state.blockRead
      });
    });
  }
  hideReadingListModal() {
    this.setState({
      readingListModalActive: false,
      enableComponents: this.state.enableComponents,
      blockRead: this.state.blockRead
    });
  }
  markAsRead() {
    const t = this;
    const store = this.props.store;
    this.props.store.markChapterAsRead().then((d: any) => {
      const msg = store.text.meta.inReadingList ?
        (
          <div className="ui vertical labeled icon buttons">
            <button className="ui orange mini button" onClick={() => store.markChapterAsUnread()}>
              <i className="fa fa-close icon"></i> Marquer comme non-lu
          </button>
          </div>
        )
        :
        (
          <div className="ui vertical labeled icon buttons">
            <button className="ui orange mini button" onClick={() => store.markChapterAsUnread()}>
              <i className="fa fa-close icon"></i> Marquer comme non-lu
          </button>
            <button className="ui blue mini button" onClick={() => t.showReadingListModal()}>
              <i className="fa fa-list icon"></i> Ajouter à une liste de lecture
          </button>
          </div>
        )
        ;
      this.props.store.addAlert(new AlertModel(
        'chapterRead',
        'Chapitre marqué comme lu',
        msg,
        'success',
        10
      ));
      this.setState({
        readingListModalActive: this.state.readingListModalActive,
        enableComponents: this.state.enableComponents,
        blockRead: true
      });
    });
  }
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected;
    const blockedBy = connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(text.meta.author.id) !== -1;
    const admin = connected && connected.user && connected.user.admin;
    const author = text.meta.author;
    return (
      <div>
        <ReadingListsTextModal
          active={this.state.readingListModalActive}
          hideModal={this.hideReadingListModal}
        />
        <div style={{ backgroundColor: '#f8f8f8', paddingBottom: '50px' }}>
          {text.meta && text.chapter && text.chapter.id ?
            <div className="ui container" style={{ padding: '70px 0px 50px 0px', backgroundColor: '#fff' }}>
              <Chapter showAnnotations={text.chapter.showAnnotations
                && (!connected || !connected.user || !connected.user.id
                  || (connected && connected.user && connected.user.id && connected.preferences.annotations))
              } step={text.chapter.step} />
              <div className="ui list container center aligned">
                {this.state.enableComponents && !text.chapter.read && text.chapter.loaded
                  && connected && connected.user && connected.user.id && connected.user.id !== author.id
                  && !this.state.blockRead ?
                  <Waypoint
                    key={'read' + text.chapter.id}
                    onEnter={this.markAsRead}
                    scrollableAncestor={window}
                  />
                  : undefined}
                <div className="ui hidden section divider" />
                <div>
                  <ChapterLike />
                  {text.chapter.nextChapter ?
                    <Link
                      to={`/text/${text.meta.id}/${text.meta.urlText}/chapter/${text.chapter.nextChapter}`}
                      className="ui button orange"
                      style={{ width: '200px', marginTop: '10px' }}
                    >
                      Lire la suite ! <i className="fa fa-fw fa-arrow-right" />
                    </Link>
                    : undefined
                  }
                </div>
              </div>
              {text.meta ?
                <div>
                  <div className="ui stackable centered grid">
                    <div id="comments" className="ten wide column">
                      {text.meta.openToComments && this.state.enableComponents ?
                        <div>
                          {text.chapter.loaded && !this.state.commentsLoaded ?
                            <Waypoint
                              key={'com' + text.chapter.id}
                              onEnter={this.loadComments}
                              scrollableAncestor={window}
                            />
                            : undefined}
                          <h3 className="ui horizontal section divider">
                            <i className="fa fa-fw fa-text"></i> Commentaires
                        </h3>
                          {this.props.store.comments.loading ?
                            <Loader message="Chargement des commentaires" />
                            :
                            <div>
                              {blockedBy ?
                                <div className="ui small message">
                                  <Link to={`/author/${author.id}/${author.urlIdentity}`}>
                                    {author.identity}</Link> vous a bloqué.
                              &nbsp;Vous ne pouvez pas participer aux commentaires.
                              </div>
                                : undefined}
                              <CommentBox
                                admin={admin}
                                newsfeedElement={false}
                                readonly={blockedBy}
                                comments={this.props.store.comments}
                              />
                            </div>
                          }
                        </div>
                        : undefined}
                      <ChapterAnnotations />
                      {connected && connected.user && connected.user.id && connected.user.id === author.id
                        || text.meta.showOldVersions ?
                        <ChapterVersions />
                        : undefined}
                    </div>
                    {/* <div className="ui container">
                      <h3 className="ui horizontal section divider">Recommandations</h3>
                      <TextCarousel texts={text.recommandations} />
                    </div> */}
                  </div>
                </div>
                : undefined}
            </div>
            : <Loader message="Chargement..." />}
        </div>
      </div>
    );
  }
}
