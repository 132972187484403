import { observable } from 'mobx';
import WritingProgramObjectiveModel from './WritingProgramObjectiveModel';

export default class WritingProgramPeriodModel {

  @observable day: number;
  @observable today: boolean;
  @observable objectives: WritingProgramObjectiveModel[];

  constructor(day: number, today: boolean, objectives: WritingProgramObjectiveModel[]) {
    this.day = day;
    this.today = today;
    this.objectives = objectives;
  }

  toJS() {
    return {
      day: this.day,
      today: this.today,
      objectives: this.objectives.map(o => o.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const objectives = object.objectives.map((o: any) => WritingProgramObjectiveModel.fromJS(o));
      return new WritingProgramPeriodModel(object.day, object.today, objectives);
    }
    return undefined;
  }

}