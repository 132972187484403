import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import TextParams from '../TextParams';
import Store from '../../../../stores/Store';

interface TextStatisticsToolbarProps {
  store?: Store;
  params: TextParams;
  location: {
    pathname: string;
    query: { chapter: number }
  };
}

interface TextStatisticsToolbarState {
  originalChapter: number;
}

@inject('store') @observer
export default class TextStatisticsToolbar
  extends React.Component<TextStatisticsToolbarProps, TextStatisticsToolbarState> {
  constructor(props: TextStatisticsToolbarProps) {
    super(props);
    this.state = {
      originalChapter: this.props.location && this.props.location.query && this.props.location.query.chapter ?
        this.props.location.query.chapter
        : undefined
    };
    this.writeStatistics = this.writeStatistics.bind(this);
    this.readStatistics = this.readStatistics.bind(this);
    this.compareStatistics = this.compareStatistics.bind(this);
  }
  writeStatistics() {
    const reg = /(.*\/statistics\/write$)/g;
    return reg.test(this.props.location.pathname);
  }
  readStatistics() {
    const reg = /(.*\/statistics\/read$)/g;
    return reg.test(this.props.location.pathname);
  }
  compareStatistics() {
    const reg = /(.*\/statistics\/compare$)/g;
    return reg.test(this.props.location.pathname);
  }
  render() {
    const premium = this.props.store.connected && this.props.store.connected.premium && this.props.store.connected.premium.premium;
    const currentText = this.props.store.write.currentText;
    const infos = currentText.infos;
    const queryParams = this.props.location && this.props.location.query && this.props.location.query.chapter ?
      `?chapter=${this.props.location.query.chapter}`
      : '';
    return (
      <div className="ui secondary inverted teal menu relecture-bar" style={{ overflowX: 'scroll', overflow: 'auto', marginTop: "0px" }}>
        <div className="item" style={{ border: 'none', height: '60px' }}>
          {infos && infos.title ?
            <b>{infos.title}</b>
            : undefined}
        </div>
        {infos ?
          <Link to={`/text/${currentText.id}/${currentText.infos.urlText}/statistics/write${queryParams}`}
            className={(this.writeStatistics() ? 'active ' : '') + ' item'}
          >
            Écriture
        </Link>
          : undefined}
        {infos ?
          premium && infos && infos.parcours && infos.parcours.active ?
          undefined 
        :
          <Link to={`/text/${currentText.id}/${currentText.infos.urlText}/statistics/read${queryParams}`}
            className={(this.readStatistics() ? 'active ' : '') + ' item'}
          >
            Lecture
        </Link>
          : undefined}
        {infos ?
          <Link to={`/text/${currentText.id}/${currentText.infos.urlText}/statistics/compare${queryParams}`}
            className={(this.compareStatistics() ? 'active ' : '') + ' item'}
          >
            Comparaison
        </Link>
          : undefined}
        <div className="stackable right menu">
          <div className="item">
            {premium && infos && infos.parcours && infos.parcours.active ?
              <Link
                  to={`/parcours/${currentText.id}/${infos.urlText}`}
                  className="ui button"
                >
                  Quitter les statistiques
              </Link>
            :
              this.state.originalChapter ?
                <Link
                  to={`/text/${this.props.params.textId}/${this.props.params.urlText}/chapter/${this.state.originalChapter}/edit`}
                  className="ui button"
                >
                  Quitter les statistiques
              </Link>
                :
                <Link
                  to={`/text/${this.props.params.textId}/${this.props.params.urlText}`}
                  className="ui button"
                >
                  Quitter les statistiques
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }
}
