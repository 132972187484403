import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class DefiTextModel {

  @observable id: number;
  @observable title: string;
  @observable urlTitle: string;
  @observable description: string;
  @observable genre: number;
  @observable author: UserModel;
  @observable defiType: number;
  @observable deadline: string;
  @observable active: boolean;
  @observable createdAt: string;

  constructor(id: number, title: string, urlTitle: string, description: string, genre: number,
    author: UserModel, defiType: number, deadline: string,
    active: boolean, createdAt: string) {
    this.id = id;
    this.title = title;
    this.urlTitle = urlTitle;
    this.description = description;
    this.genre = genre;
    this.author = author;
    this.defiType = defiType;
    this.deadline = deadline;
    this.active = active;
    this.createdAt = createdAt;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlTitle: this.urlTitle,
      description: this.description,
      genre: this.genre,
      author: this.author.toJS(),
      defiType: this.defiType,
      deadline: this.deadline,
      active: this.active,
      createdAt: this.createdAt
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new DefiTextModel(object.id, object.title, object.urlTitle, object.description,
        object.genre, UserModel.fromJS(object.author),
        object.defiType, object.deadline, object.active, object.createdAt);
    }
    return undefined;
  }
}
