import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const ReactPlayer = require('react-player');
import VideoModel from '../../../../../models/write/VideoModel';

interface ChapterVideoProps {
  active: boolean;
  close: Function | any;
  video: VideoModel;
  step: boolean;
}

export default class ChapterVideo extends React.Component<ChapterVideoProps, {}> {
  render() {
    const video = this.props.video;
    return (
      <div>
        {video ?
          <Modal onClose={this.props.close} open={this.props.active} size="small">
            <div className="header">{video.name}</div>
            <div className="content">
              <ReactPlayer url={video.url} playing width="100%" />
              <div className="ui message">
              Retrouvez des vidéos et des indications supplémentaires en cliquant sur le bouton Consignes depuis votre éditeur de texte.
              </div>
            </div>
            <div className="actions">
              {this.props.step ?
                <button className="ui primary button" onClick={this.props.close}>Suivant</button>
                :
                <button className="ui cancel button" onClick={this.props.close}>Fermer</button>
              }
            </div>
          </Modal>
          : undefined}
      </div >
    );
  }
}

