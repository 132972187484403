import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';
import TextTalkParams from './TextTalkParams';
import CommentBox from '../comment/CommentBox';
import AlertModel from '../../models/AlertModel';

interface TalkRemoveProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

@inject('store') @observer
class TextRemove extends React.Component<TalkRemoveProps, {}> {
  constructor(props: TalkRemoveProps) {
    super(props);
    this.removeTalk = this.removeTalk.bind(this);
  }
  removeTalk() {
    const talk = this.props.store.mainTalk.talk;
    let meta = this.props.store.text.meta;
    this.props.store.removeDiscussion(talk).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'removeDiscussion',
        'Discussion supprimée !',
        `La discussion ${talk.title} est supprimée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/text/${meta.id}/${meta.urlText}`
      });
    });
  }
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          <p>
            Confirmez-vous la suppression de cette discussion ?
          </p>
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.removeTalk}>Supprimer définitivement</button>
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}

interface TalkProps {
  store?: Store;
}

interface TalkState {
  activeRemove: boolean;
}

@inject('store') @observer
export default class TalkTextPage extends React.Component<TalkProps, TalkState> {
  static fetchData(store: Store, params: TextTalkParams) {
    const p: any[] = [];
    p.push(store.loadMeta(params.textId));
    p.push(store.loadTalk(params.talkId, params.textId, undefined));
    p.push(store.loadTalkMessages(params.talkId));
    p.push(store.loadRelatedTalks(params.talkId));
    return Promise.all(p);
  }
  constructor(props: TalkProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.showRemove = this.showRemove.bind(this);
    this.hideRemove = this.hideRemove.bind(this);
    this.state = {
      activeRemove: false
    };
  }
  showRemove() {
    this.setState({
      activeRemove: true
    });
  }
  hideRemove() {
    this.setState({
      activeRemove: false
    });
  }
  loadMore() {
    this.props.store.loadTalks();
  }
  render() {
    const talk = this.props.store.mainTalk.talk;
    const meta = this.props.store.text.meta;
    const connected = this.props.store.connected;
    const comments = this.props.store.comments;
    const relatedTalks = this.props.store.mainTalk.relatedTalks.map(t => {
      return (
        <div key={'related-' + t.id} className="item">
          <div className="content">
            <Link
              to={`/text/${meta.id}/${meta.urlText}/talks/${t.id}/${t.urlTitle}`}
              className="header">{t.title}
            </Link>
          </div>
        </div>
      );
    });

    const blockedBy = meta && connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(meta.author.id) !== -1;
    const admin = connected && connected.user && connected.user.admin;

    return (
      <div className="ui two column stackable grid" style={{ marginTop: '-30px' }}>
        <div className="ten wide column" style={{ paddingTop: '50px', paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              {talk && meta ?
                <div className="ui container">
                  <Title title={`${talk.title} `} />
                  <h1 className="ui large header">
                    <img src={talk.author.avatarUrl} className="ui image rounded"
                      alt={`Image de profil de ${talk.author.identity}`} />
                    <div className="content">
                      {talk.title}
                      <div className="sub header" style={{ paddingTop: '5px' }}>
                        par <Link to={`/author/${talk.author.id}/${talk.author.urlIdentity}`}>
                          {talk.author.identity}
                        </Link>
                        &nbsp; il y a {talk.createdAtAgo}
                      </div>
                    </div>
                  </h1>
                  <div className="ui basic segment">
                    <div dangerouslySetInnerHTML={{ __html: talk.message }}></div>
                    {connected.user && connected.user.id && meta.author.id === connected.user.id ?
                      <div className="ui basic segment">
                        <TextRemove
                          active={this.state.activeRemove}
                          hide={this.hideRemove}
                        />
                        <div>
                          <button className="ui right floated red mini button"
                            onClick={this.showRemove}>
                            Supprimer
                        </button>
                          <Link to={`/text/${meta.id}/${meta.urlText}/talks/${talk.id}/${talk.urlTitle}/edit`}
                            className="ui right floated orange mini button">
                            Modifier
                        </Link>
                        </div>
                      </div>
                      : undefined}
                  </div>
                </div>
                : undefined}
              {comments && meta ?
                <div>
                  <div className="ui hidden section divider " />
                  {blockedBy ?
                    <div className="ui small message">
                      <Link to={`/author/${meta.author.id}/${meta.author.urlIdentity}`}>
                        {meta.author.identity}</Link> vous a bloqué.
                              &nbsp;Vous ne pouvez pas participer à cette discussion.
                      </div>
                    : undefined}
                  <CommentBox
                    admin={admin}
                    newsfeedElement={false}
                    readonly={blockedBy}
                    comments={this.props.store.comments}
                  />
                </div>
                : undefined}
            </div>
          </div>
        </div>
        <div className="six wide right floated grey column" style={{ paddingTop: '50px', minHeight: '1000px' }}>
          {meta ?
            <div className="ui grid centered stackable">
              <div className="twelve wide column">
                <Link to={`/text/${meta.id}/${meta.urlText}`}
                  className="ui fluid button">
                  Retour à l'œuvre {meta.title}
                </Link>
                {relatedTalks.length > 0 ?
                  <div className="ui basic segment">
                    <h3 className="ui horizontal divider">Discussions sur la même œuvre</h3>
                    <div className="ui list">
                      {relatedTalks}
                    </div>
                  </div>
                  : undefined}
              </div>
            </div>
            : undefined}
        </div>
      </div>
    );
  }
}
