const steps = [
    {
      title: 'Toute l’actualité',
      text: '<p>Votre fil d’actualité vous tient au courant de ce qu’il se passe sur l\'Atelier des Auteurs.<br/>Les membres les plus investis dans la communauté y figurent en bonne place !</p>',
      selector: '#feed_all',
      isFixed: true,
      position: 'bottom'
    },
    {
      title: 'L’actualité de votre réseau',
      text: '<p>Cliquez sur ce panneau pour suivre l’actualité de vos compagnons d’écriture et des œuvres ajoutées à vos listes de lecture.</p>',
      selector: '#feed_mine',
      isFixed: true,
      position: 'bottom'
    },
    {
        title: 'Retour au tableau de bord !',
        text: '<p>Vous pourrez revenir à votre tableau de bord en cliquant sur ce bouton quand vous le souhaitez !</p>',
        selector: '#dashboard',
        isFixed: true,
        position: 'bottom'
    },
    {
      title: 'Menu de navigation',
      text: '<p>Sur mobile ou tablette, vous devez cliquer sur ce menu pour découvrir les options de navigation.</p><p>Cliquez sur le menu avant de continuer !</p>',
      selector: '.bm-burger-button',
      isFixed: true,
      position: 'bottom'
    },
    {
      title: 'Relever un défi',
      text: '<p>Envie de vous entraîner… ou de vous amuser ? Besoin de dépasser un blocage d’écriture ?<br/>Nous avons la meilleure des solutions : sur l\'Atelier des Auteurs, vous pouvez relever des défis littéraires lancés par d’autres auteurs.</p>',
      selector: '#relever_defi_mobile',
      isFixed: true,
      position: 'right'
    },
    {
      title: 'Voici votre espace d’écriture !',
      text: '<p>Vous pourrez gérer vos projets littéraires à partir de cet espace.</p><p>Prenez votre temps : vous publierez votre œuvre publiquement quand vous le souhaiterez !</p><p>D’ici là, vous pourrez l’enrichir de nouveaux chapitres, modifier sa présentation ou même ses règles d’accessibilité.</p>',
      selector: '#write_mobile',
      isFixed: true,
      position: 'right'
    },
    {
      title: 'Voici la bibliothèque de l\'Atelier des Auteurs',
      text: '<p>Vous pourrez retrouver tous les textes publiés par la communauté dans cet espace.</p><p>Il vous permettra aussi de garder un œil sur les textes ajoutés à vos listes de lecture.</p>',
      selector: '#read_mobile',
      isFixed: true,
      position: 'right'
    },
    {
      title: 'Votre messagerie',
      text: '<p>Elle vous permettra de discuter avec d’autres membres.</p>',
      selector: '#messages',
      isFixed: true,
      position: 'bottom'
    },
    {
      title: 'Votre profil',
      text: '<p>Accédez à votre profil et aux paramètres de votre compte en cliquant sur ce bouton.<br/>Nous vous conseillons d’y faire un tour pour embellir votre profil !</p>',
      selector: '#profile',
      isFixed: true,
      position: 'bottom'
    }
  ];
  
  export default steps;
  