import * as React from 'react';

interface ContentEditableProps {
  onChange: any;
  onBlur?: any;
  disabled: boolean;
  html: string;
  tagName?: string;
  placeholder?: string;
  className?: string;
}

interface ContentEditableState {
  className: string;
}

export default class ContentEditable extends React.Component<ContentEditableProps, ContentEditableState> {
  htmlEl: any;
  lastHtml: any;

  constructor(props: ContentEditableProps) {
    super(props);
    this.emitChange = this.emitChange.bind(this);
    this.getClassName = this.getClassName.bind(this);
    this.state = {
      className: this.getClassName(this.props.html)
    };
  }
  getText(el: any) {
    return el.innerText || this.getTextForFirefox(el);
  }
  getTextForFirefox(el: any) {
    let text = '';
    if (window.getSelection) {
      const sel = window.getSelection();
      const tempRange = sel.getRangeAt(0);
      sel.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(el);
      sel.addRange(range);
      text = sel.toString();
      sel.removeAllRanges();
      sel.addRange(tempRange);
    }
    return text;
  }
  emitChange(evt: any) {
    const html = this.getText(evt.target);
    if (this.props.onChange && html !== this.lastHtml) {
      this.props.onChange({ target: { value: html } });
      this.setState({
        className: this.getClassName(html)
      });
    }
    this.lastHtml = html;
  }
  onPaste(ev: any) {
    ev.preventDefault();
    const text = ev.clipboardData.getData('text');
    document.execCommand('insertText', false, text);
  }
  getClassName(html: string) {
    const className = this.props.className ? this.props.className : '';
    const placeholder = html === '' || html === '\u000A'
      ? 'contenteditable-placeholder'
      : '';
    return `contenteditable ${placeholder} ${className}`;
  }
  render() {
    return (
      <ContentEditableDiv
        ref={(e: any) => this.htmlEl = e}
        onInput={this.emitChange}
        onPaste={this.onPaste}
        onBlur={this.props.onBlur || this.emitChange}
        contentEditable={!this.props.disabled}
        dangerouslySetInnerHTML={{ __html: this.props.html }}
        style={{ outline: 'none' }}
        placeholder={this.props.placeholder}
        className={this.state.className}
      />
    );
  }
}

class ContentEditableDiv extends React.Component<{ placeholder: string } & React.HTMLProps<HTMLDivElement>, {}> {
  render() {
    return <div {...this.props}/>;
  }
}