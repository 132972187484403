import { observable } from 'mobx';
import MetaTextModel from './MetaTextModel';
import InfosTextModel from './InfosTextModel';
import ChapterModel from './ChapterModel';
import ChapterVersionModel from './ChapterVersionModel';
import LightChapterModel from './LightChapterModel';
import LightPartModel from './LightPartModel';
import LightStructureModel from './LightStructureModel';
import FullTextModel from '../FullTextModel';
import UserModel from '../UserModel';
import FeedListModel from '../feed/FeedListModel';
import ThankModel from './ThankModel';
import TalkOrCommentSummaryModel from './TalkOrCommentSummaryModel';
import FullCanvasModel from '../canvas/FullCanvasModel';
import CanvasStructureModel from '../canvas/CanvasStructureModel';
import ParcoursModel from '../parcours/ParcoursModel';

export default class TextModel {
  @observable meta: MetaTextModel;
  @observable infos: InfosTextModel;
  @observable thanks: ThankModel[];
  @observable recommandations: FullTextModel[];
  @observable chapter: ChapterModel;
  @observable talkOrCommentSummary: TalkOrCommentSummaryModel[];
  @observable feed: FeedListModel;

  @observable loading: boolean;

  constructor(meta: MetaTextModel, infos: InfosTextModel, thanks: ThankModel[],
    recommandations: FullTextModel[], chapter: ChapterModel,
    talkOrCommentSummary: TalkOrCommentSummaryModel[], feed: FeedListModel) {
    this.meta = meta;
    this.infos = infos;
    this.thanks = thanks || [];
    this.recommandations = recommandations || [];
    this.chapter = chapter;
    this.talkOrCommentSummary = talkOrCommentSummary || [];
    this.feed = feed;
  }

  setMeta(m: MetaTextModel) {
    this.meta = new MetaTextModel(
      m.id, m.title, m.urlText,
      new UserModel(m.author.id, m.author.avatarUrl, m.author.urlIdentity,
        m.author.identity, m.author.admin, m.author.twitter),
      m.published ? new LightStructureModel(
        m.structure.parts.map(p => new LightPartModel(
          p.title,
          p.chapters.map(c => new LightChapterModel(c.id, c.title, c.step, c.read)))
        ),
        m.structure.chapters.map(c => new LightChapterModel(c.id, c.title, c.step, c.read))
      ) : new LightStructureModel([], []),
      m.backgroundColor, m.textColor, m.linkColor, m.sensibleContent, m.openToComments,
      m.openToExport, m.openedToPublishers, m.showOldVersions, m.summary, m.cover, m.published, m.inReadingList, m.pub, m.users
    );
  }

  setInfos(i: InfosTextModel) {
    this.infos = new InfosTextModel(
      i.cover, i.summary, i.beginning, i.datePublication, i.sizePublic, i.wordsPublic, i.sizeAuthor, i.wordsAuthor,
      i.genres, i.tags, i.licence, i.favorites, i.defi, i.structure,
      i.canvas ? new FullCanvasModel(i.canvas.id, i.canvas.title, i.canvas.description, i.canvas.image, [],
        i.canvas.createdAt, i.canvas.updatedAt, new CanvasStructureModel([], []),
        [], i.canvas.currentCharacter) : undefined,
      i.parcours ? new ParcoursModel(i.parcours.id, i.parcours.name, i.parcours.description,
        i.parcours.image, i.parcours.active, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
        undefined, undefined, undefined, undefined,  undefined,  undefined, undefined, undefined, [], undefined, [], undefined, undefined, undefined) : undefined, []);
    this.feed = undefined;
  }

  setTextLikes(users: UserModel[]) {
    this.infos.userFavorites = users;
  }

  setThanks(thanks: ThankModel[]) {
    this.thanks = thanks.map(t => new ThankModel(t.user, t.message));
  }

  /* setRecommandations(recommandations: FullTextModel[]) {
    this.recommandations = recommandations;
  } */

  setChapter(c: ChapterModel) {
    this.chapter = new ChapterModel(
      c.id, c.title, c.textContent, c.words, c.version,
      c.versions.map(cv => new ChapterVersionModel(cv.id, cv.date)),
      c.nextChapter, c.favorites, c.favorited, c.read, c.step, true, []);
  }

  setChapterLikes(users: UserModel[]) {
    this.chapter.userFavorites = users;
  }

  setTalkOrCommentSummary(talkOrCommentSummary: TalkOrCommentSummaryModel[]) {
    this.talkOrCommentSummary = talkOrCommentSummary;
  }

  setFeed(feed: FeedListModel) {
    this.feed = feed;
  }

  fillFeed(feed: FeedListModel) {
    this.feed.elements = this.feed.elements.concat(feed.elements);
    this.feed.empty = feed.empty;
    this.feed.beforeDate = feed.beforeDate;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      meta: this.meta.toJS(),
      infos: this.infos.toJS(),
      thanks: this.thanks.map(t => t.toJS()),
      recommandations: this.recommandations.map(t => t.toJS()),
      chapter: this.chapter.toJS(),
      talkOrCommentSummary: this.talkOrCommentSummary.map(t => t.toJS()),
      feed: this.feed.toJS(),
    };
  }

  static fromJS(object: any) {
    if (object) {
      const meta = MetaTextModel.fromJS(object.meta);
      const infos = InfosTextModel.fromJS(object.infos);
      const thanks = object.thanks.map((t: any) => ThankModel.fromJS(t));
      const recommandations = object.recommandations.map((t: any) => FullTextModel.fromJS(t));
      const chapter = ChapterModel.fromJS(object.chapter);
      const talkOrCommentSummary = object.talkOrCommentSummary.map((t: any) => TalkOrCommentSummaryModel.fromJS(t));
      const feed = FeedListModel.fromJS(object.feed);
      return new TextModel(meta, infos, thanks, recommandations, chapter, talkOrCommentSummary, feed);
    }
    return undefined;
  }
}
