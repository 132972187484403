import * as React from 'react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
const throttle = require('throttle-debounce').throttle;

interface SearchBoxProps {
  store?: Store;
  mobile: boolean;
}

interface SearchBoxState {
  value: string;
}

@inject('store') @observer
export default class SearchBox extends React.Component<SearchBoxProps, SearchBoxState> {
  constructor(props: SearchBoxProps) {
    super(props);
    this.state = { value: '' };
    this.onChange = this.onChange.bind(this);
    this.searchAll = throttle(1000, this.searchAll);
  }
  onChange(e: any) {
    const v = e.target.value;
    if (v.trim().length > 0) {
      this.props.store.initSearch();
      this.searchAll(v);
    } else {
      this.props.store.resetListValues();
    }
    this.setState({
      value: v
    });
  }
  searchAll(value: string) {
    this.props.store.searchAll(value);
  }
  render() {
    const search = this.props.store.search;

    let style: {};
    let className: string;
    if (this.props.mobile) {
      style = { width: '100%' };
      className = '';
    } else {
      style = { border: 'none', padding: '10px', width: '345px' };
      className = 'item hide-tablet';
    }

    const users = search.listValues.filter(v => v.user !== null).map(v => v.user);
    const texts = search.listValues.filter(v => v.text !== null).map(v => v.text);
    const defis = search.listValues.filter(v => v.defi !== null).map(v => v.defi);
    const talks = search.listValues.filter(v => v.talk !== null).map(v => v.talk);

    let items: JSX.Element[] = [];
    if (users.length > 0) {
      items.push(<Dropdown.Header key="h_user" content="Utilisateurs" />);
      users.forEach(u => {
        items.push(<Dropdown.Item
          key={'user_' + u.id}
          text={u.identity}
          value={'user_' + u.id}
          image={{ avatar: true, src: u.avatarUrl }}
          onClick={() => browserHistory.push({
            pathname: `/author/${u.id}/${u.urlIdentity}`
          })}
        />);
      });
    }
    if (texts.length > 0) {
      items.push(<Dropdown.Header key="h_text" content="Œuvres" />);
      texts.forEach(t => {
        items.push(<Dropdown.Item
          key={'text_' + t.id}
          text={t.title}
          value={'text_' + t.id}
          image={{ src: t.id > 3000 ? t.cover : 'https://s3-eu-west-1.amazonaws.com/scribay/assets/nocover.png' }}
          onClick={() => browserHistory.push({
            pathname: `/text/${t.id}/${t.urlText}`
          })}
        />);
      });
    }
    if (defis.length > 0) {
      items.push(<Dropdown.Header key="h_defi" content="Défis" />);
      defis.forEach(d => {
        items.push(<Dropdown.Item
          key={'defi_' + d.id}
          text={d.title}
          value={'defi_' + d.id}
          onClick={() => browserHistory.push({
            pathname: `/defis/defi/${d.id}/${d.urlTitle}`
          })}
        />);
      });
    }
    if (talks.length > 0) {
      items.push(<Dropdown.Header key="h_talk" content="Discussions" />);
      talks.forEach(t => {
        items.push(<Dropdown.Item
          key={'talk_' + t.id}
          text={t.title}
          value={'talk_' + t.id}
          onClick={() => browserHistory.push({
            pathname: `/talks/${t.id}/${t.urlTitle}`
          })}
        />);
      });
    }
    if (search.listValues && search.listValues.length > 0) {
      let cat: string;
      if (texts.length > 0) {
        cat = 'text';
      } else {
        if (defis.length > 0) {
          cat = 'defi';
        } else if (talks.length > 0) {
          cat = 'talk';
        } else {
          cat = 'user';
        }
      }
      if (!search.searching) {
        items.push(
          <Dropdown.Item
            key="more"
            text="Plus de résultats"
            className="centered"
            onClick={() => browserHistory.push({
              pathname: '/search',
              query: {
                text: this.state.value,
                cat: cat
              }
            })}
          />
        );
      }
    } else {
      if (this.state.value && this.state.value.trim().length > 0 && !search.searching) {
        items.push(<Dropdown.Item
          key="none"
          text="Aucun résultat trouvé"
          className="centered"
        />);
      }
    }

    return (
      <div style={style} className={className}>
        <Dropdown
          scrolling={false}
          fluid
          search
          placeholder="Rechercher..."
          onSearchChange={this.onChange}
          loading={search.searching}
          className="selection small"
          aria-label="Rechercher"
        >
          <Dropdown.Menu>
            {items}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
