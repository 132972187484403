import * as React from 'react';

export default class AlignLeftIcon extends React.Component<{}, {}> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <title>AlignLeft</title>
        <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z" fill="currentColor" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
