import { observable } from 'mobx';
import CanvasPartModel from './CanvasPartModel';
import CanvasChapterModel from './CanvasChapterModel';

export default class CanvasStructureModel {

  @observable parts: CanvasPartModel[];
  @observable chapters: CanvasChapterModel[];

  constructor(parts: CanvasPartModel[], chapters: CanvasChapterModel[]) {
    this.parts = parts || [];
    this.chapters = chapters || [];
  }

  toJS() {
    return {
      parts: this.parts.map(p => p.toJS()),
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const parts = object.parts.map((p: any) => CanvasPartModel.fromJS(p));
      const chapters = object.chapters.map((c: any) => CanvasChapterModel.fromJS(c));
      return new CanvasStructureModel(parts, chapters);
    }
    return undefined;
  }
}
