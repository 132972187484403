import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
const InfiniteScroll = require('react-infinite-scroll-component');
import Store from '../../stores/Store';
import TextGallery from '../misc/text/TextGallery';
import ProfileParams from './ProfileParams';

interface ProfileTextsProps {
  store: Store;
  params: ProfileParams;
}

interface ProfileTextsState {
  loadingProfileTextsError: boolean;
  loadingProfileTexts: boolean;
}

@inject('store') @observer
export default class ProfileTexts extends React.Component<ProfileTextsProps, ProfileTextsState> {
  static fetchData(store: Store, params: ProfileParams) {
    store.profile.page = 0;
    store.profile.end = false;
    store.profile.action = 'view';
    store.profile.period = 'alltime';
    return store.loadProfileTexts(params.authorId);
  }
  constructor(props: ProfileTextsProps) {
    super(props);
    this.changeAction = this.changeAction.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      loadingProfileTextsError: false,
      loadingProfileTexts: false
    };
  }
  @action
  changeAction(action: string) {
    const profile = this.props.store.profile;
    profile.action = action;
    profile.page = 0;
    profile.end = false;
    this.props.store.loadProfileTexts(profile.id);
  }
  @action
  changePeriod(period: string) {
    const profile = this.props.store.profile;
    profile.period = period;
    profile.page = 0;
    profile.end = false;
    this.props.store.loadProfileTexts(profile.id);
  }

  loadMoreTexts() {
    const profile = this.props.store.profile;
    this.props.store.loadProfileTexts(profile.id, (failed: boolean)=>{
      this.setState(
      {
        loadingProfileTextsError: failed,
        loadingProfileTexts: false
      }
    )
  });
  }

  loadMore() {
    this.setState({
      loadingProfileTexts: true
    },
    () => {this.loadMoreTexts()}    
    );
  }

  render() {
    const profile = this.props.store.profile;
    const endMessage = () => {
      let message;
      let error;
      if (!profile.end){
        if (this.state.loadingProfileTexts){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingProfileTextsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid" style={{ marginBottom: '30px'}}>{error}{message}</div>

    }
    return (
      <div className="ui container" style={{ paddingTop: '50px' }}>
        <div className="ui form">
          <div className="inline fields">
            <div className="field">
              <Dropdown
                selection
                value={profile.action}
                onChange={(e: any, { value }) => this.changeAction(String(value))}
                options={[
                  {
                    value: 'recent',
                    text: 'Les plus récentes'
                  },
                  {
                    value: 'view',
                    text: 'Les plus lues'
                  },
                  {
                    value: 'rating',
                    text: 'Les plus appréciées'
                  },
                  {
                    value: 'comment',
                    text: 'Les plus commentées'
                  }
                ]}
              />
            </div>
            <div className="field">
              {profile.action === 'view' || profile.action === 'rating' || profile.action === 'comment' ?
                <Dropdown
                  selection
                  value={profile.period}
                  onChange={(e: any, { value }) => this.changePeriod(String(value))}
                  options={[
                    {
                      value: 'week',
                      text: 'de la semaine'
                    },
                    {
                      value: 'month',
                      text: 'du mois'
                    },
                    {
                      value: 'year',
                      text: 'de l\'année'
                    },
                    {
                      value: 'alltime',
                      text: 'de toujours'
                    }
                  ]}
                />
                : undefined}
            </div>
          </div>
        </div>
        <InfiniteScroll
          next={this.loadMore}
          hasMore={false}
          loader={<h4>Chargement...</h4>}
          endMessage={endMessage()}
        >
          <div style={{ padding: '20px' }}>
            <TextGallery texts={profile.texts} />
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
