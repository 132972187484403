import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Store from '../../../../../stores/Store';
import ParcoursSummary from '../../../choose/ParcoursSummary';
import ParcoursSummaryContentModel from '../../../../../models/parcours/ParcoursSummaryContentModel';

interface NewPartSummaryProps {
  store?: Store;
  active: boolean;
  showNextModal: Function;
}

@inject('store') @observer
export default class NewPartSummary extends React.Component<NewPartSummaryProps, {}> {
  constructor(props: NewPartSummaryProps) {
    super(props);
    this.saveAndHide = this.saveAndHide.bind(this);
    this.onSummaryPartChange = this.onSummaryPartChange.bind(this);
  }
  saveAndHide() {
    this.props.store.saveTextParcoursSummary(this.props.store.write.currentText.id,
      this.props.store.write.currentText.currentChapter.id).then((d: any) => {
        this.props.showNextModal();
      });
  }
  onSummaryPartChange(content: ParcoursSummaryContentModel, value: string) {
    this.props.store.write.currentText.infos.parcoursSummary.part.contents.filter((c: any) => c.index ===
      content.index)[0].body = value;
  }
  render() {
    const parcoursId = this.props.store.write.currentText.infos.parcours.id;
    const parcoursSummary = this.props.store.write.currentText.infos.parcoursSummary;
    return (
      <div>
        <Modal
          open={this.props.active}
          size="small"
          onClose={() => { }}
        >
          <div className="header">Résumé</div>
          <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            <div className="ui info message">
              <div className="ui header">Voici le résumé de cette nouvelle partie.</div>
              Changez les résumés de chapitres que vous avez déjà en tête et laissez les autres tels quels, vous pourrez y revenir plus tard !
          </div>
            {parcoursSummary && parcoursSummary.part ?
              <div className="ui basic segment">
                <ParcoursSummary parcoursId={parcoursId} summary={parcoursSummary.part}
                  summaryExamples={[]}
                  onSummaryChange={this.onSummaryPartChange} />
              </div>
              : undefined}
          </div>
          <div className="actions">
            <button className="ui primary button" onClick={this.saveAndHide}>Suivant</button>
          </div>
        </Modal>
      </div>
    );
  }
}
