import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import QuestionModel from '../../../../../models/write/QuestionModel';

interface ChapterQuestionsProps {
  active: boolean;
  questions: QuestionModel[];
  save: Function | any;
  close: Function | any;
  handleAnswerQuestion: Function;
}

export default class ChapterEndChecks extends React.Component<ChapterQuestionsProps, {}> {
  render() {
    const questions = this.props.questions;
    const notAnswered = questions.filter(c => !c.answerId).length > 0;
    const allQuestions = questions.map(question => {
      const values = question.answers.map(answer => {
        return (
          <div key={`question_answer_${answer.id}`} className="field">
            <Checkbox
              radio
              checked={answer.id === question.answerId}
              onClick={() => this.props.handleAnswerQuestion(question.id, answer.id)}
              label={answer.label}
              />
          </div>
        );
      });
      return (
        <div key={`question_${question.id}`} className="grouped fields">
          <label>{question.label}</label>
          {values}
        </div>
      );
    });
    return (
      <div>
        <Modal onClose={this.props.close} open={this.props.active} size="small">
          <div className="content">
            <div className="ui form">
              {allQuestions}
            </div>
          </div>
          <div className="actions">
            {!notAnswered ?
              <button className="ui primary button" onClick={this.props.save}>Valider</button>
              : undefined}
            <button className="ui cancel button" onClick={this.props.close}>Fermer</button>
          </div>
        </Modal>
      </div>
    );
  }
}

