import { observable } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import convertFromHtml from '../../components/editor/convertFromHtml';
import convertToHtml from '../../components/editor/convertToHtml';

class VeryLightText {
  id: number;
  title: string;
  urlText: string;
}

declare const __CLIENT__: any;

export default class ParcoursPrologueModel {

  @observable id: number;
  @observable parcoursTextId: number;
  @observable index: number;
  @observable last: boolean;
  @observable text: VeryLightText;
  @observable chapterId: number;
  @observable title: string;
  @observable description: string;
  @observable value: string;
  @observable previous: number;
  @observable next: number;
  @observable textContentState: any;

  constructor(id: number, parcoursTextId: number, index: number, last: boolean, text: VeryLightText,
    chapterId: number, title: string, description: string, value: string, previous: number, next: number) {
    this.id = id;
    this.parcoursTextId = parcoursTextId;
    this.index = index;
    this.last = last;
    this.text = text;
    this.chapterId = chapterId;
    this.title = title;
    this.description = description;
    this.value = value;
    this.previous = previous;
    this.next = next;

    if (__CLIENT__) {
      const contentState = convertFromHtml(value);
      this.textContentState = EditorState.createWithContent(contentState);
    }
  }

  updateTextContentState(newTextContentState: any) {
    this.textContentState = newTextContentState;
  }

  getHTML() {
    return convertToHtml(this.textContentState);
  }

  toJS() {
    return {
      id: this.id,
      parcoursTextId: this.parcoursTextId,
      index: this.index,
      last: this.last,
      text: this.text,
      chapterId: this.chapterId,
      title: this.title,
      description: this.description,
      value: this.value,
      previous: this.previous,
      next: this.next
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursPrologueModel(object.id, object.parcoursTextId, object.index, object.last, object.text,
        object.chapterId, object.title, object.description, object.value, object.previous, object.next);
    }
    return undefined;
  }
}
