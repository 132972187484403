import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import * as bowser from 'bowser';
import Store from '../../stores/Store';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import './customDatePickerWidth.css'
var DatePicker = require('react-datepicker');
var moment = require('moment');
 
require('react-datepicker/dist/react-datepicker.css');

interface SignUpProps {
  store?: Store;
  location: {
    query: {
      p: number;
    }
  };
}


interface SignUpState {
  username: string;
  usernameError: boolean;
  email: string;
  emailError: boolean;
  password: string;
  passwordError: boolean;
  confirmPassword: string;
  confirmPasswordError: boolean;
  strongPasswordError: boolean;
  matchingPasswordsError: boolean;
  usernameOrPasswordExistsError: boolean;
  invalidEmail: boolean;
  unknownError: boolean;
  loading: boolean;
  CGUNotChecked: boolean;
  CGUError: boolean;
  birthDate: Date;
  birthDateError: boolean;
  day: number;
  month: number;
  year: number;
  dayError: boolean;
  monthError: boolean;
  yearError: boolean;
}

@inject('store') @observer
export default class SignUp extends React.Component<SignUpProps, SignUpState> {
  constructor(props: SignUpProps) {
    super(props);
    this.state = {
      username: '',
      usernameError: false,
      email: '',
      emailError: false,
      password: '',
      passwordError: false,
      confirmPassword: '',
      confirmPasswordError: false,
      strongPasswordError: false,
      matchingPasswordsError: false,
      usernameOrPasswordExistsError: false,
      invalidEmail: false,
      unknownError: false,
      loading: false,
      CGUNotChecked: true,
      CGUError: false,
      birthDate: undefined,
      birthDateError: false,
      day: undefined,
      dayError: false,
      month: undefined,
      monthError: false,
      year: undefined,
      yearError: false,
    };
    this.goToLogin = this.goToLogin.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.postForm = this.postForm.bind(this);
    this.handleConfirmCGUChange = this.handleConfirmCGUChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }
  componentDidMount() {
    if (this.props.store.paymentTunnel.product == undefined && this.props.location.query.p !== undefined) {
      this.props.store.loadPaymentTunnelProduct(this.props.location.query.p)
    }
  }
  goToLogin() {
    this.props.store.paymentTunnel.product ? 
    browserHistory.push({
      pathname: '/login',
      search: '?p='+this.props.store.paymentTunnel.product.id
    })
    :
    browserHistory.push({
      pathname: '/login'
    });
  }
  handleDayChange(e: any) {
    if (e.target.value < 1 || e.target.value > 31){
      this.setState({
        dayError: true,
        day: e.target.value
      })
      this.handleBirthDateChange(e.target.value, this.state.month, this.state.year)
    }
    else {
      this.setState({
        dayError: false,
        day: e.target.value
      })
      this.handleBirthDateChange(e.target.value, this.state.month, this.state.year)
    }
  }
  handleMonthChange(e: any) {
    if (e.target.value < 1 || e.target.value > 12){
      this.setState({
        monthError: true,
        month: e.target.value
      })
      this.handleBirthDateChange(this.state.day, e.target.value, this.state.year)
    }
    else {
      this.setState({
        monthError: false,
        month: e.target.value
      })
      this.handleBirthDateChange(this.state.day, e.target.value, this.state.year)
    }
  }
  handleYearChange(e: any) {
    if (e.target.value < 1900 || e.target.value > 2022){
      this.setState({
        yearError: true,
        year: e.target.value
      })
      this.handleBirthDateChange(this.state.day, this.state.month, e.target.value)
    }
    else {
      this.setState({
        yearError: false,
        year: e.target.value
      })
      this.handleBirthDateChange(this.state.day, this.state.month, e.target.value)
    }
  }
  handleConfirmCGUChange(e: any) {
    this.setState({
      ...this.state,
      CGUNotChecked: !this.state.CGUNotChecked
    })
  }
  padLeadingZeros(num: number, size: number) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }
  handleBirthDateChange(day?: number, month?: number, year?: number) {
    let dayString = day ? day : this.state.day;
    let monthString = month ? month : this.state.month;
    let yearString = year ? year : this.state.year;
    this.setState({
      birthDate: moment(''+this.padLeadingZeros(dayString, 2)+'-'+this.padLeadingZeros(monthString, 2)+'-'+yearString,'DD-MM-YYYY', true)
    })
  }
  handleUsernameChange(e: any) {
    const usernameError = this.state.usernameError && e.target.value.trim().length === 0;
    this.setState({
      username: e.target.value,
      usernameError: usernameError,
      email: this.state.email,
      emailError: this.state.emailError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      confirmPassword: this.state.confirmPassword,
      confirmPasswordError: this.state.confirmPasswordError,
      strongPasswordError: this.state.strongPasswordError,
      matchingPasswordsError: this.state.matchingPasswordsError,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  handleEmailChange(e: any) {
    const emailError = this.state.emailError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      email: e.target.value,
      emailError: emailError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      confirmPassword: this.state.confirmPassword,
      confirmPasswordError: this.state.confirmPasswordError,
      strongPasswordError: this.state.strongPasswordError,
      matchingPasswordsError: this.state.matchingPasswordsError,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  handlePasswordChange(e: any) {
    const passwordError = this.state.passwordError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      email: this.state.email,
      emailError: this.state.emailError,
      password: e.target.value,
      passwordError: passwordError,
      confirmPassword: this.state.confirmPassword,
      confirmPasswordError: this.state.confirmPasswordError,
      strongPasswordError: this.state.strongPasswordError,
      matchingPasswordsError: false,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  handleConfirmPasswordChange(e: any) {
    const confirmPasswordError = this.state.confirmPasswordError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      email: this.state.email,
      emailError: this.state.emailError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      confirmPassword: e.target.value,
      confirmPasswordError: confirmPasswordError,
      strongPasswordError: this.state.strongPasswordError,
      matchingPasswordsError: false,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  postForm(event: any) {
    event.preventDefault();
    const usernameError = this.state.username.trim().length === 0;
    const emailError = this.state.email.trim().length === 0 || !this.validateEmail(this.state.email);
    const passwordError = this.state.password.trim().length === 0;
    const confirmPasswordError = this.state.confirmPassword.trim().length === 0;
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const strongPasswordError = this.state.password !== undefined
      && this.state.password.length !== 0 && !strongPassword.test(this.state.password);
    const cguError = this.state.CGUNotChecked === true;
    const matchingPasswordsError = !passwordError && !confirmPasswordError &&
      this.state.password !== this.state.confirmPassword;
    const birthDateError = moment().diff(this.state.birthDate, 'years', true) < '16';
    if (!usernameError && !emailError && !passwordError
      && !confirmPasswordError && !matchingPasswordsError 
      && !strongPasswordError && !cguError && !birthDateError && 
      !this.state.dayError && !this.state.monthError && !this.state.yearError) {
      this.setState({
        loading: true
      });
      this.props.store.signUp(
        this.state.username, this.state.email, this.state.password, this.state.birthDate
      ).then((r: any) => {
        if (this.props.store.paymentTunnel.registering)
          window.location.href = '/paymenthub?p='+this.props.store.paymentTunnel.product.id;
        else
          window.location.href = '/?m=w';
      }, (err: any) => {
        if (err.status === 409) {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: '',
            passwordError: false,
            confirmPassword: '',
            confirmPasswordError: false,
            strongPasswordError: false,
            matchingPasswordsError: false,
            usernameOrPasswordExistsError: true,
            invalidEmail: this.state.invalidEmail,
            unknownError: this.state.unknownError,
            CGUError: false,
            birthDateError: false,
            loading: false
          });
        } else if (err.status === 403) {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: '',
            passwordError: false,
            confirmPassword: '',
            confirmPasswordError: false,
            strongPasswordError: false,
            matchingPasswordsError: false,
            usernameOrPasswordExistsError: false,
            invalidEmail: true,
            unknownError: this.state.unknownError,
            CGUError: false,
            birthDateError: false,
            loading: false
          });
        } else {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: '',
            passwordError: false,
            confirmPassword: '',
            confirmPasswordError: false,
            strongPasswordError: false,
            matchingPasswordsError: false,
            usernameOrPasswordExistsError: false,
            invalidEmail: this.state.invalidEmail,
            unknownError: true,
            CGUError: false,
            birthDateError: false,
            loading: false
          });
        }
      });
    } else {
      this.setState({
        username: this.state.username,
        usernameError: usernameError,
        email: this.state.email,
        emailError: emailError,
        password: this.state.password,
        passwordError: passwordError,
        confirmPassword: this.state.confirmPassword,
        confirmPasswordError: confirmPasswordError,
        strongPasswordError: strongPasswordError,
        matchingPasswordsError: matchingPasswordsError,
        usernameOrPasswordExistsError: false,
        invalidEmail: this.state.invalidEmail,
        unknownError: this.state.unknownError,
        CGUError: cguError,
        birthDateError: birthDateError,
        loading: false
      });
    }
  }
  render() {
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const passwordError = this.state.password !== undefined
      && this.state.password.length !== 0 && !strongPassword.test(this.state.password);
    const longPassword = new RegExp('(?=.{8,})');
    const upperCasePassword = new RegExp('(?=.*[A-Z])');
    const lowerCasePassword = new RegExp('(?=.*[a-z])');
    const digitPassword = new RegExp('(?=.*[0-9])');
    const specialCharacterPassword = new RegExp('([^A-Za-z0-9])');
    const paymentTunnel = this.props.store.paymentTunnel;
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <Title title="Inscription " />
        <div className="ui text container" style={{ paddingTop: '50px', paddingBottom: '80px',  minHeight: '90vh' }}>
          <div className={'ui stackable grid'}>
            <div className={'ten wide column'}>
              <h1 className="ui header">Créer un compte</h1>
            </div>
          </div>
          <div className={' ui stackable grid'}>
            <div className={'ten wide column'}>
              {(bowser.msie && Number(bowser.version) < 11) || (bowser.safari && Number(bowser.version) < 9) ?
                <div className="ui warning message">
                  <div className="header">Oh non, votre navigateur n'est pas compatible !</div>
                  <p>L'Atelier des Auteurs repose sur des technologies modernes afin de proposer une expérience riche et fluide.</p>
                  <p>Nous vous invitons à utiliser une version récente de <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/fr/firefox/new/">Mozilla Firefox</a> ou <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">Google Chrome</a>.</p>
                </div>
                :
                <div className="ui basic segment">
                  {/* <a href="/auth/login/facebook" className="ui facebook fluid button" >
                    <i className="fa fa-fw fa-facebook"></i> Continuer avec Facebook
                </a> */}
                {this.props.store.paymentTunnel.registering ?
                      <a href={"/auth/login2/facebook/"+this.props.store.paymentTunnel.product.id} className="ui facebook fluid button" >
                        <i className="fa fa-fw fa-facebook"></i> Continuer avec Facebook
                      </a>
                    :
                      <a href="/auth/login/facebook" className="ui facebook fluid button" >
                        <i className="fa fa-fw fa-facebook"></i> Continuer avec Facebook
                      </a>
                  }
                  <div className="ui horizontal divider">ou</div>
                  <form onSubmit={this.postForm} className="ui form" style={{ overflow: 'auto' }}>
                    {this.state.usernameError || this.state.emailError
                      || this.state.passwordError || this.state.confirmPasswordError ?
                      <div className="ui error message">
                        <div className="header">Les champs marqués en rouge sont obligatoires.</div>
                      </div>
                      : (this.state.matchingPasswordsError ?
                        <div className="ui error message">
                          <div className="header">Le mot de passe et sa confirmation sont différents.</div>
                        </div>
                        : (passwordError ?
                            <div className="ui error message">
                              <div className="header">Le mot de passe ne remplit pas les prérequis :</div>
                              {longPassword.test(this.state.password) ? undefined : 
                                <div>- La longueur minimale du mot de passe est de 8 caractères.</div>
                              }
                              {upperCasePassword.test(this.state.password) ? undefined : 
                                <div>- Le mot de passe doit comporter au moins une majuscule.</div>
                              }
                              {lowerCasePassword.test(this.state.password) ? undefined : 
                                <div>- Le mot de passe doit comporter au moins une minuscule.</div>
                              }
                              {digitPassword.test(this.state.password) ? undefined : 
                                <div>- Le mot de passe doit comporter au moins un chiffre.</div>
                              }
                              {specialCharacterPassword.test(this.state.password) ? undefined : 
                                <div>- Le mot de passe doit comporter au moins un caractère spécial.</div>
                              }
                          </div>
                          : (this.state.usernameOrPasswordExistsError ?
                            <div className="ui error message">
                              <div className="header">L'identifiant ou l'email existe déjà.</div>
                            </div>
                            : (this.state.invalidEmail ?
                              <div className="ui error message">
                                <div className="header">L'email est invalide.</div>
                              </div>
                              : (this.state.unknownError ?
                                <div className="ui error message">
                                  <div className="header">Une erreur s'est produite. Veuillez réessayer.</div>
                                </div>
                                : (this.state.CGUError ?
                                  <div className="ui error message">
                                    <div className="header">Vous devez adhérer aux CGU.</div>
                                  </div>
                                  : (this.state.birthDateError ?
                                    <div className="ui error message">
                                      <div className="header">Vous devez avoir 16 ans ou plus pour vous inscrire.</div>
                                    </div>
                                    : undefined
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    }
                    <div className={'field' + (this.state.usernameError ? ' error' : '')}>
                      <label>Nom d'utilisateur</label>
                      <input
                        type="text"
                        placeholder="Nom d'utilisateur"
                        value={this.state.username}
                        onChange={this.handleUsernameChange}
                      />
                    </div>
                    <div className={'field' + (this.state.emailError ? ' error' : '')}>
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                      />
                      {this.state.emailError ? <label>L'email est invalide.</label> : undefined}
                    </div>
                    <div className={'field' + (this.state.birthDateError ? ' error' : '')}>
                      <label>Date de naissance</label>
                      {this.state.dayError || this.state.monthError || this.state.yearError ? 
                        <div className="ui negative message">La date de naissance doit être au format jj/mm/aaaa</div>
                      : undefined}
                      <div>
                        <div className='ui grid' style={{marginRight: "0px"}}>
                          <span className="five wide column">
                            <input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="number"
                              min="1"
                              max="31"    
                              value={this.state.day}
                              onChange={this.handleDayChange}        
                              placeholder="Jour"
                            />
                          </span>
                          <span className="five wide column">
                            <input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="number"
                              min="1"
                              max="12"
                              value={this.state.month}
                              onChange={this.handleMonthChange}
                              placeholder="Mois"
                            />
                          </span>
                          <span className="six wide column">
                            <input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              type="number"
                              min="1900"
                              max="2022"
                              value={this.state.year}
                              onChange={this.handleYearChange}
                              placeholder="Année"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={'field' + (this.state.passwordError ? ' error' : '')}>
                      <label>Mot de passe</label>
                      <input
                        type="password"
                        placeholder="Mot de passe"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                      />
                    </div>
                    <div className={'field' + (this.state.confirmPasswordError ? ' error' : '')}>
                      <label>Confirmez votre mot de passe</label>
                      <input
                        type="password"
                        placeholder="Confirmation du mot de passe"
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                      />
                    </div>
                  <div className="ui basic" style={{marginTop: "1rem"}}>
                    <p>Déjà membre de l'Atelier des auteurs ?&nbsp;
                      <a onClick={this.goToLogin} style={{ cursor: "pointer", fontWeight: 'bold' }}>Connexion</a>
                    </p>
                    <p>Les données collectées ci-avant sont traitées par l’Atelier des Auteurs afin de prendre en compte votre inscription, gérer vos achats et la relation client. 
                      Pour en savoir plus sur les modalités de traitement de vos données, veuillez consulter notre <a href="/privacy" style={{ fontWeight: 'bold' }}> Politique de confidentialité.</a></p>
                    <Checkbox
                    checked={!this.state.CGUNotChecked}
                    onClick={this.handleConfirmCGUChange}
                    label={<label>En rejoignant l'Atelier des auteurs, vous acceptez nos <a href="/cgu" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold' }}>
                            Conditions Générales d'Utilisation</a>.
                          </label>}
                    />
                  </div>
                  <button
                      className={'ui submit primary right floated ' + (this.state.loading ? ' loading' : '') + ' button'}
                      style={{marginTop: "10px"}}
                      type="submit">
                      Créer mon compte
                    </button>
                  </form>
                </div>
              }
            </div>
            {this.props.store.paymentTunnel.registering ?
            <div className="six wide column">
              <div className="ui segment">
                <h3 style={{paddingBottom: "5px", borderBottom: "solid 1px"}} className="ui header">{paymentTunnel.product.name}</h3>
                <div className="ui relaxed list">
                  <div className="ui two column grid">
                  <div className="item column float left">
                    <div className="content">Prix de l'article</div>
                  </div>
                  <div className="item column float right">
                    <div style={{textAlign: "right"}} className="content">{Math.round(paymentTunnel.product.currentPrice/1.2)} €</div>
                  </div>
                  <div className="ui row" style={{marginTop: "-10px"}}>
                    <div className="item column float left">
                      <div className="content">TVA <span style={{fontSize: "0.8rem"}}>20%</span></div>
                    </div>
                    <div className="item column float right">
                      <div style={{textAlign: "right"}} className="content">{Math.round(paymentTunnel.product.currentPrice/1.2*0.2)} €</div>
                    </div>
                  </div>
                  <div className="ui row ada-background-editis-beige-foncé ada-text-editis-blanc">
                    <div className="item column float left">
                      <div className="content">Total TTC</div>
                    </div>
                    <div className="item column float right">
                      <div style={{fontSize: "1.4rem", textAlign: "right"}} className="content">{paymentTunnel.product.currentPrice} €</div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : 
            
            <div className="six wide column">
              <div className="ui segment">
                <h3 className="ui header">Inscription gratuite</h3>
                <div>
                  <p>Cette inscription gratuite vous permet d'accéder à toute la communauté de l'Atelier des Auteurs, 
                    qui comprend les espaces de publication, d'échange et de partage de la plateforme.</p>

                  <p>Vous pourrez rejoindre nos ateliers d'écriture (payants) plus tard si vous le souhaitez.</p>
                </div>
              </div>
            </div>
            
            
            }
          </div>
        </div>
      </div>
    );
  }
}
