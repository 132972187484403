import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Title from '../../../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../../../stores/Store';
import Sidebar from '../../../misc/Sidebar';
import TrainingEndModal from './TrainingEndModal';
import ChapterParams from '../ChapterParams';
import { convertHtmlToText } from '../../../../utils/relecture/lisibilite';
import { countWords } from '../../../../utils/utils';
import forbiddenVerbs from '../../../../utils/relecture/forbiddenVerbs';
import TrainingConstraint from './TrainingConstraint';

interface TrainingToolbarProps {
  store?: Store;
}

interface TrainingToolbarState {
  activeConfirm: boolean;
  activeValidError: boolean;
}

@inject('store') @observer
export default class TrainingToolbar extends React.Component<TrainingToolbarProps, TrainingToolbarState> {
  constructor(props: TrainingToolbarProps) {
    super(props);
    this.validText = this.validText.bind(this);
    this.hideValidError = this.hideValidError.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);

    this.state = {
      activeConfirm: false,
      activeValidError: false
    };
  }
  validText() {
    const training = this.props.store.write.training;
    if (training.instructions.constraint) {
      const constraint = training.instructions.constraint;

      const textHtml = this.props.store.write.training.getHTML();
      const text = convertHtmlToText(textHtml);
      let confirm = false;
      if (constraint.mandatoryWords) {
        confirm = constraint.mandatoryWords.every((w: string) => text.indexOf(w) !== -1);
      } else if (constraint.forbiddenVerbs) {
        const verbs: string[] = [];
        let allVerbs: string[] = [];
        constraint.forbiddenVerbs.forEach((verb) => {
          allVerbs = allVerbs.concat((forbiddenVerbs as any)[verb]);
        });

        const regex = new RegExp(`(?:^|[^a-zA-Z0-9À-ÿ]+)(${allVerbs.join('|')})(?:$|[^a-zA-Z0-9À-ÿ]+)`, 'gi');
        let matchArr: RegExpExecArray;
        while ((matchArr = regex.exec(text)) !== null) {
          verbs.push(matchArr[0]);
        }

        confirm = verbs.length === 0;
      } else if (constraint.incipit) {
        confirm = text.trim().lastIndexOf(constraint.incipit.text, 0) === 0;
      } else if (constraint.explicit) {
        confirm = text.trim().indexOf(constraint.explicit.text, text.length - constraint.explicit.text.length) !== -1;
      } else if (constraint.length) {
        const wrongSentences = [];
        const regex = /[^\.!\?]+[\.!\?]+/g;
        let matchArr: RegExpExecArray;

        while ((matchArr = regex.exec(text)) !== null) {
          const wc = countWords(matchArr[0]);
          if ((constraint.length.min && wc < constraint.length.min)
            || (constraint.length.max && wc > constraint.length.max)) {
            wrongSentences.push(matchArr[0]);
          }
        }

        confirm = wrongSentences.length === 0;
      }

      if (confirm) {
        this.setState({
          activeConfirm: true
        });
      } else {
        this.setState({
          activeValidError: true
        });
      }
    } else {
      this.setState({
        activeConfirm: true
      });
    }
  }
  hideValidError() {
    this.setState({
      activeValidError: false
    });
  }
  hideConfirm() {
    this.setState({
      activeConfirm: false
    });
  }
  render() {
    const training = this.props.store.write.training;

    let body: JSX.Element;
    if (training) {
      body = <div className="ui secondary menu text-bar" style={{ overflowX: 'scroll', overflow: 'auto' }}>
        <TrainingEndModal
          active={this.state.activeConfirm}
          endTimer={false}
          cancel={this.hideConfirm}
        />
        {training.instructions && training.instructions.constraint ?
          <Modal
            open={this.state.activeValidError}
            onClose={this.hideValidError}
            size="small"
          >
            <div className="header">La contrainte n'est pas respectée</div>
            <div className="ui content">
              <TrainingConstraint constraint={training.instructions.constraint} />
            </div>
            <div className="actions">
              <button className="ui cancel button" onClick={this.hideValidError}>Fermer</button>
            </div>
          </Modal>
          : undefined}
        <Title title="Entraînement" />
        <Sidebar writeText />
        <div className="item text-title" style={{ paddingLeft: '50px' }}>
          Entraînement
        </div>
        <div className="ui right item">
          <button className="ui basic green button" onClick={() => this.validText()}>J'ai terminé !</button>
        </div>
      </div>;
    } else {
      body = <div className="ui secondary container menu text-bar"
        style={{
          paddingLeft: '50px'
        }}
      />;
    }
    return body;
  }
}
