import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Store from '../../stores/Store';

interface QuestionnaireProps {
  store?: Store;
}

interface QuestionnaireState {
  open: boolean;
}

@inject('store') @observer
export default class Questionnaire extends React.Component<QuestionnaireProps, QuestionnaireState> {
  constructor(props: QuestionnaireProps) {
    super(props);
    this.state = {
      open: false
    };
    this.open = this.open.bind(this);
  }
  open() {
    this.setState({
      open: true
    });
  }
  render() {
    const questionnaire = this.props.store.profile.questionnaire;
    const connected = this.props.store.connected;
    const profile = this.props.store.profile.user;

    let body: JSX.Element;
    if (this.state.open) {
      const questions = questionnaire.map(q =>
        <div key={q.index} className="item">
          <div className="header">
            <FormattedMessage
              id={`questionnaire.${q.index}`}
              defaultMessage="Question"
            />
          </div>
          <p>{q.answer}</p>
        </div>
      );
      body =
        <div className="ui read container">
          <div>
            <div className="ui relaxed large list">
              {questions}
            </div>
            {connected && connected.user && String(connected.user.id) === String(profile.id) ?
              <Link to={`/author/${connected.user.id}/${connected.user.urlIdentity}/questionnaire`}
                className="ui right floated button">
                Répondre
             </Link>
              : undefined
            }
          </div>
        </div>;
    } else if (questionnaire.length > 0) {
      body = <div>
        <h1 className="ui large header">
          <FormattedMessage
            id="questionnaire.0"
            defaultMessage="Question"
          />
        </h1>
        <div className="ui text left aligned container" style={{ marginBottom: '10px' }}>
          {questionnaire[0].answer}
        </div>
        {connected && connected.user && String(connected.user.id) === String(profile.id) ?
          <Link to={`/author/${connected.user.id}/${connected.user.urlIdentity}/questionnaire`}
            className="ui small orange right floated button">
            Répondre
             </Link>
          : undefined
        }
        <button
          className="ui small right floated button"
          onClick={this.open}
        >
          Voir plus
        </button>
      </div>;
    }

    return (
      <div>
        {questionnaire && questionnaire.length > 0 ?
          body
          : (connected && connected.user && String(connected.user.id) === String(profile.id) ?
            <div>
              <div className="ui warning message">
                Vous n'avez pas encore répondu au Questionnaire de l'Atelier des auteurs.
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Link to={`/author/${connected.user.id}/${connected.user.urlIdentity}/questionnaire`}
                    className="ui small orange button">
                    Répondre
                 </Link>
                </div>
              </div>
            </div>
            : undefined)
        }
      </div>
    );
  }
}
