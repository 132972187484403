import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Loader from '../misc/Loader';
import Store from '../../stores/Store';

interface ChaptersToPublishProps {
  store?: Store;
  showChaptersToPublish: boolean;
  confirmChaptersToPublish: Function;
  cancelChaptersToPublish: Function;
}

interface ChaptersToPublishState {
  selectedChapters: number[];
  selectedAll: boolean;
}

@inject('store') @observer
export default class ChaptersToPublish extends React.Component<ChaptersToPublishProps, ChaptersToPublishState> {
  constructor(props: ChaptersToPublishProps) {
    super(props);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.isChapterSelected = this.isChapterSelected.bind(this);
    this.selectChapter = this.selectChapter.bind(this);
    this.cancelChaptersToPublish = this.cancelChaptersToPublish.bind(this);
    this.state = { selectedChapters: [], selectedAll: false };
  }
  onSelectAll() {
    if (this.state.selectedAll) {
      this.setState({ selectedChapters: [], selectedAll: false });
    } else {
      const newSelectedChapters: number[] = [];
      this.props.store.text.infos.structure.chapters.forEach(chapter => {
        if (chapter.title && chapter.title.trim() !== '') {
          newSelectedChapters.push(chapter.id);
        }
      });
      this.setState({ selectedChapters: newSelectedChapters, selectedAll: true });
    }
  }
  isChapterSelected(chapterId: number) {
    return this.state.selectedChapters.indexOf(chapterId) !== -1;
  }
  selectChapter(chapterId: number) {
    const newSelectedChapters = this.state.selectedChapters;
    if (this.isChapterSelected(chapterId)) {
      const index = newSelectedChapters.indexOf(chapterId);
      newSelectedChapters.splice(index, 1);
    } else {
      newSelectedChapters.push(chapterId);
    }
    this.setState({
      selectedChapters: newSelectedChapters,
      selectedAll: this.state.selectedAll
    });
  }
  cancelChaptersToPublish() {
    this.props.cancelChaptersToPublish();
  }
  render() {
    let chapters: JSX.Element[];
    const loading = this.props.store.text.loading;
    if (this.props.store.text.infos.structure.chapters
      && this.props.store.text.infos.structure.chapters.length > 0) {
      chapters = this.props.store.text.infos.structure.chapters.map(chapter =>
        <tr key={chapter.id}>
          <td>
            {chapter.title && chapter.title.trim() !== '' && !chapter.empty ?
              <Checkbox
                checked={this.state.selectedChapters.indexOf(chapter.id) !== -1}
                onClick={() => this.selectChapter(chapter.id)}
                />
              :
              <span>
              </span>
            }
          </td>
          <td>
            {chapter.index}
          </td>
          <td>
            {chapter.title && chapter.title.trim() !== '' ?
              <span>
                {chapter.title}
              </span>
              :
              <span className="empty-field">
                Sans titre
              </span>
            }
          </td>
        </tr>
      );
    } else {
      chapters = [].concat.apply([], this.props.store.text.infos.structure.parts.map(part => {
        return part.chapters.map(chapter => {
          return (
            <tr key={chapter.id}>
              <td>
                {chapter.title && chapter.title.trim() !== '' && !chapter.empty ?
                  <Checkbox
                    checked={this.state.selectedChapters.indexOf(chapter.id) !== -1}
                    onClick={() => this.selectChapter(chapter.id)}
                    />
                  :
                  <span>
                  </span>
                }
              </td>
              <td>
                {chapter.index}
              </td>
              <td>
                {chapter.title && chapter.title.trim() !== '' ?
                  <span>
                    {chapter.title}
                  </span>
                  :
                  <span className="empty-field">
                    Sans titre
              </span>
                }
              </td>
            </tr>
          );
        });
      }));
    }
    return (
      <div>
        {chapters ?
          <div>
            <Modal
              open={this.props.showChaptersToPublish}
              onClose={() => { } }
              >
              <div className="header">{loading ? 'Veuillez patienter...' : 'Chapitres à publier'}</div>
              {loading ?
                <Loader message="Publication en cours..." />
                : <div className="content">
                  <label>Sélectionnez les chapitres que vous souhaitez publier: </label>
                  <table className="ui stripped table">
                    <thead>
                      <tr>
                        <th style={{ width: '30px' }}>
                          <Checkbox
                            checked={this.state.selectedAll}
                            onClick={this.onSelectAll}
                            />
                        </th>
                        <th style={{ width: '100px' }}>
                          Position
                      </th>
                        <th>
                          Titre
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {chapters}
                    </tbody>
                  </table>
                  {this.props.store.text.infos.structure.chapters
                    && this.props.store.text.infos.structure.chapters.filter(c =>
                      c.title && c.title.trim() !== '').length > 0 ?
                    <div className="ui message warning">
                      Seuls les chapitres avec un titre et un corps de texte
                    peuvent être sélectionnés.
                  </div>
                    :
                    <div className="ui message danger">
                      Seuls les chapitres avec un titre et un corps de texte
                    peuvent être sélectionnés.
                  </div>
                  }
                  <div className="ui message warning">
                    Nous vous conseillons d'étaler la publication des chapitres en plusieurs fois
                  afin de mieux capter votre lectorat !
                </div>
                </div>
              }
              {!loading ?
                <div className="actions">
                  {this.state.selectedChapters.length > 0 ?
                    <button
                      className="ui blue approve button"
                      onClick={() => this.props.confirmChaptersToPublish(this.state.selectedChapters)}
                      >
                      Publier
                  </button>
                    : null
                  }
                  <button className="ui cancel button" onClick={this.cancelChaptersToPublish}>Annuler</button>
                </div>
                : undefined}
            </Modal>
          </div>
          : null}
      </div>
    );
  }
}
