import { observable } from 'mobx';
import LightDiscussionModel from './LightDiscussionModel';

export default class AllDiscussionsModel {

  @observable count: number;
  @observable end: boolean;
  @observable discussions: LightDiscussionModel[];

  constructor(count: number, end: boolean, discussions: LightDiscussionModel[]) {
    this.count = count;
    this.end = end;
    this.discussions = discussions;
  }

  setDiscussions(discussions: LightDiscussionModel[]) {
    if (this.count === 0) {
      this.discussions = discussions;
    } else {
      this.discussions = this.discussions.concat(discussions);
    }
    this.count = this.count + 1;
    this.end = (discussions.length < 15);
  }

  toJS() {
    return {
      count: this.count,
      end: this.end,
      discussions: this.discussions.map(m => m.toJS()),
    };
  }

  static fromJS(object: any) {
    if (object) {
      const discussions = object.discussions.map((d: any) => LightDiscussionModel.fromJS(d));
      return new AllDiscussionsModel(object.count, object.end, discussions);
    }
    return undefined;
  }
}
