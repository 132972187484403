import { observable } from 'mobx';

export default class CanvasChapterModel {

  @observable id: number;
  @observable index: number;
  @observable title: string;
  @observable description: string;

  constructor(id: number, index: number, title: string, description: string) {
    this.id = id;
    this.index = index;
    this.title = title;
    this.description = description;
  }

  toJS() {
    return {
      id: this.id,
      index: this.index,
      title: this.title,
      description: this.description
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CanvasChapterModel(object.id, object.index, object.title, object.description);
    }
    return undefined;
  }
}
