import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface ChapterAnnotationsSwitchProps {
  store?: Store;
}

@inject('store') @observer
export default class ChapterAnnotationsSwitch extends React.Component<ChapterAnnotationsSwitchProps, {}> {
  constructor(props: ChapterAnnotationsSwitchProps) {
    super(props);
    this.switchAnnotations = this.switchAnnotations.bind(this);
  }
  @action
  switchAnnotations() {
    this.props.store.text.chapter.showAnnotations = !this.props.store.text.chapter.showAnnotations;
  }
  render() {
    const showAnnotations = this.props.store.text.chapter.showAnnotations;
    return (
      <div>
        {this.props.store.text && this.props.store.text.chapter ?
          <div>
            <button
              className={'ui icon ' + (showAnnotations ? 'active' : '') + ' button'}
              data-tooltip={showAnnotations ? 'Masquer les annotations' : 'Afficher les annotations'}
              data-inverted="" data-position="bottom center"
              onClick={this.switchAnnotations}
              >
              <i className="fa fa-fw fa-font"
                title={showAnnotations ? 'Masquer les annotations' : 'Afficher les annotations'} />
            </button>
          </div>
          : undefined}
      </div>
    );
  }
}
