import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import StructureModel from '../../models/write/StructureModel';
import ChapterDescription from './text/chapter/toolbar/ChapterDescription';
import AlertModel from '../../models/AlertModel';
import Store from '../../stores/Store';

interface ConfirmChangeStructureProps {
  active: boolean;
  confirmChange: Function | any;
  cancelChange: Function | any;
}

class ConfirmChangeStructure extends React.Component<ConfirmChangeStructureProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
        size="small"
      >
        <div className="header">Confirmation</div>
        <div className="content">
          Confirmez-vous le retour à une structure à un niveau ?
          <br />Les chapitres ajoutés seront gardés mais les parties créées seront supprimées.
        </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.props.confirmChange}
          >
            Confirmer
          </button>
          <button className="ui button" onClick={this.props.cancelChange}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}

interface ConfirmNewPartChapterProps {
  active: boolean;
  text: string;
  confirm: Function | any;
  cancel: Function | any;
}

class ConfirmNewPartChapter extends React.Component<ConfirmNewPartChapterProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
        size="small"
      >
        <div className="header">Confirmation</div>
        <div className="content">
          {this.props.text}
        </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.props.confirm}
          >
            Allons-y !
          </button>
          <button className="ui button" onClick={this.props.cancel}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}

interface StructurePart {
  title: string;
  titleEdit: string;
  chapters?: StructureChapter[];
  editMode: boolean;
}

interface StructureChapter {
  id?: number;
  title: string;
  titleEdit: string;
  status?: string;
  step?: number;
  description?: string;
  views?: number;
  favorites?: number;
  comments?: number;
  annotations?: number;
  editMode: boolean;
}

interface TextStructureFormProps {
  store?: Store;
  textId: number;
  urlText: string;
  published: boolean;
  structure: StructureModel;
  reload: Function;
  scrollable?: boolean;
}

interface TextStructureFormState {
  twoLevels: boolean;
  structure: {
    parts: StructurePart[];
    chapters: StructureChapter[];
  };
  descriptionActive: boolean;
  selectedChapter: number;
  confirmChangeActive: boolean;
  confirmNewPart: boolean;
  confirmNewChapter: boolean;
  reorg: boolean;
  saveLoading: boolean;
}

@inject('store') @observer
export default class TextStructureForm
  extends React.Component<TextStructureFormProps, TextStructureFormState> {
  constructor(props: TextStructureFormProps) {
    super(props);
    this.convertPropsStructureToState = this.convertPropsStructureToState.bind(this);
    this.saveTextStructure = this.saveTextStructure.bind(this);
    this.handleTwoLevelsChange = this.handleTwoLevelsChange.bind(this);
    this.confirmTwoLevelsChange = this.confirmTwoLevelsChange.bind(this);
    this.cancelTwoLevelsChange = this.cancelTwoLevelsChange.bind(this);
    this.upChapter = this.upChapter.bind(this);
    this.downChapter = this.downChapter.bind(this);
    this.removeChapter = this.removeChapter.bind(this);
    this.changePartEditMode = this.changePartEditMode.bind(this);
    this.startEditPart = this.startEditPart.bind(this);
    this.cancelEditPart = this.cancelEditPart.bind(this);
    this.validEditPart = this.validEditPart.bind(this);
    this.handlePartTitleChange = this.handlePartTitleChange.bind(this);
    this.changeChapterEditMode = this.changeChapterEditMode.bind(this);
    this.startEditChapter = this.startEditChapter.bind(this);
    this.cancelEditChapter = this.cancelEditChapter.bind(this);
    this.validEditChapter = this.validEditChapter.bind(this);
    this.handleChapterTitleChange = this.handleChapterTitleChange.bind(this);
    this.createNewPart = this.createNewPart.bind(this);
    this.confirmCreateNewPart = this.confirmCreateNewPart.bind(this);
    this.createNewChapter = this.createNewChapter.bind(this);
    this.confirmCreateNewChapter = this.confirmCreateNewChapter.bind(this);
    this.cancelNew = this.cancelNew.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescription = this.hideDescription.bind(this);
    this.enableReorg = this.enableReorg.bind(this);
    this.disableReorg = this.disableReorg.bind(this);

    const structureState = this.convertPropsStructureToState();
    this.state = {
      twoLevels: structureState.parts && structureState.parts.length > 0,
      structure: structureState,
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: false,
      confirmNewPart: false,
      confirmNewChapter: false,
      reorg: false,
      saveLoading: false
    };
  }
  convertPropsStructureToState() {
    const structure = this.props.structure;
    const parts = structure.parts && structure.parts.length > 0 ?
      structure.parts.map(p => {
        return {
          title: p.title,
          titleEdit: '',
          chapters: p.chapters.map(c => {
            return {
              id: c.id,
              title: c.title,
              titleEdit: '',
              status: c.status,
              step: c.step,
              views: c.views,
              favorites: c.favorites,
              comments: c.comments,
              annotations: c.annotations,
              description: c.description,
              editMode: false
            };
          }),
          editMode: false
        };
      })
      : [];
    const chapters = structure.chapters.map(c => {
      return {
        id: c.id,
        title: c.title,
        titleEdit: '',
        status: c.status,
        step: c.step,
        views: c.views,
        favorites: c.favorites,
        comments: c.comments,
        annotations: c.annotations,
        description: c.description,
        editMode: false
      };
    });
    return {
      parts: parts,
      chapters: chapters
    };
  }
  saveTextStructure() {
    this.setState({
      saveLoading: true
    });
    const structure = {
      parts: this.state.structure.parts.map(p => {
        return {
          title: p.title,
          chapters: p.chapters.map(c => {
            return {
              id: c.id,
              title: c.title
            };
          })
        };
      }),
      chapters: this.state.structure.chapters.map(c => {
        return {
          id: c.id,
          title: c.title
        };
      })
    };
    this.props.store.saveTextStructure(this.props.textId, this.state.twoLevels, structure).then((d: any) => {
      this.props.reload(this.props.textId).then((d2: any) => {
        this.props.store.addAlert(new AlertModel(
          'saveTextStructure',
          'Structure sauvegardée !',
          '',
          'success',
          5
        ));
        this.setState({
          reorg: false,
          saveLoading: false
        });
      });
    });
  }
  handleTwoLevelsChange() {
    if (this.state.twoLevels) {
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: this.state.structure,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        confirmChangeActive: true
      });
    } else {
      this.confirmTwoLevelsChange();
    }
  }
  cancelTwoLevelsChange() {
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: this.state.structure,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      confirmChangeActive: false
    });
  }
  confirmTwoLevelsChange() {
    const newTwoLevels = !this.state.twoLevels;
    const newStructure: {
      parts: StructurePart[];
      chapters: StructureChapter[];
    } = newTwoLevels ?
        {
          parts: [
            {
              title: 'Partie 1',
              titleEdit: '',
              chapters: this.state.structure.chapters.map(c => {
                const newChapter = c;
                newChapter.titleEdit = '';
                newChapter.editMode = false;
                return newChapter;
              }),
              editMode: false
            }
          ],
          chapters: []
        }
        :
        {
          parts: [],
          chapters: [].concat.apply([], this.state.structure.parts.map(p => p.chapters.map(c => {
            const newChapter = c;
            newChapter.titleEdit = '';
            newChapter.editMode = false;
            return newChapter;
          })))
        };

    this.setState({
      twoLevels: newTwoLevels,
      structure: newStructure,
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: false
    });
  }
  upChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      if (chapterIndex === 0) {
        // move to previous part
        const chapter = parts[partIndex].chapters[chapterIndex];
        parts[partIndex].chapters.shift();
        parts[partIndex - 1].chapters.push(chapter);
      } else {
        // keep in current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        const previousChapter = parts[partIndex].chapters[chapterIndex - 1];
        parts[partIndex].chapters[chapterIndex - 1] = chapter;
        parts[partIndex].chapters[chapterIndex] = previousChapter;
      }
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts.filter(p => p.chapters.length > 0),
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      const previousChapter = chapters[chapterIndex - 1];
      chapters[chapterIndex - 1] = chapter;
      chapters[chapterIndex] = previousChapter;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  downChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const partsLength = parts[partIndex].chapters.length;
      if (chapterIndex === partsLength - 1) {
        // move to next part
        // current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        parts[partIndex].chapters.pop();
        parts[partIndex + 1].chapters.unshift(chapter);
      } else {
        // keep in current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        const nextChapter = parts[partIndex].chapters[chapterIndex + 1];
        parts[partIndex].chapters[chapterIndex + 1] = chapter;
        parts[partIndex].chapters[chapterIndex] = nextChapter;
      }
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts.filter(p => p.chapters.length > 0),
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      const nextChapter = chapters[chapterIndex + 1];
      chapters[chapterIndex + 1] = chapter;
      chapters[chapterIndex] = nextChapter;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  removeChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const part = parts[partIndex];
      part.chapters.splice(chapterIndex, 1);
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      chapters.splice(chapterIndex, 1);
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  changePartEditMode(partIndex: number, editMode: boolean) {
    const parts = this.state.structure.parts;
    const part = parts[partIndex];
    part.editMode = editMode;
    part.titleEdit = editMode ? part.title : '';
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  startEditPart(partIndex: number, chapterIndex: number) {
    this.changePartEditMode(partIndex, true);
  }
  cancelEditPart(partIndex: number, chapterIndex: number) {
    this.changePartEditMode(partIndex, false);
  }
  validEditPart(partIndex: number, chapterIndex: number) {
    const parts = this.state.structure.parts;
    const part = parts[partIndex];
    part.title = part.titleEdit;
    part.editMode = false;
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  handlePartTitleChange(partIndex: number, e: any) {
    const parts = this.state.structure.parts;
    parts[partIndex].titleEdit = e.target.value;
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  changeChapterEditMode(partIndex: number, chapterIndex: number, editMode: boolean) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.editMode = editMode;
      chapter.titleEdit = editMode ? chapter.title : '';
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.editMode = editMode;
      chapter.titleEdit = editMode ? chapter.title : '';
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  startEditChapter(partIndex: number, chapterIndex: number) {
    this.changeChapterEditMode(partIndex, chapterIndex, true);
  }
  cancelEditChapter(partIndex: number, chapterIndex: number) {
    this.changeChapterEditMode(partIndex, chapterIndex, false);
  }
  validEditChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.title = chapter.titleEdit;
      chapter.editMode = false;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.title = chapter.titleEdit;
      chapter.editMode = false;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  handleChapterTitleChange(partIndex: number, chapterIndex: number, e: any) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.titleEdit = e.target.value;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.titleEdit = e.target.value;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        descriptionActive: false,
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  createNewPart() {
    this.setState({
      confirmNewPart: true
    });
  }
  confirmCreateNewPart() {
    const urlText = this.props.urlText;
    this.props.store.createNewPart(this.props.textId).then((createdChapterId: string) => {
      this.props.store.addAlert(new AlertModel(
        'createNewPart',
        'Nouvelle partie créée !',
        '',
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/text/${this.props.textId}/${urlText}/chapter/${createdChapterId}/edit`,
        state: {
          newPart: false,
          showCongratulationsChapter: false
        }
      });
    });
  }
  createNewChapter() {
    this.setState({
      confirmNewChapter: true
    });
  }
  confirmCreateNewChapter() {
    const urlText = this.props.urlText;
    this.props.store.createNewChapter(this.props.textId).then((createdChapterId: string) => {
      this.props.store.addAlert(new AlertModel(
        'createNewChapter',
        'Nouveau chapitre créé !',
        '',
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/text/${this.props.textId}/${urlText}/chapter/${createdChapterId}/edit`,
        state: {
          newPart: false,
          showCongratulationsChapter: false
        }
      });
    });
  }
  cancelNew() {
    this.setState({
      confirmNewPart: false,
      confirmNewChapter: false
    });
  }
  showDescription(chapterId: number) {
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: this.state.structure,
      descriptionActive: true,
      selectedChapter: chapterId,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  hideDescription(description: string) {
    const plainDescription = description !== '<p></p>' ? description : '';

    const structure = this.state.structure;

    const parts = structure.parts && structure.parts.length > 0 ?
      structure.parts.map(p => {
        return {
          title: p.title,
          titleEdit: p.titleEdit,
          chapters: p.chapters.map(c => {
            return {
              id: c.id,
              title: c.title,
              titleEdit: c.titleEdit,
              description: description && c.id === this.state.selectedChapter ? plainDescription : c.description,
              editMode: c.editMode
            };
          }),
          editMode: p.editMode
        };
      })
      : [];
    const chapters = structure.chapters.map(c => {
      return {
        id: c.id,
        title: c.title,
        titleEdit: c.titleEdit,
        description: description && c.id === this.state.selectedChapter ? plainDescription : c.description,
        editMode: c.editMode
      };
    });

    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: chapters
      },
      descriptionActive: false,
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  enableReorg() {
    this.setState({
      reorg: true
    });
  }
  disableReorg() {
    const structureState = this.convertPropsStructureToState();
    this.setState({
      twoLevels: structureState.parts && structureState.parts.length > 0,
      structure: structureState,
      reorg: false
    });
  }
  render() {
    let parts: JSX.Element[];
    let chapters: JSX.Element[];

    const inEditMode = (this.state.twoLevels &&
      this.state.structure.parts.filter(p => p.editMode || p.chapters.filter(c => c.editMode).length > 0).length > 0
      || (!this.state.twoLevels && this.state.structure.chapters.filter(c => c.editMode).length > 0));

    const premium = this.props.store.connected && this.props.store.connected.premium.premium;

    if (this.state.twoLevels) {
      parts = [].concat.apply([], this.state.structure.parts.map((part, i) => {
        chapters = part.chapters.map((chapter, j) => {
          return (
            <tr key={`part_${i}_chapter_${j}`}>
              {j === 0 ?
                <td rowSpan={part.chapters.length}>
                  {part.editMode ?
                    <div>
                      <input
                        ref="titleField"
                        type="text"
                        onChange={(e) => this.handlePartTitleChange(i, e)}
                        value={part.titleEdit}
                        style={{ width: '300px' }}
                        autoFocus={true}
                      />
                    </div>
                    :
                    part.title}
                  {inEditMode ?
                    (part.editMode ?
                      <div
                        className="ui one column stackable form"
                        style={{ marginBottom: '5px', marginTop: '10px' }}
                      >
                        <div className="inline fields">
                          <div className="field">
                            <button
                              className="ui blue mini button"
                              onClick={() => this.validEditPart(i, j)}
                              type="submit"
                            >
                              Ok
                        </button>
                          </div>
                          <div className="field">
                            <button
                              type="button"
                              className="ui mini button"
                              onClick={() => this.cancelEditPart(i, j)}
                            >
                              Annuler
                        </button>
                          </div>
                        </div>
                      </div>
                      : undefined)
                    :
                    (this.state.reorg ?
                      <span style={{ float: 'right' }}>
                        <button
                          title="Modifier le titre"
                          data-tooltip="Modifier le titre" data-inverted="" data-position="bottom center"
                          className="ui mini icon orange button"
                          style={{ marginRight: '5px' }}
                          onClick={() => this.startEditPart(i, j)}
                        >
                          <i className="fa fa-edit" />
                        </button>
                      </span>
                      : undefined)
                  }
                </td>
                : undefined}
              <td className="center aligned">
                {chapter.editMode ?
                  <div>
                    <input
                      ref="titleField"
                      type="text"
                      onChange={(e) => this.handleChapterTitleChange(undefined, i, e)}
                      value={chapter.titleEdit}
                      style={{ width: '300px' }}
                      autoFocus={true}
                    />
                  </div>
                  : (
                    chapter.status && chapter.status !== 'NEVER_PUBLISHED' ?
                      <Link to={`/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}`}>
                        {chapter.title}
                      </Link>
                      : <span>
                        {chapter.title && chapter.title.trim().length > 0 ?
                          chapter.title :
                          <span style={{ color: '#ddd' }}>Sans titre</span>
                        }
                      </span>
                  )
                }
              </td>
              <td className="center aligned">
                <span>
                  {chapter.status === 'PUBLISHED' ?
                    <div className="ui label green small">Publié</div>
                    :
                    <span>
                      {chapter.status === 'INPROGRESS' ?
                        <span>
                          <div className="ui label green small">Publié</div>
                          <div className="ui label orange small">En cours de modification</div>
                        </span>
                        :
                        <div className="ui label yellow small">Non publié</div>
                      }
                    </span>
                  }
                </span>
              </td>
              <td className="center aligned">
                <div className="ui label blue small">
                  {chapter.step === 1 ?
                    'Premier jet'
                    : (chapter.step === 2 ? 'Correction / Relecture' : 'Texte final')
                  }
                </div>
              </td>
              {premium ?
                <td className="center aligned">
                  <div style={{ cursor: 'pointer' }}
                    data-tooltip="Cliquer pour modifier la description du chapitre"
                    data-inverted="" data-position="bottom center"
                    onClick={() => this.showDescription(chapter.id)}
                    dangerouslySetInnerHTML={{
                      __html: chapter.description ?
                        '<div>' + chapter.description + '...</div>'
                        : '<div>...</div>'
                    }}
                  >
                  </div>
                </td>
                : undefined}
              <td>{chapter.views}</td>
              <td>{chapter.favorites}</td>
              <td>{chapter.comments}</td>
              <td>{chapter.annotations}</td>
              <td>
                {inEditMode ?
                  (chapter.editMode ?
                    <div
                      className="ui one column stackable center aligned grid form"
                      style={{ marginBottom: '5px', marginTop: '10px' }}
                    >
                      <div className="inline fields">
                        <div className="field">
                          <button
                            className="ui blue mini button"
                            onClick={() => this.validEditChapter(i, j)}
                            type="submit"
                          >
                            Ok
                        </button>
                        </div>
                        <div className="field">
                          <button
                            type="button"
                            className="ui mini button"
                            onClick={() => this.cancelEditChapter(i, j)}
                          >
                            Annuler
                        </button>
                        </div>
                      </div>
                    </div>
                    : undefined)
                  : <span style={{ float: 'right' }}>
                    {!this.state.reorg ?
                      <Link
                        title="Ouvrir le chapitre dans l'éditeur"
                        className="ui mini icon orange button"
                        style={{ marginRight: '5px' }}
                        to={`/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}/edit`}
                      >
                        <i className="fa fa-edit" />
                      </Link>
                      : undefined}
                    {this.state.reorg ?
                      <div>
                        {i > 0 || j > 0 ?
                          <button
                            title="Monter"
                            data-tooltip="Monter" data-inverted="" data-position="bottom center"
                            className="ui mini icon blue button"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.upChapter(i, j)}
                          >
                            <i className="fa fa-arrow-up">
                            </i>
                          </button>
                          : null}
                        {i < this.state.structure.parts.length - 1 || j < part.chapters.length - 1 ?
                          <button
                            title="Descendre"
                            data-tooltip="Descendre" data-inverted="" data-position="bottom center"
                            className="ui mini icon blue button"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.downChapter(i, j)}
                          >
                            <i className="fa fa-arrow-down">
                            </i>
                          </button>
                          : null}
                      </div>
                      : undefined}
                  </span>
                }
              </td>
            </tr>
          );
        });
        return chapters;
      }));
    } else {
      chapters = this.state.structure.chapters.map((chapter, i) => {
        return (
          <tr key={`chapter_${i}`}>
            <td className="center aligned">
              {chapter.editMode ?
                <div>
                  <input
                    ref="titleField"
                    type="text"
                    onChange={(e) => this.handleChapterTitleChange(undefined, i, e)}
                    value={chapter.titleEdit}
                    style={{ width: '300px' }}
                    autoFocus={true}
                  />
                </div>
                : (chapter.status && chapter.status !== 'NEVER_PUBLISHED' ?
                  <Link to={`/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}`}>
                    {chapter.title}
                  </Link>
                  : <span>
                    {chapter.title && chapter.title.trim().length > 0 ?
                      chapter.title :
                      <span style={{ color: '#ddd' }}>Sans titre</span>
                    }
                  </span>
                )
              }
            </td>
            <td className="center aligned">
              <span>
                {chapter.status === 'PUBLISHED' ?
                  <div className="ui label green small">Publié</div>
                  :
                  <span>
                    {chapter.status === 'INPROGRESS' ?
                      <span>
                        <div className="ui label green small">Publié</div>
                        <div className="ui label orange small">En cours de modification</div>
                      </span>
                      :
                      <div className="ui label yellow small">Non publié</div>
                    }
                  </span>
                }
              </span>
            </td>
            <td className="center aligned">
              <div className="ui label blue small">
                {chapter.step === 1 ?
                  'Premier jet'
                  : (chapter.step === 2 ? 'Correction / Relecture' : 'Texte final')
                }
              </div>
            </td>
            {premium ?
              <td className="center aligned">
                {chapter.id ?
                  <div style={{ cursor: 'pointer' }}
                    data-tooltip="Cliquer pour modifier la description du chapitre"
                    data-inverted="" data-position="bottom center"
                    onClick={() => this.showDescription(chapter.id)}
                    dangerouslySetInnerHTML={{
                      __html: chapter.description ?
                        '<div>' + chapter.description + '...</div>'
                        : '<div>...</div>'
                    }}
                  />
                  : undefined}
              </td>
              : undefined}
            <td>{chapter.views}</td>
            <td>{chapter.favorites}</td>
            <td>{chapter.comments}</td>
            <td>{chapter.annotations}</td>
            <td>
              {inEditMode ?
                (chapter.editMode ?
                  <div
                    className="ui one column stackable center aligned grid form"
                    style={{ marginBottom: '5px', marginTop: '10px' }}
                  >
                    <div className="inline fields">
                      <div className="field">
                        <button
                          className="ui blue mini button"
                          onClick={() => this.validEditChapter(undefined, i)}
                          type="submit"
                        >
                          Ok
                        </button>
                      </div>
                      <div className="field">
                        <button
                          type="button"
                          className="ui mini button"
                          onClick={() => this.cancelEditChapter(undefined, i)}
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                  : undefined)
                : <span style={{ float: 'right' }}>
                  {!this.state.reorg ?
                    <Link
                      title="Ouvrir le chapitre dans l'éditeur"
                      className="ui mini icon orange button"
                      style={{ marginRight: '5px' }}
                      to={`/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}/edit`}
                    >
                      <i className="fa fa-edit" />
                    </Link>
                    : undefined}
                  {this.state.reorg ?
                    <div>
                      {i > 0 ?
                        <button
                          title="Monter"
                          data-tooltip="Monter" data-inverted="" data-position="bottom center"
                          className="ui mini icon blue button"
                          style={{ marginRight: '5px' }}
                          onClick={() => this.upChapter(undefined, i)}
                        >
                          <i className="fa fa-arrow-up">
                          </i>
                        </button>
                        : null}
                      {i < this.state.structure.chapters.length - 1 ?
                        <button
                          title="Descendre"
                          data-tooltip="Descendre" data-inverted="" data-position="bottom center"
                          className="ui mini icon blue button"
                          style={{ marginRight: '5px' }}
                          onClick={() => this.downChapter(undefined, i)}
                        >
                          <i className="fa fa-arrow-down">
                          </i>
                        </button>
                        : null}
                    </div>
                    : undefined}
                </span>
              }
            </td>
          </tr>
        );
      });
    }

    return (
      <div>
        <ConfirmChangeStructure
          active={this.state.confirmChangeActive}
          confirmChange={this.confirmTwoLevelsChange}
          cancelChange={this.cancelTwoLevelsChange}
        />
        <ConfirmNewPartChapter
          text="Vous allez débuter une nouvelle partie."
          active={this.state.confirmNewPart}
          confirm={this.confirmCreateNewPart}
          cancel={this.cancelNew}
        />
        <ConfirmNewPartChapter
          text="Vous allez débuter un nouveau chapitre."
          active={this.state.confirmNewChapter}
          confirm={this.confirmCreateNewChapter}
          cancel={this.cancelNew}
        />
        {premium ?
          <ChapterDescription
            hideModal={this.hideDescription}
            active={this.state.descriptionActive}
            textId={this.props.textId}
            chapterId={this.state.selectedChapter}
          />
          : undefined}
        <div className="ui form">
          {this.state.reorg ?
            <div className="inline fields">
              <label>Type de structure</label>
              <div className="field">
                <Checkbox
                  radio
                  checked={!this.state.twoLevels}
                  onClick={this.handleTwoLevelsChange}
                  label="Chapitres (un niveau)"
                />
              </div>
              <div className="field">
                <Checkbox
                  radio
                  checked={this.state.twoLevels}
                  onClick={this.handleTwoLevelsChange}
                  label="Parties et chapitres (deux niveaux)"
                />
              </div>
            </div>
            : undefined}
          <div className="field">
            <div style={{ paddingBottom: '20px' }}>
              {this.state.twoLevels ?
                <div>
                  <div style={this.props.scrollable ?
                    { paddingBottom: '20px', maxHeight: '400px', overflowX: 'scroll', overflowY: 'scroll' }
                    : { overflowX: 'scroll' }}>
                    <table className="ui celled unstackable structured table">
                      <thead>
                        <tr>
                          <th>Partie</th>
                          <th>Chapitre</th>
                          <th colSpan={2} />
                          {premium ?
                            <th data-tooltip="Description" data-inverted="" data-position="bottom center">
                              <i className="fa fa-align-justify" />
                            </th>
                            : undefined}
                          <th data-tooltip="Lectures" data-inverted="" data-position="bottom center">
                            <i className="fa fa-eye" />
                          </th>
                          <th data-tooltip="J'aime" data-inverted="" data-position="bottom center">
                            <i className="fa fa-heart" />
                          </th>
                          <th data-tooltip="Commentaires" data-inverted="" data-position="bottom center">
                            <i className="fa fa-comment" />
                          </th>
                          <th data-tooltip="Annotations" data-inverted="" data-position="bottom center">
                            <i className="fa fa-edit" />
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {parts}
                      </tbody>
                    </table>
                  </div>
                  {this.state.reorg ?
                    <div style={{ float: 'right', marginTop: '10px' }}>
                      <button
                        className={'ui blue mini' + (this.state.saveLoading ? ' loading' : '') + ' button'}
                        onClick={this.saveTextStructure}>
                        Enregistrer
                      </button>
                      <button className="ui mini button" onClick={this.disableReorg}>
                        Annuler
                      </button>
                    </div>
                    :
                    <div style={{ float: 'right', marginTop: '10px' }}>
                      <button className="ui right floated mini button" onClick={this.createNewChapter}>
                        Nouveau chapitre
                    </button>
                      <button className="ui right floated mini button" onClick={this.createNewPart}>
                        Nouvelle partie
                    </button>
                      <button className="ui right floated orange mini button" onClick={this.enableReorg}>
                        Réorganiser
                    </button>
                    </div>
                  }
                </div>
                :
                <div>
                  <div style={this.props.scrollable ?
                    { paddingBottom: '20px', maxHeight: '400px', overflowX: 'scroll', overflowY: 'scroll' }
                    : { overflowX: 'scroll' }}>
                    <table className="ui celled unstackable table">
                      <thead>
                        <tr>
                          <th>Chapitre</th>
                          <th colSpan={2} />
                          {premium ?
                            <th data-tooltip="Description" data-inverted="" data-position="bottom center">
                              <i className="fa fa-align-justify" />
                            </th>
                            : undefined}
                          <th data-tooltip="Lectures" data-inverted="" data-position="bottom center">
                            <i className="fa fa-eye" />
                          </th>
                          <th data-tooltip="J'aime" data-inverted="" data-position="bottom center">
                            <i className="fa fa-heart" />
                          </th>
                          <th data-tooltip="Commentaires" data-inverted="" data-position="bottom center">
                            <i className="fa fa-comment" />
                          </th>
                          <th data-tooltip="Annotations" data-inverted="" data-position="bottom center">
                            <i className="fa fa-edit" />
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {chapters}
                      </tbody>
                    </table>
                  </div>
                  {this.state.reorg ?
                    <div style={{ float: 'right', marginTop: '10px' }}>
                      <button
                        className={'ui blue mini' + (this.state.saveLoading ? ' loading' : '') + ' button'}
                        onClick={this.saveTextStructure}>
                        Enregistrer
                      </button>
                      <button className="ui mini button" onClick={this.disableReorg}>
                        Annuler
                      </button>
                    </div>
                    :
                    <div style={{ float: 'right', marginTop: '10px' }}>
                      <button className="ui right floated mini button" onClick={this.createNewChapter}>
                        Nouveau chapitre
                      </button>
                      <button className="ui right floated orange mini button" onClick={this.enableReorg}>
                        Réorganiser
                      </button>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
