import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';

interface AuthorsBioModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  author: number;
}

interface AuthorsBioModalState {
  constraint: boolean;
}

@inject('store') @observer
export default class AuthorsBioModal
  extends React.Component<AuthorsBioModalProps, AuthorsBioModalState> {
  constructor(props: AuthorsBioModalProps) {
    super(props);
    this.state = {
      constraint: false
    };
  }
  
  render() {
    switch(this.props.author) {
      case 1:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Franck Thilliez</div>
          <div className="content" style={{maxHeight: '400px' }}>
          <p>Franck Thilliez est l’auteur d’une vingtaine de romans, dont Pandemia, Sharko, 
                  Le Manuscrit inachevé, Luca et Il était deux fois. Comptant parmi les cinq auteurs les plus 
                  lus en France, il s’affirme comme la référence du thriller français et continue d’alterner one shots et enquêtes 
                  menées par son couple phare Lucie Henebelle / Franck Sharko.</p>

                <p>Son roman La Chambre des morts (prix SNCF du polar français) a été adapté au cinéma. Et Syndrôme E en bande dessinée.</p>
                <p>Franck Thilliez est aussi scénariste. Il a créé, avec Niko Tackian, la série Alex Hugo.</p>

                <p>Ses livres sont traduits dans le monde entier.</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 2:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Gilberto Villarroel</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Né en 1964 à Santiago du Chili, Gilberto Villarroel est un véritable touche-à-tout de la culture : 
              enseignant, journaliste, éditeur, scénariste et producteur au cinéma et à la télévision, et désormais écrivain.</p>

            <p>En 2016, il a scénarisé et produit une série documentaire sur Thomas Cochrane, commandant en chef de la 
                Première Escadre Nationale, héros des luttes pour l’indépendance, notamment du Chili et du Pérou : Lord Cochrane, Maître à bord.</p>

            <p>Passionné par cette figure iconoclaste, il s’attelle depuis à faire vivre à son personnage de papier, si proche du 
              personnage historique, des aventures à sa mesure.</p>

            <p>Aux Forges de Vulcain et chez Pocket, il publie Cochrane vs Cthulhuen 2020, et Lord Cochrane vs l’Ordre des catacombes, en 2021.</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 3:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Victor Dixen</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Victor Dixen, double lauréat du Grand Prix de l’Imaginaire, est l’une des figures de proue de la 
                littérature française de l’imaginaire (Animale, Cogito, Extincta, ainsi que les sagas Phobos et Vampyria).</p>

            <p>Écrivain nomade, il a vécu à Paris, à Dublin, à Singapour et à New York, puisant son inspiration aussi bien dans les 
              promesses du futur que dans les fantômes du passé.</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 4:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Romain Benassaya</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Romain Benassaya est né à Nice en 1984. Après des études de linguistique menées à Paris et l’obtention d’un master, 
                il enseigne le français au Canada, puis en Ouganda, avant de poser ses valises à Bangkok.</p>

            <p>Passionné de science-fiction, et de son aveu même profondément influencé par Dune de Frank Herbert et Hyperion 
              de Dan Simmons, c’est tout naturellement qu’il se tourne vers le space opera. Pyramides (Critic, 2018 ; Pocket, 2020), 
              son deuxième roman après Arca (Critic, 2016 ; Pocket, 2018), a été salué par la critique. </p>

            <p>Avec son troisième roman, Les Naufragés de Velloa (Critic, 2019), il poursuit son exploration de l’espace infini mais 
              surtout de l’infinie complexité de l’âme humaine.</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 5:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">David Bry</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>David Bry est un auteur de fantasy, d’anticipation et d’uchronie. Grand dévoreur de livres, il commence très
              tôt à écrire ses premières histoires, puis des pièces de théâtre, des scénarios de jeux de rôle, et enfin des romans.</p>

            <p>Il en a publié huit, dont la trilogie La Seconde Chute d’Ervalon (2009, Mnémos), 2087 (2012, Black Book), 
              Que passe l’hiver (2017, Éditions de l’Homme sans nom) et Le Garçon & la ville qui ne souriait plus (2019, Éditions Lynks).</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 6:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Floriane Soulas</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Née en 1989, Floriane Soulas a fait une entrée remarquée dans les littératures de l’Imaginaire avec son premier roman Rouille 
              (Scrineo, 2018 : Pocket, 2019), couronné par le Prix ActuSF de l’Uchronie, le Prix Imaginales des Lycéens et le Prix Chrysalis – 
              European Science Fiction Society.</p>

            <p>Après des études en école d’ingénieur, elle obtient un doctorat en génie mécanique, mais la littérature qu’elle dévore dès son plus 
              jeune âge la rattrape. De son amour pour les auteurs du XIXe (Zola et Hugo) et les grands maîtres de la science-fiction (Asimov et Orwell) 
              naîtra Rouille, récit steampunk.</p>

            <p>L’année suivante, elle emmène ses lecteurs dans la fantasy d’un Japon médiéval avec Les Noces de la Renarde (Scrineo, 2019).</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 7:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">David Lelait-Helo</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Né en 1971 à Orléans, David Lelait-Helo est l'auteur de 25 romans et biographies, dont celles de Dalida, Eva Perón, Maria Callas, 
              Barbara et Romy Schneider.</p>

            <p>Ses autofictions, Poussière d'homme et Quand je serai grand, je serai Nana Mouskouri, explorent le deuil comme la 
              construction de soi.</p>

            <p>À travers son cours au sein de l'Atelier des Auteurs, David Lelait-Helo invite chacun à se découvrir autrement et à 
              écrire sur soi, à travers une fiction ""plus vraie que le réel"".</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
      case 8:
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Alexandre Galien</div>
          <div className="content" style={{maxHeight: '400px' }}>
            <p>Après des études de droit et de sciences criminelles, Alexandre Galien a intégré la direction régionale 
              de la police judiciaire. Il se consacre aujourd'hui à l'écriture.</p>

            <p>À trente ans, il est le plus jeune lauréat du prix du Quai des Orfèvres, obtenu pour Les Cicatrices de 
              la nuit en 2020. Depuis septembre 2020, il enseigne le cours "Roman noir, époque sombre" à la chaire d'écriture 
              et de rhétorique de Sciences Po Paris.</p>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
    case 9:
       return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Sophie Chauveau</div>
        <div className="content" style={{maxHeight: '400px' }}>
          <p>Née en 1953 à Boulogne-Billancourt, Sophie Chauveau intègre d’abord le Conservatoire national supérieur d’art dramatique de Paris. Elle devient par la suite journaliste, comédienne puis écrivaine.</p>
          <p>Elle a publié au cours de sa vie une vingtaine d’ouvrages de genres différents dont une trilogie de romans historiques consacrée, au début des années 2000, aux peintres de la Renaissance italienne : <i>La Passion Lippi</i> (2004), <i>Le Rêve Botticelli</i> (2005) et <i>L'Obsession Vinci</i> (2007).</p>
          <p>Elle est également à l’origine d’un ouvrage important dans l’évolution des mentalités : <i>La Fabrique des pervers</i> (2016) où elle racontait les viols incestueux ayant été commis au sein de sa propre famille. Toute sa vie, elle s’est engagée, en parallèle de l’écriture, dans le militantisme en faveur du droit des femmes.</p>
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal>
    );

    case 10:
       return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Carène Ponte</div>
        <div className="content" style={{maxHeight: '400px' }}>
        <p>Romancière française née en 1980, Carène Ponte est juriste de formation, spécialisée dans le droit de la santé.</p>
        <p>Elle se fait connaître du grand public par le biais de son blog <i>Des mots et moi</i> fondé en 2013. </p>
        <p>Son premier roman, <i>Un merci de trop</i>, est publié en 2016 aux éditions Michel Lafon. À l’origine d’une dizaine de romans, elle est régulièrement classée parmi les coups de cœur du public et des rédactions spécialisées.</p>
        <p>Ainsi, en juin 2020, l’Est républicain la cite entre Stephen King et Guillaume Musso dans les lectures favorites de leurs abonnés durant le confinement qui s’achève. </p>
        <p>Passée aux Éditions Fleuve en 2021 où elle publie <i>La lumière était si parfaite</i>, elle s’engage désormais dans une vie entièrement consacrée à l’écriture.</p>
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal>
    );

    default:
      return (<Modal></Modal>);
  }
  
   
  }
}
