import * as React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';

import Store from './stores/Store';
import App from './components/App';
import DefaultTopBar from './components/misc/DefaultTopBar';
import Home from './components/home/Home';
import AllReadingListsLastTexts from './components/home/AllReadingListsLastTexts';
import AllReadingListsPage from './components/home/AllReadingListsPage';
import Profile from './components/profile/Profile';
import ProfileAuthor from './components/profile/ProfileAuthor';
import ProfileTexts from './components/profile/ProfileTexts';
import ProfileSocial from './components/profile/ProfileSocial';
import AllAbonnes from './components/profile/AllAbonnes';
import AllAbonnements from './components/profile/AllAbonnements';
/* import ProfileDiary from './components/profile/ProfileDiary'; */
import ProfileActivity from './components/profile/ProfileActivity';
import ProfileLists from './components/profile/ProfileLists';
import EditQuestionnaire from './components/profile/EditQuestionnaire';

import SubTextChapterBar from './components/read/SubTextChapterBar';
import ReadPage from './components/read/ReadPage';
import TextPage from './components/read/TextPage';
import TextInfosPage from './components/read/TextInfosPage';
import ChapterPage from './components/read/ChapterPage';
import TalkTextPage from './components/read/TalkTextPage';
import NewTalkTextPage from './components/read/NewTalkTextPage';
import EditTalkTextPage from './components/read/EditTalkTextPage';

import TextsAndDrafts from './components/write/choose/TextsAndDrafts';
import WriteChapter from './components/write/text/chapter/WriteChapter';
import TextToolbar from './components/write/text/toolbar/TextToolbar';
import TrainingPage from './components/write/text/training/TrainingPage';
import TrainingToolbar from './components/write/text/training/TrainingToolbar';
import TextStatisticsWrite from './components/write/text/statistics/TextStatisticsWrite';
import TextStatisticsRead from './components/write/text/statistics/TextStatisticsRead';
import TextStatisticsCompare from './components/write/text/statistics/TextStatisticsCompare';
import TextStatisticsToolbar from './components/write/text/statistics/TextStatisticsToolbar';

import ProloguePage from './components/write/text/parcours/prologue/ProloguePage';
import PrologueToolbar from './components/write/text/parcours/prologue/PrologueToolbar';

import TalksPage from './components/talk/TalksPage';
import TalkPage from './components/talk/TalkPage';
import NewTalkPage from './components/talk/NewTalkPage';
import EditTalkPage from './components/talk/EditTalkPage';

import DefisPage from './components/defis/DefisPage';
import DefiPage from './components/defis/DefiPage';
import NewDefiPage from './components/defis/NewDefiPage';
import EditDefiPage from './components/defis/EditDefiPage';

import SearchPage from './components/search/SearchPage';
import ChatPage from './components/chat/ChatPage';
import ChatDiscussion from './components/chat/ChatDiscussion';
import ChatNewDiscussion from './components/chat/ChatNewDiscussion';
import ChatIndexDiscussion from './components/chat/ChatIndexDiscussion';
import NotificationsPage from './components/notification/NotificationsPage';

import SettingsPage from './components/settings/SettingsPage';
import SettingsPageAccount from './components/settings/SettingsPageAccount';
import SettingsPagePreferences from './components/settings/SettingsPagePreferences';
import SettingsPageEmails from './components/settings/SettingsPageEmails';
import SettingsPageNotifications from './components/settings/SettingsPageNotifications';
import SettingsPagePremium from './components/settings/SettingsPagePremium';
import SettingsPageAdmin from './components/settings/SettingsPageAdmin';
import SettingsPageAdminSearch from './components/settings/SettingsPageAdminSearch';
import SettingsPageAdminSearchPage from './components/settings/SettingsPageAdminSearchPage';

import PaymentHubPage from './components/premium/PaymentHubPage';

import ListPage from './components/readinglist/ListPage';

import WritingProgramPage from './components/writingprogram/WritingProgramPage';

import About from './components/footer/About';
import Faq from './components/footer/Faq';
import Mentions from './components/footer/Mentions';
import CGU from './components/footer/CGU';
import Privacy from './components/footer/Privacy';
import CGV from './components/footer/CGV';
import Cookies from './components/footer/Cookies';
import Moderation from './components/footer/Moderation';
import Presse from './components/footer/Presse';
import Partenariats from './components/footer/Partenariats';
import PartnersAndAuthorsPage from './components/footer/PartnersAndAuthorsPage';
import SensibleContent from './components/footer/SensibleContent';
import IntProperty from './components/footer/IntProperty';
import Contact from './components/footer/Contact';

import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';

import PremiumPageAda from './components/premium/PremiumPageAda';
import CheckoutPage from './components/premium/CheckoutPage';

import NotFound from './components/NotFound';
import Unsubscribe from './components/Unsubscribe';
import NoFooter from './components/NoFooter';
import ParcoursPage from './components/write/text/parcours/ParcoursPage';
import PaymentConfirmationPage from './components/premium/PaymentConfirmationPage';
import CleanTopBar from './components/misc/CleanTopBar';
import CommunautePageAda from './components/home/CommunautePageAda';
import HomeNotConnectedAda from './components/home/HomeNotConnectedAda';
import LandingPageParcours from './components/home/LandingPageParcours';

declare var require: any;
require('../semantic/src/semantic.less');

export default (store: Store) => {
  const requireLogin = (nextState: any, replace: any, cb: any) => {
    function checkAuth() {
      if (!store.isAuthenticated()) {
        if (store.areCookiesExpired())
          replace('/auth/logout');
        else
          replace('/login');
      }
      cb();
    }
    checkAuth();
  };

 const checkTokens = (nextState: any, replace: any, cb: any) => {
    function checkAuth() {
      if (!store.isAuthenticated()) {
        if (store.areCookiesExpired())
          replace('/auth/logout');
      }
      cb();
    }
    checkAuth();
  };

  const requireVisitor = (nextState: any, replace: any, cb: any) => {
    function checkAuth() {
      if (store.isAuthenticated()) {
        replace('/');
      }
      cb();
    }
    checkAuth();
  };

  /**
   * Please keep routes in alphabetical order
   */
  return (
    <Route path="/" component={App}>
      <IndexRoute
        components={{ main: Home, topbar: DefaultTopBar }}
        onEnter={checkTokens}
      />
      <Route
        path="/readings"
        components={{ main: AllReadingListsLastTexts, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/lists"
        components={{ main: AllReadingListsPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/author/:authorId/:urlIdentity"
        components={{ main: Profile, topbar: DefaultTopBar }}
      >
        <IndexRoute component={ProfileAuthor} />
        <Route path="texts" component={ProfileTexts} />
        <Route path="social" component={ProfileSocial} />
        {/* <Route path="diary" component={ProfileDiary} /> */}
        <Route path="activity" component={ProfileActivity} />
        <Route path="lists" component={ProfileLists} />
      </Route>
      <Route
        path="/author/:authorId/:urlIdentity/social/abonnes"
        components={{ main: AllAbonnes, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/author/:authorId/:urlIdentity/social/abonnements"
        components={{ main: AllAbonnements, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/author/:authorId/:urlIdentity/questionnaire"
        components={{ main: EditQuestionnaire, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />

      <Route
        path="/read"
        components={{ main: ReadPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/read/text/:textId/:urlText"
        components={{ main: TextPage, topbar: DefaultTopBar }}>
        <IndexRoute components={{ main: TextInfosPage }} />
        <Route path="chapter/:chapterId" components={{ textbar: SubTextChapterBar, main: ChapterPage }} />
      </Route>
      <Route
        path="/text/:textId/:urlText"
        components={{ main: TextPage, topbar: DefaultTopBar }}>
        <IndexRoute components={{ main: TextInfosPage }} />
        <Route path="chapter/:chapterId" components={{ textbar: SubTextChapterBar, main: ChapterPage }} />
      </Route>
      <Route path="/text/:textId/:urlText/statistics/write"
        components={{ topbar: TextStatisticsToolbar, main: TextStatisticsWrite }}
      />
      <Route path="/text/:textId/:urlText/statistics/read"
        components={{ topbar: TextStatisticsToolbar, main: TextStatisticsRead }}
      />
      <Route path="/text/:textId/:urlText/statistics/compare"
        components={{ topbar: TextStatisticsToolbar, main: TextStatisticsCompare }}
      />
      <Route path="/text/:textId/:urlText/talks/:talkId/:urlTalk"
        components={{ topbar: DefaultTopBar, main: TalkTextPage }} />
      <Route
        path="/text/:textId/:urlText/talks/:talkId/:urlTalk/edit"
        components={{ topbar: DefaultTopBar, main: EditTalkTextPage }}
        onEnter={requireLogin}
      />
      <Route
        path="/text/:textId/:urlText/talks/new"
        components={{ topbar: DefaultTopBar, main: NewTalkTextPage }}
        onEnter={requireLogin}
      />
      <Route
        path="/write"
        components={{ main: TextsAndDrafts, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/text/:textId/:urlText/chapter/:chapterId/edit"
        onEnter={requireLogin}
        components={{ main: WriteChapter, topbar: TextToolbar, footer: NoFooter }}
      />
      <Route
        path="/write/training"
        onEnter={requireLogin}
        components={{ main: TrainingPage, topbar: TrainingToolbar, footer: NoFooter }}
      />
      <Route
        path="/write/parcours/:parcoursId/prep/:prologueId"
        components={{ main: ProloguePage, topbar: PrologueToolbar, footer: NoFooter }}
        onEnter={requireLogin}
      />

      <Route
        path="/talks"
        components={{ main: TalksPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/talks/new"
        components={{ main: NewTalkPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/talks/:talkId/:urlTalk"
        components={{ main: TalkPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/talks/:talkId/:urlTalk/edit"
        components={{ main: EditTalkPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/defis"
        components={{ main: DefisPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/parcours/:textId/:urlText"
        components={{ main: ParcoursPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/defis/new"
        components={{ main: NewDefiPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/defis/defi/:defiId/:urlDefi"
        components={{ main: DefiPage, topbar: DefaultTopBar }} />
      <Route
        path="/defis/defi/:defiId/:urlTitle/edit"
        components={{ main: EditDefiPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/list/:listId/:urlName"
        components={{ main: ListPage, topbar: DefaultTopBar }}
      />
      <Route
        path="/program"
        components={{ main: WritingProgramPage, topbar: DefaultTopBar }}
      />
      <Route
        path="/search"
        components={{ main: SearchPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />

      <Route path="/messages" components={{ main: ChatPage, topbar: DefaultTopBar }} onEnter={requireLogin}>
        <IndexRoute component={ChatIndexDiscussion} />
        <Route path="new" component={ChatNewDiscussion} />
        <Route path="discussion/:discussionId" component={ChatDiscussion} />
      </Route>

      <Route
        path="/notifications"
        components={{ main: NotificationsPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />

      <Route
        path="/search_page"
        components={{ main: SettingsPageAdminSearchPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      />

      <Route
        path="/settings"
        components={{ main: SettingsPage, topbar: DefaultTopBar }}
        onEnter={requireLogin}
      >
        <IndexRoute component={SettingsPagePreferences} />
        <Route path="account" component={SettingsPageAccount} />
        <Route path="emails" component={SettingsPageEmails} />
        <Route path="notifications" component={SettingsPageNotifications} />
      <Route path="ecole" component={SettingsPagePremium} />
      <Route path="admin_search" component={SettingsPageAdminSearch} />
        <Route path="admin" component={SettingsPageAdmin} />
        <Redirect path="premium" to="ecole" />
      </Route>

      <Route
        path="/paymentconfirmation/:productId"
        components={{ main: PaymentConfirmationPage, topbar: CleanTopBar }}
        onEnter={requireLogin}
      />

      <Route
        path="/forgotpassword"
        components={{ main: ForgotPassword, topbar: DefaultTopBar }}
      />

      <Route
        path="/resetpassword/:email/:token"
        components={{ main: ResetPassword, topbar: DefaultTopBar }}
      />

      <Route
        path="/ecole"
        components={{ main: PremiumPageAda, topbar: DefaultTopBar }} 
      />

      <Route
        path="/partenaires"
        components={{ main: PartnersAndAuthorsPage, topbar: DefaultTopBar }} 
      />

      <Route
        path="/homepage"
        components={{ main: HomeNotConnectedAda, topbar: DefaultTopBar }} 
      />

      <Route
        path="/atelier/:productId"
        components={{ main: LandingPageParcours, topbar: DefaultTopBar }} 
      />

      <Route
        path="/communaute"
        components={{ main: CommunautePageAda, topbar: DefaultTopBar }} 
      />

      <Redirect path="/premium" to="/ecole" />
      <Route
        path="/communaute"
        components={{ main: CommunautePageAda, topbar: DefaultTopBar }} 
      />
     {/*  <Route
        path="/academie/sign"
        onEnter={requireLogin}
        components={{ main: CheckoutPage, topbar: DefaultTopBar }} />
      <Redirect path="/premium/sign" to="/academie/sign" /> */}
      {/* No button links to the about section */}
      {/* <Route
      <Redirect path="/premium/sign" to="/academie/sign" />

      <Route
        path="/paymenthub"
        components={{ main: PaymentHubPage, topbar: CleanTopBar }}
        onEnter={requireLogin}
      />

      <Route
        path="/about"
        components={{ main: About, topbar: DefaultTopBar }}
      /> */}

      <Route
        path="/paymenthub"
        components={{ main: PaymentHubPage, topbar: CleanTopBar }}
        onEnter={requireLogin}
      />
      <Route
        path="/faq"
        components={{ main: Faq, topbar: DefaultTopBar }}
      />
      <Route
        path="/mentions"
        components={{ main: Mentions, topbar: DefaultTopBar }}
      />
      <Route
        path="/cgu"
        components={{ main: CGU, topbar: DefaultTopBar }}
      />
      <Route
        path="/cgv"
        components={{ main: CGV, topbar: DefaultTopBar }}
      />
      <Route
        path="/privacy"
        components={{ main: Privacy, topbar: DefaultTopBar }}
      />
      {/* <Route
        path="/cookies"
        components={{ main: Cookies, topbar: DefaultTopBar }}
      /> */}
      <Route
        path="/moderation"
        components={{ main: Moderation, topbar: DefaultTopBar }}
      />
      {/* <Route
        path="/presse"
        components={{ main: Presse, topbar: DefaultTopBar }}
      /> */}
      {/* <Route
        path="/partenariats"
        components={{ main: Partenariats, topbar: DefaultTopBar }}
      /> */}
      <Route
        path="/sensiblecontent"
        components={{ main: SensibleContent, topbar: DefaultTopBar }}
      />
      <Route
        path="/intproperty"
        components={{ main: IntProperty, topbar: DefaultTopBar }}
      />
      <Route
        path="/contact"
        components={{ main: Contact, topbar: DefaultTopBar }}
      />
      <Route
        path="/unsub"
        components={{ main: Unsubscribe, topbar: DefaultTopBar }}
      />
      <Route
        path="/login"
        onEnter={requireVisitor}
        components={{ main: Login, topbar: DefaultTopBar }}
      />
      <Route
        path="/signup"
        onEnter={requireVisitor}
        components={{ main: SignUp, topbar: DefaultTopBar }}
      />
      <Route path="*" components={{ main: NotFound, topbar: DefaultTopBar }} />
    </Route>
  );
};
