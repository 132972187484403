import { observable } from 'mobx';
import EditorQuestionModel from './EditorQuestionModel';

export default class EditorFormModel {

  @observable name: string;
  @observable email: string;
  @observable accept: boolean;

  @observable questionId: number;
  @observable questions: EditorQuestionModel[];

  constructor(name: string, email: string, accept: boolean,
    questionId: number, questions: EditorQuestionModel[]) {
    this.name = name;
    this.email = email;
    this.accept = accept;
    this.questionId = questionId;
    this.questions = questions || [];
  }

  setQuestions(questions: {
    question: string, idx: number, multiple: boolean, large: boolean, mandatory: boolean,
    answers: string[]
  }[]) {
    this.questions = questions.map((q) => {
      const answers: { answer: string, selected: boolean }[] =
        q.answers.map((a) => { return { answer: a, selected: false }; });
      return new EditorQuestionModel(q.question, q.idx, q.multiple, q.large, q.mandatory, answers, '');
    });
  }

  toJS() {
    return {
      name: this.name,
      email: this.email,
      accept: this.accept,
      questionId: this.questionId,
      questions: this.questions.map(q => q.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const questions = object.questions.map((q: any) => EditorQuestionModel.fromJS(q));
      return new EditorFormModel(object.name, object.email, object.accept,
        object.questionId, questions);
    }
    return undefined;
  }
}
