import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import * as bowser from 'bowser';
const ReactPlayer = require('react-player');
const LazyLoad = require('react-lazy-load').default;
import Store from '../../stores/Store';
import AlertModel from '../../models/AlertModel';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Title from '../misc/Title';
import '../ada.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import MultipleAuthorBioModal from './MultipleAuthorBioModal';
import TrailerModal from './TrailerModal';
import ContactForm from '../misc/ContactForm';
import TestimonySlider from '../misc/TestimonySlider';
import EditorsSlider from '../misc/EditorsSlider';
import NewsletterWidget from '../home/NewsletterWidget';


interface PremiumPageAdaProps {
  store?: Store;
}
interface PremiumPageAdaState {
  trailerModalActive: boolean;
  trailerContent: string; 
  trailerTitle: string;
  authorBioModalActive: boolean;
  authorBioContent: string; 
  authorBioTitle: string;
  contactFormModalActive: boolean;
}


@inject('store') @observer
export default class PremiumPageAda extends React.Component<PremiumPageAdaProps, PremiumPageAdaState> {
  constructor(props: PremiumPageAdaProps) {
    super(props);
    this.state = {
      trailerModalActive: false,
      trailerContent: undefined,
      trailerTitle: undefined,
      authorBioModalActive: false,
      authorBioContent: undefined,
      authorBioTitle: undefined,
      contactFormModalActive: false
    };
    this.hideAuthorBioModal = this.hideAuthorBioModal.bind(this);
    this.activateAuthorBioModal = this.activateAuthorBioModal.bind(this);
    this.hideTrailerModal = this.hideTrailerModal.bind(this);
    this.activateTrailerModal = this.activateTrailerModal.bind(this);
    this.hideContactFormModal = this.hideContactFormModal.bind(this);
    this.showContactFormModal = this.showContactFormModal.bind(this);
  }
  componentDidMount() {
    if(this.props.store.allCards.length === 0)
      this.props.store.loadAllCardsDisconnected();
    }
  signUpAndBuyAny() {
    this.props.store.setRegisteringAny()
    browserHistory.push({
      pathname: '/signup'
    });
  }
  /* buyParcours(marketIdentifier: number, stripeIdentifier: String) {
    this.props.store.buyParcours(marketIdentifier, stripeIdentifier);
    browserHistory.push({
      pathname: '/paymenthub'
    });
  } */
  goToLanding(productId: number) {
    browserHistory.push({
      pathname: '/atelier/'+productId
    });
  }
  goToHub() {
    if (!this.props.store.connected.premium.premiumUI )
      this.props.store.switchUI().then((p: any) => {
        browserHistory.push({
          pathname: '/'
        });
      });
    else
      browserHistory.push({
        pathname: '/'
      });
  }
  activateAuthorBioModal(content: string, title: string) {
    this.setState({authorBioContent: content, authorBioTitle: title, authorBioModalActive: true})
  }
  hideAuthorBioModal() {
    this.setState({authorBioContent: undefined, authorBioTitle: undefined, authorBioModalActive: false})
  }
  activateTrailerModal(trailer: string, title: string) {
    this.setState({trailerContent: trailer, trailerTitle: title, trailerModalActive: true})
  }
  hideTrailerModal() {
    this.setState({trailerContent: undefined, trailerTitle: undefined, trailerModalActive: false})
  }
  hideContactFormModal() {
    this.setState({
      contactFormModalActive: false
    });
  }
  showContactFormModal() {
    this.setState({
      contactFormModalActive: true
    });
  }
  feather(feather: number, id: number) {
    let feathers: any = []
    for (let i = 0; i < feather; i++) { feathers.push(<span className="fa fa-leaf" key={id+'feather'+i}></span>)}
    return feathers;
  }
  render() {
    const connected = this.props.store.connected;
    const premium = connected ? connected.premium : undefined;
    const isConnected = connected && connected.user && connected.user.id

    const allCards = this.props.store.allCards; 
    const allCardsElement = allCards.map((parcours, i) => {
      return (
          parcours.stripeIdentifier !== null ?
          <div id={`atelier_${parcours.id}`} key={i} 
            className="fourteen wide mobile eight wide tablet five wide computer column"
            style={{scrollMargin: "55px"}}
          >  
          <div className="ui card ada-parcours-card">
            <div className="ui image">
                <img src={`${parcours.image}`} />
            </div>
            {/* {parcours.marketIdentifier ? */}
            <div className="content ada-card-border ada-parcours-name">
              <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: parcours.name }} />
            </div>{/* :
            <div className="content ada-card-border ada-parcours-name-soon">
              <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: parcours.name }} />
            </div>} */}
            <div className="content ada-card-border ada-card-else">
              {parcours.authorsLink !== "" ? 
                <div style={{textAlign: "center", flex: 0, flexGrow: 1}}>
                  <b><p className="ada-learn-more" onClick={() => this.activateAuthorBioModal(parcours.authorsDescription, parcours.authorsLink)}>
                  <i className="fa fa-angle-right"/> <u>{parcours.authorsLink}</u>
                  </p></b>
                </div> 
              : undefined}
              {parcours.titleDescription !== "" ? <div style={{flex: 0, flexGrow: 1}} className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: parcours.titleDescription }} /> : undefined}
              {parcours.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
              <div style={{flex: 0, flexGrow: 1}} dangerouslySetInnerHTML={{ __html: parcours.shortDescription }} />
              {parcours.trailerUrl !== "" && parcours.trailerUrl !== null ?  <b><p style={{textAlign: "center"}} className="ada-learn-more"  onClick={() => this.activateTrailerModal(parcours.trailerUrl, "Trailer")}>
                <i className="fa fa-angle-right"/><u> BANDE-ANNONCE</u></p></b> 
              : undefined}
             {/*  {parcours.objective !== "" ? <div className="ui divider"></div> : undefined}
              {parcours.objective !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.objective }} /> : undefined} */}
              
                <div className="ui divider"></div>
              {/* {parcours.feather !== 0 ? <p>Taille du projet : {this.feather(parcours.feather, parcours.id)} </p>  : undefined}
              {parcours.estimatedLength !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.estimatedLength }} /> : undefined} */}
                      {/*{parcours.videosDescription !== "" ?<p><span style={{marginRight: "5px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: parcours.videosDescription }} /></p> : undefined}
                  */}
              {/* {parcours.marketIdentifier ? 
                parcours.productPrice !== 0 ?
                <span>
                  <p>Accès sans limitation de durée à l’atelier. Sans abonnement ou renouvellement.</p>
                <div style={{marginBottom: "15px"}}>
                  {parcours.promotionPercentage ? 
                    <div style={{textAlign: "center"}}>
                      <button style={{marginRight: "5px"}} className="ui button ada-price">
                        <span className="price-cross">{parcours.productPrice} €</span>
                      </button>
                      <button style={{marginLeft: "5px"}} className="ui button ada-price-promo">
                        <span>  -{parcours.promotionPercentage}% </span><i className="fa fa-arrow-right" />
                        <span><b> {parcours.currentPrice} €</b></span>
                      </button>
                      </div>
                  :
                  <div style={{textAlign: "center"}}>
                    <button className="ui button ada-price">
                      {parcours.productPrice} €
                    </button>
                  </div>
                }
                    </div>
                    </span>
                :
                  undefined
                  :
                  undefined
                } */}
              {parcours.marketIdentifier ?
              parcours.id ?
                isConnected ?
                  premium && (premium.allInclusive || ((premium.marketIdentifier & parcours.marketIdentifier) != 0)) ?
                  <div className="sub header">
                      <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                        <button
                          className="ui primary button"
                          onClick={() => this.goToHub()}
                          style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                        >
                          Accéder à l'atelier
                        </button>
                      </div>
                    </div>
                  :
                    <div className="sub header" style={{flex: 0, flexGrow: 1}}>
                      <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                        <button
                          className="ui primary button ada-background editis-bleu" 
                          onClick={() => this.goToLanding(parcours.productId)} 
                          style={{ cursor: 'pointer'}}
                          id={"ecole_atelier_CTA_"+parcours.productId}
                        >
                          Découvrir cet atelier
                        </button>
                      </div>
                    </div>
                    
                : 
                  <div className="sub header">
                    <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                      <button
                        className="ui primary button ada-background editis-bleu" 
                        onClick={() => this.goToLanding(parcours.productId)} 
                        style={{ cursor: 'pointer'}}
                        id={"ecole_atelier_CTA_"+parcours.productId}
                      >
                        Découvrir cet atelier
                      </button>
                    </div>
                  </div>
                  
              : undefined
            :
              undefined
            }
              </div>
          </div> 
        </div>: undefined
                        
      );
    });
    const allCardsElementReduced = allCards.map((parcours, i) => {
      return (
        parcours.stripeIdentifier != null ?
        <div key={i} className="twelve wide mobile four wide tablet three wide computer column" 
          onClick={() => document.getElementById(`atelier_${parcours.id}`).scrollIntoView({behavior: "smooth"})}
          style={{cursor: "pointer"}}
        >
            <div className="ui card ada-bonus-parcours-card">
              <div className="ui image">
                  <img src={`${parcours.shortImage}`} />
              </div>
              <div className="content ada-card-border ada-bonus-parcours-name">
                <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: parcours.shortName }} />
              </div>
            </div>
        </div>              
          : 
            undefined
      );
    });

    const allCardsBonus = allCards.map((parcours, i) => {
      return (
        parcours.stripeIdentifier == null ?
          <div key={i} className="twelve wide mobile six wide tablet four wide computer column">
            <div className="ui card ada-bonus-parcours-card">
              <div className="ui image">
                  <img src={`${parcours.image}`} />
              </div>
              <div className="content ada-card-border ada-bonus-parcours-name" style={{padding: "0.9em 0.9em"}}>
                <span style={{color: "white", textAlign: "center", fontSize: "1.1rem"}} className="header">{parcours.name}</span>
            </div>
            {parcours.trailerUrl !== "" && parcours.trailerUrl !== null ? 
              <div className="content ada-card-border ada-card-else">
              <b><p className="ada-learn-more" style={{textAlign: "center", fontSize:"0.8rem"}} 
                  onClick={() => this.activateTrailerModal(parcours.trailerUrl, "Trailer")}>
                BANDE-ANNONCE
              </p></b>
            
                {/* {parcours.titleDescription !== "" ? <div className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: parcours.titleDescription }} /> : undefined}
                {parcours.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
                {parcours.authorsLink !== "" ? <div>
                  <b><p className="ada-learn-more" style={{fontSize:"0.8rem"}} onClick={() => this.activateAuthorBioModal(parcours.authorsDescription, parcours.authorsLink)}>
                    {parcours.authorsLink}
                  </p></b>
                  </div> : undefined}
                  <div className="ui divider"></div>
                {parcours.videosDescription !== "" ?<p style={{fontSize: "0.9rem"}}><span style={{marginRight: "5px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: parcours.videosDescription }} /></p> : undefined} */}
              </div>
              : undefined
              }
            </div>
          </div> 
        : 
          undefined
      );
    });


    const metaProperties = [
      {
        name: 'description',
        content: `Rejoignez l\'Atelier des Auteurs, l'école d'écriture pour votre premier roman.`
      },
      { name: 'twitter:card', content: 'player' },
      { name: 'twitter:site', content: '@atelier_auteurs' },
      { name: 'twitter:title', content: 'L\'Atelier des Auteurs, l\'école d\'écriture' },
      {
        name: 'twitter:description',
        content: 'Rejoignez l\'Atelier des Auteurs, l\'école d\'écriture pour votre premier roman.'
      },
      {
        name: 'twitter:image',
        content: 'https://scribay.s3.amazonaws.com/assets/logo_square.png'
      },
      { property: 'fb:app_id', content: '460911020714321' },
      { property: 'og:type', content: 'article' },
      { property: 'og:title', content: 'L\'Atelier des Auteurs, l\'école d\'écriture' },
      { property: 'og:image', content: 'https://scribay.s3.amazonaws.com/assets/logo_square.png' },
      {
        property: 'og:description',
        content: 'Rejoignez l\'Atelier des Auteurs, l\'école d\'écriture pour votre premier roman.'
      },
      { property: 'og:site_name', content: 'Atelier des auteurs' },
      { property: 'article:publisher', content: 'https://www.facebook.com/atelierdesauteurs' },
    ];
/*     const currentPromotion = this.props.store.mainSettings.admin.currentPromotion;
 */    return (
      <div style={{backgroundColor: '#fff'}}>
        <Title title="L'école d'écriture" meta={metaProperties} scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            '@type': "Organization",
            "url": "https://www.atelierdesauteurs.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://scribay.s3.amazonaws.com/assets/logo_square.png"
            }
          })
        }]} />
        {/* <NewsletterWidget/> */}
          <div className="ui" style={{ marginTop: '-25px' }}>
            <div className="academie-ada">
                <div className="ui container">
                  <div style={{ maxWidth: '600px' }}>
                    <h1 className="ada-title-font ada-text editis-bleu-nuit ada-museo-500" style={{ lineHeight: "0.9", paddingTop: "135px", fontSize: '5rem' }}>La première école d'écriture en ligne</h1>
                    <h2 className="ada-title-font ada-text editis-bleu ada-museo-300"style={{ marginTop:"-5px", fontSize: '2rem' }}>issue du monde de l'édition</h2>
                      <button
                        className="ui button ada-background editis-bleu" 
                        onClick={() => document.getElementById('ateliers').scrollIntoView({behavior: "smooth"})} 
                        style={{ cursor: 'pointer', color: "white"}}
                      >
                      <i className="fa fa-angle-right"></i> Nos ateliers d'écriture
                      </button>
                  </div>
                </div>
              </div>
          </div>
          <div className="ada-background editis-beige-clair" style={{paddingBottom: "35px", marginTop: "-25px"}} >
          <div className="ui center aligned container">
            <h4 className="ui horizontal hide-tablet divider ada-divider">Par les auteurs, pour les auteurs</h4>
            <h4 className="ui horizontal hide-tablet-large ada-divider">Par les auteurs, pour les auteurs</h4>
            <div className="ui centered three column very relaxed grid stackable" style={{paddingBottom: '15px'}}>
              <div className="five wide column">
                <div className="ui centered grid">
                  <img style={{marginTop: "30px"}} src="https://scribay.s3.amazonaws.com/assets/picto_face_cam.png"
                    alt="plume" width="60%" height="60%"/>
                    <div style={{marginTop: "0px"}} className="row ada-pastille-subtitle"><p>Plus de 300 Vidéos de <br/>conseils et de témoignages</p></div>
                </div>
              </div>
              <div className="five wide column">
                <div className="ui centered grid">
                  <img style={{marginTop: "30px"}} src="https://scribay.s3.amazonaws.com/assets/picto_peoples.png"
                    alt="plume" width="60%" height="60%"/>
                    <div style={{marginTop: "0px"}} className="row ada-pastille-subtitle"><p>En lien avec une communauté<br/> de 70 000 passionnés</p></div>
                </div>
              </div>
              <div className="five wide column">
                <div className="ui centered grid">
                <img style={{marginTop: "30px"}} src="https://scribay.s3.amazonaws.com/assets/picto_writting.png"
                    alt="picto_people" width="60%" height="60%"/>
                      <div style={{marginTop: "0px"}} className="row ada-pastille-subtitle"><p style={{width: "90%"}}>Avancez à votre rythme et luttez contre la procrastination</p></div>
                  </div>
              </div>
            </div>
            <div className="ui center aligned container" id="universel" style={{scrollMargin: "20px"}}>
            <h4 className="ui horizontal divider hide-tablet ada-divider" style={{marginBottom:"20px"}}>L'UNIVERSEL</h4>
            <h4 className="ui horizontal hide-tablet-large ada-divider" style={{marginBottom:"20px"}}>L'UNIVERSEL</h4>
              <div className="ui relaxed centered grid stackable cards" style={{marginBottom: "15px"}}>
              <div key={"allInclusive"} className="fifteen wide column" id="universal">
                  <div className="ui card ada-huge-parcours-card" >
                    <div className="ui image">
                        <img src={`https://scribay.s3.amazonaws.com/parcours/parcours_universel.jpg`} />
                    </div>
                    <div className="content ada-card-border ada-parcours-name">
                      <span style={{color: "white", textAlign: "center"}} className="header">PACK UNIVERSEL : offre d'un an <br/>
                        Accès illimité à tous les ateliers et bonus exclusifs</span>
                    </div>
                    <div className="content ada-card-border ada-card-else-no-bottom">
{/*                       <div style={{textAlign: "center", marginTop: "15px"}} className="ada-parcours-title">Accédez à tous nos parcours et à tous nos futurs contenus à vie.</div> 
 */}                      {/* <div className="ui divider"></div> */}
                      {/* <div>
                        <p>L’Atelier des Auteurs compte chaque mois de nouveaux contenus. </p>
                      <p>Cette offre vous permettra d’y accéder sans frais et sans achat supplémentaire, en plus de débloquer l’ensemble des cours déjà compris dans l’école.</p>
                    </div> */}
                      {/* <div className="ui divider"></div>
                       <p>Durée estimée : <FontAwesomeIcon icon={faInfinity} /></p> */}
                      <h5 className="ui horizontal ada-card-divider hide-tablet divider">INCLUS DANS CE PACK : l’ensemble des cours, exercices et témoignages (300 vidéos)</h5>
                      <h5 className="ui horizontal ada-card-divider hide-tablet-large">INCLUS DANS CE PACK : l’ensemble des cours, exercices et témoignages (300 vidéos)</h5>
                      <div className="ui relaxed centered grid cards" style={{marginTop: "20px", paddingBottom: '30px'}}>
                        {allCardsElementReduced}
                      </div>
                      <h5 className="ui horizontal ada-card-divider hide-tablet divider"><b>NOS BONUS EXCLUSIFS :</b> paroles d’auteurs et d’éditeurs (28 vidéos)</h5>
                      <h5 className="ui horizontal ada-card-divider hide-tablet-large"><b>NOS BONUS EXCLUSIFS :</b> paroles d’auteurs et d’éditeurs (28 vidéos)</h5>
                      <div style={{textAlign: "center"}} >
                        Des conseils et témoignages exclusifs !<br/>
                        Contenus bonus disponibles uniquement dans le pack Universel.
                      </div>
                      <div className="ui relaxed centered grid cards" style={{marginTop: "15px", paddingBottom: '20px'}}>
                        {allCardsBonus}
                      </div>
                      {/* <div style={{textAlign: "center", fontWeight: "bold"}} >
                        Valeur cumulée des ateliers à ce jour : 620€
                      </div> */}
                      </div>
                      <div className="content ada-card-border ada-subscribe-info">
                        <div style={{textAlign: "center"}} >
                          <span style={{color: "white", textAlign: "center", fontSize: "1.4rem"}} className="header">
                            Offre d'<b>un an sans engagement</b>.
                          </span>
                        </div>
                      </div>
                      <div className="content ada-card-border ada-card-else">
                      {/* <div style={{textAlign: "center", marginTop: "25px"}}>
                        <button style={{marginRight: "5px"}} className="ui button ada-price">
                          <span className="price-cross">180 €</span>
                        </button>
                        <button style={{marginLeft: "5px"}} className="ui button ada-price-promo">
                          <span>  -50% </span> <i className="fa fa-arrow-right" /> 
                          <span><b> 90 €</b></span>
                        </button>
                      </div>  */}
                        {/* <div style={{textAlign: "center"}}>
                          <button className="ui button ada-price">
                            580 €
                          </button>
                        </div> */}
                      { isConnected &&
                      premium && premium.allInclusive ?
                        <div className="sub header">
                          <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                            <button
                              className="ui primary button"
                              onClick={() => this.goToHub()}
                              style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                            >
                              Accéder aux cours
                            </button>
                          </div>
                        </div> 
                        /* <div className="sub header">
                          <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                            <button
                              className="ui disabled button"
                              style={{ marginTop: "5px", cursor: 'pointer'}}
                            >
                              Bientôt disponible
                            </button>
                          </div>
                        </div> */
                      :
                        <div className="sub header">
                          <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                            <button
                              className="ui primary button ada-background editis-bleu"
                              onClick={() => this.goToLanding(6000)} 
                              style={{ marginTop: "5px", cursor: 'pointer'}}
                            >
                              Découvrir l'universel
                            </button>
                          </div>
                        </div> 
                        /* <div className="sub header">
                          <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                            <button
                              className="ui disabled button"
                              style={{ marginTop: "5px", cursor: 'pointer'}}
                            >
                              Bientôt disponible
                            </button>
                          </div>
                        </div> */
                      }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          
        <div className="ui center aligned container" id="ateliers" style={{scrollMargin: "20px"}}>
          <h4 className="ui horizontal divider hide-tablet ada-divider">Nos ateliers d'écriture à la carte</h4>
          <h4 className="ui horizontal hide-tablet-large ada-divider">Nos ateliers d'écriture à la carte</h4>
            <div className="ui centered grid cards">
              {allCardsElement}
              <MultipleAuthorBioModal 
              active={this.state.authorBioModalActive} hide={this.hideAuthorBioModal}
              content={this.state.authorBioContent} title={this.state.authorBioTitle}/>
              <TrailerModal 
              active={this.state.trailerModalActive} hide={this.hideTrailerModal}
              content={this.state.trailerContent} title={this.state.trailerTitle}/>
            </div>
        </div>
        <div>
          <div className="ada-background editis-beige-clair" style={{paddingBottom:"25px" }} >
            <div className="ui center aligned container" id="universel" style={{scrollMargin: "20px"}}>
              {<TestimonySlider/>}
            </div>
          </div>
          </div>
          <div className="ui center aligned container"  style={{paddingBottom: "50px", paddingTop: "20px"}}>
            <div className="ui center aligned container">
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            
            {<EditorsSlider/>}
            </div>
          </div>
          <div className="ada-background editis-beige-clair" style={{paddingBottom: "35px", paddingTop: "35px"}}>
            <div className="ui center aligned container" >
              <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>Bande-annonce de nos Ateliers</h4>
              <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>Bande-annonce de nos Ateliers</h4>
              <div className="ui centered grid">
                <div className="ui hide-mobile nine wide column">
                  <div className="player-wrapper single-video-ada react-player">
                    <ReactPlayer
                      url="https://vimeo.com/699772909"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
                <div className="ui hide-large sixteen wide column">
                  <div className="player-wrapper single-video-ada react-player">
                    <ReactPlayer
                      url="https://vimeo.com/702054726"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
              <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
              <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
              <p>Vous souhaitez plus d'informations sur l'Atelier des Auteurs ?</p>
              <p>Envoyez-nous un message et nous vous répondrons au plus vite !</p>
              {<Modal
                open={this.state.contactFormModalActive}
                onClose={this.hideContactFormModal}
              >
                <div className="header">Contactez-nous</div>
                <div className="content" style={{maxHeight: '400px' }}>
                  <ContactForm location="ecole"/>
                </div>
                <div className="actions">
                  <button className="ui cancel button" onClick={this.hideContactFormModal}>Fermer</button>
                </div>
              </Modal>}
              <button
                className="ui button ada-background editis-bleu" 
                onClick={() => this.showContactFormModal()} 
                style={{ marginTop: "25px", cursor: 'pointer', color: "white"}}
              >
                Contactez-nous
              </button>
            </div>
          </div>
          {/* 
          <div className="ui container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal divider hide-tablet ada-divider">52 éditeurs partenaires</h4>
            <h4 className="ui horizontal hide-tablet-large ada-divider">52 éditeurs partenaires</h4>
            <div className="ui middle aligned relaxed grid">
              <div className="seven wide column ada-text editis-bleu-nuit" 
              style={{borderRight: "solid", borderWidth:"1px", textAlign:"right", paddingTop: "65px", paddingBottom: "65px", fontSize: "1.2rem" }}>
                <p>
                  Des témoignages exclusifs d’éditeurs
                </p>
                <p style={{marginTop: "-15px"}}>
                  et de professionnels de l'édition.
                </p>
              </div>
              <div className="seven wide right floated column" style={{overflow: "hidden"}}>
              <div className="player-wrapper single-video-ada react-player">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=r0z8pE9Ix9M"
                  width="100%"
                  height="100%"
                />
              </div>
              </div>
            <div className="seven wide left floated column" style={{overflow: "hidden"}}>
                  <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/two-books.jpg"
                    alt="Two books" width="100%" />
              </div>
              <div className="seven wide column ada-text editis-bleu-nuit" 
              style={{borderLeft: "solid", borderWidth:"1px", textAlign:"left", paddingTop: "65px", paddingBottom: "65px", fontSize: "1.2rem" }}>
                <p>
                  <b>Prenez le temps d'écrire</b>
                </p>
                <p style={{marginTop: "-15px"}}>
                  Notre méthode vous accompagne 
                </p>
                <p style={{marginTop: "-17px"}}>
                  sur plusieurs mois.
                </p>
                <p style={{marginTop: "-15px"}}>
                  Grâce aux conseils de nos auteurs, écrivez
                </p>
                <p style={{marginTop: "-17px"}}>
                  votre roman de la première à la dernière ligne.
                </p>
              </div>
            </div>
          </div> */}

         {/*  <div className="ui center aligned container">
              <h4 className="ui horizontal divider ada-divider">Nos tarifs</h4>
              <table className="ui center aligned collapsing celled unstackable table ada-table" style={{margin:"auto"}}>
                <thead>
                  <tr>
                    <th style={{width:"25em", backgroundColor:"rgb(20, 100, 146)", color:"white"}}>L'universel</th>
                    <th style={{width:"25em", backgroundColor:"rgb(209, 175, 162)", color: "rgb(0, 28, 54)"}}>Au cours</th>
                  </tr>
                </thead>
                <tbody style={{color: "rgb(0, 28, 54)"}}>
                  <tr>
                    <td>Achat définitif et illimité de tous les cours</td>
                    <td>Achat définitif</td>
                  </tr>
                  <tr>
                    <td>De nouveaux contenus tous les mois</td>
                    <td>Accès à un seul cours</td>
                  </tr>
                  <tr>
                    <td>580 €</td>
                    <td>de 160 à 265 €</td>
                  </tr>
                </tbody>
              </table>
          </div> */}


      </div>
    );
  }
}
