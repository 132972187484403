import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import ParcoursSummaryModel from '../../../models/parcours/ParcoursSummaryModel';
import ParcoursSummaryExampleModel from '../../../models/parcours/ParcoursSummaryExampleModel';
import ParcoursSummaryContentModel from '../../../models/parcours/ParcoursSummaryContentModel';
import Store from '../../../stores/Store';

interface ParcoursSummaryFieldProps {
  store?: Store;
  content: ParcoursSummaryContentModel;
  showExamples: Function | any;
  onChange: Function | any;
  parcoursId: number;
}

@inject('store') @observer
class ParcoursSummaryField extends React.Component<ParcoursSummaryFieldProps, {}> {
  constructor(props: ParcoursSummaryFieldProps) {
    super(props);
  }
  render() {
    const content = this.props.content;
    return (
      <div className="field" key={content.index}>
        <label>{content.title}</label>
        {this.props.showExamples ?
          <div className="ui action input">
            <input type="text" value={content.body} onChange={(e: any) => this.props.onChange(this.props.content, e.target.value)} />
            <button className="ui icon button"
              data-tooltip="Voir des exemples" data-inverted="" data-position="left center"
              onClick={() => this.props.showExamples(content)}
            >
              <i className="fa fa-question" />
            </button>
          </div>
          :
          <input type="text" value={content.body} onChange={(e: any) => this.props.onChange(this.props.content, e.target.value)} />
        }
      </div>
    );
  }
}

interface ParcoursSummaryProps {
  parcoursId: number;
  summary: ParcoursSummaryModel;
  summaryExamples: ParcoursSummaryExampleModel[];
  store?: Store;
  onSummaryChange: Function | any;
}

interface ParcoursSummaryState {
  currentContent: ParcoursSummaryContentModel;
  examples: ParcoursSummaryModel[];
  currentExample: ParcoursSummaryModel;
}

@inject('store') @observer
export default class ParcoursSummary extends React.Component<ParcoursSummaryProps, ParcoursSummaryState> {
  constructor(props: ParcoursSummaryProps) {
    super(props);
    this.state = { currentContent: undefined, examples: [], currentExample: undefined };
    this.showExamplesForIndex = this.showExamplesForIndex.bind(this);
    this.hideExamplesForIndex = this.hideExamplesForIndex.bind(this);
    this.showExample = this.showExample.bind(this);
    this.hideExample = this.hideExample.bind(this);
  }
  showExamplesForIndex(content: ParcoursSummaryContentModel) {
    this.props.store.loadParcoursSummaryExamplesForIndex(this.props.parcoursId, content.index).then((examples: any[]) => {
      this.setState({
        currentContent: content,
        examples: examples
      });
    });
  }
  hideExamplesForIndex() {
    this.setState({
      currentContent: undefined,
      examples: []
    });
  }
  showExample(exempleId: number) {
    this.props.store.loadParcoursSummaryExample(this.props.parcoursId, exempleId).then((example: any) => {
      this.setState({
        currentExample: example
      });
    });
  }
  hideExample() {
    this.setState({
      currentExample: undefined
    });
  }
  render() {
    const fields = this.props.summary.contents.map((content) => {
      return (
        <ParcoursSummaryField key={content.index} content={content}
          showExamples={this.props.summaryExamples.length > 0 ? this.showExamplesForIndex : undefined}
          onChange={this.props.onSummaryChange} parcoursId={this.props.parcoursId} />
      );
    });
    const allExamples = this.props.summaryExamples.map((example) => {
      return (
        <Dropdown.Item
          key={`example_${example.id}`}
          text={example.title}
          onClick={() => this.showExample(example.id)} />
      );
    });
    const examplesForIndex = this.state.examples.map((example) => {
      return (
        <div className="field" key={example.title}>
          <label>{example.title}</label>
          <div className="ui message" dangerouslySetInnerHTML={{ __html: example.contents[0].body }} />
        </div>
      );
    });
    const partsForExample = this.state.currentExample ? this.state.currentExample.contents.map((content) => {
      return (
        <div className="field" key={content.title}>
          <label>{content.title}</label>
          <div className="ui message" dangerouslySetInnerHTML={{ __html: content.body }} />
        </div>
      );
    }) : [];
    return (
      <div>
        <Modal
          open={this.state.currentContent !== undefined}
          onClose={this.hideExamplesForIndex}
          size="small"
        >
          <div className="header">Exemples pour "{this.state.currentContent ? this.state.currentContent.title : ''}"</div>
          <div className="content">
            <div className="ui form">
              {examplesForIndex}
            </div>
          </div>
          <div className="actions">
            <button className="ui button" onClick={this.hideExamplesForIndex}>
              Fermer
          </button>
          </div>
        </Modal>
        <Modal
          open={this.state.currentExample !== undefined}
          onClose={this.hideExample}
          size="small"
        >
          <div className="header">{this.state.currentExample ? this.state.currentExample.title : ''}</div>
          <div className="content">
            <div className="ui segment form" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
              {partsForExample}
            </div>
          </div>
          <div className="actions">
            <button className="ui button" onClick={this.hideExample}>
              Fermer
          </button>
          </div>
        </Modal>
        <div className="ui basic segment">
          <div className="ui form">
            {fields}
          </div>
          {allExamples.length > 0 ?
            <div>
              <div className="ui hidden divider" />
              <Dropdown text="Exemples" className="right floated button" pointing="bottom left">
                <Dropdown.Menu>
                  {allExamples}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            : undefined}
        </div>
      </div>
    );
  }
}
