export default class DefiModel {
    id: number;
    title: string;
    urlTitle: string;

    constructor(id: number, title: string, urlTitle: string) {
      this.id = id;
      this.title = title;
      this.urlTitle = urlTitle;
    }

    toJS() {
      return {
        id: this.id,
        title: this.title,
        urlTitle: this.urlTitle
      };
    }

    static fromJS(object: any) {
      if (object) {
        return new DefiModel(object.id, object.title, object.urlTitle);
      }
      return undefined;
    }
}
