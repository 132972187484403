import * as React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../../stores/Store';
import CharacterForm from './CharacterForm';
import CharacterFullModel from '../../../../models/write/CharacterFullModel';

interface CharactersListProps {
  store?: Store;
  textId: number;
  active: boolean;
  hide: Function | any;
  chapterIndex?: number;
}

interface CharactersListState {
  formActive: boolean;
  removeActive: boolean;
  selectedCharacter: number;
}

@inject('store') @observer
export default class Characters extends React.Component<CharactersListProps, CharactersListState> {
  constructor(props: CharactersListProps) {
    super(props);
    this.saveCharacters = this.saveCharacters.bind(this);
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.remove = this.remove.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.cancelRemove = this.cancelRemove.bind(this);
    this.selectList = this.selectList.bind(this);
    this.unselectList = this.unselectList.bind(this);
    this.goToChapter = this.goToChapter.bind(this);

    this.state = {
      formActive: false,
      removeActive: false,
      selectedCharacter: undefined
    };
  }
  saveCharacters() {
    const characters = this.props.store.write.currentText.characters
      .filter(c => c.inChapter)
      .map(c => c.id);
    this.props.store.addRemoveCharactersToChapter(characters).then((d: any) => {
      this.setState({
        formActive: false,
        removeActive: false,
        selectedCharacter: undefined
      });
    });
    this.props.hide();
  }
  @action
  showForm(characterId?: number) {
    if (characterId) {
      this.props.store.loadCharacter(this.props.textId, characterId).then((character: any) => {
        this.setState({
          formActive: true,
          removeActive: false,
          selectedCharacter: undefined
        });
      });
    } else {
      this.props.store.write.currentText.currentCharacter = new CharacterFullModel(
        undefined, '', '<div></div>', undefined, undefined, false);
      this.setState({
        formActive: true,
        removeActive: false,
        selectedCharacter: undefined
      });
    }
  }
  @action
  hideForm() {
    this.props.store.write.currentText.currentCharacter = undefined;
    this.setState({
      formActive: false,
      removeActive: false,
      selectedCharacter: undefined
    });
  }
  remove(characterId: number) {
    this.setState({
      formActive: false,
      removeActive: true,
      selectedCharacter: characterId
    });
  }
  @action
  confirmRemove() {
    this.props.store.removeCharacter(this.props.textId, this.state.selectedCharacter).then((d: any) => {
      const currentCharacter = this.props.store.write.currentText.currentCharacter;
      if (currentCharacter && currentCharacter.id && this.state.selectedCharacter === currentCharacter.id) {
        this.props.store.write.currentText.currentCharacter = undefined;
      }
      this.setState({
        formActive: false,
        removeActive: false,
        selectedCharacter: undefined
      });
    });
  }
  cancelRemove() {
    this.setState({
      formActive: false,
      removeActive: false,
      selectedCharacter: undefined
    });
  }
  @action selectList(characterId: number) {
    const characters = this.props.store.write.currentText.characters
      .filter(c => c.id === characterId);
    if (characters.length > 0) {
      characters[0].inChapter = true;
    }
  }
  @action unselectList(characterId: number) {
    const characters = this.props.store.write.currentText.characters
      .filter(c => c.id === characterId);
    if (characters.length > 0) {
      characters[0].inChapter = false;
    }
  }
  goToChapter(chapterId: number) {
    const textId = this.props.store.write.currentText.id;
    const urlText = this.props.store.write.currentText.infos.urlText;
    this.hideForm();
    this.props.hide();
    browserHistory.push({
      pathname: `/text/${textId}/${urlText}/chapter/${chapterId}/edit`,
      state: {
        newPart: false,
        showCongratulationsChapter: false
      }
    });
  }
  render() {
    const currentText = this.props.store.write.currentText;
    const characters = currentText.characters;
    const charactersList = characters.map(c => {
      return (
        <tr key={c.id}>
          {/* {this.props.chapterIndex ?
            <td>
              <Checkbox
                checked={c.inChapter}
                onClick={(e: any) => {
                  if (c.inChapter) {
                    this.unselectList(c.id);
                  } else {
                    this.selectList(c.id);
                  }
                }}
              />
            </td>
            : undefined} */}
          <td style={{ width: '50px' }}>
            <img src={c.image} className="ui mini rounded image"
              alt={`Image du personnage ${c.name}`} />
          </td>
          <td>
            {c.name}
          </td>{/* 
          <td>
            {c.chapters.length > 0 ? c.chapters.join(', ') : <span />}
          </td> */}
          <td className="right aligned">
            <button
              title="Voir"
              className="ui mini blue button"
              style={{ marginRight: '5px' }}
              onClick={() => this.showForm(c.id)}
            >
              Détails
          </button>
            {!c.undeletable ?
              <button
                title="Supprimer"
                className="ui mini red button"
                style={{ marginRight: '5px' }}
                onClick={() => this.remove(c.id)}
              >
                Supprimer
          </button>
              : undefined}
          </td>
        </tr>
      );
    });
    let style: {};
    if (this.props.chapterIndex) {
      style = { cursor: 'pointer', fontSize: '12px' };
    } else {
      style = { cursor: 'pointer' };
    }
    return (
      <div>
        {currentText.currentCharacter ?
          <CharacterForm textId={this.props.textId} chapterIndex={this.props.chapterIndex} active={this.state.formActive} hide={this.hideForm}
            goToChapter={this.goToChapter} currentCharacter={currentText.currentCharacter} />
          : undefined}
        <Modal
          open={this.state.removeActive}
          onClose={() => { }}
        >
          <div className="content">
            Confirmez-vous la suppression de ce personnage ?
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.confirmRemove}
            >
              Oui
          </button>
            <button className="ui cancel button" onClick={this.cancelRemove}>Non</button>
          </div>
        </Modal>
        <Modal
          open={this.props.active}
          size="small"
          onClose={this.props.hide}
        >
          <div className="header">Personnages</div>
          <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }} >
            {/* {this.props.chapterIndex ?
              <div className="ui info message">
                Sélectionnez les personnages qui interviennent dans ce chapitre.
              </div>
              : undefined} */}
            <table className="ui table">
              <tbody>
                {charactersList.length > 0
                  ? charactersList
                  : <tr>
                    <td colSpan={3} className="center aligned">
                      Aucun personnage
                  </td>
                  </tr>
                }
              </tbody>
            </table>
            <div style={{ marginBottom: '40px' }}>
              <button
                onClick={() => this.showForm()}
                className="ui mini right floated button"
              >
                <i className="fa fa-plus" style={{ marginRight: '5px' }} />
                Nouveau personnage
                </button>
            </div>
          </div>
          <div className="actions">
            {/* {this.props.chapterIndex ?
              <button className="ui primary button" onClick={this.saveCharacters}>
                Enregistrer
              </button>
              : undefined} */}
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
        </Modal>
      </div >
    );
  }
}

