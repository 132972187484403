import { observable } from 'mobx';

class LightChapterModel {
  @observable id: number;
  @observable index: number;
  @observable title: string;
}

export default class CurrentDefiModel {

  @observable id: number;
  @observable title: string;
  @observable urlText: string;
  @observable cover: string;
  @observable defiTitle: string;
  @observable deadline: string;
  @observable chapters: LightChapterModel[];

  constructor(id: number, title: string, urlText: string, cover: string,
    defiTitle: string, deadline: string, chapters: LightChapterModel[]) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.cover = cover;
    this.defiTitle = defiTitle;
    this.deadline = deadline;
    this.chapters = chapters || [];
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      cover: this.cover,
      defiTitle: this.defiTitle,
      deadline: this.deadline,
      chapters: this.chapters
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentDefiModel(object.id, object.title, object.urlText,
        object.cover, object.defiTitle, object.deadline, object.chapters);
    }
    return undefined;
  }
}
