import UserModel from '../UserModel';
import LightTextModel from '../LightTextModel';
import DefiModel from '../DefiModel';
import BadgeModel from '../BadgeModel';
import TalkModel from '../TalkModel';
import LightChapterModel from '../text/LightChapterModel';

export default class NewsfeedModel {
  id: string;
  newsType: string;
  ago: string;
  user: UserModel;
  text: LightTextModel;
  chapter: LightChapterModel;
  chapterVersionId: number;
  anchorId: number;
  defi: DefiModel;
  badge: BadgeModel;
  talk: TalkModel;
  pinnedAltruiste: boolean;
  pinnedNew: boolean;

  constructor(id: string, newsType: string, ago: string, user: UserModel,
    text: LightTextModel, chapter: LightChapterModel, anchorId: number, chapterVersionId: number,
    defi: DefiModel, badge: BadgeModel, talk: TalkModel,
    pinnedAltruiste: boolean, pinnedNew: boolean) {
    this.id = id;
    this.newsType = newsType;
    this.ago = ago;
    this.user = user;
    this.text = text;
    this.chapter = chapter;
    this.anchorId = anchorId;
    this.chapterVersionId = chapterVersionId;
    this.defi = defi;
    this.badge = badge;
    this.talk = talk;
    this.pinnedAltruiste = pinnedAltruiste;
    this.pinnedNew = pinnedNew;
  }

  toJS() {
    return {
      id: this.id,
      newsType: this.newsType,
      ago: this.ago,
      user: this.user.toJS(),
      text: this.text.toJS(),
      chapter: this.chapter.toJS(),
      anchorId: this.anchorId,
      chapterVersionId: this.chapterVersionId,
      defi: this.defi.toJS(),
      badge: this.badge.toJS(),
      talk: this.talk.toJS(),
      pinnedAltruiste: this.pinnedAltruiste,
      pinnedNew: this.pinnedNew,
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new NewsfeedModel(object.id, object.newsType, object.ago,
        UserModel.fromJS(object.user), LightTextModel.fromJS(object.text),
        LightChapterModel.fromJS(object.chapter),
        object.anchorId, object.chapterVersionId, DefiModel.fromJS(object.defi),
        BadgeModel.fromJS(object.badge), TalkModel.fromJS(object.talk),
        object.pinnedAltruiste, object.pinnedNew);
    }
    return undefined;
  }
}
