import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface VolatileLoginProps {
  store?: Store;
  huge?: boolean;
  withUsername?: boolean;
}

interface VolatileLoginState {
  username: string;
  usernameError: boolean;
  email: string;
  emailError: boolean;
  password: string;
  passwordError: boolean;
  strongPasswordError: boolean;
  usernameOrPasswordExistsError: boolean;
  invalidEmail: boolean;
  unknownError: boolean;
  loading: boolean;
}

@inject('store') @observer
export default class VolatileLogin extends React.Component<VolatileLoginProps, VolatileLoginState> {
  constructor(props: VolatileLoginProps) {
    super(props);
    this.state = {
      username: '',
      usernameError: false,
      email: '',
      emailError: false,
      password: '',
      passwordError: false,
      strongPasswordError: false,
      usernameOrPasswordExistsError: false,
      invalidEmail: false,
      unknownError: false,
      loading: false
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  handleUsernameChange(e: any) {
    const usernameError = this.state.usernameError && e.target.value.trim().length === 0;
    this.setState({
      username: e.target.value,
      usernameError: usernameError,
      email: this.state.email,
      emailError: this.state.emailError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      strongPasswordError: this.state.strongPasswordError,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  handleEmailChange(e: any) {
    const emailError = this.state.emailError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      email: e.target.value,
      emailError: emailError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      strongPasswordError: this.state.strongPasswordError,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  handlePasswordChange(e: any) {
    const passwordError = this.state.passwordError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      email: this.state.email,
      emailError: this.state.emailError,
      password: e.target.value,
      passwordError: passwordError,
      strongPasswordError: this.state.strongPasswordError,
      usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
      invalidEmail: this.state.invalidEmail,
      unknownError: this.state.unknownError
    });
  }
  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  postForm(event: any) {
    event.preventDefault();
    const usernameError = this.state.username.trim().length === 0;
    const emailError = this.state.email.trim().length === 0 || !this.validateEmail(this.state.email);
    const passwordError = this.state.password.trim().length === 0;
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const strongPasswordError = this.state.password !== undefined
      && this.state.password.length !== 0 && !strongPassword.test(this.state.password);

    if (!usernameError && !emailError && !passwordError && !strongPasswordError) {
      this.setState({
        loading: true
      });
      this.props.store.signUp(
        this.state.username, this.state.email, this.state.password, undefined
      ).then((r: any) => {
        window.location.href = '/?m=w';
      }, (err: any) => {
        if (err.status === 409) {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: this.state.password,
            passwordError: this.state.passwordError,
            strongPasswordError: this.state.strongPasswordError,
            usernameOrPasswordExistsError: true,
            invalidEmail: this.state.invalidEmail,
            unknownError: this.state.unknownError,
            loading: false
          });
        } else if (err.status === 403) {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: this.state.password,
            passwordError: this.state.passwordError,
            strongPasswordError: this.state.strongPasswordError,
            usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
            invalidEmail: true,
            unknownError: this.state.unknownError,
            loading: false
          });
        } else {
          this.setState({
            username: this.state.username,
            usernameError: this.state.usernameError,
            email: this.state.email,
            emailError: this.state.emailError,
            password: this.state.password,
            passwordError: this.state.passwordError,
            strongPasswordError: this.state.strongPasswordError,
            usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
            invalidEmail: this.state.invalidEmail,
            unknownError: true,
            loading: false
          });
        }
      });
    } else {
      this.setState({
        username: this.state.username,
        usernameError: usernameError,
        email: this.state.email,
        emailError: emailError,
        password: this.state.password,
        passwordError: passwordError,
        strongPasswordError: strongPasswordError,
        usernameOrPasswordExistsError: this.state.usernameOrPasswordExistsError,
        invalidEmail: this.state.invalidEmail,
        unknownError: this.state.unknownError,
        loading: false
      });
    }
  }
  render() {
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const strongPasswordError = this.state.password !== undefined
      && this.state.password.length !== 0 && !strongPassword.test(this.state.password);
    const longPassword = new RegExp('(?=.{8,})');
    const upperCasePassword = new RegExp('(?=.*[A-Z])');
    const lowerCasePassword = new RegExp('(?=.*[a-z])');
    const digitPassword = new RegExp('(?=.*[0-9])');
    const specialCharacterPassword = new RegExp('([^A-Za-z0-9])');
    return (
      <div>
        {/* <a href="/auth/login/facebook"
          className={'ui ' + (this.props.huge ? 'large ' : '') + ' facebook fluid button'}>
          <i className="fa fa-fw fa-facebook"></i> Continuer avec Facebook
        </a>
        <div className="ui horizontal divider" style={{ color: '#fff' }}>ou</div> */}
        {this.props.withUsername ?
          <form onSubmit={this.postForm} className={'ui ' + (this.props.huge ? 'large ' : '') + 'form'}>
            {this.state.usernameError || this.state.emailError
              || this.state.passwordError ?
              <div className="ui error message">
                <div className="header">Les champs marqués en rouge sont obligatoires.</div>
              </div>
              : (strongPasswordError ?
                  <div className="ui error message">
                    <div className="header">Le mot de passe ne remplit pas les prérequis :</div>
                    {longPassword.test(this.state.password) ? undefined : 
                      <div>- La longueur minimale du mot de passe est de 8 caractères.</div>
                    }
                    {upperCasePassword.test(this.state.password) ? undefined : 
                      <div>- Le mot de passe doit comporter au moins une majuscule.</div>
                    }
                    {lowerCasePassword.test(this.state.password) ? undefined : 
                      <div>- Le mot de passe doit comporter au moins une minuscule.</div>
                    }
                    {digitPassword.test(this.state.password) ? undefined : 
                      <div>- Le mot de passe doit comporter au moins un chiffre.</div>
                    }
                    {specialCharacterPassword.test(this.state.password) ? undefined : 
                      <div>- Le mot de passe doit comporter au moins un caractère spécial.</div>
                    }
                </div>
                : (this.state.usernameOrPasswordExistsError ?
                  <div className="ui error message">
                    <div className="header">L'identifiant ou l'email existe déjà.</div>
                  </div>
                  : (this.state.invalidEmail ?
                    <div className="ui error message">
                      <div className="header">L'email est invalide.</div>
                    </div>
                    : (this.state.unknownError ?
                      <div className="ui error message">
                        <div className="header">Une erreur s'est produite. Veuillez réessayer.</div>
                      </div>
                      : undefined
                    )
                  )
                )
              )
            }
            <div className={'field' + (this.state.usernameError ? ' error' : '')}>
              <input
                type="text"
                placeholder="Nom d'utilisateur"
                value={this.state.username}
                onChange={this.handleUsernameChange}
                aria-label="Nom d'utilisateur"
              />
            </div>
            <div className={'field' + (this.state.emailError ? ' error' : '')}>
              <input
                type="text"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                aria-label="Email"
              />
              {this.state.emailError ? <label>L'email est invalide.</label> : undefined}
            </div>
            <div className={'field' + (this.state.passwordError ? ' error' : '')}>
              <input
                type="password"
                placeholder="Mot de passe"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                aria-label="Mot de passe"
              />
            </div>
            <button
              className={'ui submit orange right floated ' + (this.state.loading ? ' loading' : '') + (this.props.huge ? ' large' : '') + ' button'}
              type="submit">
              C'est parti !
                      </button>
          </form>
          :
          <a href="/signup"
            className={'ui ' + (this.props.huge ? 'large ' : '') + ' fluid button'}>
            Inscription
        </a>
        }
        <div style={this.props.huge ? { fontSize: '1.2em' } : {}}>
          <p>En rejoignant l'Atelier des auteurs, vous acceptez nos <a href="/cgu" target="_blank" rel="noopener noreferrer" style={{ color: '#f0ad4e', fontWeight: 'bold' }}>
            Conditions Générales d'Utilisation</a>.
                    </p>
          <p>Déjà membre de l'Atelier des auteurs ? <a href="/login" style={{ color: '#f0ad4e', fontWeight: 'bold' }}>Connexion</a>
          </p>
        </div>
      </div>
    );
  }
}
