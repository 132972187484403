/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ApiClient from './helpers/ApiClient';
import { applyRouterMiddleware, Router, browserHistory, match } from 'react-router';
const useScroll = require('react-router-scroll').useScroll;
import { Provider } from 'mobx-react';
import { IntlProvider, addLocaleData } from 'react-intl';
import * as fr from 'react-intl/locale-data/fr';
require('core-js');
const CookiesProvider = require('react-cookie').CookiesProvider;
import getRoutes from './routes';
import Store from './stores/Store';
import MainAlertsModel from './models/MainAlertsModel';
import ConnectedModel from './models/ConnectedModel';
import HomeModel from './models/home/HomeModel';
import ProfileModel from './models/profile/ProfileModel';
import CommentListModel from './models/comment/CommentListModel';
import ReadModel from './models/text/ReadModel';
import TextModel from './models/text/TextModel';
import WriteModel from './models/write/WriteModel';
import SearchModel from './models/search/SearchModel';
import ChatModel from './models/chat/ChatModel';
import MainNotificationsModel from './models/notification/MainNotificationsModel';
import MainTalkModel from './models/talk/MainTalkModel';
import MainDefiModel from './models/defi/MainDefiModel';
import MainSettingsModel from './models/settings/MainSettingsModel';
import MainReadingListModel from './models/readinglist/MainReadingListModel';
import MainWritingProgramModel from './models/writingprogram/MainWritingProgramModel';
import MainCanvasModel from './models/canvas/MainCanvasModel';
import MainParcoursModel from './models/parcours/MainParcoursModel';
import MainEditorModel from './models/editors/MainEditorModel';
import { fetchDataOnLocationMatch } from './utils/fetch';
/* const ReactGA = require('react-ga');
const cookie = new (require('react-cookie')).Cookies(); */
const cookie = new (require('react-cookie')).Cookies();
import * as TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-P34KFX7',
    auth: 'b8PBXUbIqOOsFaubTV5uSg',
    preview: 'env-2'
}

TagManager.initialize(tagManagerArgs)

interface MyWindow extends Window {
  __data: any;
  React: any;
}

interface MyNodeModule extends NodeModule {
  hot: any;
}

declare var window: MyWindow;
declare var module: MyNodeModule;

const messages = require('./intl/fr.json');
const HotEnabler = require('react-hot-loader').AppContainer;

global.Intl = require('intl');

addLocaleData(fr);
const dest = document.getElementById('content');
const state: any = window.__data;

const client: any = new ApiClient(undefined, undefined);
client.setClientIp(state.ip);
client.setTokenAlt(state.tokenalt);

const store = Store.init(client, undefined, state.language, state.ip, state.vapidKey,
  state.isConnected, state.isHttps, null);
store.mainAlerts = MainAlertsModel.fromJS(state.mainAlerts);
store.connected = ConnectedModel.fromJS(state.connected);
store.home = HomeModel.fromJS(state.home);
store.profile = ProfileModel.fromJS(state.profile);
store.comments = CommentListModel.fromJS(state.comments);
store.read = ReadModel.fromJS(state.read);
store.text = TextModel.fromJS(state.text);
store.write = WriteModel.fromJS(state.write);
store.search = SearchModel.fromJS(state.search);
store.chat = ChatModel.fromJS(state.chat);
store.mainNotifications = MainNotificationsModel.fromJS(state.mainNotifications);
store.mainTalk = MainTalkModel.fromJS(state.mainTalk);
store.mainDefi = MainDefiModel.fromJS(state.mainDefi);
store.mainSettings = MainSettingsModel.fromJS(state.mainSettings);
store.mainReadingList = MainReadingListModel.fromJS(state.mainReadingList);
store.mainWritingProgram = MainWritingProgramModel.fromJS(state.mainWritingProgram);
store.mainCanvas = MainCanvasModel.fromJS(state.mainCanvas);
store.mainParcours = MainParcoursModel.fromJS(state.mainParcours);
store.mainEditor = MainEditorModel.fromJS(state.mainEditor);

fetchDataOnLocationMatch(browserHistory, getRoutes(store), match, store);

/* const cookieAudience = cookie && cookie.get && cookie.get('user-has-accepted-cookies-audience');
if (cookieAudience) {
  ReactGA.initialize('UA-55186763-1', {
    debug: process.env.NODE_ENV !== 'production',
    gaOptions: state.connected && state.connected.user && state.connected.user.id ?
      { userId: state.connected.user.id }
      : {}
  });
} */

function logPageView() {
  const cookieAudience = cookie && cookie.get && cookie.get('user-has-accepted-cookies-audience');
  if (cookieAudience) {
    const page = window.location.pathname + window.location.search;
    /* ReactGA.set({ page: page });
    ReactGA.pageview(page); */
  }
}

function hashLinkScroll() {
  logPageView();

  const { hash } = window.location;
  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

const render = (routes: any) => {
  ReactDOM.render(
    <HotEnabler>
      <CookiesProvider>
        <IntlProvider
          locale={store.language}
          messages={messages}
        >
          <Provider store={store} >
            <Router history={browserHistory} render={applyRouterMiddleware(useScroll())} onUpdate={hashLinkScroll}>
              {routes}
            </Router>
          </Provider>
        </IntlProvider>
      </CookiesProvider>
    </HotEnabler>,
    dest
  );
};

render(getRoutes(store));

if (module.hot) {
  module.hot.accept('./routes', () => {
    const nextRoutes = require('./routes');
    render(nextRoutes);
  });
}

if (process.env.NODE_ENV !== 'production') {
  window.React = React; // enable debugger

  if (!dest || !dest.firstChild) {
    console.error('Server-side React render was discarded.' +
      'Make sure that your initial render does not contain any client-side code.');
  }
}

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    require('offline-plugin/runtime').install({
      onInstalled: (() => {
        console.log('Installed !')
      })
    });
  }
}
