import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface SettingsPageAdminPromotionProps {
  store?: Store;
}

interface SettingsPageAdminPromotionState {
  content: string;
  until: string;
  created: boolean;
  deleted: boolean;
}

@inject('store') @observer
export default class SettingsPageAdminPromotion extends React.Component<SettingsPageAdminPromotionProps, SettingsPageAdminPromotionState> {
  constructor(props: SettingsPageAdminPromotionProps) {
    super(props);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleUntilChange = this.handleUntilChange.bind(this);
    this.createPromotion = this.createPromotion.bind(this);
    this.deletePromotion = this.deletePromotion.bind(this);
    this.state = {
      content: '',
      until: '',
      created: false,
      deleted: false
    };
  }
  handleContentChange(e: any) {
    this.setState({
      content: e.target.value
    });
  }
  handleUntilChange(e: any) {
    this.setState({
      until: e.target.value
    });
  }
  @action createPromotion() {
    this.setState({
      created: false,
      deleted: false
    });
    this.props.store.createPromotion(this.state.content, this.state.until).then((d: any) => {
      this.setState({
        content: '',
        until: '',
        created: true,
        deleted: false
      });
      this.props.store.getCurrentPromotion();
      this.props.store.getAllPromotions();
    });
  }
  @action deletePromotion(promotionId: number) {
    this.setState({
      created: false,
      deleted: false
    });
    this.props.store.deletePromotion(promotionId).then((d: any) => {
      this.setState({
        deleted: true
      });
      this.props.store.getCurrentPromotion();
      this.props.store.getAllPromotions();
    });
  }
  render() {
    const connected = this.props.store.connected;
    const admin = this.props.store.mainSettings.admin;

    const allPromotions = admin.allPromotions.map(promotion =>
      <tr key={`promotion_${promotion.id}`}>
        <td dangerouslySetInnerHTML={{ __html: promotion.content }} />
        <td>{promotion.until}</td>
      </tr>
    );
    return (
      <div>
        <h3>Promotions</h3>
        {this.state.created ?
          <div className="ui success message">
            Promotion créée !
          </div>
          : undefined}
        {this.state.deleted ?
          <div className="ui success message">
            Promotion supprimée !
          </div>
          : undefined}
        {admin.currentPromotion ?
          <div>
            <h4>Promotion actuelle</h4>
            <div className="ui info message">
              Jusqu'au {admin.currentPromotion.until}, <span dangerouslySetInnerHTML={{ __html: admin.currentPromotion.content }} />
            </div>
            <button className="ui right floated red button" onClick={() => this.deletePromotion(admin.currentPromotion.id)}>
              Supprimer
            </button>
          </div>
          : undefined}
        <h4>Toutes les promotions</h4>
        <table className="ui celled striped small table">
          <thead>
            <tr>
              <th>Texte</th>
              <th>Jusqu'au (à minuit)</th>
            </tr>
          </thead>
          <tbody>
            {allPromotions}
          </tbody>
        </table>
        <h4>Nouvelle promotion</h4>
        <form className="ui reply form" style={{ padding: '10px' }}>
          <div className="field">
            <label>Texte</label>
            <input
              type="text"
              value={this.state.content}
              onChange={this.handleContentChange}
            />
          </div>
          <div className="field">
            <label>Jusqu'au (format DD/MM/YYYY)</label>
            <input
              type="text"
              value={this.state.until}
              onChange={this.handleUntilChange}
            />
          </div>
          <button
            onClick={this.createPromotion}
            className="ui blue submit right floated button"
            disabled={this.state.content.trim().length === 0 && this.state.until.trim().length === 0}
            type="button"
          >
            Créer
                </button>
        </form>
      </div>
    );
  }
}
