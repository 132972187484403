import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import PopupWarning from './PopupWarning';
import { isParagraphTooLong } from '../../../../../../utils/relecture/lisibilite';

function paragraphStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  if (isParagraphTooLong(text)) {
    callback(0, text.length);
  }
}

const paragraphSpan = (props: any) => (
  <PopupWarning
    body="Attention, votre paragraphe dépasse les 100 mots !"
    spanClass="write-warning-2"
    children={props.children}
    />
);

const decorator = new CompositeDecorator([
  {
    strategy: paragraphStrategy,
    component: paragraphSpan,
  },
]);

export default decorator;
