import { observable } from 'mobx';
import ReadingListModel from './ReadingListModel';
import FullTextModel from '../FullTextModel';

export default class MainReadingListModel {

  @observable lists: ReadingListModel[];
  @observable currentList: ReadingListModel;
  @observable texts: FullTextModel[];
  @observable similar: ReadingListModel[];
  @observable sameUser: ReadingListModel[];
  @observable loading: boolean;

  constructor(lists: ReadingListModel[], currentList: ReadingListModel,
    texts: FullTextModel[], similar: ReadingListModel[], sameUser: ReadingListModel[]) {
    this.lists = lists || [];
    this.currentList = currentList;
    this.texts = texts || [];
    this.similar = similar || [];
    this.sameUser = sameUser || [];
    this.loading = false;
  }

  setReadingLists(lists: ReadingListModel[]) {
    this.lists = lists;
  }

  setCurrentList(currentList: ReadingListModel) {
    this.currentList = currentList;
  }

  setTexts(texts: FullTextModel[]) {
    this.texts = texts;
  }

  setSimilar(similar: ReadingListModel[]) {
    this.similar = similar;
  }

  setSameUser(sameUser: ReadingListModel[]) {
    this.sameUser = sameUser;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      lists: this.lists.map(l => l.toJS()),
      currentList: this.currentList.toJS(),
      texts: this.texts.map(t => t.toJS()),
      similar: this.similar.map(t => t.toJS()),
      sameUser: this.sameUser.map(t => t.toJS()),
    };
  }

  static fromJS(object: any) {
    if (object) {
      const lists = object.lists.map((l: any) => ReadingListModel.fromJS(l));
      const currentList = ReadingListModel.fromJS(object.currentList);
      const texts = object.texts.map((t: any) => FullTextModel.fromJS(t));
      const similar = object.similar.map((l: any) => ReadingListModel.fromJS(l));
      const sameUser = object.sameUser.map((l: any) => ReadingListModel.fromJS(l));
      return new MainReadingListModel(lists, currentList, texts, similar, sameUser);
    }
    return undefined;
  }
}
