import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ProfileHeatMapDetail from '../../models/profile/ProfileHeatMapDetail';

interface HeatMapDetailProps {
  heatMapDetail: ProfileHeatMapDetail;
}

@inject('store') @observer
export default class HeatMapDetail extends React.Component<HeatMapDetailProps, {}> {
  render() {
    const date = this.props.heatMapDetail.readableDate;
    const hm = this.props.heatMapDetail.heatMap;
    const total = hm.annotations + hm.chapters + hm.comments +
      hm.diaries + hm.talks + hm.texts + hm.versions;

    let title: string;
    if (total === 0) {
      title = `Aucune action le ${date}`;
    } else {
      title = `${total} action${total > 1 ? 's' : ''} le ${date}`;
    }

    let elements: JSX.Element[] = [];
    if (total > 0) {
      if (hm.texts > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.texts}</b> {hm.texts === 1 ? 'nouvelle œuvre' : 'nouvelles œuvres'}
          </div>
        );
      }
      if (hm.chapters > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.chapters}</b> {hm.chapters === 1 ? 'nouveau chapitre' : 'nouveaux chapitres'}
          </div>
        );
      }
      if (hm.versions > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.versions}</b> {hm.versions === 1 ? 'nouvelle version' : 'nouvelles versions'}
          </div>
        );
      }
      if (hm.comments > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.comments}</b> {hm.comments === 1 ? 'commentaire' : 'commentaires'}
          </div>
        );
      }
      if (hm.annotations > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.annotations}</b> {hm.annotations === 1 ? 'annotation' : 'annotations'}
          </div>
        );
      }
      if (hm.talks > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.talks}</b> {hm.talks === 1 ? 'message' : 'messages'} au sein de discussions
          </div>
        );
      }
      if (hm.diaries > 0) {
        elements.push(
          <div className="item" key={Math.random() * 1000}>
            <b>{hm.diaries}</b> {hm.diaries === 1 ? 'note' : 'notes'} de journal
          </div>
        );
      }
    }

    return (
      <div className="ui segment">
        <h4>{title}</h4>
        <div className="ui list">
          {elements}
        </div>
      </div>
    );
  }
}
