import { observable } from 'mobx';

export default class TextModel {

  @observable id: number;
  @observable title: string;
  @observable urlText: string;
  @observable fromDefi: boolean;
  @observable views: number;
  @observable favorites: number;
  @observable comments: number;
  @observable annotations: number;
  @observable countChapters: number;
  @observable published: boolean;
  @observable datePublication: string;
  @observable lastModificationDate: string;

  constructor(id: number, title: string, urlText: string, fromDefi: boolean,
    views: number, favorites: number, comments: number, annotations: number,
    countChapters: number, published: boolean,
    datePublication: string, lastModificationDate: string) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.fromDefi = fromDefi;
    this.views = views;
    this.favorites = favorites;
    this.comments = comments;
    this.annotations = annotations;
    this.countChapters = countChapters;
    this.published = published;
    this.datePublication = datePublication;
    this.lastModificationDate = lastModificationDate;
  }

  init(id: number, title: string, urlText: string) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      fromDefi: this.fromDefi,
      views: this.views,
      favorites: this.favorites,
      comments: this.comments,
      annotations: this.annotations,
      countChapters: this.countChapters,
      published: this.published,
      datePublication: this.datePublication,
      lastModificationDate: this.lastModificationDate
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new TextModel(object.id, object.title, object.urlText,
        object.fromDefi, object.views,
        object.favorites, object.comments, object.annotations, object.countChapters,
        object.published, object.datePublication, object.lastModificationDate);
    }
    return undefined;
  }
}
