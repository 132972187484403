import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class DefiWithAuthorModel {

  @observable id: number;
  @observable title: string;
  @observable urlTitle: string;
  @observable author: UserModel;

  constructor(id: number, title: string, urlTitle: string, author: UserModel) {
    this.id = id;
    this.title = title;
    this.urlTitle = urlTitle;
    this.author = author;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlTitle: this.urlTitle,
      author: this.author.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new DefiWithAuthorModel(object.id, object.title, object.urlTitle,
        UserModel.fromJS(object.author));
    }
    return undefined;
  }
}
