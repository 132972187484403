import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';

interface MagnifyModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  url: string;
  alt: string;
}


@inject('store') @observer
export default class MagnifyModal
  extends React.Component<MagnifyModalProps, {}> {
  constructor(props: MagnifyModalProps) {
    super(props);
    
  }
  
  render() {
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <img src={this.props.url}
                    alt={this.props.alt} width="100%" />
      </Modal>
      ); 
  }
}
