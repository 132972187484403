import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
const ret = require('react-event-timeline');
import Store from '../../../../stores/Store';
import Loader from '../../../misc/Loader';
import ParcoursStatus from './ParcoursStatus';
import ParcoursPresentation from './ParcoursPresentation';
import "../../../ada.css"

interface ParcoursPageContentProps {
  store?: Store;
/*   parcoursId: number;
 */  text: {
    id: number;
    title: string;
    chapter: number;
  };
  pinnedPrologue: number;
  withHeader?: boolean;
}

interface ParcoursPageContentState {
  message: string;
  remainingCharacters: number;
}

@inject('store') @observer
export default class ParcoursPageContent extends React.Component<ParcoursPageContentProps, ParcoursPageContentState> {
  constructor(props: ParcoursPageContentProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.state = {
      message: '',
      remainingCharacters: 5000
    };
  }
  loadMore() {
/*     this.props.store.loadParcoursTalks(this.props.parcoursId, []);
 */  }
  handleVisibleChange() {
    this.props.store.changeParcoursProgressVisible(this.props.store.mainParcours.currentParcours.id);
  }
  render() {
    const mainParcours = this.props.store.mainParcours;
    const status = mainParcours.status;
    const connected = this.props.store.connected;

    let parts: JSX.Element[];
    /* if (parcoursProgress && connected && connected.user && connected.user.id) {
      parts = parcoursProgress.all.parts.map((part) => {
        const chapters: JSX.Element[] = part.chapters.map((chapter) => {
          const users = chapter.users.map((user) => {
            return (
              <div key={`chap_${chapter.index}_user_${user.id}`} className="item">
                <img className="ui avatar image" src={user.avatarUrl} />
                <div className="content">
                  <Link
                    to={`/author/${user.id}/${user.urlIdentity}`}
                    className="header"
                  >
                    {user.identity}
                  </Link>
                </div>
              </div>
            );
          });
          const invisibleUsers: JSX.Element[] = [];
          for (let i = 0; i < chapter.invisibleUsers; i++) {
            invisibleUsers.push(
              <div key={`chap_${chapter.index}_invuser_${i}`} className="item">
                <img className="ui avatar image" src="https://s3-eu-west-1.amazonaws.com/scribay/assets/noavatar.png" />
                <div className="content">
                  Auteur masqué
                </div>
              </div>
            );
          }
          const containsMe = parcoursProgress.mine && parcoursProgress.mine.parcoursChapterId === chapter.id;
          return users.length > 0 || invisibleUsers.length > 0 ? (
            <TimelineEvent
              key={`chap_${chapter.index}`}
              title={chapter.title}
              icon={<i className={'fa ' + (containsMe ? 'fa-star' : 'fa-star-o')} style={{ fontSize: '1.5em' }} />}
              iconColor={containsMe ? '#FBBD08' : '#00B5AD'}
            >
              <div className="ui relaxed list">
                {users}
                {invisibleUsers}
              </div>
            </TimelineEvent>
          )
            : (<TimelineBlip
              key={`chap_${chapter.index}`}
              title={chapter.title}
              icon={<i className="fa fa-star-o" style={{ fontSize: '1.5em' }} />}
              iconColor="#00B5AD"
            />);
        });
        const partUsers = [].concat.apply([], part.chapters.map(c => c.users.map(u => u.id)));
        const partInvisibleUsers = part.chapters.map(c => c.invisibleUsers).reduce((a, b) => a + b);
        const containsMe = parcoursProgress.mine
          && part.chapters.map(c => c.id).indexOf(parcoursProgress.mine.parcoursChapterId) !== -1;
        return partUsers.length > 0 || partInvisibleUsers > 0 ? (
          <TimelineEvent
            key={`part_${part.index}`}
            title={part.title}
            icon={<i className="fa fa-star" style={{ fontSize: '1.1em' }} />}
            iconColor={containsMe ? '#FBBD08' : '#2185D0'}
          >
            <div>
              <Timeline>
                {chapters}
              </Timeline>
            </div>
          </TimelineEvent>
        ) : (<TimelineBlip
          key={`part_${part.index}`}
          title={part.title}
          icon={<i className="fa fa-star" style={{ fontSize: '1.1em' }} />}
          iconColor={containsMe ? '#FBBD08' : '#2185D0'}
        />);
      });
    } */

    /* const talk = this.props.store.mainTalk;
    const talks = talk.talks.map((t) => {
      const textTags = t.tags.map((tag) => {
        return <span
          key={Math.random() * 1000}
          className="ui label"
        >
          {tag}
        </span>;
      });

      const readLabel = t.read ?
        <span>lu</span>
        : <span style={{ color: '#F2711C' }}>non lu</span>;
      return (
        <div key={Math.random() * 1000} className="ui item">
          <img className="ui tiny rounded image" src={t.author.avatarUrl}
            alt={`Image de profil de ${t.author.identity}`}
            style={{ height: '80px' }} />
          <div className="content">
            <Link
              to={`/write/parcours/${parcours.id}/talks/${t.id}/${t.urlTitle}`}
              className="header"
            >
              {t.locked ?
                <span className="fa fa-fw fa-lock" />
                : undefined}
              {t.title}
            </Link>
            <div className="description">
              Par <Link
                to={`/author/${t.author.id}/${t.author.urlIdentity}`}
              >
                {t.author.identity}
              </Link>
              <div className="right floated">
                {t.answers} réponse{t.answers > 1 ? 's' : undefined} | {t.lastMessageAgo}| {readLabel}
              </div>
            </div>
            <div className="extra">
              {t.permanent ?
                <span className="ui green label">Permanent</span>
                : undefined}
              {textTags}
            </div>
          </div>
        </div>
      );
    }); */

    const text = this.props.text;
    return (
      <div>
        {!status ?
          <Loader message="Chargement..." />
          :
          <div>
            {/* <div className="ui two column stackable grid" style={{ marginTop: '-34px' }}> */}
              {/* <div className="ten wide column" style={{ paddingTop: '50px', paddingBottom: '80px' }}> */}
                <div className="ui grid container centered stackable">
                  <div className="twelve wide column">
                    {this.props.withHeader ?
                      <div className="ui massive breadcrumb">
                        <h1 className="ui header">
                          <span style={{fontSize: "1.5rem"}}> Parcours « {status.parcoursName} »</span>
                        {text && text.id && text.title && status.projectName?
                            <div className="sub header">
                              Vous suivez le parcours avec le roman&nbsp;
                              {/* the text title has to be formated */}
                              {/* <Link to={`/text/${text.id}/${text.title}`}> */}
                                <b>{status.textTitle}</b>
                              {/* </Link> */}
                            </div>
                          : undefined}
                        {text && text.id && text.title && text.chapter ?
                          <div className="sub header">
                            <Link to={`/text/${text.id}/${text.title}/chapter/${text.chapter}/edit`}>
                              Retour à l'éditeur
                            </Link>
                          </div>
                        : undefined}
                        </h1>
                      </div>
                      : undefined}
                    
                  </div>
                </div>

                  {/* {text && text.id && status ? */}
                    <ParcoursStatus />
                    {/* : <ParcoursPresentation pinnedPrologue={this.props.pinnedPrologue} />
                  } */}
              {/* </div> */}
              {/* <div className="six wide right floated grey column" style={{ paddingTop: '50px', minHeight: '1000px' }}>
                <div className="ui grid centered stackable">
                  <div className="twelve wide column">
                    <div className="ui hidden section divider" />
                    <h4 className="ui horizontal divider">Ils avancent sur le parcours</h4>
                    {parcoursProgress.mine ?
                      <div className="ui message">
                        Vous suivez le parcours.
                        <br />
                        Votre position est
                        <span style={{ marginLeft: '10px' }}>
                          <Checkbox
                            toggle
                            checked={parcoursProgress.mine.visible === true}
                            onClick={() => this.handleVisibleChange()}
                            label={parcoursProgress.mine.visible ? 'visible' : 'masquée'}
                          />
                        </span>
                      </div>
                      : <div className="ui message">
                        Vous ne suivez pas le parcours actuellement.
                    </div>}
                    <div style={{ fontSize: '1.4em' }}>
                      <Timeline>
                        {parts}
                      </Timeline>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
          </div>
        }
      </div>
    );
  }
}
