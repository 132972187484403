import * as React from 'react';
import { Link } from 'react-router';
import LightStructureModel from '../../models/text/LightStructureModel';
import LightChapterModel from '../../models/text/LightChapterModel';

interface TocProps {
  textId: number;
  urlText: string;
  structure: LightStructureModel;
}

export default class Toc extends React.Component<TocProps, {}> {
  chapterLists(chapters: LightChapterModel[]) {
    return chapters.map(c => {
      let linkStyle: {};
      let tooltip: string;
      if (c.read) {
        linkStyle = { color: '#555' };
        tooltip = 'Chapitre lu';
      } else {
        tooltip = 'Chapitre non lu';
      }
      return (
        <div key={c.id} className="item">
          <Link
            to={`/text/${this.props.textId}/${this.props.urlText}/chapter/${c.id}`}
            style={linkStyle}
            data-tooltip={tooltip} data-inverted="" data-position="bottom center"
          >
            {c.title}
          </Link>
        </div>
      );
    });
  }
  render() {
    const list: JSX.Element[] = this.props.structure.parts && this.props.structure.parts.length > 0 ?
      this.props.structure.parts.map(p => {
        const chapters = this.chapterLists(p.chapters);
        return (
          <div key={Math.random() * 1000} style={{ marginBottom: '10px' }}>
            <h1 className="ui sub huge header">{p.title}</h1>
            <div className="ui horizontal bulleted list">
              {chapters}
            </div>
          </div>
        );
      })
      : this.props.structure.chapters && this.props.structure.chapters.length > 0 ? (
        [<div key={Math.random() * 1000} className="ui horizontal bulleted list">
          {this.chapterLists(this.props.structure.chapters)}
        </div>
        ]
      ) : undefined;
    return (
      <div>
        {list ?
          <div>
            <h3 className="ui section horizontal large divider">
              <i className="fa fa-fw fa-list" style={{ marginRight: '10px' }} />
              Table des matières
            </h3>
            {list}
          </div>
          :
          <div>
            <h3 className="ui section horizontal large divider">
              <i className="fa fa-fw fa-list" style={{ marginRight: '10px' }} />
              Table des matières
              <div className="sub header">Aucun chapitre publié.</div>
            </h3>
          </div>
        }
      </div>
    );
  }
}
