import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import VolatileLogin from '../auth/VolatileLogin';
import Title from '../misc/Title';
const LazyLoad = require('react-lazy-load').default;
const Typist = require('react-typist').default;
const ReactPlayer = require('react-player');
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'
import LandingPageAuthorsBioModal from './LandingPageAuthorsBioModal';
import LandingPageAccordionParcoursPart from './LandingPageAccordionParcoursPart';
import LandingPageAccordionParcours from './LandingPageAccordionParcours';
import LandingPageAccordionBulletPoints from './LandingPageAccordionBulletPoints';
import LandingPageAuthorsCarousel from './LandingPageAuthorsCarousel';
import ContactForm from '../misc/ContactForm';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import EditorsSlider from '../misc/EditorsSlider';
import TestimonySlider from '../misc/TestimonySlider';
import LandingPageParams from './LandingPageParams';
import ProductModel from '../../models/product/ProductModel';
import Loader from '../misc/Loader';



interface LandingPageParcoursProps {
  store?: Store;
  params: LandingPageParams;
}

interface LandingPageParcoursState {
  typing: boolean;
  authorName: string;
  authorBio: string;
  authorModalActive: boolean;
  contactFormModalActive: boolean;
}


@inject('store') @observer
export default class LandingPageParcours extends React.Component<LandingPageParcoursProps, LandingPageParcoursState> {
  constructor(props: LandingPageParcoursProps) {
    super(props);
/*     this.restartTyping = this.restartTyping.bind(this);
 */    
    this.state = {
      typing: true,
      authorModalActive: false,
      contactFormModalActive: false,
      authorName: "",
      authorBio: ""
    };
    this.hideAuthorModal = this.hideAuthorModal.bind(this);
    this.showAuthorModal = this.showAuthorModal.bind(this);
    this.hideContactFormModal = this.hideContactFormModal.bind(this);
    this.showContactFormModal = this.showContactFormModal.bind(this);
  }
  componentDidMount() {
    this.props.store.loadProduct(this.props.params.productId)
  }
  componentWillUnmount() {
    this.props.store.clearCurrentProduct();
  }
  componentWillReceiveProps(nextProps : any) {
    this.props.store.loadProduct(nextProps.params.productId)
}
  /* restartTyping() {
    this.setState({ typing: false }, () => {
      this.setState({ typing: true });
    });
  } */
  communaute() {
    browserHistory.push({
      pathname: '/communaute'
    });
  }
  school() {
    browserHistory.push({
      pathname: '/ecole'
    });
  }
  hideAuthorModal() {
    this.setState({
      authorName: "",
      authorBio: "",
      authorModalActive: false
    });
  }
  showAuthorModal(author: string, bio: string) {
    this.setState({
      authorName: author,
      authorBio: bio,
      authorModalActive: true
    });
  }
  hideContactFormModal() {
    this.setState({
      contactFormModalActive: false
    });
  }
  showContactFormModal() {
    this.setState({
      contactFormModalActive: true
    });
  }
  buyParcours(product: ProductModel) {
    this.props.store.buyParcours(product);
    browserHistory.push({
      pathname: '/paymenthub',
      search: '?p='+product.id
    });
  }
  goToHub() {
    if (!this.props.store.connected.premium.premiumUI )
      this.props.store.switchUI().then((p: any) => {
        browserHistory.push({
          pathname: '/'
        });
      });
    else
      browserHistory.push({
        pathname: '/'
      });
  }
  goToLanding(productId: number) {
    browserHistory.push({
      pathname: '/atelier/'+productId
    });
  }
  signUpAndBuyParcours(product: ProductModel) {
    this.props.store.setRegistering(product)
    browserHistory.push({
      pathname: '/signup',
      search: '?p='+product.id
    });
  }
  render() {
    const metaProperties = [
      {
        name: 'description',
        content: 'L\'Atelier des auteurs est la plateforme d’écriture pensée pour vous. Trouvez l’inspiration, écrivez et progressez au sein d’une communauté bienveillante.'
      }
    ];
    const connected = this.props.store.connected;
    const premium = connected ? connected.premium : undefined;
    const isConnected = connected && connected.user && connected.user.id
    const currentProduct = this.props.store.currentProduct;
    const productBulletPoints = currentProduct.productBulletPoints;
    const mainBulletPoints: any = [];
    const nonMainBulletPoints: any = [];
    productBulletPoints.map((bulletPoint, index) => { //Split the bullet points with the three main one on one side and the others on the other side
      if (bulletPoint.main == true) {
        mainBulletPoints.push(bulletPoint)
      }
      else {
        nonMainBulletPoints.push(bulletPoint)
      }
    });
    var cpt = 0;
    const formattedMainBulletPoints = Object.keys(mainBulletPoints).map((key, index) => {
      cpt = cpt+1;
      return (<div style={{textAlign: cpt == 1 ? 'left' : cpt == 2 ? 'center' : 'right'}} key={'main-bulletpoint-'+key} className="ui column">
        <i className={'fa ' + mainBulletPoints[parseInt(key)].fontAwesome}/> &nbsp;
        {mainBulletPoints[parseInt(key)].content}
      </div>)
    });
    const formatedParcoursParts = currentProduct && currentProduct.parcoursParts ? currentProduct.parcoursParts.reduce(function (r, a) {
      r[a.parcoursId] = r[a.parcoursId] || [];
      r[a.parcoursId].push(a);
      return r;
    }, Object.create(null)) : undefined;
    const sortedFormatedParcoursParts = formatedParcoursParts ? Object.keys(formatedParcoursParts).reduce(function (r, a) {
      if (formatedParcoursParts[a][0].mainProductId !== null) {
        r[formatedParcoursParts[a][0].mainProductId] = r[formatedParcoursParts[a][0].mainProductId] || formatedParcoursParts[a];
      }
      else {
        r[formatedParcoursParts[a][0].parcoursId + 100000] = r[formatedParcoursParts[a][0].parcoursId + 100000] || formatedParcoursParts[a];
      }
      return r;
    }, Object.create(null)) : undefined;
    const parcoursPartsTitle = Object.keys(formatedParcoursParts).length > 1 ? 
      ("Les Ateliers : " + currentProduct.name)
    : 
      ("Les étapes de l'Atelier : " + currentProduct.name);
    const parcoursAccordion = 
    Object.keys(formatedParcoursParts).length > 1 ?
    <div className="ui eleven wide computer twelve wide tablet sixteen wide mobile column">
      <LandingPageAccordionParcours formatedParcours={sortedFormatedParcoursParts}/>
      </div>
    :
      formatedParcoursParts ? Object.keys(formatedParcoursParts).map((key, index) => { 
        return (<div key={'accordion-p-'+key} className="ui eleven wide computer twelve wide tablet sixteen wide mobile column">
          <LandingPageAccordionParcoursPart formatedParcoursParts={formatedParcoursParts[key]}/>
          </div>)
      }) : undefined;


    return (
      currentProduct.stripeIdentifier && currentProduct.marketIdentifier != null ? 
      <div style={{backgroundColor: '#fff'}}>
        <Title title="Pour les écrivains d'aujourd'hui et de demain" meta={metaProperties} scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://www.atelierdesauteurs.com',
            logo: {
              '@type': 'ImageObject',
              url: 'https://scribay.s3.amazonaws.com/assets/logo_square.png'
            }
          })
        }]} />

        <div className="ui" style={{ marginTop: '-25px' }}>
          <div className="background-ada landing-banner" style={{ backgroundImage: `url(${currentProduct.image})`, 
          backgroundPosition: "center", backgrounSize: "cover", backgrounRepeat: "no-repeat"}}>
              <div className="ui landing-banner-padding container">
                <div className="blur-bg" style={{maxWidth: '600px'}}>
                  <div style={{color: "white", maxWidth: '600px' }}>
                  {/* <img style={{marginTop: "135px"}} src="https://scribay.s3.amazonaws.com/assets/logo-ada.png"
                    alt="ada-logo" width="78%" /> */}
                    <h1 className="ada-museo-500" style={{ textTransform: "uppercase", fontSize: '3.2rem' }}> {currentProduct.name}</h1>{/* var */}
                    <h2 className="ada-museo-300" style={{ marginTop:"-13px", fontSize: '1.5rem' }}>{currentProduct.authorsLink}</h2>
                    <div className="ada-custom-divider-horizontal"></div>
                    <h3>{currentProduct.shortDescription}</h3>
                    <h3>{currentProduct.titleDescription ? currentProduct.titleDescription : ""}</h3>
                    <div style={{marginBottom: "15px"}}>
                      <span style={{fontSize: "1.3rem", marginRight: "7px"}} className="price-cross-landing">{currentProduct.productPrice}€</span> 
                      <span style={{fontSize: "3rem"}}>{currentProduct.currentPrice}€{currentProduct.marketIdentifier == 0 ? " /an" : ""}</span>
                    </div>
                    <h5>{currentProduct.objective ? currentProduct.objective : ""}</h5>
                      {currentProduct.id ?
                        currentProduct.marketIdentifier || currentProduct.marketIdentifier == 0 ?
                          isConnected ?
                            premium && (premium.allInclusive || ((premium.marketIdentifier & currentProduct.marketIdentifier) != 0)) ?
                            <div className="sub header">
                                <div style={{paddingTop: "0.6rem"}}>
                                  <button
                                    className="ui primary button"
                                    onClick={() => this.goToHub()}
                                    style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                                  >
                                    Accéder à l'atelier
                                  </button>
                                </div>
                              </div>
                            :
                              <div className="sub header" style={{flex: 0, flexGrow: 1}}>
                                <div style={{paddingTop: "0.6rem"}}>
                                  <button
                                    className="ui primary button ada-background editis-bleu" 
                                    onClick={() => this.buyParcours(currentProduct)} 
                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                    id={"buy_this_workshop_"+currentProduct.id}
                                  >
                                    Acheter {currentProduct.marketIdentifier != 0 ? "cet atelier" : "l'Universel"}
                                  </button>
                                  {currentProduct.marketIdentifier != 0 ?
                                    <button
                                      className="ui primary button ada-background editis-bleu" 
                                      onClick={() => this.goToLanding(6000)}
                                      style={{ cursor: 'pointer', marginTop: '5px'}}
                                      id={"go_to_workshop_"}
                                    >
                                    Disponible dans l'Universel
                                  </button>
                                  : this.props.store.previouslyVisitedProduct.id != undefined ?
                                  <button
                                    className="ui primary button ada-background editis-bleu" 
                                    onClick={() => this.goToLanding(this.props.store.previouslyVisitedProduct.id)}
                                    style={{ cursor: 'pointer', marginTop: '5px'}}
                                    id={"go_to_workshop_"+this.props.store.previouslyVisitedProduct.id}
                                  >
                                    Retour à l'atelier {this.props.store.previouslyVisitedProduct.shortName}
                                  </button> : undefined
                                  }
                                </div>
                              </div>
                              
                          : 
                            <div className="sub header">
                              <div style={{paddingTop: "0.6rem"}}>
                                <button
                                  className="ui primary button ada-background editis-bleu" 
                                  onClick={() => this.signUpAndBuyParcours(currentProduct)} 
                                  style={{ cursor: 'pointer', marginRight: '10px'}}
                                  id={"buy_this_workshop_"+currentProduct.id}
                                >
                                  Acheter {currentProduct.marketIdentifier != 0 ? "cet atelier" : "l'Universel"}
                                </button>
                                {currentProduct.marketIdentifier != 0 ?
                                  <button
                                  className="ui primary button ada-background editis-bleu" 
                                  onClick={() => this.goToLanding(6000)}
                                  style={{ cursor: 'pointer', marginTop: '5px'}}
                                  id={"go_to_workshop_6000"}
                                >
                                  Disponible dans l'Universel
                                </button>
                                : this.props.store.previouslyVisitedProduct.id != undefined ?
                                  <button
                                    className="ui primary button ada-background editis-bleu" 
                                    onClick={() => this.goToLanding(this.props.store.previouslyVisitedProduct.id)}
                                    style={{ cursor: 'pointer', marginTop: '5px'}}
                                    id={"go_to_workshop_"+this.props.store.previouslyVisitedProduct.id}
                                  >
                                    Retour à l'atelier {this.props.store.previouslyVisitedProduct.shortName}
                                  </button> : undefined
                                }
                              </div>
                            </div>
                            
                        : undefined
                      :
                        undefined
                      }
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="content ada-background editis-beige-foncé" style={{paddingTop: "35px", paddingBottom: "35px"}}>
          <div className="ui container" >
            <div className="ui three column centered grid" style={{color: "white", fontSize: "1.3rem"}}>
              {formattedMainBulletPoints}
            </div>
          </div>
        </div>
        <div className="ui center aligned container">
          <h4 className="ui horizontal ada-divider divider hide-tablet ada-museo-300">{currentProduct.marketIdentifier != 0 ? "L'atelier" : "LE PACK UNIVERSEL"}</h4>
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">{currentProduct.marketIdentifier != 0 ? "L'atelier" : "LE PACK UNIVERSEL"}</h4>
            <div className="row ada-image-subtitle" style={{marginTop: "-15px", marginBottom: "30px", textAlign: "center"}}>{/* var */}
            <div className="ui center grid" style={{marginLeft: "auto", marginRight: "auto", width: "70%"}}>
              <span dangerouslySetInnerHTML={{ __html: currentProduct.description}}></span>
                </div>
            </div>
            <div className="ui two column stackable grid" style={{marginBottom: "50px", marginTop: "-20px"}}>
              <div className=" column ">
                <div className="row ada-video-subtitle"><p>Bande-annonce {currentProduct.marketIdentifier != 0 ? "de l'atelier" : ""}</p></div>
                <div className="player-wrapper  single-video-ada react-player">
                  <ReactPlayer
                    url={currentProduct.productVideoUrl}
                    /* url="https://www.youtube.com/watch?v=n61ULEU7CO0" */
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <div className="column">
                <div className="row ada-video-subtitle"><p>Comment ça marche ?</p></div>
                <div className="player-wrapper single-video-ada react-player">
                  <ReactPlayer
                    url="https://vimeo.com/726710635"
                    /* url="https://www.youtube.com/watch?v=r0z8pE9Ix9M" */
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
        </div>
        <div className="ada-background editis-beige-clair" style={{paddingBottom: "50px"}}>
          <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Qui intervient dans {currentProduct.marketIdentifier != 0 ? "cet atelier" : "nos ateliers"} ?</h4>{/* var */}
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Qui intervient dans {currentProduct.marketIdentifier != 0 ? "cet atelier" : "nos ateliers"} ?</h4>
            {/* var carousel or not*/}
            {<LandingPageAuthorsCarousel productAuthors={currentProduct.productAuthors} showAuthorModal={this.showAuthorModal}/>}
            {<LandingPageAuthorsBioModal active={this.state.authorModalActive} hide={this.hideAuthorModal} bio={this.state.authorBio} name={this.state.authorName}/>}
          </div>
        </div>
        <div className="ui center aligned container">
          <h4 className="ui horizontal ada-divider divider hide-tablet ada-museo-300">{parcoursPartsTitle}</h4>{/* var */}
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">{parcoursPartsTitle}</h4>
          {currentProduct.marketIdentifier == 0 ?
            <div className="row ada-image-subtitle" style={{marginTop: "-15px", marginBottom: "30px", textAlign: "center"}}>{/* var */}
              <div className="ui center grid" style={{marginLeft: "auto", marginRight: "auto", width: "70%"}}>
                <p>Découvrez tous les ateliers auxquels vous aurez accès avec l'Universel. Cliquez et découvrez nos bandes-annonces !</p>
              </div>
            </div>
          : undefined}
          <div style={{marginLeft: "auto", marginRight: "auto", textAlign: "left", paddingBottom: "50px"}}>
            <div className="ui centered grid">
              {parcoursAccordion}
            </div>
          </div>
        </div>
        <div className="ada-background editis-beige-clair" style={{paddingBottom: "50px"}}>
          <div className="ui center aligned container">
            <h4 className="ui horizontal ada-divider divider hide-tablet ada-museo-300">5 bonnes raisons de rejoindre l'Atelier des Auteurs</h4>{/* var */}
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">5 bonnes raisons de rejoindre l'Atelier des Auteurs</h4>
            <div style={{textAlign: "left"}}>
              <div className="ui centered grid">
                <div className="ui eleven wide computer twelve wide tablet sixteen wide mobile column">
                  <LandingPageAccordionBulletPoints nonMainBulletPoints={nonMainBulletPoints}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            {<EditorsSlider/>}
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "50px", paddingTop: "20px"}}>
          <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
          <p>Vous souhaitez plus d'informations sur l'Atelier des Auteurs ?</p>
          <p>Envoyez-nous un message et nous vous répondrons au plus vite !</p>
          {<Modal
            open={this.state.contactFormModalActive}
            onClose={this.hideContactFormModal}
          >
            <div className="header">Contactez-nous</div>
            <div className="content" style={{maxHeight: '400px' }}>
              <ContactForm location="home"/>
            </div>
            <div className="actions">
              <button className="ui cancel button" onClick={this.hideContactFormModal}>Fermer</button>
            </div>
          </Modal>}
          <button
            className="ui button ada-background editis-bleu" 
            onClick={() => this.showContactFormModal()} 
            style={{ marginTop: "25px", cursor: 'pointer', color: "white"}}
          >
            Contactez-nous
          </button>
        </div>
        
      </div>
      : <div><Loader message="Chargement..."/></div>
    );
  }
}
