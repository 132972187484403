import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { scroller } from 'react-scroll';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import TalkParams from './TalkParams';
import CommentBox from '../comment/CommentBox';
import AlertModel from '../../models/AlertModel';
import NotConnectedBandeau from '../misc/NotConnectedBandeau';

interface TalkProps {
  store?: Store;
  params: TalkParams;
}

interface TalkState {
  activeRemove: boolean;
  activeBlock: boolean;
  blockMessage: string;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class TalkPage extends React.Component<TalkProps, TalkState> {
  static fetchData(store: Store, params: TalkParams) {
    const p: any[] = [];
    p.push(store.loadTalk(params.talkId, undefined, undefined));
    /* p.push(store.loadRelatedTalks(params.talkId));
    p.push(store.loadLinkedTalks(params.talkId)); */
    p.push(store.loadTalkMessages(params.talkId));
    return Promise.all(p);
  }
  constructor(props: TalkProps) {
    super(props);
    this.state = {
      activeRemove: false,
      activeBlock: false,
      blockMessage: ''
    };
    this.removeTalk = this.removeTalk.bind(this);
    this.confirmRemoveTalk = this.confirmRemoveTalk.bind(this);
    this.hideRemoveTalk = this.hideRemoveTalk.bind(this);
    this.blockTalk = this.blockTalk.bind(this);
    this.pinTalk = this.pinTalk.bind(this);
    this.confirmBlockTalk = this.confirmBlockTalk.bind(this);
    this.hideBlockTalk = this.hideBlockTalk.bind(this);
    this.unblockTalk = this.unblockTalk.bind(this);
    this.unpinTalk = this.unpinTalk.bind(this);
  }
  removeTalk() {
    this.setState({
      activeRemove: true
    });
  }
  confirmRemoveTalk() {
    const talk = this.props.store.mainTalk.talk;
    this.props.store.removeDiscussion(talk).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'removeDiscussion',
        'Discussion supprimée !',
        `La discussion ${talk.title} est supprimée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/talks`
      });
    });
  }
  hideRemoveTalk() {
    this.setState({
      activeRemove: false
    });
  }
  blockTalk() {
    this.setState({
      activeBlock: true
    });
  }
  unblockTalk() {
    const talk = this.props.store.mainTalk.talk;
    this.props.store.unblockDiscussion(talk).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'unblockDiscussion',
        'Discussion débloquée !',
        `La discussion ${talk.title} est débloquée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/talks`
      });
    });
  }
  confirmBlockTalk() {
    const talk = this.props.store.mainTalk.talk;
    this.props.store.blockDiscussion(talk, this.state.blockMessage).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'blockDiscussion',
        'Discussion bloquée !',
        `La discussion ${talk.title} est bloquée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/talks`
      });
    });
  }
  pinTalk() {
    const talk = this.props.store.mainTalk.talk;
    this.props.store.pinDiscussion(talk).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'pinDiscussion',
        'Discussion accrochée !',
        `La discussion ${talk.title} est accrochée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/talks`
      });
    });
  }
  unpinTalk() {
    const talk = this.props.store.mainTalk.talk;
    this.props.store.unpinDiscussion(talk).then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'pinDiscussion',
        'Discussion décrochée !',
        `La discussion ${talk.title} est décrochée.`,
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/talks`
      });
    });
  }
  hideBlockTalk() {
    this.setState({
      activeBlock: false
    });
  }
  render() {
    const talk = this.props.store.mainTalk.talk;
    const connected = this.props.store.connected;
    const comments = this.props.store.comments;
    let textTags: JSX.Element[];
    /* let linkedTalks: JSX.Element[] = [];
    let relatedTalks: JSX.Element[] = []; */

    if (talk) {
      textTags = talk.tags.map((tag) => {
        return <Link
          to={connected && connected.user && connected.user.id ? encodeURI(`/talks?tag=${tag}`) : '/signup'}
          key={Math.random() * 1000}
          className="ui label"
        >
          {tag}
        </Link>;
      });

      /* linkedTalks = this.props.store.mainTalk.linkedTalks.map(t => {
        return (
          <div key={'linked-' + t.id} className="item">
            <div className="content">
              <Link to={`/talks/${t.id}/${t.urlTitle}`} className="header">{t.title}</Link>
              <div className="description">
                {t.author.identity}
              </div>
            </div>
          </div>
        );
      });

      relatedTalks = this.props.store.mainTalk.relatedTalks.map(t => {
        return (
          <div key={'related-' + t.id} className="item">
            <div className="content">
              <Link to={`/talks/${t.id}/${t.urlTitle}`} className="header">{t.title}</Link>
              <div className="description">
                {t.author.identity}
              </div>
            </div>
          </div>
        );
      }); */
    }

    const metaProperties = talk ?
      [
        {
          name: 'description',
          content: talk.message.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '').substring(0, Math.min(talk.message.length, 200))
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@Atelier_Auteurs' },
        { name: 'twitter:title', content: talk.title },
        { name: 'twitter:creator', content: talk.author.twitter ? talk.author.twitter : '' },
        {
          name: 'twitter:description',
          content: talk.message.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '')
        },
        { name: 'twitter:image', content: talk.author.avatarUrl },
        { property: 'fb:app_id', content: '460911020714321' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: talk.title },
        { property: 'og:image', content: talk.author.avatarUrl },
        {
          property: 'og:description',
          content: talk.message.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '')
        }
      ]
      : [];

    const isAuthor = connected && connected.user && connected.user.id
      && talk && talk.author.id === connected.user.id;

    const blockedBy = talk && connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(talk.author.id) !== -1;

    const admin = connected && connected.user && connected.user.admin;

    return (
      <div>
        <NotConnectedBandeau
          title="Inscrivez-vous sur l'Atelier des Auteurs..."
          body="...et retrouvez une communauté soudée par l'amour de l'écriture, le partage et l’entraide entre auteurs."
        />
        {admin ?
          <Modal
            open={this.state.activeRemove}
            onClose={() => { }}
          >
            <div className="header">Confirmation</div>
            <div className="content">
              Confirmez-vous la suppression de cette discussion ?
      </div>
            <div className="actions">
              <button
                className="ui primary approve button" onClick={this.confirmRemoveTalk}
              >
                Oui
        </button>
              <button className="ui button" onClick={this.hideRemoveTalk}>
                Non
        </button>
            </div>
          </Modal>
          : undefined
        }
        {admin ?
          <Modal
            open={this.state.activeBlock}
            onClose={() => { }}
          >
            <div className="header">Confirmation</div>
            <div className="content">
              Confirmez-vous le bloquage de cette discussion ?
              <div className="ui form">
                <div className="ui field">
                  <label>Motif</label>
                  <textarea
                    value={this.state.blockMessage}
                    rows={2}
                    style={{ height: '7em' }}
                    onChange={(e: any) => {
                      this.setState({
                        blockMessage: e.target.value
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <button
                className="ui primary approve button" onClick={this.confirmBlockTalk}
              >
                Oui
        </button>
              <button className="ui button" onClick={this.hideBlockTalk}>
                Non
        </button>
            </div>
          </Modal>
          : undefined
        }
        {/* <div className="ui two column stackable grid" style={{ marginTop: '-30px' }}>
          <div className="ten wide column" style={{ paddingTop: '50px', paddingBottom: '80px' }}> */}
            <div className="ui grid container centered stackable" style={{ backgroundColor: '#fff', minHeight: '90vh', paddingBottom: '30px', paddingTop: '30px' }}>
              <div className="eleven wide column">
                {talk ?
                  <div>
                    <Title
                      title={`${talk.title} `}
                      meta={metaProperties}
                      scripts={[{
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                          "@context": "http://schema.org",
                          '@type': "Article",
                          "headline": talk.title,
                          "author": {
                            "@type": "Person",
                            "name": talk.author.identity
                          },
                          "image": {
                            "@type": "ImageObject",
                            "url": talk.author.avatarUrl,
                            "height": 800,
                            "width": 800
                          },
                          'description': talk.message.substring(0, Math.min(talk.message.length, 200)),
                          "datePublished": talk.createdAt,
                          "dateModified": talk.createdAt,
                          "publisher": {
                            "@type": "Organization",
                            name: "Atelier des Auteurs",
                            "logo": {
                              "@type": "ImageObject",
                              "url": "https://scribay.s3.amazonaws.com/assets/logo_square.png"
                            }
                          },
                          "mainEntityOfPage": `https://www.atelierdesauteurs.com/talks/${talk.id}/${talk.urlTitle}`
                        })
                      }]}
                    />
                    <div className="ui large header">
                      <img src={talk.author.avatarUrl} className="ui image rounded"
                        alt={`Image de profil de ${talk.author.identity}`} />
                      <div className="content">
                        {talk.locked ?
                          <span className="fa fa-fw fa-lock" />
                          : undefined}
                        <h1 style={{ margin: '0rem' }}>{talk.title}</h1>
                        <div className="sub header" style={{ paddingTop: '5px' }}>
                          par <Link to={`/author/${talk.author.id}/${talk.author.urlIdentity}`}>
                            {talk.author.identity}
                          </Link>
                          &nbsp; il y a {talk.createdAtAgo}
                        </div>
                      </div>
                    </div>
                    <div className="ui basic segment">
                      {talk.locked ?
                        <div className="ui warning message">
                          <div className="header">Cette discussion est verrouillée. Il n'est plus possible de publier de nouveau message.</div>
                          <div dangerouslySetInnerHTML={{ __html: talk.lockedMessage }} />
                        </div>
                        : undefined}
                      <div dangerouslySetInnerHTML={{ __html: talk.message }} className="ui container talk-container" />
                      <div className="ui basic segment">
                        {talk.permanent ?
                          <span className="ui green label">Permanent</span>
                          : undefined}
                        {textTags}
                        {isAuthor && !talk.locked ?
                          <Link to={`/talks/${talk.id}/${talk.urlTitle}/edit`}
                            className="ui right floated orange small mini button">
                            Modifier
                        </Link>
                          : undefined}
                        {admin ?
                          <button className="ui right floated red small mini button" onClick={this.removeTalk}>
                            Supprimer
                          </button>
                          : undefined}
                        {admin && !talk.locked ?
                          <button className="ui right floated red small mini button" onClick={this.blockTalk}>
                            Bloquer
                          </button>
                          : undefined}
                        {admin && talk.locked ?
                          <button className="ui right floated red small mini button" onClick={this.unblockTalk}>
                            Débloquer
                            </button>
                          : undefined}
                        {admin && !talk.permanent ?
                          <button className="ui right floated green small mini button" onClick={this.pinTalk}>
                            Accrocher
                          </button>
                          : undefined}
                        {admin && talk.permanent ?
                          <button className="ui right floated blue small mini button" onClick={this.unpinTalk}>
                            Décrocher
                            </button>
                          : undefined}
                      </div>
                    </div>
                  </div>
                  : undefined}
                <div className="ui divider" />
                {talk && comments ?
                  <div>
                    <div className="ui hidden section divider " />
                    {blockedBy ?
                      <div className="ui small message">
                        <Link to={`/author/${talk.author.id}/${talk.author.urlIdentity}`}>
                          {talk.author.identity}</Link> vous a bloqué.
                              &nbsp;Vous ne pouvez pas participer à cette discussion.
                      </div>
                      : undefined}
                    <CommentBox
                      admin={admin}
                      newsfeedElement={false}
                      readonly={blockedBy || talk.locked}
                      comments={this.props.store.comments}
                    />
                  </div>
                  : undefined}
              </div>
            </div>
          {/* </div> */}
          {/* <div className="six wide right floated grey column" style={{ paddingTop: '50px', minHeight: '1000px' }}>
            <div className="ui grid centered stackable">
              <div className="twelve wide column">
                <Link to={connected && connected.user && connected.user.id ? '/talks' : '/signup'}
                  className="ui fluid button">
                  Toutes les discussions
                </Link>
                {linkedTalks.length > 0 ?
                  <div className="ui basic segment">
                    <h3 className="ui horizontal divider">Discussions liées</h3>
                    <div className="ui relaxed list">
                      {linkedTalks}
                    </div>
                  </div>
                  : undefined}
                {relatedTalks.length > 0 ?
                  <div className="ui basic segment">
                    <h3 className="ui horizontal divider">Discussions proches</h3>
                    <div className="ui relaxed list">
                      {relatedTalks}
                    </div>
                  </div>
                  : undefined}
              </div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}
