import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
const DropzoneComponent = require('react-dropzone-component');
import Store from '../../stores/Store';
import TextColors from './TextColors';
import ColorPicker from '../misc/ColorPicker';

interface TextCoverProps {
  store?: Store;
  strangerMode: boolean;
}

interface TextCoverState {
  activeCoverModal: boolean;
  generatorStep: number;
  image: string;
  font: string;
  color: string;
  preview: string;
  previewLoading: boolean;
  saveLoading: boolean;
}

declare const dropzone: any;
declare const file: any;

@inject('store') @observer
export default class TextCover extends React.Component<TextCoverProps, TextCoverState> {
  constructor(props: TextCoverProps) {
    super(props);
    this.showCoverModal = this.showCoverModal.bind(this);
    this.hideCoverModal = this.hideCoverModal.bind(this);
    this.handleAddCover = this.handleAddCover.bind(this);
    this.handleRemoveCover = this.handleRemoveCover.bind(this);
    this.saveCover = this.saveCover.bind(this);
    this.startGenerator = this.startGenerator.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.handleFontChange = this.handleFontChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);

    this.state = {
      activeCoverModal: false,
      generatorStep: undefined,
      image: undefined,
      font: undefined,
      color: undefined,
      preview: undefined,
      previewLoading: false,
      saveLoading: false
    };
  }
  showCoverModal() {
    this.props.store.text.infos.coverTemp = this.props.store.text.infos.cover;
    this.setState({
      activeCoverModal: true,
      generatorStep: undefined,
      image: undefined,
      font: undefined,
      color: undefined,
      preview: undefined,
      previewLoading: false,
      saveLoading: false
    });
  }
  hideCoverModal() {
    this.setState({
      activeCoverModal: false,
      generatorStep: undefined,
      image: undefined,
      font: undefined,
      color: undefined,
      preview: undefined,
      previewLoading: false,
      saveLoading: false
    });
  }
  @action
  handleAddCover(f: File) {
    this.props.store.text.infos.coverFileTemp = f;
  }
  @action
  handleRemoveCover() {
    this.props.store.text.infos.coverTemp = undefined;
    this.props.store.text.infos.coverFileTemp = undefined;
  }
  saveCover() {
    this.setState({
      saveLoading: true
    });
    this.props.store.saveTextCover(this.state.preview).then((d: any) => {
      this.hideCoverModal();
    });
  }
  startGenerator() {
    this.setState({
      generatorStep: 1,
      font: 'Merriweather-Regular',
      color: '#FFFFFF'
    });
  }
  previous() {
    this.setState({
      generatorStep: this.state.generatorStep - 1
    });
  }
  next() {
    if (this.state.generatorStep === 2) {
      this.setState({
        previewLoading: true
      });
      this.props.store.generateCover(this.state.image, this.state.font, this.state.color).then((preview: string) => {
        this.setState({
          generatorStep: 3,
          preview: preview,
          previewLoading: false
        });
      });
    } else {
      this.setState({
        generatorStep: this.state.generatorStep + 1
      });
    }
  }
  handleSelectImage(image: string) {
    this.setState({
      image: image
    });
  }
  handleFontChange(font: string) {
    this.setState({
      font: font
    });
  }
  handleColorChange(color: string) {
    this.setState({
      color: color
    });
  }
  render() {
    const that = this;
    const text = this.props.store.text;
    const infos = text.infos;
    const meta = text.meta;
    const connected = this.props.store.connected;
    const isAuthor = text && text.meta && text.meta.author
      && connected && connected.user && connected.user.id
      && connected.user.id === text.meta.author.id;
    const componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: true,
      postUrl: 'no-url',
      maxFiles: 1
    };
    const eventHandlers = {
      init(dropzone: any) {
        if (infos.coverTemp) {
          const mockFile = { name: 'Image de couverture', size: 30000 };
          dropzone.emit('addedfile', mockFile);
          dropzone.emit('thumbnail', mockFile, infos.coverTemp);
          dropzone.emit('complete', mockFile);
          dropzone.options.maxFiles = 1;
        }
      },
      addedfile: that.handleAddCover
    };
    const djsConfig = {
      maxFiles: (infos.coverTemp ? 0 : 1),
      acceptedFiles: 'image/jpg,image/jpeg,image/png,image/gif',
      thumbnailWidth: 100,
      thumbnailHeight: 160,
      autoProcessQueue: false,
      resizefile() {
        const resizeInfo = {
          srcX: 0,
          srcY: 0,
          trgX: 0,
          trgY: 0,
          srcWidth: file.width,
          srcHeight: file.height,
          trgWidth: this.options.thumbnailWidth,
          trgHeight: this.options.thumbnailHeight,
        };
        return resizeInfo;
      },
      reset(f: any) {
        that.handleRemoveCover();
      },
      uploadMultiple: false,
      addRemoveLinks: true,
      dictDefaultMessage: 'Cliquez ici ou glissez-déposez des fichiers dans cet emplacement.',
      dictFallbackMessage: 'Votre navigateur ne supporte pas le glisser-déposer.',
      dictRemoveFile: 'Supprimer',
      dictCancelUpload: 'Annuler',
      dictCancelUploadConfirmation: 'Confirmez-vous l\'arrêt du transfert ?',
      dictMaxFilesExceeded: 'Une seule photo de couverture maximum est autorisée.',
    };

    let covers: JSX.Element[];
    if (this.state.generatorStep && this.state.generatorStep === 1) {
      let coversIdx = [];
      for (let i = 1; i < 51; i++) {
        coversIdx.push(i);
      }
      covers = coversIdx.map((i: number) => {
        const imageIndex = String(i);
        return (
          <img
            key={`image_${i + 1}`}
            className={'ui select ' + (imageIndex === this.state.image ? 'selected ' : '') + 'image'}
            src={`https://scribay.s3.amazonaws.com/models/${imageIndex}.jpg`}
            onClick={() => this.handleSelectImage(imageIndex)}
            data-tooltip={imageIndex === this.state.image ? 'Déselectionner' : 'Sélectionner'}
            data-inverted="" data-position="top center"
            alt="Modèle de couverture"
          />
        );
      });
    }
    return (
      <div className="ui image rounded medium" style={{ marginBottom: '30px' }}>
        {isAuthor && !this.props.strangerMode ?
          <Modal
            open={this.state.activeCoverModal}
            size="small"
          >
            <div className="header">Image de couverture</div>
            <div className="content">
              {this.state.generatorStep ?
                <div>
                  {this.state.generatorStep === 1 ?
                    <div>
                      <h3>Sélectionnez un modèle de couverture parmi les suivants :</h3>
                      <div className="ui tiny images">
                        {covers}
                      </div>
                    </div>
                    : (this.state.generatorStep === 2 ?
                      <div>
                        <h3>Choisissez une police et une couleur de texte :</h3>
                        <div className="ui form">
                          <div className="field">
                            <label>Police</label>
                            <Dropdown
                              selection
                              value={this.state.font}
                              onChange={(e: any, { value }) => this.handleFontChange(String(value))}
                              options={
                                [
                                  {
                                    value: 'Merriweather-Regular',
                                    text: 'Merriweather'
                                  },
                                  {
                                    value: 'OpenSans',
                                    text: 'Open Sans'
                                  },
                                  {
                                    value: 'RobotoSlab',
                                    text: 'Roboto Slab'
                                  },
                                  {
                                    value: 'AmaticSC-Regular',
                                    text: 'Amatic'
                                  },
                                ]
                              }
                            />
                          </div>
                          <div className="field">
                            <ColorPicker
                              color={this.state.color}
                              handleColorPickerChange={this.handleColorChange}
                              defaultColor="#FFFFFF"
                              colorPickerLabel="Couleur du texte"
                            />
                          </div>
                        </div>
                      </div>
                      : <div>
                        <h3>Aperçu :</h3>
                        <img src={`data:image/png;base64,${this.state.preview}`} className="ui centered medium image"
                          alt="Aperçu de la couverture" />
                      </div>)
                  }
                </div>
                :
                <div>
                  <div className="ui message warning">
                    Une couverture bien choisie rendra votre œuvre plus attractive aux yeux des lecteurs !
                  <br />L'image doit être au format 400x650 pixels. Si elle ne respecte pas ce format, elle sera recadrée et un fond blanc pourra être ajouté pour combler l'espace manquant.
                  <br />L'image ne doit pas dépasser 850ko.
                </div>
                  <h3>Chargez une image ...</h3>
                  <DropzoneComponent
                    key={infos.coverTemp}
                    config={componentConfig}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                  />
                  <h3>Ou utilisez notre générateur de couverture !</h3>
                  <button className="ui small orange button" onClick={this.startGenerator}>
                    Lancer le générateur de couverture
                    </button>
                </div>
              }
            </div>
            <div className="actions">
              {this.state.generatorStep && this.state.generatorStep > 1 ?
                <button
                  className="ui primary button"
                  onClick={this.previous}
                  disabled={this.state.previewLoading}>
                  Précédent
              </button>
                : undefined}
              {!this.state.generatorStep || this.state.generatorStep === 3 ?
                (this.state.preview || infos.coverFileTemp || (infos.cover && !infos.coverTemp) ?
                  <button
                    className={'ui primary' + (this.state.saveLoading ? ' loading' : '') + ' button'}
                    onClick={this.saveCover}
                    disabled={this.state.previewLoading}>
                    Enregistrer
              </button>
                  : undefined)
                :
                <button
                  className={'ui primary' + (this.state.previewLoading ? ' loading' : '') + ' button'}
                  onClick={this.next}
                  disabled={this.state.generatorStep && this.state.generatorStep === 1
                    && !this.state.image}>
                  {this.state.generatorStep === 2 ? 'Aperçu' : 'Suivant'}
                </button>
              }
              <button className="ui cancel button" onClick={this.hideCoverModal}>
                {!infos.coverTemp || (infos.coverTemp && !infos.coverFileTemp) ? 'Fermer' : 'Annuler'}
              </button>
            </div>
          </Modal>
          : undefined}
        {isAuthor && !this.props.strangerMode ?
          <button className="ui orange left ribbon label" onClick={this.showCoverModal}>
            {infos.cover ? 'Modifier' : 'Ajouter une couverture'}
          </button>
          : undefined}
        {infos.cover && meta ?
          <img
            alt={`Image de couverture de ${meta.title}`}
            src={infos.cover}
            style={{ borderRadius: 'inherit' }}
          />
          : (isAuthor && !this.props.strangerMode ? <img
            alt="Couverture vide"
            src="https://scribay.s3.amazonaws.com/assets/nocover_large.png"
            style={{ borderRadius: 'inherit', width: '100%' }}
          />
            : undefined)
        }
        {isAuthor && !this.props.strangerMode ?
          <TextColors />
          : undefined}
      </div>
    );
  }
}
