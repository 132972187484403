import { observable } from 'mobx';

export default class CheckValueModel {

  @observable id: number;
  @observable label: string;
  @observable checked: boolean;

  constructor(id: number, label: string, checked: boolean) {
    this.id = id;
    this.label = label;
    this.checked = checked;
  }

  toJS() {
    return {
      id: this.id,
      label: this.label,
      checked: this.checked
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CheckValueModel(object.id, object.label, object.checked);
    }
    return undefined;
  }
}
