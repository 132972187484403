import { observable } from 'mobx';

export default class PdfModel {

  @observable id: number;
  @observable name: string;
  @observable pdfOrder: number;
  @observable url: string;

  constructor(id: number, name: string, pdfOrder: number, 
    url: string) {
    this.id = id;
    this.name = name;
    this.pdfOrder = pdfOrder;
    this.url = url;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      pdfOrder: this.pdfOrder,
      url: this.url
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new PdfModel(object.id, object.name, object.pdfOrder, 
        object.url);
    }
    return undefined;
  }
}
