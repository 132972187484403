import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import AlertModel from '../../models/AlertModel';
import Store from '../../stores/Store';

interface TextReindexProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface TextReindexState {
  title: string;
}

@inject('store') @observer
export default class TextReindex extends React.Component<TextReindexProps, TextReindexState> {
  constructor(props: TextReindexProps) {
    super(props);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.reindexText = this.reindexText.bind(this);
    this.state = {
      title: '',
    };
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  reindexText() {
    this.props.store.reindexText().then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'reindexText',
        `Texte ${this.props.store.text.meta.title} réindexé !`,
        '',
        'success',
        5
      ));
      this.props.hide();
    });
  }
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          <p>
            Confirmez-vous la réindexation de cette œuvre ?
          </p>
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.reindexText}>Réindexer</button>
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
