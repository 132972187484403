import { observable } from 'mobx';

export default class QuestionModel {

  index: number;
  @observable answer: string;

  constructor(index: number, answer: string) {
    this.index = index;
    this.answer = answer;
  }

  toJS() {
    return {
      index: this.index,
      answer: this.answer
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new QuestionModel(object.index, object.answer);
    }
    return undefined;
  }
}
