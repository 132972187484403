import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { FormattedHTMLMessage } from 'react-intl';
const Accordion = require('semantic-ui-react/dist/commonjs/modules/Accordion').default;
import Store from '../../stores/Store';

interface TextLicenceProps {
  store?: Store;
  strangerMode: boolean;
}

interface TextLicenceState {
  showModal: boolean;
  licenceEditMode: boolean;
  licence: number;
}

@inject('store') @observer
export default class TextLicence extends React.Component<TextLicenceProps, TextLicenceState> {
  constructor(props: TextLicenceProps) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.startLicenceEdit = this.startLicenceEdit.bind(this);
    this.cancelLicenceEdit = this.cancelLicenceEdit.bind(this);
    this.handleSelectLicence = this.handleSelectLicence.bind(this);
    this.saveLicence = this.saveLicence.bind(this);
    this.state = { showModal: false, licenceEditMode: false, licence: undefined };
  }
  open() {
    this.setState({
      showModal: true,
      licenceEditMode: false,
      licence: this.props.store.text.infos.licence
    });
  }
  close() {
    this.setState({
      showModal: false,
      licenceEditMode: false,
      licence: this.props.store.text.infos.licence
    });
  }
  startLicenceEdit() {
    this.setState({
      showModal: false,
      licenceEditMode: true,
      licence: this.props.store.text.infos.licence
    });
  }
  cancelLicenceEdit() {
    this.setState({
      showModal: false,
      licenceEditMode: false,
      licence: undefined
    });
  }
  handleSelectLicence(licence: number) {
    this.setState({
      showModal: false,
      licenceEditMode: true,
      licence: licence
    });
  }
  saveLicence(e: any) {
    e.preventDefault();
    this.props.store.saveTextLicence(this.state.licence).then((d: any) => {
      this.setState({
        showModal: false,
        licenceEditMode: false,
        licence: undefined
      });
    });
  }
  render() {
    const text = this.props.store.text;
    const licence = text.infos.licence;
    const newLicence = this.state.licence;
    const connected = this.props.store.connected;
    const isAuthor = text && text.meta && text.meta.author
      && connected && connected.user && connected.user.id
      && connected.user.id === text.meta.author.id;

    return (
      <div style={{ marginTop: '10px', paddingBottom: '20px' }}>
        {isAuthor && !this.props.strangerMode && this.state.licenceEditMode ?
          <div className="ui segment" style={{ paddingBottom: '40px' }}>
            <form
              className="ui form"
              onSubmit={this.saveLicence}
            >
              <div className="ui message warning">
                Choisissez la licence qui convient le mieux à votre œuvre.
                <p>Par défaut, vous gardez tous les droits sur votre œuvre :
                  personne ne peut la distribuer, la modifier ou l'exploiter
                  sans votre autorisation.
                </p>
                <p>
                  Si vous souhaitez permettre à d'autres auteurs de reprendre,
                  partager ou même modifier votre œuvre sans votre autorisation préalable,
                   vous pouvez adopter les licences Creative Commons !
                   <br />Notre article : <a
                    href="/blog/2016/06/01/definition-droit-auteur-propriete-intellectuelle/"
                    target="_blank" rel="noopener noreferrer"
                  >Qu'est-ce que le droit d'auteur ?
                  </a>
                </p>
              </div>
              <Accordion styled inverted>
                <Accordion.Title active={newLicence === 1}
                  className={newLicence === 1 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(1)}>
                  Tous droits réservés
                </Accordion.Title>
                <Accordion.Content active={newLicence === 1}
                  className={newLicence === 1 ? 'accordion-content-success' : undefined}>
                  L'œuvre ne peut être distribuée, modifiée ou exploitée sans autorisation de l'auteur.
                </Accordion.Content>
                <Accordion.Title active={newLicence === 2}
                  className={newLicence === 2 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(2)}>
                  Licence CC - Attribution
                </Accordion.Title>
                <Accordion.Content active={newLicence === 2}
                  className={newLicence === 2 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY
                  </i>
                  <br />
                  l'œuvre peut être distribuée, exploitée ou modifiée sans restriction,
                  à condition de l'attribuer à l'auteur en citant son nom.
                  <br />
                  <a href="http://creativecommons.org/licenses/by/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 3}
                  className={newLicence === 3 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(3)}>
                  Licence CC - Attribution - Partage dans les mêmes conditions
                </Accordion.Title>
                <Accordion.Content active={newLicence === 3}
                  className={newLicence === 3 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY-SA
                  </i>
                  <br />
                  l'œuvre peut être distribuée, exploitée ou modifiée librement sous deux conditions:
                  <ul>
                    <li>
                      le nom de l'auteur doit être cité,
                    </li>
                    <li>
                      la distribution doit se faire sous une licence
                   identique à celle régissant l'œuvre originale.
                    </li>
                  </ul>
                  <a href="http://creativecommons.org/licenses/by-sa/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 4}
                  className={newLicence === 4 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(4)}>
                  Licence CC - Attribution - Pas de modification
                </Accordion.Title>
                <Accordion.Content active={newLicence === 4}
                  className={newLicence === 4 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY-ND
                  </i>
                  <br />
                  l'œuvre peut être distribuée ou exploitée librement sous deux conditions:
                  <ul>
                    <li>
                      le nom de l'auteur doit être cité,
                    </li>
                    <li>
                      l'œuvre ne doit pas connaître de modification ou la création d'œuvres dérivées.
                    </li>
                  </ul>
                  <a href="http://creativecommons.org/licenses/by-nd/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 5}
                  className={newLicence === 5 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(5)}>
                  Licence CC - Attribution - Pas d'utilisation commerciale
                </Accordion.Title>
                <Accordion.Content active={newLicence === 5}
                  className={newLicence === 5 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY-NC
                  </i>
                  <br />
                  l'œuvre peut être distribuée, exploitée ou modifiée
                  uniquement à des fins non-commerciales, à condition
                    de l'attribuer à l'auteur en citant son nom.
                  <br />
                  <a href="http://creativecommons.org/licenses/by-nc/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 6}
                  className={newLicence === 6 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(6)}>
                  Licence CC - Attribution - Pas d'utilisation commerciale - Pas de modification
                </Accordion.Title>
                <Accordion.Content active={newLicence === 6}
                  className={newLicence === 6 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY-NC-ND
                  </i>
                  <br />
                  l'œuvre peut être distribuée ou exploitée librement
                  uniquement à des fins non-commerciales sous deux conditions:
                  <ul>
                    <li>
                      le nom de l'auteur doit être cité,
                    </li>
                    <li>
                      l'œuvre ne doit pas connaître de modification ou la création d'œuvres dérivées.
                    </li>
                  </ul>
                  <a href="http://creativecommons.org/licenses/by-nc-nd/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 7}
                  className={newLicence === 7 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(7)}>
                  Licence CC - Attribution - Pas d'utilisation commerciale - Partage dans les mêmes conditions
                </Accordion.Title>
                <Accordion.Content active={newLicence === 7}
                  className={newLicence === 7 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons BY-NC-SA
                  </i>
                  <br />
                  l'œuvre peut être distribuée ou exploitée librement
                  uniquement à des fins non-commerciales sous deux conditions:
                  <ul>
                    <li>
                      le nom de l'auteur doit être cité,
                    </li>
                    <li>
                      la distribution doit se faire sous une licence
                   identique à celle régissant l'œuvre originale.
                    </li>
                  </ul>
                  <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
                <Accordion.Title active={newLicence === 8}
                  className={newLicence === 8 ? 'accordion-title-success' : undefined}
                  onClick={() => this.handleSelectLicence(8)}>
                  Licence CC - Transfert dans le Domaine Public
                </Accordion.Title>
                <Accordion.Content active={newLicence === 8}
                  className={newLicence === 8 ? 'accordion-content-success' : undefined}>
                  <i>
                    Licence Creative Commons 0
                  </i>
                  <br />
                  l'auteur abandonne tous ses droits d'auteur sur l'œuvre,
                  dans la limite de la loi de chaque pays.
                  <br />l'œuvre peut être distribuée, exploitée ou modifiée
                 sans aucune restriction, à l'exception de celles exigées par la loi de chaque pays.
                  <a href="https://creativecommons.org/publicdomain/zero/1.0/deed.fr">
                    La licence sur le site de la fondation Creative Commons
                  </a>
                </Accordion.Content>
              </Accordion>
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelLicenceEdit}
                >
                  Annuler
                </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveLicence}
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
          : <div>
            <Modal onClose={this.close} open={this.state.showModal}>
              <div className="header">
                <span style={{ marginRight: '5px' }}>
                  {licence === 1 ?
                    <i className="fa fa-fw fa-copyright " />
                    : <i className="fa fa-fw fa-creative-commons " />
                  }
                </span>
                <FormattedHTMLMessage
                  id={`licence.${licence}.name`}
                  defaultMessage="Nom de la licence"
                />
              </div>
              <div className="content">
                <i>
                  <FormattedHTMLMessage
                    id={`licence.${licence}.subname`}
                  />
                </i>
                <p>
                  <FormattedHTMLMessage
                    id={`licence.${licence}.description`}
                    defaultMessage="Description de la licence"
                  />
                </p>
              </div>
            </Modal>
            <span style={{ cursor: 'pointer', color: text.meta.textColor }} onClick={this.open}
              data-tooltip="En savoir plus sur la licence" data-inverted="" data-position="bottom center">
              <span style={{ marginRight: '5px' }}>
                {licence === 1 ?
                  <i className="fa fa-fw fa-copyright " />
                  : <i className="fa fa-fw fa-creative-commons " />
                }
              </span>
              <FormattedHTMLMessage
                id={`licence.${licence}.name`}
                defaultMessage="Nom de la licence"
              />
            </span>
            {isAuthor && !this.props.strangerMode ?
              <button className="ui right floated orange small label" onClick={this.startLicenceEdit}>
                Modifier la licence
          </button>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
