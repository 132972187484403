import { observable } from 'mobx';

export default class UserModel {
  @observable id: number;
  @observable avatarUrl: string;
  @observable urlIdentity: string;
  @observable identity: string;
  @observable admin: boolean;
  @observable twitter: string;

  constructor(id: number, avatarUrl: string, urlIdentity: string, 
  identity: string, admin: boolean, twitter: string) {
    this.id = id;
    this.avatarUrl = avatarUrl;
    this.urlIdentity = urlIdentity;
    this.identity = identity;
    this.admin = admin;
    this.twitter = twitter;
  }

  toJS() {
    return {
      id: this.id,
      avatarUrl: this.avatarUrl,
      urlIdentity: this.urlIdentity,
      identity: this.identity,
      admin: this.admin,
      twitter: this.twitter
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new UserModel(object.id, object.avatarUrl, object.urlIdentity,
        object.identity, object.admin, object.twitter);
    }
    return undefined;
  }
}
