import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
const $ = require('jquery');
import { scroller } from 'react-scroll';
import Store from '../../stores/Store';
import Duration from './Duration';

declare const __CLIENT__: any;

let annotator: any;
if (__CLIENT__) {
  annotator = require('annotator');
}

interface ChapterProps {
  store?: Store;
  showAnnotations: boolean;
  step: number;
}

@inject('store') @observer
export default class Chapter extends React.Component<ChapterProps, {}> {
  refs: {
    [string: string]: any;
    tbody: any;
  };
  app: any;

  constructor(props: ChapterProps) {
    super(props);
    this.getCategories = this.getCategories.bind(this);
    this.initAnnotator = this.initAnnotator.bind(this);
  }
  componentDidMount() {
    if (this.props.showAnnotations) {
      this.initAnnotator(true);
    }
    this.props.store.text.chapter.makeLoaded();
    if (__CLIENT__) {
      if (this.props.store.connected && this.props.store.connected.user
        && this.props.store.connected.user.id && this.props.store.connected.preferences
        && this.props.store.connected.preferences.fontFamily !== 'OpenSans') {
        const fontFamily = this.props.store.connected.preferences.fontFamily;
        let family;
        switch (fontFamily) {
          case 'OpenSans':
            family = 'Open Sans';
            break;
          case 'LibreBaskerville':
            family = 'Libre Baskerville';
            break;
          case 'DroidSerif':
            family = 'Droid Serif';
            break;
          case 'DroidSans':
            family = 'Droid Sans';
            break;
          default:
            family = 'Open Sans';
            break;
        }

        const WebFont = require('webfontloader');
        WebFont.load({
          google: {
            families: [family]
          }
        });
      }
    }
  }
  componentWillUnmount() {
    if (this.app) {
      this.app.destroy();
    }
  }
  componentWillReceiveProps(nextProps: ChapterProps) {
    if ((this.props.showAnnotations != null
      && nextProps.showAnnotations !== this.props.showAnnotations)
      || (this.props.step != null && nextProps.step !== this.props.step)) {
      this.initAnnotator(nextProps.showAnnotations);
    }
    this.props.store.text.chapter.makeLoaded();
  }
  getCategories() {
    switch (this.props.store.text.chapter.step) {
      case 1:
        return ['Intrigue & Idées'];
      case 2:
        return ['Ortho. & Grammaire', 'Style'];
      default:
        return [];
    }
  }
  @action
  initAnnotator(showAnnotations: boolean) {
    const t = this;
    const showAnnotationsNull = showAnnotations == null || showAnnotations === null;
    if (this.app) {
      this.app.destroy();
      this.app = null;
    }
    if ((showAnnotationsNull || showAnnotations === true) && __CLIENT__) {
      if (showAnnotations) {
        const element = ReactDOM.findDOMNode(this.refs.tbody);

        const annotationParams = () => {
          return {
            beforeAnnotationCreated: (ann: any) => {
              ann.chapterVersionId = this.props.store.text.chapter.version;
            }
          };
        };
        const viewerMode = function () {
          let ui: any = {};

          return {
            start: function () {
              ui.highlighter = new annotator.ui.highlighter.Highlighter(element);
              ui.viewer = new annotator.ui.viewer.Viewer({
                permitEdit: function (ann: any) { return false; },
                permitDelete: function (ann: any) { return false; },
                autoViewHighlights: element
              });
              ui.viewer.attach();
            },
            destroy: function () {
              ui.highlighter.destroy();
              ui.viewer.destroy();
            },
            annotationsLoaded: function (anns: any) {
              ui.highlighter.drawAll(anns);
            }
          };
        };
        const viewerExtension = function viewerExtension(v: any) {
          function updateViewer(field: any, annotation: any) {
            field = $(field);
            const allCategories = [
              { name: 'Normal', catClass: 'annotator-category-0' },
              { name: 'Intrigue & Idées', catClass: 'annotator-category-1' },
              { name: 'Style', catClass: 'annotator-category-2' },
              { name: 'Ortho. & Grammaire', catClass: 'annotator-category-3' }
            ];
            if (annotation.category) {
              let highlightClass = 'annotator-category';
              const cat = allCategories.filter(ac => ac.name === annotation.category);
              if (cat.length > 0) {
                highlightClass = highlightClass + ' ' + cat[0].catClass;
              }
              field.append(
                `<div class="${highlightClass}" style="position: fixed; top: 10px; right: 10px">` +
                `${annotation.category}</div>`
              );
            } else {
              field.remove();
            }
          }

          v.addField({
            load: updateViewer
          });
        };
        const editorExtension = function editorExtension(e: any) {
          // The input element added to the Annotator.Editor wrapped in jQuery.
          // Cached to save having to recreate it everytime the editor is displayed.
          let field: any = null;
          let input: any = null;

          function updateField(field: any, annotation: any) {
            let value = '';
            if (annotation.category) {
              value = annotation.category;
            }
            input.val(value);
          }

          function setCategory(field: any, annotation: any) {
            annotation.category = input.val();
          }
          field = e.addField({
            type: 'select',
            options: t.getCategories(),
            label: 'Motif :',
            load: updateField,
            submit: setCategory
          });
          input = $(field).find(':input');
        };

        const app = new annotator.App();
        const meta = this.props.store.text.meta;
        const connected = this.props.store.connected;

        const blockedBy = meta && connected && connected.user && connected.user.id
          && connected.blockedBy.indexOf(meta.author.id) !== -1;

        if (this.props.store.connected && this.props.store.connected.user
          && this.props.store.connected.user.id && !blockedBy && this.props.step < 3) {
          app.include(annotator.ui.main,
            {
              element: element,
              editorExtensions: [editorExtension],
              viewerExtensions: [viewerExtension]
            }
          );
        } else {
          app.include(viewerMode);
        }
        app.include(annotator.storage.http, {
          prefix: '/at'
        });
        app.include(annotator.authz.acl);
        app.include(annotationParams);

        app.start()
          .then(() => {
            if (this.props.store.connected && this.props.store.connected.user
              && this.props.store.connected.user.id) {
              app.ident.identity = this.props.store.connected.user.id;
            }
            app.annotations.load({
              chapterVersionId: this.props.store.text.chapter.version
            }).then(() => {
              const url = window.location.href;
              const regexp = /#annId_(.*)/;
              const match = regexp.exec(url);

              if (match.length > 1) {
                scroller.scrollTo(`ann_${match[1]}`, {
                  offset: -200,
                  duration: 500,
                  delay: 100,
                  smooth: true
                });
              }
            });
          });
        this.app = app;
      }
    }
  }
  render() {
    const chapter = this.props.store.text.chapter;

    let style: {};
    if (this.props.store.connected && this.props.store.connected.user
      && this.props.store.connected.user.id) {
      const preferences = this.props.store.connected.preferences;
      style = {
        fontFamily: preferences.computedFontFamily,
        fontSize: preferences.computedFontSize,
        lineHeight: preferences.computedFontSpaced
      };
    } else {
      style = {
        fontFamily: 'OpenSans',
        fontSize: '18px',
        lineHeight: '1.5'
      };
    }

    return (
      <div>
        <div className="ui container center aligned" style={{ paddingBottom: '50px' }}>
          <h2 style={{ margin: '30px' }}>{chapter.title}</h2>
          <Duration words={chapter.words} />
        </div>
        <div
          ref="tbody"
          className="ui read container text-chapter"
          style={style}
          dangerouslySetInnerHTML={{ __html: chapter.textContent }}
        />
      </div>
    );
  }
}
