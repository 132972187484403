const Megadraft = require('megadraft');
const MegadraftIcons = Megadraft.MegadraftIcons;
import UnderlineIcon from './UnderlineIcon';
import StrikeIcon from './StrikeIcon';
import H1Icon from './H1Icon';
import H2Icon from './H2Icon';
import AlignLeftIcon from './AlignLeftIcon';
import AlignCenterIcon from './AlignCenterIcon';
import AlignRightIcon from './AlignRightIcon';
import AlignJustifyIcon from './AlignJustifyIcon';

const actions = [
  { type: 'inline', label: 'B', style: 'BOLD', icon: MegadraftIcons.BoldIcon },
  { type: 'inline', label: 'I', style: 'ITALIC', icon: MegadraftIcons.ItalicIcon },
  { type: 'inline', label: 'U', style: 'UNDERLINE', icon: UnderlineIcon },
  { type: 'inline', label: 'S', style: 'STRIKETHROUGH', icon: StrikeIcon },
  { type: 'separator' },
  { type: 'block', label: 'UL', style: 'unordered-list-item', icon: MegadraftIcons.ULIcon },
  { type: 'block', label: 'OL', style: 'ordered-list-item', icon: MegadraftIcons.OLIcon },
  { type: 'separator' },
  { type: 'block', label: 'H1', style: 'header-one', icon: H1Icon },
  { type: 'block', label: 'H2', style: 'header-two', icon: H2Icon },
  { type: 'separator' },
  { type: 'block', label: 'LEFT', style: 'align-left', icon: AlignLeftIcon },
  { type: 'block', label: 'CENTER', style: 'align-center', icon: AlignCenterIcon },
  { type: 'block', label: 'RIGHT', style: 'align-right', icon: AlignRightIcon },
  { type: 'block', label: 'JUSTIFY', style: 'align-justify', icon: AlignJustifyIcon }
];

export default actions;
