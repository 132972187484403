import Config from '../config';
const dict = require('../dict');
const helpers2 = require('../helpers');

export function findTokens(that: { tokenized: any[], config: Config }) {
  const taggedWords = [];
  const taggedObjs: any[] = [];
  const wordsAsObj: { id: number, originalWord: string, word: string, tagged: boolean, stop: boolean }[] = [];
  that.tokenized.forEach((word, id) => {
    wordsAsObj.push({ id: id, originalWord: word, word: word.toLowerCase(), tagged: false, stop: word.stop });
  });
  const dicWord = that.config.strictness === true ? 'word' : 'word_nosc';

  wordsAsObj.forEach((wordObj, index) => {
    that.config.tagTypes.forEach((tagType: { name: string, minimumLength: number }) => {
      if (wordObj.word.length >= tagType.minimumLength) {
        const found = dict.all[`${tagType.name}Index`].indexOf(wordObj.word);
        if (found !== -1) {
          const wordDict = dict.all[tagType.name].lexi[found];
          let pos = wordDict.pos;
          if (pos === 'NOM') {
            const firstLetter = wordObj.originalWord.charAt(0);
            if (firstLetter !== firstLetter.toLowerCase() && firstLetter === firstLetter.toUpperCase()) {
              pos = 'NOMC';
            }
          }
          taggedObjs.push({
            id: wordObj.id,
            word: wordObj.word,
            lemma: wordDict.lemma,
            pos: pos,
            stop: wordDict.stop
          });
          wordsAsObj[index].tagged = true;
        }
      }
    });
  });

  //   });
  // });
  // Checking that there a no words left,
  // otherwise tagging them as "UNK"
  wordsAsObj.forEach((wordObj) => {
    if (wordObj.tagged !== true) {
      const firstLetter = wordObj.originalWord.charAt(0);
      taggedObjs.push({
        id: wordObj.id,
        word: wordObj.word,
        lemma: wordObj.word,
        pos: firstLetter !== firstLetter.toLowerCase() && firstLetter === firstLetter.toUpperCase() ? 'NOMC' : 'UNK',
        stop: wordObj.stop
      });
    }
  });
  return helpers2.orderByObjId(helpers2.removeDuplicateObjects(taggedObjs));
};

export function analyseTokens(that: { tokenized: any[], config: Config }) {
  const taggedWords = [];
  const taggedObjs: any[] = [];
  const wordsAsObj: { w: { originalWord: string, word: string, tagged: boolean, stop: boolean }, c: number }[] = [];
  that.tokenized.forEach((word, id) => {
    const exist = wordsAsObj.some(w => {
      if (w.w.originalWord === word) {
        w.c = w.c + 1;
        return true;
      }
      return false;
    });
    if (!exist) {
      wordsAsObj.push({ w: { originalWord: word, word: word.toLowerCase(), tagged: false, stop: word.stop }, c: 1 });
    }
  });
  const dicWord = that.config.strictness === true ? 'word' : 'word_nosc';

  wordsAsObj.forEach((wordObj, index) => {
    that.config.tagTypes.forEach((tagType: { name: string, minimumLength: number }) => {
      if (wordObj.w.word.length >= tagType.minimumLength) {
        const found = dict.all[`${tagType.name}Index`].indexOf(wordObj.w.word);
        if (found !== -1) {
          const wordDict = dict.all[tagType.name].lexi[found];
          let pos = wordDict.pos;
          if (pos === 'NOM') {
            const firstLetter = wordObj.w.originalWord.charAt(0);
            if (firstLetter !== firstLetter.toLowerCase() && firstLetter === firstLetter.toUpperCase()) {
              pos = 'NOMC';
            }
          }
          for (let i = 0; i < wordObj.c; i = i + 1) {
            taggedObjs.push({
              word: wordObj.w.word,
              lemma: wordDict.lemma,
              pos: pos,
              stop: wordDict.stop
            });
          }
          wordsAsObj[index].w.tagged = true;
        }
      }
    });
  });

  //   });
  // });
  // Checking that there a no words left,
  // otherwise tagging them as "UNK"
  wordsAsObj.forEach((wordObj) => {
    if (wordObj.w.tagged !== true) {
      const firstLetter = wordObj.w.originalWord.charAt(0);
      for (let i = 0; i < wordObj.c; i = i + 1) {
        taggedObjs.push({
          word: wordObj.w.word,
          lemma: wordObj.w.word,
          pos: firstLetter !== firstLetter.toLowerCase() && firstLetter === firstLetter.toUpperCase() ? 'NOMC' : 'UNK',
          stop: wordObj.w.stop
        });
      }
    }
  });

  return taggedObjs;
};
