import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import ReadingListTextModel from '../../models/readinglist/ReadingListTextModel';

interface ReadingListsLastTextProps {
  text: ReadingListTextModel;
  withDate?: boolean;
}

@inject('store') @observer
export default class ReadingListsLastText extends React.Component<ReadingListsLastTextProps, {}> {
  constructor(props: ReadingListsLastTextProps) {
    super(props);
  }
  render() {
    const text = this.props.text;
    const chapters = text.chapters.map(c => {
      let linkStyle: {};
      if (c.read) {
        linkStyle = { color: 'rgba(40, 40, 40, 0.3)' };
      }
      return (
        <tr key={c.id}>
          <td>
            <Link
              to={`/text/${text.id}/${text.urlTitle}/chapter/${c.id}`}
              style={linkStyle}
            >
              {c.title}
            </Link>
          </td>
          <td>
            <div className={'ui basic small ' + (c.read ? 'disabled' : 'blue') + ' label'}>
              {c.step === 1 ?
                'Premier jet'
                : (c.step === 2 ? 'Correction / Relecture' : 'Texte final')
              }
            </div>
          </td>
          {this.props.withDate ?
            <td className={c.read ? 'disabled' : ''}>
              {c.lastDate}
            </td>
            : undefined}
          <td>
            {c.isNew ?
              <div className={'ui small ' + (c.read ? 'disabled' : 'green') + ' label'}>
                Nouveau
              </div>
              : undefined
            }
          </td>
          <td>
            <div className={'ui small ' + (c.read ? 'disabled' : 'blue') + ' label'}>
              {c.read ? 'Lu' : 'Non lu'}
            </div>
          </td>
        </tr>
      );
    });
    return (
      <div key={Math.random() * 1000} className="item">
        <div className="ui mini image">
          <img src={text.cover} alt={`Image de couverture de ${text.title}`} />
        </div>
        <div className="content">
          <div className="header">{text.title}</div>
          <div className="meta">
            <span>de {text.user.identity}</span>
          </div>
          <div className="description">
            <table className="ui small very basic table">
              <tbody>
                {chapters}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
