import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import EmptyConnectedTopBar from './EmptyConnectedTopBar';

interface CleanTopBarProps {
  store: Store;
  location: { pathname: string };
}

@inject('store') @observer
export default class CleanTopBar extends React.Component<CleanTopBarProps, {}> {
  static fetchData(store: Store) {
    if (store.isAuthenticated()) {
      return store.loadConnected();
    }
  }
  constructor(props: CleanTopBarProps) {
    super(props);
  }

  render() {
    const connected = this.props.store.connected;
    const isConnected = this.props.store.isAuthenticated() && connected && connected.user && connected.user.id;
    return <EmptyConnectedTopBar location={this.props.location} /> ;
  }
}
