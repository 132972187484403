import { observable } from 'mobx';

class PromotionModel {
  id: number;
  content: string;
  until: string;
}

export default class SettingsAdminModel {

  @observable currentPromotion: PromotionModel;
  @observable allPromotions: PromotionModel[];

  constructor(currentPromotion: PromotionModel, allPromotions: PromotionModel[]) {
    this.currentPromotion = currentPromotion;
    this.allPromotions = allPromotions || [];
  }

  toJS() {
    return {
      communicationsEmail: this.currentPromotion,
      allPromotions: this.allPromotions
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SettingsAdminModel(object.currentPromotion, object.allPromotions);
    }
    return undefined;
  }
}
