import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../stores/Store';

const DropdownButton = (props: any) => {
  return (
    <span className="ui icon button">
      <i className="fa fa-fw fa-cloud-download" title="Télécharger" /> Télécharger
    </span>
  );
};

const DropdownIconButton = (props: any) => {
  return (
    <button className="ui icon button">
      <i className="fa fa-cloud-download" title="Télécharger" />
    </button>
  );
};

interface CannotDownloadProps {
  active: boolean;
  hide: Function | any;
}

class CannotDownload extends React.Component<CannotDownloadProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
        size="small"
      >
        <div className="content">
          Le téléchargement des œuvres est réservé aux membres de l'Atelier des auteurs.
        </div>
        <div className="actions">
          <Link to="/signup" className="ui primary button">Rejoindre l'Atelier des auteurs</Link>
          <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal>
    );
  }
}

interface TextChapterDownloadProps {
  store?: Store;
  icon?: boolean;
  vertical?: boolean;
  chapter?: boolean;
}

interface TextChapterDownloadState {
  activeCannotDownload: boolean;
}

@inject('store') @observer
export default class TextChapterDownload extends React.Component<TextChapterDownloadProps, TextChapterDownloadState> {
  constructor(props: TextChapterDownloadProps) {
    super(props);
    this.onMenuChange = this.onMenuChange.bind(this);
    this.hideCannotDownload = this.hideCannotDownload.bind(this);
    this.state = {
      activeCannotDownload: false
    };
  }
  hideCannotDownload() {
    this.setState({
      activeCannotDownload: false
    });
  }
  onMenuChange(value: string) {
    const text = this.props.store.text;
    if (this.props.store.isAuthenticated()) {
      const url = `/download/text/${text.meta.id}/${value}/${encodeURIComponent(text.meta.title)}.${value}`;
      window.location.href = url;
    } else {
      this.setState({
        activeCannotDownload: true
      });
    }
  }
  render() {
    return (
      <div data-tooltip="Télécharger" data-inverted=""
        data-position={this.props.vertical ? 'left center' : 'bottom center'}>
        <CannotDownload active={this.state.activeCannotDownload} hide={this.hideCannotDownload} />
        <Dropdown trigger={this.props.icon ? <DropdownIconButton /> : <DropdownButton />} icon={null}
          pointing={this.props.icon ? 'top right' : true}>
          <Dropdown.Menu>
            <Dropdown.Item value="epub" text="EPUB (liseuse)" onClick={() => this.onMenuChange('epub')} />
            <Dropdown.Item value="pdf" text="PDF" onClick={() => this.onMenuChange('pdf')} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
