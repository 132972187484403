import * as React from 'react';
import Title from '../misc/Title';

export default class IntProperty extends React.Component<{}, {}> {
  render() {
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ minHeight: '90vh', paddingTop: '30px' }}>
          <Title title="Propriété intellectuelle " />
          <h1>Propriété intellectuelle</h1>
          <div className="ui basic segment">
            <h2>Vous restez les propriétaires de vos œuvres</h2>
            <p>Comme nous l’indiquons dans nos Conditions Générales d’Utilisation :</p>
            <p><b>L’Atelier des auteurs ne revendique aucun droit de propriété sur l’ensemble des&nbsp;
            Contenus qui pourraient être publiés sur le Site par ses Membres.</b></p>
            <p>Lorsque vous publiez un texte sur l’Atelier des Auteurs, celui-ci est techniquement et&nbsp;
            légalement votre propriété en tant qu'auteur. Comme le dit la loi :&nbsp;
            "la qualité d'auteur appartient, sauf preuve contraire, à celui ou à ceux sous&nbsp;
            le nom de qui l’œuvre est divulguée" (L113-1 du Code de la Propriété Intellectuelle).</p>
            <p>De plus, nos serveurs auront enregistré l'instant auquel vous aurez publié&nbsp;
            votre texte. Cette date sera inscrite dans nos bases de données, ce qui peut&nbsp;
            constituer une preuve d'antériorité de votre œuvre.</p>
            <p>Cependant, cette preuve peut être contestable, et l’Atelier des Auteurs n'est pas une&nbsp;
            société de gestion des droits d'auteur.</p>

            <h2>Vous nous permettez d’afficher vos œuvres sur l’Atelier des Auteurs</h2>
            <p>Pendant la durée de l’hébergement de votre œuvre sur l’Atelier des Auteurs, vous nous accordez&nbsp;
            une licence nous permettant de l’afficher normalement sur le site. Cela nous autorise&nbsp;
            à publier l’œuvre hébergée sur l’Atelier des Auteurs.</p>
            <p>Nous souhaitons insister sur le point suivant : cette licence n’est accordée que&nbsp;
            pendant la durée de l’hébergement. Cela signifie qu’en supprimant une œuvre du site,&nbsp;
            cela nous retire les droits que vous nous accordiez.</p>

            <h2>Sensibilisation</h2>
            <p>Nous vous invitons très fortement à faire attention aux Conditions Générales d’Utilisation&nbsp;
            et règlements des sites que vous utilisez pour diffuser vos œuvres !</p>
            <p>Certaines plateformes peuvent revendiquer une licence exclusive sur vos œuvres.&nbsp;
            D’autres peuvent disposer d’un droit sur vos créations et vos publications.</p>
            <p>N’hésitez jamais à demander à l’administration d’un site quelle est sa position&nbsp;
            sur ces points, à lire les C.G.U. ou même à faire des recherches sur ce point.</p>
          </div>
        </div>
      </div>
    );
  }
}
