import * as React from 'react';
import { Link } from 'react-router';
import Title from '../misc/Title';

export default class Faq extends React.Component<{}, {}> {
  render() {
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff', paddingTop: '30px'}}>
        <div className="ui text read container" style={{ paddingBottom: '100px' }}>
          <Title title="Foire aux questions" />
          <h1>Foire aux questions</h1>
          <h2>La plateforme</h2>
          <ul className="ui large list">
            <li><a href="#q11">Qu’est-ce que l'Atelier des Auteurs?</a></li>
            <li><a href="#q14">À qui appartiennent les œuvres publiées sur l'Atelier des Auteurs ?</a></li>
          </ul>
          <h2>L'école</h2>
          <ul className="ui large list">
            <li><a href="#q40">Je viens de lancer un parcours. Puis-je en lancer un second ?</a></li>
            <li><a href="#q41">Qui contacter en cas de problème ou de question ?</a></li>
         </ul>
          <h2>La communauté gratuite</h2>
          <ul className="ui large list">
            <li><a href="#q21">Comment publier une œuvre sur l'Atelier des Auteurs ?</a></li>
            <li><a href="#q23">Comment être averti de la publication de nouveaux chapitres d’une œuvre que j’apprécie ?</a></li>
            <li><a href="#q24">Comment ajouter un résumé, des mots-clefs ou même une couverture à mon œuvre ?</a></li>
            <li><a href="#q25">Comment ajouter un chapitre à mon œuvre ?</a></li>
            <li><a href="#q26">Comment modifier un chapitre publié ?</a></li>
            <li><a href="#q27">Comment répondre à un défi ?</a></li>
          </ul>
          <h2>Questions techniques</h2>
          <ul className="ui large list">
            <li><a href="#q31">Existe-t-il une application sur les stores iOS ou Android ?</a></li>
            <li><a href="#q32">J’ai tenté de copier-coller mon texte à partir de Word et ça ne semble pas marcher ! Comment faire ?</a></li>
            <li><a href="#q33">J'ai l'impression que l'éditeur « ne marche pas » ou connaît des problèmes... que faire ?</a></li>
            <li><a href="#q34">Les annotations ont disparu sur la page de lecture d'un chapitre !</a></li>
            <li><a href="#q35">Quels sont les navigateurs Internet conseillés pour utiliser l'Atelier des Auteurs ?</a></li>
          </ul>
          <div className="ui basic segment">
            <h2 className="ui horizontal section divider">La plateforme</h2>
            <span className="anchor" id="q11" />
            <h2>Qu’est-ce que l'Atelier des Auteurs?</h2>
            <p>L’Atelier des Auteurs est une plateforme composée d’une école d’écriture et d’un espace communautaire. Son principal objectif est de permettre à chacun de progresser dans son art et d'améliorer ses œuvres.</p>

            {/* <span className="anchor" id="q12" />
            <h2>Qu'est-ce que l'Académie ?</h2>
            <p>L'Académie est l'offre pédagogique et payante de Scribay. Elle permet à tout le monde d'écrire son premier roman un chapitre après l'autre à l'aide d'une méthode unique. Afin de savoir si l'Académie correspond à vos besoins, vous pouvez profiter d'un essai gratuit de 15 jours et nous poser vos questions directement.
              <br /><Link to="/academie">En savoir plus</Link>
            </p> */}

            {/* <span className="anchor" id="q13" />
            <h2>Dois-je payer pour profiter de Scribay ?</h2>
            <p>Non ! Toute la plateforme sociale destinée aux auteurs est gratuite.
            <br />Ainsi, toutes les fonctionnalités sociales (commentaires, annotations, défis littéraires, espace Échanger, etc.) et de publication sont gratuites.
            </p>
            <p>L'Académie est l'offre pédagogique payante de Scribay. Ses fonctionnalités sont privées et réservées à ses adhérents.
            </p> */}

            <span className="anchor" id="q14" />
            <h2>À qui appartiennent les œuvres publiées sur l'Atelier des Auteurs ?</h2>
            <p>À vous ! Vous restez les propriétaires de vos œuvres et chaque auteur est responsable des contenus qu'il publie sur l'Atelier des Auteurs.
            <br />Entre autres, nous ne vous imposons pas non plus « d'option » sur vos textes ou une obligation si vous vous faites éditer. L'Atelier des Auteurs ne gagne pas d'argent grâce au contenu publié sur l'Atelier des Auteurs et n'a aucun intérêt à imposer ce type de pratiques.
            <br /><Link to="/intproperty">Voir notre page « Propriété intellectuelle »</Link>
            </p>

     {/*        <span className="anchor" id="q15" />
            <h2>Je vais me faire éditer ! Mon manuscrit est sur Scribay, que dois-je faire ?</h2>
            <p>Félicitations !
            <br />Chaque éditeur adopte une stratégie différente par rapport aux réseaux sociaux et à une plateforme d'écriture comme Scribay. Sachez juste que vous pouvez privatiser ou supprimer votre œuvre en quelques clics sans difficulté.
            </p> */}

            <h2 className="ui horizontal section divider">L’école</h2>

            <span className="anchor" id="q40" />
            <h2>Je viens de lancer un parcours. Puis-je en lancer un second ?</h2>
            <p>Oui ! Vous pouvez lancer plusieurs parcours et donc débuter plusieurs récits à partir d’une même structure afin de réaliser plusieurs essais.
            </p>


            <span className="anchor" id="q41" />
            <h2>Qui contacter en cas de problème ou de question ?</h2>
            <p>Vous pouvez contacter l’équipe de l’Atelier par email.
            </p>



            <h2 className="ui horizontal section divider">La communauté gratuite</h2>
            <span className="anchor" id="q21" />
            <h2>Comment publier une œuvre sur l'Atelier des Auteurs?</h2>
            <b>À partir de la page d’accueil :</b>
            <ol>
              <li>Cliquez sur le bouton « Nouvelle œuvre ». </li>
              <li>Cliquez sur « Page blanche ».</li>
            </ol>
            <b>À partir du menu latéral, accessible en cliquant sur le menu en haut à gauche :</b>
            <ol>
              <li>Cliquez sur « Écrire ». </li>
              <li>Cliquez sur le bouton « Nouvelle œuvre » de la page Écrire.</li>
              <li>Cliquez sur « Page blanche ».</li>
            </ol>
            <p>Vous pourrez rédiger ainsi le brouillon de votre premier texte. Vous pourrez choisir de le publier immédiatement ou d’attendre afin d'écrire d'autres chapitres avant sa publication.</p>
{/* 
            <span className="anchor" id="q22" />
            <h2>Comment recevoir des commentaires sur mes publications ?</h2>
            <p>Scribay est avant tout un espace de travail et d’entraide. La meilleure solution pour recevoir des retours sur ses textes est de s’investir dans la communauté : aider d’autres membres en commentant leurs œuvres, par exemple.</p>
            <p>La plateforme met en avant deux types de membres : les nouveaux venus (afin de leur souhaiter la bienvenue !) et les auteurs investis au sein de la communauté.</p>
 */}
            <span className="anchor" id="q23" />
            <h2>Comment être averti de la publication de nouveaux chapitres d’une œuvre que j’apprécie ?</h2>
            <p>Il suffit de l’ajouter à une de vos listes de lecture. L’ajout est proposé automatiquement lorsque vous arrivez à la fin d’un chapitre. </p>
            <p>Vous pouvez également ajouter une œuvre à l’une de vos listes de lecture en cliquant sur le bouton « Liste » situé sur la page de présentation de votre œuvre et sur chaque page de lecture d’un chapitre.</p>

            <span className="anchor" id="q24" />
            <h2>Comment ajouter un résumé, des mots-clefs ou même une couverture à mon œuvre ?</h2>
            <p>Vous pouvez modifier tous ces éléments à partir de la page de présentation de votre œuvre. </p>
            <p>Pour y accéder, vous avez le choix entre :</p>
            <ol>
              <li>Cliquer sur le titre de votre œuvre en haut à gauche de votre éditeur de texte.</li>
              <li>Cliquer sur le titre de votre œuvre dans votre espace Écrire, accessible en cliquant sur le menu accessible en haut à gauche.</li>
              <li>Cliquer sur le titre de votre œuvre à partir de l’espace « Chapitres en cours d'écriture » sur votre page d’accueil.</li>
            </ol>

            <span className="anchor" id="q25" />
            <h2>Comment ajouter un chapitre à mon œuvre ?</h2>
            <p><b>À partir de la page de présentation de votre œuvre :</b>
              <br />Cliquez sur le bouton « Nouveau chapitre » situé sous l’espace « Table des matières ».</p>
            <p><b>À partir de l’éditeur de texte :</b>
              <br />Cliquez sur « Structure » puis « Nouveau chapitre ».</p>

            <span className="anchor" id="q26" />
            <h2>Comment modifier un chapitre publié ?</h2>
            <div><b>À partir de la page de présentation de votre œuvre : </b></div>
            <ol>
              <li>Cliquez sur le bouton orange situé en bout de la ligne consacrée à un chapitre dans la Table des Matières. Vous accèderez à l’éditeur de texte. </li>
              <li>Une fois les modifications réalisées, vous pouvez mettre votre texte à jour en cliquant sur « En cours » puis « Publier les modifications ».</li>
            </ol>
            <div><b>À partir de la page de lecture d’un chapitre :</b></div>
            <ol>
              <li>Cliquez sur le bouton orange situé dans la barre supérieure de la page de lecture. Vous accèderez à l’éditeur de texte. </li>
              <li>Une fois les modifications réalisées, vous pouvez mettre votre texte à jour en cliquant sur « En cours » puis « Publier les modifications ».</li>
            </ol>

            <span className="anchor" id="q27" />
            <h2>Comment répondre à un défi ?</h2>
            <ol>
              <li>Rendez-vous sur la <Link to="/defis">page des défis littéraires</Link></li>
              <li>Un défi vous intéresse ? Cliquez sur "En savoir plus".</li>
              <li>Cliquez sur "Relevez ce défi" puis suivez les indications affichées à l'écran.</li>
            </ol>
            <span>La réponse à un défi littéraire est comparable à n'importe quelle œuvre de l'Atelier des Auteurs.</span>

            <h2 className="ui horizontal section divider">Questions techniques</h2>
            <span className="anchor" id="q31" />
            <h2>Existe-t-il une application sur les stores iOS ou Android ?</h2>
            <p>Non.
            <br />
              L'Atelier des Auteurs est une « progressive web app ». Vous pouvez la retrouver aux côtés de vos autres applications en cliquant sur le menu de votre navigateur de votre smartphone puis « Ajouter à l’écran d’accueil ».
            </p>

            <span className="anchor" id="q32" />
            <h2>J’ai tenté de copier-coller mon texte à partir de Word et ça ne semble pas marcher ! Comment faire ?</h2>
            <p>Certaines versions de Word adoptent un formatage spécial pour leurs documents.
            <br />Nous conseillons la manipulation suivante :</p>
            <ol>
              <li>Copiez-collez votre texte de Word à un éditeur de texte au formatage plus simple. Exemple : Bloc-note ou Notepad sous Windows / TextEdit sous Mac.</li>
              <li>Copiez-collez le texte à partir de ce second éditeur de texte vers l'Atelier des Auteurs.</li>
            </ol>

            <span className="anchor" id="q33" />
            <h2>J'ai l'impression que l'éditeur « ne marche pas » ou connaît des problèmes... que faire ?</h2>
            <p>
              L'Atelier des Auteurs est optimisé pour les dernières versions des navigateurs Chrome et Firefox.
              <br />Avant de nous contacter pour ce problème, nous vous conseillons de vérifier quel est votre navigateur et s'il est à jour.
            </p>

            <span className="anchor" id="q34" />
            <h2>Les annotations ont disparu sur la page de lecture d'un chapitre !</h2>
            <p>Chaque annotation est rattachée à la version d'un texte. Les annotations disparaissent donc lorsque vous publiez la nouvelle version d'un chapitre. Vous pouvez retrouver les anciennes versions de votre texte en cliquant sur le bouton "Versions" en bas de la page de lecture d'un chapitre.</p>


            <span className="anchor" id="q35" />
            <h2>Quels sont les navigateurs Internet conseillés pour utiliser l'Atelier des Auteurs?</h2>
            <p>
              Nous conseillons l’utilisation des dernières versions des navigateurs Mozilla Firefox ou Chrome.
            <br />
              En cas de problème, notre premier conseil est toujours de vérifier quel est le navigateur utilisé et s’il est à jour.
            </p>
          </div>
        </div >
      </div>
    );
  }
}
