import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';

interface RelectureTextToolbarProps {
  store?: Store;
}

@inject('store') @observer
export default class RelectureTextToolbar
  extends React.Component<RelectureTextToolbarProps, {}> {
  constructor(props: RelectureTextToolbarProps) {
    super(props);
    this.disableRelectureMode = this.disableRelectureMode.bind(this);
  }
  disableRelectureMode() {
    this.props.store.write.currentText.currentChapter.disableRelectureMode();
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    const infos = this.props.store.write.currentText.infos;
    return (
      <div className="ui secondary orange inverted menu relecture-bar" style={{ marginTop: "0px", overflowX: 'scroll', overflow: 'auto' }}>
        <div className="item" style={{ border: 'none', height: '60px', color: '#fff' }}>
          <b>{infos.title}</b>&nbsp;-&nbsp;Chapitre "{chapter.title}"
        </div>
        <div className="stackable right menu">
          <div className="item">
            <button className="ui button" onClick={this.disableRelectureMode}>
              Quitter la relecture
            </button>
          </div>
        </div>
      </div>
    );
  }
}
