import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import NewParcoursModal from '../../choose/NewParcoursModal';
import { browserHistory } from 'react-router';
import TrailerModal from '../../../premium/TrailerModal';
import MultipleAuthorBioModal from '../../../premium/MultipleAuthorBioModal';



interface NewParcoursChoicePageContentProps {
  store?: Store;
}

interface NewParcoursChoicePageContentState {
  message: string;
  parcoursIsActive: boolean;
  activeParcours?: number;
  trailerModalActive: boolean;
  trailerContent: string; 
  trailerTitle: string;
  authorBioModalActive: boolean;
  authorBioContent: string; 
  authorBioTitle: string;
}

@inject('store') @observer
export default class AllParcoursPageContent extends React.Component<NewParcoursChoicePageContentProps, NewParcoursChoicePageContentState> {
  constructor(props: NewParcoursChoicePageContentProps) {
    super(props);
    this.showParcours = this.showParcours.bind(this);
    this.hideParcours = this.hideParcours.bind(this);
    this.state = {
      message: '',
      parcoursIsActive: false,
      trailerModalActive: false,
      trailerContent: undefined,
      trailerTitle: undefined,
      authorBioModalActive: false,
      authorBioContent: undefined,
      authorBioTitle: undefined
    }; 
    this.hideAuthorBioModal = this.hideAuthorBioModal.bind(this);
    this.activateAuthorBioModal = this.activateAuthorBioModal.bind(this);
    this.hideTrailerModal = this.hideTrailerModal.bind(this);
    this.activateTrailerModal = this.activateTrailerModal.bind(this);
  
  }
  buyParcours(productId: number) {
    browserHistory.push({
      pathname: '/atelier/'+productId
    });
  }
  buyAllInclusive() {
    /* this.props.store.buyAllInclusive();
    browserHistory.push({
      pathname: '/paymenthub'
    }); */
  }
  showParcours(parcoursId: number) {
    this.setState({
      parcoursIsActive: true,
      activeParcours: parcoursId
    });
  }
  hideParcours() {
    this.setState({
      parcoursIsActive: false,
      activeParcours: null
    });
  }
  activateAuthorBioModal(content: string, title: string) {
    this.setState({authorBioContent: content, authorBioTitle: title, authorBioModalActive: true})
  }
  hideAuthorBioModal() {
    this.setState({authorBioContent: undefined, authorBioTitle: undefined, authorBioModalActive: false})
  }
  activateTrailerModal(trailer: string, title: string) {
    this.setState({trailerContent: trailer, trailerTitle: title, trailerModalActive: true})
  }
  hideTrailerModal() {
    this.setState({trailerContent: undefined, trailerTitle: undefined, trailerModalActive: false})
  }
  feather(feather: number, id: number) {
    let feathers: any = []
    for (let i = 0; i < feather; i++) { feathers.push(<span className="fa fa-leaf" key={id+'feather'+i}></span>)}
    return feathers;
  }
  render() {
    const premium = this.props.store.connected.premium;
    const allParcours = this.props.store.allParcours; 
    const allCards = this.props.store.allCards; 
    let parcoursOwnedNumber = 0;
    let parcoursToBuyNumber = 0;


    const allParcoursElementBought = allParcours.map((parcours, i) => {
      if (premium && (premium.allInclusive || ((premium.marketIdentifier & parcours.marketIdentifier) != 0))) parcoursOwnedNumber++;
      return (
        premium &&  parcours.marketIdentifier && (premium.allInclusive || ((premium.marketIdentifier & parcours.marketIdentifier) != 0)) ?
        <div key={i} className="column">
          <NewParcoursModal active={this.state.parcoursIsActive && this.state.activeParcours === parcours.id} hide={this.hideParcours} parcoursId={parcours.id} passFirstSlide />
          {/* {parcours.marketIdentifier ? */}
          <div className="ui card ada-parcours-card" style={{  minHeight: "570px"}}>
          <div className="ui image">
                <img src={`${parcours.image}`} />
            </div>
            <div className="content ada-card-border ada-parcours-name">
              <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: parcours.name }} />
            </div>
            <div className="content ada-card-border ada-card-else">
              {/* {parcours.authorsLink !== "" ? 
                <div style={{textAlign: "center", flex: 0, flexGrow: 1}}>
                  <b><p className="ada-learn-more" onClick={() => this.activateAuthorBioModal(parcours.authorsDescription, parcours.authorsLink)}>
                  <i className="fa fa-angle-right"/> <u>{parcours.authorsLink}</u>
                  </p></b>
                </div> 
              : undefined} */}
              {parcours.titleDescription !== "" ? <div style={{flex: 0, flexGrow: 1}} className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: parcours.titleDescription }} /> : undefined}
              {parcours.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
              <div style={{flex: 0, flexGrow: 1}} dangerouslySetInnerHTML={{ __html: parcours.shortDescription }} />
              {/* {parcours.parcoursVideoUrl !== "" ?  <b><p style={{textAlign: "center"}} className="ada-learn-more"  onClick={() => this.activateTrailerModal(parcours.parcoursVideoUrl, "Trailer")}>
                <i className="fa fa-angle-right"/><u> BANDE-ANNONCE</u></p></b> 
              : undefined} */}
             {/*  {parcours.objective !== "" ? <div className="ui divider"></div> : undefined}
              {parcours.objective !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.objective }} /> : undefined} */}
              
                <div className="ui divider"></div>
              {/* {parcours.feather !== 0 ? <p>Taille du projet : {this.feather(parcours.feather, parcours.id)} </p>  : undefined}
              {parcours.estimatedLength !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.estimatedLength }} /> : undefined} */}
{/*               {parcours.videosDescription !== "" ?<p><span style={{marginRight: "5px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: parcours.videosDescription }} /></p> : undefined}
 */}
              
              {parcours.id ?
                  <div className="sub header">
                      <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                        <button
                          className="ui primary button"
                          onClick={() => this.showParcours(parcours.id)}
                          style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                        >
                          Accéder au cours
                        </button>
                      </div>
                    </div>
              : undefined}
              </div>
          </div> 
          {/* : undefined} */}
        </div>
               : undefined         
      );
    });

    const allParcoursElementToBuy = allCards.map((parcours, i) => {
      if (premium && (!((premium.allInclusive && !(parcours.productId && !parcours.marketIdentifier)) && ((premium.marketIdentifier & parcours.marketIdentifier) != 0)))) parcoursToBuyNumber++;
      return (
          premium && (!((premium.allInclusive && !(parcours.productId && !parcours.marketIdentifier)) || ((premium.marketIdentifier & parcours.marketIdentifier) != 0))) ?
          <div key={i} className="column">
            {/* {parcours.marketIdentifier ?//TODO how to we display bonus parcours? */}
            <div className="ui card ada-parcours-card" style={{  minHeight: "700px"}}>
              <div className="ui image">
                  <img src={`${parcours.image}`} />
              </div>
              <div className="content ada-card-border ada-parcours-name">
                <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: parcours.name }} />
              </div>
              <div className="content ada-card-border ada-card-else">
                {parcours.authorsLink !== "" ? 
                  <div style={{textAlign: "center", flex: 0, flexGrow: 1}}>
                    <b><p className="ada-learn-more" onClick={() => this.activateAuthorBioModal(parcours.authorsDescription, parcours.authorsLink)}>
                    <i className="fa fa-angle-right"/> <u>{parcours.authorsLink}</u>
                    </p></b>
                  </div> 
                : undefined}
                {parcours.titleDescription !== "" ? <div style={{flex: 0, flexGrow: 1}} className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: parcours.titleDescription }} /> : undefined}
                {parcours.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
                <div style={{flex: 0, flexGrow: 1}} dangerouslySetInnerHTML={{ __html: parcours.shortDescription }} />
                {parcours.trailerUrl !== "" && parcours.trailerUrl !== null ?  <b><p style={{textAlign: "center"}} className="ada-learn-more"  onClick={() => this.activateTrailerModal(parcours.trailerUrl, "Trailer")}>
                  <i className="fa fa-angle-right"/><u> BANDE-ANNONCE</u></p></b> 
                : undefined}
              {/*  {parcours.objective !== "" ? <div className="ui divider"></div> : undefined}
                {parcours.objective !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.objective }} /> : undefined} */}
                
                  <div className="ui divider"></div>
                {/* {parcours.feather !== 0 ? <p>Taille du projet : {this.feather(parcours.feather, parcours.id)} </p>  : undefined}
                {parcours.estimatedLength !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.estimatedLength }} /> : undefined} */}
                {/*{parcours.videosDescription !== "" ?<p><span style={{marginRight: "5px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: parcours.videosDescription }} /></p> : undefined}
                */}
              {parcours.productPrice !== 0 && parcours.marketIdentifier ?
                <div style={{marginBottom: "15px"}}>
                  {parcours.promotionPercentage ? 
                    <div style={{textAlign: "center"}}>
                      <button style={{marginRight: "5px"}} className="ui button ada-price">
                        <span className="price-cross">{parcours.productPrice} €</span>
                      </button>
                      <button style={{marginLeft: "5px"}} className="ui button ada-price-promo">
                        <span>  -{parcours.promotionPercentage}% </span><i className="fa fa-arrow-right" /> 
                        <span><b> {parcours.currentPrice} €</b></span>
                      </button>
                      </div>
                  :
                  <div style={{textAlign: "center"}}>
                    <button className="ui button ada-price">
                      {parcours.productPrice} €
                    </button>
                  </div>
                }
                    </div>
                :
                  <span>Inclus dans le pack <b>l'UNIVERSEL</b></span>
                }
              {parcours.id ?
                <div className="sub header">
                  <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
                    {parcours.stripeIdentifier && parcours.marketIdentifier ?
                      <button
                        className="ui primary button ada-background editis-bleu" 
                        onClick={() => this.buyParcours(parcours.productId)} 
                        style={{ cursor: 'pointer'}}
                      >
                        Découvrir ce parcours
                      </button>
                      : undefined }
                    {!parcours.productId && !premium.allInclusive ?
                    <button
                      className="ui primary button"
                      onClick={() => this.buyParcours(6000)} 
                      style={{ marginTop: "5px", cursor: 'pointer'}}
                    >
                      Découvrir l'universel
                    </button>
                    : undefined}
                  </div>
                </div>
              : undefined}
              </div>
            </div> 
            {/* : undefined} */}
          </div>
          : undefined       
      );
    });



//     const allParcoursElement = allParcours.map((parcours, i) => {
    
//       return (
//           <div key={i} className="column">
//             <NewParcoursModal active={this.state.parcoursIsActive && this.state.activeParcours === parcours.id} hide={this.hideParcours} parcoursId={parcours.id} passFirstSlide />
//             <div className="ui card ada-parcours-card">
//               <div className="ui image">
//                   <img src={`${parcours.image}`} />
//               </div>
//               <div className="content ada-card-border ada-parcours-name">
//                 <span style={{color: "white", textAlign: "center"}} className="header">{parcours.name}</span>
//               </div>
//               <div className="content ada-card-border ada-card-else">
//                 {parcours.titleDescription !== "" ? <div className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: parcours.titleDescription }} /> : undefined}
//                 {parcours.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
//                 <div dangerouslySetInnerHTML={{ __html: parcours.shortDescription }} />
//                 <div className="ui divider"></div>
//                 {parcours.objective !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.objective }} /> : undefined}
//                 {parcours.objective !== "" ? <div className="ui divider"></div> : undefined}
// {/*                 {parcours.feather !== 0 ? <p>Taille du projet : {this.feather(parcours.feather, parcours.id)} </p>  : undefined}
//  */}                {parcours.estimatedLength !== "" ? <div dangerouslySetInnerHTML={{ __html: parcours.estimatedLength }} /> : undefined}
//                 {parcours.videosDescription !== "" ?<p><span style={{marginRight: "5px", marginBottom:"10px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: parcours.videosDescription }} /></p> : undefined}

//                   {parcours.id ?
//                     premium.allInclusive || ((premium.marketIdentifier & parcours.marketIdentifier) != 0) ?
//                       <div className="sub header">
//                           <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
//                             <button
//                               className="ui primary button"
//                               onClick={() => this.showParcours(parcours.id)}
//                               style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
//                             >
//                               Accéder au cours
//                             </button>
//                           </div>
//                       </div>
//                       : parcours.marketIdentifier ? 
//                         <div className="sub header">
//                             <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
//                               <button
//                                 className="ui primary button ada-background editis-bleu" 
//                                 onClick={() => this.buyParcours(parcours.marketIdentifier, parcours.stripeIdentifier)} 
//                                 style={{ cursor: 'pointer'}}
//                               >
//                                 Acheter ce parcours
//                               </button>
//                               <button
//                                 className="ui primary button"
//                                 onClick={() => this.buyAllInclusive()} 
//                                 style={{ marginTop: "5px", cursor: 'pointer'}}
//                               >
//                                 Acheter l'universel
//                               </button>
//                             </div>
//                         </div>
//                       :
//                         <div className="sub header">
//                           <div style={{paddingTop: "0.6rem", textAlign: "center"}}>
//                             <button
//                               className="ui primary button"
//                               onClick={() => this.buyAllInclusive()} 
//                               style={{ marginTop: "5px", cursor: 'pointer'}}
//                             >
//                               Acheter l'universel
//                             </button>
//                           </div>
//                         </div> 
                      
//                   : undefined}
//                 </div>
//             </div>
//           </div>
//       );
//     });
    return (
      <div>
        {parcoursOwnedNumber == 1 ?
          <div>
            <div className="ui horizontal hide-tablet divider ada-divider-chill">
              Démarrer votre parcours
            </div>
            <div className="ui horizontal hide-tablet-large ada-divider-chill">
              Démarrer votre parcours
            </div>
          </div>
        :
          <div>
            <div className="ui horizontal hide-tablet divider ada-divider-chill">
              Démarrer un parcours
            </div>
            <div className="ui horizontal hide-tablet-large ada-divider-chill">
              Démarrer un parcours
            </div>
          </div>
        }
        <div className="ui relaxed centered three column grid stackable cards" style={{paddingBottom: '30px'}}>
          {allParcoursElementBought}
          <MultipleAuthorBioModal 
                active={this.state.authorBioModalActive} hide={this.hideAuthorBioModal}
                content={this.state.authorBioContent} title={this.state.authorBioTitle}/>
          <TrailerModal 
                active={this.state.trailerModalActive} hide={this.hideTrailerModal}
                content={this.state.trailerContent} title={this.state.trailerTitle}/>
        </div>
        {parcoursToBuyNumber != 0 ?
          <div>
            <div className="ui hide-tablet horizontal divider ada-divider-chill">
              Acheter un nouveau parcours
            </div>
            <div className="ui horizontal hide-tablet-large ada-divider-chill">
              Acheter un nouveau parcours
            </div>
          </div>
        :
          undefined}
        <div className="ui relaxed centered three column grid stackable cards" style={{paddingBottom: '30px'}}>
          {allParcoursElementToBuy}
        </div>
      </div>
    );
  }
}
