import * as React from 'react';
import { inject, observer } from 'mobx-react';
const HotKeys = require('react-hotkeys').HotKeys;
const xpath = require('xpath');
import MediaQuery from 'react-responsive';
import * as bowser from 'bowser';
import { DOMParser, XMLSerializer } from 'xmldom';
import Store from '../../../../stores/Store';
import Loader from '../../../misc/Loader';
import ChapterToolbar from './toolbar/ChapterToolbar';
import FullEditor from './editor/FullEditor';
import ChapterParams from '../ChapterParams';
import StartHelp from './StartHelp';
import { extractAnnotations } from '../../../../utils/AnnotationsExtractor';
import editorSteps from '../../../tour/editorSteps';
import editorMobileSteps from '../../../tour/editorMobileSteps';
import RelectureType from '../../../../models/write/RelectureType';

interface ChapterWithChildrenProps {
  store?: Store;
  params: ChapterParams;
  location: any;
}

interface ChapterWithChildrenState {
  showStatistics: boolean;
  showHelp: boolean;
  tour: boolean;
}

declare const __CLIENT__: any;

require('react-joyride/lib/react-joyride-compiled.css');

@inject('store') @observer
export default class ChapterWithChildren extends React.Component<ChapterWithChildrenProps, ChapterWithChildrenState> {
  _timer: any;
  refs: {
    [string: string]: any;
    joyride: any;
  };

  constructor(props: ChapterWithChildrenProps) {
    super(props);
    this.initChapter = this.initChapter.bind(this);
    this.showStatistics = this.showStatistics.bind(this);
    this.hideStatistics = this.hideStatistics.bind(this);
    this.poll = this.poll.bind(this);
    this.save = this.save.bind(this);
    this.blockRefreshByMistake = this.blockRefreshByMistake.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.startHelp = this.startHelp.bind(this);

    this.state = {
      showStatistics: false,
      showHelp: this.props.location && this.props.location.query
      && this.props.location.query.m === 'h',
      tour: false
    };
  }
  componentDidMount() {
    this.startPolling();
    this.initChapter();
    window.addEventListener('beforeunload', this.blockRefreshByMistake);
  }
  componentWillReceiveProps(nextProps: ChapterWithChildrenProps) {
    const w = this.props.store.write;
    const ct = w.currentText;
    const cp = ct.currentChapter;
    if (__CLIENT__
      && (cp.textContent && !cp.textContentState || (ct.id !== this.props.params.textId)
        || (cp.id !== this.props.params.chapterId))) {
      this.initChapter();
    }
  }
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }

    const currentText = this.props.store.write.currentText;
    const chapter = currentText.currentChapter;
    if (chapter && chapter.status !== 'PUBLISHED') {
      this.saveWithoutMessage();
    }
    window.removeEventListener('beforeunload', this.blockRefreshByMistake);
  }
  blockRefreshByMistake(event: any) {
    event.returnValue = 'Attention !';
  }
  initChapter() {
    const w = this.props.store.write;
    const ct = w.currentText;
    const cp = ct.currentChapter;
    if (__CLIENT__
      && (cp.textContent && !cp.textContentState || (ct.id !== this.props.params.textId)
        || (cp.id !== this.props.params.chapterId))) {

      if (cp.status === 'PUBLISHED') {
        // Load annotations
        this.props.store.loadAnnotations(cp.id, cp.id).then((r: { rows: any[] }) => {
          const annotations = r.rows;
          if (annotations.length > 0) {
            const textWithAnnotations = extractAnnotations('<div>' + cp.textContent + '</div>', annotations);
            const doc = new DOMParser().parseFromString(textWithAnnotations);
            const nodes = xpath.select('//span[contains(@class, \'annotator-hl\')]', doc);

            nodes.forEach((n: any) => {
              // Create new node 'Annotation'
              const ann = doc.createElement('annotation');
              const annContent = doc.createTextNode(n.textContent);
              ann.appendChild(annContent);
              ann.setAttribute('id', n.getAttribute('data-annotation-id'));
              n.parentNode.replaceChild(ann, n);
            });
            const finalText = new XMLSerializer().serializeToString(doc);
            cp.setTextContent(finalText);
          }
          cp.init();
          w.disableLoading();
        }, (err: any) => {
          cp.init();
          w.disableLoading();
        });
      } else {
        cp.init();
        w.disableLoading();
      }
    }
  }
  poll() {
    this.save();
  }
  startPolling() {
    const that = this;
    setTimeout(function () {
      that._timer = setInterval(that.poll.bind(self), 30000);
    }, 1000);
  }
  showStatistics() {
    this.setState({ showStatistics: true });
  }
  hideStatistics() {
    this.setState({ showStatistics: false });
  }
  save() {
    this.props.store.saveChapter(true, false);
  }
  saveWithoutMessage() {
    this.props.store.saveChapter(false, true);
  }
  showHelp() {
    this.refs.joyride.reset(true);
    this.setState({
      showHelp: true,
      tour: false
    });
  }
  startHelp() {
    this.setState({
      showHelp: false,
      tour: true
    });
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    const handlers = {
      'save': (event: any) => {
        event.preventDefault();
        this.save();
      }
    };
    const joyRideAdditionalStep = chapter.status === 'PUBLISHED' ?
      {
        title: 'Chapitre publié',
        text: '<p>Votre chapitre est actuellement publié et vous n\'avez pas de modification en cours dans l\'éditeur de texte.</p>',
        selector: '.chapter-published',
        isFixed: true,
        position: 'top'
      } : chapter.status === 'INPROGRESS' ?
        {
          title: 'Chapitre en cours',
          text: '<p>Vous pouvez mettre à jour votre chapitre déjà publié ou annuler vos modifications avec ce bouton !</p>',
          selector: '.chapter-inprogress',
          isFixed: true,
          position: 'top'
        }
        :
        {
          title: 'Publier le chapitre',
          text: '<p>Vous pouvez rendre public votre chapitre en cliquant sur ce bouton.</p><p>Nous demandons toujours une validation avant une telle action ! N\'ayez donc pas peur de cliquer dessus sans faire exprès.</p>',
          selector: '.chapter-publish',
          isFixed: true,
          position: 'top'
        };

    const Joyride = !bowser.msie && !bowser.safari ? require('react-joyride').default : undefined;

    return (
      <HotKeys keyMap={{ 'save': ['command+s', 'ctrl+s'] }} handlers={handlers}>
        {chapter && chapter.textContentState ?
          <div style={{ outline: 'none' }}>
            <StartHelp active={this.state.showHelp} startHelp={this.startHelp} />
            <MediaQuery minWidth={767}>
              {Joyride ?
                <Joyride
                  ref="joyride"
                  steps={editorSteps.concat(joyRideAdditionalStep)}
                  run={this.state.tour}
                  autoStart={this.state.tour}
                  type="continuous"
                  scrollOffset={200}
                  locale={{
                    back: 'Précédent',
                    close: 'Quitter',
                    last: 'Fin',
                    next: 'Suivant',
                    skip: 'Quitter'
                  }}
                  showSkipButton={true}
                />
                : undefined}
            </MediaQuery>
            <MediaQuery maxWidth={768}>
              {Joyride ?
                <Joyride
                  ref="joyride"
                  steps={editorMobileSteps.concat(joyRideAdditionalStep)}
                  run={this.state.tour}
                  autoStart={this.state.tour}
                  type="continuous"
                  scrollOffset={200}
                  locale={{
                    back: 'Précédent',
                    close: 'Quitter',
                    last: 'Fin',
                    next: 'Suivant',
                    skip: 'Quitter'
                  }}
                  showSkipButton={true}
                />
                : undefined}
              </MediaQuery>
            <div>
              <FullEditor />
            </div>
            {!chapter.relectureMode ?
              <ChapterToolbar
                showStatistics={this.showStatistics}
                showHelp={this.showHelp}
                location={this.props.location}
                params={this.props.params}
              />
              /* : (chapter.relectureStep === RelectureType.Spellcheck ?
                <SpellcheckBar /> */
              : undefined
              /* ) */
              }
          </div>
          : <Loader message="Chargement..." />
        }
      </HotKeys>
    );
  }
}
