import { observable } from 'mobx';

export default class ParcoursProductPartModel {

  @observable parcoursId: number;
  @observable parcoursName: string;
  @observable parcoursDescription: string;
  @observable parcoursShortImage: string;
  @observable parcoursVideoUrl: string;
  @observable mainProductId: number;
  @observable partId: number;
  @observable partName: string;
  @observable partDescription: string;
  @observable partIndex: number;
  @observable parcoursPartType: number;


  constructor(parcoursId: number, parcoursName: string, parcoursDescription: string, parcoursShortImage: string, parcoursVideoUrl: string,
    mainProductId: number, partId: number, partName: string, partDescription: string,
    partIndex: number, parcoursPartType: number) {
      this.parcoursId = parcoursId
      this.parcoursName = parcoursName
      this.parcoursDescription = parcoursDescription
      this.parcoursShortImage = parcoursShortImage
      this.parcoursVideoUrl = parcoursVideoUrl
      this.mainProductId = mainProductId
      this.partId = partId
      this.partName = partName
      this.partDescription = partDescription
      this.partIndex = partIndex
      this.parcoursPartType = parcoursPartType
    }
  toJS() {
    return {
      parcoursId: this.parcoursId,
      parcoursName: this.parcoursName,
      parcoursDescription: this.parcoursDescription,
      parcoursShortImage: this.parcoursShortImage,
      parcoursVideoUrl : this.parcoursVideoUrl,
      mainProductId : this.mainProductId,
      partId: this.partId,
      partName: this.partName,
      partDescription: this.partDescription,
      partIndex: this.partIndex,
      parcoursPartType: this.parcoursPartType
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursProductPartModel(
        object.parcoursId,
        object.parcoursName,
        object.parcoursDescription,
        object.parcoursShortImage,
        object.parcoursVideoUrl,
        object.mainProductId,
        object.partId,
        object.partName,
        object.partDescription,
        object.partIndex,
        object.parcoursPartType
        );
    }
    return undefined;
  }
}
