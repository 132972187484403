import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import TextParams from './TextParams';
import SensibleContentModal from './SensibleContentModal';

interface TextPageProps {
  store: Store;
  params: TextParams;
  textbar: any;
  main: any;
  location: { pathname: string };
}

@inject('store') @observer
export default class TextPage extends React.Component<TextPageProps, {}> {
  static fetchData(store: Store, params: TextParams) {
    return store.loadMeta(params.textId);
  }
  chapterPage() {
    const reg = /(^\/text.*\/chapter\/)/g;
    return reg.test(this.props.location.pathname);
  }
  render() {
    const text = this.props.store.text;

    const title = this.chapterPage() && text && text.meta && text.chapter ?
    `${text.meta.title} - ${text.chapter.title}`
    : (text && text.meta ? text.meta.title : '');
    
    const metaProperties = text && text.meta ?
      [
        {
          name: 'description',
          content: text.meta.summary ? text.meta.summary : `Une œuvre de ${text.meta.author.identity}`
        },
        {
          name: 'twitter:card', content: text.meta.cover ? 'summary_large_image' : 'summary'
        },
        { name: 'twitter:site', content: '@atelier_auteurs' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:creator', content: text.meta.author.twitter ? text.meta.author.twitter : '' },
        {
          name: 'twitter:description',
          content: text.meta.summary ? text.meta.summary : `Une œuvre de ${text.meta.author.identity}`
        },
        {
          name: 'twitter:image',
          content: text.meta.cover ? text.meta.cover : text.meta.author.avatarUrl
        },
        { property: 'fb:app_id', content: '460911020714321' },
        { property: 'og:type', content: 'article' },
        { property: 'og:title', content: title },
        { property: 'og:image', content: text.meta.cover ? text.meta.cover : text.meta.author.avatarUrl },
        {
          property: 'og:description',
          content: text.meta.summary ? text.meta.summary : `Une œuvre de ${text.meta.author.identity}`
        },
        { property: 'og:site_name', content: 'Atelier des auteurs' },
        { property: 'article:publisher', content: 'https://www.facebook.com/atelierdesauteurs' },
      ]
      : [];

    return (
      <div>
        {text && text.meta ?
          <div>
            <Title
              title={title}
              meta={metaProperties}
              scripts={text.infos ? [{
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                  "@context": "http://schema.org",
                  '@type': "Article",
                  "headline": title,
                  "author": {
                    "@type": "Person",
                    "name": text.meta.author.identity
                  },
                  "image": {
                    "@type": "ImageObject",
                    "url": text.meta.cover ? text.meta.cover : '',
                    "height": 1300,
                    "width": 800
                  },
                  'description': text.meta.summary ? text.meta.summary : `Une œuvre de ${text.meta.author.identity}`,
                  "datePublished": text.infos.datePublication,
                  "dateModified": text.infos.datePublication,
                  "publisher": {
                    "@type": "Organization",
                    name: "Atelier des auteurs",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "https://scribay.s3.amazonaws.com/assets/logo_square.png"
                    }
                  },
                  "mainEntityOfPage": `https://www.atelierdesauteurs.com/text/${text.meta.id}/${text.meta.urlText}`
                })
              }] : []}
            />
            {this.props.textbar}
            {this.props.main}
            <SensibleContentModal />
          </div>
          : undefined}
      </div>
    );
  }
}
