import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import * as bowser from 'bowser';
import VolatileLogin from '../auth/VolatileLogin';
import Store from '../../stores/Store';

interface NotConnectedBandeauProps {
  store?: Store;
  title: string;
  body: string;
}

interface NotConnectedBandeauState {
  username: string;
  usernameError: boolean;
  email: string;
  emailError: boolean;
  password: string;
  passwordError: boolean;
  usernameOrPasswordExistsError: boolean;
  invalidEmail: boolean;
  unknownError: boolean;
  loading: boolean;
}

@inject('store') @observer
export default class NotConnectedBandeau extends React.Component<NotConnectedBandeauProps, NotConnectedBandeauState> {
  render() {
    return (
      <div>
        {!this.props.store.isAuthenticated() ?
          <div className="not-connected-bandeau">
            <div className="hide-mobile ui grid">
              <div className="ten wide center aligned column">
                <div className="ui basic segment">
                  <h1>{this.props.title}</h1>
                  <p style={{ fontSize: '24px' }} dangerouslySetInnerHTML={{ __html: this.props.body }} />
                </div>
              </div>
              <div className="six wide column">
                {(bowser.msie && Number(bowser.version) < 11) || (bowser.safari && Number(bowser.version) < 9) ?
                  <div className="ui warning message">
                    <div className="header">Oh non, votre navigateur n'est pas compatible !</div>
                    <p>L'Atelier des auteurs repose sur des technologies modernes afin de proposer une expérience riche et fluide.</p>
                    <p>Nous vous invitons à utiliser une version récente de <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/fr/firefox/new/">Mozilla Firefox</a> ou <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">Google Chrome</a>.</p>
                  </div>
                  : <VolatileLogin />
                }
              </div>
            </div>
            <div className="hide-large" style={{ textAlign: 'center' }}>
              <Link to="/signup" style={{ color: '#f0ad4e', fontWeight: 'bold' }}>Inscrivez-vous</Link> pour profiter pleinement de l'Atelier des auteurs !
            </div>
          </div>
          : undefined}
      </div>
    );
  }
}
