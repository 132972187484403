import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { scroller } from 'react-scroll';
import Store from '../../stores/Store';
import ChapterParams from './ChapterParams';
import ChapterSection from './ChapterSection';
import SensibleContentModal from './SensibleContentModal';
import NotConnectedBandeau from '../misc/NotConnectedBandeau';

declare const __CLIENT__: any;

interface ChapterPageQuery {
  version: number;
}

interface ChapterPageProps {
  store: Store;
  location: { query: ChapterPageQuery };
  params: ChapterParams;
}

@inject('store') @observer
export default class ChapterPage extends React.Component<ChapterPageProps, {}> {
  static fetchData(store: Store, params: ChapterParams, query: ChapterPageQuery) {
    const p: any[] = [];
    if (query.version) {
      p.push(store.loadChapter(params.textId, params.chapterId, query.version));
    } else {
      p.push(store.loadChapter(params.textId, params.chapterId));
    }
    p.push(store.loadMeta(params.textId));
    /* p.push(store.loadRecommandations(params.textId)); */
    return Promise.all(p);
  }
  componentDidMount() {
    if (__CLIENT__) {
      const url = window.location.href;
      const regexp = /#comment_(.*)/;
      const match = regexp.exec(url);

      if (match && match.length > 1) {
        this.props.store.loadComments(this.props.params.textId, this.props.params.chapterId).then(() => {
          scroller.scrollTo(`comment_${match[1]}`, {
            offset: -100,
            duration: 500,
            delay: 100,
            smooth: true
          });
        });
      }
    }
  }
  render() {
    return (
      <div>
        {this.props.store.text.meta ?
          <div>
            <ChapterSection />
            <SensibleContentModal />
            <NotConnectedBandeau
              title={`Vous aimez lire ${this.props.store.text.meta.author.identity} ?`}
              body="Commentez et annotez ses textes en vous inscrivant à l'Atelier des auteurs !<br>Sur l'Atelier des auteurs, un auteur n'est jamais seul : vous pouvez suivre ses avancées, soutenir ses efforts et l'aider à progresser."
            />
          </div>
          : undefined}
      </div>
    );
  }
}
