import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import ProductAuthorModel from '../../models/product/ProductAuthorModel';



function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
    className="fa fa-angle-left hide-tablet ada-carousel-left"
      style={{ ...style, marginLeft: "-15px" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="fa fa-angle-right hide-tablet ada-carousel-right"
      style={{ ...style, marginRight: "-15px" }}
      onClick={onClick}
    />
  );
}



interface ResponsiveAuthorsProps {
  productAuthors: ProductAuthorModel[];
  showAuthorModal: Function | any;
}

export default class ResponsiveAuthors extends React.Component<ResponsiveAuthorsProps, {}> {
  render() {
    const productAuthorsLength = this.props.productAuthors.length;
    const sliderLength = productAuthorsLength > 4 ? 4 : productAuthorsLength;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: sliderLength,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    var settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const sliders = this.props.productAuthors ? this.props.productAuthors.map((productAuthor, index) => {
      return (
        <div className="ui column">
          {productAuthorsLength < 4 ? 
          <img style={{maxHeight: "460px", display: "inline-block"}} key={'carousel-'+index} className="ui image ada-carousel-bio" src={productAuthor.url}
            alt={productAuthor.name} onClick={() => this.props.showAuthorModal(productAuthor.name, productAuthor.bio)}
          />
          :
          <img key={'carousel-'+index} className="ada-carousel-bio" src={productAuthor.url}
            alt={productAuthor.name} width="100%" onClick={() => this.props.showAuthorModal(productAuthor.name, productAuthor.bio)} 
          />
          }
        </div>
      )
    })
    : undefined;

    return (
      <div style={{marginTop: "30px", marginBottom: "45px"}}>
        {productAuthorsLength < 4 ? 
        <div className="ui equal width center stackable grid">
          {sliders} 
          </div> 
        :
        <span>
          <span className="hide-tablet">
            <Slider {...settings}>
              {sliders}
            </Slider>
          </span>
          <span className="hide-tablet-large">
            <Slider {...settings2}>
              {sliders}
            </Slider>
          </span>
        </span>
        }
      </div>
    );
  }
}
