import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';

interface ForgotPasswordProps {
  store?: Store;
}

interface ForgotPasswordState {
  email: string;
  emailError: boolean;
  sent: boolean;
}

@inject('store') @observer
export default class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      sent: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  handleEmailChange(e: any) {
    const emailError = this.state.emailError && e.target.value.trim().length === 0;
    this.setState({
      email: e.target.value,
      emailError: emailError,
      sent: false
    });
  }
  postForm(event: any) {
    event.preventDefault();
    const emailError = this.state.email.trim().length === 0;

    if (!emailError) {
      this.props.store.forgotPassword(this.state.email).then((res: any) => {
        this.setState({
          email: this.state.email,
          emailError: false,
          sent: true
        });
      });
    } else {
      this.setState({
        email: this.state.email,
        emailError: true,
        sent: false
      });
    }
  }
  render() {
    return (
      <div className="ui text container">
        <Title title="Identifiants oubliés" />
        <h1 className="ui header">Mot de passe ou identifiant oublié</h1>
        {this.state.sent ?
          <div className="ui basic segment">
            <p>
              Un email vous a été envoyé à <i>{this.state.email}</i>.
              <br />Vous devriez le recevoir d'ici quelques instants.
            </p>
            <p>
              Si vous ne voyez pas d'email dans votre boîte de réception,
              consultez votre boîte Spam / Courrier indésirable.
              <br />Et si vous n'en voyez toujours pas, contactez-nous par email à l'adresse
              &nbsp;<b>contact@atelierdesauteurs.com</b>. Nous ferons en sorte de traiter au plus vite votre problème !
            </p>
            <Link className="ui right floated button" to="/login">
              Retour
            </Link>
          </div>
          :
          <div className="ui basic segment">
            <p>
              Veuillez entrer l'adresse email associée à votre compte.
            <br />Vous recevrez un email contenant :
            </p>
            <ul>
              <li>Un lien pour réinitialiser votre mot de passe, si vous souhaitez le changer.</li>
              <li>Un rappel de votre identifiant de connexion.</li>
            </ul>
            <form className="ui form" onSubmit={this.postForm}>
              <div className={'field' + (this.state.emailError ? ' error' : '')}>
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                  />
              </div>
              <Link className="ui basic right floated button" to="/login">
                Annuler
            </Link>
              <button className="ui submit primary right floated button" type="submit" onClick={this.postForm}>
                Envoyer l'email
            </button>
            </form>
          </div>
        }
      </div>
    );
  }
}
