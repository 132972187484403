import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface ChapterCongratulationsChapterProps {
  showCongratulationsChapter: boolean;
  startNewPart: Function | any;
}

export default class ChapterCongratulationsChapter extends React.Component<ChapterCongratulationsChapterProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.showCongratulationsChapter}
        onClose={this.props.startNewPart}
        size="small"
        >
        <div className="header">Félicitations !</div>
        <div className="content">
          Cette étape est terminée. Place à la suite !
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.startNewPart}>Suivant</button>
        </div>
      </Modal>
    );
  }
}
