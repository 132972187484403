import * as React from 'react';

import MentionListItem from './MentionListItem';

interface MentionListProps {
  isAutocomplete: boolean;
  selectedIdx: number;
  word: string;
  data: { value: string, label: string }[];
  insertFn: Function;
}

export default class MentionList extends React.Component<MentionListProps, {}> {
  render() {
    const classes = !this.props.isAutocomplete
      ? 'mention__list hidden'
      : 'mention__list';

    const that = this;
    const items = this.props.data.map((item, idx) => {
      const focus = that.props.selectedIdx === idx;

      return (<MentionListItem
        key={idx}
        focus={focus}
        word={that.props.word}
        itemId={item.value}
        itemIdentity={item.label}
        callback={that.props.insertFn}
      />);
    }, this);
    return (
      <ul className={classes}>
        {items}
      </ul>
    );
  }
}
