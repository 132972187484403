import { observable } from 'mobx';
import FeedModel from './FeedModel';
import CommentModel from '../comment/CommentModel';
import CommentListModel from '../comment/CommentListModel';

export default class FeedListModel {

  // Feed
  @observable elements: FeedModel[];
  @observable empty: boolean;
  @observable beforeDate: string;

  constructor(elements: FeedModel[], empty: boolean, beforeDate: string) {
    this.elements = elements.map(e => new FeedModel(e.newsfeed, e.diary)) || [];
    this.empty = empty;
    this.beforeDate = beforeDate;
  }

  addDiary(diary: CommentModel) {
    const commentList = new CommentListModel(
      [diary], 'diary', undefined, undefined
    );

    const elements = this.elements.slice();

    elements.unshift(new FeedModel(undefined, commentList));

    this.elements = elements;
  }

  toJS() {
    return {
      elements: this.elements.map(f => f.toJS()),
      empty: this.empty,
      beforeDate: this.beforeDate
    };
  }

  static fromJS(object: any) {
    if (object) {
      const elements = object.elements.map((e: any) => FeedModel.fromJS(e));
      return new FeedListModel(elements, object.empty, object.beforeDate);
    }
    return undefined;
  }
}
