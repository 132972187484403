import * as React from 'react';

interface CommentEditProps {
  handleInitEdit: Function;
}

interface CommentEditState {
  open: boolean;
}

export default class CommentEdit extends React.Component<CommentEditProps, CommentEditState> {
  constructor(props: CommentEditProps) {
    super(props);
    this.state = { open: false };
    this.handleInitEdit = this.handleInitEdit.bind(this);
  }
  handleInitEdit() {
    this.props.handleInitEdit();
  }
  render() {
    return (
      <a onClick={this.handleInitEdit}>
        Modifier
      </a>
    );
  }
}
