import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import ConnectedTopBarAda from './ConnectedTopBarAda';
import NotConnectedTopBarAda from './NotConnectedTopBarAda';
import AcademyTopBarAda from './AcademyTopBarAda';
import { browserHistory, Link } from 'react-router';

interface DefaultTopBarProps {
  store: Store;
  location: { pathname: string };
}

@inject('store') @observer
export default class DefaultTopBar extends React.Component<DefaultTopBarProps, {}> {
  static fetchData(store: Store) {
    if (store.isAuthenticated()) {
      return store.loadConnected();
    }
  }
  constructor(props: DefaultTopBarProps) {
    super(props);
    this.academiePage = this.academiePage.bind(this);
    this.buyParcours = this.buyParcours.bind(this);
    this.signUpAndBuyParcours = this.signUpAndBuyParcours.bind(this);
    this.goToHub = this.goToHub.bind(this);
  }
  buyParcours() {
    const currentProduct = this.props.store.currentProduct;
    this.props.store.buyParcours(currentProduct);
    browserHistory.push({
      pathname: '/paymenthub',
      search: '?p='+currentProduct.id
    });
  }
  signUpAndBuyParcours() {
    const currentProduct = this.props.store.currentProduct;
    this.props.store.setRegistering(currentProduct)
    browserHistory.push({
      pathname: '/signup',
      state: {p: currentProduct.id}
    });
  }
  goToHub() {
    if (!this.props.store.connected.premium.premiumUI )
      this.props.store.switchUI().then((p: any) => {
        browserHistory.push({
          pathname: '/'
        });
      });
    else
      browserHistory.push({
        pathname: '/'
      });
  }
  academiePage() {
    return this.props.location.pathname === '/ecole';
  }
  atelierPage() {
    let currentProduct = this.props.store.currentProduct;
    return currentProduct.id !== undefined && currentProduct.stripeIdentifier && currentProduct.marketIdentifier != null;
  }
  render() {
    const connected = this.props.store.connected;
    const isConnected = this.props.store.isAuthenticated() && connected && connected.user && connected.user.id;
    const premium = connected ? connected.premium : undefined;
    const currentProduct = this.props.store.currentProduct;
    const posessed = premium && currentProduct && ((premium.marketIdentifier & currentProduct.marketIdentifier) != 0 || premium.allInclusive)
    const buyFunction = isConnected ? posessed ? this.goToHub : this.buyParcours : this.signUpAndBuyParcours;
    const buyParcoursText = "Acheter "+(currentProduct.marketIdentifier != 0 ? "cet atelier" : "l'Universel");
    return isConnected ? 
    premium && premium.premium && premium.premiumUI ? 
    <AcademyTopBarAda atelier={this.atelierPage()} location={this.props.location} buyParcours={buyFunction}  posessed={posessed} 
      buyParcoursText={buyParcoursText}/> : 
    <ConnectedTopBarAda atelier={this.atelierPage()} location={this.props.location} buyParcours={buyFunction}  posessed={posessed} 
      buyParcoursText={buyParcoursText}/> : 
    <NotConnectedTopBarAda atelier={this.atelierPage()} isConnected={isConnected} posessed={posessed}
      buyParcours={buyFunction} buyParcoursText={buyParcoursText}/>;
  }
}
