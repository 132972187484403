import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../stores/Store';

interface TransformToChapterProps {
  store?: Store;
  active: boolean;
  otherTexts: { value: string, text: string }[];
  hide: Function | any;
  transformToChapter: Function;
}

interface TransformToChapterState {
  selectedText: string;
}

@inject('store') @observer
export default class TransformToChapter extends React.Component<TransformToChapterProps, TransformToChapterState> {
  constructor(props: TransformToChapterProps) {
    super(props);
    this.transformToChapter = this.transformToChapter.bind(this);
    this.state = { selectedText: undefined };
  }
  handleChangeText(selectedText: string) {
    this.setState({
      selectedText: selectedText
    });
  }
  transformToChapter() {
    this.props.transformToChapter(this.state.selectedText);
  }
  render() {
    return (
      <Modal onClose={this.props.hide} open={this.props.active}>
        <div className="header">
          Transformer en chapitre
          </div>
        <div className="content">
          <div className="ui message">
            Cette œuvre n'est formée que d'un seul chapitre. Vous avez la possibilité de la transformer en un
              chapitre d'une autre de vos œuvres.
              <br />Les commentaires, statistiques de vues et "j'aime" seront transférés vers l'œuvre cible.
            </div>
          <form className="ui form" onSubmit={this.transformToChapter}>
            <div className="field" style={{ maxWidth: '600px' }}>
              <label>Cette œuvre sera un nouveau chapitre de l'œuvre :</label>
              <Dropdown
                selection
                value={this.state.selectedText}
                onChange={(e: any, { value }) => this.handleChangeText(String(value))}
                options={this.props.otherTexts}
              />
            </div>
          </form>
        </div>
        <div className="actions">
          {this.state.selectedText ?
            <button className="ui primary submit button" onClick={this.transformToChapter}>
              Transformer en chapitre
            </button>
            : undefined}
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
