import { observable } from 'mobx';

export default class ChapterVersionModel {

    @observable id: number;
    @observable date: string;

    constructor(id: number, date: string) {
      this.id = id;
      this.date = date;
    }

    toJS() {
      return {
        id: this.id,
        date: this.date
      };
    }

    static fromJS(object: any) {
      if (object) {
        return new ChapterVersionModel(object.id, object.date);
      }
      return undefined;
    }
}
