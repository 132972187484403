import UserModel from '../UserModel';

export default class LightDiscussionModel {

  id: number;
  read: boolean;
  targetUsers: UserModel[];
  lastMessageDate: string;

  constructor(id: number, read: boolean, targetUsers: UserModel[], lastMessageDate: string) {
    this.id = id;
    this.read = read;
    this.targetUsers = targetUsers;
    this.lastMessageDate = lastMessageDate;
  }

  toJS() {
    return {
      id: this.id,
      read: this.read,
      targetUsers: this.targetUsers.map(u => u.toJS()),
      lastMessageDate: this.lastMessageDate,
    };
  }

  static fromJS(object: any) {
    if (object) {
      const targetUsers = object.targetUsers.map((u: any) => UserModel.fromJS(u));
      return new LightDiscussionModel(object.id, object.read,
        targetUsers, object.lastMessageDate);
    }
    return undefined;
  }
}
