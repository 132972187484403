import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Store from '../../../../../stores/Store';
import ChapterVideo from './ChapterVideo';
import VideoModel from '../../../../../models/write/VideoModel';

interface Peripetie {
  title: string;
  perturb: string;
  action: string;
  resol: string;
}

interface ChapterGeneratorsProps {
  store?: Store;
  comp?: string;
  peripetie: Peripetie;
  active: boolean;
  hide: Function | any;
  generatePeripetie: Function | any;
}

@inject('store') @observer
export default class ChapterGenerators extends React.Component<ChapterGeneratorsProps, {}> {
  render() {
    const comp = this.props.comp;
    const peripetie = this.props.peripetie;
    return (
      <div>
        <Modal onClose={this.props.hide} open={this.props.active} size="small">
          <div className="content">
            {peripetie ?
              <div>
                <div className="ui info message">
                  <div className="ui header">Inspirez-vous à l'aide du générateur de péripéties !</div>
                  {comp ?
                    <div>
                      {comp === 'perturb' ?
                        <p>Ce chapitre est un chapitre de perturbation.</p>
                        : (comp === 'action' ?
                          <p>Ce chapitre est un chapitre d'action.</p>
                          : (comp === 'resol' ?
                            <p>Ce chapitre est un chapitre de résolution.</p>
                            : undefined))}
                    </div>
                    : undefined}
                </div>
                <div className="ui segment">
                  <div className="ui small header" dangerouslySetInnerHTML={{ __html: peripetie.title }} />
                  <div className="ui items">
                    <div className="item" style={comp && comp !== 'perturb' ? { opacity: 0.4 } : {}}>
                      <div className="content">
                        <div className="ui small header">Chapitre de perturbation</div>
                        <div dangerouslySetInnerHTML={{ __html: peripetie.perturb }} />
                      </div>
                    </div>
                    <div className="item" style={comp && comp !== 'action' ? { opacity: 0.4 } : {}}>
                      <div className="content">
                        <div className="ui small header">Chapitre d'action</div>
                        <div dangerouslySetInnerHTML={{ __html: peripetie.action }} />
                      </div>
                    </div>
                    <div className="item" style={comp && comp !== 'resol' ? { opacity: 0.4 } : {}}>
                      <div className="content">
                        <div className="ui small header">Chapitre de résolution</div>
                        <div dangerouslySetInnerHTML={{ __html: peripetie.resol }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : undefined
            }
          </div>
          <div className="actions">
            <button className="ui primary button" onClick={this.props.generatePeripetie}>Générer une autre péripétie</button>
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
        </Modal>
      </div >
    );
  }
}

