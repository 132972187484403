import * as React from 'react';
import Select from 'react-select'
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { Link, browserHistory } from 'react-router';
const StepZilla = require('react-stepzilla').default;
const ReactPlayer = require('react-player');
import ForceTrainingModal from './ForceTrainingModal';
import NewTrainingModal from '../../../write/choose/NewTrainingModal';
import Store from '../../../../stores/Store';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'
import ParcoursPartTypeEnum from '../../../../models/parcours/ParcoursPartTypeEnum';
import TextStatisticsButton from '../toolbar/TextStatisticsButton';
import CharactersList from '../characters/CharactersList';
import NotePad from '../toolbar/NotePad';
import TextTitleButton from '../toolbar/TextTitleButton';
import ParcoursEndingModal from './ParcoursEndingModal';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';



interface ParcoursStatusProps {
  store?: Store;
}

class DefaultStep extends React.Component<{}, {}> {
  render() {
    return (
      <div></div>
    );
  }
}

interface ParcoursChapter {
  value : number;
  label: string;
  index: number;
}

interface ParcoursStatusState {
  activeForceTraining: boolean;
  activeNewTraining: boolean;
  editVideo: number;
  selectedChapter: ParcoursChapter;
  selectedChapterTitle: string;
  selectedChapterInstructions: string;
  showCharacters:boolean;
  showNotePad:boolean;
  showMeta:boolean;
  showParcoursEnding:boolean;
  categoryIndex: number;
  loading: boolean;
  openedToPublishers: boolean;
}

@inject('store') @observer
export default class ParcoursStatus extends React.Component<ParcoursStatusProps, ParcoursStatusState> {
  constructor(props: ParcoursStatusProps) {
    super(props);
    this.state = {
      activeForceTraining: false,
      activeNewTraining: false,
      editVideo: Math.random() * 1000,
      selectedChapter: {
        index: 0,
        value: this.props.store.mainParcours.status.chapterId, 
        label: this.props.store.mainParcours.status.partName
      },
      selectedChapterTitle: this.props.store.mainParcours.status.chapterTitle,
      selectedChapterInstructions: this.props.store.mainParcours.status.chapterInstructions,
      showCharacters: false,
      showNotePad: false,
      showMeta: false,
      showParcoursEnding: false,
      categoryIndex: 0,
      loading: false,
      openedToPublishers: false
    };
  
    this.showForceTraining = this.showForceTraining.bind(this);
    this.showNewTraining = this.showNewTraining.bind(this);
    this.hideNewTraining = this.hideNewTraining.bind(this);
    this.showCharacters = this.showCharacters.bind(this);
    this.hideCharacters = this.hideCharacters.bind(this);
    this.showMeta = this.showMeta.bind(this);
    this.hideMeta = this.hideMeta.bind(this);
    this.showNotes = this.showNotes.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.hideNotes = this.hideNotes.bind(this);
    this.download = this.download.bind(this);
    this.showParcoursEndingModal = this.showParcoursEndingModal.bind(this);
    this.hideParcoursEndingModal = this.hideParcoursEndingModal.bind(this);
    this.changeVisibleVideo = this.changeVisibleVideo.bind(this);
    this.handleChangeChapter = this.handleChangeChapter.bind(this);
    this.nextChapter = this.nextChapter.bind(this);
    this.handleOpenedToPublishersChange = this.handleOpenedToPublishersChange.bind(this);
    this.saveOpenedToPublishersEdit = this.saveOpenedToPublishersEdit.bind(this);
  }
  handleOpenedToPublishersChange() {
    this.setState({
      openedToPublishers: !this.state.openedToPublishers
    });
  }
  saveOpenedToPublishersEdit(e: any) {
    e.preventDefault();
    this.props.store.saveParcoursTextOpenedToPublishers(this.state.openedToPublishers).then((e: any) => {
      this.hideMeta();
    });
  }
  showCharacters() {
    const currentTextId = this.props.store.mainParcours.status.textId;
    const chapterIndex = this.props.store.mainParcours.status.chapterId;
    this.props.store.loadCharacters(currentTextId, chapterIndex).then((d: any) => {
      this.setState({
        showCharacters: true
      });
    });
  }
  hideCharacters() {
    this.setState({
      showCharacters: false
    });
  }
  showNotes() {
    const currentTextId = this.props.store.mainParcours.status.textId;
    this.props.store.loadNotes(currentTextId).then((d: any) => {
      this.setState({
        showNotePad: true
      });
    });
  }
  hideNotes() {
    this.setState({
      showNotePad: false
    });
  }
  showMeta() {
    const currentTextId = this.props.store.mainParcours.status.textId;
    this.props.store.loadMeta(currentTextId).then((d: any) => {
      this.setState({
        showMeta: true,
        openedToPublishers: this.props.store.text && this.props.store.text.meta ? this.props.store.text.meta.openedToPublishers : false
      });
    });
  }
  hideMeta() {
    this.setState({
      showMeta: false
    });
  }
  downloadPdf(url: string) {
    this.props.store.downloadPdf(url);
  }
  showParcoursEndingModal() {
    this.setState({
      showParcoursEnding: true
    });
  }
  hideParcoursEndingModal() {
    this.setState({
      showParcoursEnding: false
    });
  }
  showForceTraining() {
    this.setState({
      activeForceTraining: true
    });
  }
  download() {
    const mainParcours = this.props.store.mainParcours;
    const status = mainParcours.status;
    const url = `/download/text/${status.textId}/unfinished/${encodeURIComponent(status.textTitle)}.pdf`;
    window.location.href = url;
  }
  showNewTraining() {
    const mainParcours = this.props.store.mainParcours;
    const status = mainParcours.status;
    this.props.store.generateTrainingInstructions(false, false, { id: status.textId, url: status.textUrl, chapterId: status.chapterId })
      .then((d: any) => {
        this.setState({
          activeForceTraining: false,
          activeNewTraining: true
        });
      });
  }
  hideNewTraining() {
    this.setState({
      activeNewTraining: false
    });
  }
  @action
  changeVisibleVideo(videoId: number) {
    if (this.props.store.mainParcours.previousStatuses[this.state.selectedChapter.index].videos.filter(v => v.id === videoId)[0] &&
      this.props.store.mainParcours.previousStatuses[this.state.selectedChapter.index].videos.filter(v => v.id === videoId)[0].visible !== true) {
      this.props.store.mainParcours.previousStatuses[this.state.selectedChapter.index].videos.filter(v => v.id === videoId)[0].visible = true;
      this.props.store.mainParcours.previousStatuses[this.state.selectedChapter.index].videos.filter(v => v.id !== videoId).map(v => v.visible = false);
      this.setState({
        editVideo: Math.random() * 1000
      });
    }
  }
  @action
  changeVisibleVideoCategory(index: number) {
    this.setState({
      categoryIndex: index
    });
  }

  componentWillUnmount() {
    this.props.store.clearCurrentParcoursText();
  }

  handleChangeChapter = (selectedChapter: ParcoursChapter) => {
    this.setState({ 
      selectedChapter: selectedChapter,
      selectedChapterTitle: this.props.store.mainParcours.previousStatuses[selectedChapter.index].chapterTitle,
      selectedChapterInstructions: this.props.store.mainParcours.previousStatuses[selectedChapter.index].chapterInstructions
     });
  };

  nextChapter() {
    this.setState({
      loading: true
    });
    this.props.store.saveChapterFromHub(false, false).then((d: any) => {
      this.props.store.nextChapterFromHub([]).then((v: any) => {
        this.setState({
          activeForceTraining: false,
          activeNewTraining: false,
          editVideo: Math.random() * 1000,
          selectedChapter: {
            index: 0,
            value: this.props.store.mainParcours.status.chapterId, 
            label: this.props.store.mainParcours.status.partName
          },
          selectedChapterTitle: this.props.store.mainParcours.status.chapterTitle,
          selectedChapterInstructions: this.props.store.mainParcours.status.chapterInstructions,
          categoryIndex: 0,
          loading: false
        });
        if (v.status.newPart)
          this.props.store.loadCurrentParcoursText(this.props.store.mainParcours.status.textId)
      });   
    });
  };

  render() {
    const mainParcours = this.props.store.mainParcours;
    const status = mainParcours.status;
    const previousStatuses = mainParcours.previousStatuses;
    const partIndex = status.partIndex;

    /* const partSteps: { name: string, component: JSX.Element }[] = [];
    for (let i = 0; i < status.partsCount; i = i + 1) {
      partSteps.push({ name: `Partie ${i + 1}`, component: <DefaultStep /> });
    } */

  /*   const chapterSteps: { name: string, component: JSX.Element }[] = [];
    for (let i = 0; i < status.chaptersCount; i = i + 1) {
      chapterSteps.push({ name: `Chapitre ${i + 1}`, component: <DefaultStep /> });
    } */

    const chapterOptions: ParcoursChapter[] = [];
    for (let i = 0; i < previousStatuses.length; i = i + 1) {
      chapterOptions.push({index: i, value: previousStatuses[i].chapterId, label: previousStatuses[i].partName})
    }
    const selectedOption = this.state.selectedChapter;
    const chapterDropdown =
      <div>
        
        <Select
          clearable={false}
          value={selectedOption}
          onChange={this.handleChangeChapter}
          options={chapterOptions}
        ></Select>
      </div>

    const currentStatus = mainParcours.previousStatuses[selectedOption.index];
    const formatedVideos = currentStatus ? currentStatus.videos.reduce(function (r, a) {
      r[a.name] = r[a.name] || [];
      r[a.name].push(a);
      return r;
    }, Object.create(null)) : undefined;

    const videosMenu: JSX.Element[] = formatedVideos ? Object.keys(formatedVideos).map((key, index) => { 
      return (
        <div key={key} className="" onClick={() => this.changeVisibleVideoCategory(index)}>
          <a className={`${index === this.state.categoryIndex ? "item active" : "item"}`}>
            <i className={`${index === this.state.categoryIndex ? "fa fa-angle-right" : "fa fa-angle-down"}`}></i>
            <span style={{marginLeft: '5px'}}>{key}</span>
          </a>
          <div className="menu">
            {index === this.state.categoryIndex ? formatedVideos[key].map((v: any) => {
              return(
                <div key={`video_${v.id}`} style={v.visible ? {backgroundColor: "rgba(0,0,0,.05)"} : {}}>
                  <a className={`${v.visible ? "item active" : "item"}`}onClick={() => this.changeVisibleVideo(v.id)}
                  data-tooltip={v.visible ? 'Vidéo affichée actuellement' : 'Afficher la vidéo'}>
                    {v.videoName}
                  </a>
                </div>
              )
            })  : undefined}
          </div>
        </div>
      )
    })

    : undefined ;

    const videos: JSX.Element[] = currentStatus && currentStatus.videos ? currentStatus.videos.map((v) => {
      return (
        <div key={`video_${v.id}_${this.state.editVideo}`}>
          {v.visible === true ?
            <div className="item">
              <div className="content">
                
                  <div className="description">
                    <ReactPlayer
                      url={v.url}
                      width="100%"
                    />
                  </div>
              </div>
            </div>
          : undefined}
        </div>
      );
    }) : undefined;

    const pdf: JSX.Element[] = currentStatus ? currentStatus.pdf.map((p) => {
      return (
        <button
          key={`pdf_${p.id}`}
          onClick={() => this.downloadPdf(p.url)}
          className="ui item basic button text-notes"
        >
          {p.name}
        </button>  
        
      );
    }) : undefined;

    return (
      <div>
        <div className="ui grid container" style={{marginTop: "30px"}}>
              <div style={{textAlign: "right", paddingRight: "0px"}} className="two wide column content">
                <Popup
                  trigger={<i className="fa fa-lg fa-question-circle" />}
                  position="top center"
                  size="large"
                >
                    <strong>Ce menu déroulant vous permettra de naviguer dans toutes les étapes et dans votre texte !</strong>
                </Popup>
              </div>
              <div className="twelve wide column content">
                {chapterDropdown}
              </div>
            </div>
        <div style={{paddingBottom:"15px"}} className="ui container centered stackable">
          <div className="ui basic centered grid segment">
            <div className="twelve wide column">
              {videos && videos.length > 0 ?
                <div className="ui two stackable column grid row" style={{boxShadow: "0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5"}}>
                    <div className="ui five wide column vertical text menu">{videosMenu}</div>
                    <div className="ui eleven wide column" style={{ fontSize: '0.8em' }}>{videos}</div>
                  </div>
              : undefined}
            </div>
          </div>
        </div>
        {/* <ForceTrainingModal
          active={this.state.activeForceTraining}
          acceptTraining={this.showNewTraining}
          text={{ id: status.textId, url: status.textUrl, chapterId: status.chapterId }}
        />
        <NewTrainingModal active={this.state.activeNewTraining} hide={this.hideNewTraining} forceTraining
          text={{ id: status.textId, url: status.textUrl, chapterId: status.chapterId }} /> */}
            {/*<div className="ui items">
          <div className="item"> */}
            {/* <div className="ui small rounded image">
              <img src={status.parcoursImage} />
            </div> */}
          <div style={{paddingTop: "15px", paddingBottom: "30px"}} className="ui ada-background editis-beige-clair">
            <div className="ui grid container centered stackable">
              <div className="twelve wide column content">

                <div className="meta">
                  <span className="ui header meta">{this.state.selectedChapterTitle}</span>
                </div>
                <div style={{marginTop: "15px"}} className="description">
                  <div className="ui message" dangerouslySetInnerHTML={{ __html: this.state.selectedChapterInstructions }} />
                </div>
                  <div className="ui info message" style={{fontSize: "0.9em"}}>
                    <i className="close icon"></i>
                    <div className="header">
                      Quelques conseils :
                    </div>
                    {status.projectName ?
                    <p>Les étapes de votre roman peuvent être de longueur variable et être composées de plusieurs chapitres. Vous êtes libre de structurer le texte de chacune des étapes de votre récit comme vous le souhaitez !</p>
                    : undefined}
                    <p>Vous pouvez cliquer sur le bouton <i className="fa fa-cog"></i> dans le lecteur de vidéo pour changer la vitesse de lecture et sur le bouton <i className="fa fa-cc"></i> (lorsqu’il est présent) pour faire apparaître des sous-titres.</p>
                    
                  </div>
                <div className="extra" style={{ marginTop: '15px' }}>
                  {status.chapterId == selectedOption.value ? //If the selected chapter is the last one
                    status.parcoursPartType && ((status.parcoursPartType & ParcoursPartTypeEnum.FinalStep) != 0) ?//If this chapter is the final for this parcours
                      status.projectName ?
                        <div>
                          <button
                            className={'ui primary ' + (this.state.loading ? ' loading' : '') + ' button'}
                            onClick={() => this.showParcoursEndingModal()}
                            style={{ cursor: 'pointer' }}
                          >
                            Fin du parcours
                          </button>
                          <ParcoursEndingModal active={this.state.showParcoursEnding} hide={this.hideParcoursEndingModal} text={{id: status.textId, url: status.textUrl, chapterId: status.chapterId}}/>
                          </div>
                          :
                          undefined
                    : 
                      status.parcoursPartType && ((status.parcoursPartType & ParcoursPartTypeEnum.ChapterOrExercice) != 0) ?//If this chapter requires writing
                        <Link
                          to={`/text/${status.textId}/${status.textUrl}/chapter/${status.chapterId}/edit`}
                          className="ui primary button"
                        >
                          Poursuivre l'écriture !
                        </Link>
                      :
                      <button
                        className={'ui primary ' + (this.state.loading ? ' loading' : '') + ' button'}
                        onClick={() => this.nextChapter()}
                        style={{ cursor: 'pointer' }}
                      >
                        Passer à la suite
                      </button>
                  :
                    currentStatus.parcoursPartType && ((currentStatus.parcoursPartType & ParcoursPartTypeEnum.ChapterOrExercice) != 0) ?//If this chapter requires writing
                      <Link
                        to={`/text/${currentStatus.textId}/${currentStatus.textUrl}/chapter/${currentStatus.chapterId}/edit`}
                        className="ui primary button"
                      >
                        Retourner à l'écriture du chapitre {currentStatus.chapterTitle}
                      </Link>
                    :
                      undefined
                  }
                </div>
                  {/* </div>
                </div> */}
              </div>
            </div>
          </div>
        
              {/* <h4 className="ui horizontal small divider" style={{ marginTop: '40px' }}>Dans le parcours</h4>
              <div className="step-progress">
                <StepZilla
                  steps={partSteps}
                  showNavigation={false}
                  stepsNavigation={false}
                  startAtStep={partIndex - 1}
                  key={partIndex}
                />
              </div> */}
              {/* <h4 className="ui horizontal small divider" style={{ marginTop: '50px' }}>Dans la partie</h4>
              <div className="step-progress">
                <StepZilla
                  steps={chapterSteps}
                  showNavigation={false}
                  stepsNavigation={false}
                  startAtStep={chapterIndex - 1}
                  key={chapterIndex}
                />
              </div> */}
          <div className="ui container centered stackable" style={{marginBottom: "30px"}}>
          <div className="ui centered grid">
              <div className="twelve wide column">
          {pdf && pdf.length > 0 ? 
            <div>
              <h4 className="ui horizontal divider" style={{ marginTop: '30px', color: "rgb(0, 28, 54)" }}>Pdf</h4>

              {pdf}

            </div>
          :
            undefined
          }

          {status.projectName ? 
            <h4 className="ui horizontal divider" style={{ marginTop: '30px', color: "rgb(0, 28, 54)" }}>Options et notes</h4>
          :
            <h4 className="ui horizontal divider" style={{ marginTop: '30px', color: "rgb(0, 28, 54)"  }}>Notes</h4>
          }
          {status.projectName ? 
            <div className="ui centered stackable grid " style={{ marginTop: '30px' }}>
              <div className="four column row">
                <div className="column aligned">
                  <TextStatisticsButton />
                  </div>
                <div className="column aligned">
                  <button
                    onClick={this.showCharacters}
                    data-tooltip="Personnages" data-inverted="" data-position="top center"
                    className="ui item basic button chapter-characters"
                  >
                    <i className="fa fa-users" style={{ marginRight: '5px' }} />
                    Fiches de personnages
                  </button>
                </div>
                <CharactersList
                  textId={status.textId}
                  active={this.state.showCharacters}
                  hide={this.hideCharacters}
                  chapterIndex={status.chapterId}
                />
                <div className="column aligned">
                  <button
                    onClick={() => this.showNotes()}
                    className="ui item basic button text-notes"
                  >
                    <i className="fa fa-sticky-note-o" style={{ marginRight: '5px' }} />
                    Bloc-notes
                  </button>  
                </div>
                <NotePad textId={status.textId} active={this.state.showNotePad} hide={this.hideNotes} />
                <div className="column aligned">
                  <TextTitleButton title={status.textTitle} />
                </div>
              </div>
              <div className="four column row">
                <div className="column aligned">  
                  <button
                    onClick={() => this.download()}
                    className="ui item basic button text-notes"
                  >
                    <i className="fa fa-cloud-download" style={{ marginRight: '5px' }} />
                    Télécharger mon roman (PDF)
                  </button>
                </div>
              <div className="column aligned">
              <button
                onClick={this.showMeta}
                className="ui item basic button text-notes"
              >
                <i className="fa fa-star" style={{ marginRight: '5px' }} />
                Accessibilité éditeur
              </button>
              <Modal
                open={this.state.showMeta}
                size="small"
                onClose={this.hideMeta}
                >
                <div className="header">Paramètres d'accessibilité</div>
                <div className="content" >
                <div className="ui field">
                    <Checkbox
                      checked={this.state.openedToPublishers}
                      onClick={this.handleOpenedToPublishersChange}
                      label="Offrir la possibilité à un éditeur de lire cette œuvre"
                    />
                  </div>
                </div>
                <div className="actions">
                  
                    <button className="ui primary button" onClick={this.saveOpenedToPublishersEdit}>
                        Enregistrer
                    </button>
                    
                    <button className="ui cancel button" onClick={this.hideMeta}>Fermer</button>
                </div>
                </Modal>
                </div>
              </div>
            </div>
          :
          
            <div className="ui centered grid " style={{ marginTop: '30px' }}>
              <div className="one column row">
                <div className="column aligned">
                  <button
                    onClick={() => this.showNotes()}
                    className="ui item basic button text-notes"
                  >
                    <i className="fa fa-sticky-note-o" style={{ marginRight: '5px' }} />
                    Bloc-notes
                  </button>  
                </div>
                <NotePad textId={status.textId} active={this.state.showNotePad} hide={this.hideNotes} />
              </div>
            
            </div>
          }
        </div>
        </div>
        </div>

      </div>
    );
  }
}
