import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import ProfileParams from './ProfileParams';
import ProfileUserBlock from './ProfileUserBlock';
import ProfileUserSuspend from './ProfileUserSuspend';
import ProfileFormIdentity from './ProfileFormIdentity';
import ProfileFormWhere from './ProfileFormWhere';
import ProfileAvatar from './ProfileAvatar';
import ProfileColors from './ProfileColors';
import Loader from '../misc/Loader';
import NotConnectedBandeau from '../misc/NotConnectedBandeau';
import { scroller } from 'react-scroll';
import ProfileUserDelete from './ProfileUserDelete';
interface ProfileProps {
  store: Store;
  location: any;
  params: ProfileParams;
}

interface ProfileState {
  activeTab: string;
}

@inject('store') @observer
export default class Profile extends React.Component<ProfileProps, ProfileState> {
  static fetchData(store: Store, params: ProfileParams) {
    return store.loadProfileMain(params.authorId);
  }
  constructor(props: ProfileProps) {
    super(props);
    const paths = props.location.pathname.split('/');

    let activeTab: string;
    if (paths.length === 5) {
      activeTab = paths[paths.length - 1];
    } else {
      activeTab = 'author';
    }

    this.state = { activeTab };
    this.onTabChange = this.onTabChange.bind(this);
    this.follow = this.follow.bind(this);
    this.unfollow = this.unfollow.bind(this);
    this.findDiscussion = this.findDiscussion.bind(this);
  }
  componentWillReceiveProps(nextProps: ProfileProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      let activeTab: string;
      const paths = nextProps.location.pathname.split('/');
      if (paths.length === 5) {
        activeTab = paths[paths.length - 1];
      } else {
        activeTab = 'author';
      }

      this.setState({
        activeTab: activeTab
      });

      scroller.scrollTo('tab', {
        offset: -60,
        duration: 0,
        delay: 0,
        smooth: true
      });
    }
  }
  onTabChange(newTab: string) {
    if (newTab) {
      let activeTab = '';
      if (newTab !== 'author') {
        activeTab = `/${newTab}`;
      }
      this.setState({ activeTab: newTab });
      browserHistory.push({
        pathname: `/author/${this.props.params.authorId}/${this.props.params.urlIdentity}${activeTab}`
      });
    }
  }
  follow() {
    this.props.store.followUser(this.props.params.authorId).then((d: any) => {
      this.props.store.profile.user.followed = true;
    });
  }
  unfollow() {
    this.props.store.unfollowUser(this.props.params.authorId).then((d: any) => {
      this.props.store.profile.user.followed = false;
    });
  }
  findDiscussion(targetUser: number) {
    this.props.store.findDiscussion(targetUser).then((r: {
      discussionId: number,
      targetUser: number
    }) => {
      if (r.discussionId) {
        browserHistory.push({
          pathname: `/messages/discussion/${r.discussionId}`
        });
      } else {
        browserHistory.push({
          pathname: `/messages/new`,
          query: {
            id: r.targetUser
          }
        });
      }
    });
  }
  render() {
    const user = this.props.store.profile.user;
    const connected = this.props.store.connected;
    const blockedBy = user && user.id && connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(user.id) !== -1;

    const avatarUrl = user ?
      (user.avatar ?
        (user.avatar.indexOf('http') !== - 1 ?
          `https://graph.facebook.com/${user.facebookUser}/picture?width=150&height=150`
          : `https://s3-eu-west-1.amazonaws.com/scribay/avatars/${user.avatar}.jpg`
        )
        : 'https://s3-eu-west-1.amazonaws.com/scribay/assets/noavatar.png'
      )
      : '';

    let titleSuffix = '';
    switch (this.state.activeTab) {
      case 'texts':
        titleSuffix = ' - Toutes ses œuvres';
        break;
      case 'social':
        titleSuffix = ' - Abonnés et abonnements';
        break;
      /* case 'diary':
        titleSuffix = ' - Journal';
        break; */
      case 'activity':
        titleSuffix = ' - Activité';
        break;
      case 'lists':
        titleSuffix = ' - Listes de lecture';
        break;
    }

    const metaProperties = user ?
      [
        {
          name: 'description',
          content: `${user.identity} est auteur sur l'Atelier des auteurs. Découvrez ses romans, nouvelles, poésies et toutes ses autres créations littéraires !${titleSuffix}`
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@Atelier_Auteurs' },
        { name: 'twitter:title', content: user.identity },
        { name: 'twitter:creator', content: user.twitter ? user.twitter : '' },
        { name: 'twitter:description', content: `Découvrez les œuvres de ${user.identity} !` },
        { name: 'twitter:image', content: avatarUrl },
        { property: 'fb:app_id', content: '460911020714321' },
        { property: 'og:type', content: 'books.author' },
        { property: 'og:title', content: user.identity },
        { property: 'og:image', content: avatarUrl },
        { property: 'og:image:width', content: '150' },
        { property: 'og:image:height', content: '150' },
        { property: 'og:description', content: `Découvrez les œuvres de ${user.identity} !` }
      ]
      : [];


    return (
      <div style={{ backgroundColor: '#fff', minHeight: '90vh', paddingBottom: '80px', marginTop: '-35px' }}>
        {
          user ?
            <div>
              <div style={{
                backgroundColor: user.backgroundColor,
                color: user.textColor
              }}>
                <Title
                  title={`Profil de ${user.identity}${titleSuffix}`}
                  meta={metaProperties}
                />
                <NotConnectedBandeau
                  title="Suivez, soutenez et aidez vos auteurs favoris"
                  body="Inscrivez-vous à l'Atelier des auteurs et tissez des liens avec vos futurs compagnons d'écriture."
                />
                <div className="ui container items" style={{ padding: '30px 0px 30px 00px' }}>
                  <div className="ui item">
                    <ProfileAvatar />
                    <div className="content">
                      <ProfileFormIdentity />
                      <ProfileFormWhere />
                      {connected && connected.user && connected.user.id === user.id ?
                        <ProfileColors />
                        : undefined}
                      {connected && connected.user && connected.user.id !== user.id ?
                        <div className="extra">
                          {!blockedBy && !user.blocked ?
                            <span>
                              {user.followed ?
                                <button className="ui button active small" onClick={this.unfollow}>
                                  <i className="fa fa-user fa-fw"></i> Ne plus suivre l'auteur
                                </button>
                                :
                                <button className="ui button small" onClick={this.follow}>
                                  <i className="fa fa-user fa-fw"></i> Suivre l'auteur
                                </button>
                              }
                            </span>
                            : undefined}
                          <ProfileUserBlock />
                          {connected && connected.user && connected.user.id
                            && connected.user.admin && user.id !== connected.user.id ?
                              <ProfileUserSuspend />
                            : undefined}
                          {connected && connected.user && connected.user.id
                            && connected.user.admin && user.id !== connected.user.id ?
                              <ProfileUserDelete />
                            : undefined}
                          {!blockedBy && !user.blocked ?
                            <button className="ui small button" onClick={() => this.findDiscussion(user.id)}>
                              <i className="fa fa-envelope fa-fw"></i> Contacter
                            </button>
                            : undefined}
                        </div>
                        : undefined}
                    </div>
                  </div>
                </div>
                <div id="tab" className="ui sixteen wide column stackable centered grid grey"
                  style={{ marginTop: '0px', padding: '0px' }}>
                  <div
                    className="ui container"
                  >
                    <Menu
                      secondary
                      fluid
                      widths={6}
                      className="stackable"
                    >
                      <Menu.Item name="author" active={this.state.activeTab === 'author'} className="nopadding"
                        onClick={() => this.onTabChange('author')}>
                        L'Auteur
                    </Menu.Item>
                      <Menu.Item name="texts" active={this.state.activeTab === 'texts'} className="nopadding"
                        onClick={() => this.onTabChange('texts')}>
                        Œuvres
                    </Menu.Item>
                      <Menu.Item name="social" active={this.state.activeTab === 'social'} className="nopadding"
                        onClick={() => this.onTabChange('social')}>
                        Social
                    </Menu.Item>
                      {/* <Menu.Item name="diary" active={this.state.activeTab === 'diary'} className="nopadding"
                        onClick={() => this.onTabChange('diary')}>
                        Journal
                    </Menu.Item> */}
                      <Menu.Item name="activity" active={this.state.activeTab === 'activity'} className="nopadding"
                        onClick={() => this.onTabChange('activity')}>
                        Activité
                    </Menu.Item>
                      <Menu.Item name="lists" active={this.state.activeTab === 'lists'} className="nopadding"
                        onClick={() => this.onTabChange('lists')}>
                        Listes
                    </Menu.Item>
                    </Menu>
                  </div>
                </div>
              </div >
              {this.props.store.profile.loading ?
                <div className="ui container" style={{ marginTop: '20px' }}>
                  <Loader message="Chargement..." />
                </div>
                : this.props.children
              }
            </div>
            : <Loader message="Chargement..." />}
      </div >
    );
  }
}
