import { observable } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import convertFromHtml from '../../components/editor/convertFromHtml';
import convertToHtml from '../../components/editor/convertToHtml';
export default class NoteModel {

  @observable id: number;
  @observable title: string;
  @observable message: string;
  @observable messageState: any;
  @observable updatedAt: string;

  constructor(id: number, title: string, message: string, updatedAt: string) {
    this.id = id;
    this.title = title;
    this.message = message;
    let contentState = convertFromHtml(message);
    this.messageState = EditorState.createWithContent(contentState);
    this.updatedAt = updatedAt;
  }

  updateMessageState(messageState: any) {
    this.messageState = messageState;
    this.message = convertToHtml(this.messageState);
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      message: this.message,
      updatedAt: this.updatedAt
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new NoteModel(object.id, object.title, object.message, object.updatedAt);
    }
    return undefined;
  }
}
