import * as React from 'react';

interface CommentAnswersProps {
  handleShowAnswers: Function | any;
  countAnswers: number;
}

export default class CommentAnswers extends React.Component<CommentAnswersProps, {}> {
  render() {
    return (
      <a
        className="footer-comment"
        onClick={this.props.handleShowAnswers}
        style={{ fontWeight: 'bold', marginLeft: '10px' }}
        >
        {this.props.countAnswers}&nbsp;réponse{this.props.countAnswers > 1
          ? <span>s</span>
          : null}
      </a>
    );
  }
}
