import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Store from '../../stores/Store';
import NotificationsList from '../notification/NotificationsList';
import Sidebar from '../misc/Sidebar';
import ConnectedBanner from '../misc/ConnectedBanner';
import SearchBox from './SearchBox';

interface AcademyTopBarAdaProps {
  store?: Store;
  atelier: boolean;
  location: { pathname: string };
  posessed: boolean;
  buyParcours: Function | any;
  buyParcoursText: string;

}

interface AcademyTopBarAdaState {
  showNotifications: boolean;
  notifications: string[];
}

@inject('store') @observer
export default class AcademyTopBarAda extends React.Component<AcademyTopBarAdaProps, AcademyTopBarAdaState> {
  _timer: any;

  constructor(props: AcademyTopBarAdaProps) {
    super(props);
    this.startPolling = this.startPolling.bind(this);
    this.poll = this.poll.bind(this);
    this.onMenuChange = this.onMenuChange.bind(this);
    this.switchUI = this.switchUI.bind(this);
  }
  componentDidMount() {
    this.startPolling();
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that._timer = setInterval(that.poll.bind(self), 120000);
    }, 1000);
  }
  poll() {
    if (this.props.store.isAuthenticated()) {
      this.props.store.loadConnected();
    }
  }
  @action
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  onMenuChange(value: number) {
    const connected = this.props.store.connected;
    switch (value) {
      case 1:
        browserHistory.push({
          pathname: `/author/${connected.user.id}/${connected.user.urlIdentity}`
        });
        break;
      case 2:
        browserHistory.push({
          pathname: '/settings'
        });
        break;
      case 3:
        window.location.href = '/auth/logout';
        break;
      default:
        break;
    }
  }
  switchUI() {
    this.props.store.switchUI().then((p: any) => {
      browserHistory.push({
        pathname: '/'
      });
    });
  }
  render() {
    const connected = this.props.store.connected;

    return (
      <div>
        <div className="ui top fixed menu borderless ada-background-editis-beige-1" style={{ boxShadow: 'none', borderBottom: 'none' }}>
            {connected && connected.newUser ?
            <Link
            id="dashboard"
            className="item"
            to="/?m=w"
            title="Tableau de bord">
            <i className="fa fa-home" style={{ fontSize: '1.3em', color: 'rgb(0, 28, 54)' }}></i>
          </Link>
            :
            <Link
              id="dashboard"
              className="item"
              to="/?m=w"
              title="Tableau de bord">
              <i className="fa fa-home" style={{ fontSize: '1.3em', color: 'rgb(0, 28, 54)' }}></i>
            </Link>
            }
            {this.props.atelier ?
              this.props.posessed ?
              <div className="sub header">
                <div style={{paddingTop: "0.6rem"}}>
                  <button
                    className="ui primary button"
                    onClick={this.props.buyParcours}
                    style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                  >
                    Accéder à l'atelier
                  </button>
                </div>
              </div>
            :
            <div className="sub header">
              <div style={{paddingTop: "0.6rem"}}>
                <button
                  className="ui primary button ada-background editis-bleu" 
                  onClick={this.props.buyParcours} 
                  style={{ cursor: 'pointer'}}
                  id={"buy_this_workshop_navbar"}
                >
                  {this.props.buyParcoursText}
                </button>
              </div>
            </div>
            : undefined}
            <div className="right menu" style={{ border: 'none' }}>
              <div className="item">
                {connected.premium.premium ?
                  <span style={{ marginRight: '10px' }}>
                    <Button
                      toggle
                      onClick={this.switchUI}
                      className='ui label ada-background-editis-bleu-nuit'
                      data-tooltip= 'Basculer vers la Communauté'
                      data-inverted="" data-position="bottom left"
                    >
                      <i className="hide-mobile fa fa-arrow-right ada-text-editis-blanc" />
                      <span className="hide-mobile ada-text-editis-blanc" style={{marginRight: "5px"}}> Basculer vers la communauté</span>
                      <i className="fa fa-university ada-text-editis-blanc" />
                    </Button>
                  </span>
                  : undefined}
                <span style={{ marginRight: '10px' }}>
                  <Link
                    id="messages"
                    to="/messages"
                    className={'ui label ' + (connected.messagesCount > 0 ? ' orange' : 'ada-menu ada-background-editis-bleu-nuit')}
                  >
                    <i
                      className={'fa fa-envelope ' + (connected.messagesCount > 0 ? ' ' : 'ada-text-editis-blanc')}
                      style={{ marginRight: '5px' }}
                    />
                    <span className={(connected.messagesCount > 0 ? ' ' : 'ada-text-editis-blanc')}>{connected.messagesCount}</span>
                  </Link>
                </span>
                <div id="profile">
                  <Dropdown icon={null} trigger={
                    <div
                      className={'ui ada-background-editis-bleu-nuit label'}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    >
                      <i
                        className="fa fa-user ada-text-editis-blanc"
                        style={{ marginRight: '5px' }}
                      />
                      <i className="fa fa-caret-down ada-text-editis-blanc"></i>
                    </div>
                  }
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item value={1} text="Mon profil" onClick={() => this.onMenuChange(1)} />
                      <Dropdown.Item value={2} text="Paramètres" onClick={() => this.onMenuChange(2)} />
                      <Dropdown.Item value={3} text="Déconnexion" onClick={() => this.onMenuChange(3)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
        </div>
        <ConnectedBanner />
      </div >
    );
  }
}
