import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
const InfiniteScroll = require('react-infinite-scroll-component');
import Notification from './Notification';
import Store from '../../stores/Store';

interface NotificationsPageProps {
  store?: Store;
}

interface NotificationsPageState {
  loadingNotificationsError: boolean;
  loadingNotifications: boolean;
}

@inject('store') @observer
export default class NotificationsPage extends React.Component<NotificationsPageProps, NotificationsPageState> {
  static fetchData(store: Store) {
    return store.loadAllNotifications(true);
  }
  constructor(props: NotificationsPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      loadingNotificationsError: false,
      loadingNotifications: false
    };
  }
  loadMoreNotifications() {
    this.props.store.loadAllNotifications(false, (failed: boolean)=>{
      this.setState(
      {
        loadingNotificationsError: failed,
        loadingNotifications: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingNotifications: true
    },
    () => {this.loadMoreNotifications()}    
    );
  }
  render() {
    const mainNotifications = this.props.store.mainNotifications;
    const groupNotifs = mainNotifications.notifications.map(group => {
      const notifs = group.notifications.map(n => {
        return (
          <Notification key={n.id} userId={this.props.store.connected.user.id} notif={n} />
        );
      });
      return (
        <div key={`group_${group.day}${group.month}`}>
          <div className="ui horizontal section divider">{group.day}&nbsp;{group.month}</div>
          <div className="ui feed">
            {notifs}
          </div>
        </div>
      );
    }
    );
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.mainNotifications.end){
        if (this.state.loadingNotifications){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingNotificationsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous n'avez pas d'autres notifications</span>
      }
    
      return <div className="ui large feed centered grid" style={{ marginBottom: '30px'}}>{error}{message}</div>

    }
    return (
      <div className="ui grid" style={{ backgroundColor: '#fff'}}>
        <div className="ui stackable text read container" style={{ minHeight: '90vh', paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Notifications " />
          <h1 className="ui header">Notifications</h1>
          <InfiniteScroll
            next={this.loadMore}
            hasMore={false}
            loader={<h4>Chargement...</h4>}
            endMessage={endMessage()}
          >
            {groupNotifs}
          </InfiniteScroll>
        </div >
      </div>
    );
  }
}
