import { observable } from 'mobx';
import { browserHistory } from 'react-router';
import ProductModel from './product/ProductModel';


export default class PaymentTunnelModel {
  @observable registering: boolean;
  @observable product: ProductModel;
  constructor(registering: boolean, product: ProductModel) {
    this.registering = registering;
    this.product = product;
  }

  setProduct(product: ProductModel) {
    this.product = product;
  }


  sendToStripe() {
      browserHistory.push({
        pathname: `/create-checkout-session`
      });
  }

  setRegistering () {
    this.registering = true;
  }

  toJS() {
    return {
      registering: this.registering,
      product: this.product
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new PaymentTunnelModel(object.registering, object.product);
    }
    return undefined;
  }
}
