import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';

interface TextTalksProps {
  store?: Store;
}

@inject('store') @observer
export default class TextTalks extends React.Component<TextTalksProps, {}> {
  render() {
    const text = this.props.store.text;
    const meta = text.meta;
    const authorId = meta.author.id;
    const connected = this.props.store.connected;
    const isAuthor = connected && connected.user && connected.user.id
      && connected.user.id === authorId;
    const talks = text.talkOrCommentSummary.map(t => {
      return (
        <tr key={'talk' + (t.talkId || t.chapterId)} style={t.talkId ? { backgroundColor: '#FFF8DB' } : {}}>
          <td className="nine wide">
            {t.talkId ?
              <Link to={`/text/${meta.id}/${meta.urlText}/talks/${t.talkId}/${t.urlTalk}`}>
                {t.talkTitle}
              </Link>
              : <Link to={`/text/${meta.id}/${meta.urlText}/chapter/${t.chapterId}`}>
                {t.chapterTitle}
              </Link>
            }
          </td>
          <td className="three wide">
            {t.talkId ? 'Discussion' : 'Chapitre'}
          </td>
          <td className="four wide">
            {t.answers} message{t.answers > 1 ? 's' : undefined} {t.lastMessageAgo ? '| ' + t.lastMessageAgo : ''}
          </td>
        </tr>
      );
    });
    return (
      <div>
        {isAuthor || talks.length > 0 ?
          <div className="ui text container">
            <table className="ui small table">
              <tbody>
                {talks.length > 0 ?
                  talks
                  : <tr key="none">
                    <td colSpan={2} className="center aligned">Aucune discussion</td>
                  </tr>
                }
              </tbody>
            </table>
            {isAuthor ?
              <Link
                to={`/text/${meta.id}/${meta.urlText}/talks/new`}
                className="ui right floated mini orange button"
                >
                Nouvelle discussion
                </Link>
              : undefined}
          </div>
          : undefined}
      </div>
    );
  }
}
