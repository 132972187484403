import * as React from 'react';
import TextCard from './TextCard';
import FullTextModel from '../../../models/FullTextModel';

interface TextGalleryProps {
  texts: FullTextModel[];
  twoPerRow?: boolean;
}

export default class TextGallery extends React.Component<TextGalleryProps, {}> {
  render() {
    const childElements = this.props.texts.map(text =>
      <TextCard key={text.id} text={text} />
    );
    return (
      <div className={'ui ' + (this.props.twoPerRow ? 'two' : 'three') + ' stackable cards'}>
        {childElements}
      </div>
    );
  }
}
