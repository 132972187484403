import { observable } from 'mobx';
import UserModel from '../UserModel';
import DefiReponseModel from './DefiReponseModel';
import FullTextModel from '../FullTextModel';

export default class DefiModel {

  @observable id: number;
  @observable title: string;
  @observable urlTitle: string;
  @observable description: string;
  @observable genre: number;
  @observable author: UserModel;
  @observable available: boolean;
  @observable inprogress: boolean;
  @observable nouveau: boolean;
  @observable reponses: number;
  @observable reponsesObj: DefiReponseModel[];
  @observable texts: FullTextModel[];
  @observable comments: number;
  @observable textExample: FullTextModel;
  @observable defiType: number;
  @observable starUntil: string;
  @observable myReponse: DefiReponseModel;
  @observable createdAt: string;

  constructor(id: number, title: string, urlTitle: string, description: string,
    genre: number, author: UserModel, available: boolean,
    inprogress: boolean, nouveau: boolean, reponses: number, reponsesObj: DefiReponseModel[],
    texts: FullTextModel[], comments: number, textExample: FullTextModel,
    defiType: number, starUntil: string, myReponse: DefiReponseModel, createdAt: string) {
    this.id = id;
    this.title = title;
    this.urlTitle = urlTitle;
    this.description = description;
    this.genre = genre;
    this.author = author;
    this.available = available;
    this.inprogress = inprogress;
    this.nouveau = nouveau;
    this.reponses = reponses;
    this.reponsesObj = reponsesObj || [];
    this.texts = texts || [];
    this.comments = comments;
    this.textExample = textExample;
    this.defiType = defiType;
    this.starUntil = starUntil;
    this.myReponse = myReponse;
    this.createdAt = createdAt;
  }

  setTexts(texts: FullTextModel[]) {
    this.texts = texts;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlTitle: this.urlTitle,
      description: this.description,
      genre: this.genre,
      author: this.author.toJS(),
      available: this.available,
      inprogress: this.inprogress,
      nouveau: this.nouveau,
      reponses: this.reponses,
      reponsesObj: this.reponsesObj.map(r => r.toJS()),
      texts: this.texts.map(t => t.toJS()),
      comments: this.comments,
      textExample: this.textExample.toJS(),
      defiType: this.defiType,
      starUntil: this.starUntil,
      myReponse: this.myReponse.toJS(),
      createdAt: this.createdAt
    };
  }

  static fromJS(object: any) {
    if (object) {
      const reponsesObj = object.reponsesObj.map((r: any) => DefiReponseModel.fromJS(r));
      const texts = object.texts.map((t: any) => FullTextModel.fromJS(t));
      return new DefiModel(object.id, object.title, object.urlTitle, object.description,
        object.genre, UserModel.fromJS(object.author),
        object.available, object.inprogress, object.nouveau, object.reponses, reponsesObj,
        texts, object.comments,
        FullTextModel.fromJS(object.textExample), object.defiType, object.starUntil,
        DefiReponseModel.fromJS(object.myReponse), object.createdAt);
    }
    return undefined;
  }
}
