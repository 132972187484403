import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import Store from '../../stores/Store';
import DefiModel from '../../models/defi/DefiModel';
import Loader from '../misc/Loader';
import actions from '../editor/actions';
import styleMap from '../editor/styleMap';
import blockStyles from '../editor/blockStyles';
import convertFromHtml from '../editor/convertFromHtml';
import convertToHtml from '../editor/convertToHtml';

declare const __CLIENT__: any;

export interface DefiFormProps {
  store?: Store;
  submit: Function;
  defi?: DefiModel;
}

interface DefiFormState {
  enableComponents: boolean;
  loading: boolean;
  title: string;
  titleError: boolean;
  description: string;
  descriptionState: any;
  descriptionError: boolean;
  remainingCharacters: number;
  genre: number;
  notificationsEmail: boolean;
}

@inject('store') @observer
export default class DefiForm extends React.Component<DefiFormProps, DefiFormState> {
  static contextTypes = {
    intl: React.PropTypes.object.isRequired,
  };

  constructor(props: DefiFormProps) {
    super(props);
    if (props.defi) {
      this.state = {
        enableComponents: false,
        loading: false,
        title: props.defi.title,
        titleError: false,
        description: props.defi.description,
        descriptionState: undefined,
        descriptionError: false,
        remainingCharacters: 5000,
        genre: props.defi.genre ? props.defi.genre : 0,
        notificationsEmail: true
      };
    } else {
      this.state = {
        enableComponents: false,
        loading: false,
        title: '',
        titleError: false,
        description: '',
        descriptionState: undefined,
        remainingCharacters: 5000,
        descriptionError: false,
        genre: 0,
        notificationsEmail: true
      };
    }
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleGenreChange = this.handleGenreChange.bind(this);
    this.submit = this.submit.bind(this);
    this.checkNotificationsEmail = this.checkNotificationsEmail.bind(this);
  }
  componentDidMount() {
    if (__CLIENT__) {
      const contentState = convertFromHtml(this.state.description);
      const descriptionState = EditorState.createWithContent(contentState);
      const plainText = contentState.getPlainText().trim();

      this.setState({
        enableComponents: true,
        loading: this.state.loading,
        title: this.state.title,
        titleError: this.state.titleError,
        description: this.state.description,
        descriptionState: descriptionState,
        remainingCharacters: 5000 - plainText.length,
        descriptionError: this.state.descriptionError,
        genre: this.state.genre
      });
    }
  }
  handleTitleChange(e: any) {
    const titleError = this.state.titleError && e.target.value.trim().length === 0;
    this.setState({
      enableComponents: this.state.enableComponents,
      loading: false,
      title: e.target.value,
      titleError: titleError,
      description: this.state.description,
      descriptionState: this.state.descriptionState,
      descriptionError: this.state.descriptionError,
      genre: this.state.genre
    });
  }
  handleDescriptionChange(value: any) {
    const descriptionState = value;
    const description = convertToHtml(value);

    const plainText = value.getCurrentContent().getPlainText().trim();
    const remainingCharacters = 5000 - plainText.length;

    const descriptionError = (this.state.descriptionError && plainText.length === 0)
      || remainingCharacters < 0;
    this.setState({
      enableComponents: this.state.enableComponents,
      loading: false,
      title: this.state.title,
      titleError: this.state.titleError,
      description: description,
      descriptionState: descriptionState,
      remainingCharacters: remainingCharacters,
      descriptionError: descriptionError,
      genre: this.state.genre
    });
  }
  handleGenreChange(genre: number) {
    this.setState({
      enableComponents: this.state.enableComponents,
      loading: false,
      title: this.state.title,
      titleError: this.state.titleError,
      description: this.state.description,
      descriptionState: this.state.descriptionState,
      descriptionError: this.state.descriptionError,
      genre: genre
    });
  }
  submit() {
    const titleError = this.state.title.trim().length === 0;
    const plainText = this.state.descriptionState.getCurrentContent().getPlainText().trim();
    const descriptionError = plainText.length === 0 || plainText.length > 5000;
    this.setState({
      enableComponents: this.state.enableComponents,
      loading: !titleError && !descriptionError,
      title: this.state.title,
      titleError: titleError,
      description: this.state.description,
      descriptionState: this.state.descriptionState,
      descriptionError: descriptionError,
      genre: this.state.genre
    });


    if (!titleError && !descriptionError) {
      this.props.submit(this.state.title, this.state.description,
        this.state.genre);

      this.props.store.mainSettings.notifications.email = this.state.notificationsEmail;
      this.props.store.saveSettingsNotifications(true);
    }
  }
  checkNotificationsEmail() {
    this.setState({
      notificationsEmail: !this.state.notificationsEmail
    });
  }
  render() {
    const genres: Array<any> = [];
    genres.push({
      text: '',
      value: 0
    });

    for (let i = 1; i <= 20; i++) {
      genres.push(
        {
          text: this.context.intl.formatMessage({ id: `genre.${i}` }),
          value: i
        }
      );
    }
    const notEmailNotif: boolean = this.props.store.connected && this.props.store.connected.email
      && this.props.store.mainSettings && this.props.store.mainSettings.notifications
      && !this.props.store.mainSettings.notifications.email;
    return (
      <div>
        {this.state.loading ?
          <Loader message="Veuillez patienter" />
          :
          <div>
            <form className="ui form">
              {this.state.titleError || this.state.descriptionError ?
                <div className="ui error message">
                  <div className="header">
                    {this.state.remainingCharacters < 0 ?
                      <span>La description est limitée à 5000 caractères.</span>
                      : <span>Les champs marqués en rouge sont obligatoires.</span>
                    }
                  </div>
                </div>
                : undefined
              }
              <div className={'field' + (this.state.titleError ? ' error' : '')}>
                <label>Titre</label>
                <input type="text" placeholder="Titre" value={this.state.title} onChange={this.handleTitleChange} />
              </div>
              <div className={'field' + (this.state.descriptionError ? ' error' : '')}>
                <label>Description</label>
                {this.state.enableComponents ?
                  <div className="embedded small">
                    <div className="ui segment">
                      <MegadraftEditor
                        editorState={this.state.descriptionState}
                        actions={actions}
                        placeholder="Description"
                        onChange={this.handleDescriptionChange}
                        sidebarRendererFn={() => { return <div />; }}
                        customStyleMap={styleMap}
                        blockStyles={blockStyles}
                        spellcheck={false}
                      />
                      {this.state.remainingCharacters < 0 ?
                        <span style={{ color: '#912d2b' }}>{this.state.remainingCharacters} caractères</span>
                        : undefined}
                    </div>
                  </div>
                  : undefined}
              </div>
              <div className="field" style={{ maxWidth: '300px' }}>
                <label>Genre imposé</label>
                <Dropdown
                  search
                  selection
                  placeholder="Genre"
                  value={this.state.genre}
                  onChange={(e: any, { value }) => this.handleGenreChange(parseInt(String(value)))}
                  options={genres}
                />
              </div>
              {notEmailNotif &&
                <div className="ui info message">
                  <div className="header">Nous vous recommandons d'accepter de recevoir les emails de notifications. Ainsi, vous serez tenu au courant des réponses à votre défi !</div>
                  <div className="form" style={{ marginTop: '15px' }}>
                    <div className="field">
                      <Checkbox
                        checked={this.state.notificationsEmail}
                        onClick={() => this.checkNotificationsEmail()}
                        label="Recevoir les emails de notification"
                      />
                    </div>
                  </div>
                </div>
              }
              {this.props.defi ?
                <Link to={`/defis/defi/${this.props.defi.id}/${this.props.defi.urlTitle}`}
                  className="ui right floated button">
                  Annuler
              </Link>
                : undefined}
              <button className="ui right floated primary button" type="button"
                onClick={this.submit}>Enregistrer</button>
            </form>
          </div>
        }
      </div>
    );
  }
}