import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface ChapterModerationProps {
  store?: Store;
}

interface ChapterModerationState {
  modalActive: boolean;
  identity: string;
  identityError: boolean;
  reasons: string;
  reasonsError: boolean;
}

@inject('store') @observer
export default class ChapterModeration extends React.Component<ChapterModerationProps, ChapterModerationState> {
  constructor(props: ChapterModerationProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleIdentityChange = this.handleIdentityChange.bind(this);
    this.handleReasonsChange = this.handleReasonsChange.bind(this);
    this.postForm = this.postForm.bind(this);
    this.state = {
      modalActive: false,
      identity: '',
      identityError: false,
      reasons: '',
      reasonsError: false
    };
  }
  showModal() {
    const identity = this.props.store.connected && this.props.store.connected.user
      && this.props.store.connected.user.id ?
      this.props.store.connected.user.identity
      : '';
    this.setState({
      modalActive: true,
      identity: identity,
      identityError: false,
      reasons: '',
      reasonsError: false
    });
  }
  hideModal() {
    this.setState({
      modalActive: false,
      identity: this.state.identity,
      identityError: this.state.identityError,
      reasons: this.state.reasons,
      reasonsError: this.state.reasonsError
    });
  }
  handleIdentityChange(e: any) {
    const identityError = this.state.identityError && e.target.value.trim().length === 0;
    this.setState({
      modalActive: true,
      identity: e.target.value,
      identityError: identityError,
      reasons: this.state.reasons,
      reasonsError: this.state.reasonsError
    });
  }
  handleReasonsChange(e: any) {
    const reasonsError = this.state.reasonsError && e.target.value.trim().length === 0;
    this.setState({
      modalActive: true,
      identity: this.state.identity,
      identityError: this.state.identityError,
      reasons: e.target.value,
      reasonsError: reasonsError
    });
  }
  postForm(event: any) {
    event.preventDefault();
    const identityError = this.state.identity.trim().length === 0;
    const reasonsError = this.state.reasons.trim().length === 0;
    this.setState({
      modalActive: true,
      identity: this.state.identity,
      identityError: identityError,
      reasons: this.state.reasons,
      reasonsError: reasonsError
    });

    if (!identityError && !reasonsError) {
      this.props.store.alertModeration(this.state.identity, this.state.reasons).then((d: any) => {
        this.setState({
          modalActive: false,
          identity: this.state.identity,
          identityError: identityError,
          reasons: this.state.reasons,
          reasonsError: reasonsError
        });
      });
    } else {
      this.setState({
        modalActive: true,
        identity: this.state.identity,
        identityError: identityError,
        reasons: this.state.reasons,
        reasonsError: reasonsError
      });
    }
  }
  render() {
    const text = this.props.store.text.meta;

    return (
      <div>
        <Modal
          onClose={this.hideModal}
          open={this.state.modalActive}
          >
          <div className="header">Modération</div>
          <div className="content">
            <h4>Texte visé : {text.title}, de {text.author.identity}</h4>
            <p>
              Merci de préciser votre identité et votre raisons ci-dessous.
              </p>
            <form className="ui form">
              <div className={'field' + (this.state.identityError ? ' error' : '')}>
                <label>Votre identité</label>
                <input
                  type="text"
                  placeholder="Identité"
                  onChange={this.handleIdentityChange}
                  value={this.state.identity}
                  />
              </div>
              <div className={'field' + (this.state.reasonsError ? ' error' : '')}>
                <label>Vos raisons</label>
                <textarea
                  value={this.state.reasons}
                  rows={2}
                  style={{ height: '7em' }}
                  onChange={this.handleReasonsChange}
                  />
              </div>
            </form>
          </div>
          <div className="actions">
            <button className="ui submit primary button" type="button" onClick={this.postForm}>
              Signaler
              </button>
            <button className="ui cancel button" onClick={this.hideModal}>
              Fermer
            </button>
          </div>
        </Modal>
        <div>
          <button className="ui icon button" onClick={this.showModal}
            data-tooltip="Signaler à la modération" data-inverted="" data-position="bottom center"
            >
            <i className="fa fa-exclamation-triangle" />
          </button>
        </div>
      </div>
    );
  }
}
