import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class TalkModel {

  @observable id: number;
  @observable title: string;
  @observable message: string;
  @observable urlTitle: string;
  @observable author: UserModel;
  @observable locked: boolean;
  @observable lockedMessage: string;
  @observable permanent: boolean;
  @observable answers: number;
  @observable likes: number;
  @observable createdAt: string;
  @observable createdAtAgo: string;
  @observable lastMessageAgo: string;
  @observable tags: string[];
  @observable read: boolean;
  @observable textId: number;

  constructor(id: number, title: string, message: string, urlTitle: string,
    author: UserModel, locked: boolean, lockedMessage: string, permanent: boolean, answers: number,
    likes: number, createdAt: string, createdAtAgo: string, lastMessageAgo: string,
    tags: string[], read: boolean, textId: number) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.urlTitle = urlTitle;
    this.author = author;
    this.locked = locked;
    this.lockedMessage = lockedMessage;
    this.permanent = permanent;
    this.answers = answers;
    this.likes = likes;
    this.createdAt = createdAt;
    this.createdAtAgo = createdAtAgo;
    this.lastMessageAgo = lastMessageAgo;
    this.tags = tags || [];
    this.read = read || false;
    this.textId = textId;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      message: this.message,
      urlTitle: this.urlTitle,
      author: this.author.toJS(),
      locked: this.locked,
      lockedMessage: this.lockedMessage,
      permanent: this.permanent,
      answers: this.answers,
      likes: this.likes,
      createdAt: this.createdAt,
      createdAtAgo: this.createdAtAgo,
      lastMessageAgo: this.lastMessageAgo,
      tags: this.tags,
      read: this.read,
      textId: this.textId
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new TalkModel(object.id, object.title, object.message, object.urlTitle,
        UserModel.fromJS(object.author), object.locked, object.lockedMessage, object.permanent,
        object.answers, object.likes, object.createdAt, object.createdAtAgo,
        object.lastMessageAgo, object.tags, object.read, object.textId);
    }
    return undefined;
  }
}
