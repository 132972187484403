import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import Store from '../../../../../stores/Store';
import Loader from '../../../../misc/Loader';
import actions from '../../../../editor/actions';
import styleMap from '../../../../editor/styleMap';
import blockStyles from '../../../../editor/blockStyles';
import convertFromHtml from '../../../../editor/convertFromHtml';
import convertToHtml from '../../../../editor/convertToHtml';

interface ChapterDescriptionProps {
  store?: Store;
  textId: number;
  chapterId: number;
  active: boolean;
  hideModal: Function | any;
}

interface ChapterDescriptionState {
  description: string;
  descriptionState: any;
}

@inject('store') @observer
export default class ChapterDescription extends React.Component<ChapterDescriptionProps, ChapterDescriptionState> {
  constructor(props: ChapterDescriptionProps) {
    super(props);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.save = this.save.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      description: '',
      descriptionState: undefined
    };
  }
  componentWillReceiveProps(nextProps: ChapterDescriptionProps) {
    if (nextProps.textId && nextProps.chapterId && this.props.chapterId !== nextProps.chapterId) {
      this.props.store.loadChapterDescription(nextProps.textId, nextProps.chapterId).then((d: any) => {
        let contentState = convertFromHtml(d.description);
        const descriptionState = EditorState.createWithContent(contentState);

        this.setState({
          description: d.description,
          descriptionState: descriptionState
        });
      });
    }
  }
  handleDescriptionChange(value: any) {
    const descriptionState = value;
    const description = convertToHtml(value);

    this.setState({
      description: description,
      descriptionState: descriptionState
    });
  }
  save() {
    this.props.store.saveChapterDescription(this.props.textId, this.props.chapterId,
      this.state.description).then((d: any) => {
        this.props.hideModal(this.state.description);
      });
  }
  hide() {
    this.setState({
      description: '',
      descriptionState: undefined
    });
    this.props.hideModal();
  }
  render() {
    return (
      <Modal open={this.props.active} size="small" onClose={() => { }}>
        <div className="header">Modifier la description du chapitre</div>
        <div className="content">
          {this.state.description && this.state.descriptionState ?
            <div className="embedded small">
              <div className="ui segment">
                <MegadraftEditor
                  editorState={this.state.descriptionState}
                  actions={actions}
                  placeholder="Description du chapitre"
                  onChange={this.handleDescriptionChange}
                  sidebarRendererFn={() => { return <div />; }}
                  customStyleMap={styleMap}
                  blockStyles={blockStyles}
                  spellcheck={false}
                />
              </div>
            </div>
            : <Loader message="Chargement..." />
          }
        </div>
        <div className="actions">
          <button className="ui primary submit button"
            onClick={this.save}
            type="submit"
          >
            Enregistrer
          </button>
          <button className="ui cancel button" onClick={() => this.hide()}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}
