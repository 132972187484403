import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
const InfiniteScroll = require('react-infinite-scroll-component');
import Text from './Text';
import Store from '../../../../stores/Store';
import NewTextModal from '../NewTextModal';
import NewTrainingModal from '../NewTrainingModal';

interface TextsProps {
  store?: Store;
}

interface TextsState {
  loadingTextsError: boolean;
  loadingTexts: boolean;
  activeNewText: boolean;
  activeNewTraining: boolean;
}

@inject('store') @observer
export default class Texts extends React.Component<TextsProps, TextsState> {
  constructor(props: TextsProps) {
    super(props);
    this.sortTexts = this.sortTexts.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.showNewText = this.showNewText.bind(this);
    this.hideNewText = this.hideNewText.bind(this);
    this.showNewTraining = this.showNewTraining.bind(this);
    this.hideNewTraining = this.hideNewTraining.bind(this);
    this.state = {
      loadingTextsError: false,
      loadingTexts: false,
      activeNewText: false,
      activeNewTraining: false
    };
  }
  @action
  loadMoreTexts() {
    this.props.store.loadTexts(false, (failed: boolean)=>{
      this.setState(
      {
        loadingTextsError: failed,
        loadingTexts: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingTexts: true
    },
    () => {this.loadMoreTexts()}    
    );
  }
  showNewText() {
    const p: any[] = [];
    p.push(this.props.store.loadAllCanvas());
    p.push(this.props.store.loadAllParcours());
    Promise.all(p).then((d: any) => {
      this.setState({
        activeNewText: true
      });
    });
  }
  hideNewText() {
    this.setState({
      activeNewText: false
    });
  }
  showNewTraining() {
    this.props.store.generateTrainingInstructions(false, false, undefined).then((d: any) => {
      this.setState({
        activeNewTraining: true
      });
    });
  }
  hideNewTraining() {
    this.setState({
      activeNewTraining: false
    });
  }
  @action
  sortTexts(c: string, o: string) {
    const write = this.props.store.write;
    write.criteria = c;
    write.order = o;
    write.page = 0;
    this.props.store.loadTexts(true);
  }
  render() {
    const write = this.props.store.write;
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;
    const texts = write.texts.map(text =>
      <Text key={text.id + Math.random() * 100} text={text} />
    );
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.write.end){
        if (this.state.loadingTexts){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingTextsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span></span>
      }
    
      return <div className="ui large feed centered grid">{error}{message}</div>

    }

    return (
      <div>
        <NewTextModal active={this.state.activeNewText} hide={this.hideNewText} />
        <NewTrainingModal active={this.state.activeNewTraining} hide={this.hideNewTraining} />
        <h3>
          Toutes mes œuvres
          </h3>
        <InfiniteScroll
          next={this.loadMore}
          hasMore={false}
          loader={<h4>Chargement...</h4>}
          endMessage={endMessage()}
        >
          <table className="ui unstackable table teal">
            <thead>
              <tr>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'title' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('title', 'DESC')
                      : () => this.sortTexts('title', 'ASC')
                    )
                    : () => this.sortTexts('title', 'ASC')}
                >
                  Titre
                    {write.criteria === 'title' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="center aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'published' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('published', 'DESC')
                      : () => this.sortTexts('published', 'ASC')
                    )
                    : () => this.sortTexts('published', 'ASC')}
                >
                  Statut
                    {write.criteria === 'published' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="center aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'fromDefi' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('fromDefi', 'DESC')
                      : () => this.sortTexts('fromDefi', 'ASC')
                    )
                    : () => this.sortTexts('fromDefi', 'ASC')}
                >
                  Défi ?
                  {write.criteria === 'fromDefi' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="right aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'views' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('views', 'DESC')
                      : () => this.sortTexts('views', 'ASC')
                    )
                    : () => this.sortTexts('views', 'ASC')}
                >
                  <i className="fa fa-eye"></i>
                  {write.criteria === 'views' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up"></i>
                        :
                        <i className="fa fa-caret-down"></i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="right aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'favorites' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('favorites', 'DESC')
                      : () => this.sortTexts('favorites', 'ASC')
                    )
                    : () => this.sortTexts('favorites', 'ASC')}
                >
                  <i className="fa fa-heart" />
                  {write.criteria === 'favorites' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="right aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'comments' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('comments', 'DESC')
                      : () => this.sortTexts('comments', 'ASC')
                    )
                    : () => this.sortTexts('comments', 'ASC')}
                >
                  <i className="fa fa-comment" />
                  {write.criteria === 'comments' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  className="right aligned"
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'annotations' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('annotations', 'DESC')
                      : () => this.sortTexts('annotations', 'ASC')
                    )
                    : () => this.sortTexts('annotations', 'ASC')}
                >
                  <i className="fa fa-edit" />
                  {write.criteria === 'annotations' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up">
                        </i>
                        :
                        <i className="fa fa-caret-down">
                        </i>
                      }
                    </span>
                    : null}
                </th>
                <th
                  style={{ cursor: 'pointer' }}
                  onClick={write.criteria === 'lastModificationDate' ?
                    (write.order === 'ASC' ?
                      () => this.sortTexts('lastModificationDate', 'DESC')
                      : () => this.sortTexts('lastModificationDate', 'ASC')
                    )
                    : () => this.sortTexts('lastModificationDate', 'ASC')}
                >
                  Modifié le
                    {write.criteria === 'lastModificationDate' ?
                    <span style={{ marginLeft: '5px' }}>
                      {write.order === 'ASC' ?
                        <i className="fa fa-caret-up"></i>
                        : <i className="fa fa-caret-down"></i>
                      }
                    </span>
                    : null}
                </th>
              </tr>
            </thead>
            <tbody>
              {texts.length > 0
                ? texts
                : <tr>
                  <td colSpan={9} className="center aligned">
                    Aucune œuvre
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </InfiniteScroll>
        {premium ?
            <button
              id="new_text"
              onClick={this.showNewTraining}
              className="ui right floated button"
            >
              Entraînement
          </button>
            : undefined}
          <button
            onClick={this.showNewText}
            className="ui right floated orange button"
          >
            Nouvelle œuvre
          </button>
      </div>
    );
  }
}
