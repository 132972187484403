import { observable } from 'mobx';
import StructureChapterModel from './StructureChapterModel';

export default class StructurePartModel {

  @observable title: string;
  @observable chapters: StructureChapterModel[];

  constructor(title: string, chapters: StructureChapterModel[]) {
    this.title = title;
    this.chapters = chapters;
  }

  toJS() {
    return {
      title: this.title,
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const chapters = object.chapters.map((c: any) => StructureChapterModel.fromJS(c));
      return new StructurePartModel(object.title, chapters);
    }
    return undefined;
  }
}
