import * as React from 'react';
import { inject, observer } from 'mobx-react';
import UserModel from '../../models/UserModel';
import CommentModel from '../../models/comment/CommentModel';
import Store from '../../stores/Store';

interface CommentAnswerProps {
  store?: Store;
  comment: CommentModel;
  chapterUrl: string;
  author: UserModel;
}

@inject('store') @observer
export default class CommentAnswer extends React.Component<CommentAnswerProps, {}> {
  constructor(props: CommentAnswerProps) {
    super(props);
    this.handleInitAnswer = this.handleInitAnswer.bind(this);
  }
  handleInitAnswer() {
    this.props.store.handleInitAnswer(this.props.comment);
  }
  render() {
    let cpt: any;
    if (this.props.author) {
      cpt = <a onClick={this.handleInitAnswer}>Répondre</a>;
    } else {
      cpt = <a href="/login" className="footer-comment">Répondre</a>;
    }

    return cpt;
  }
}
