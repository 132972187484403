import { observable } from 'mobx';

export default class ProductBulletPointModel {

  @observable id: number;
  @observable productId: number;
  @observable title: string;
  @observable index: number;
  @observable imageUrl: string;
  @observable fontAwesome: string;
  @observable content: string;
  @observable main: boolean;


  constructor(id: number, productId: number, title: string, index: number,
    imageUrl: string, fontAwesome: string, content: string, main: boolean) {
      this.id = id
      this.productId = productId
      this.title = title
      this.index = index
      this.imageUrl = imageUrl
      this.fontAwesome = fontAwesome
      this.content = content
      this.main = main
    }
  toJS() {
    return {
      id: this.id,
      productId: this.productId,
      title: this.title,
      index: this.index,
      imageUrl: this.imageUrl,
      fontAwesome: this.fontAwesome,
      content: this.content,
      main: this.main
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ProductBulletPointModel(
        object.id,
        object.productId,
        object.title,
        object.index,
        object.imageUrl,
        object.fontAwesome,
        object.content,
        object.main
        );
    }
    return undefined;
  }
}
