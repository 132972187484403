import { observable } from 'mobx';

export default class SettingsEmailsModel {

  @observable communicationsEmail: boolean;
  @observable newsletterEmail: boolean;
  @observable tipsEmail: boolean;
  @observable boughtAParcoursNews: boolean;
  @observable allInclusiveNews: boolean;

  @observable loading: boolean;

  constructor(communicationsEmail: boolean, newsletterEmail: boolean, tipsEmail: boolean, 
    boughtAParcoursNews: boolean, allInclusiveNews: boolean) {
    this.communicationsEmail = communicationsEmail;
    this.newsletterEmail = newsletterEmail;
    this.tipsEmail = tipsEmail;
    this.boughtAParcoursNews = boughtAParcoursNews;
    this.allInclusiveNews = allInclusiveNews;
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      communicationsEmail: this.communicationsEmail,
      newsletterEmail: this.newsletterEmail,
      tipsEmail: this.tipsEmail,
      boughtAParcoursNews: this.boughtAParcoursNews,
      allInclusiveNews: this.allInclusiveNews
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SettingsEmailsModel(object.communicationsEmail, object.newsletterEmail, object.tipsEmail, 
        object.boughtAParcoursNews, object.allInclusiveNews);
    }
    return undefined;
  }
}
