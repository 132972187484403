import * as React from 'react';
import { Link } from 'react-router';
import SidebarNotConnected from '../misc/SidebarNotConnected';


interface NotConnectedTopBarProps {
  atelier: boolean;
  isConnected: number;
  posessed: boolean;
  buyParcours: Function | any;
  buyParcoursText: string;
}

export default class NotConnectedTopBar extends React.Component<NotConnectedTopBarProps, {}> {
  constructor(props: NotConnectedTopBarProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="hide-large">
          <SidebarNotConnected />
        </div>
        <div className={'ui top inverted ada-background-editis-bleu-nuit fixed menu'} style={{ boxShadow: 'none', borderBottom: 'none' }}>
          <div className="ui container">
            <Link to="/" className="hide-mobile">
              <img
                src='/logo-ada-blanc.png'
                style={{ marginTop: "3px", marginLeft: "5px", width: '9em' }}
                alt="Logo Ada"
              />
            </Link>
            <Link
              to="/ecole"
              className="ui item hide-mobile large button ada-text-editis-white ada-museo-300"
              id="l_ecole_d_ecriture_navbar"
            >
              L'école d'écriture
            </Link>
            <Link
              to="/communaute"
              className="ui item hide-mobile large button hide-mobile ada-text-editis-white ada-museo-300"
            >
              La communauté
            </Link>
            {this.props.atelier ?
              this.props.posessed ?
              <div className="sub header home-padding">
                <div style={{paddingTop: "0.6rem"}}>
                  <button
                    className="ui primary button"
                    onClick={this.props.buyParcours}
                    style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                  >
                    Accéder à l'atelier
                  </button>
                </div>
              </div>
            :
            <div className="sub header home-padding">
              <div style={{paddingTop: "0.6rem"}}>
                <button
                  className="ui primary button ada-background editis-bleu" 
                  onClick={this.props.buyParcours} 
                  style={{ cursor: 'pointer'}}
                  id={"buy_this_workshop_navbar"}
                >
                  {this.props.buyParcoursText}
                </button>
              </div>
            </div>
            : undefined}
            {this.props.isConnected ? undefined :
            <div className="right menu">
              <Link
                to="/login"
                className="ui item button ada-text-editis-white ada-museo-300"
              >
                Se connecter
              </Link>
              {!this.props.atelier ?
              <Link to="/signup"
                className="ui item button ada-text-editis-white ada-museo-300">
                Inscription
              </Link> : undefined}
            </div>
            }
          </div>
        </div >
      </div >
    );
  }
}
