import * as React from 'react';
import ListCard from './ListCard';
import ReadingListModel from '../../models/readinglist/ReadingListModel';

interface ListCarouselProps {
  lists: ReadingListModel[];
}

export default class ListCarousel extends React.Component<ListCarouselProps, {}> {
  render() {
    const childElements = this.props.lists.map(list => <ListCard key={list.id} list={list} />);

    return (
      <div className="ui three stackable cards">
        {childElements}
      </div>
    );
  }
}
