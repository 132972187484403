const convertFromHTML = require('draft-convert').convertFromHTML;
import htmlToStyle from './htmlToStyle';
import htmlToBlock from './htmlToBlock';

function convertFromHtml(value: any) {
  return convertFromHTML({
    htmlToStyle: htmlToStyle,
    htmlToBlock: htmlToBlock
  })(value);
}

export default convertFromHtml;
