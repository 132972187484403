import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as NotificationSystem from 'react-notification-system';
import Store from '../../stores/Store';

interface AlertsSystemProps {
  store?: Store;
}

@inject('store') @observer
export default class AlertsSystem extends React.Component<AlertsSystemProps, {}> {
  refs: {
    [string: string]: any;
    notificationSystem: any;
  };
  constructor(props: AlertsSystemProps) {
    super(props);
  }
  componentWillUpdate(nextProps: AlertsSystemProps, nextstate: any) {
    const alerts = nextProps.store.mainAlerts.alerts;

    alerts.forEach(alert => {
      this.refs.notificationSystem.addNotification({
        title: alert.title,
        message: alert.message,
        level: alert.level,
        position: 'br',
        autoDismiss: alert.autoDismiss,
        dismissible: true,
        onRemove: () => {
          this.props.store.removeAlert(alert.id);
        }
      });
    });
  }
  render() {
    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px'
        },

        success: {
          borderTop: 'none',
          backgroundColor: '#FCFFF5',
          color: '#2C662D',
          WebkitBoxShadow: '0 0 0 1px #A3C293 inset,0 0 0 0 transparent',
          MozBoxShadow: '0 0 0 1px #A3C293 inset,0 0 0 0 transparent',
          boxShadow: '0 0 0 1px #A3C293 inset,0 0 0 0 transparent'
        }
      },
      Title: {
        success: {
          color: '#1A531B'
        }
      },
      Dismiss: {
        success: {
          color: 'inherit',
          backgroundColor: 'inherit'
        }
      }
    };
    return (
      <div>
        <span style={{ display: 'none' }}>
          {this.props.store.mainAlerts ? this.props.store.mainAlerts.alerts.length : 0}
        </span>
        <NotificationSystem ref="notificationSystem" style={style} />
      </div>
    );
  }
}
