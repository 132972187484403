import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import Store from '../../stores/Store';
import SuggestionModel from '../../models/home/SuggestionModel';

interface SuggestionsProps {
  store?: Store;
}

@inject('store') @observer
export default class CurrentTexts extends React.Component<SuggestionsProps, {}> {
  constructor(props: SuggestionsProps) {
    super(props);
    this.follow = this.follow.bind(this);
  }
  @action
  follow(userId: number) {
    this.props.store.followUser(userId).then((d: any) => {
      this.props.store.home.suggestions.filter(s => s.user.id === userId)[0].followed = true;
    });
  }
  render() {
    const suggestions = this.props.store.home.suggestions.map(suggestion => {
      return (
        <div key={`suggestion_${suggestion.user.id}`} className="item">
          <div className="right floated content">
            {suggestion.followed ?
              <button className="ui mini disabled button">
                <i className="fa fa-fw fa-check" />Suivre
              </button>
              : <button className="ui mini button" onClick={() => this.follow(suggestion.user.id)}>Suivre</button>
            }
          </div>
          <div className="ui fifty rounded image">
            <img src={suggestion.user.avatarUrl} alt={`Image de profil de ${suggestion.user.identity}`} />
          </div>
          <div className="content" style={{ paddingRight: '50px' }}>
            <Link
              to={`/author/${suggestion.user.id}/${suggestion.user.urlIdentity}`}
              className="header"
              style={{ display: 'inline' }}
            >
              {suggestion.user.identity}
            </Link>
            <div className="description">
              {suggestion.count} œuvre{suggestion.count > 1 ? 's' : ''}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="ui basic segment" style={{ maxWidth: '500px' }}>
        {suggestions.length > 0 ?
          <div>
            <div className="ui ui middle aligned divided large relaxed list">
              {suggestions}
            </div>
          </div>
          :
          undefined
        }
      </div>
    );
  }
}
