import { observable } from 'mobx';

export default class ReadingListLightModel {

  @observable id: number;
  @observable name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ReadingListLightModel(
        object.id, object.name
      );
    }
    return undefined;
  }
}
