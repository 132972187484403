import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { scroller } from 'react-scroll';
import { Async } from 'react-select';
import ChatView from './ChatView';
import ChatDiscussionTypingMessage from './ChatDiscussionTypingMessage';
import Store from '../../stores/Store';

declare const __CLIENT__: any;

interface ChatDiscussionPanelProps {
  store?: Store;
  fetchUsers: any;
  handleUsersChange: (value: any) => void;
  message: string;
  postMessage: Function | any;
  handleTextChange: Function | any;
  remainingCharacters: number;
}

interface ChatDiscussionPanelState {
  enableComponents: boolean;
}

@inject('store') @observer
export default class ChatDiscussionPanel extends React.Component<ChatDiscussionPanelProps, ChatDiscussionPanelState> {
  constructor(props: ChatDiscussionPanelProps) {
    super(props);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
      scroller.scrollTo('conv', {
        offset: -55,
        duration: 500,
        delay: 100,
        smooth: true
      });
    }
  }
  render() {
    const connected = this.props.store.connected;
    const discussion = this.props.store.chat.discussion;
    const users = discussion.targetUsers
      .map(tu => tu.label)
      .splice(0, 3)
      .join(', ')
      + (discussion.targetUsers.length > 3 ? ',...' : '');
    return (
      <div className="ui basic segment">
        {connected && connected.user && connected.user.id === discussion.creatorId ?
          <div id="conv">
            <h2 className="ui header">Conversation avec</h2>
            {this.state.enableComponents ?
              <Async
                loadOptions={this.props.fetchUsers}
                multi={true}
                value={(discussion.targetUsers as any).toJS()}
                onChange={this.props.handleUsersChange}
                placeholder="..."
                noResultsText="Aucun utilisateur trouvé"
                searchPromptText="Entrez un nom d'utilisateur"
                loadingPlaceholder="Chargement..."
                ignoreCase={true}
                ignoreAccents={false}
                backspaceRemoves={false}
                deleteRemoves={false}
                clearable={false}
                aria-label="Entrez un nom d'utilisateur"
              />
              : undefined}
          </div>
          :
          <h2 className="ui header">Conversation avec {users}</h2>
        }
        <div className="ui divider" />
        <div>
          <ChatView />
          {!discussion.blocked ?
            <div className="ui comments">
              <form onSubmit={this.props.postMessage} className="ui reply form" style={{ padding: '10px' }}>
                <div className={(this.props.remainingCharacters < 0 ? 'error' : '') + ' field'} style={{ margin: '0em 0em 1.4em' }}>
                  <textarea
                    value={this.props.message}
                    rows={2}
                    style={{ height: '7em' }}
                    onChange={this.props.handleTextChange}
                  />
                </div>
                {this.props.remainingCharacters < 0 ?
                  <span style={{ color: '#912d2b' }}>{this.props.remainingCharacters} caractères</span>
                  : undefined}
                <button
                  className="ui blue submit right floated button"
                  disabled={discussion.targetUsers.length === 0 || this.props.remainingCharacters < 0 || this.props.remainingCharacters === 5000}
                  type="submit"
                >
                  Poster
                </button>
              </form>
            </div>
            :
            <div className="ui small message">
              Vous ne pouvez plus participer à cette discussion.
            </div>
          }
        </div>
      </div>
    );
  }
}
