import { observable } from 'mobx';

export default class LightParcoursPrologueModel {

  @observable id: number;
  @observable index: number;
  @observable title: string;

  constructor(id: number, index: number, title: string) {
    this.id = id;
    this.index = index;
    this.title = title;
  }

  toJS() {
    return {
      id: this.id,
      index: this.index,
      title: this.title
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new LightParcoursPrologueModel(object.id, object.index, object.title);
    }
    return undefined;
  }
}
