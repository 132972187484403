import * as React from 'react';
import BadgeModal from '../misc/BadgeModal';
import BadgeModel from '../../models/BadgeModel'


interface ProfileBadgesProps {
  badges: BadgeModel[];
}

interface ProfileBadgesState {
  badge: BadgeModel;
  targetBadgeModal: any;
  activeBadgeModal: boolean;
}

export default class ProfileBadges extends React.Component<ProfileBadgesProps, ProfileBadgesState> {
  constructor(props: ProfileBadgesProps) {
    super(props);
    this.state = {
      badge: undefined,
      targetBadgeModal: undefined,
      activeBadgeModal: false
    };
    this.showBadgeModal = this.showBadgeModal.bind(this);
    this.hideBadgeModal = this.hideBadgeModal.bind(this);
  }
  showBadgeModal(event: any, badge: BadgeModel) {
    this.setState({
      badge: badge,
      activeBadgeModal: true,
      targetBadgeModal: event.target
    });
  }
  hideBadgeModal() {
    this.setState({
      badge: undefined,
      activeBadgeModal: false,
      targetBadgeModal: this.state.targetBadgeModal
    });
  }
  render() {
    const badges = this.props.badges.map(badge => {
      let badgeUrl: string;
      if (badge.level) {
        badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/${badge.name}/${badge.level}.png`;
      } else {
        if (badge.name.indexOf('defi') !== -1) {
          badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/defis/${badge.name.split('_')[1]}.png`;
        } else {
          badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/misc/${badge.name}.png`;
        }
      }
      return <img
        key={badge.name} src={badgeUrl} className="ui image"
        onClick={(e: any) => this.showBadgeModal(e, badge)}
        style={{ cursor: 'pointer' }}
        alt={`Image du badge ${badge.name}`}
      />;
    });
    return (
      <div>
        {this.state.badge ?
          <BadgeModal
            badge={this.state.badge}
            active={this.state.activeBadgeModal}
            hideModal={this.hideBadgeModal}
          />
          : undefined
        }
        <div className="ui images">
          {badges}
        </div>
      </div>
    );
  }
}
