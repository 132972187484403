import { observable } from 'mobx';
import ParcoursSummaryContentModel from './ParcoursSummaryContentModel';

export default class ParcoursSummaryModel {

  @observable title: string;
  @observable contents: ParcoursSummaryContentModel[];

  constructor(title: string, contents: ParcoursSummaryContentModel[]) {
    this.title = title;
    this.contents = contents;
  }

  toJS() {
    return {
      title: this.title,
      contents: this.contents
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursSummaryModel(object.title, object.contents);
    }
    return undefined;
  }
}
