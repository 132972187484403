import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
const Select = require('semantic-ui-react/dist/commonjs/addons/Select').default;

interface ProfileUserDeleteProps {
  store?: Store;
}

interface ProfileUserDeleteState {
  active: boolean;
  userDeleted: boolean;
  deleteMailjet: boolean;
  deletionFailure: boolean
}

@inject('store') @observer
export default class ProfileUserDelete extends React.Component<ProfileUserDeleteProps, ProfileUserDeleteState> {
  constructor(props: ProfileUserDeleteProps) {
    super(props);
    this.handleChangeDeleteMailjet = this.handleChangeDeleteMailjet.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showDeleted = this.showDeleted.bind(this);
    this.hideDeleted = this.hideDeleted.bind(this);
    this.delete = this.delete.bind(this);
    this.state = {
      active: false,
      userDeleted: false,
      deleteMailjet: true,
      deletionFailure: false
    };
  }
  handleChangeDeleteMailjet() {
    this.setState({
      deleteMailjet: !this.state.deleteMailjet
    });
  }
  showModal() {
    this.setState({
      active: true,
      deleteMailjet: true
    });
  }
  hideModal() {
    this.setState({
      active: false
    });
  }
  showDeleted() {
    this.setState({
      active: false,
      userDeleted: true,
      deletionFailure: false
    });
  }
  hideDeleted() {
    browserHistory.push({
      pathname: `/`
    });
  }
  deletionFailure() {
    this.setState({
      deletionFailure: true
    });
  }
  delete() {
    const profileId = this.props.store.profile.id;
    this.props.store.deleteUser(profileId, this.state.deleteMailjet).then((d: any) => {
      if (this.props.store.profile.beingDeleted === true)
      {
        this.showDeleted();
      }
      else
      {
        this.deletionFailure();
      }
    });
  }
  render() {
    return (
      <span>
        <Modal
          open={this.state.active}
          onClose={() => { } }
          >
          <div className="header">Confirmation</div>
          <div className="content">
            Confirmez-vous la suppression de cet utilisateur ?
            <br/>
            <Checkbox
              checked={this.state.deleteMailjet}
              onClick={this.handleChangeDeleteMailjet}
              label="Supprimer le contact Mailjet"
            />
            {this.state.deletionFailure ?
               <span className="ui" style={{ color: "red"}}><br/>La suppression n'a pas fonctionné</span>               
             : undefined}
          </div>
          <div className="actions">
            <button
              className="ui red approve button" onClick={this.delete}
              >
              Confirmer
          </button>
            <button className="ui cancel button" onClick={this.hideModal}>Annuler</button>
          </div>
        </Modal>
        <Modal
          open={this.state.userDeleted}
          onClose={() => { } }
          >
          <div className="content">
            L'utilisateur a bien été supprimé !
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.hideDeleted}
              >
              Retour à la page d'accueil
          </button>
          </div>
        </Modal>
        <button className="ui red small button" onClick={this.showModal}>
        <i className="ui red fa fa-remove fa-fw"></i> Supprimer l'utilisateur
        </button>
      </span>
    );
  }
}
