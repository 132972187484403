import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import * as superagent from 'superagent';
import { Radar } from 'react-chartjs-2';
const color = require('chartjs-color');
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import ReadIndicator from './ReadIndicator';
import Loader from '../../../misc/Loader';
import Title from '../../../misc/Title';

interface TextStatisticsCompareProps {
  store?: Store;
  params: TextParams;
  location: {
    query: {
      author: string
    }
  };
}

declare const __CLIENT__: any;

function normalize(value: number, min: number, max: number) {
  const norm = ((value - min) / (max - min));
  return (norm > 1 ? 1 : (norm < 0 ? 0 : norm)).toFixed(2);
}
function normalizeAvgSentencesPerParagraph(value: number) {
  return normalize(value, 1, 15);
}
function normalizeAvgWordsPerSentence(value: number) {
  return normalize(value, 1, 40);
}
function normalizeAvgCharactersPerWord(value: number) {
  return normalize(value, 3, 6);
}
function normalizeNames(value: number) {
  return normalize(value, 0, 100);
}
function normalizeAdjectives(value: number) {
  return normalize(value, 0, 100);
}
function normalizeVerbs(value: number) {
  return normalize(value, 0, 100);
}
function normalizeAdverbs(value: number) {
  return normalize(value, 0, 100);
}
function normalizeNegatives(value: number) {
  return normalize(value, 0, 100);
}
function normalizePassives(value: number) {
  return normalize(value, 0, 100);
}
function normalizeTernes(value: number) {
  return normalize(value, 0, 100);
}
function normalizeNonVerbales(value: number) {
  return normalize(value, 0, 100);
}
function normalizeLexicale(value: number) {
  return normalize(value, 0, 100);
}

@inject('store') @observer
export default class TextStatisticsCompare extends React.Component<TextStatisticsCompareProps, {}> {
  timerCompute: any;
  timerReload: any;

  static fetchData(store: Store, params: TextParams, query: { author: string }) {
    const author = query && query.author ? query.author : undefined;
    if (store.isAuthenticated()) 
      store.loadConnected()
    return store.loadTextInfos(params.textId).then((d: any) => {
      const p: any[] = [];
      p.push(store.loadTextStatisticsCompareList());
      p.push(store.loadTextStatisticsCompareAuthors());
      if (author) {
        p.push(store.loadTextStatisticsCompare(author));
      }
      return Promise.all(p);
    });
  }
  render() {
    const currentText = this.props.store.write.currentText;
    const statisticsCompareList = currentText.statisticsCompareList;
    const authorCompare = this.props.location && this.props.location.query && this.props.location.query.author ?
      currentText.statisticsCompare
      : (statisticsCompareList && statisticsCompareList.length > 0 ? statisticsCompareList[0] : undefined);
    const authorList = currentText.statisticsCompareAuthors;
    const selectedAuthor = authorCompare ? authorCompare.author : '';

    const statistics = currentText.statisticsCompareList;
    const rows = statistics ? statistics.map((statistic) => {
      return (
        <tr key={`stat_compare_${statistic.author}`}>
          <td>
            <b>{statistic.author}</b>
          </td>
          <td className="right aligned">
            <span>{statistic.avgSentencesPerParagraph}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.avgWordsPerSentence}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.avgCharactersPerWord}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.names}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.adjectives}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.verbs}</span>
          </td>
          <td className="right aligned">
            <span>{statistic.adverbs}</span>
          </td>
        </tr>
      );
    }) : [];
    if (statistics) {
      rows.unshift(
        <tr key="stat_compare_me" className="active">
          <td>
            <b>Mon œuvre</b>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myAvgSentencesPerParagraph}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myAvgWordsPerSentence}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myAvgCharactersPerWord}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myNames}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myAdjectives}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myVerbs}</span>
          </td>
          <td className="right aligned">
            <span>{statistics[0].myAdverbs}</span>
          </td>
        </tr>
      );
    }

    const stillLoading = !authorCompare || !authorList;

    let data = {};
    const options = {
      tooltips: {
        enabled: false
      }
    };
    if (!stillLoading) {
      const labels = ['Phrases par paragraphe', 'Mots par phrase', 'Caractères par mot',
        'Noms', 'Adjectifs', 'Verbes', 'Adverbes'];

      data = {
        labels: labels,
        datasets: [
          {
            label: 'Mon œuvre',
            backgroundColor: color('rgb(54, 162, 235)').alpha(0.2).rgbString(),
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            data: [
              normalizeAvgSentencesPerParagraph(authorCompare.myAvgSentencesPerParagraph),
              normalizeAvgWordsPerSentence(authorCompare.myAvgWordsPerSentence), normalizeAvgCharactersPerWord(authorCompare.myAvgCharactersPerWord),
              normalizeNames(authorCompare.myNames), normalizeAdjectives(authorCompare.myAdjectives), normalizeVerbs(authorCompare.myVerbs),
              normalizeAdverbs(authorCompare.myAdverbs)
            ]
          },
          {
            label: authorCompare.author,
            backgroundColor: color('rgb(255, 99, 132)').alpha(0.2).rgbString(),
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            data: [
              normalizeAvgSentencesPerParagraph(authorCompare.avgSentencesPerParagraph),
              normalizeAvgWordsPerSentence(authorCompare.avgWordsPerSentence), normalizeAvgCharactersPerWord(authorCompare.avgCharactersPerWord),
              normalizeNames(authorCompare.names), normalizeAdjectives(authorCompare.adjectives), normalizeVerbs(authorCompare.verbs),
              normalizeAdverbs(authorCompare.adverbs)
            ]
          }
        ]
      };
    }

    return (
      <div>
        {this.props.store.write.loading || !currentText || !statisticsCompareList || !authorCompare ?
          <Loader message="Chargement..." />
          :
          <div>
            {currentText && currentText.infos ?
              <Title title={`${currentText.infos.title} - Statistiques`} />
              : undefined}
            <div className="ui text container">
              <div className="ui info message">
                L'outil de comparaison vous permet de comparer le style de votre œuvre avec celui d'autres auteurs reconnus !
                <br />Les résultats de cet outil ne sont qu'indicatifs.
              </div>
            </div>
            <div className="ui hidden divider" />
            {currentText && currentText.infos ?
              <div className="ui grid centered stackable">
                <div className="fourteen wide column" style={{ paddingBottom: '80px' }}>
                  <div style={{ overflowX: 'scroll' }}>
                    <table className="ui center aligned celled unstackable teal table">
                      <thead>
                        <tr>
                          <th colSpan={8}>Liste des auteurs dont le style se rapproche le plus de {currentText.infos.title}</th>
                        </tr>
                        <tr>
                          <th rowSpan={2}>Auteur</th>
                          <th colSpan={3}>Longueur moyenne</th>
                          <th colSpan={4}>Catégorie de mots</th>
                        </tr>
                        <tr>
                          <th>Phrases par paragraphe</th>
                          <th>Mots par phrase</th>
                          <th>Caractères par mot</th>
                          <th>% Noms</th>
                          <th>% Adjectifs</th>
                          <th>% Verbes</th>
                          <th>% Adverbes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows}
                      </tbody>
                    </table>
                  </div>
                  <div className="ui hidden divider" />
                  {stillLoading ?
                    <Loader message="Calcul des statistiques en cours..." />
                    :
                    <div className="ui center aligned container">
                      <h4>Comparer avec</h4>
                      <Dropdown
                        selection
                        value={authorCompare.author}
                        onChange={(e: any, { value }) => {
                          const query = { author: value };
                          browserHistory.push({
                            pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/compare`, query: query
                          });
                        }}
                        options={authorList.map((a: string) => {
                          return { text: a, value: a };
                        })}
                      />
                      <div className="ui hidden divider" />
                      <Radar data={data} options={options} />
                    </div>
                  }
                </div>
              </div>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
