import { observable } from 'mobx';
import DefiTextModel from '../write/DefiTextModel';
import StructureModel from '../write/StructureModel';
import FullCanvasModel from '../canvas/FullCanvasModel';
import LastChapterModel from './LastChapterModel';
import ParcoursModel from '../parcours/ParcoursModel';
import UserModel from '../UserModel';

export default class InfosTextModel {
  @observable cover: string;
  @observable summary: string;
  @observable beginning: string;
  @observable datePublication: string;
  @observable sizePublic: number;
  @observable wordsPublic: number;
  @observable sizeAuthor: number;
  @observable wordsAuthor: number;
  @observable genres: number[];
  @observable tags: string[];
  @observable licence: number;
  @observable favorites: number;
  @observable defi: DefiTextModel;
  @observable structure: StructureModel;
  @observable canvas: FullCanvasModel;
  @observable parcours: ParcoursModel;

  @observable coverTemp: string;
  @observable coverFileTemp: File;

  @observable userFavorites: UserModel[];

  constructor(cover: string, summary: string, beginning: string, datePublication: string, sizePublic: number,
    wordsPublic: number, sizeAuthor: number, wordsAuthor: number, genres: number[], tags: string[], licence: number,
    favorites: number, defi: DefiTextModel, structure: StructureModel, canvas: FullCanvasModel,
    parcours: ParcoursModel, userFavorites: UserModel[]) {
    this.cover = cover;
    this.summary = summary;
    this.beginning = beginning;
    this.datePublication = datePublication;
    this.sizePublic = sizePublic;
    this.wordsPublic = wordsPublic;
    this.sizeAuthor = sizeAuthor;
    this.wordsAuthor = wordsAuthor;
    this.genres = genres;
    this.tags = tags;
    this.licence = licence;
    this.favorites = favorites;
    this.defi = defi;
    this.structure = structure;
    this.canvas = canvas;
    this.parcours = parcours;
    this.userFavorites = userFavorites || [];
  }

  setStructure(structure: StructureModel) {
    this.structure = structure;
  }

  toJS() {
    return {
      cover: this.cover,
      summary: this.summary,
      beginning: this.beginning,
      datePublication: this.datePublication,
      sizePublic: this.sizePublic,
      wordsPublic: this.wordsPublic,
      sizeAuthor: this.sizeAuthor,
      wordsAuthor: this.wordsAuthor,
      genres: this.genres,
      tags: this.tags,
      licence: this.licence,
      favorites: this.favorites,
      defi: this.defi.toJS(),
      structure: this.structure.toJS(),
      canvas: this.canvas.toJS(),
      parcours: this.parcours.toJS(),
      userFavorites: this.userFavorites.map(u => u.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const defi = DefiTextModel.fromJS(object.defi);
      const structure = StructureModel.fromJS(object.structure);
      const canvas = FullCanvasModel.fromJS(object.canvas);
      const parcours = ParcoursModel.fromJS(object.parcours);
      const userFavorites = object.userFavorites.map((u: any) => UserModel.fromJS(u));
      return new InfosTextModel(object.cover, object.summary, object.beginning, object.datePublication,
        object.sizePublic, object.wordsPublic, object.sizeAuthor, object.wordsAuthor,
        object.genres, object.tags, object.licence, object.favorites, defi,
        structure, canvas, parcours, userFavorites);
    }
    return undefined;
  }
}
