import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface ChapterReadProps {
  store?: Store;
}

@inject('store') @observer
export default class ChapterRead extends React.Component<ChapterReadProps, {}> {
  constructor(props: ChapterReadProps) {
    super(props);
    this.markAsRead = this.markAsRead.bind(this);
    this.markAsUnread = this.markAsUnread.bind(this);
  }
  markAsRead() {
    this.props.store.markChapterAsRead();
  }
  markAsUnread() {
    this.props.store.markChapterAsUnread();
  }
  render() {
    return (
      <div>
        {this.props.store.text.chapter.read ?
          <button className="ui icon active button" onClick={this.markAsUnread}
            data-tooltip="Marquer comme non-lu" data-inverted="" data-position="bottom center">
            <i className="fa fa-check fa-fw"></i> Lu
          </button>
          :
          <button className="ui icon button" onClick={this.markAsRead}
            data-tooltip="Marquer comme lu" data-inverted="" data-position="bottom center">
            Non lu
          </button>
        }
      </div>
    );
  }
}
