import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Title from '../../../../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../../../../stores/Store';
import Sidebar from '../../../../misc/Sidebar';
import PrologueModal from './PrologueModal';

interface PrologueConsignesProps {
  active: boolean;
  hide: Function | any;
  store?: Store;
}

@inject('store') @observer
class PrologueConsignes extends React.Component<PrologueConsignesProps, {}> {
  render() {
    const prologue = this.props.store.mainParcours.prologue;
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
        size="small"
      >
        <div className="header">{prologue.title}</div>
        <div className="content" dangerouslySetInnerHTML={{ __html: prologue.description }} />
        <div className="actions">
          <button className="ui button" onClick={this.props.hide}>
            Fermer
          </button>
        </div>
      </Modal>
    );
  }
}

interface PrologueToolbarProps {
  store?: Store;
  location: {
    state: {
      activeConsignes: boolean;
    };
  };
}

interface PrologueToolbarState {
  activeCreateText: boolean;
  activeConsignes: boolean;
}

@inject('store') @observer
export default class PrologueToolbar extends React.Component<PrologueToolbarProps, PrologueToolbarState> {
  constructor(props: PrologueToolbarProps) {
    super(props);
    this.createText = this.createText.bind(this);
    this.hideCreateText = this.hideCreateText.bind(this);
    this.showConsignes = this.showConsignes.bind(this);
    this.hideConsignes = this.hideConsignes.bind(this);
    this.next = this.next.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      activeCreateText: false,
      activeConsignes: false
    };
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        activeConsignes: this.props.location.state.activeConsignes
      });
    }
  }
  componentWillReceiveProps(nextProps: PrologueToolbarProps) {
    const nextLocState = nextProps.location.state;
    if (nextLocState && nextLocState.activeConsignes !== this.state.activeConsignes) {
      this.setState({
        activeConsignes: nextProps.location.state.activeConsignes
      });
    }
  }
  showConsignes() {
    this.setState({
      activeConsignes: true
    });
  }
  hideConsignes() {
    this.setState({
      activeConsignes: false
    });
  }
  createText() {
    this.setState({
      activeCreateText: true
    });
  }
  hideCreateText() {
    this.setState({
      activeCreateText: false
    });
  }
  next() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const prologue = this.props.store.mainParcours.prologue;
    const textHtml = this.props.store.mainParcours.prologue.getHTML();
    this.props.store.savePrologue(parcours.id, prologue.id, textHtml).then((r: any) => {
      this.props.store.nextPrologue(parcours.id, prologue.id).then((prologueId: number) => {
        browserHistory.push({
          pathname: `/write/parcours/${parcours.id}/prep/${prologueId}`,
          state: {
            activeConsignes: true
          }
        });
      });
    });
  }
  save() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const prologue = this.props.store.mainParcours.prologue;
    const textHtml = this.props.store.mainParcours.prologue.getHTML();
    this.props.store.savePrologue(parcours.id, prologue.id, textHtml);
  }
  render() {
    const mainParcours = this.props.store.mainParcours;
    const parcours = this.props.store.mainParcours.currentParcours;
    const prologue = this.props.store.mainParcours.prologue;

    let body: JSX.Element;
    if (parcours && prologue) {
      body = <div className="ui secondary menu text-bar" style={{ overflowX: 'scroll', overflow: 'auto' }}>
        <PrologueModal active={this.state.activeCreateText} hide={this.hideCreateText} />
        <PrologueConsignes active={this.state.activeConsignes} hide={this.hideConsignes} />
        <Title title={`Préparation du Parcours ${parcours.name} - Étape ${prologue.index}`} />
        <Sidebar writeText />
        <div className="item text-title" style={{ paddingLeft: '50px' }}>
          Préparation du Parcours {parcours.name} - Étape {prologue.index}
        </div>
        <div className="ui right item">
          <button className="ui basic button" onClick={this.showConsignes}>Consignes</button>
        </div>
        <div className="ui item">
          <button className="ui basic button" onClick={this.save}>Sauvegarder</button>
        </div>
        {prologue.text ?
          <div className="ui item">
            {prologue.chapterId ?
              <Link to={`/text/${prologue.text.id}/${prologue.text.urlText}/chapter/${prologue.chapterId}/edit`}
                className="ui basic button">
                Retour au chapitre
            </Link>
              :
              <Link to={`/text/${prologue.text.id}/${prologue.text.urlText}`} className="ui basic button">
                Retour à l'œuvre
            </Link>
            }
          </div>
          : undefined}
        {prologue.previous ?
          <div className="ui item">
            <Link className="ui basic button" to={`/write/parcours/${parcours.id}/prep/${prologue.previous}`}>
              Précédent
            </Link>
          </div>
          : undefined}
        {!prologue.last && !prologue.next ?
          <div className="ui item">
            <button className="ui green button" onClick={this.next}>Passer à l'étape suivante</button>
          </div>
          : undefined
        }
        {prologue.next ?
          <div className="ui item">
            <Link className="ui basic button" to={`/write/parcours/${parcours.id}/prep/${prologue.next}`}>
              Suivant
            </Link>
          </div>
          : undefined
        }
        {prologue.last && !prologue.text ?
          <div className="ui item">
            <button className="ui green button" onClick={this.createText}>Commencer le roman</button>
          </div>
          : undefined
        }
      </div>;
    } else {
      body = <div className="ui secondary container menu text-bar"
        style={{
          paddingLeft: '50px'
        }}
      />;
    }
    return body;
  }
}
