import * as React from 'react';
import { inject, observer } from 'mobx-react';
import HomeConnected from './HomeConnected';
import HomeNotConnectedAda from './HomeNotConnectedAda';
import HomePremium from './HomePremium';
import Store from '../../stores/Store';

interface HomeProps {
  store: Store;
  location: {
    query: {
      m: string;
    }
  };
}

@inject('store') @observer
export default class Home extends React.Component<HomeProps, {}> {
  static fetchData(store: Store) {
    if (store.isAuthenticated()) {
      return store.loadConnectedOnly().then((d: any) => {
        if (store.connected.premium.premiumUI) {
          if (store.connected.premium.pinnedText && store.write.currentText && store.write.currentText.infos && store.write.currentText.infos.parcours) {
            return store.loadTextInfos(store.connected.premium.pinnedText).then((d: any) => {
              const p: any[] = [];
              const parcoursId = store.write.currentText.infos.parcours.id;
              p.push(store.loadParcoursPrerequisites(parcoursId));
              p.push(store.loadParcoursProgress(parcoursId));
              p.push(store.loadParcoursTalks(parcoursId, [], true));
              p.push(store.loadCurrentWritingProgram());
              p.push(store.getCurrentPromotion());
              return Promise.all(p);
            });
          } else {
            const p: any[] = [];
            const parcoursId = 1;
            p.push(store.loadParcoursPrerequisites(parcoursId));
            p.push(store.loadParcoursProgress(parcoursId));
            p.push(store.loadParcoursTalks(parcoursId, [], true));
            p.push(store.loadCurrentWritingProgram());
            p.push(store.getCurrentPromotion());
            return Promise.all(p);
          }
        } else {
          const p: any[] = [];
          p.push(store.loadCurrentTexts());
          p.push(store.loadCurrentDefis());
          p.push(store.loadCurrentTalks());
          p.push(store.loadCurrentWritingProgram());
          p.push(store.loadCurrentParcours());
          p.push(store.loadLastListsTexts());
          p.push(store.getCurrentPromotion());
          return Promise.all(p);
        }
      });
    } else {
      if (store.areCookiesExpired() && typeof window !== "undefined") {
        window.location.href = "/auth/logout";
      }
      return store.loadHomeStats();
    }
  }
  render() {
    return this.props.store.isAuthenticated() ?
      (this.props.store.connected && this.props.store.connected.premium && this.props.store.connected.premium.premium
        && this.props.store.connected.premium.premiumUI ?
        <HomePremium 
          welcome={this.props.location.query.m && this.props.location.query.m === 'w'}
        />
        :
        <HomeConnected
          welcome={this.props.location.query.m && this.props.location.query.m === 'w'}
          newPremium={this.props.location.query.m && this.props.location.query.m === 'p'}
        />
      )
      : <HomeNotConnectedAda />;
  }
}
