import * as React from 'react';
import { Link } from 'react-router';
const Menu = require('react-burger-menu/lib/menus/slide');
const Radium = require('radium');

const RadiumLink = Radium(Link);

interface SidebarNotConnectedProps {
  writeText?: boolean;
}

export default class SidebarNotConnected extends React.Component<SidebarNotConnectedProps, {}> {
  render() {
    let styles = {};
    if (this.props.writeText) {
      styles = {
        bmBurgerBars: {
          background: '#000000'
        }
      };
    }
    return (
      <Menu
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        styles={styles}
        isOpen={false}
      >
        <div className="ui secondary reverse vertical inverted menu">
          <RadiumLink to="/">
            <img
              alt="Logo Ada"
              src="/logo-ada-blanc.png"
              className="ui image small centered"
              style={{ marginBottom: '20px' }}
            />
          </RadiumLink>
          <RadiumLink
            to="/"
            className="item"
          >
            Accueil
          </RadiumLink>
          <RadiumLink
            id="communaute"
            to="/communaute"
            className="item"
          >
            La communauté
          </RadiumLink>
          <RadiumLink
            id ="ecole"
            to="/ecole"
            className="item"
          >
            L'école d'écriture
          </RadiumLink>
        </div>
      </Menu>
    );
  }
}
