import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import PopupWarning from './PopupWarning';
import { getNegativeRegexp } from '../../../../../../utils/relecture/lisibilite';

function negativeStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  const regex = getNegativeRegexp();
  let matchArr: RegExpExecArray;
  let start: number;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const negativeSpan = (props: any) => (
  <PopupWarning
    body="Forme négative"
    spanClass="write-warning"
    children={props.children}
    />
);

const decorator = new CompositeDecorator([
  {
    strategy: negativeStrategy,
    component: negativeSpan,
  },
]);

export default decorator;
