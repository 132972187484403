import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import ChatUsers from './ChatUsers';
import ChatParams from './ChatParams';

interface ChatPageProps {
  store?: Store;
  params: ChatParams;
}
@inject('store') @observer
export default class ChatPage extends React.Component<ChatPageProps, {}> {
  static fetchData(store: Store, params: ChatParams) {
    return store.loadAllDiscussions(true);
  }
  render() {
    return (
      <div>
        <Title title="Messages " />
        <div className="ui two column grid stackable">
          <div className="four wide column grey">
            <div className="ui grid centered stackable">
              <div className="fourteen wide column" style={{ paddingTop: '40px' }}>
                <ChatUsers />
              </div>
            </div>
          </div>
          <div className="twelve wide column" style={{ backgroundColor: '#fff'}}>
            <div className="ui grid centered stackable">
              <div className="sixteen wide column" style={{ textAlign: 'left' }}>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
