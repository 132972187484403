import { observable } from 'mobx';
import VideoModel from '../write/VideoModel';
import PdfModel from '../write/PdfModel';

export default class ParcoursStatusModel {

  @observable parcoursName: string;
  @observable parcoursImage: string;
  @observable partName: string;
  @observable partType: number;
  @observable chapterTitle: string;
  @observable textId: number;
  @observable textTitle: string;
  @observable textUrl: string;
  @observable chapterId: number;
  @observable chapterInstructions: string;
  @observable chapterIndex: number;
  @observable chaptersCount: number;
  @observable partIndex: number;
  @observable partsCount: number;
  @observable videos: VideoModel[];
  @observable pdf: PdfModel[];
  @observable forceTraining: boolean;
  @observable parcoursPartType: number;
  @observable projectName: boolean;

  constructor(parcoursName: string, partType: number, parcoursImage: string, partName: string,
    chapterTitle: string, textId: number,
    textTitle: string, textUrl: string, chapterId: number, chapterInstructions: string,
    chapterIndex: number, chaptersCount: number, partIndex: number, partsCount: number,
    videos: VideoModel[], pdf: PdfModel[], forceTraining: boolean, parcoursPartType: number, projectName: boolean) {
    this.parcoursName = parcoursName;
    this.parcoursImage = parcoursImage;
    this.partName = partName;
    this.partType = partType;
    this.chapterTitle = chapterTitle;
    this.textId = textId;
    this.textTitle = textTitle;
    this.textUrl = textUrl;
    this.chapterId = chapterId;
    this.chapterInstructions = chapterInstructions;
    this.chapterIndex = chapterIndex;
    this.chaptersCount = chaptersCount;
    this.partIndex = partIndex;
    this.partsCount = partsCount;
    this.videos = videos;
    this.pdf = pdf;
    this.forceTraining = forceTraining;
    this.parcoursPartType = parcoursPartType;
    this.projectName = projectName;
  }

  toJS() {
    return {
      parcoursName: this.parcoursName,
      parcoursImage: this.parcoursImage,
      partName: this.partName,
      partType: this.partType,
      chapterTitle: this.chapterTitle,
      textId: this.textId,
      textTitle: this.textTitle,
      textUrl: this.textUrl,
      chapterId: this.chapterId,
      chapterInstructions: this.chapterInstructions,
      chapterIndex: this.chapterIndex,
      chaptersCount: this.chaptersCount,
      partIndex: this.partIndex,
      partsCount: this.partsCount,
      videos: this.videos.map(v => v.toJS()),
      pdf: this.pdf.map(p => p.toJS()),
      forceTraining: this.forceTraining,
      parcoursPartType: this.parcoursPartType,
      projectName: this.projectName
    };
  }

  static fromJS(object: any) {
    if (object) {
      let videos = [];
      if (object.videos)
        videos = object.videos.map((v: any) => VideoModel.fromJS(v));
      let pdf = [];
      if (object.pdf)
        pdf = object.pdf.map((p: any) => PdfModel.fromJS(p));
      return new ParcoursStatusModel(object.parcoursName, object.partType, object.parcoursImage, object.partName, object.chapterTitle,
        object.textId, object.textTitle, object.textUrl, object.chapterId, object.chapterInstructions,
        object.chapterIndex, object.chaptersCount, object.partIndex,
        object.partsCount, videos, pdf, object.forceTraining, object.parcoursPartType, object.projectName);
    }
    return undefined;
  }
}
