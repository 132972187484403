import * as React from 'react';
import Title from '../misc/Title';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Store from '../../stores/Store';
import ProfileParams from './ProfileParams';
import Loader from '../misc/Loader';

interface EditQuestionnaireProps {
  store?: Store;
  params: ProfileParams;
}

interface EditQuestionnaireState {
  error: boolean;
}

@inject('store') @observer
export default class EditQuestionnaire extends React.Component<EditQuestionnaireProps, EditQuestionnaireState> {
  constructor(props: EditQuestionnaireProps) {
    super(props);
    this.state = {
      error: false
    };
    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.postQuestionnaire = this.postQuestionnaire.bind(this);
  }
  static fetchData(store: Store, params: ProfileParams) {
    return store.loadQuestionnaire(params.authorId);
  }
  @action
  handleQuestionChange(answer: string, questionIndex: number) {
    const error = this.state.error && answer.trim().length === 0;
    this.setState({
      error: error
    });
    this.props.store.profile.questionnaire
      .filter(q => q.index === questionIndex)[0].answer = answer;
  }
  postQuestionnaire() {
    const error = this.props.store.profile.questionnaire
      .filter(q => q.answer.trim() === '')
      .length > 0;
    this.setState({
      error: error
    });
    if (!error) {
      this.props.store.saveQuestionnaire(this.props.params.authorId)
        .then((d: any) => {
          browserHistory.push({
            pathname: `/author/${this.props.params.authorId}/${this.props.params.urlIdentity}`
          });
        });
    }
  }
  render() {
    const profile = this.props.store.profile;
    const questions = profile.questionnaire.map(q =>
      <div key={q.index} className={'field' + (this.state.error ? ' error' : '')}>
        <label>
          <FormattedMessage
            id={`questionnaire.${q.index}`}
            defaultMessage="Question"
          />
        </label>
        <textarea rows={2} style={q.index === 0 ? { height: '7em' } : { height: '4em' }}
          onChange={(e: any) => this.handleQuestionChange(e.target.value, q.index)}
          value={q.answer}
        />
      </div>
    );
    return (
      <div className="ui read container" style={{ paddingBottom: '80px' }}>
        {profile.loading ?
          <Loader message="Chargement..." />
          :
          <div>
            <Title title="Questionnaire de l'Atelier des auteurs " />
            <div className="ui massive breadcrumb">
              <Link to={`/author/${this.props.params.authorId}/${this.props.params.urlIdentity}`}
                className="section">
                Mon profil
              </Link>
              <i className="fa fa-fw fa-chevron-right divider"></i>
              <div className="active section">
                Questionnaire de l'Atelier des auteurs
              </div>
            </div>
            <div className="ui basic segment">
              <form className="ui form" onSubmit={this.postQuestionnaire}>
                {this.state.error ?
                  <div className="ui error message">
                    <div className="header">
                      Vous devez répondre à toutes les questions pour valider le formulaire.
                    </div>
                  </div>
                  : undefined}
                {questions}
                <button className="ui right floated primary button" type="button"
                  onClick={this.postQuestionnaire}
                >
                  Enregistrer mes réponses
                </button>
              </form>
            </div>
          </div>
        }
      </div>
    );
  }
}
