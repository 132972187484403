import { observable } from 'mobx';
import StructurePartModel from './StructurePartModel';
import StructureChapterModel from './StructureChapterModel';

export default class StructureModel {

  @observable parts: StructurePartModel[];
  @observable chapters: StructureChapterModel[];

  constructor(parts: StructurePartModel[], chapters: StructureChapterModel[]) {
    this.parts = parts;
    this.chapters = chapters;
  }

  toJS() {
    return {
      parts: this.parts.map(p => p.toJS()),
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const parts = object.parts.map((p: any) => StructurePartModel.fromJS(p));
      const chapters = object.chapters.map((c: any) => StructureChapterModel.fromJS(c));
      return new StructureModel(parts, chapters);
    }
    return undefined;
  }
}
