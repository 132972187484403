import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
import { Pie } from 'react-chartjs-2';

interface PosProps {
  store?: Store;
  chapterId?: number;
}

@inject('store') @observer
export default class Pos extends React.Component<PosProps, {}> {
  render() {
    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsWrite;
    const filteredStatistics = statistics.filter(stat => stat.chapter.title !== "")
    const rows = statistics.map((statistic) => {
      if (statistic.stat && statistic.stat.posTagStatistics !== undefined && statistic.stat.posTagStatistics !== null && statistic.exists && statistic.chapter.title !== "") {
        const pos = statistic.stat.posTagStatistics;
        const total = pos.names + pos.adjectives + pos.verbs + pos.adverbs;
        return (
          <tr key={`stat_pos_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId == statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td className="right aligned">
              {total > 0 ? Math.round(pos.names / total * 100) : '-'}
            </td>
            <td className="right aligned">
              {total > 0 ? Math.round(pos.adjectives / total * 100) : '-'}
            </td>
            <td className="right aligned">
              {total > 0 ? Math.round(pos.verbs / total * 100) : '-'}
            </td>
            <td className="right aligned">
              {total > 0 ? Math.round(pos.adverbs / total * 100) : '-'}
            </td>
          </tr>
        )
      } else if (statistic.chapter.title !== "") {
        return (
          <tr key={`stat_pos_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId === statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td colSpan={4}>
              {statistic.exists ?
                <span><span className="ui small active inline loader" style={{ marginRight: '10px' }} /> Calcul en cours...</span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      }
      else {
        return(undefined)
      }
    });
    const stillLoading = statistics.filter(stat => (!stat.stat || !stat.stat.posTagStatistics) && stat.exists && stat.chapter.title !== "").length > 0;
    const withValues = statistics.filter(stat => stat.stat && stat.stat.posTagStatistics && stat.exists && stat.chapter.title !== "").length > 0;
    if (!stillLoading) {
      const filteredStats = statistics.filter(s => (s.exists && s.stat && s.stat.posTagStatistics && s.chapter.title !== "" && (s.stat.posTagStatistics.names + s.stat.posTagStatistics.adjectives + s.stat.posTagStatistics.verbs + s.stat.posTagStatistics.adverbs) > 0));
      if (filteredStats.length > 0) {
        const names = filteredStats.map(s => Math.round(s.stat.posTagStatistics.names / (s.stat.posTagStatistics.names +
          s.stat.posTagStatistics.adjectives + s.stat.posTagStatistics.verbs + s.stat.posTagStatistics.adverbs) * 100))
          .reduce((a, b) => a + b);
        const adjectives = filteredStats.map(s => Math.round(s.stat.posTagStatistics.adjectives / (s.stat.posTagStatistics.names +
          s.stat.posTagStatistics.adjectives + s.stat.posTagStatistics.verbs + s.stat.posTagStatistics.adverbs) * 100))
          .reduce((a, b) => a + b);
        const verbs = filteredStats.map(s => Math.round(s.stat.posTagStatistics.verbs / (s.stat.posTagStatistics.names +
          s.stat.posTagStatistics.adjectives + s.stat.posTagStatistics.verbs + s.stat.posTagStatistics.adverbs) * 100))
          .reduce((a, b) => a + b);
        const adverbs = filteredStats.map(s => Math.round(s.stat.posTagStatistics.adverbs / (s.stat.posTagStatistics.names +
          s.stat.posTagStatistics.adjectives + s.stat.posTagStatistics.verbs + s.stat.posTagStatistics.adverbs) * 100))
          .reduce((a, b) => a + b);
        rows.push(
          <tr key="stat_pos_total">
            <td><b>Moyenne</b></td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(names / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(adjectives / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(verbs / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(adverbs / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      } else {
        rows.push(
          <tr key="stat_pos_total">
            <td><b>Moyenne</b></td>
            <td colSpan={4}>
              -
            </td>
          </tr>
        );
      }
    }

    let data: {};
    const selectedChapter = this.props.chapterId ? this.props.chapterId : filteredStatistics[0].chapter.id;
    if (!stillLoading && withValues) {
      const labels = ['Noms', 'Adjectifs', 'Verbes', 'Adverbes'];
      const ft = filteredStatistics.filter(stat => stat.exists && stat.chapter.id == selectedChapter);
      if (ft.length > 0) {
        const pos = ft[0].stat.posTagStatistics;
        const total = pos.names + pos.adjectives + pos.verbs + pos.adverbs;
        const values = [
          total > 0 ? Math.round(pos.names / total * 100) : 0,
          total > 0 ? Math.round(pos.adjectives / total * 100) : 0,
          total > 0 ? Math.round(pos.verbs / total * 100) : 0,
          total > 0 ? Math.round(pos.adverbs / total * 100) : 0,
        ];
        data = {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(54, 162, 235)', 'rgb(75, 192, 192)'],
              data: values
            }
          ]
        };
      }
    }

    return (
      <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
        <div className="seven wide column" style={{ paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <table className="ui center aligned celled table">
                <thead>
                  <tr>
                    <th>-</th>
                    <th>% Noms</th>
                    <th>% Adjectifs</th>
                    <th>% Verbes</th>
                    <th>% Adverbes</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
              <div className="ui info message">
                <div className="header">Pourquoi cet indicateur est important</div>
                La répartition des mots composant un texte fait partie intégrante du style d’une œuvre.
              </div>
            </div>
          </div>
        </div>
        <div className="nine wide column">
          <div className="ui grid stackable">
            <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '45px' }}>
              {stillLoading ?
                <Loader message="Calcul des statistiques en cours..." />
                :
                <div>
                  <div className="ui center aligned container">
                    {data ?
                      <Pie data={data} />
                      : <div className="ui message">
                        Pas de graphique (chapitre vide)
                  </div>
                    }
                    <div style={{ marginTop: '20px' }}>
                      <Dropdown
                        selection
                        value={String(selectedChapter)}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/write`,
                            query: {
                              chapter: value,
                              stat: 'pos'
                            }
                          });
                        }}
                        options={filteredStatistics.map((stat) => {
                          return { text: `${stat.chapter.title.substring(0, 25)}`, value: String(stat.chapter.id) };
                        })}
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
