enum RelectureType {
  SentenceSize,
  ParagraphSize,
  Adverbs,
  Passive,
  Negative,
  Repetitions,
/*   Spellcheck,
 */  Punctuation,
  VerbeTernes
}
export default RelectureType;
