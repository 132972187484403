import { observable } from 'mobx';
import CurrentTextInfosModel from './CurrentTextInfosModel';
import ChapterModel from './ChapterModel';
import StructureModel from './StructureModel';
import StructureParcoursModel from './StructureParcoursModel';
import NoteModel from './NoteModel';
import CharacterModel from './CharacterModel';
import CharacterFullModel from './CharacterFullModel';
import DefiTextModel from './DefiTextModel';
import FullCanvasModel from '../canvas/FullCanvasModel';
import ParcoursModel from '../parcours/ParcoursModel';
import UserModel from '../UserModel';
import TextStatisticsWriteModel from './TextStatisticsWriteModel';
import TextStatisticsReadModel from './TextStatisticsReadModel';
import TextStatisticsCompareModel from './TextStatisticsCompareModel';

export default class CurrentTextModel {

  @observable id: number;
  @observable infos: CurrentTextInfosModel;
  @observable structure: StructureModel | StructureParcoursModel;
  @observable currentChapter: ChapterModel;
  @observable chaptersToPublish: number[];
  @observable notes: NoteModel[];
  @observable currentNote: NoteModel;
  @observable characters: CharacterModel[];
  @observable currentCharacter: CharacterFullModel;
  @observable statisticsWrite: TextStatisticsWriteModel[];
  @observable topWords: { exists: boolean, words: { word: string, count: number }[] };
  @observable statisticsRead: TextStatisticsReadModel[];
  @observable statisticsCompareList: TextStatisticsCompareModel[];
  @observable statisticsCompare: TextStatisticsCompareModel;
  @observable statisticsCompareAuthors: string[];

  constructor(id: number, infos: CurrentTextInfosModel, structure: StructureModel | StructureParcoursModel,
    currentChapter: ChapterModel, chaptersToPublish: number[], notes: NoteModel[], currentNote: NoteModel,
    characters: CharacterModel[], currentCharacter: CharacterFullModel, statisticsWrite: TextStatisticsWriteModel[],
    topWords: { exists: boolean, words: { word: string, count: number }[] }, statisticsRead: TextStatisticsReadModel[],
    statisticsCompareList: TextStatisticsCompareModel[], statisticsCompare: TextStatisticsCompareModel,
    statisticsCompareAuthors: string[]) {
    this.id = id;
    this.infos = infos;
    this.structure = structure;
    this.currentChapter = currentChapter;
    this.chaptersToPublish = chaptersToPublish;
    this.notes = notes || [];
    this.currentNote = currentNote;
    this.characters = characters || [];
    this.currentCharacter = currentCharacter;
    this.statisticsWrite = statisticsWrite;
    this.topWords = topWords;
    this.statisticsRead = statisticsRead;
    this.statisticsCompareList = statisticsCompareList;
    this.statisticsCompare = statisticsCompare;
    this.statisticsCompareAuthors = statisticsCompareAuthors;
  }

  init(id: number, infos: CurrentTextInfosModel) {
    this.id = id;
    this.infos = new CurrentTextInfosModel(infos.title, infos.published, infos.urlText,
      infos.defi ? new DefiTextModel(infos.defi.id, infos.defi.title, infos.defi.urlTitle, infos.defi.description,
        infos.defi.genre, new UserModel(infos.defi.author.id, infos.defi.author.avatarUrl,
          infos.defi.author.urlIdentity, infos.defi.author.identity, infos.defi.author.admin,
          infos.defi.author.twitter), infos.defi.defiType,
        infos.defi.deadline, infos.defi.active, infos.defi.createdAt) : undefined,
      infos.canvas ? new FullCanvasModel(infos.canvas.id, infos.canvas.title, infos.canvas.description,
        infos.canvas.image, infos.canvas.notes, infos.canvas.createdAt, infos.canvas.updatedAt, infos.canvas.structure,
        infos.canvas.characters, infos.canvas.currentCharacter) : undefined,
      infos.parcours ? new ParcoursModel(infos.parcours.id, infos.parcours.name, infos.parcours.description,
        infos.parcours.image, infos.parcours.active, undefined,  undefined,  undefined,  undefined,  undefined,  undefined,  undefined, 
        undefined,  undefined,  undefined,  undefined,  undefined,  undefined,  undefined,  undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        infos.parcours.summary, []) : undefined, undefined, undefined);
  }

  setStructure(structure: StructureModel | StructureParcoursModel) {
    this.structure = structure;
  }

  setCurrentChapter(c: ChapterModel) {
    this.currentChapter = new ChapterModel(c.id, c.index, c.title, c.part, c.textContent, true,
      c.status, c.step, c.parcoursChoiceId, c.instructions, c.partSummary, c.partInstruction, c.checks, c.generators, 
      c.lastChapter, c.size, c.words, c.time);
  }

  setChaptersToPublish(chaptersToPublish: number[]) {
    this.chaptersToPublish = chaptersToPublish;
  }

  setNotes(notes: NoteModel[]) {
    this.notes = notes;
  }

  setCurrentNote(currentNote: NoteModel) {
    this.currentNote = new NoteModel(currentNote.id, currentNote.title, currentNote.message,
      currentNote.updatedAt);
  }

  resetCurrentNote() {
    this.currentNote = undefined;
  }

  initNote() {
    this.currentNote = new NoteModel(undefined, '', '<div></div>', '');
  }

  setCharacters(characters: CharacterModel[], chapterIndex: number) {
    this.characters = characters.map(c => new CharacterModel(c.id, c.name, c.image,
      c.chapters, c.undeletable, c.chapters.indexOf(chapterIndex) !== -1));
  }

  setCurrentCharacter(currentCharacter: CharacterFullModel) {
    this.currentCharacter = new CharacterFullModel(currentCharacter.id, currentCharacter.name,
      currentCharacter.description, currentCharacter.image, currentCharacter.chapters, currentCharacter.undeletable);
  }

  setStatisticsWrite(statistics: TextStatisticsWriteModel[]) {
    this.statisticsWrite = statistics;
  }

  setTopWords(topWords: { exists: boolean, words: { word: string, count: number }[] }) {
    this.topWords = topWords;
  }

  setStatisticsRead(statistics: TextStatisticsReadModel[]) {
    this.statisticsRead = statistics;
  }

  setStatisticsCompareList(statistics: TextStatisticsCompareModel[]) {
    this.statisticsCompareList = statistics;
  }

  setStatisticsCompare(statistics: TextStatisticsCompareModel) {
    this.statisticsCompare = statistics;
  }

  setStatisticsCompareAuthors(authors: string[]) {
    this.statisticsCompareAuthors = authors;
  }

  toJS() {
    return {
      id: this.id,
      infos: this.infos.toJS(),
      structure: this.structure.toJS(),
      currentChapter: this.currentChapter.toJS(),
      chaptersToPublish: this.chaptersToPublish,
      notes: this.notes.map(n => n.toJS()),
      currentNote: this.currentNote.toJS(),
      characters: this.characters.map(n => n.toJS()),
      currentCharacter: this.currentCharacter.toJS(),
      statisticsWrite: this.statisticsWrite,
      topWords: this.topWords,
      statisticsRead: this.statisticsRead,
      statisticsCompareList: this.statisticsCompareList,
      statisticsCompare: this.statisticsCompare,
      statisticsCompareAuthors: this.statisticsCompareAuthors
    };
  }

  static fromJS(object: any) {
    if (object) {
      const infos = CurrentTextInfosModel.fromJS(object.infos);
      const structure = StructureModel.fromJS(object.structure);
      const currentChapter = ChapterModel.fromJS(object.currentChapter);
      const notes = object.notes.map((n: any) => NoteModel.fromJS(n));
      const currentNote = NoteModel.fromJS(object.currentNote);
      const characters = object.characters.map((n: any) => CharacterModel.fromJS(n));
      const currentCharacter = CharacterFullModel.fromJS(object.currentCharacter);
      return new CurrentTextModel(object.id, infos, structure, currentChapter,
        object.chaptersToPublish, notes, currentNote, characters, currentCharacter,
        object.statisticsWrite, object.topWords, object.statisticsRead,
        object.statisticsCompareList, object.statisticsCompare, object.statisticsCompareAuthors);
    }
    return undefined;
  }
}
