import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import PopupWarning from './PopupWarning';
import { getPassiveRegexp } from '../../../../../../utils/relecture/lisibilite';

function passiveStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  const regex = getPassiveRegexp();
  let matchArr: RegExpExecArray;
  let start: number;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const passiveSpan = (props: any) => (
  <PopupWarning
    body="Utilisation de la voix passive"
    spanClass="write-warning"
    children={props.children}
    />
);

const decorator = new CompositeDecorator([
  {
    strategy: passiveStrategy,
    component: passiveSpan,
  },
]);

export default decorator;
