import { observable } from 'mobx';
import SimpleNotificationModel from './SimpleNotificationModel';
import UserModel from './UserModel';
import SettingsPreferencesModel from './settings/SettingsPreferencesModel';
import BadgeModel from './BadgeModel';

class ConnectedPremiumModel {
  @observable premium: boolean;
  @observable premiumId: string;
  @observable premiumEnd: string;
  @observable premiumUI: boolean;
  @observable pinnedText: number;
  @observable pinnedPrologue: number;
  @observable init: boolean;
  @observable allInclusive: boolean;
  @observable marketIdentifier: number;
}

export default class ConnectedModel {

  @observable user: UserModel;
  @observable notifications: SimpleNotificationModel[];
  @observable notificationsCount: number;
  @observable messagesCount: number;
  @observable preferences: SettingsPreferencesModel;
  @observable active: boolean;
  @observable email: string;
  @observable newEmail: string;
  @observable blockedBy: number[];
  @observable alertAltruiste: boolean;
  @observable badges: BadgeModel[];
  @observable desktopNotifications: boolean;
  @observable newUser: boolean;
  @observable showNotifBanner: boolean;
  @observable premium: ConnectedPremiumModel;
  @observable containers: string[];

  @observable containersEdit: string[];
  @observable activeTab: number;

  constructor(user: UserModel,
    notifications: SimpleNotificationModel[], notificationsCount: number,
    messagesCount: number, preferences: SettingsPreferencesModel,
    active: boolean, email: string, newEmail: string, blockedBy: number[],
    activeTab: number, alertAltruiste: boolean, badges: BadgeModel[], desktopNotifications: boolean,
    newUser: boolean, showNotifBanner: boolean, premium: ConnectedPremiumModel,
    containers: string[]) {
    this.user = user;
    this.notifications = notifications || [];
    this.notificationsCount = notificationsCount;
    this.messagesCount = messagesCount;
    this.preferences = preferences;
    this.active = active;
    this.email = email;
    this.newEmail = newEmail;
    this.blockedBy = blockedBy || [];
    this.alertAltruiste = alertAltruiste;
    this.badges = badges;
    this.premium = premium;
    this.desktopNotifications = desktopNotifications;
    this.newUser = newUser;
    this.showNotifBanner = showNotifBanner;
    this.containers = containers;
    this.containersEdit = containers ? containers.slice() : [];
    this.activeTab = activeTab;
  }

  setConnected(user: UserModel, notificationsCount: number,
    messagesCount: number, preferences: SettingsPreferencesModel,
    active: boolean, activeTab: number, email: string, newEmail: string, blockedBy: number[],
    alertAltruiste: boolean, badges: BadgeModel[], desktopNotifications: boolean, newUser: boolean,
    showNotifBanner: boolean, premium: ConnectedPremiumModel, containers: string[]) {
    this.user = user;
    this.notifications = [];
    this.notificationsCount = notificationsCount;
    this.messagesCount = messagesCount;
    this.preferences = preferences ?
      new SettingsPreferencesModel(preferences.defaultSensibleContent, preferences.fontFamily,
        preferences.fontSize, preferences.fontSpaced, preferences.annotations, preferences.feedTab)
      : undefined;
    this.active = active;
    this.email = email;
    this.newEmail = newEmail;
    this.blockedBy = blockedBy || [];
    this.activeTab = activeTab ? activeTab : preferences ? preferences.feedTab : undefined;
    this.alertAltruiste = alertAltruiste;
    this.badges = badges;
    this.desktopNotifications = desktopNotifications;
    this.newUser = newUser;
    this.showNotifBanner = showNotifBanner;
    this.premium = premium;
    this.containers = containers;
    this.containersEdit = containers ? containers.slice() : [];
  }

  setNotifications(notifications: SimpleNotificationModel[]) {
    this.notifications = notifications;
  }

  increaseNotificationsCount() {
    this.notificationsCount = this.notificationsCount + 1;
  }

  makeActive() {
    this.active = true;
  }

  toJS() {
    return {
      user: this.user.toJS(),
      notifications: this.notifications,
      notificationsCount: this.notificationsCount,
      messagesCount: this.messagesCount,
      preferences: this.preferences.toJS(),
      active: this.active,
      email: this.email,
      newEmail: this.newEmail,
      blockedBy: this.blockedBy,
      activeTab: this.activeTab,
      alertAltruiste: this.alertAltruiste,
      badges: this.badges,
      desktopNotifications: this.desktopNotifications,
      newUser: this.newUser,
      showNotifBanner: this.showNotifBanner,
      premium: this.premium,
      containers: this.containers
    };
  }

  static fromJS(object: any) {
    if (object) {
      const notifications = object.notifications.map((n: any) => SimpleNotificationModel.fromJS(n));
      return new ConnectedModel(object.user,
        notifications, object.notificationsCount, object.messagesCount,
        SettingsPreferencesModel.fromJS(object.preferences), object.active,
        object.email, object.newEmail, object.blockedBy, object.activeTab,
        object.alertAltruiste, object.badges,
        object.desktopNotifications, object.newUser, object.showNotifBanner, object.premium, object.containers);
    }
    return undefined;
  }
}
