import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
const date = require('date-and-time');
const countdown = require('countdown');
countdown.setLabels(
  ' milliseconde| seconde| minute| heure| jour| semaine| mois| année| décennie| siècle| millénaire',
  ' millisecondes| secondes| minutes| heures| jours| semaines| mois| années| décennies| siècles| millénaires',
  ' et ',
  ', ',
  'maintenant');
import Store from '../../stores/Store';
import AlertModel from '../../models/AlertModel';
import ConfirmAbandonDefi from './ConfirmAbandonDefi';

interface DefiRepondantActiveProps {
  store?: Store;
}

interface DefiRepondantActiveState {
  countDown: string;
  confirmAbandonActive: boolean;
}

@inject('store') @observer
export default class DefiRepondantActive extends React.Component<DefiRepondantActiveProps, DefiRepondantActiveState> {
  _timer: any;
  constructor(props: DefiRepondantActiveProps) {
    super(props);
    this.startPolling = this.startPolling.bind(this);
    this.updateCountDown = this.updateCountDown.bind(this);
    this.abandonDefi = this.abandonDefi.bind(this);
    this.confirmAbandonDefi = this.confirmAbandonDefi.bind(this);
    this.hideConfirmAbandon = this.hideConfirmAbandon.bind(this);

    this.state = {
      countDown: undefined,
      confirmAbandonActive: false
    };
  }
  componentDidMount() {
    this.startPolling();
  }
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  startPolling() {
    const that = this;
    setTimeout(function () {
      that._timer = setInterval(that.updateCountDown.bind(self), 0);
    }, 1000);
  }
  updateCountDown() {
    const strDeadline = this.props.store.mainDefi.defi.myReponse.deadline + ' 23:59';
    const now: Date = new Date();
    const deadline: Date = date.parse(strDeadline, 'DD/MM/YYYY HH:mm');

    const countDown = countdown(now, deadline, undefined, 3).toString();

    this.setState({
      countDown: countDown
    });
  }
  abandonDefi() {
    this.setState({
      confirmAbandonActive: true
    });
  }
  hideConfirmAbandon() {
    this.setState({
      confirmAbandonActive: false
    });
  }
  confirmAbandonDefi() {
    this.props.store.abandonDefi().then((d: any) => {
      this.setState({
        confirmAbandonActive: false
      });
      this.props.store.addAlert(new AlertModel(
        'abandonDefi',
        'Défi abandonné !',
        '',
        'success',
        5
      ));
    });
  }
  render() {
    const defi = this.props.store.mainDefi.defi;
    const myReponse = defi.myReponse;
    return (
      <div className="ui stackable basic segment container">
        <ConfirmAbandonDefi
          active={this.state.confirmAbandonActive}
          confirm={this.confirmAbandonDefi}
          cancel={this.hideConfirmAbandon}
        />
        {this.state.countDown ?
          <div className="ui message warning">
            <h3>Il vous reste {this.state.countDown} pour publier votre réponse à ce défi.</h3>
            Vous avez commencé à y répondre avec l'œuvre <Link
              to={`/text/${myReponse.text.id}/${myReponse.text.urlText}/chapter/${myReponse.text.firstChapterId}/edit`}>
              {myReponse.text.title}
            </Link>.
        </div>
          : undefined}
        <div className="ui center aligned basic segment">
          <div className="ui buttons">
            <Link
              to={`/text/${myReponse.text.id}/${myReponse.text.urlText}/chapter/${myReponse.text.firstChapterId}/edit`}
              className="ui primary button"
            >
              Répondre au défi
            </Link>
            <button className="ui red button" onClick={this.abandonDefi}>Abandonner le défi</button>
          </div>
        </div>
      </div>
    );
  }
}
