import * as React from 'react';

interface ChapterShareProps {
  textTitle: string;
  chapterIndex?: number;
  type: string;
  vertical?: boolean;
}

interface ChapterShareState {
  enableComponents: boolean;
}

declare const __CLIENT__: any;

export default class ChapterShare extends React.Component<ChapterShareProps, ChapterShareState> {
  constructor(props: ChapterShareProps) {
    super(props);
    this.getUrl = this.getUrl.bind(this);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    this.setState({
      enableComponents: true
    });
  }
  getUrl() {
    return this.props.type === 'facebook' ?
      `https://facebook.com/sharer/sharer.php?u=${window.location.href}`
      : encodeURI(`https://twitter.com/intent/tweet/?text=J'aime "${this.props.textTitle}"${this.props.chapterIndex ? ' (Chapitre ' + this.props.chapterIndex + ')' : ''} sur @Atelier_Auteurs  ${window.location.href}`);
  }
  render() {
    const t = this.props.type;
    return (
      <a className={'ui ' + t + ' icon button'}
        data-tooltip={`Partager sur ${t.charAt(0).toUpperCase() + t.slice(1)}`}
        data-inverted="" data-position={this.props.vertical ? 'left center' : 'bottom center'}
        href={this.state.enableComponents ? this.getUrl() : ''}
        target="_blank" rel="noopener noreferrer" aria-label=""
        style={{ width: '37px' }}
      >
        <i className={'fa fa-' + t} />
      </a>
    );
  }
}
