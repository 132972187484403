import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Comment from './Comment';
import UserModel from '../../models/UserModel';
import CommentListModel from '../../models/comment/CommentListModel';

interface CommentListProps {
  store?: Store;
  memberId: number;
  readonly: boolean;
  newsfeedElement: boolean;
  admin: boolean;
  author: UserModel;
  comments: CommentListModel;
}

@inject('store') @observer
export default class CommentList extends React.Component<CommentListProps, {}> {
  render() {
    const that = this;
    let commentNodes: any = '';
    if (this.props.comments) {
      commentNodes = this.props.comments.comments.map(comment => (
        <Comment
          key={comment.id}
          comment={comment}
          commentChildren={comment.childs}
          author={that.props.author}
          memberId={that.props.memberId}
          authorText={that.props.comments.authorText}
          authorDefi={that.props.comments.authorDefi}
          readonly={that.props.readonly}
          newsfeedElement={that.props.newsfeedElement}
          admin={that.props.admin}
          comments={that.props.comments}
        />));
    }
    return (
      <div className="comments">
        {commentNodes}
      </div>
    );
  }
}
