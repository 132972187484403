import * as React from 'react';
import { Link } from 'react-router';
import TalkModel from '../../models/talk/TalkModel';

interface ProfileTalksProps {
  talks: TalkModel[];
}

export default class ProfileTalks extends React.Component<ProfileTalksProps, {}> {
  render() {
    const talks = this.props.talks.map((t) => {
      const textTags = t.tags.map((tag) => {
        return <Link
          to={encodeURI(`/talks?tag=${tag}`)}
          key={Math.random() * 1000}
          className="ui label"
        >
          {tag}
        </Link>;
      });

      const readLabel = t.read ?
        <span>lu</span>
        : <span style={{ color: '#F2711C' }}>non lu</span>;
      return (
        <div key={Math.random() * 1000} className="ui item">
          <div className="content">
            <Link
              to={`/talks/${t.id}/${t.urlTitle}`}
              className="header"
            >
              {t.title}
            </Link>
            <div className="description">
              {textTags}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="ui read container relaxed divided unstackable items" style={{ textAlign: 'left' }}>
        {talks}
      </div>
    );
  }
}
