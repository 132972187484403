import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
const TagCloud = require('react-tagcloud').TagCloud;

declare const __CLIENT__: any;

interface WordCloudProps {
  store?: Store;
  chapterId?: number;
}

interface WordCloudState {
  enableComponents: boolean;
}

@inject('store') @observer
export default class WordCloud extends React.Component<WordCloudProps, WordCloudState> {
  constructor(props: WordCloudProps) {
    super(props);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  render() {
    const currentText = this.props.store.write.currentText;
    const topWords = currentText.topWords;

    const stillLoading = topWords && topWords.exists && (!topWords.words || topWords.words.length === 0);
    const withValues = topWords && topWords.exists && topWords.words && topWords.words.length > 0;
    let data;
    if (!stillLoading && withValues) {
      data = topWords.words.map((w) => {
        return { value: w.word, count: w.count };
      });
    }

    const statistics = currentText.statisticsWrite;
    const filteredStatistics = statistics.filter(stat => stat.chapter.title !== "");
    const selectedChapter = this.props.chapterId ? this.props.chapterId : 'text';

    const customRenderer = (tag: { value: string }, size: string, color: string) => (
      <span key={tag.value}
        style={{
          fontSize: size,
          margin: '3px',
          padding: '3px',
          display: 'inline-block',
          color: color
        }}>{tag.value}</span>
    );

    const chaptersList = filteredStatistics.map((stat) => {
      return { text: `${stat.chapter.title.substring(0, 25)}`, value: String(stat.chapter.id) };
    });
    chaptersList.unshift({ text: 'Œuvre', value: 'text' });

    return (
      <div className="ui grid stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
        <div className="eight wide centered column" style={{ paddingTop: '45px' }}>
          {stillLoading ?
            <Loader message="Calcul des statistiques en cours..." />
            : <div className="ui center aligned container">
              {withValues && this.state.enableComponents ?
                <TagCloud
                  minSize={18}
                  maxSize={42}
                  tags={data}
                  renderer={customRenderer}
                />
                : <div className="ui message">
                  Pas de graphique (chapitre vide)
                  </div>
              }
              <div style={{ marginTop: '20px' }}>
                <Dropdown
                  selection
                  value={String(selectedChapter)}
                  onChange={(e: any, { value }) => {
                    const query = value === 'text' ?
                      { stat: 'topwords' }
                      : {
                        chapter: value,
                        stat: 'topwords'
                      };
                    browserHistory.push({
                      pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/write`,
                      query: query
                    });
                  }}
                  options={chaptersList}
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
