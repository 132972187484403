import * as React from 'react';
import Title from '../../../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';

import Structure from './Structure';
import TextDefi from './TextDefi';
import TextCanvas from './TextCanvas';
import TextStatisticsButton from './TextStatisticsButton';
import TextParcoursSummary from './TextParcoursSummary';
import NotePad from './NotePad';
import Store from '../../../../stores/Store';
import Sidebar from '../../../misc/Sidebar';
import ChapterParams from '../ChapterParams';

interface ClassicTextToolbarProps {
  store?: Store;
  params: ChapterParams;
  location: any;
}

interface ClassicTextToolbarState {
  cannotPublishText: boolean;
  showStructure: boolean;
  showStructureLoading: boolean;
  showChaptersToPublish: boolean;
  showRemove: boolean;
  showNotePad: boolean;
  showCharacters: boolean;
}

@inject('store') @observer
export default class ClassicTextToolbar extends React.Component<ClassicTextToolbarProps, ClassicTextToolbarState> {
  constructor(props: ClassicTextToolbarProps) {
    super(props);
    this.showStructure = this.showStructure.bind(this);
    this.hideStructure = this.hideStructure.bind(this);
    this.showNotes = this.showNotes.bind(this);
    this.hideNotes = this.hideNotes.bind(this);
    this.saveAndQuit = this.saveAndQuit.bind(this);

    this.state = {
      cannotPublishText: false,
      showStructure: false,
      showStructureLoading: false,
      showChaptersToPublish: false,
      showRemove: false,
      showNotePad: false,
      showCharacters: false
    };
  }
  componentWillReceiveProps(nextProps: ClassicTextToolbarProps) {
    if (nextProps.params.chapterId !== this.props.params.chapterId) {
      this.setState({
        showStructure: false
      });
    }
  }
  showStructure() {
    this.setState({
      showStructureLoading: true
    });
    this.props.store.saveChapter(false, false).then((d: any) => {
      this.props.store.loadTextStructure(this.props.params.textId).then((d: any) => {
        this.setState({
          showStructure: true,
          showStructureLoading: false,
          cannotPublishText: this.state.cannotPublishText,
          showChaptersToPublish: this.state.showChaptersToPublish,
          showRemove: this.state.showRemove,
          showNotePad: this.state.showNotePad,
          showCharacters: this.state.showCharacters
        });
      });
    });
  }
  hideStructure() {
    this.setState({
      showStructure: false
    });
  }
  showNotes() {
    const currentTextId = this.props.store.write.currentText.id;
    this.props.store.loadNotes(currentTextId).then((d: any) => {
      this.setState({
        showChaptersToPublish: this.state.showChaptersToPublish,
        showStructure: this.state.showStructure,
        cannotPublishText: this.state.cannotPublishText,
        showRemove: this.state.showRemove,
        showNotePad: true,
        showCharacters: this.state.showCharacters
      });
    });
  }
  hideNotes() {
    this.setState({
      showChaptersToPublish: this.state.showChaptersToPublish,
      showStructure: this.state.showStructure,
      cannotPublishText: this.state.cannotPublishText,
      showRemove: this.state.showRemove,
      showNotePad: false,
      showCharacters: this.state.showCharacters
    });
  }
  saveAndQuit() {
    const text = this.props.store.write.currentText;
    const infos = text.infos;
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;
    {premium && infos.parcours && infos.parcours.active ?
      browserHistory.push({
        pathname: `/parcours/${text.id}/${text.infos.urlText}`
      })
    :
      browserHistory.push({
        pathname: `/text/${text.id}/${text.infos.urlText}`
      });
    }
  }
  render() {
    const text = this.props.store.write.currentText;
    const infos = text.infos;
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;

    let body: JSX.Element;
    if (infos) {
      body = <div className="ui secondary menu text-bar" style={{ overflowX: 'scroll', overflow: 'auto', marginTop: "0px" }}>
        <Title title={`${infos.title} `} />
        {premium && infos.parcours && infos.parcours.active ?
        undefined: <Sidebar writeText />}
        <div className="item text-title" style={premium && infos.parcours && infos.parcours.active ? undefined : { paddingLeft: '50px' }}>
          {premium && infos.parcours && infos.parcours.active ?
            <Link to={`/parcours/${text.id}/${text.infos.urlText}`}>
              {text.infos.title}
            </Link>
          :
            <Link to={`/text/${text.id}/${text.infos.urlText}`}>
              {text.infos.title}
            </Link>
          }
          {infos.published ?
            <div className="ui green basic label text-status">
              Publié
          </div>
            :
            <div className="ui hide-mobile orange basic label">
              {premium && infos.parcours && infos.parcours.active ?
                <span>d'après le parcours <i>{infos.parcours.name}</i></span>
                : <span className="text-status">Non publié</span>
              }
            </div>
          }
        </div>
        <div className="ui right icon buttons item">
          {premium && !(infos.parcours && infos.parcours.active) ?
            <button
              onClick={() => this.showStructure()}
              className={'ui item basic text-structure' + (this.state.showStructureLoading ? ' loading' : '') + ' button'}
            >
              Structure
            </button>
          :
            undefined
          }
          {/* {premium && infos.parcours ?
            <TextParcoursSummary />
            : undefined
          } */}
          {premium && !(infos.parcours && infos.parcours.active) ?
            <TextStatisticsButton />
            : undefined
          }
          {premium && !(infos.parcours && infos.parcours.active) ?
            <button
              onClick={() => this.showNotes()}
              className="ui item basic button text-notes"
            >
              Notes
          </button>
            : undefined}
          {premium ?
            <NotePad textId={text.id} active={this.state.showNotePad} hide={this.hideNotes} />
            : undefined}
          {infos.defi ?
            <TextDefi />
            : undefined}
          {premium && infos.canvas ?
            <TextCanvas />
            : undefined}
          <Structure
            active={this.state.showStructure}
            hide={this.hideStructure}
            textId={text.id}
            urlText={text.infos.urlText}
            published={text.infos.published}
          />
          <button
            onClick={this.saveAndQuit}
            className="ui item icon basic button save-and-quit"
            title="Sauvegarder et quitter l'éditeur"
          >
            {premium && infos.parcours && infos.parcours.active ?
              <span>
                <span className="hide-tablet" style={{fontSize: "1.3rem"}}>Sauvegarder et quitter&nbsp;  </span>
                <i className="fa fa-15x fa-window-close-o" />
              </span>
            :
              <i className="fa fa-15x fa-window-close-o" />
            }
          </button>
        </div>
      </div>;
    } else {
      body = <div className="ui secondary container menu text-bar"
        style={{
          paddingLeft: '50px'
        }}
      />;
    }
    return body;
  }
}
