import { observable } from 'mobx';
import UserModel from '../UserModel';
import MessageModel from './MessageModel';

export default class DiscussionModel {

  @observable id: number;
  @observable users: UserModel[];
  @observable targetUsers: { value: string, label: string }[];
  @observable blocked: boolean;
  @observable creatorId: number;
  @observable count: number;
  @observable end: boolean;
  @observable messages: MessageModel[];
  @observable loading: boolean;

  constructor(id: number, users: UserModel[], targetUsers: { value: string, label: string }[],
    blocked: boolean, creatorId: number, count: number, end: boolean, messages: MessageModel[]) {
    this.id = id;
    this.users = users || [];
    this.targetUsers = targetUsers || [];
    this.blocked = blocked;
    this.creatorId = creatorId;
    this.count = count;
    this.end = end;
    this.messages = messages || [];
    this.loading = false;
  }

  setDiscussion(d: DiscussionModel, connectedId: number) {
    this.id = d.id;
    if (this.count === 0) {
      // New
      this.messages = d.messages.reverse();
      if (d.users) {
        this.users = d.users.map(u => new UserModel(u.id, u.avatarUrl,
          u.urlIdentity, u.identity, u.admin, u.twitter));
        const targetUsers = d.users.filter(u => u.id !== connectedId);
        this.targetUsers = targetUsers.map(u => {
          return {
            value: String(u.id),
            label: u.identity,
            clearableValue: targetUsers.length > 1
          };
        });
      }
      this.blocked = d.blocked;
      this.creatorId = d.creatorId;
      this.count = 1;
    } else {
      this.messages = this.messages.concat(d.messages.reverse());
      this.count = this.count + 1;
    }

    this.end = this.messages.length < 10;
  }

  resetDiscussion(targetUser?: UserModel) {
    this.id = undefined;
    this.users = [];
    if (targetUser) {
      this.targetUsers = [{
        value: String(targetUser.id), label: targetUser.identity
      }];
    } else {
      this.targetUsers = [];
    }
    this.blocked = false;
    this.creatorId = 0;
    this.count = 0;
    this.end = false;
    this.messages = [];
  }

  addMessage(m: MessageModel) {
    this.messages.unshift(new MessageModel(m.id, m.discussionId, m.user,
      m.message, m.date));
  }

  setUsers(users: UserModel[], connectedId: number) {
    this.users = users.map(u => new UserModel(u.id, u.avatarUrl,
      u.urlIdentity, u.identity, u.admin, u.twitter));
    const targetUsers = users.filter(u => u.id !== connectedId);
    this.targetUsers = targetUsers.map(u => {
      return {
        value: String(u.id),
        label: u.identity,
        clearableValue: targetUsers.length > 1
      };
    });
  }

  toJS() {
    return {
      id: this.id,
      users: this.users.map(u => u.toJS()),
      targetUsers: this.targetUsers,
      blocked: this.blocked,
      creatorId: this.creatorId,
      count: this.count,
      end: this.end,
      messages: this.messages.map(m => m.toJS()),
    };
  }

  static fromJS(object: any) {
    if (object) {
      const users = object.users.map((u: any) => UserModel.fromJS(u));
      const messages = object.messages.map((m: any) => MessageModel.fromJS(m));
      return new DiscussionModel(object.id, users, object.targetUsers, object.blocked,
        object.creatorId, object.count, object.end, messages);
    }
    return undefined;
  }
}
