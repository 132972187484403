import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface TextSummaryProps {
  store?: Store;
  strangerMode: boolean;
}

interface TextSummaryState {
  summaryEditMode: boolean;
  summaryEdit: string;
  remainingCharacters: number;
}

@inject('store') @observer
export default class TextSummary extends React.Component<TextSummaryProps, TextSummaryState> {
  constructor(props: TextSummaryProps) {
    super(props);
    this.startSummaryEdit = this.startSummaryEdit.bind(this);
    this.cancelSummaryEdit = this.cancelSummaryEdit.bind(this);
    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.saveSummaryEdit = this.saveSummaryEdit.bind(this);
    this.state = {
      summaryEditMode: false,
      summaryEdit: '',
      remainingCharacters: 3000
    };
  }
  startSummaryEdit() {
    const summary = this.props.store.text.infos.summary;
    const summaryEdit = summary ?
      this.props.store.text.infos.summary.replace(/<br>/g, '\n').replace(/<br\/>/g, '\n')
      : '';
    this.setState({
      summaryEditMode: true,
      summaryEdit: summaryEdit,
      remainingCharacters: 3000 - summaryEdit.length
    });
  }
  cancelSummaryEdit() {
    this.setState({
      summaryEditMode: false,
      summaryEdit: this.state.summaryEdit,
      remainingCharacters: 3000
    });
  }
  handleSummaryChange(e: any) {
    const summary = e.target.value;
    const remainingCharacters = 3000 - summary.length;
    this.setState({
      summaryEdit: summary,
      summaryEditMode: this.state.summaryEditMode,
      remainingCharacters: remainingCharacters
    });
  }
  saveSummaryEdit(e: any) {
    e.preventDefault();
    if (this.state.remainingCharacters >= 0) {
      this.props.store.saveTextSummary(this.state.summaryEdit).then((e: any) => {
        this.setState({
          summaryEditMode: false,
          summaryEdit: this.state.summaryEdit
        });
      }, (err: string) => {
        this.setState({
          summaryEditMode: false,
          summaryEdit: this.state.summaryEdit
        });
      });
    }
  }
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected;
    const isAuthor = text && text.meta && text.meta.author
      && connected && connected.user && connected.user.id
      && connected.user.id === text.meta.author.id;
    const hasSummary = text.infos.summary && text.infos.summary.trim().length > 0;
    return (
      <div>
        {isAuthor && !this.props.strangerMode && this.state.summaryEditMode ?
          <div className="ui basic segment" style={isAuthor ? { paddingBottom: '40px' } : {}}>
            <form
              className="ui form"
              onSubmit={this.saveSummaryEdit}
            >
              <div className={'field' + (this.state.remainingCharacters < 0 ? ' error' : '')}>
                <textarea
                  id="summary"
                  name="summary"
                  rows={12}
                  onChange={this.handleSummaryChange}
                  value={this.state.summaryEdit}
                />
                {this.state.remainingCharacters < 0 ?
                  <div className="ui small error message">{this.state.remainingCharacters} caractères</div>
                  : undefined}
              </div>
              <button
                type="button"
                className="ui mini right floated button"
                onClick={this.cancelSummaryEdit}
              >
                Annuler
            </button>
              <button
                className="ui blue right floated mini button"
                onClick={this.saveSummaryEdit}
                type="submit"
                disabled={this.state.remainingCharacters < 0}
              >
                Enregistrer
            </button>
              <a
                href="/blog/2016/10/27/comment-rediger-le-resume-et-la-presentation-de-votre-roman/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: text.meta.linkColor }}
              >
                Comment rédiger le résumé de votre roman
             </a>
            </form>
          </div>
          :
          <div className="ui basic segment">
            <div className="ui segment" style={isAuthor && !this.props.strangerMode ? { paddingBottom: '40px' } : {}}>
              {hasSummary ?
                <div
                  className="medium text"
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                  dangerouslySetInnerHTML={{ __html: text.infos.summary }} />
                :
                <div
                  className="text-summary medium text fade"
                  style={{ marginTop: '15px', marginBottom: '15px', maxHeight: '250px', overflowY: 'hidden' }}
                  dangerouslySetInnerHTML={{ __html: text.infos.beginning }} />
              }
              {isAuthor && !this.props.strangerMode ?
                <div>
                  {!hasSummary ?
                    <div className="ui message warning">
                      Ajoutez un texte de présentation pour inciter vos lecteurs à lire votre œuvre !
                      <br />
                      <a
                        href="/blog/2016/10/27/comment-rediger-le-resume-et-la-presentation-de-votre-roman/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Comment rédiger le résumé de votre roman
                      </a>
                    </div>
                    : undefined}
                  <button className="ui right floated orange small button" onClick={this.startSummaryEdit}>
                    {hasSummary ? 'Modifier le texte de présentation' : 'Ajouter un texte de présentation'}
                  </button>
                </div>
                : undefined}
            </div>
          </div>
        }
      </div>
    );
  }
}
