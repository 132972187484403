import * as React from 'react';
import { Link } from 'react-router';
import ReadingListModel from '../../models/readinglist/ReadingListModel';

interface ListCardProps {
  list: ReadingListModel;
}

export default class ListCard extends React.Component<ListCardProps, {}> {
  render() {
    const list = this.props.list;
    const withTexts: string = list.texts ? 'Avec ' + list.texts + '...' : 'Aucune œuvre';
    return (
      <div key={list.id} className="ui card">
        {list.cover ?
          <div className="image">
            <Link to={`/list/${list.id}/${list.urlName}`}>
              <div style={{
                backgroundImage: `url(${list.cover})`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                width: '100%',
                height: '200px'
              }}
              />
            </Link>
          </div>
          : undefined}
        <div className="content">
          <div className="header">
            <Link to={`/list/${list.id}/${list.urlName}`}>{list.name}</Link>
          </div>
          <div className="meta">
            <Link to={`/author/${list.user.id}/${list.user.urlIdentity}`}>{list.user.identity}</Link>
          </div>
          <div className="description">
            {withTexts}
          </div>
        </div>
      </div>
    );
  }
}
