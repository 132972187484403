import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';

interface SensibleContentModalProps {
  store?: Store;
}

@inject('store') @observer
export default class SensibleContentModal
  extends React.Component<SensibleContentModalProps, {}> {
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected && this.props.store.connected.user
      && this.props.store.connected.user.id;
    const sensibleContent = text.meta.sensibleContent;
    const defaultSensibleContent = connected && this.props.store.connected.preferences.defaultSensibleContent;

    const modalActive = sensibleContent && !defaultSensibleContent;
    return (
      <Modal
        open={modalActive}
        onClose={() => { } }
        >
        <div className="header">Œuvre non accessible</div>
        <div className="content">
          L'œuvre <b>{text.meta.title}</b> est classée comme <b>Contenu sensible</b>.
            {connected ?
            <p>Pour y accéder, cochez la case <b>Accéder aux œuvres avec un contenu sensible</b> dans
                      &nbsp;<Link to="/settings">Paramètres > Préférences</Link>.
              </p>
            :
            <p>
              Vous devez être membre de l'Atelier des auteurs pour y accéder.
              </p>
          }
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={() => history.back()}>Retour</button>
          {connected ?
            <Link className="ui primary button" to="/settings">Modifier mes préférences</Link>
            :
            <Link className="ui primary button" to="/signup">Inscription</Link>
          }
        </div>
      </Modal>
    );
  }
}
