import { observable } from 'mobx';
import DefiModel from './DefiModel';

export default class MainDefiModel {

  @observable defis: DefiModel[];
  @observable loading: boolean;
  @observable count: number;
  @observable end: boolean;
  @observable defi: DefiModel;
  @observable similar: DefiModel[];

  constructor(defis: DefiModel[], loading: boolean, count: number, end: boolean,
    defi: DefiModel, similar: DefiModel[]) {
    this.defis = defis || [];
    this.loading = loading;
    this.count = count;
    this.end = end;
    this.defi = defi;
    this.similar = similar || [];
  }

  setDefis(defis: DefiModel[]) {
    if (this.count === 0) {
      this.defis = defis;
    } else {
      this.defis = this.defis.concat(defis);
    }
    this.count = this.count + 1;
    this.end = (defis.length < 5);
    this.loading = false;
  }

  resetDefis() {
    this.defis = [];
    this.loading = true;
    this.count = 0;
    this.end = false;
  }

  setDefi(d: DefiModel) {
    this.defi = new DefiModel(d.id, d.title, d.urlTitle, d.description, d.genre,
      d.author, d.available, d.inprogress, d.nouveau, d.reponses, d.reponsesObj,
      [], d.comments, d.textExample, d.defiType, d.starUntil, d.myReponse, d.createdAt);
  }

  setSimilar(similar: DefiModel[]) {
    this.similar = similar.map(d =>
      new DefiModel(d.id, d.title, d.urlTitle, d.description, d.genre,
        d.author, d.available, d.inprogress, d.nouveau, d.reponses, d.reponsesObj,
        d.texts, d.comments, d.textExample, d.defiType, d.starUntil, d.myReponse, d.createdAt)
    );
  }

  toJS() {
    return {
      defis: this.defis.map(t => t.toJS()),
      count: this.count,
      end: this.end,
      defi: this.defi.toJS(),
      similar: this.similar.map(s => s.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const defis = object.defis.map((t: any) => DefiModel.fromJS(t));
      const similar = object.similar.map((t: any) => DefiModel.fromJS(t));
      return new MainDefiModel(defis, object.loading, object.count, object.end,
        DefiModel.fromJS(object.defi), similar);
    }
    return undefined;
  }
}
