import { observable } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import convertFromHtml from '../../components/editor/convertFromHtml';
import convertToHtml from '../../components/editor/convertToHtml';

export default class FullCanvasCharacterModel {

  @observable id: number;
  @observable name: string;
  @observable image: string;
  @observable imageFile: File;
  @observable description: string;
  @observable descriptionState: any;
  @observable nameError: boolean;

  constructor(id: number, name: string, image: string, description: string) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.description = description || '<div></div>';
    let contentState = convertFromHtml(this.description);
    this.descriptionState = EditorState.createWithContent(contentState);
    this.nameError = false;
  }

  updateName(name: string) {
    const nameError = this.nameError && name.trim().length === 0;
    this.name = name;
    this.nameError = nameError;
  }

  updateDescriptionState(descriptionState: any) {
    this.descriptionState = descriptionState;
    this.description = convertToHtml(this.descriptionState);
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      image: this.image,
      description: this.description
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new FullCanvasCharacterModel(object.id, object.name, object.image, object.description);
    }
    return undefined;
  }
}
