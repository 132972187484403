import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import ReadingListModel from '../../models/readinglist/ReadingListModel';
import Store from '../../stores/Store';

interface ReadingListFormProps {
  store?: Store;
  active: boolean;
  hideModal: Function;
  list?: ReadingListModel;
}

interface ReadingListFormState {
  name: string;
  nameError: boolean;
  pub: boolean;
  ordered: boolean;
}

@inject('store') @observer
export default class ReadingListForm extends React.Component<ReadingListFormProps, ReadingListFormState> {
  constructor(props: ReadingListFormProps) {
    super(props);
    const list = this.props.list;
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handePubChange = this.handePubChange.bind(this);
    this.handleOrderedChange = this.handleOrderedChange.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      name: list ? list.name : '',
      nameError: false,
      pub: list ? list.pub : true,
      ordered: list ? list.ordered : false
    };
  }
  componentWillReceiveProps(nextProps: ReadingListFormProps) {
    const list = this.props.list;
    this.setState({
      name: list ? list.name : '',
      nameError: false,
      pub: list ? list.pub : true,
      ordered: list ? list.ordered : false,
    });
  }
  handleNameChange(e: any) {
    const nameError = this.state.nameError && e.target.value.trim().length === 0;
    this.setState({
      name: e.target.value,
      nameError: nameError,
      pub: this.state.pub,
      ordered: this.state.ordered
    });
  }
  handePubChange() {
    this.setState({
      name: this.state.name,
      nameError: this.state.nameError,
      pub: !this.state.pub,
      ordered: this.state.ordered
    });
  }
  handleOrderedChange() {
    this.setState({
      name: this.state.name,
      nameError: this.state.nameError,
      pub: this.state.pub,
      ordered: !this.state.ordered
    });
  }
  save(event: any) {
    event.preventDefault();
    const nameError = this.state.name.trim().length === 0;
    if (!nameError) {
      if (this.props.list) {
        this.props.store.editReadingList(this.props.list.id, this.state.name, this.state.pub,
          this.state.ordered).then((d: any) => {
            this.props.hideModal();
          });
      } else {
        this.props.store.createReadingList(this.state.name, this.state.pub,
          this.state.ordered).then((d: any) => {
            this.props.hideModal();
          });
      }
    } else {
      this.setState({
        name: this.state.name,
        nameError: true,
        pub: this.state.pub,
        ordered: this.state.ordered
      });
    }
  }
  render() {
    const header = this.props.list
      ? `Modifier la liste "${this.props.list.name}"`
      : 'Nouvelle liste';
    return (
      <Modal open={this.props.active} size="small" onClose={() => { } }>
        <div className="header">{header}</div>
        <div className="content">
          <form onSubmit={this.save} className="ui form">
            <div className={'inline field' + (this.state.nameError ? ' error' : '')}>
              <label>Nom</label>
              <input type="text" placeholder="Nom"
                value={this.state.name} onChange={this.handleNameChange} />
            </div>
            <div className="inline fields">
              <label>Visibilité</label>
              <div className="field">
                <Checkbox
                  radio
                  checked={this.state.pub}
                  onClick={this.handePubChange}
                  label="Publique"
                  />
              </div>
              <div className="field">
                <Checkbox
                  radio
                  checked={!this.state.pub}
                  onClick={this.handePubChange}
                  label="Privée"
                  />
              </div>
            </div>
            <div className="inline fields">
              <label>Type de liste</label>
              <div className="field">
                <Checkbox
                  radio
                  checked={!this.state.ordered}
                  onClick={this.handleOrderedChange}
                  label="Classique"
                  />
              </div>
              <div className="field">
                <Checkbox
                  radio
                  checked={this.state.ordered}
                  onClick={this.handleOrderedChange}
                  label="Ordonnée"
                  />
              </div>
            </div>
          </form>
        </div>
        <div className="actions">
          <button className="ui primary submit button"
            onClick={this.save}
            type="submit"
            >
            Enregistrer
          </button>
          <button className="ui cancel button" onClick={() => this.props.hideModal()}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}
