import * as React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
const throttle = require('throttle-debounce').throttle;
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import MessageModel from '../../models/chat/MessageModel';
import MessageSocketModel from '../../models/chat/MessageSocketModel';
import TypingMessageSocketModel from '../../models/chat/TypingMessageSocketModel';
import ChatDiscussionPopup from './ChatDiscussionPopup';
import ChatDiscussionPanel from './ChatDiscussionPanel';
import ChatParams from './ChatParams';

declare const __CLIENT__: any;

interface ChatDiscussionProps {
  store?: Store;
  params: ChatParams;
  popup?: boolean;
  closePopup?: Function;
}

interface ChatDiscussionState {
  message: string;
  remainingCharacters: number;
}

@inject('store') @observer
export default class ChatDiscussion extends React.Component<ChatDiscussionProps, ChatDiscussionState> {
  static fetchData(store: Store, params: ChatParams) {
    return store.loadConnected().then((d: any) => {
      return store.loadDiscussion(params.discussionId, true);
    });
  }
  constructor(props: ChatDiscussionProps) {
    super(props);
    this.fetchUsers = throttle(1000, this.fetchUsers.bind(this));
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    // this.emitTypingMessage = throttle(1000, this.emitTypingMessage.bind(this));
    this.postMessage = this.postMessage.bind(this);
    this.enableNewMode = this.enableNewMode.bind(this);
    this.displayDiscussion = this.displayDiscussion.bind(this);
    this.state = {
      message: '',
      remainingCharacters: 5000
    };
  }
  fetchUsers(input: string, callback: Function) {
    this.props.store.getRelations(input).then((relations: any) => {
      const options = relations.map((r: any) => ({
        value: r.value,
        label: r.label,
      }));

      callback(null, { options });
    });
  }
  displayDiscussion(msg: MessageSocketModel) {
    if (msg.discussionId === this.props.store.chat.discussion.id
      && this.props.store.chat.allDiscussions.discussions.length > 0) {
      this.props.store.chat.discussion.addMessage(msg.message);
      this.props.store.makeDiscussionRead(msg.discussionId);
      const firstDiscussionInList = this.props.store.chat.allDiscussions.discussions[0];
      if (firstDiscussionInList && firstDiscussionInList.id !== msg.discussionId) {
        this.props.store.loadAllDiscussions(true);
      }
    }
  }
  // componentDidMount() {
  //   if (this.props.store.socket) {
  //     this.props.store.socket.on('new message ack', this.displayDiscussion);
  //   }
  // }
  // componentWillUnmount() {
  //   if (this.props.store.socket) {
  //     this.props.store.socket.removeEventListener('new message ack', this.displayDiscussion);
  //   }
  // }
  handleUsersChange(value: { label: string, value: string }[]) {
    this.props.store.updateDiscussionUsers(value.map(u => u.value)).then((u: any) => {
      const connectedId = this.props.store.connected.user.id;
      this.props.store.chat.discussion.setUsers(u, connectedId);
    });
  }
  handleTextChange(e: any) {
    const message = e.target.value;
    this.setState({
      message: message,
      remainingCharacters: 5000 - message.length
    });
    // this.emitTypingMessage();
  }
  // emitTypingMessage() {
  //   if (this.props.store.socket) {
  //     const user = this.props.store.connected.user;
  //     const typingMessageSocket = new TypingMessageSocketModel(
  //       this.props.store.chat.discussion.id,
  //       this.props.store.chat.discussion.users.filter(u => u.id !== user.id).map(u => u.id),
  //       { label: user.identity, value: user.id }
  //     );

  //     this.props.store.socket.emit('typing message', typingMessageSocket);
  //   }
  // }
  postMessage(event?: any) {
    if (event) {
      event.preventDefault();
    }
    if (this.state.remainingCharacters >= 0 && this.state.message.trim().length > 0) {
      this.props.store.sendMessage(this.state.message).then((m: MessageModel) => {
        // if (this.props.store.socket) {
        //   const messageSocket = new MessageSocketModel(
        //     this.props.store.chat.discussion.id,
        //     this.props.store.chat.discussion.users.map(u => u.id),
        //     m
        //   );

        //   this.props.store.socket.emit('new message', messageSocket);
        //   this.setState({
        //     message: ''
        //   });
        // } else {
        this.props.store.chat.discussion.addMessage(m);
        this.setState({
          message: ''
        });
        const firstDiscussionInList = this.props.store.chat.allDiscussions.discussions[0];
        if (firstDiscussionInList && firstDiscussionInList.id !== m.discussionId) {
          this.props.store.loadAllDiscussions(true);
        }
        // }
      });
    }
  }
  enableNewMode() {
    browserHistory.push({
      pathname: '/messages'
    });
  }
  render() {
    const discussion = this.props.store.chat.discussion;
    return (
      <div>
        {discussion.loading ?
          <Loader message="Chargement..." />
          :
          (this.props.popup ?
            <ChatDiscussionPopup
              message={this.state.message}
              postMessage={this.postMessage}
              handleTextChange={this.handleTextChange}
              closePopup={this.props.closePopup}
              remainingCharacters={this.state.remainingCharacters}
            />
            :
            <div className="ui basic comments segment">
              <ChatDiscussionPanel
                fetchUsers={this.fetchUsers}
                handleUsersChange={this.handleUsersChange}
                message={this.state.message}
                postMessage={this.postMessage}
                handleTextChange={this.handleTextChange}
                remainingCharacters={this.state.remainingCharacters}
              />
            </div>
          )
        }
      </div>
    );
  }
}
