import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../../stores/Store';

interface ChapterSavePremiumProps {
  store?: Store;
}

@inject('store') @observer
export default class ChapterSavePremium extends React.Component<ChapterSavePremiumProps, {}> {
  constructor(props: ChapterSavePremiumProps) {
    super(props);
    this.onSave = this.onSave.bind(this);
  }
  onSave() {
    this.props.store.saveChapter(true, false);
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    return (
      <button
        className={'ui ' + (chapter.saved ? ' active' : '') + ' button chapter-save'}
        onClick={this.onSave}
        title="Sauvegarder"
        data-tooltip="Sauvegarder" data-inverted="" data-position="top center"
        style={{marginRight: "5px"}}
        >
          {chapter.saved ?
            <span>Sauvegardé&nbsp;</span>
          :
            <span>Sauvegarder&nbsp;</span>
          }
        <div className="fa fa-fw fa-save" />
        {chapter.saved ?
          <div className="fa fa-fw fa-check" />
          : undefined}
      </button>
    );
  }
}
