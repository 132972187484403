import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import VolatileLogin from '../auth/VolatileLogin';
import Title from '../misc/Title';
const ReactPlayer = require('react-player');
const LazyLoad = require('react-lazy-load').default;
const Typist = require('react-typist').default;
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'
import AuthorBioModal from './AuthorBioModal';
import MagnifyModal from './MagnifyModal';
import ContactForm from '../misc/ContactForm';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import EditorsSlider from '../misc/EditorsSlider';
import AuthorsSlider from '../misc/AuthorsSlider';
import TestimonySlider from '../misc/TestimonySlider';
import NewsletterWidget from './NewsletterWidget';


interface HomeNotConnectedAdaProps {
  store?: Store;
}

interface HomeNotConnectedAdaState {
  typing: boolean;
  author: number;
  authorModalActive: boolean;
  contactFormModalActive: boolean;
  magnifyModalActive: boolean;
  imageUrl: string;
  imageAlt: string;
}

@inject('store') @observer
export default class HomeNotConnectedAda extends React.Component<HomeNotConnectedAdaProps, HomeNotConnectedAdaState> {
  constructor(props: HomeNotConnectedAdaProps) {
    super(props);
/*     this.restartTyping = this.restartTyping.bind(this);
 */    this.state = {
      typing: true,
      author: 0,
      authorModalActive: false,
      contactFormModalActive: false,
      magnifyModalActive: false,
      imageUrl: "",
      imageAlt: ""
    };
    this.hideMagnifyModal = this.hideMagnifyModal.bind(this);
    this.showMagnifyModal = this.showMagnifyModal.bind(this);
    this.hideAuthorModal = this.hideAuthorModal.bind(this);
    this.showAuthorModal = this.showAuthorModal.bind(this);
    this.hideContactFormModal = this.hideContactFormModal.bind(this);
    this.showContactFormModal = this.showContactFormModal.bind(this);
  }
  /* restartTyping() {
    this.setState({ typing: false }, () => {
      this.setState({ typing: true });
    });
  } */
  signUp() {
    browserHistory.push({
      pathname: '/signup'
    });
  }
  communaute() {
    browserHistory.push({
      pathname: '/communaute'
    });
  }
  school() {
    browserHistory.push({
      pathname: '/ecole'
    });
  }
  hideMagnifyModal() {
    this.setState({
      imageUrl: "",
      imageAlt: "",
      magnifyModalActive: false
    });
  }
  showMagnifyModal(imageUrl: string, imageAlt: string) {
    this.setState({
      imageUrl: imageUrl,
      imageAlt: imageAlt,
      magnifyModalActive: true
    });
  }
  hideAuthorModal() {
    this.setState({
      author: 0,
      authorModalActive: false
    });
  }
  showAuthorModal(author: number) {
    this.setState({
      author: author,
      authorModalActive: true
    });
  }
  hideContactFormModal() {
    this.setState({
      contactFormModalActive: false
    });
  }
  showContactFormModal() {
    this.setState({
      contactFormModalActive: true
    });
  }
 
  render() {
    const ratioAuthorDefi = this.props.store.home && this.props.store.home.homeStats
      && this.props.store.home.homeStats.ratioAuthorDefi ?
      this.props.store.home.homeStats.ratioAuthorDefi
      : 33;
    const texts = this.props.store.home && this.props.store.home.homeStats
      && this.props.store.home.homeStats.texts ?
      this.props.store.home.homeStats.texts
      : 16500;
    const metaProperties = [
      {
        name: 'description',
        content: 'L\'Atelier des auteurs est la plateforme d’écriture pensée pour vous. Trouvez l’inspiration, écrivez et progressez au sein d’une communauté bienveillante.'
      }
    ];
    return (
      <div style={{backgroundColor: '#fff'}}>
        <Title title="Pour les écrivains d'aujourd'hui et de demain" meta={metaProperties} scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://www.atelierdesauteurs.com',
            logo: {
              '@type': 'ImageObject',
              url: 'https://scribay.s3.amazonaws.com/assets/logo_square.png'
            }
          })
        }]} />
        {/* <NewsletterWidget/> */}
        <div className="ui" style={{ marginTop: '-10px' }}>
          <div className="not-connected-ada" style={{ paddingBottom: "50px"}}>
            <div className="ui container">
              <div style={{ color: "white", maxWidth: '500px' }}>
              {/* <img style={{marginTop: "135px"}} src="https://scribay.s3.amazonaws.com/assets/logo-ada.png"
                alt="ada-logo" width="78%" /> */}
                <h1 className="ada-museo-500" style={{lineHeight: "4.3rem",textTransform: "uppercase", paddingTop: "130px", fontSize: '4.2rem' }}>
                  Tout le monde a une histoire à raconter
                </h1>
                <h2 className="ada-museo-300" style={{ marginTop:"-13px", fontSize: '1.9rem' }}>L'Atelier des Auteurs est la</h2>
                <h2 className="ada-museo-300" style={{ marginTop:"-18px", fontSize: '1.9rem' }}>première plateforme d’écriture</h2>
                <h2 className="ada-museo-300" style={{ marginTop:"-18px", fontSize: '1.9rem' }}>issue du monde de l’édition.</h2>
                  <button
                    className="ui button ada-background editis-bleu-nuit" 
                    onClick={() => this.school()} 
                    style={{ cursor: 'pointer', color: "white"}}
                    id="nos_ateliers_decriture_home_banner"
                  >
                    <i className="fa fa-angle-right"></i> Nos ateliers d'écriture
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ui center aligned container">
          <h4 className="ui horizontal ada-divider divider  hide-tablet ada-museo-300">Une école d'écriture & une communauté</h4>
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Une école d'écriture & une communauté</h4>
          <div className="ui centered two column very relaxed grid stackable" style={{paddingBottom: '30px'}}>
            <div className="seven wide column">
              <div className="ui centered grid">
                <img style={{marginTop: "30px"}} src="https://scribay.s3.amazonaws.com/assets/PLUME.png"
                  alt="plume" width="35%" height="35%"/>
                  <div className="row ada-image-title-bottom ada-museo-300"><p>Les Ateliers</p></div>

                  <div className="row ada-image-subtitle" style={{width:"30%"}}><p> Une école d'écriture <br/>par les auteurs, pour les auteurs.</p></div>
                  {/* <button
                      className="ui button ada-background editis-bleu-nuit" 
                      onClick={() => this.school()} 
                      style={{ cursor: 'pointer', color: "white", marginTop: "5px"}}
                      id="decouvrir_les_ateliers_home_picto"
                    >
                      <i className="fa fa-angle-right"></i> Découvrir les ateliers
                    </button> */}
                </div>
            </div>
            <div className="seven wide column">
              <div className="ui centered grid">
              <img style={{marginTop: "30px"}} src="https://scribay.s3.amazonaws.com/assets/PICTO_PEOPLE.png"
                  alt="picto_people" width="35%" height="35%"/>
                  <div className="row ada-image-title-bottom ada-museo-300"><p>La communauté</p></div>

                  <div className="row ada-image-subtitle" style={{width:"30%"}}><p>70 000 passionnés qui échangent, publient <br/> et commentent leurs histoires et les vôtres.</p></div>
                  {/* <button
                      className="ui button ada-background editis-bleu-nuit" 
                      onClick={() => this.communaute()} 
                      style={{ cursor: 'pointer', color: "white"}}
                    >
                      <i className="fa fa-angle-right"></i> Découvrir la communauté
                    </button> */}
                </div>
            </div>
          </div>
        </div>    
        <div className="ada-background editis-beige-clair" style={{marginTop: "-15px", paddingBottom: "50px"}}>
          <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Tout le monde peut écrire un roman</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Tout le monde peut écrire un roman</h4>
            <div className="ui centered grid">
              <div className="row ada-image-subtitle" style={{marginTop: "-15px"}}><p>Nous avons travaillé avec des éditeurs, des auteurs et nos adhérents pour construire une méthode unique.</p></div>
              <div className="row ada-image-subtitle" style={{marginTop: "-5px"}}><p>Chacun son chemin d'écriture : découvrez le vôtre avec nos auteurs.</p></div>
            </div>
            {<AuthorsSlider showAuthorModal= {this.showAuthorModal}/>}
            {<AuthorBioModal active={this.state.authorModalActive} hide={this.hideAuthorModal} author={this.state.author}/>}
            
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Découvrez notre école d'écriture</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Découvrez notre école d'écriture</h4>
            <div className="ui centered grid" style={{marginTop: "-20px"}}>
              <div className="twelve wide column">
                <div style={{textAlign: "center"}} className="row ada-video-subtitle"><p>Comment ça marche ?</p></div>
                <div className="player-wrapper single-video-ada react-player">
                  <ReactPlayer
                    url="https://vimeo.com/726710635"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <div className="twelve wide column ">
                <div style={{textAlign: "center"}} className="row ada-video-subtitle"><p>Bande-annonce</p></div>
                <div className="player-wrapper  single-video-ada react-player">
                  <ReactPlayer
                    url="https://vimeo.com/699772909"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">L'Atelier des Auteurs est fait pour vous si vous souhaitez :</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">L'Atelier des Auteurs est fait pour vous si vous souhaitez :</h4>
            <div className="ui centered grid">
              <div className="fourteen wide column">
                <div className="ui relaxed bulleted list" style={{textAlign: "left", fontSize: "1.5rem"}}>
                  <div className="item">Commencer ou reprendre l'écriture d'un roman.</div>
                  <div className="item">Surmonter le syndrome de la page blanche.</div>
                  <div className="item">Lutter contre la procrastination.</div>
                  <div className="item">Apprendre à donner du corps à vos personnages, votre intrigue et votre style.</div>
                  <div className="item">Découvrir les conseils et témoignages d'auteurs renommés dans leurs genres respectifs. </div>
                  <div className="item">Adopter une méthode 100% en ligne qui vous permet d'avancer à votre rythme.</div>
                  <div className="item">Finir l'écriture d'un roman entier.</div>
                </div>
              </div>
              <button
                className="ui button ada-background editis-bleu" 
                onClick={() => this.school()} 
                style={{ marginTop: "25px", cursor: 'pointer', color: "white"}}
                id="decouvrir_nos_ateliers_home_middle"
              >
                <i className="fa fa-angle-right"></i> Découvrir nos ateliers
              </button>
            </div>
          </div>
        </div>
          <div className="ui center aligned container">
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Comment fonctionnent nos ateliers d’écriture ?</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Comment fonctionnent nos ateliers d’écriture ?</h4>
            <div className="ui centered grid">
              <div className="row ada-image-subtitle" style={{marginTop: "-15px"}}><p>Ce qui vous attend dans cette aventure.</p></div>
            </div>
          </div>
        <div className="ui vertical stripe segment">
          <div className="ui container middle aligned stackable grid">
            <div className="row">
              <div className="hide-mobile eight wide column">
                  <img style={{cursor: "zoom-in"}} className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/1_rythme_ecriture.png"
                    alt="Image 1" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/1_rythme_ecriture.png", "rythme_ecriture")}/>
              </div>
              <div className="seven wide right floated column">
                <h3 className="ui header">1. Adoptez une routine d’écriture</h3>
                <p>
                  Faites entrer l’écriture dans votre quotidien. Combien de mots souhaitez-vous écrire par jour ? 
                  Choisissez votre rythme d’écriture et lancez-vous !
                </p>
              </div>
              <div className="hide-large eight wide column">
                  <img style={{cursor: "zoom-in"}} className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/1_rythme_ecriture.png"
                    alt="rythme_ecriture" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/1_rythme_ecriture.png", "rythme_ecriture")}/>
              </div>
            </div>
            <div className="ui horizontal hidden divider" />
            <div className="row">
              <div className="seven wide column">
                <h3 className="ui header">2. Choisissez votre aventure !</h3>
                <p>
                  L’Atelier des Auteurs comprend un catalogue d’ateliers d’écriture mis à jour régulièrement ! 
                  Science-fiction & fantasy, autobiographie, polar, feel-good... Choisissez votre genre de prédilection !
                </p>
              </div>
              <div className="eight wide right floated column">
                  <img style={{cursor: "zoom-in"}} className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/2_selection_parcours.jpg"
                    alt="choose_your_adventure" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/2_selection_parcours.jpg", "choose_your_adventure")}/>
              </div>
            </div>
            <div className="ui horizontal hidden divider" />
            <div className="row">
              <div className="eight wide column">
                  <img style={{cursor: "zoom-in"}} className="hide-mobile ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/3_commencer_ecrire.jpg"
                    alt="Image 9" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/3_commencer_ecrire.jpg", "demarrez_ecriture")}/>
              </div>
              <div className="seven wide right floated column">
                <h3 className="ui header">3. Démarrez l’écriture de votre roman</h3>
                <p>
                  Des écrivains vous accueillent et partagent avec vous des consignes, des conseils et des témoignages. 
                  Il n’existe pas une seule méthode : nos ateliers sont conçus de façon à ce que vous découvriez la vôtre.
                </p>
              </div>
              <div className="eight wide column">
                  <img style={{cursor: "zoom-in"}} className="hide-large ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/3_commencer_ecrire.jpg"
                    alt="demarrez_ecriture" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/3_commencer_ecrire.jpg", "demarrez_ecriture")}/>
              </div>
            </div>
            <div className="ui horizontal hidden divider" />
            <div className="row">
              <div className="seven wide column">
                <h3 className="ui header">4. Écrivez. Relisez. Maîtrisez.</h3>
                <p>
                  Écrivez directement votre roman dans notre éditeur en ligne. Vous venez d’achever votre premier jet ? 
                  Utilisez nos outils de relecture et suivez les conseils de nos auteurs pour peaufiner votre roman.
                </p>
              </div>
              <div className="eight wide right floated column">
                  <img style={{cursor: "zoom-in"}} className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/4_ecriture.png"
                    alt="ecrivez_relisez_maitrisez" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/4_ecriture.png", "ecrivez_relisez_maitrisez")}/>
              </div>
            </div>
            <div className="ui horizontal hidden divider" />
            <div className="row">
              <div className="eight wide column">
                  <img style={{cursor: "zoom-in"}} className="hide-mobile ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/5_retours.jpg"
                    alt="Image 9" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/5_retours.jpg", "communaute_70000")}/>
              </div>
              <div className="seven wide right floated column">
                <h3 className="ui header">5. Une communauté de 70 000 membres</h3>
                <p>
                  Vous cherchez un premier public ? Vous avez la possibilité de partager votre roman au sein de la communauté.
                </p>
              </div>
              <div className="eight wide column">
                  <img className="hide-large ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/5_retours.jpg"
                    alt="communaute_70000" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/5_retours.jpg", "communaute_70000")}/>
              </div>
            </div>

            <div className="ui horizontal hidden divider" />
            <div className="row">
              <div className="seven wide column">
                <h3 className="ui header">6. Et après ?</h3>
                <p>
                  Une fois votre roman achevé, vous êtes libre de sa destinée ! 
                  Si vous souhaitez vous engager dans sa publication, n’oubliez pas de suivre nos conseils de professionnels avant de le soumettre à des maisons d’édition.
                </p>
              </div>
              <div className="eight wide right floated column">
                  <img style={{cursor: "zoom-in"}} className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/home/6_editeur.jpg"
                    alt="editeur" width="100%" onClick={() => this.showMagnifyModal("https://scribay.s3.amazonaws.com/home/6_editeur.jpg", "editeur")}/>
              </div>
            </div>
          </div>
          <div className="ui horizontal hidden divider" />

          <div className="ui center aligned container">

          <div className="ui info message">
            <i className="close icon"></i>
            <div className="header">
              Vos textes vous appartiennent !
            </div>
            <p>L’Atelier des Auteurs ne revendique aucune propriété sur vos écrits.
               Vous avez la possibilité de garder cachés vos récits ou de les diffuser une fois qu’ils sont achevés !</p>
          </div>
          </div>
          {<MagnifyModal active={this.state.magnifyModalActive} hide={this.hideMagnifyModal} url={this.state.imageUrl} alt={this.state.imageAlt}/>}
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            {<EditorsSlider/>}
        </div>
        <div className="ada-background editis-beige-clair" style={{paddingBottom: "35px", paddingTop: "35px"}}>
          <div className="ui center aligned container">
              {<TestimonySlider/>}
          </div>
          <div className="ui center aligned container" >
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>Pourquoi avoir créé l'Atelier des Auteurs ?</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>Pourquoi avoir créé l'Atelier des Auteurs ?</h4>
            <div className="ui centered grid">
              <div className="ui hide-mobile nine wide column">
                <div className="player-wrapper single-video-ada react-player">
                  <ReactPlayer
                    url="https://vimeo.com/702054726"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              <div className="ui hide-large sixteen wide column">
                <div className="player-wrapper single-video-ada react-player">
                  <ReactPlayer
                    url="https://vimeo.com/702054726"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ui center aligned container"  style={{paddingTop: "20px"}}>
          <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>L'aventure vous attend</h4>
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>L'aventure vous attend</h4>
          <p>Vous avez une idée de roman en tête ? Une envie créatrice qui persiste depuis des années ?</p>
          <p>Nos ateliers d'écriture vous permettront de donner naissance à votre histoire, de la faire grandir et de l'enrichir.</p>
            <button
              className="ui button ada-background editis-bleu" 
              onClick={() => this.school()} 
              style={{ marginTop: "25px", cursor: 'pointer', color: "white"}}
              id="decouvrir_nos_ateliers_home_foot"
            >
              <i className="fa fa-angle-right"></i> Découvrir nos ateliers
            </button>
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "50px", paddingTop: "20px"}}>
          <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
          <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300" style={{marginBottom: "15px"}}>Vous avez une question ?</h4>
          <p>Vous souhaitez plus d'informations sur l'Atelier des Auteurs ?</p>
          <p>Envoyez-nous un message et nous vous répondrons au plus vite !</p>
          {<Modal
            open={this.state.contactFormModalActive}
            onClose={this.hideContactFormModal}
          >
            <div className="header">Contactez-nous</div>
            <div className="content" style={{maxHeight: '400px' }}>
              <ContactForm location="home"/>
            </div>
            <div className="actions">
              <button className="ui cancel button" onClick={this.hideContactFormModal}>Fermer</button>
            </div>
          </Modal>}
          <button
            className="ui button ada-background editis-bleu" 
            onClick={() => this.showContactFormModal()} 
            style={{ marginTop: "25px", cursor: 'pointer', color: "white"}}
          >
            Contactez-nous
          </button>
        </div>
        
      </div>
    );
  }
}
