import * as React from 'react';

import { inject, observer } from 'mobx-react';
import Relations from './Relations';
import Store from '../../stores/Store';
import ProfileParams from './ProfileParams';

interface ProfileSocialProps {
  store: Store;
  params: ProfileParams;
}

@inject('store') @observer
export default class ProfileSocial extends React.Component<ProfileSocialProps, {}> {
  static fetchData(store: Store, params: ProfileParams) {
    return store.loadProfileSocial(params.authorId);
  }
  render() {
    const profile = this.props.store.profile;
    return (
      <div className="ui container stackable grid two column">
        <Relations
          name="Abonnés"
          count={profile.countAbonnes}
          users={profile.abonnes}
          seeAll={`/author/${profile.id}/${this.props.params.urlIdentity}/social/abonnes`}
        />
        <Relations
          name="Abonnements"
          count={profile.countAbonnements}
          users={profile.abonnements}
          seeAll={`/author/${profile.id}/${this.props.params.urlIdentity}/social/abonnements`}
        />
      </div>
    );
  }
}
