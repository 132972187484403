import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';
const Select = require('semantic-ui-react/dist/commonjs/addons/Select').default;

interface ProfileUserSuspendProps {
  store?: Store;
}

interface ProfileUserSuspendState {
  active: boolean;
  weeks: number;
}

@inject('store') @observer
export default class ProfileUserSuspend extends React.Component<ProfileUserSuspendProps, ProfileUserSuspendState> {
  constructor(props: ProfileUserSuspendProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setDuration = this.setDuration.bind(this);
    this.suspend = this.suspend.bind(this);
    this.unsuspend = this.unsuspend.bind(this);
    this.state = {
      active: false,
      weeks: 1
    };
  }
  showModal() {
    this.setState({
      active: true
    });
  }
  hideModal() {
    this.setState({
      active: false
    });
  }
  setDuration(weeks: number) {
    this.setState({ weeks });
  }
  suspend() {
    const profileId = this.props.store.profile.id;
    this.props.store.suspendUser(profileId, this.state.weeks).then((d: any) => {
      this.hideModal();
    });
  }
  unsuspend() {
    const profileId = this.props.store.profile.id;
    this.props.store.unsuspendUser(profileId);
  }
  render() {
    const suspended = this.props.store.profile.user.suspended;
    return (
      <span>
        <Modal
          open={this.state.active}
          onClose={() => { } }
          >
          <div className="header">Confirmation</div>
          <div className="content">
            Confirmez-vous la suspension de cet utilisateur ?
            <div>
              Durée : <Select
                compact
                value={this.state.weeks}
                options={[{ key: 1, value: 1, text: '1 semaine' }, { key: 2, value: 2, text: '2 semaines' },
                { key: 3, value: 3, text: '3 semaines' }, { key: 4, value: 4, text: '4 semaines' }]}
                onChange={(e: any, { value }: any) => {
                  this.setDuration(value);
                }}
              />
            </div>
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.suspend}
              >
              Confirmer
          </button>
            <button className="ui cancel button" onClick={this.hideModal}>Annuler</button>
          </div>
        </Modal>
        {suspended ?
          <button className="ui small active button" onClick={this.unsuspend}>
            <i className="fa fa-remove fa-fw"></i> Lever la suspension
          </button>
          :
          <button className="ui small button" onClick={this.showModal}>
            <i className="fa fa-remove fa-fw"></i> Suspendre l'utilisateur
          </button>
        }
      </span>
    );
  }
}
