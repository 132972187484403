import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
const ReactPlayer = require('react-player');
import NewDraftModel from '../../../models/write/NewDraftModel';
import ParcoursModel from '../../../models/parcours/ParcoursModel';
import ParcoursSummaryContentModel from '../../../models/parcours/ParcoursSummaryContentModel';
import ParcoursSummary from './ParcoursSummary';
import Store from '../../../stores/Store';

interface ParcoursModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  passFirstSlide?: boolean;
}

interface ParcoursModalState {
  showPrologue: boolean;
}

@inject('store') @observer
export default class ParcoursModal
  extends React.Component<ParcoursModalProps, ParcoursModalState> {
  constructor(props: ParcoursModalProps) {
    super(props);
/*     this.previous = this.previous.bind(this);
 */    /* this.next = this.next.bind(this); */
    this.goToPremiumHomePage = this.goToPremiumHomePage.bind(this);
    /* this.startPrologue = this.startPrologue.bind(this); */
    this.hide = this.hide.bind(this);

    this.state = {
      showPrologue: false
    };
  }
  /* startPrologue() {
    const parcours = this.props.store.mainParcours.currentParcours;
    this.props.store.startPrologue(parcours.id).then((prologueId: number) => {
      browserHistory.push({
        pathname: `/write/parcours/${parcours.id}/prep/${prologueId}`,
        state: {
          activeConsignes: true
        }
      });
    });
  }
  previous() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const currentVideo = this.props.store.mainParcours.currentParcours.currentVideo;

    if (currentVideo) {
      const indexCurrentVideo = parcours.videos.map(v => v.id).indexOf(currentVideo.id);
      if (indexCurrentVideo > 0) {
        parcours.currentVideo = parcours.videos[indexCurrentVideo - 1];
      } else {
        parcours.currentVideo = undefined;
      }
    } else if (this.state.showPrologue) {
      if (parcours.videos && parcours.videos.length > 0) {
        parcours.currentVideo = parcours.videos[parcours.videos.length - 1];
      } else {
        parcours.currentVideo = undefined;
      }
      this.setState({
        showPrologue: false
      });
    }
  } */
  /* next() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const currentVideo = this.props.store.mainParcours.currentParcours.currentVideo;

    if (!currentVideo && !this.state.showPrologue) {
      if (parcours.videos.length > 0) {
        parcours.currentVideo = parcours.videos[0];
      }
    } else if (currentVideo) {
      const indexCurrentVideo = parcours.videos.map(v => v.id).indexOf(currentVideo.id);
      if (parcours.videos[indexCurrentVideo + 1]) {
        parcours.currentVideo = parcours.videos[indexCurrentVideo + 1];
      } else {
        parcours.currentVideo = undefined;
        this.setState({
          showPrologue: true
        });
      }
    }
  } */
  goToPremiumHomePage() {
    if (!this.props.store.connected.premium.premiumUI )
      this.props.store.switchUI().then((p: any) => {
        browserHistory.push({
          pathname: '/'
        });
      });
    else
      browserHistory.push({
        pathname: '/'
      });
  }
  hide() {
    this.setState({
      showPrologue: false
    });
    this.props.hide();
  }
  render() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const previous = parcours && (this.state.showPrologue || (parcours.currentVideo && !this.props.passFirstSlide));
    const next = parcours && !this.state.showPrologue && !this.props.passFirstSlide;

    /* let video: JSX.Element;

    if (parcours && parcours.currentVideo) {
      video =
        <div>
          <h3>{parcours.currentVideo.name}</h3>
          <ReactPlayer
            url={parcours.currentVideo.url}
            playing
            width="100%"
          />
          <div className="ui message">
            Retrouvez des vidéos et des indications supplémentaires en cliquant sur le bouton <b>Consignes</b> depuis votre éditeur de texte.
          </div>
        </div>;
    } */
    const prologue = (
      <div>
        <p>Avant de débuter le parcours narratif, nous vous proposons trois exercices préparatoires destinés à vous échauffer, vous stimuler et explorer votre univers.</p>
        <p>Vous pouvez relever ces exercices en cinq lignes comme en cinq pages. Ils ne seront pas intégrés dans votre œuvre finale. Le plus important est d’imaginer et raconter des histoires à partir des consignes qui vous seront données.</p>
        <p>Avant de vous lancer, nous avons déjà trois conseils pour vous :
        <ul>
            <li>Écrivez d’une traite vos premiers jets.</li>
            <li>Ne vous souciez pas des regards extérieurs ou d’un éventuel public.</li>
            <li>Détendez-vous et amusez-vous !</li>
          </ul>
        </p>
        <p><b>Si vous ressentez un blocage</b>, vous pouvez remettre ces exercices à plus tard et débuter le parcours narratif sans les terminer. Vous pouvez également nous écrire ou prendre un <b>rendez-vous téléphonique</b> (par message privé) avec nous si vous le souhaitez.</p>
        <p>Bonne séance d’écriture !</p>
      </div>
    );

    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Parcours - {parcours ? '"' + parcours.name + '"' : undefined}</div>
          <div className="content" style={{ paddingBottom: '50px' }}>
          {parcours ?
            <div dangerouslySetInnerHTML={{ __html: parcours.description }} />
            : undefined}
            {/* {parcours ?
              <div>
                {parcours.currentVideo ?
                  video
                  : (this.state.showPrologue ?
                    prologue
                    : (this.props.passFirstSlide ?
                      prologue
                      : <div dangerouslySetInnerHTML={{ __html: parcours.description }} />
                    )
                  )
                }
              </div>
              : undefined
            } */}
          </div>
          <div className="actions">
            <button className="ui primary button"
              onClick={() => this.goToPremiumHomePage()}>
              En savoir plus
            </button>
            {/* {previous ?
              <button className="ui primary button" onClick={this.previous}>Précédent</button>
              : undefined
            }
            {next ?
              (!parcours.currentVideo
                && !this.state.showPrologue && !this.props.passFirstSlide ?
                <button className="ui primary button"
                  onClick={() => this.goToPremiumHomePage()}>
                  En savoir plus
              </button>
                :
                <button className="ui primary button"
                  onClick={this.next}
                >
                  {(this.props.passFirstSlide || (parcours.currentVideo && (parcours.videos[parcours.videos.map(v => v.id)
                    .indexOf(parcours.currentVideo.id) + 1]) || !parcours.currentVideo)) ? 'Suivant' : 'Commencer le parcours'}
                </button>
              )
              : <button className="ui primary button" onClick={this.startPrologue}>
                Commencer la préparation du roman
                </button>
            }
            <button className="ui cancel button" onClick={this.hide}>Annuler</button> */}
          </div>
        </Modal>
      </div>
    );
  }
}
