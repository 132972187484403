import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import ListCarousel from '../readinglist/ListCarousel';

interface AllReadingListsPageProps {
  store?: Store;
}

@inject('store') @observer
export default class AllReadingListsPage extends React.Component<AllReadingListsPageProps, {}> {
  static fetchData(store: Store) {
    return store.loadReadingLists();
  }
  render() {
    const lists = this.props.store.mainReadingList.lists;
    return (
      <div className="ui grid" style={{ backgroundColor: '#fff', minHeight: '90vh' }}>
        <div className="ui text container" style={{ paddingTop: '50px', paddingBottom: '60px'}}>
          <div>
            <Title title="Toutes mes listes " />
            <h1 className="ui large header">
              Toutes mes listes
              <div className="sub header">
                Les listes de lecture vous permettent de suivre plus facilement des œuvres.
                <br />Créez des listes et ajoutez-y des œuvres à partir des pages Œuvre ou Chapitre.
              </div>
            </h1>
            <div className="ui basic segment">
              {lists.length === 0 ?
                <div className="ui message">Aucune liste de lecture.</div>
                : <ListCarousel lists={lists} />
              }
            </div>
          </div>
        </div >
      </div>
    );
  }
}
