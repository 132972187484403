import { observable, computed, action } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
const Entity = DraftJs.Entity;
const convertFromHTML = require('draft-convert').convertFromHTML;
const convertToHTML = require('draft-convert').convertToHTML;
const throttle = require('throttle-debounce').throttle;
import { getWords, countWords, getTime } from '../../utils/utils';
import decorators from '../../components/write/text/chapter/editor/decorators/RelectureDecorators';
import AnnotationDecorator from '../../components/write/text/chapter/editor/decorators/AnnotationDecorator';
import RelectureModel from './RelectureModel';
import RelectureType from './RelectureType';
import InstructionsModel from './InstructionsModel';
import ParcoursSummaryModel from '../parcours/ParcoursSummaryModel';
import CheckModel from './CheckModel';
import allRelectures from './AllRelectures';
import { convertHtmlToText, avgWordsPerSentence }
  from '../../utils/relecture/lisibilite';
const MultiDecorator = require('draft-js-multidecorators');
import blocksToHTML from '../../components/editor/blocksToHTML';
import styleToHTML from '../../components/editor/styleToHTML';
import htmlToStyle from '../../components/editor/htmlToStyle';
import htmlToBlock from '../../components/editor/htmlToBlock';

export default class ChapterModel {

  @observable id: number;
  @observable index: number;
  @observable title: string;
  @observable part: string;
  @observable textContent: string;
  @observable textContentState: any;
  @observable saved: boolean;
  @observable status: string;
  @observable step: number;
  @observable parcoursChoiceId: number;
  @observable instructions: InstructionsModel;
  @observable partSummary: ParcoursSummaryModel;
  @observable partInstruction: string;
  @observable checks: CheckModel[];
  @observable generators: {
    generator: string,
    comp: string
  }[];
  @observable lastChapter: boolean;

  @observable size: number;
  @observable words: number;
  @observable distinctLemmes: number;
  @observable time: number;

  // Relecture
  @observable relectureMode: boolean;
  @observable relectureSteps: { r: RelectureModel, warning: boolean }[];
  @observable relectureStep: RelectureType;
  @observable relectureLoading: boolean;

  @observable inited: boolean;

  constructor(id: number, index: number, title: string, part: string, textContent: string, saved: boolean,
    status: string, step: number, parcoursChoiceId: number, instructions: InstructionsModel, partSummary: ParcoursSummaryModel,
    partInstruction: string, checks: CheckModel[], generators: { generator: string, comp: string }[], lastChapter: boolean, size: number, 
    words: number, time: number) {
    this.id = id;
    this.index = index;
    this.title = title;
    this.part = part;
    this.textContent = textContent;
    this.saved = saved;
    this.status = status;
    this.step = step;
    this.parcoursChoiceId = parcoursChoiceId;
    this.instructions = instructions;
    this.partSummary = partSummary;
    this.partInstruction = partInstruction;
    this.checks = checks;
    this.generators = generators;
    this.lastChapter = lastChapter;
    this.size = size;
    this.words = words;
    this.time = time;
    this.updateStatsAndRelecture = throttle(1500, this.updateStatsAndRelecture);

    this.relectureMode = false;
    this.relectureSteps = [];
    this.relectureStep = undefined;
    this.relectureLoading = false;

    this.inited = false;
  }

  init() {
    this.relectureMode = false;
    this.relectureSteps = [];
    this.relectureStep = undefined;

    let contentState = convertFromHTML({
      htmlToBlock: htmlToBlock,
      htmlToEntity: (nodeName: string, node: any) => {
        if (nodeName === 'annotation') {
          return Entity.create(
            'ANNOTATION',
            'SEGMENTED',
            { id: node.id }
          );
        }
      }
    })(this.textContent);
    const textContentState = EditorState.createWithContent(contentState, AnnotationDecorator);
    this.textContentState = textContentState;
    this.updateStatistics(textContentState);
  }

  setTitle(title: string) {
    this.title = title;
  }

  setTextContent(textContent: string) {
    this.textContent = textContent;
  }

  setTextContentState(textContentState: any) {
    this.textContentState = textContentState;
  }

  setSaved(saved: boolean) {
    this.saved = saved;
  }

  setStatus(status: string) {
    this.status = status;
  }

  setStatistics(size: number, words: number, time: number) {
    this.size = size;
    this.words = words;
    this.time = time;
  }

  updateTitle(title: string) {
    this.setTitle(title);
    this.setStatus(this.status !== 'NEVER_PUBLISHED' ? 'INPROGRESS' : 'NEVER_PUBLISHED');
    this.setSaved(false);
  }

  updateTextContentState(newTextContentState: any) {
    let changed: boolean;
    if (this.status === 'PUBLISHED') {
      const oldHtml = this.textContent;
      this.convertToHTML();
      changed = this.textContent.trim().replace(/(\r\n|\n|\r)/gm, '').replace(/>\s+</g, '><')
        !== oldHtml.trim().replace(/(\r\n|\n|\r)/gm, '').replace(/>\s+</g, '><');
    } else {
      changed = this.textContentState !== newTextContentState;
    }

    this.setTextContentState(newTextContentState);

    if (changed) {
      this.setStatus(this.status !== 'NEVER_PUBLISHED' ? 'INPROGRESS' : 'NEVER_PUBLISHED');
      this.setSaved(false);
      this.updateStatsAndRelecture();
    }
  }

  updateStatsAndRelecture() {
    this.updateStatistics(this.textContentState);

    // Update relecture
    if (this.relectureMode) {
      // update relectures array
      const text = convertHtmlToText(this.textContent);
      const newSteps = this.relectureSteps.map((rs) => {
        if (rs.r.name === this.relectureStep) {
          if (rs.r.fn) {
            rs.warning = rs.r.fn(text);
          }
        }
        return rs;
      });
      this.relectureSteps = newSteps;
    }
  }

  getStatistics(text: string) {
    const size = text.length;
    const words = countWords(text);
    const time = getTime(words);

    return { size, words, time };
  }

  updateStatistics(textContentState: any) {
    const text = textContentState.getCurrentContent().getPlainText('');
    const { size, words, time } = this.getStatistics(text);

    if (words !== this.words) {
      this.setStatistics(size, words, time);
    }
  }

  @computed get timeString() {
    const time = this.time;
    if (time === 0) {
      return 'Moins d\'une minute de lecture';
    } else if (time === 1) {
      return 'Une minute de lecture';
    }
    return `${time} minutes de lecture`;
  }

  @computed get chapterTooLong() {
    return this.time > 6;
  }

  @action
  enableRelectureMode() {
    const text = convertHtmlToText(this.textContent);

    const newSteps: { r: RelectureModel, warning: boolean }[] = [];

    allRelectures.forEach((r: any) => {
      newSteps.push({ r: r, warning: r.fn ? r.fn(text) : undefined });
    });

    this.relectureMode = true;
    this.relectureSteps = newSteps;

    const firstStepWithWarning = newSteps.filter(s => s.warning)[0];
    this.relectureStep = firstStepWithWarning ? firstStepWithWarning.r.name : newSteps[0].r.name;
    const selectedDecorators = decorators.filter(d => d.name === this.relectureStep)[0].dec;
    const multiDecorator = selectedDecorators.length === 0 ?
      selectedDecorators[0]
      : new MultiDecorator(selectedDecorators);

    let contentState = convertFromHTML({
      htmlToStyle: htmlToStyle,
      htmlToBlock: htmlToBlock
    })(this.textContent);
    const textContentState = EditorState.createWithContent(
      contentState,
      multiDecorator);
    this.textContentState = textContentState;
  }

  @action
  disableRelectureMode() {
    this.relectureMode = false;
    this.relectureSteps = [];
    this.relectureStep = undefined;

    const textContentState = EditorState.createWithContent(
      this.textContentState.getCurrentContent(),
      AnnotationDecorator);
    this.textContentState = textContentState;
  }

  @action convertToHTML() {
    const textContent = convertToHTML({
      styleToHTML: styleToHTML,
      blockToHTML: blocksToHTML,
      entityToHTML: (entity: any, originalText: any) => {
        if (entity.type === 'ANNOTATION') {
          return `<annotation id="${entity.data.id}">${originalText}</annotation>`;
        } else {
          return originalText;
        }
      }
    })(this.textContentState.getCurrentContent());
    this.textContent = textContent;
  }

  @action
  changeRelectureItem(step: RelectureType) {
    this.relectureStep = allRelectures.filter(a => a.name === step)[0].name;
    const md = decorators.filter(d => d.name === this.relectureStep)[0];
    if (md.onSelect) {
      this.relectureLoading = true;
      const text = this.textContentState.getCurrentContent().getPlainText('');
      md.onSelect(text).then((d: any) => {
        this.updateDecorator(md.dec);
        this.relectureLoading = false;
      });
    } else {
      this.updateDecorator(md.dec);
    }
  }

  updateDecorator(selectedDecorators: any[]) {
    const multiDecorator = new MultiDecorator(selectedDecorators);

    const textContentState = EditorState.createWithContent(
      this.textContentState.getCurrentContent(),
      multiDecorator);
    this.textContentState = textContentState;
  }

  toJS() {
    return {
    };
  }

  static fromJS(object: any) {
    if (object) {
      const checks = object.checks ? object.checks.map((c: any) => CheckModel.fromJS(c)) : [];
      const instructions = InstructionsModel.fromJS(object.instructions);
      const partSummary = object.partSummary ? ParcoursSummaryModel.fromJS(object.partSummary) : undefined;
      return new ChapterModel(object.id, object.index, object.title, object.part, object.textContent,
        object.saved, object.status, object.step, object.parcoursChoiceId, instructions, partSummary,
        object.partInstruction, checks, object.generators, object.lastChapter, object.size, object.words, object.time);
    }
    return undefined;
  }

}
