import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../../../stores/Store';

interface ChapterRemoveProps {
  store?: Store;
  active: boolean;
  canRemove: boolean;
  open: Function | any;
  cancel: Function | any;
  confirm: Function | any;
}

@inject('store') @observer
export default class ChapterRemove extends React.Component<ChapterRemoveProps, {}> {
  render() {
    const text = this.props.store.write.currentText;
    const body = this.props.canRemove ?
      (<div>Confirmez-vous la suppression de ce chapitre ?</div>)
      : (
        <div>
          {text.infos.published ?
            <div>
              Cette œuvre est publiée. Vous devez conserver au moins un chapitre publié.
              <br />Si vous souhaitez supprimer l'œuvre entière, rendez-vous sur la <Link to={`/text/${text.id}/${text.infos.urlText}`}>page de l'œuvre</Link>.
        </div>
            : <div>Vous devez conserver au moins un chapitre dans cette œuvre.
          <br />Si vous souhaitez supprimer l'œuvre entière, rendez-vous sur la <Link to={`/text/${text.id}/${text.infos.urlText}`}>page de l'œuvre</Link>.</div>
          }
        </div>
      );
    return (
      <button
        className="ui button chapter-remove"
        onClick={this.props.open}
        title="Supprimer"
        data-tooltip="Supprimer" data-inverted="" data-position="top center">
        <i className="fa fa-fw fa-remove" />
        <Modal
          open={this.props.active}
          onClose={() => { }}
          size="small"
        >
          <div className="header">{this.props.canRemove ? 'Suppression du chapitre' : 'Suppression impossible'}</div>
          <div className="content">
            {body}
          </div>
          <div className="actions">
            {this.props.canRemove ?
              <button
                className="ui red button" onClick={this.props.confirm}
              >
                Supprimer définitivement le chapitre
            </button>
              : undefined}
            <button className="ui button" onClick={this.props.cancel}>
              {this.props.canRemove ? 'Annuler' : 'Fermer'}
            </button>
          </div>
        </Modal>
      </button>
    );
  }
}
