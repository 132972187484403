import { observable } from 'mobx';
import NotificationModel from './NotificationModel';

export default class NotificationsListModel {
  @observable id: number;
  @observable day: number;
  @observable month: string;
  @observable notifications: NotificationModel[];

  constructor(id: number, day: number, month: string, notifications: NotificationModel[]) {
    this.id = id;
    this.day = day;
    this.month = month;
    this.notifications = notifications || [];
  }

  toJS() {
    return {
      id: this.id,
      day: this.day,
      month: this.month,
      notifications: this.notifications.map(n => n.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const notifs = object.notifications.map((n: any) => NotificationModel.fromJS(n));
      return new NotificationsListModel(object.id, object.day, object.month, notifs);
    }
    return undefined;
  }
}
