import * as React from 'react';
import PopupAnnotation from './PopupAnnotation';
const CompositeDecorator = require('draft-js').CompositeDecorator;
const Entity = require('draft-js').Entity;
import Store from '../../../../../../stores/Store';

function annotationStrategy(contentBlock: any, callback: any) {
  contentBlock.findEntityRanges(
    (character: any) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        Entity.get(entityKey).getType() === 'ANNOTATION'
      );
    },
    callback
  );
}

interface AnnotationDecoratorProps {
  body: string;
  spanClass: string;
  children: any;
  text: string;
  data: any;
  store?: Store;
}

const annotationSpan = (props: any) => {
  const { id } = Entity.get(props.entityKey).getData();
  return (
    <PopupAnnotation
      annotationId={ id }
      children={props.children}
      />
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: annotationStrategy,
    component: annotationSpan,
  },
]);

export default decorator;
