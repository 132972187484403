import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
const InfiniteScroll = require('react-infinite-scroll-component');
import Store from '../../stores/Store';
import DefisQueryParams from './DefisQueryParams';
import Loader from '../misc/Loader';

declare const __CLIENT__: any;

interface DefisPageProps {
  store?: Store;
  location: {
    query: DefisQueryParams
  };
}

interface DefisPageState {
  loadingDefisError: boolean;
  loadingDefis: boolean;
}

@inject('store') @observer
export default class DefisPage extends React.Component<DefisPageProps, DefisPageState> {
  static contextTypes = {
    intl: React.PropTypes.object.isRequired,
  };

  static fetchData(store: Store, params: void, query: DefisQueryParams) {
    return store.loadDefis(query, true);
  }
  constructor(props: DefisPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      loadingDefisError: false,
      loadingDefis: false
    };
  }
  loadMoreDefis() {
    const query = this.props.location.query;
    this.props.store.loadDefis(query, false, (failed: boolean)=>{
      this.setState(
      {
        loadingDefisError: failed,
        loadingDefis: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingDefis: true
    },
    () => {this.loadMoreDefis()}    
    );
  }
  render() {
    const query = this.props.location.query;
    const available = query.available !== undefined && query.available !== null ?
      query.available
      : true;
    const genre = query.genre ? parseInt(String(query.genre)) : 0;

    const sort = query.sort ? query.sort : 'date';
    const order = query.order ? query.order : 'desc';

    const type = query.type;
    const defis = this.props.store.mainDefi.defis.map(defi => {
      return (
        <div key={Math.random() * 1000} style={{ marginBottom: '20px' }}>
          <Link to={`/defis/defi/${defi.id}/${defi.urlTitle}`}>
            <h3 className={'ui top attached centered inverted ' +
              (defi.available ? 'defi-active' : 'defi-inactive') + ' header'}>
              {defi.title}
              <span style={{ float: 'right' }}>
                {defi.inprogress ?
                  <span className="ui orange right label"
                    data-tooltip="Un auteur relève ce défi en ce moment !" data-inverted="" data-position="left center">
                    En cours
                </span>
                  : undefined}
                {defi.nouveau ?
                  <span className="ui teal right label">Nouveau</span>
                  : undefined}
                {defi.defiType ?
                  <span className="ui orange right label">À l'honneur</span>
                  : undefined}
              </span>
            </h3>
          </Link>
          <div className="ui attached segment">
            {defi.myReponse ?
              (defi.myReponse.active ?
                <div className="ui message warning">
                  Vous avez jusqu'au {defi.myReponse.deadline} à 23h59 pour répondre à ce défi.
                </div>
                : <div className="ui message info">
                  Vous avez terminé ce défi.
                </div>
              )
              : ''
            }
            <div className="ui stackable grid">
              <div className="five wide column">
                <table className="ui celled table">
                  <tbody>
                    <tr><td>
                      Par <Link to={`/author/${defi.author.id}/${defi.author.urlIdentity}`}>
                        {defi.author.identity}
                      </Link>
                    </td></tr>
                    {defi.defiType ?
                      <tr><td>
                        Jusqu'au {defi.starUntil}
                      </td></tr>
                      :
                      undefined
                    }
                    {defi.genre ?
                      <tr><td className="center aligned">
                        <FormattedMessage
                          id={`genre.${defi.genre}`}
                          defaultMessage="Genre"
                        />
                      </td></tr>
                      : null
                    }
                    <tr><td>
                      <span className="ui tiny blue label" style={{ marginRight: '5px' }}>
                        {defi.reponses}
                      </span> réponses
                    </td></tr>
                    <tr><td>
                      <span className="ui tiny green label" style={{ marginRight: '5px' }}>
                        {defi.comments}
                      </span> commentaires
                    </td></tr>
                  </tbody>
                </table>
              </div>
              <div className="eleven wide column">
                <div style={{ paddingBottom: '60px' }}>
                  {defi.defiType ?
                    <div dangerouslySetInnerHTML={{ __html: defi.description.split('<<<<<>>>>>')[0] }} />
                    : <div dangerouslySetInnerHTML={{ __html: defi.description }} />
                  }
                  <Link
                    to={`/defis/defi/${defi.id}/${defi.urlTitle}`}
                    className="ui button"
                    style={{ bottom: '10px', right: '5px', position: 'absolute' }}
                  >
                    En savoir plus
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    const genres: Array<any> = [];
    genres.push({
      text: '',
      value: 0
    });

    for (let i = 1; i <= 20; i++) {
      genres.push(
        {
          text: this.context.intl.formatMessage({ id: `genre.${i}` }),
          value: i
        }
      );
    }
    let alertMessage: any;
    let title: string;
    switch (type) {
      case 'releves':
        const nbDefisActive = this.props.store.mainDefi.defis.filter(d => {
          return d.myReponse && d.myReponse.active;
        }).length;
        switch (nbDefisActive) {
          case 0:
            alertMessage = (
              <div className="ui message">
                Vous n'avez pas de défi relevé en cours.
              </div>
            );
            break;
          case 3:
            alertMessage = (
              <div className="ui message warning">
                Vous avez atteint le maximum de 3 défis relevés simultanément.
              </div>
            );
            break;
          default:
            alertMessage = (
              <div className="ui message info">
                Vous avez {nbDefisActive} défi{nbDefisActive > 1 ? 's' : undefined}
                &nbsp;relevé{nbDefisActive > 1 ? 's' : undefined} en cours.
              </div>
            );
        }
        title = 'Mes défis relevés';
        break;
      case 'proposes':
        const nbDefisAvailable = this.props.store.mainDefi.defis.filter(d => {
          return d.available;
        }).length;
        switch (nbDefisAvailable) {
          case 0:
            alertMessage = (
              <div className="ui message">
                Vous n'avez pas de défi proposé actif.
                <div className="ui center aligned basic segment">
                  <Link to="/defis/new" className="ui orange button">
                    Proposer un défi
                </Link>
                </div>
              </div>
            );
            break;
          case 3:
            alertMessage = (
              <div className="ui message warning">
                Vous avez atteint le maximum de 3 défis proposés disponibles.
                <br />Vous devez en désactiver un avant de pouvoir en proposer un nouveau.
              </div>
            );
            break;
          default:
            alertMessage = (
              <div className="ui message info">
                Vous avez {nbDefisAvailable}&nbsp;défi{nbDefisAvailable > 1 ? 's' : undefined}
                &nbsp;proposé{nbDefisAvailable > 1 ? 's' : undefined}&nbsp;actif{nbDefisAvailable > 1 ? 's' : undefined}.
                <Link to="/defis/new" className="ui blue right floated small button" style={{ marginTop: '-7px' }}>
                  Proposer un défi
                </Link>
              </div>
            );
            break;
        }
        title = 'Mes défis proposés';
        break;
      default:
        title = 'Tous les défis';
    }
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.mainDefi.end){
        if (this.state.loadingDefis){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingDefisError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid" style={{ marginBottom: '30px'}}>{error}{message}</div>

    }
    return (
      <div>
        <Title title="Défis " />
        <div className="ui two column grid stackable">
          <div className="eleven wide grey column" style={{ paddingTop: '30px', minHeight: '90vh' }}>
            <div className="ui grid centered stackable">
              <div className="twelve wide column">
                <div className="ui massive breadcrumb">
                  <div className="section">Défis</div>
                  <i className="fa fa-fw fa-chevron-right divider"></i>
                  <div className="active section">{title}</div>
                </div>
                <div style={{ marginTop: '30px' }}>
                  {this.props.store.mainDefi.loading ?
                    <Loader message="Chargement..." />
                    :
                    <div>
                      {defis.length > 0 || type === 'releves' || type === 'proposes' ?
                        <InfiniteScroll
                          next={this.loadMore}
                          hasMore={false}
                          loader={<h4>Chargement...</h4>}
                          endMessage={endMessage()}
                        >
                          {alertMessage}

                          <div className="ui items" style={{ padding: '5px' }}>
                            {defis}
                          </div>
                        </InfiniteScroll>
                        : <div className="ui message warning">
                          Aucun défi ne correspond à ces critères.
                                </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="five wide column" style={{ backgroundColor: '#fff' }}>
            <div className="ui grid centered stackable">
              <div className="twelve wide column" style={{ textAlign: 'left', paddingTop: '50px' }}>
                <div className="ui vertical fluid secondary menu">
                  <Link
                    to={`/defis?type=releves&available=${available}&genre=${genre}&sort=${sort === 'random' ? 'date' : query.sort}&order=${order}`}
                    className={'item' + (type === 'releves' ? ' active' : '')}>
                    Mes défis relevés
                  </Link>
                  <Link
                    to={`/defis?type=proposes&available=${available}&genre=${genre}&sort=${sort === 'random' ? 'date' : query.sort}&order=${order}`}
                    className={'item' + (type === 'proposes' ? ' active' : '')}>
                    Mes défis proposés
                  </Link>
                  <Link
                    to={`/defis?type=all&available=${available}&genre=${genre}&sort=${sort === 'random' ? 'date' : query.sort}&order=${order}`}
                    className={'item' + (!type || (type !== 'releves' && type !== 'proposes') ? ' active' : '')}>
                    Tous les défis
                  </Link>
                </div>
                <div className="ui basic aligned segment">
                  <div className="ui horizontal divider">Filtrer par</div>
                  <div className="ui form" style={{ marginTop: '10px' }}>
                    <div className="field">
                      <div className="ui fluid tiny buttons">
                        <button
                          className={'ui button' + (String(available) === 'true' ? ' positive active' : '')}
                          onClick={() => {
                            browserHistory.push({
                              pathname: '/defis', query: {
                                type: type,
                                available: true,
                                genre: genre,
                                sort: sort === 'random' ? 'date' : query.sort,
                                order: order
                              }
                            });
                          }}
                        >
                          Disponibles
                          </button>
                        <div className="or" data-text="ou"></div>
                        <button
                          className={'ui button' + (String(available) === 'false' ? ' positive active' : '')}
                          onClick={() => {
                            browserHistory.push({
                              pathname: '/defis', query: {
                                type: type,
                                available: false,
                                genre: genre,
                                sort: sort === 'random' ? 'date' : query.sort,
                                order: order
                              }
                            });
                          }}
                        >
                          Indisponibles
                          </button>
                      </div>
                    </div>
                    <div className="field">
                      <label>Genre imposé</label>
                      <Dropdown
                        search
                        selection
                        placeholder="Genre"
                        value={genre}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/defis', query: {
                              type: type,
                              available: available,
                              genre: value,
                              sort: sort === 'random' ? 'date' : query.sort,
                              order: order
                            }
                          });
                        }}
                        options={genres}
                      />
                    </div>
                    <div className="ui horizontal divider">Trier par</div>
                    <div className="field">
                      <Dropdown
                        selection
                        value={sort}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/defis', query: {
                              type: type,
                              available: available,
                              genre: genre,
                              sort: value,
                              order: order
                            }
                          });
                        }}
                        options={
                          [
                            {
                              text: 'Date',
                              value: 'date'
                            },
                            {
                              text: 'Nombre de réponses',
                              value: 'responses'
                            }
                          ]
                        }
                      />
                    </div>
                    <div className="field">
                      <Dropdown
                        selection
                        value={sort !== 'random' ? order : ''}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/defis', query: {
                              type: type,
                              available: available,
                              genre: genre,
                              sort: sort === 'random' ? 'date' : query.sort,
                              order: value
                            }
                          });
                        }}
                        options={
                          [
                            {
                              text: 'Croissante',
                              value: 'asc'
                            },
                            {
                              text: 'Décroissante',
                              value: 'desc'
                            }
                          ]
                        }
                      />
                    </div>
                    <div className="ui divider" />
                    <Link
                      to={`/defis?sort=random`}
                      className="ui orange fluid button"
                    >
                      Sélection aléatoire
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
