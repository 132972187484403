import { observable } from 'mobx';
import NotificationsListModel from './NotificationsListModel';

export default class MainNotificationsModel {

  @observable idAfter: number;
  @observable end: boolean;
  @observable notifications: NotificationsListModel[];

  constructor(idAfter: number, end: boolean, notifications: NotificationsListModel[]) {
    this.idAfter = idAfter;
    this.end = end;
    this.notifications = notifications || [];
  }

  setNotifications(first: boolean, notifications: NotificationsListModel[], end: boolean) {
    if (first) {
      this.notifications = notifications;
    } else {
      // get last day
      const lastDay = this.notifications[this.notifications.length - 1];
      const lastDayToConcat = notifications.filter(n => n.day === lastDay.day && n.month === lastDay.month)[0];
      const toConcat = lastDayToConcat ? lastDayToConcat.notifications : [];

      this.notifications[this.notifications.length - 1].notifications =
        this.notifications[this.notifications.length - 1].notifications.concat(toConcat);

      this.notifications = this.notifications.concat(notifications
        .filter(n => n.day !== lastDay.day || n.month !== lastDay.month));
    }
    if (notifications.length > 0) {
      const newLastDay = notifications[notifications.length - 1];
      this.idAfter = newLastDay.notifications[newLastDay.notifications.length - 1].id;
    }
    this.end = end;
  }

  toJS() {
    return {
      idAfter: this.idAfter,
      end: this.end,
      notifications: this.notifications.map(n => n.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const notifications = object.notifications.map((d: any) => NotificationsListModel.fromJS(d));
      return new MainNotificationsModel(object.idAfter, object.end, notifications);
    }
    return undefined;
  }
}
