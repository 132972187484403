import { observable } from 'mobx';

export default class AlertModel {

  @observable id: string;
  @observable title: string;
  @observable message: any;
  @observable level: string;
  @observable autoDismiss: number;

  constructor(id: string, title: string, message: any, level: string,
    autoDismiss: number) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.level = level;
    this.autoDismiss = autoDismiss;
  }
}
