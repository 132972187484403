import UserModel from './UserModel';
import LightTextModel from './LightTextModel';
import DefiModel from './DefiModel';
import LightChapterModel from './LightChapterModel';
import TalkModel from './TalkModel';
import NotificationAnnotationModel from './notification/NotificationAnnotationModel';
import ParcoursModel from './parcours/ParcoursModel';

export default class SimpleNotificationModel {
  id: number;
  notificationType: string;
  ago: string;
  message: string;
  targetUser: UserModel;
  targetUser2: UserModel;
  text: LightTextModel;
  chapter: LightChapterModel;
  chapterVersionId: number;
  defi: DefiModel;
  talk: TalkModel;
  parcours: ParcoursModel;
  anchorId: number;
  annotations: NotificationAnnotationModel[];
  read: boolean;

  constructor(id: number, notificationType: string, ago: string, message: string,
    targetUser: UserModel, targetUser2: UserModel, text: LightTextModel, chapter: LightChapterModel,
    chapterVersionId: number, defi: DefiModel, talk: TalkModel, parcours: ParcoursModel,
    annotations: NotificationAnnotationModel[], anchorId: number, read: boolean) {
    this.id = id;
    this.notificationType = notificationType;
    this.ago = ago;
    this.message = message;
    this.targetUser = targetUser;
    this.targetUser2 = targetUser2;
    this.text = text;
    this.chapter = chapter;
    this.chapterVersionId = chapterVersionId;
    this.defi = defi;
    this.talk = talk;
    this.parcours = parcours;
    this.anchorId = anchorId;
    this.annotations = annotations;
    this.read = read;
  }

  toJS() {
    return {
      id: this.id,
      notificationType: this.notificationType,
      ago: this.ago,
      message: this.message,
      targetUser: this.targetUser.toJS(),
      targetUser2: this.targetUser2.toJS(),
      text: this.text.toJS(),
      chapter: this.chapter.toJS(),
      chapterVersionId: this.chapterVersionId,
      defi: this.defi.toJS(),
      talk: this.talk.toJS(),
      parcours: this.parcours.toJS(),
      anchorId: this.anchorId,
      annotations: this.annotations,
      read: this.read
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SimpleNotificationModel(object.id, object.notificationType, object.ago, object.message,
        UserModel.fromJS(object.targetUser), UserModel.fromJS(object.targetUser2),
        LightTextModel.fromJS(object.text), LightChapterModel.fromJS(object.chapter),
        object.chapterVersionId, DefiModel.fromJS(object.defi), TalkModel.fromJS(object.talk),
        ParcoursModel.fromJS(object.parcours), object.anchorId, object.annotations, object.read);
    }
    return undefined;
  }
}
