import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'


function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
    className="fa fa-angle-left hide-tablet ada-carousel-left"
      style={{ ...style, marginLeft: "-15px" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="fa fa-angle-right hide-tablet ada-carousel-right"
      style={{ ...style, marginRight: "-15px" }}
      onClick={onClick}
    />
  );
}


export default class TestimonySlider extends React.Component<{}, {}> {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true
    };
    return (
      <div style={{marginTop: "30px", marginBottom: "75px", width: "96%"}}>
        <Slider {...settings}>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="six wide column" style={{overflow: "hidden"}}>
            <img src="https://scribay.s3.amazonaws.com/assets/1637922020 1.png"
              alt="arrête ton cirque" style={{margin: "auto"}} width="60%"/>
          </div>
          <div className="seven wide left floated column ada-text editis-bleu-nuit" >
          <div style={{textAlign:"center"}}>
            <p className="ada-font-200" style={{fontSize: "1.4rem" }}>
              &laquo;  C'est ici que j'ai retravaillé <i>Arrête ton cirque</i>.
              Cela a été un sacré moteur et la communauté m'a permis de vraiment l'améliorer...  &raquo;
            </p>
            <p className="ada-font-200" style={{fontSize: "1rem", marginTop: "15px"}}>
              Odile Baltar, éditée chez Fleuve Noir <br/>
              Membre depuis juillet 2017
            </p>
          </div>
          </div>
        </div>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="six wide column" style={{overflow: "hidden"}}>
            <img src="https://scribay.s3.amazonaws.com/assets/wildekat.jpg"
              alt="wildekat" style={{margin: "auto"}} width="60%"/>
          </div>
          <div className="seven wide left floated column ada-text editis-bleu-nuit" >
          <div style={{textAlign:"center"}}>
            <p className="ada-font-200" style={{fontSize: "1.4rem" }}>
              &laquo;  J'y ai posté mon premier roman, et y ai trouvé des bêta lecteurs engagés, et soucieux de me donner des retours intéressants. 
              Grâce à eux, j'ai pu polir mon roman avant de le soumettre aux maisons d'édition. &raquo;
            </p>
            <p className="ada-font-200" style={{fontSize: "1rem", marginTop: "15px"}}>
              Sophie Bachet, éditée chez Bêta Publisher <br/>
              Membre depuis mai 2020
            </p>
          </div>
          </div>
        </div>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="six wide column" style={{overflow: "hidden"}}>
            <img src="https://scribay.s3.amazonaws.com/assets/couv-VOE101.jpg"
              alt="voyagedemy" style={{margin: "auto"}} width="60%"/>
          </div>
          <div className="seven wide left floated column ada-text editis-bleu-nuit" >
          <div style={{textAlign:"center"}}>
            <p className="ada-font-200" style={{fontSize: "1.4rem" }}>
              &laquo;  Grâce à l'Atelier des Auteurs, j'ai réussi à finir mon premier roman avec 
              l'aide de nombreuses personnes et de retours constructifs ! &raquo;
            </p>
            <p className="ada-font-200" style={{fontSize: "1rem", marginTop: "15px"}}>
              Dimitri Olivier, édité chez Nanachi Editions <br/>
              Membre depuis décembre 2018 
            </p>
          </div>
          </div>
        </div>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="thirteen wide column ada-text editis-bleu-nuit" >
          <div style={{textAlign:"center"}}>
            <p className="ada-font-200" style={{fontSize: "1.4rem" }}>
              &laquo;  J'avais le rêve de faire un 300 pages et je l'ai maintenant dépassé.  &raquo;
            </p>
            <p className="ada-font-200" style={{fontSize: "1rem", marginTop: "15px"}}>
              Chris Morg, membre depuis novembre 2021
            </p>
          </div>
          </div>
        </div>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="thirteen wide column ada-text editis-bleu-nuit" >
          <div style={{textAlign:"center"}}>
            <p className="ada-font-200" style={{fontSize: "1.4rem" }}>
              &laquo;  Avant vous, mes textes restaient dans de petits carnets et je ne les montrais à personne.  &raquo;
            </p>
            <p className="ada-font-200" style={{fontSize: "1rem", marginTop: "15px"}}>
              Kuate Laura, membre depuis septembre 2017
            </p>
          </div>
          </div>
        </div>
        </Slider>
      </div>
    );
  }
}