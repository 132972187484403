import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../../../stores/Store';

interface ParcoursEndingModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  text?: { id: number, url: string, chapterId: number };
}

interface ParcoursEndingModalState {
  constraint: boolean;
}

@inject('store') @observer
export default class ParcoursEndingModal
  extends React.Component<ParcoursEndingModalProps, ParcoursEndingModalState> {
  constructor(props: ParcoursEndingModalProps) {
    super(props);
    this.transferTextToScribay = this.transferTextToScribay.bind(this);
    this.state = {
      constraint: false
    };
  }
  transferTextToScribay() {
    this.props.store.nextChapterFromHub([]).then((d: any) => {
      this.props.hide();
      browserHistory.push({
        pathname: '/write'
      });
    });
    
  }
  
  render() {

    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Fin du parcours</div>
        <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          <p>Vous êtes arrivé à la <b>fin</b> du parcours.</p>
          <p>Vous pouvez désormais faire basculer le texte que vous avez écrit dans la partie publique de la plateforme pour éventuellement le soumettre à la communauté.</p>
          <p className="ui red"><i className="fa fa-exclamation-triangle"/> Attention, cette action est irreversible. Vous ne pourrez plus suivre le parcours avec ce texte.</p>
          <button className="ui primary button" onClick={this.transferTextToScribay}>Transférer mon texte vers la communauté !</button>
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
