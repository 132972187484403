import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
const RichUtils = require('draft-js').RichUtils;
const SelectionState = require('draft-js').SelectionState;
const getRangesForDraftEntity = require('draft-js/lib/getRangesForDraftEntity');
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import Store from '../../../../../../stores/Store';
import AnnotationModel from '../../../../../../models/text/AnnotationModel';

interface PopupAnnotationProps {
  annotationId: number;
  children: any;
  store?: Store;
}

interface PopupAnnotationState {
  active: boolean;
  annotation?: AnnotationModel;
  childrenInState: any;
}

@inject('store') @observer
export default class PopupAnnotation extends React.Component<PopupAnnotationProps, PopupAnnotationState> {
  constructor(props: PopupAnnotationProps) {
    super(props);
    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.removeAnnotation = this.removeAnnotation.bind(this);
    this.state = { active: false, childrenInState: this.props.children };
  }
  showPopup() {
    this.props.store.apiClient.get(`/read/at/annotations/${this.props.annotationId}`)
      .then((a: AnnotationModel) => {
        this.setState({
          active: true,
          annotation: a,
          childrenInState: this.state.childrenInState
        });
      });
  }
  hidePopup() {
    this.setState({
      active: false
    });
  }
  getEntitySelectionState(contentState: any, selectionState: any, entityKey: any) {
    const selectionKey = selectionState.getAnchorKey();
    const selectionOffset = selectionState.getAnchorOffset();
    const block = contentState.getBlockForKey(selectionKey);
    const blockKey = block.getKey();

    let entitySelection: any;
    getRangesForDraftEntity(block, entityKey).forEach((range: any) => {
      if (range.start <= selectionOffset && selectionOffset <= range.end) {
        entitySelection = new SelectionState({
          anchorOffset: range.start,
          anchorKey: blockKey,
          focusOffset: range.end,
          focusKey: blockKey,
          isBackward: false,
          hasFocus: selectionState.getHasFocus(),
        });
      }
    });
    return entitySelection;
  }
  @action
  removeAnnotation(e: any) {
    const editorState = this.props.store.write.currentText.currentChapter.textContentState;
    const selection = editorState.getSelection();
    const blockKey = selection.getStartKey();
    const block = editorState.getCurrentContent().getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(selection.getStartOffset());

    const newSelection = this.getEntitySelectionState(
      editorState.getCurrentContent(),
      selection,
      entityKey
    );

    this.props.store.write.currentText.currentChapter.textContentState =
      RichUtils.toggleLink(editorState, newSelection, null);

  }
  render() {
    const ann = this.state.annotation;
    return (
      <Popup
        trigger={
          <span
            title="Cliquez pour afficher"
            className="annotator-category-0"
          >
            {this.props.children}
          </span>
        }
        on="click"
        position="bottom center"
        flowing
        open={this.state.active}
        onOpen={this.showPopup}
        onClose={this.hidePopup}
      >
        <div className="ui comments">
          {ann ?
            <div className="comment">
              <a className="avatar">
                <img src={ann.userAvatar} alt={`Image de profil de ${ann.userIdentity}`} />
              </a>
              <div className="content">
                <a className="author">{ann.userIdentity}</a>
                <div className="metadata">
                  <span className="date">{ann.readableCreated}</span>
                </div>
                <div className="text"
                  dangerouslySetInnerHTML={{ __html: ann.text }}
                />
                <div className="actions" style={{ marginTop: '20px' }}>
                  <button
                    className="ui orange mini button"
                    onClick={this.removeAnnotation}
                  >
                    Effacer la note
                 </button>
                  <button
                    className="ui mini button"
                    onClick={this.hidePopup}
                  >
                    Fermer
                 </button>
                </div>
              </div>
            </div>
            : undefined
          }
        </div>
      </Popup>
    );
  }
}
