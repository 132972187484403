import { observable } from 'mobx';
import VideoModel from './VideoModel';

export default class InstructionsModel {

  @observable parcoursInstructions: string;
  @observable partInstructions: string;
  @observable chapterInstructions: string;
  @observable parcoursVideos: VideoModel[];
  @observable partVideos: VideoModel[];

  constructor(parcoursInstructions: string, partInstructions: string, chapterInstructions: string,
    parcoursVideos: VideoModel[], partVideos: VideoModel[]) {
    this.parcoursInstructions = parcoursInstructions;
    this.partInstructions = partInstructions;
    this.chapterInstructions = chapterInstructions;
    this.parcoursVideos = parcoursVideos;
    this.partVideos = partVideos;
  }

  toJS() {
    return {
      parcoursInstructions: this.parcoursInstructions,
      partInstructions: this.partInstructions,
      chapterInstructions: this.chapterInstructions,
      parcoursVideos: this.parcoursVideos.map(v => v.toJS()),
      partVideos: this.partVideos.map(v => v.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const parcoursVideos = object.parcoursVideos.map((v: any) => VideoModel.fromJS(v));
      const partVideos = object.partVideos.map((v: any) => VideoModel.fromJS(v));
      return new InstructionsModel(object.parcoursInstructions, object.partInstructions, object.chapterInstructions,
        parcoursVideos, partVideos);
    }
    return undefined;
  }
}
