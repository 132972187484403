import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';
import PremiumModel from '../../models/settings/PremiumModel';
const ReactPlayer = require('react-player');


interface SettingsAdminPremiumModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  update: Function | any;
  title: string;
  premiumInfos: PremiumModel[];
  id: number;
}

interface SettingsAdminPremiumModalState {
  loading: boolean;
}


@inject('store') @observer
export default class SettingsAdminPremiumModal
  extends React.Component<SettingsAdminPremiumModalProps, SettingsAdminPremiumModalState> {
  constructor(props: SettingsAdminPremiumModalProps) {
    super(props);
    this.state = {
      loading: false
    }
    this.promoteUser = this.promoteUser.bind(this);
  }
  promoteUser () {
    this.setState({loading: true})
    this.props.store.promoteUser(this.props.id).then((d: any) => {
      this.props.update(d[0].id, d[0].premium);
      this.setState({loading: false})
    });
  }
  render() {
    
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">{this.props.title}</div>
          <div className="content" style={{maxHeight: '600px' }}>
            <table style={{fontSize: "0.9rem", overflowWrap: "break-word", marginTop: "15px"}} className="ui fixed stackable table teal">
              <thead>
                <tr>
                  <th className="four wide center aligned">
                    Date de début
                  </th>
                  <th className="four wide center aligned">
                    Date de fin
                  </th>
                  <th className="four wide center aligned">
                    Parcours acheté
                  </th>
                  <th className="three wide center aligned">
                    Universel
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.premiumInfos.map(premium => {      return (

                  <tr>
                  <th className="four wide center aligned">
                    {premium.start === null ? "/" : premium.start}
                  </th>
                  <th className="four wide center aligned">
                    {premium.end === null ? "/" : premium.end}
                  </th>
                  <th className="four wide center aligned">
                    {premium.marketIdentifier}
                  </th>
                  <th className="three wide center aligned">
                    {premium.allInclusive ? "Oui" : "Non"}
                  </th>
                </tr>)
                })}
              </tbody>
            </table>
            <button className={"ui validate "  + (this.state.loading ? ' loading' : '') + " button"} onClick={this.promoteUser}>Promote</button>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
    
  
   
  }
}
