import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';
import Relations from './Relations';
import ProfileParams from './ProfileParams';
import Loader from '../misc/Loader';

interface AllAbonnesProps {
  store?: Store;
  params: ProfileParams;
}

@inject('store') @observer
export default class AllAbonnes extends React.Component<AllAbonnesProps, {}> {
  static fetchData(store: Store, params: ProfileParams) {
    return store.loadProfileSocialAbonnes(params.authorId);
  }
  render() {
    const profile = this.props.store.profile;
    const relations = profile.relations;
    return (
      <div className="ui text container">
        {profile.loading ?
          <Loader message="Chargement..." />
          :
          <div>
            <Title title={`Abonnés de ${relations.user.identity} `} />
            <div className="ui massive breadcrumb">
              <Link to={`/author/${this.props.params.authorId}/${this.props.params.urlIdentity}`}
                className="section">
                {relations.user.identity}
              </Link>
              <i className="fa fa-fw fa-chevron-right divider"></i>
              <div className="active section">
                Tous ses abonnés
              </div>
            </div>
            <Relations
              count={relations.relations ? relations.relations.length : 0}
              users={relations.relations}
            />
          </div>
        }
      </div>
    );
  }
}
