import Set from 'typescript-collections/dist/lib/Set';

const Snowball = require('snowball');

export function getWords(text: string) {
  const regex = /[A-zÀ-ÿ0-9]+/g;
  const v = text.match(regex);
  return v ? v : [];
}

export function countWords(text: string) {
  if (text) {
    const wordArray = getWords(text);
    return wordArray.length;
  }
  return 0;
}

export function countDistinctWords(text: string) {
  const words = new Set<any>();
  if (text) {
    const regex = /[A-zÀ-ÿ0-9]+/g;
    let matchArr: string[];
    while ((matchArr = regex.exec(text)) != null) {
      const w = matchArr[0];
      words.add(w);
    }
  }
  return words.size();
}

export function thousandsSeparator(n: number) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function getTime(words: number) {
  return Math.round(words / 250);
}

export function getChapterTime(words: number): string {
  const time = getTime(words);
  switch (time) {
    case 0:
      return 'Moins d\'une minute de lecture';
    case 1:
      return 'Une minute de lecture';
    default:
      return `${time} minutes de lecture`;
  }
}

export function getTextTime(words: number, chaptersCount: number): string {
  const wordsPerChapter = words / chaptersCount;
  const time = getTime(wordsPerChapter);

  let avgTime: string;

  switch (time) {
    case 0:
      avgTime = 'de moins d\'une minute';
      break;
    case 1:
      avgTime = 'd\'une minute';
      break;
    default:
      avgTime = `de ${time} minutes`;
      break;
  }

  switch (chaptersCount) {
    case 0:
    case 1:
      return `${chaptersCount} chapitre ${avgTime}`;
    default:
      return `${chaptersCount} chapitres ${avgTime} en moyenne`;
  }

}

export function countDistinctLemmes(text: string): number {
  const stems = new Set<any>();
  if (text) {
    const stemmer = new Snowball('French');

    const regex = /[A-zÀ-ÿ0-9]+/g;
    let matchArr: string[];
    while ((matchArr = regex.exec(text)) != null) {
      const w = matchArr[0];
      stemmer.setCurrent(w);
      stemmer.stem();
      stems.add(stemmer.getCurrent());
    }
  }
  return stems.size();
}

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export function intersperse(arr: any[], sep: string) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(function (xs, x, i) {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}
