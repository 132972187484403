import UserModel from './UserModel';

export default class FullTextModel {
  id: number;
  title: string;
  urlText: String;
  author: UserModel;
  cover: string;
  summary: string;
  sensibleContent: boolean;
  defi: boolean;
  words: number;
  favorites: number;
  comments: number;
  annotations: number;
  dateCreation: string;

  constructor(id: number, title: string, urlText: string, author: UserModel, cover: string,
    summary: string, sensibleContent: boolean, defi: boolean, words: number, favorites: number, comments: number,
    annotations: number, dateCreation: string) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.author = author;
    this.cover = cover;
    this.summary = summary;
    this.sensibleContent = sensibleContent;
    this.defi = defi;
    this.words = words;
    this.favorites = favorites;
    this.comments = comments;
    this.annotations = annotations;
    this.dateCreation = dateCreation;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      author: this.author.toJS(),
      cover: this.cover,
      summary: this.summary,
      sensibleContent: this.sensibleContent,
      defi: this.defi,
      words: this.words,
      favorites: this.favorites,
      comments: this.comments,
      annotations: this.annotations,
      dateCreation: this.dateCreation
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new FullTextModel(object.id, object.title, object.urlText, UserModel.fromJS(object.author),
        object.cover, object.summary, object.sensibleContent, object.defi,
        object.words, object.favorites, object.comments, object.annotations, object.dateCreation);
    }
    return undefined;
  }
}
