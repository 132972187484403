import { observable } from 'mobx';
import UserModel from '../UserModel';
import LightTextModel from '../LightTextModel';
import DefiWithAuthorModel from '../defi/DefiWithAuthorModel';
import RelatedTalkModel from '../talk/RelatedTalkModel';

export default class SearchItemModel {

  @observable user: UserModel;
  @observable text: LightTextModel;
  @observable defi: DefiWithAuthorModel;
  @observable talk: RelatedTalkModel;

  constructor(user: UserModel, text: LightTextModel, defi: DefiWithAuthorModel, talk: RelatedTalkModel) {
    this.user = user;
    this.text = text;
    this.defi = defi;
    this.talk = talk;
  }

  toJS() {
    return {
      user: this.user.toJS(),
      text: this.text.toJS(),
      defi: this.defi.toJS(),
      talk: this.talk.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const user = UserModel.fromJS(object.user);
      const text = LightTextModel.fromJS(object.text);
      const defi = DefiWithAuthorModel.fromJS(object.defi);
      const talk = RelatedTalkModel.fromJS(object.talk);

      return new SearchItemModel(user, text, defi, talk);
    }
    return undefined;
  }
}
