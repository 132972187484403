import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { Accordion, Icon } from 'semantic-ui-react'
import Store from '../../stores/Store';
import ParcoursProductPartModel from '../../models/product/ParcoursProductPartModel';
const ReactPlayer = require('react-player');

interface LandingPageAccordionParcoursState {
  activeIndex: number;
  maxParcours: boolean;
}

interface LandingPageAccordionParcoursProps {
  formatedParcours: ParcoursProductPartModel[][];
}

@inject('store') @observer
export default class LandingPageAccordionParcours extends React.Component<LandingPageAccordionParcoursProps, LandingPageAccordionParcoursState> {
  constructor(props: void) {
    super();
    this.state = {
       activeIndex: 0,
       maxParcours: true
      }
  }
  handleClick = (titleProps: any) => {
    const index = titleProps
    const activeIndex = this.state.activeIndex
    const newIndex = activeIndex === index ? -1 : index

    this.setState({activeIndex: newIndex})
  }

  toggleMoreSteps = () => {
    this.setState({maxParcours: !this.state.maxParcours})
  }
  goToLanding(productId: number) {
    browserHistory.push({
      pathname: '/atelier/'+productId
    });
  }
  render() {
    const accordion: JSX.Element[] = this.props.formatedParcours ? Object.keys(this.props.formatedParcours).map((key, index) => { 
      return (
        (!this.state.maxParcours || index < 12) ?
        <div>
          <div className={'title ' + (this.state.activeIndex === parseInt(key) ? 'active' : '')} onClick={() => this.handleClick(parseInt(key))}>
            <i className="dropdown icon"></i>
            {this.props.formatedParcours[parseInt(key)][0].parcoursName}
          </div>
          <div className={'content ' + (this.state.activeIndex === parseInt(key) ? 'active' : '')}>
          <p className={'transition ' + (this.state.activeIndex === parseInt(key) ? '' : 'hidden')}>
            <div className="ui stackable grid">
            <div className="ui twelve wide column hide-large">
                <img className="ui image" width="100%" src={this.props.formatedParcours[parseInt(key)][0].parcoursShortImage} />
              </div>
              <div className="ui ten wide column">
                <div dangerouslySetInnerHTML={{ __html: this.props.formatedParcours[parseInt(key)][0].parcoursDescription }} />
                {this.props.formatedParcours[parseInt(key)][0].mainProductId != null ?
                  <button
                    className="ui primary button ada-background editis-bleu" 
                    onClick={() => this.goToLanding(this.props.formatedParcours[parseInt(key)][0].mainProductId)}
                    style={{ cursor: 'pointer', marginTop: '5px'}}
                    id={"go_to_workshop_"+this.props.formatedParcours[parseInt(key)][0].mainProductId}
                  >
                    Découvrir l'atelier
                  </button> : undefined
                }
              </div>
              <div className="ui six wide column hide-mobile">
                <img className="ui image" width="100%" src={this.props.formatedParcours[parseInt(key)][0].parcoursShortImage} />
              </div>
            </div>
            {this.props.formatedParcours[parseInt(key)][0].parcoursVideoUrl ? 
              <div className="ui center grid">
                <div className="column ">
                  <div className="player-wrapper single-video-ada react-player">
                    <ReactPlayer
                      url={this.props.formatedParcours[parseInt(key)][0].parcoursVideoUrl}
                      /* url="https://www.youtube.com/watch?v=n61ULEU7CO0" */
                      width="100%"
                      height="100%"
                    />
                  </div> 
                </div> 
              </div>
            : undefined}
          </p>
        </div>
      </div>
      : undefined
      )
    })

    : undefined ;
    return (
        <div className="ui styled fluid accordion">
          {accordion}
          {Object.keys(this.props.formatedParcours).length > 12 ?
            <div className="title" style={{textAlign: "center"}} onClick={() => this.toggleMoreSteps()}>
              {this.state.maxParcours ? "Afficher tout" : "Réduire"}
            </div>
          :
            undefined}
        </div>
        
    )
  }
}