import * as React from 'react';
import { inject, observer } from 'mobx-react';
import UserModel from '../../models/UserModel';
import CommentModel from '../../models/comment/CommentModel';
import Store from '../../stores/Store';

interface CommentVoteProps {
  store?: Store;
  comment: CommentModel;
  author: UserModel;
  restPath: string;
}

interface CommentVoteState {
  title: string;
}

@inject('store') @observer
export default class CommentVote extends React.Component<CommentVoteProps, CommentVoteState> {
  constructor(props: CommentVoteProps) {
    super(props);
    this.state = {
      title: this.props.comment.voted
        ? 'Je n\'aime plus'
        : 'J\'aime'
    };
    this.handleVote = this.handleVote.bind(this);
  }
  handleVote() {
    if (this.props.comment.voted) {
      this.setState({
        title: 'J\'aime'
      });
      this.props.store.handleUnUpvote(this.props.restPath, this.props.comment);
    } else {
      this.setState({
        title: 'Je n\'aime plus'
      });
      this.props.store.handleUpvote(this.props.restPath, this.props.comment);
    }
  }
  render() {
    return (
      <a onClick={this.handleVote}
        className={`${(this.props.comment.voted ? 'ui sp orange' : '')}`}
        title={this.state.title}
      >
        <i className="fa fa-fw fa-heart" />
        <span className="score">
          {this.props.comment.score}
        </span>
      </a>
    );
  }
}
