import * as React from 'react';

interface TrainingConstraintProps {
  constraint: {
    mandatoryWords: string[];
    forbiddenVerbs: string[];
    incipit: {
      text: string;
      title: string;
    };
    explicit: {
      text: string;
      title: string;
    };
    length: {
      min: number;
      max: number;
    }
  };
}

export default class TrainingConstraint
  extends React.Component<TrainingConstraintProps, {}> {
  render() {
    const constraint = this.props.constraint;
    return (
      <div className="ui info message">
        {constraint.mandatoryWords ?
          <div>
            Votre histoire doit comprendre obligatoirement les mots suivants :&nbsp;
              <div className="ui labels" style={{ marginTop: '10px' }}>
              {constraint.mandatoryWords.map((w) => {
                return (<span key={w} className="ui small label">{w}</span>);
              })}
            </div>
          </div>
          : (constraint.forbiddenVerbs ?
            <div>
              Vous ne pourrez pas utiliser les verbes suivants, conjugués ou non :&nbsp;
                <div className="ui labels" style={{ marginTop: '10px' }}>
                {constraint.forbiddenVerbs.map((w) => {
                  return (<span key={w} className="ui small label">{w}</span>);
                })}
              </div>
            </div>
            : (constraint.incipit ?
              <div>
                Votre histoire doit obligatoirement débuter par :&nbsp;
                  <blockquote style={{ marginTop: '10px' }}>
                  {constraint.incipit.text}
                  <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {constraint.incipit.title}
                  </div>
                </blockquote>
              </div>
              : (constraint.explicit ?
                <div>
                  Votre histoire doit obligatoirement terminer par :&nbsp;
                    <blockquote style={{ marginTop: '10px' }}>
                    {constraint.explicit.text}
                    <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {constraint.explicit.title}
                    </div>
                  </blockquote>
                </div>
                : constraint.length ?
                  <div>
                    Chacune des phrases de votre histoire doit compter <b>{constraint.length.max ? `au plus ${constraint.length.max} mots` : `au moins ${constraint.length.min} mots`}</b>.
                    </div>
                  : undefined)))
        }
      </div>
    );
  }
}
