import * as React from 'react';
import { Link } from 'react-router';
import FullTextModel from '../../../models/FullTextModel';
import { getTime, getChapterTime } from '../../../utils/utils';

interface TextCardProps {
  text: FullTextModel;
  label?: string;
  labelColor?: string;
}

export default class TextCard extends React.Component<TextCardProps, {}> {
  render() {
    const text = this.props.text;
    const time = getTime(text.words);
    const timeString = getChapterTime(text.words);
    return (
      <div key={text.id} className="card">
        {text.defi ?
          <div>
            <span className="ui top right attached orange label" style={{ zIndex: 10 }}>
              Défi
          </span>
          </div>
          : undefined}
        {text.cover ?
          <div className="image">
            <Link to={`/text/${text.id}/${text.urlText}`}>
              <div style={{
                backgroundImage: `url(https://s3-eu-west-1.amazonaws.com/scribay/covers/${text.cover}.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                width: '100%',
                height: '200px'
              }}
              />
            </Link>
          </div>
          : undefined}
        <div className="content">
          <div className="header">
            <Link to={`/text/${text.id}/${text.urlText}`}>{text.title}</Link>
          </div>
          <div className="meta">
            {text.author.identity}
          </div>
          <div
            className="description card-summary"
            dangerouslySetInnerHTML={{ __html: text.summary }}
          />
        </div>
        <div className="extra content">
          <div className="ui horizontal list" style={{ display: 'table', width: '100%' }}>
            {text.defi ?
              <div title="Cette œuvre est une réponse à un défi" style={{ display: 'table-cell ' }}>
                <span className="fa-stack fa-lg">
                  <i className="fa fa-circle fa-stack-2x" />
                  <i className="fa fa-search fa-stack-1x" />
                </span>
              </div>
              : undefined}
          </div>
          <div className="ui horizontal list" style={{ marginBottom: '0px' }}>
            <div className="item" title="Lecture">
              <i className="fa fa-heart fa-fw" /> {text.favorites}
            </div>
            <div className="item" title="Commentaires">
              <i className="fa fa-comment fa-fw" /> {text.comments}
            </div>
            <div className="item" title="Annotations">
              <i className="fa fa-edit fa-fw" /> {text.annotations}
            </div>

          </div>
          <div className="ui right floated horizontal list">
            {text.sensibleContent ?
            <div className="item" title="Contenu sensible">
              <i className="fa fa-fire fa-fw" />
            </div>
            : null}
            <div className="item" title={`${timeString}`}>
              <i className="fa fa-clock-o fa-fw" /> {time}
            </div>
          </div>
        </div>
        {this.props.label ?
          <div
            className={'ui bottom attached ' + (this.props.labelColor ? this.props.labelColor : '') + ' button'}>
            {this.props.label}
          </div>
          : undefined}
      </div>
    );
  }
}
