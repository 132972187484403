import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Title from '../../../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../../../stores/Store';
import NewDraftModel from '../../../../models/write/NewDraftModel';
import { countWords } from '../../../../utils/utils';
import AlertModel from '../../../../models/AlertModel';
import Sidebar from '../../../misc/Sidebar';
import ChapterParams from '../ChapterParams';
import TitleModal from '../../choose/TitleModal';

interface TrainingEndModalProps {
  store?: Store;
  endTimer: boolean;
  active: boolean;
  cancel?: Function | any;
}

interface TrainingEndModalState {
  option: string;
  activeTitle: boolean;
  title: string;
}

@inject('store') @observer
export default class TrainingEndModal extends React.Component<TrainingEndModalProps, TrainingEndModalState> {
  constructor(props: TrainingEndModalProps) {
    super(props);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.confirmTransform = this.confirmTransform.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.hideTitle = this.hideTitle.bind(this);
    this.transformToText = this.transformToText.bind(this);

    this.state = {
      option: 'transform',
      activeTitle: false,
      title: ''
    };
  }
  handleOptionChange(value: string) {
    this.setState({
      option: value
    });
  }
  confirmTransform() {
    const training = this.props.store.write.training;
    if (this.state.option === 'transform') {
      this.setState({
        activeTitle: true,
        title: ''
      });
    } else {
      const words = countWords(this.props.store.write.training.getRaw());
      this.props.store.validateTraining(words).then((result: { levelType: string, week: boolean, obj: number }[]) => {
        if (training && training.instructions && training.instructions.text) {
          const text = training.instructions.text;
          browserHistory.push({
            pathname: `/text/${text.id}/${text.url}/chapter/${text.chapterId}/edit`
          });
        } else {
          browserHistory.push({
            pathname: '/write'
          });
        }
        const programAlerts = this.props.store.generateProgramAlerts(result);
        programAlerts.push(new AlertModel(
          'removeTraining',
          'Le texte est supprimé.',
          '',
          'success',
          5
        ));
        if (training && training.instructions && training.instructions.text) {
          programAlerts.push(new AlertModel(
            'endTraining',
            'Votre échauffement est terminé, place à l\'écriture de votre roman !',
            '',
            'success',
            5
          ));
        }
        this.props.store.addAlerts(programAlerts);
      });
    }
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  hideTitle() {
    this.setState({
      activeTitle: false,
      title: ''
    });
  }
  transformToText() {
    const training = this.props.store.write.training;
    const title = this.state.title;
    const textContent = this.props.store.write.training.getHTML();
    const words = countWords(this.props.store.write.training.getRaw());
    this.props.store.validateTraining(words).then((result: { levelType: string, week: boolean, obj: number }[]) => {
      this.props.store.createNewTextTraining(title, textContent).then((draft: NewDraftModel) => {
        if (training && training.instructions && training.instructions.text) {
          const text = training.instructions.text;
          browserHistory.push({
            pathname: `/text/${text.id}/${text.url}/chapter/${text.chapterId}/edit`
          });
        } else {
          browserHistory.push({
            pathname: `/text/${draft.textId}/${draft.urlText}`
          });
        }
        const programAlerts = this.props.store.generateProgramAlerts(result);
        programAlerts.push(new AlertModel(
          'transformTraining',
          'Brouillon transformé !',
          '',
          'success',
          5
        ));
        if (training && training.instructions && training.instructions.text) {
          programAlerts.push(new AlertModel(
            'endTraining',
            'Votre échauffement est terminé, place à l\'écriture de votre roman !',
            '',
            'success',
            5
          ));
        }
        this.props.store.addAlerts(programAlerts);
      });
    });
  }
  render() {
    const training = this.props.store.write.training;

    return (
      <div>
        <TitleModal
          active={this.state.activeTitle}
          title={this.state.title}
          handleTitleChange={this.handleTitleChange}
          cancel={this.hideTitle}
          next={this.transformToText}
        />
        <Modal
          open={this.props.active}
          onClose={() => { }}
        >
          <div className="header">
            {this.props.endTimer ?
              <span>Le temps est écoulé...</span>
              : <span>Félicitations !</span>
            }
          </div>
          <div className="content">
            <div className="ui form">
              <div className="grouped fields">
                <label>Que souhaitez-vous faire ?</label>
                <div className="field">
                  <Checkbox
                    radio
                    checked={this.state.option === 'transform'}
                    onClick={() => this.handleOptionChange('transform')}
                    label="Transformer ce brouillon en une nouvelle œuvre non-publiée"
                  />
                  <div style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                    Vous pourrez ensuite lui ajouter un titre, la modifier, ajouter de nouveaux chapitres, la publier...
                  </div>
                </div>
                <div className="field">
                  <Checkbox
                    radio
                    checked={this.state.option === 'remove'}
                    onClick={() => this.handleOptionChange('remove')}
                    label="Supprimer définitivement ce texte"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <button
              className="ui primary approve button" onClick={this.confirmTransform}
            >
              Confirmer
            </button>
            {!this.props.endTimer ?
              <button className="ui button" onClick={this.props.cancel}>
                Annuler
          </button>
              : undefined}
          </div>
        </Modal>
      </div>
    );
  }
}
