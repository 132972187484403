import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import actions from '../editor/actions';
import styleMap from '../editor/styleMap';
import blockStyles from '../editor/blockStyles';
import convertFromHtml from '../editor/convertFromHtml';
import convertToHtml from '../editor/convertToHtml';

interface ProfileDescriptionProps {
  store?: Store;
}

interface ProfileDescriptionState {
  descriptionEditMode: boolean;
  descriptionState: any;
  remainingCharacters: number;
}

@inject('store') @observer
export default class ProfileDescription extends React.Component<ProfileDescriptionProps, ProfileDescriptionState> {
  constructor(props: ProfileDescriptionProps) {
    super(props);
    this.startSummaryEdit = this.startSummaryEdit.bind(this);
    this.cancelSummaryEdit = this.cancelSummaryEdit.bind(this);
    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.saveSummaryEdit = this.saveSummaryEdit.bind(this);
    this.state = {
      descriptionEditMode: false,
      descriptionState: undefined,
      remainingCharacters: 5000
    };
  }
  startSummaryEdit() {
    const description = this.props.store.profile.description ?
      this.props.store.profile.description
      : '<p></p>';
    const contentState = convertFromHtml(description);
    const descriptionState = EditorState.createWithContent(contentState);

    const plainText = descriptionState.getCurrentContent().getPlainText().trim();

    this.setState({
      descriptionEditMode: true,
      descriptionState: descriptionState,
      remainingCharacters: 5000 - plainText.length
    });
  }
  cancelSummaryEdit() {
    this.setState({
      descriptionEditMode: false,
      descriptionState: undefined,
      remainingCharacters: 5000
    });
  }
  handleSummaryChange(value: any) {
    const descriptionState = value;

    const description = convertToHtml(value);
    const plainText = value.getCurrentContent().getPlainText().trim();
    const remainingCharacters = 5000 - plainText.length;

    this.setState({
      descriptionState: descriptionState,
      remainingCharacters: remainingCharacters
    });
  }
  saveSummaryEdit(e: any) {
    e.preventDefault();
    if (this.state.remainingCharacters >= 0) {
      const profile = this.props.store.profile;
      const description = convertToHtml(this.state.descriptionState);
      this.props.store.updateProfileDescription(profile.id, description).then((description: string) => {
        profile.description = description;
        this.setState({
          descriptionEditMode: false,
          descriptionState: undefined
        });
      }, (err: string) => {
        this.setState({
          descriptionEditMode: false,
          descriptionState: undefined
        });
      });
    }
  }
  render() {
    const profile = this.props.store.profile;
    const connected = this.props.store.connected;
    const isAuthor = profile && profile.user
      && connected && connected.user && connected.user.id
      && connected.user.id === profile.user.id;
    const hasSummary = profile.description && profile.description.trim().length > 0;
    return (
      <div className={'ui ' + (profile.description ? 'twelve' : 'sixteen') + ' wide column'}>
        {isAuthor && this.state.descriptionEditMode ?
          <div className="ui basic segment read container" style={isAuthor ? { paddingBottom: '40px' } : {}}>
            <form
              className="ui form"
              onSubmit={this.saveSummaryEdit}
            >
              <div className={'field' + (this.state.remainingCharacters < 0 ? ' error' : '')}>
                <div className="embedded small">
                  <div className="ui segment">
                    <MegadraftEditor
                      editorState={this.state.descriptionState}
                      actions={actions}
                      placeholder="Texte"
                      onChange={this.handleSummaryChange}
                      sidebarRendererFn={() => { return <div />; }}
                      customStyleMap={styleMap}
                      blockStyles={blockStyles}
                      spellcheck={false}
                    />
                    {this.state.remainingCharacters < 0 ?
                      <span style={{ color: '#912d2b' }}>{this.state.remainingCharacters} caractères</span>
                      : undefined}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="ui mini right floated button"
                onClick={this.cancelSummaryEdit}
              >
                Annuler
            </button>
              <button
                className="ui blue right floated mini button"
                onClick={this.saveSummaryEdit}
                disabled={this.state.remainingCharacters < 0}
                type="submit"
              >
                Enregistrer
            </button>
            </form>
          </div>
          :
          <div className="ui padded basic segment medium text" style={isAuthor ? { paddingBottom: '40px' } : {}}>
            {hasSummary ?
              <div
                style={{ marginTop: '15px', marginBottom: '15px' }}
                dangerouslySetInnerHTML={{ __html: profile.description }} />
              : undefined
            }
            {isAuthor ?
              <div>
                {!hasSummary ?
                  <div className="ui message warning">
                    Ajoutez un texte de présentation pour vous présenter auprès de vos lecteurs !
                    </div>
                  : undefined}
                <button className="ui right floated orange small button"
                  onClick={this.startSummaryEdit} style={{ marginTop: '10px' }}>
                  {hasSummary ? 'Modifier le texte de présentation' : 'Ajouter un texte de présentation'}
                </button>
              </div>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
