import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import InstructionsModel from '../../../../../models/write/InstructionsModel';

interface ChapterNewPartProps {
  showNewPart: boolean;
  showNextVideo: Function | any;
  partName: string;
  instructions: InstructionsModel;
}

export default class ChapterNewPart
  extends React.Component<ChapterNewPartProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.showNewPart}
        onClose={this.props.showNextVideo}
        size="small"
        >
        <div className="header">Nouvelle partie !</div>
        <div className="content">
          Vous avez déverrouillé la partie <b>{this.props.partName}</b>.
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.props.instructions.partInstructions }} />
          </div>
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.showNextVideo}>Suivant</button>
        </div>
      </Modal>
    );
  }
}
