function htmlToBlock(nodeName: string, node: any) {
  if (node.style.textAlign && node.style.textAlign !== '') {
    switch (node.style.textAlign) {
      case 'left':
        return {
          type: 'align-left'
        };
      case 'center':
        return {
          type: 'align-center'
        };
      case 'right':
        return {
          type: 'align-right'
        };
      case 'justify':
        return {
          type: 'align-justify'
        };
      default:
        return {};
    }
  } else {
    return {};
  }
}
export default htmlToBlock;