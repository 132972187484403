import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';

interface AlertAltruisteProps {
  store?: Store;
}

@inject('store') @observer
export default class AlertAltruiste extends React.Component<AlertAltruisteProps, {}> {
  constructor(props: AlertAltruisteProps) {
    super(props);
    this.close = this.close.bind(this);
  }
  @action
  close() {
    this.props.store.connected.alertAltruiste = false;
  }
  render() {
    const alertAltruiste = this.props.store.connected && this.props.store.connected.alertAltruiste;
    return (
      <div>
        <Modal
          open={alertAltruiste}
          onClose={this.close}
          size="small"
        >
          <div className="header">Bravo !</div>
          <div className="ui content">
            <div className="ui success message">
              <div className="ui header">Nous tenons à vous féliciter et à vous remercier pour votre investissement auprès des autres auteurs.</div>
              <div className="ui horizontal divider" />
              <div>
                <p>
                  Votre bienveillance est désormais reconnue publiquement !
                  <br />Durant toute la période où votre altruisme rayonnera, vos écrits apparaîtront en bonne place dans les fils d'actualités de toute la communauté.
                </p>
                <p>Nous espérons que cela donnera une plus grande visibilité à vos œuvres.</p>
              </div>
            </div>
          </div>
          <div className="actions">
            <button className="ui primary button" onClick={this.close}>Bien reçu !</button>
          </div>
        </Modal>
      </div>
    );
  }
}

