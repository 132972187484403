import * as React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
const Megadraft = require('megadraft');
const ReactCountdownClock = require('react-countdown-clock');
const MegadraftEditor = Megadraft.MegadraftEditor;
const MegadraftIcons = Megadraft.MegadraftIcons;
import UnderlineIcon from '../../../editor/UnderlineIcon';
import StrikeIcon from '../../../editor/StrikeIcon';
import TiretIcon from '../../../editor/TiretIcon';
import H1Icon from '../../../editor/H1Icon';
import H2Icon from '../../../editor/H2Icon';
import AlignLeftIcon from '../../../editor/AlignLeftIcon';
import AlignCenterIcon from '../../../editor/AlignCenterIcon';
import AlignRightIcon from '../../../editor/AlignRightIcon';
import AlignJustifyIcon from '../../../editor/AlignJustifyIcon';
import styleMap from '../../../editor/styleMap';
import blockStyles from '../../../editor/blockStyles';
import Store from '../../../../stores/Store';
import ChapterParams from '../ChapterParams';
import Loader from '../../../misc/Loader';
import TrainingEndModal from './TrainingEndModal';

interface TrainingPageProps {
  store?: Store;
}

const actions = [
  { type: 'inline', label: 'B', style: 'BOLD', icon: MegadraftIcons.BoldIcon },
  { type: 'inline', label: 'I', style: 'ITALIC', icon: MegadraftIcons.ItalicIcon },
  { type: 'inline', label: 'U', style: 'UNDERLINE', icon: UnderlineIcon },
  { type: 'inline', label: 'S', style: 'STRIKETHROUGH', icon: StrikeIcon },
  { type: 'separator' },
  { type: 'block', label: 'UL', style: 'unordered-list-item', icon: TiretIcon },
  { type: 'separator' },
  { type: 'block', label: 'H1', style: 'header-one', icon: H1Icon },
  { type: 'block', label: 'H2', style: 'header-two', icon: H2Icon },
  { type: 'separator' },
  { type: 'block', label: 'LEFT', style: 'align-left', icon: AlignLeftIcon },
  { type: 'block', label: 'CENTER', style: 'align-center', icon: AlignCenterIcon },
  { type: 'block', label: 'RIGHT', style: 'align-right', icon: AlignRightIcon },
  { type: 'block', label: 'JUSTIFY', style: 'align-justify', icon: AlignJustifyIcon },
];

interface TrainingPageState {
  activeConfirm: boolean;
}

@inject('store') @observer
export default class TrainingPage extends React.Component<TrainingPageProps, TrainingPageState> {
  constructor(props: TrainingPageProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onTimerComplete = this.onTimerComplete.bind(this);
    this.state = {
      activeConfirm: false
    };
  }
  @observable
  onChange(value: any) {
    this.props.store.write.training.updateTextContentState(value);
  }
  onTimerComplete() {
    this.setState({
      activeConfirm: true
    });
  }
  render() {
    const training = this.props.store.write.training;
    let constraintEl: JSX.Element;
    if (training && training.instructions && training.instructions.constraint) {
      const constraint = training.instructions.constraint;
      constraintEl = (
        <div>
          {constraint.mandatoryWords ?
            <div>
              Votre histoire doit comprendre obligatoirement les mots suivants :&nbsp;
                {constraint.mandatoryWords.map((w) => {
                return (<span key={w} className="ui small label">{w}</span>);
              })}
            </div>
            : (constraint.forbiddenVerbs ?
              <div>
                Vous ne pourrez pas utiliser les verbes suivants, conjugués ou non :&nbsp;
                  {constraint.forbiddenVerbs.map((w) => {
                  return (<span key={w} className="ui small label">{w}</span>);
                })}
              </div>
              : (constraint.incipit ?
                <div>
                  Votre histoire doit obligatoirement débuter par :&nbsp;
                    <i>{constraint.incipit.text}</i>
                </div>
                : (constraint.explicit ?
                  <div>
                    Votre histoire doit obligatoirement terminer par :&nbsp;
                   <i>{constraint.explicit.text}</i>
                  </div>
                  : constraint.length ?
                    <div>
                      Chacune des phrases de votre histoire doit compter <b>{constraint.length.max ? `au plus ${constraint.length.max} mots` : `au moins ${constraint.length.min} mots`}</b>.
                    </div>
                    : undefined)))
          }
        </div>
      );
    }
    return (
      <div>
        {training ?
          <div>
            <TrainingEndModal
              active={this.state.activeConfirm}
              endTimer={true}
            />
            <div style={{ position: 'fixed', bottom: '150px', right: '100px' }}>
              <ReactCountdownClock seconds={600}
                color="#AAA"
                alpha={0.9}
                size={80}
                onComplete={this.onTimerComplete} />
            </div>
            <div className="ui text container chapter-content">
              <div className="ui read text container write-editor" style={{ padding: '20px 0px 90vh 0px' }}>
                <MegadraftEditor
                  editorState={training.textContentState}
                  actions={actions}
                  placeholder="Votre texte"
                  onChange={this.onChange}
                  sidebarRendererFn={() => { return <div />; }}
                  customStyleMap={styleMap}
                  blockStyles={blockStyles}
                  spellcheck={false}
                />
              </div>
            </div>
            <div className="training-bar">
              « {training.instructions.pitch} »
              {constraintEl}
            </div>
          </div>
          : <div />}
      </div>
    );
  }
}
