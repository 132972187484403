import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
const Waypoint = require('react-waypoint');
import Questionnaire from './Questionnaire';
import ProfileBadges from './ProfileBadges';
import ProfileTalks from './ProfileTalks';
import ProfileDescription from './ProfileDescription';
import TextCarousel from '../misc/text/TextCarousel';
import ListCarousel from '../readinglist/ListCarousel';
import Store from '../../stores/Store';
import ProfileParams from './ProfileParams';
import Feed from '../feed/Feed';

interface ProfileAuthorProps {
  store?: Store;
  params: ProfileParams;
}

interface ProfileAuthorState {
  enableComponents: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class ProfileAuthor extends React.Component<ProfileAuthorProps, ProfileAuthorState> {
  static fetchData(store: Store, params: ProfileParams) {
    const p: any[] = [];
    p.push(store.loadProfileAuthor(params.authorId));
    p.push(store.loadProfileAuthorTexts(params.authorId));
    p.push(store.loadProfileAuthorLists(params.authorId));
    return Promise.all(p);
  }
  constructor(props: ProfileAuthorProps) {
    super(props);
    this.loadMoreFeed = this.loadMoreFeed.bind(this);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true,
      });
    }
  }
  loadMoreFeed(_callback?: Function) {
    const beforeDate = this.props.store.profile.feed.beforeDate;
    this.props.store.loadMoreProfileFeed(this.props.params.authorId, beforeDate);
    if (!_callback)
      this.props.store.loadMoreProfileFeed(this.props.params.authorId, beforeDate);
    else    
      this.props.store.loadMoreProfileFeed(this.props.params.authorId, beforeDate, _callback);

  }
  render() {
    const profile = this.props.store.profile;
    const connected = this.props.store.connected;
    let isAuthor: boolean = false;
    if (connected && connected.user && connected.user.id
      && profile && String(profile.id) === String(connected.user.id)) {
      isAuthor = true;
    }
    return (
      <div className="ui container">
        <div className="ui stackable middle aligned grid">
          {profile.description || isAuthor ?
            <ProfileDescription />
            : undefined}
          {profile.statistics ?
            (profile.description ?
              <div className="four wide column">
                <div className="ui large horizontal statistics">
                  <div className="ui statistic">
                    <div className="value">
                      {profile.statistics.texts}
                    </div>
                    <div className="label">œuvres</div>
                  </div>
                  <div className="ui hidden fitted divider" />
                  <div className="ui statistic">
                    <div className="value">
                      {profile.statistics.defis}
                    </div>
                    <div className="label">défis réussis</div>
                  </div>
                  <div className="ui hidden fitted divider" />
                  <div className="ui statistic">
                    <div className="value">
                      {profile.statistics.favorites}
                    </div>
                    <div className="label">"J'aime" reçus</div>
                  </div>
                </div>
              </div>
              :
              <div className="sixteen wide column">
                <div className="ui padded basic segment">
                  <div className="ui three large statistics">
                    <div className="ui statistic">
                      <div className="value">
                        {profile.statistics.texts}
                      </div>
                      <div className="label">œuvres</div>
                    </div>
                    <div className="ui hidden fitted divider" />
                    <div className="ui statistic">
                      <div className="value">
                        {profile.statistics.defis}
                      </div>
                      <div className="label">défis réussis</div>
                    </div>
                    <div className="ui hidden fitted divider" />
                    <div className="ui statistic">
                      <div className="value">
                        {profile.statistics.favorites}
                      </div>
                      <div className="label">"J'aime" reçus</div>
                    </div>
                  </div>
                </div>
              </div>
            )
            : undefined}
        </div>
        {profile.texts && profile.texts.length > 0 ?
          <div className="ui container">
            <h3 className="ui section horizontal large divider">
              <Link
                to={`/author/${this.props.params.authorId}/${this.props.params.urlIdentity}/texts`}
                data-tooltip="Voir plus" data-inverted="" data-position="bottom center"
              >
                Œuvres <i className="fa fa-fw fa-chevron-right" />
              </Link>
            </h3>
            <TextCarousel texts={profile.texts} />
          </div>
          : undefined}
        {(profile.questionnaire && profile.questionnaire.length > 0)
          || isAuthor ?
          <div className="ui container">
            <h3 className="ui section horizontal large divider">
              Questionnaire de l'Atelier des auteurs
            </h3>
            <div className="ui container text">
              <Questionnaire />
            </div>
          </div>
          : undefined}
        {profile.lists && profile.lists.length > 0 ?
          <div className="ui container">
            <h3 className="ui section horizontal large divider">
              <Link
                to={`/author/${this.props.params.authorId}/${this.props.params.urlIdentity}/lists`}
                data-tooltip="Voir plus" data-inverted="" data-position="bottom center"
              >
                Listes <i className="fa fa-fw fa-chevron-right" />
              </Link>
            </h3>
            <ListCarousel lists={profile.lists} />
          </div>
          : undefined}
        {profile.talks && profile.talks.length > 0 ?
          <div className="ui container">
            <h3 className="ui section horizontal large divider">
              Discussions créées <i className="fa fa-fw fa-chevron-right" />
            </h3>
            <div className="ui center aligned container">
              <ProfileTalks talks={profile.talks} />
            </div>
          </div>
          : undefined}
        {profile.badges && profile.badges.length > 0 ?
          <div className="ui container">
            <h3 className="ui section horizontal large divider">
              <Link
                to={`/author/${this.props.params.authorId}/${this.props.params.urlIdentity}/activity`}
                data-tooltip="Voir plus" data-inverted="" data-position="bottom center"
              >
                Badges <i className="fa fa-fw fa-chevron-right" />
              </Link>
            </h3>
            <div className="ui center aligned container">
              <ProfileBadges badges={profile.badges} />
            </div>
          </div>
          : undefined}
        {this.state.enableComponents ?
          <div className="ui container">
            <Waypoint
              key={'feed' + profile.id}
              onEnter={() => this.props.store.loadProfileFeed(profile.id)}
              scrollableAncestor={window}
            />
            <h3 className="ui section horizontal large divider">
              Activité
              </h3>
            <div className="ui text container">
              <Feed
                feed={profile.feed}
                withForm={false}
                loadMoreFeed={this.loadMoreFeed}
              />
            </div>
          </div>
          : undefined}
      </div>
    );
  }
}
