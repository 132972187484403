import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import ListFavorites from './ListFavorites';
import Store from '../../stores/Store';

interface TextFavoritesProps {
  store?: Store;
}

interface TextFavoritesState {
  active: boolean;
}

@inject('store') @observer
export default class TextFavorites extends React.Component<TextFavoritesProps, TextFavoritesState> {
  constructor(props: TextFavoritesProps) {
    super(props);
    this.showDetail = this.showDetail.bind(this);
    this.hideDetail = this.hideDetail.bind(this);
    this.state = { active: false };
  }
  showDetail() {
    this.props.store.loadTextLikes(this.props.store.text.meta.id).then((r: any) => {
      this.setState({ active: true });
    });
  }
  hideDetail() {
    this.setState({ active: false });
  }
  render() {
    const text = this.props.store.text;
    return (
      <div style={{ marginTop: '10px' }}>
        <ListFavorites
          title="Œuvre appréciée par"
          users={text.infos.userFavorites}
          close={this.hideDetail}
          active={this.state.active}
        />
        {text.infos.favorites > 0 ?
          <span style={{ color: text.meta.textColor }}>
            <i className="fa fa-fw fa-heart" /> Apprécié par <span style={{ color: text.meta.linkColor, cursor: 'pointer' }}
              data-tooltip="Voir le détail" data-inverted="" data-position="bottom center"
              onClick={this.showDetail}>
              {text.infos.favorites} lecteur{text.infos.favorites > 1 ? 's' : ''}
            </span>
          </span>
          :
          <span style={{ color: text.meta.textColor }}>
            <i className="fa fa-fw fa-heart" /> Apprécié par {text.infos.favorites} lecteur{text.infos.favorites > 1 ? 's' : ''}
          </span>
        }
      </div>
    );
  }
}
