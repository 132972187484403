import * as React from 'react';
import { Link } from 'react-router';
import DefiTextModel from '../../models/write/DefiTextModel';

interface DefiReadProps {
  defi: DefiTextModel;
  isAuthor: boolean;
  strangerMode: boolean;
  userId: number;
}

export default class DefiRead extends React.Component<DefiReadProps, {}> {
  render() {
    const defi = this.props.defi;
    return (
      <div className="ui items">
        <div className="item">
          <div className="content">
            <h3 className="ui header">
              {defi.title}
            </h3>
            <div className="meta">
              Lancé par <Link
                to={`/author/${defi.author.id}/${defi.author.urlIdentity}`}
              >
                {defi.author.identity}
              </Link>
            </div>
            <div className="description">
              {defi.defiType ?
                <div
                  className="ui segment"
                  dangerouslySetInnerHTML={{ __html: defi.description.split('<<<<<>>>>>')[1] }} />
                : <div
                  className="ui segment"
                  dangerouslySetInnerHTML={{ __html: defi.description }} />
              }

              {this.props.isAuthor && !this.props.strangerMode ?
                <div>
                  {this.props.userId !== defi.author.id ?
                    <div>
                      <div>
                        {defi.active ?
                          <div className="ui warning message">
                            Vous avez jusqu'au {defi.deadline} à 23h59 pour répondre à ce défi.
                        </div>
                          : <div className="ui success message">
                            Vous avez réussi ce défi !
                          </div>
                        }
                      </div>
                    </div>
                    :
                    <div className="ui info message">
                      Cette œuvre est l'interprétation de ce défi que vous avez lancé.
                </div>
                  }
                </div>
                : undefined}
            </div>
            <div className="extra">
              <Link
                to={`/defis/defi/${defi.id}/${defi.urlTitle}`}
                className="ui right floated button"
              >
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
