import * as React from 'react';
import Title from '../misc/Title';
const Mailto = require('react-mailto').default;

interface ContactState {
  showEmails: boolean;
}

export default class Contact extends React.Component<{}, ContactState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const contactEmail = this.state.showEmails ?
      <Mailto email="contact@atelierdesauteurs.com" obfuscate={false}>
        contact@atelierdesauteurs.com
     </Mailto> : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ minHeight: '90vh', paddingTop: '30px' }}>
          <Title title="Contact " />
          <h1>Contact</h1>
          <div className="ui basic segment">
            <p>Si vous souhaitez nous contacter, une seule adresse : {contactEmail}</p>
            <p>Nous ferons tout pour vous répondre le plus rapidement possible !</p>
          </div>
        </div>
      </div>
    );
  }
}

