import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { Async, AsyncCreatable } from 'react-select';
const throttle = require('throttle-debounce').throttle;
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import Store from '../../stores/Store';
import TalkModel from '../../models/talk/TalkModel';
import RelatedTalkModel from '../../models/talk/RelatedTalkModel';
import Loader from '../misc/Loader';
import actions from '../editor/actions';
import styleMap from '../editor/styleMap';
import blockStyles from '../editor/blockStyles';
import convertFromHtml from '../editor/convertFromHtml';
import convertToHtml from '../editor/convertToHtml';

declare const __CLIENT__: any;

interface HelpProps {
  active: boolean;
  hide: Function | any;
}

class Help extends React.Component<HelpProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
        size="small"
      >
        <div className="header">À lire</div>
        <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          <p>L'Atelier des Auteurs est avant tout une plateforme destinée à la progression des auteurs et l'amélioration de leurs œuvres. Ses espaces publics de discussion peuvent être modérés et suivent les mêmes règles que l'ensemble du site.</p>
          <h3>Soyez courtois(e) et bienveillant(e)</h3>
          <p>
            Si vous êtes sur le coup de l'émotion, n'hésitez pas à vous relire deux fois avant de publier un message. Même si vous jugez votre colère légitime, vous n'avez pas à vous montrer insultant, grossier ou menaçant.
          </p>
          <h3>Évitez l'autopromotion et les appels à lecture</h3>
          <p>
            Nous tolérons de tels contenus dans les messages de présentation des nouveaux membres, bien entendu. Nous comprenons parfaitement que pour vous présenter, vous souhaitez également parler de vos œuvres.
          </p>
          <p>
            En-dehors de cette exception, la meilleure façon de gagner en visibilité sur l'Atelier des Auteurs est tout simplement de s'investir dans la communauté et de bien présenter ses œuvres.
          </p>
          <h3>Évitez la publicité</h3>
          <p>
            Chacun peut présenter sur son profil et dans sa présentation son métier, son site, ses livres en vente ou même son entreprise.
            <br />Cependant, nous préférons éviter que les espaces publics de discussion soient saturés de publicités. De tels contenus seront supprimées et leurs auteurs avertis.
          </p>
          <h3>Évitez les suggestions, rapports de bugs et retours sur la plateforme</h3>
          <p>
          Si vous avez un retour à faire sur la plateforme sociale ou ses fonctionnalités, vous pouvez directement nous écrire par messagerie privée ou par email (contact@atelierdesauteurs.com).
          </p>
          <h3>Contenu illégal ou contrevenant aux C.G.U.</h3>
          Nous nous donnons toute liberté pour supprimer des contenus de ce type et les comptes en publiant, sans avertissement. Nous vous invitons également, au moindre doute, à consulter nos <Link to="/moderation">règles de modération</Link>.
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
        </div>
      </Modal >
    );
  }
}

export interface TalkPageFormProps {
  store?: Store;
  submit: Function;
  talk?: TalkModel;
  linkedTalks?: RelatedTalkModel[];
  text?: { id: number, urlText: string };
  parcoursId?: number;
}

interface TalkPageFormState {
  enableComponents: boolean;
  loading: boolean;
  title: string;
  titleError: boolean;
  message: string;
  messageState: any;
  messageError: boolean;
  tags: { label: string, value: string }[];
  linked: { label: string, value: string }[];
  activeHelp: boolean;
}

@inject('store') @observer
export default class TalkPageForm extends React.Component<TalkPageFormProps, TalkPageFormState> {
  constructor(props: TalkPageFormProps) {
    super(props);
    let message = props.talk ? props.talk.message : '<div></div>';

    if (props.talk) {
      this.state = {
        enableComponents: false,
        loading: false,
        title: props.talk.title,
        titleError: false,
        message: message,
        messageState: undefined,
        messageError: false,
        tags: props.talk.tags.map(t => { return { label: t, value: t }; }),
        linked: props.linkedTalks ? props.linkedTalks.map(t => { return { label: t.title, value: String(t.id) }; }) : [],
        activeHelp: false
      };
    } else {
      this.state = {
        enableComponents: false,
        loading: false,
        title: '',
        titleError: false,
        message: message,
        messageState: undefined,
        messageError: false,
        tags: [],
        linked: [],
        activeHelp: false
      };
    }
    this.fetchTalks = throttle(1000, this.fetchTalks.bind(this));
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this);
    this.fetchTags = throttle(1000, this.fetchTags.bind(this));
    this.handleSelectTalkChange = this.handleSelectTalkChange.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.hideHelp = this.hideHelp.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    if (__CLIENT__) {
      let contentState = convertFromHtml(this.state.message);
      const messageState = EditorState.createWithContent(contentState);
      this.setState({
        enableComponents: true,
        loading: this.state.loading,
        title: this.state.title,
        titleError: this.state.titleError,
        message: this.state.message,
        messageState: messageState,
        messageError: this.state.messageError,
        tags: this.state.tags,
        linked: this.state.linked
      });
    }
  }
  fetchTalks(input: string, callback: Function) {
    let notTalk = 0;
    if (this.props.talk) {
      notTalk = this.props.talk.id;
    }
    this.props.store.fetchTalks(notTalk, input, this.props.text ? this.props.text.id : undefined)
      .then((relations: any) => {
        const options = relations.map((r: any) => ({
          value: r.value,
          label: r.label,
        }));

        callback(null, { options });
      });
  }
  fetchTags(input: string, callback: Function) {
    this.props.store.getTalksTagsBeginningWith(input).then((tags: any) => {
      const options = tags.map((t: string) => ({
        value: t,
        label: t,
      }));

      callback(null, { options });
    });
  }
  handleTitleChange(e: any) {
    const titleError = this.state.titleError && e.target.value.trim().length === 0;
    this.setState({
      enableComponents: true,
      loading: false,
      title: e.target.value,
      titleError: titleError,
      message: this.state.message,
      messageState: this.state.messageState,
      messageError: this.state.messageError,
      tags: this.state.tags,
      linked: this.state.linked
    });
  }
  handleMessageChange(value: any) {
    const messageState = value;
    const message = convertToHtml(value);

    const messageError = this.state.messageError && value.getCurrentContent().getPlainText().trim().length === 0;
    this.setState({
      enableComponents: true,
      loading: false,
      title: this.state.title,
      titleError: this.state.titleError,
      message: message,
      messageState: messageState,
      messageError: messageError,
      tags: this.state.tags,
      linked: this.state.linked
    });
  }
  handleSelectTagChange(value: [{ create: boolean, label: string, value: string }]) {
    if (value.length > 5) {
      value.shift();
    }
    this.setState({
      enableComponents: true,
      loading: false,
      title: this.state.title,
      titleError: this.state.titleError,
      message: this.state.message,
      messageState: this.state.messageState,
      messageError: this.state.messageError,
      tags: value.map(v => {
        return { label: v.label, value: v.value };
      }),
      linked: this.state.linked
    });
  }
  handleSelectTalkChange(value: [{ create: boolean, label: string, value: string }]) {
    if (value.length > 10) {
      value.shift();
    }
    this.setState({
      enableComponents: true,
      loading: false,
      title: this.state.title,
      titleError: this.state.titleError,
      message: this.state.message,
      messageState: this.state.messageState,
      messageError: this.state.messageError,
      tags: this.state.tags,
      linked: value
    });
  }
  submit() {
    const titleError = this.state.title.trim().length === 0;
    const messageError = this.state.messageState.getCurrentContent().getPlainText().trim().length === 0;
    this.setState({
      enableComponents: true,
      loading: !titleError && !messageError,
      title: this.state.title,
      titleError: titleError,
      message: this.state.message,
      messageState: this.state.messageState,
      messageError: messageError,
      tags: this.state.tags,
      linked: this.state.linked
    });

    if (!titleError && !messageError) {
      this.props.submit(this.state.title, this.state.message,
        this.state.tags.map(t => t.value), this.state.linked.map(l => l.value));
    }
  }
  showHelp() {
    this.setState({
      activeHelp: true
    });
  }
  hideHelp() {
    this.setState({
      activeHelp: false
    });
  }
  render() {
    const text = this.props.text;

    let cancelLink: string;
    if (this.props.talk) {
      if (this.props.text) {
        cancelLink = `/text/${text.id}/${text.urlText}/talks/${this.props.talk.id}/${this.props.talk.urlTitle}`;
      } else if (this.props.parcoursId) {
        cancelLink = `/write/parcours/${this.props.parcoursId}/${this.props.talk.id}/${this.props.talk.urlTitle}`;
      } else {
        cancelLink = `/talks/${this.props.talk.id}/${this.props.talk.urlTitle}`;
      }
    } else {
      if (this.props.text) {
        cancelLink = `/text/${text.id}/${text.urlText}`;
      } else if (this.props.parcoursId) {
        cancelLink = `/`;
      } else {
        cancelLink = '/talks';
      }
    }
    return (
      <div>
        <Help active={this.state.activeHelp} hide={this.hideHelp} />
        {this.state.loading ?
          <Loader message="Chargement..." />
          :
          <form className="ui form">
            {this.state.titleError || this.state.messageError ?
              <div className="ui error message">
                <div className="header">Les champs marqués en rouge sont obligatoires.</div>
              </div>
              : undefined
            }
            <div className={'field' + (this.state.titleError ? ' error' : '')}>
              <label>Titre</label>
              <input type="text" placeholder="Titre" value={this.state.title} onChange={this.handleTitleChange} />
            </div>
            {this.state.enableComponents && !text ?
              <div className="field">
                <label>Mots-clés (5 maximum)</label>
                <AsyncCreatable
                  loadOptions={this.fetchTags}
                  multi={true}
                  value={this.state.tags}
                  onChange={this.handleSelectTagChange}
                  promptTextCreator={(label: string) => `Ajouter mot-clé: ${label}`}
                  placeholder="Aucun mot-clé"
                  noResultsText="Aucun mot-clé"
                  loadingPlaceholder="Chargement..."
                  ignoreCase={true}
                  ignoreAccents={false}
                  searchPromptText="Entrez un mot-clé"
                />
              </div>
              : undefined
            }
            {/* {this.state.enableComponents && !this.props.text && !this.props.parcoursId ?
              <div className="field">
                <label>Discussions liées</label>
                <Async
                  loadOptions={this.fetchTalks}
                  multi={true}
                  value={this.state.linked}
                  onChange={this.handleSelectTalkChange}
                  placeholder="Aucune discussion sélectionnée"
                  noResultsText="Aucune discussion trouvée"
                  searchPromptText="Entrez un titre de discussion"
                  loadingPlaceholder="Chargement..."
                  ignoreCase={true}
                  ignoreAccents={false}
                />
                <div className="ui message">
                  Ces discussions apparaîtront à côté de cette discussion.
                </div>
              </div>
              : undefined} */}
            <div className={'field' + (this.state.messageError ? ' error' : '')}>
              <label>Texte</label>
              {this.state.enableComponents ?
                <div className="embedded small">
                  <div className="ui segment">
                    <MegadraftEditor
                      editorState={this.state.messageState}
                      actions={actions}
                      placeholder="Texte"
                      onChange={this.handleMessageChange}
                      sidebarRendererFn={() => { return <div />; }}
                      customStyleMap={styleMap}
                      blockStyles={blockStyles}
                      spellcheck={false}
                    />
                  </div>
                </div>
                : undefined}
            </div>
            <div className="ui info message">
              <a onClick={this.showHelp} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                À lire avant de publier
              </a>
            </div>
            <Link to={cancelLink} className="ui right floated button" type="button">
              Annuler
            </Link>
            <button className="ui right floated primary button" type="button"
              onClick={this.submit}>Enregistrer</button>
          </form>
        }
      </div>
    );
  }
}
