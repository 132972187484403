import * as React from 'react';

export default class Unsubscribe extends React.Component<{}, {}> {
  render() {
    return (
      <div className="ui container">
        <h1>Désabonnement</h1>
        <p>Vous vous êtes bien désabonnés !</p>
        <p>Si vous souhaitez vous réabonner, vous pouvez le faire depuis vos <a href="https://www.atelierdesauteurs.com/settings">paramètres.</a></p>
        <p><a href="https://www.atelierdesauteurs.com">Revenir à la page d'accueil</a>.</p>
      </div>
    );
  }
}
