import * as React from 'react';
import Title from '../../misc/Title';
import { inject, observer } from 'mobx-react';
import Store from '../../../stores/Store';
import Drafts from './Drafts';
import Texts from './text/Texts';

interface TextsAndDraftsProps {
  store?: Store;
}

@inject('store') @observer
export default class TextsAndDrafts extends React.Component<TextsAndDraftsProps, {}> {
  static fetchData(store: Store) {
    const p: any[] = [];
    p.push(store.loadTexts(true));
    p.push(store.loadDrafts());
    return Promise.all(p);
  }
  static getCriteria(criteria: string): string {
    return criteria ? criteria : 'title';
  }
  static getOrder(order: string): string {
    return order ? order : 'ASC';
  }
  render() {
    return (
      <div className="ui grid" style={{ backgroundColor: '#fff' }}>
        <div className="ui container" style={{ minHeight: '90vh', paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Écrire " />
          <h1>Écrire</h1>
{/*           <Drafts />
 */}          <Texts />
        </div>
      </div>
    );
  }
}
