import { observable } from 'mobx';
import AllDiscussionsModel from './AllDiscussionsModel';
import DiscussionModel from './DiscussionModel';

export default class ChatModel {

  @observable allDiscussions: AllDiscussionsModel;
  @observable discussion: DiscussionModel;

  constructor(allDiscussions: AllDiscussionsModel, discussion: DiscussionModel) {
    this.allDiscussions = allDiscussions || new AllDiscussionsModel(0, false, []);
    this.discussion = discussion
      || new DiscussionModel(undefined, undefined, undefined, undefined, undefined, 0, false, []);
  }

  toJS() {
    return {
      allDiscussions: this.allDiscussions.toJS(),
      discussion: this.discussion.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ChatModel(
        AllDiscussionsModel.fromJS(object.allDiscussions),
        DiscussionModel.fromJS(object.discussion)
      );
    }
    return undefined;
  }
}
