import { observable } from 'mobx';

export default class LightChapterModel {
  id: number;
  @observable title: string;
  @observable step: number;
  @observable read: boolean;

  constructor(id: number, title: string, step: number, read: boolean) {
    this.id = id;
    this.title = title;
    this.step = step;
    this.read = read;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      read: this.read
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new LightChapterModel(object.id, object.title, object.step, object.read);
    }
    return undefined;
  }
}
