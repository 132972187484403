import { observable } from 'mobx';
import WritingProgramPeriodModel from './WritingProgramPeriodModel';

export default class WritingProgramModel {

  @observable startedAt: string;
  @observable week: WritingProgramPeriodModel;
  @observable days: WritingProgramPeriodModel[];
  @observable reg: boolean;
  @observable novel: boolean;
  @observable inspi: boolean;
  @observable conf: boolean;

  constructor(startedAt: string, week: WritingProgramPeriodModel, days: WritingProgramPeriodModel[],
  reg: boolean, novel: boolean, inspi: boolean, conf: boolean) {
    this.startedAt = startedAt;
    this.week = week;
    this.days = days || [];
    this.reg = reg;
    this.novel = novel;
    this.inspi = inspi;
    this.conf = conf;
  }

  toJS() {
    return {
      startedAt: this.startedAt,
      week: this.week.toJS(),
      days: this.days.map(d => d.toJS()),
      reg: this.reg,
      novel: this.novel,
      inspi: this.inspi,
      conf: this.conf
    };
  }

  static fromJS(object: any) {
    if (object) {
      const days = object.days ? object.days.map((day: any) => WritingProgramPeriodModel.fromJS(day)) : undefined;
      return new WritingProgramModel(object.startedAt, WritingProgramPeriodModel.fromJS(object.week),
        days, object.reg, object.novel, object.inspi, object.conf);
    }
    return undefined;
  }

}