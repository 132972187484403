import { observable } from 'mobx';
import ParcoursModel from './ParcoursModel';
import ParcoursQuestionModel from './ParcoursQuestionModel';
import ParcoursProgressModel from './ParcoursProgressModel';
import ParcoursStatusModel from './ParcoursStatusModel';
import ParcoursPrologueModel from './ParcoursPrologueModel';
import VideoModel from '../write/VideoModel';
import DiscussionModel from '../chat/DiscussionModel';
import ConnectedModel from '../ConnectedModel';

export default class MainParcoursModel {

  @observable allParcours: ParcoursModel[];
  @observable currentParcours: ParcoursModel;
  @observable parcoursProgress: ParcoursProgressModel;
  @observable status: ParcoursStatusModel;
  @observable previousStatuses: ParcoursStatusModel[];
  @observable discussion: DiscussionModel;
  @observable prologue: ParcoursPrologueModel;

  constructor(allParcours: ParcoursModel[], currentParcours: ParcoursModel,
    parcoursProgress: ParcoursProgressModel, status: ParcoursStatusModel, previousStatuses: ParcoursStatusModel[], discussion: DiscussionModel,
    prologue: ParcoursPrologueModel) {
    this.allParcours = allParcours || [];
    this.currentParcours = currentParcours;
    this.parcoursProgress = parcoursProgress;
    this.status = status;
    this.previousStatuses = previousStatuses || [];
    this.discussion = discussion
      || new DiscussionModel(undefined, undefined, undefined, undefined, undefined, 0, false, []);
    this.prologue = prologue;
  }

  clearStatus() {
    this.status = undefined;
    this.previousStatuses = [];
  }

  sortAllParcours(allParcours: ParcoursModel[], connected: ConnectedModel) {
    let marketIdentifiers = connected.premium.marketIdentifier ? connected.premium.marketIdentifier : 0
    let sortedParcoursList: ParcoursModel[] = [];
    allParcours.map((p) => {
      if ((p.marketIdentifier & marketIdentifiers) != 0)
        sortedParcoursList.unshift(p);
      else
        sortedParcoursList.push(p);
    })
    return sortedParcoursList;
  }

  setAllParcours(allParcours: ParcoursModel[], connected?: ConnectedModel) {
    if (connected && connected.premium)
      this.allParcours = this.sortAllParcours(allParcours, connected);
    else 
      this.allParcours = allParcours;
  }

  setCurrentParcours(parcours: ParcoursModel) {
    this.currentParcours = new ParcoursModel(
      parcours.id, parcours.name, parcours.description, parcours.image, true, parcours.marketIdentifier, parcours.parcoursPrice,
      parcours.stripeIdentifier, parcours.parcoursOrder, parcours.titleDescription, parcours.objective, "", 
      parcours.videosDescription, parcours.authorsLink, parcours.authorsDescription, parcours.marketDescription, parcours.currentPrice, 
      parcours.promotionPercentage, parcours.promoCode, parcours.parcoursVideoUrl, parcours.shortName, parcours.shortImage,
      parcours.questions.map(q => new ParcoursQuestionModel(
        q.id, q.label, q.character, q.inspire, q.answers, undefined, undefined
      )), undefined, parcours.videos.map(v => new VideoModel(v.id, v.name, v.videoName, v.index, v.url, v.mandatory, true)),
      undefined, parcours.summary, parcours.summaryExamples
    );
  }

  setStatus(status: ParcoursStatusModel) {
    this.status = status;
  }

  addPreviousStatus(status : ParcoursStatusModel) {
    this.previousStatuses.push(status);
  }

  setPrologue(prologueId: number, prologue: ParcoursPrologueModel, chapterId: number) {
    this.prologue = new ParcoursPrologueModel(prologueId, prologue.parcoursTextId, prologue.index,
      prologue.last, prologue.text, chapterId, prologue.title, prologue.description, prologue.value,
      prologue.previous, prologue.next);
  }

  toJS() {
    return {
      allParcours: this.allParcours.map(p => p.toJS()),
      currentParcours: this.currentParcours.toJS(),
      parcoursProgress: this.parcoursProgress.toJS(),
      status: this.status.toJS(),
      discussion: this.discussion.toJS(),
      prologue: this.prologue.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const allParcours = object.allParcours.map((p: any) => ParcoursModel.fromJS(p));
      const allPreviousStatuses = object.previousStatuses.map((p: any) => ParcoursStatusModel.fromJS(p));
      const currentParcours = ParcoursModel.fromJS(object.currentParcours);
      return new MainParcoursModel(allParcours, currentParcours,
        ParcoursProgressModel.fromJS(object.parcoursProgress),
        ParcoursStatusModel.fromJS(object.status),
        allPreviousStatuses,
        DiscussionModel.fromJS(object.discussion),
        ParcoursPrologueModel.fromJS(object.prologue));
    }
    return undefined;
  }
}
