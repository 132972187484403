import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import TrainingConstraint from '../text/training/TrainingConstraint';
import Store from '../../../stores/Store';

interface NewTrainingModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  forceTraining?: boolean;
  text?: { id: number, url: string, chapterId: number };
}

interface NewTrainingModalState {
  constraint: boolean;
}

@inject('store') @observer
export default class NewTrainingModal
  extends React.Component<NewTrainingModalProps, NewTrainingModalState> {
  constructor(props: NewTrainingModalProps) {
    super(props);
    this.generateTrainingInstructions = this.generateTrainingInstructions.bind(this);
    this.startTraining = this.startTraining.bind(this);
    this.handleConstraintChange = this.handleConstraintChange.bind(this);
    this.state = {
      constraint: false
    };
  }
  generateTrainingInstructions() {
    this.props.store.generateTrainingInstructions(this.state.constraint, false, this.props.text);
  }
  startTraining() {
    if (this.props.forceTraining) {
      this.props.store.markParcoursTraining();
    }
    this.props.store.startTraining();
    browserHistory.push({
      pathname: '/write/training',
      state: {
        newPart: false,
        showCongratulationsChapter: false
      }
    });
  }
  handleConstraintChange() {
    const newConstraint = !this.state.constraint;
    this.setState({
      constraint: newConstraint
    });
    this.props.store.generateTrainingInstructions(newConstraint, true, this.props.text);
  }
  render() {
    const premium = this.props.store.connected.premium.premium;
    const trainingInstructions = this.props.store.write.trainingInstructions;

    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Entraînement</div>
        <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          <p>Le mode <b>Entraînement</b> vous permet de vous exercer dix minutes sur un sujet tiré au hasard.</p>
          {trainingInstructions ?
            <div>
              <div className="ui divider" />
              <blockquote>{trainingInstructions.pitch}</blockquote>
              <Checkbox
                checked={this.state.constraint}
                onClick={() => this.handleConstraintChange()}
                label="Ajouter une contrainte"
              />
              {trainingInstructions.constraint ?
                <TrainingConstraint constraint={trainingInstructions.constraint} />
                : undefined}
            </div>
            : undefined}
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
          <button className="ui orange button" onClick={this.generateTrainingInstructions}>Je veux d'autres instructions</button>
          <button className="ui primary button" onClick={this.startTraining}>Commencer !</button>
        </div>
      </Modal>
    );
  }
}
