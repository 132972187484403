import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ChapterWithChildren from './ChapterWithChildren';
import Store from '../../../../stores/Store';
import ChapterParams from '../ChapterParams';
import Loader from '../../../misc/Loader';

interface WriteChapterProps {
  store?: Store;
  params: ChapterParams;
  location: any;
}

@inject('store') @observer
export default class WriteChapter extends React.Component<WriteChapterProps, {}> {

  intervals: any;

  static fetchData(store: Store, params: ChapterParams) {
    const chapter = store.write.currentText.currentChapter;
    if (!chapter || !chapter.textContentState
      || (chapter.id !== params.chapterId)) {
      return store.loadTextChapter(params.textId, params.chapterId);
    }
  }
  render() {
    const cp = this.props.store.write.currentText.currentChapter;
    return (
      <div>
        {(this.props.store.write.loading && !cp) ?
          <Loader message="Chargement..." />
          :
          <div>
            <ChapterWithChildren params={this.props.params} location={this.props.location} />
          </div>
        }
      </div>
    );
  }
}
