import * as React from 'react';
import { Link } from 'react-router';
import Title from '../misc/Title';

interface CGUState {
  showEmails: boolean;
}

export default class CGU extends React.Component<{}, CGUState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const adminEmail = this.state.showEmails ? 'admin@atelierdesauteurs.com' : '';
    const contactEmail = this.state.showEmails ? 'contact@atelierdesauteurs.com' : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Conditions générales d'utilisation " />
          <h1>Conditions générales d'utilisation <small>(dernière mise à jour : le 23 avril 2022)</small></h1>
          <div className="ui basic segment">
            <h2>I - Acceptation des CGU</h2>
            <p>En créant un compte sur le site www.atelierdesauteurs.com (« le Site »), vous acceptez d’être lié par les présentes Conditions Générales d’Utilisation, à la société française Atelier des auteurs, SASU au capital de 5.000€ immatriculée sous le numéro 820 829 356 au RCS de Paris, dont le siège social se situe au 92 avenue de France, 75013 Paris (ci-après « Nous » ou « L’Atelier des auteurs »), qui édite le Site.</p>
            <p>Toute personne inscrite au Site, à titre gratuit ou onéreux, est ci-après dénommée « Membre » ou « Utilisateur » ou « Vous ».</p>
            <p>L’Atelier des auteurs se réserve le droit de modifier, à tout moment, les présentes Conditions Générales d’Utilisation et toute modification entrera alors en vigueur dès sa publication sur le Site. Ces modifications n’auront pas d’effet rétroactif.</p>
            <p>Pour être Membre, vous devez être âgé d’au moins 16 ans. L’Atelier des auteurs se réserve le droit de refuser la création de compte ou de supprimer le compte de toute personne âgée de moins de 16 ans.</p>
            <p>Dans un esprit de transparence et d’opposabilité, tous les Membres seront prévenus lors des changements importants des présentes Conditions Générales d’Utilisation. Il incombe au Membre de lire régulièrement les Conditions Générales d’Utilisation (ci-après « CGU ») pour prendre connaissance des modifications apportées.</p>

            <h2>II - Présentation du site et des services fournis</h2>
            <p>Le Site est une plateforme en ligne communautaire permettant à ses membres inscrits (qualifiés « d'Auteurs » ou « Membres ») de créer, diffuser et partager des œuvres écrites via un compte personnel crée sur le Site (qualifié de « Compte »).</p>
            <p>Le Site permet aux Membres de consulter librement les créations des autres membres inscrits.</p>
            <p>Le Site permet à ses Membres de publier des textes à vocation littéraires (qualifiées de « Créations Littéraires »). Chaque Création Littéraire peut être accompagnée d’une image, qualifiée de « Couverture », ainsi que d’une présentation (qualifiée de « Présentation »).</p>
            <p>Les Membres peuvent proposer ou répondre à des « Défis littéraires ». Ces derniers sont des consignes, thématiques ou même idées permettant aux autres Membres de s’en inspirer afin de rédiger des “Créations Littéraires”.</p>
            <p>Ces Créations Littéraires sont hébergées et visibles sur le Site. Les Membres peuvent les commenter et les annoter par des textes qualifiés de « Commentaires ».</p>
            <p>Les Membres peuvent personnaliser leur profil, en téléchargeant une photo de profil (qualifié « d’Avatar »), en écrivant un court texte de présentation ou en répondant au questionnaire disponible sur leur Compte. Les Membres peuvent participer à des échanges libres avec d'autres membres (qualifiés de « Discussions »).</p>
            <p>Les Auteurs peuvent également décider de masquer ou non leurs Créations Littéraires et leurs différentes versions aux autres Membres. Seules les Créations Littéraires répondant directement à un Défi Littéraire ne peuvent être cachées au public.</p>
            <p>Les Créations Littéraires, Défis Littéraires, Commentaires, Présentations, Avatars, Discussions ou Couvertures sont génériquement qualifiés de « Contenus » dans les présentes CGU.</p>
            <p>Le Site propose également des formules d’abonnement payantes permettant au Membre d’accéder à une collection d’outils en ligne et de contenus destinés à l’aider à écrire des œuvres.</p>

            <h2>III – Création d’un Compte</h2>
            <h4>1- Informations fournies par les Membres</h4>
            <p>En vous inscrivant sur le Site, vous vous engagez à renseigner des informations exactes, véridiques, complètes et à jour. Vous êtes seul responsable de la sincérité et de l'exactitude de ces Informations. Vous vous engagez à mettre à jour régulièrement l’ensemble de vos informations, afin de préserver leur exactitude.</p>

            <h4>2 – Mot de passe</h4>
            <p>Vous êtes responsable de la robustesse et de la confidentialité du mot de passe que vous utilisez pour accéder au Site et pour toutes les activités liées à votre Compte. Vous acceptez de ne pas utiliser le compte, le nom d’utilisateur ou le mot de passe d’un autre Membre à aucun moment. Vous vous engagez également à ne pas divulguer votre mot de passe à un tiers.</p>
            <p>Vous acceptez de nous avertir immédiatement dans le cas où vous soupçonneriez une utilisation non autorisée de votre compte ou un accès frauduleux à votre compte.</p>
            <p>L’Atelier des auteurs ne saurait être tenu pour responsable d'un quelconque dommage résultant d'un manquement quant à la robustesse ou la confidentialité de votre mot de passe.</p>

            <h4>3 - Mode de connexion</h4>
            <p>Vous êtes seul responsable du mode de connexion utilisé pour créer votre Compte.</p>
            <p>Nous vous informons qu’en cas de création d’un Compte via vos identifiants Facebook, la validité de votre Compte sera conditionnée à celle de votre compte Facebook. Ainsi, la résiliation de votre compte Facebook, ou tout problème de connexion lié à Facebook pourrait empêcher l’accès à votre Compte, ce dont l’Atelier des auteurs ne pourra en aucun cas être tenu responsable.</p>

            <h2>IV - Propriété intellectuelle</h2>
            <h4>1 – Propriété intellectuelle afférente au Site et aux éléments qui le composent</h4>
            <p>La structure générale du Site, ainsi que l’ensemble des textes, graphiques, images, sons ou vidéos le composant, à l’exception du Contenu posté par les Membres, sont la propriété exclusive de l’Atelier des auteurs.</p>
            <p>Le Membre reconnait qu'aucune propriété ne lui est transmise, et qu'aucun droit ou licence ne lui est accordé(e), en dehors d’un droit d’utiliser le Site conformément aux présentes.</p>
            <p>La marque Atelier des auteurs est une marque déposée auprès de l’INPI.</p>
            <p>Toute utilisation non expressément autorisée d'éléments du Site engage la responsabilité civile et/ou pénale de son auteur et sera susceptible d'entraîner des poursuites judiciaires à son encontre.</p>

            <h4>2 – Propriété intellectuelle des Contenus :</h4>
            <p>L’Atelier des auteurs ne revendique aucun droit de propriété sur l’ensemble des Contenus qui pourraient être publiés sur le Site par ses Membres.</p>
            <p>Pendant la durée de l’hébergement de votre Contenu sur le Site, vous concédez à l’Atelier des auteurs une licence gratuite non-exclusive, mondiale et pour la durée de la protection de l’œuvre aux fins de communication, directe ou indirecte, de l’œuvre au public. Cette licence permet notamment au Site de stocker, afficher, adapter, reproduire et représenter les Contenus publiés.</p>
            <p>Dès lors que vous publiez un Contenu sur le Site, vous acceptez que tout Membre puisse disposer à titre gratuit et à des fins personnelles de la faculté de consulter celui-ci pendant toute la période de son hébergement sur le Site.</p>
            <p>Vous acceptez également que des liens vers votre Contenu soient diffusés par tout Utilisateur.</p>
            <p>Vous acceptez que chaque Membre puisse créer une version électronique de vos Créations Littéraires et de sa Couverture aux formats ePub et PDF à partir du Site si vous accordez ce droit dans les options de leur publication.</p>
            <p>Vous consentez également à ce que tout Membre du Site, ou l’Atelier des auteurs, puissent promouvoir vos Contenus ou informer de leur existence auprès de tout public. Et cela par l’intermédiaire de tous supports, comprenant entre autres la publication d’un lien vers vos œuvres sur des réseaux sociaux tels que Facebook ou Twitter.</p>
            <p>Une fois enregistrée sur nos serveurs, une Création Littéraire est associée à une date de dépôt. Cette date de dépôt n’est pas l’équivalent juridique d’un constat réalisé par un huissier ou par un dépôt légal. Malgré sa réfutabilité, cette date de dépôt peut constituer une preuve d’antériorité de création. Vous êtes cependant conscient que supprimer un Contenu du Site le fait disparaître de nos bases de données, et donc fait également disparaître cette date de dépôt.</p>
            <p>Dès lors que vous commentez ou annotez une Création Littéraire, vous acceptez qu’un Membre puisse utiliser vos Commentaires afin de modifier sa Création Littéraire. Vous acceptez de ne pas revendiquer la propriété de l’œuvre modifiée par un autre Membre grâce à vos Commentaires.</p>
            <p>Dès lors que vous proposez un Défi Littéraire et qu’un autre Membre y répond par une Création Littéraire, vous acceptez de ne pas revendiquer la propriété de l’œuvre ainsi créée par un autre Membre.</p>

            <h4>3- Base de données</h4>
            <p>Les données associées à chaque Compte et au contenu du Site sont stockées dans les bases de données gérées et possédées par l’Atelier des auteurs. Si les Créations Littéraires, par exemple, sont la propriété de leur auteur respectif, les bases de données de l’Atelier des auteurs bénéficient de la protection sui generis des bases de données. Il est donc interdit de reproduire et exploiter leur contenu par quelque moyen que ce soit sans autorisation préalable de l’Atelier des auteurs, notamment par l’intermédiaire d’un système de requête automatique visant à collecter tout ou partie des données de ces bases.</p>

            <h2>V - Contenu mis en ligne par les Membres et Responsabilité</h2>
            <p>Vous acceptez de n’utiliser et diffuser sur le Site que du Contenu dont vous êtes propriétaire, ou pour lequel vous disposez d’une licence vous autorisant à l’utiliser et le diffuser, ou qui appartient au domaine public.</p>
            <p>Vous assumez l’entière responsabilité de vos publications et du Contenu (texte, image, photo ou autres contenus) que vous partagez sur le Site. Vous vous engagez également à ne publier aucun Contenu qui irait contre les lois de votre pays ou les lois françaises.</p>
            <p>Vous pouvez poster des images destinées à illustrer votre Avatar ou votre Couverture. Vous vous engagez dans ce cadre à respecter les droits des tiers (droit d’auteur, droit à l’image, etc…) et les lois françaises.</p>
            <p>Vous êtes également informé que des Membres du Site peuvent être âgés de moins de 18 ans. En conséquence, vous vous engagez à ne pas diffuser d’images à caractère pornographique sur le Site.</p>
            <p>Et plus particulièrement, vous nous garantissez que les Contenus publiés sur le Site ne contreviennent pas aux droits des tiers (notamment aux droits de la personnalité tels le droit au respect de la vie privée, le droit à l’image, etc… ; aux droits de propriété intellectuelle tels droit d’auteur, droits voisins, droit sur les bases de données, droit des marques, droit des brevets, dessins ou modèles, secret de fabrique, etc…), aux dispositions relatives à la diffamation, à l’injure.</p>
            <p>Vous acceptez de n’utiliser le Site et ses services que dans un but conforme à sa destination et à en faire un usage loyal et licite. Vous vous engagez ainsi à :</p>
            <ul>
              <li>Garantir que vous êtes l’auteur des Contenus publiés sur le Site ;</li>
              <li>Garantir le bon usage du Site ;</li>
              <li>Ne pas publier de communications commerciales sans notre autorisation préalable et ne pas solliciter les autres Membres à des fins commerciales ;</li>
              <li>Ne pas publier des Contenus malveillants, dénigrants, obscènes, pornographiques, diffamatoires, volontairement trompeurs, illicites et/ou contraires aux bonnes mœurs, de nature à harceler ou intimider un autre Membre, incitant à la haine, à la violence ou des Contenus qui soient menaçants envers les autres Membres ;</li>
              <li>A respecter la politique de modération du Site accessible <Link to="/moderation">ici</Link> ;</li>
              <li>Respecter les droits des tiers, et notamment le droit de chacun au respect de sa vie privée, de son image et de ses autres droits de la personnalité, ainsi que les droits de propriété intellectuelle (droit d'auteur, droits voisins, droit sur les bases de données, droit des marques, droit des brevets, dessins ou modèles, secret de fabrique…) ;</li>
              <li>Ne pas usurper la qualité, l’attribut ou l’identifiant d’un autre Membre ou d’un tiers de nature à induire en erreur ou à créer une confusion quelconque quant à votre identité, à la provenance des Contenus publiés sur le Site ;</li>
              <li>Ne pas altérer ou perturber l'intégrité du Site et/ou des données qui y sont contenues ;</li>
              <li>Ne pas utiliser le Site dans le seul but de nuire au bon fonctionnement du Site, aux partenaires de l’Atelier des auteurs ou à toute autre personne ;</li>
              <li>Ne pas tenter d'obtenir un accès non autorisé au Site, aux comptes des autres Membres ou aux systèmes ou réseaux qui lui sont associés ou d’intercepter des données ;</li>
              <li>Utiliser le Site dans le respect des législations et réglementations nationales et/ou internationales applicables.</li>
            </ul>
            <p>En cas de manquement à l’une de ces obligations, l’Atelier des auteurs se réserve le  droit de réagir en conséquence. Il pourra, en toute discrétion :</p>
            <ul>
              <li>Avertir le Membre de sanctions potentielles.</li>
              <li>Supprimer du Contenu.</li>
              <li>Suspendre temporairement ou supprimer définitivement le Compte.</li>
              <li>Prendre toutes mesures légales appropriées et engager toute action en justice.</li>
              <li>Avertir les autorités compétentes, coopérer avec elles et leur fournir toutes les informations utiles à la recherche et à la répression d’activités illégales ou illicites.</li>
            </ul>
        
            <h2>VI - Notre Responsabilité</h2>
            <p>Vous êtes informé qu’en raison des caractéristiques propres à Internet et à la démarche de publication publique d’œuvres, il existe un risque de détournement, piratage ou plagiat de tout Contenu ou des données publiées sur le Site. L’Atelier des auteurs ne saurait être tenu pour responsable de tels désagréments ou dommages.</p>
            <p>Le Site peut contenir des liens hypertextes vers des sites tiers. L’Atelier des auteurs n'exerce aucun contrôle sur les sites vers lesquels ces liens renvoient et décline toute responsabilité quant à l'accès, au contenu ou à l'utilisation de ces sites, ainsi qu'aux dommages pouvant résulter de la consultation des informations présentes sur ces sites.</p>
            <p>Le membre qui insère un lien hypertexte sur le Site s’engage à ce que le site tiers vers lequel renvoie ce lien ne soit pas illicite ou nuisible de quelque manière que ce soit aux autres Membres.</p>
            <p>Dès lors que le Membre est redirigé sur le site d’un tiers, les conditions d’utilisation du site en cause s’appliquent en lieu et place des présentes CGU, qui cessent de s’appliquer.</p>
            <p>La décision de cliquer sur ces liens relève de la pleine et entière responsabilité du Membre.</p>
            <p>Si l’Utilisateur accède, par l’intermédiaire du Site, à un site tiers illicite ou nuisible de quelque manière que ce soit, il en avise immédiatement l’Atelier des auteurs de façon à lui permettre de supprimer sans délai le lien renvoyant vers le site litigieux.</p>
        
            <h2>VII - Modération des Contenus mis en ligne sur le Site</h2>
            <p>En sa qualité d'hébergeur des Contenus mis en ligne par les Membres par l’intermédiaire du Site, l’Atelier des auteurs n’est pas en mesure de contrôler en amont les Contenus mis en ligne par ses Membres.</p>
            <p>L’Atelier des auteurs est donc soumis au régime de responsabilité atténuée prévu aux articles 6.I.2 et suivants de la loi nº2004-575 du 21 juin 2004 pour la Confiance dans l'Economie Numérique. Il pourra, dans ce cadre, supprimer les contenus manifestement illicites qui lui seront notifiés par un tiers, conformément au présent article.</p>
            <p>L’Atelier des auteurs se réserve le droit de supprimer tout Contenu qui pourrait être considéré comme une incitation à la réalisation de crimes et délits, diffusion de messages violents, injurieux, racistes, révisionnistes, faisant l'apologie des crimes de guerre et/ou du terrorisme, pédophiles, appelant au meurtre ou incitant au suicide.</p>
            <p>L’Atelier des auteurs se réserve également le droit de supprimer tout contenu qui pourrait enfreindre les lois sur la propriété intellectuelle, le droit d'auteur ou le droit à l'image, ou la politique de Modération du Site, que chaque Membre s’engage à respecter en acceptant les présentes CGU.</p>
            <p>Un Membre peut signaler tout texte semblant contraire aux Conditions Générales d’Utilisation ainsi qu’à la loi française, et cela selon toute façon lui semblant nécessaire : envoi d’un message électronique à l’adresse {adminEmail}, ou grâce au bouton "Signaler à la modération" situé à côté de chaque texte.</p>

            <h2>VIII – Suppression d’un compte et de Contenu</h2>
            <p>Un Membre peut demander à supprimer son compte en envoyant un email à l’adresse de contact du Site : {contactEmail}.</p>
            <p>De même, l’Atelier des auteurs se réserve le droit de supprimer un Contenu ou le Compte d’un Utilisateur qui enfreindrait la politique de Modération du Site ou l’une ou l’autre des obligations visées aux articles IV et V des présentes CGU.</p>
            <p>En cas de suppression du compte d’un Membre, les Contenus qu’il a publiés sur le Site seront supprimés . Ces Contenus seront toutefois sauvegardés temporairement pendant un (1) mois mais resteront inaccessibles aux Membres et à l’Utilisateur. Les données personnelles associées à ce compte seront supprimées dans les délais définis à la Politique de protection des données personnelles accessible en pied de page du Site.</p>
            <p></p>
            
            <h2>IX - Accès au Site et aux services fournis</h2>
            <p>L’Atelier des auteurs fera tout son possible pour rendre le Site accessible en permanence, sauf opérations de maintenance, mises à jour ou cas de force majeure. L’Atelier des auteurs ne saurait être tenu responsable de toute conséquence résultant d’une indisponibilité du Site. L’Atelier des auteurs ne saurait non plus être tenue responsable en cas d’utilisation du Site non conforme aux présentes CGU.</p>
            <p>De plus, l’Atelier des auteurs ne donne aucune garantie, expresse ou implicite, que le Site puisse être exempt d'erreurs, de vice, de défauts ou bugs. L’Atelier des auteurs ne donne pas non plus la garantie que les services standards fournis par l’Atelier des auteurs répondront aux besoins et attentes d'une utilisation spécifique du Site, selon les contraintes personnelles d'un Utilisateur.</p>
            <p>L’Atelier des auteurs ne garantit pas la pérennité ou les performances du Site.</p>
            <p>L’Atelier des auteurs se réserve le droit de suspendre, sans préavis, l'accès au Site, temporairement ou définitivement, notamment pour des raisons de maintenance, de mise à jour et en cas d'urgence.</p>
            <p>L’interruption temporaire ou définitive du Site n’engagera pas la responsabilité de l’Atelier des auteurs et ne donnera droit à aucune indemnité à l’Utilisateur.</p>
            <p>En conséquence, l’Atelier des auteurs ne peut être tenue pour responsable de la perte d'argent, ou de réputation, ni des dommages spéciaux, indirects ou induits résultant de l’interruption du Site.</p>
            <p>Pour éviter autant que possible des désagréments, l’Utilisateur doit s’assurer de la réalisation régulière de sauvegardes concernant ses Créations Littéraires en en conservant une copie au fur et à mesure de leur écriture.</p>
            <p>L’Utilisateur reconnait utiliser le Site tel qu’il lui est fourni, à ses risques et périls et ce, en parfaite connaissance de cause.</p>
            
            <h2>X - Données personnelles</h2>
            <p>En créant un Compte sur le Site, nous collections et traitons des informations personnelles vous concernant.</p>
            <p>Pour en savoir plus sur les modalités de traitement de vos données personnelles, nous vous invitons à consulter notre Politique de <Link to="/privacy">Protection des Données Personnelles</Link>.</p>
            
            <h2>XI – Loi applicable</h2>
            <p>Les présentes CGU sont régies par la loi française.</p>

            <h2>XII - POLITIQUE FOUILLE DE TEXTES ET DE DONNEES &lt; TDM-RESERVATION: 1 &gt;</h2>
            <p>Nous nous opposons à toutes opérations de moissonnage et de fouille de textes et de données au sens de l’article L. 122-5-3 du code de la propriété intellectuelle, notamment à des fins d’entraînement d’une intelligence artificielle.</p>
            <p>Cette opposition couvre l’ensemble du site et des contenus auxquels il donne accès.</p>
            <p>Toutes opérations de moissonnage et de fouille de textes et de données visant le site et ses contenus, y compris par des dispositifs de collecte automatisée de données, constituent donc des actes de contrefaçon sauf obtention préalable d’un accord spécifique formellement exprimé de notre part.</p>
            <p>Pour faciliter la lecture de ce droit d’opposition par tout dispositif de collecte automatisée de données, cette opposition est également exprimée ainsi &lt; TDM-RESERVATION: 1 &gt;</p>
          </div>
        </div>
      </div>
    );
  }
}
