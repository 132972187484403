const participePasse = [
  'é',
  'és',
  'ée',
  'ées',
  'i',
  'is',
  'ie',
  'ies',
  's',
  'se',
  'ses',
  'us',
  'ute',
  'utes'
];

export default participePasse;
