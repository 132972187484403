import { observable } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import convertFromHtml from '../../components/editor/convertFromHtml';
import convertToHtml from '../../components/editor/convertToHtml';

class LightChapterModel {
  @observable id: number;
  @observable index: number;
  @observable title: string;
}

export default class CharacterFullModel {

  @observable id: number;
  @observable name: string;
  @observable description: string;
  @observable descriptionState: any;
  @observable image: string;
  @observable imageFile: File;
  @observable chapters: LightChapterModel[];
  @observable undeletable: boolean;
  @observable nameError: boolean;

  constructor(id: number, name: string, description: string, image: string,
    chapters: LightChapterModel[], undeletable: boolean) {
    this.id = id;
    this.name = name;
    this.description = description || '<div></div>';
    let contentState = convertFromHtml(this.description);
    this.descriptionState = EditorState.createWithContent(contentState);
    this.image = image;
    this.chapters = chapters || [];
    this.undeletable = undeletable;
    this.nameError = false;
  }

  updateName(name: string) {
    const nameError = this.nameError && name.trim().length === 0;
    this.name = name;
    this.nameError = nameError;
  }

  updateDescriptionState(descriptionState: any) {
    this.descriptionState = descriptionState;
    this.description = convertToHtml(this.descriptionState);
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      image: this.image,
      chapters: this.chapters,
      undeletable: this.undeletable
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CharacterFullModel(object.id, object.name,
        object.description, object.image, object.chapters, object.undeletable);
    }
    return undefined;
  }
}
