import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { scroller } from 'react-scroll';
import Comment from './Comment';
import CommentForm from './CommentForm';
import CommentModel from '../../models/comment/CommentModel';
import CommentListModel from '../../models/comment/CommentListModel';
import UserModel from '../../models/UserModel';
import Store from '../../stores/Store';

interface CommentChildrenProps {
  store?: Store;
  comment: CommentModel;
  commentChildren: CommentModel[];
  author: UserModel;
  memberId: number;
  authorText: number;
  authorDefi: number;
  readonly: boolean;
  admin: boolean;
  newsfeedElement: boolean;
  comments: CommentListModel;
}

@inject('store') @observer
export default class CommentChildren extends React.Component<CommentChildrenProps, {}> {
  constructor(props: CommentChildrenProps) {
    super(props);
    this.handleCommentAnswerSubmit = this.handleCommentAnswerSubmit.bind(this);
    this.handleCommentAnswerCancel = this.handleCommentAnswerCancel.bind(this);
  }
  handleCommentAnswerSubmit(text: string) {
    return this.props.store.handleCommentSubmit(this.props.comments, text, this.props.comment.id).then((commentId: number) => {
      const commentAnchor = `comment_${commentId}`;
      scroller.scrollTo(commentAnchor, {
        duration: 500,
        delay: 100,
        smooth: true
      });
    });
  }
  handleCommentAnswerCancel() {
    return this.props.store.handleCancelAnswer(this.props.comment);
  }
  render() {
    const that = this;
    let childNodes: any[];
    if (this.props.commentChildren && this.props.commentChildren.length > 0) {
      childNodes = this.props.commentChildren.map(comment => (
        <Comment
          key={comment.id}
          comment={comment}
          commentChildren={comment.childs}
          parentId={that.props.comment.id}
          author={that.props.author}
          memberId={that.props.memberId}
          authorText={that.props.authorText}
          authorDefi={that.props.authorDefi}
          readonly={that.props.readonly}
          admin={that.props.admin}
          newsfeedElement={that.props.newsfeedElement}
          comments={this.props.comments}
        />
      ));
    }
    return (
      <div className="comments">
        {this.props.comment.answerMode
          ? <CommentForm
            author={this.props.author}
            submit={this.handleCommentAnswerSubmit}
            comment={this.props.comment}
            textEdit=""
            focus
            cancel={this.handleCommentAnswerCancel}
          />
          : null
        }
        {childNodes}
      </div>
    );
  }
}

