import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { scroller } from 'react-scroll';
const Waypoint = require('react-waypoint');
import Store from '../../stores/Store';
import DefiParams from './DefiParams';
import CommentBox from '../comment/CommentBox';
import Loader from '../misc/Loader';
import DefiPageAuthor from './DefiPageAuthor';
import DefiRepondantActive from './DefiRepondantActive';
import DefiPageNorAuthorNotRepondantActive from './DefiPageNorAuthorNotRepondantActive';
import TextGallery from '../misc/text/TextGallery';
/* import DefiSimilar from './DefiSimilar'; */
import NotConnectedBandeau from '../misc/NotConnectedBandeau';

interface DefiPageProps {
  store?: Store;
  params: DefiParams;
}

interface DefiPageState {
  enableComponents: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class DefiPage extends React.Component<DefiPageProps, DefiPageState> {
  static fetchData(store: Store, params: DefiParams) {
    const p: any[] = [];
    p.push(store.loadDefi(params.defiId, false));
    /* p.push(store.loadDefiSimilar(params.defiId)); */
    return Promise.all(p);
  }
  constructor(props: DefiPageProps) {
    super(props);
    this.isAuthor = this.isAuthor.bind(this);
    this.isActiveReponse = this.isActiveReponse.bind(this);
    this.loadComments = this.loadComments.bind(this);
    this.loadDefiTexts = this.loadDefiTexts.bind(this);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      const url = window.location.href;
      const regexp = /#comment_(.*)/;
      const match = regexp.exec(url);

      if (match && match.length > 1) {
        this.props.store.loadDefiComments(this.props.params.defiId).then(() => {
          scroller.scrollTo(`comment_${match[1]}`, {
            offset: -100,
            duration: 500,
            delay: 100,
            smooth: true
          });
        });
      }

      this.setState({
        enableComponents: true
      });
    }
  }
  componentWillReceiveProps(nextProps: DefiPageProps) {
    if (this.props.params.defiId && this.props.params.defiId !== nextProps.params.defiId) {
      this.props.store.loadDefiComments(nextProps.params.defiId);
    }
  }
  isAuthor() {
    const connected = this.props.store.connected;
    const defi = this.props.store.mainDefi.defi;
    return this.props.store.isAuthenticated() && connected && connected.user
      && connected.user.id === defi.author.id;
  }
  isActiveReponse() {
    const connected = this.props.store.connected;
    const defi = this.props.store.mainDefi.defi;
    return this.props.store.isAuthenticated() && connected && connected.user
      && defi.myReponse && defi.myReponse.active;
  }
  loadComments() {
    return this.props.store.loadDefiComments(this.props.params.defiId);
  }
  loadDefiTexts() {
    return this.props.store.loadDefiTexts(this.props.params.defiId);
  }
  render() {
    const defi = this.props.store.mainDefi.defi;
    const connected = this.props.store.connected;
    const blockedBy = defi && connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(defi.author.id) !== -1;
    const admin = connected && connected.user && connected.user.admin;
    const metaProperties = defi ? [
      {
        name: 'description',
        content: (defi.defiType ?
          defi.description.split('<<<<<>>>>>')[0].replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '').substring(0, Math.min(defi.description.split('<<<<<>>>>>')[0].length, 200))
          : defi.description.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '').substring(0, Math.min(defi.description.length, 200))
        )
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@Atelier_Auteurs' },
      { name: 'twitter:title', content: defi.title },
      { name: 'twitter:creator', content: defi.author.twitter ? defi.author.twitter : '' },
      { name: 'twitter:description', content: `${defi.author.identity} vous lance un défi !` },
      { name: 'twitter:image', content: defi.author.avatarUrl },
      { property: 'fb:app_id', content: '460911020714321' },
      { property: 'og:title', content: defi.title },
      { property: 'og:image', content: defi.author.avatarUrl },
      { property: 'og:description', content: `${defi.author.identity} vous lance un défi !` }
    ]
      : [];
    return (
      <div>
        {defi && !defi.defiType ?
          <NotConnectedBandeau
            title="Relevez des défis littéraires sur l'Atelier des auteurs !"
            body="L'imagination est un muscle. Inscrivez-vous à l'Atelier des auteurs et entraînez-vous en participant aux défis littéraires !"
          />
          : undefined
        }
        {/* <div className="ui two column stackable grid" style={{ marginTop: '-30px' }}> */}
          {/* <div className="ten wide column" style={{ paddingTop: '50px', paddingBottom: '80px' }}> */}
            <div className="ui grid container centered stackable" style={{ backgroundColor: '#fff', minHeight: '95vh', paddingBottom: '30px', paddingTop: '30px' }}>
              <div className="twelve wide column">
                {defi ?
                  <div>
                    <Title
                      title={`Défi littéraire : ${defi.title} `}
                      meta={metaProperties}
                    />
                    <div className="ui stackable container">
                      <h1 className="ui massive breadcrumb">
                        <Link to="/defis" className="section">Tous les défis littéraires</Link>
                        <i className="fa fa-fw fa-chevron-right divider"></i>
                        <span className="active section">
                          <span style={{ position: 'relative' }}>
                            {defi.title}
                            <span style={{ marginRight: '10px' }}>
                              {defi.nouveau ?
                                <span className="ui small teal label">Nouveau</span>
                                : undefined}
                              {defi.defiType ?
                                <span className="ui small orange label"
                                  style={{ width: '83px' }}>
                                  À l'honneur
                              </span>
                                : undefined}
                            </span>
                          </span>
                        </span>
                      </h1>
                      {this.isAuthor() ?
                        <h2 className="ui header">
                          Vous avez lancé ce défi à la communauté !
                    </h2>
                        :
                        <h2 className="ui header">
                          <Link
                            to={`/author/${defi.author.id}/${defi.author.urlIdentity}`}
                          >
                            {defi.author.identity}
                          </Link>
                          &nbsp; vous lance un défi !
                    </h2>
                      }
                      <div>
                        <div className="ui padded basic quote medium text segment">
                          {defi.defiType ?
                            <span dangerouslySetInnerHTML={{ __html: defi.description.split('<<<<<>>>>>')[1] }} />
                            : <span dangerouslySetInnerHTML={{ __html: defi.description }} />
                          }
                          <div className="ui divider" />
                          {defi.genre ?
                            <div>
                              Le genre imposé est <b><FormattedMessage
                                id={`genre.${defi.genre}`}
                                defaultMessage="Genre"
                              /></b>.
                        </div>
                            : undefined}
                          {defi.textExample ?
                            <div>
                              {this.isAuthor() ?
                                <span>Vous avez donné votre interprétation du défi avec l'œuvre&nbsp;</span>
                                : <span>L'auteur du Défi a donné son interprétation du défi avec l'œuvre&nbsp;</span>
                              }
                              <Link
                                to={`/text/${defi.textExample.id}/${defi.textExample.urlText}`}
                              >
                                {defi.textExample.title}
                              </Link>
                            </div>
                            : undefined}
                        </div>
                      </div>
                    </div>
                    {this.isAuthor() ?
                      <DefiPageAuthor />
                      : (this.isActiveReponse() ? <DefiRepondantActive /> : <DefiPageNorAuthorNotRepondantActive />)}

                    {this.state.enableComponents ?
                      <div>
                        <Waypoint
                          key={'defi_texts_' + defi.id}
                          onEnter={this.loadDefiTexts}
                          scrollableAncestor={window}
                        />
                        {defi.texts && defi.texts.length > 0 ?
                          <div className="ui stackable container" style={{ marginTop: '20px' }}>
                            <h2 className="ui header">
                              {defi.texts.length === 1 ?
                                <span>Un auteur a déjà relevé</span> :
                                <span>{defi.texts.length} auteurs ont déjà relevé</span>
                              }
                              &nbsp;avec succès ce défi !
                          </h2>
                            <TextGallery texts={defi.texts} twoPerRow />
                          </div>
                          : undefined}
                      </div>
                      : undefined}
                    {!defi.defiType && this.state.enableComponents ?
                      <div className="ui container">
                        <Waypoint
                          key={'defi_' + defi.id}
                          onEnter={this.loadComments}
                          scrollableAncestor={window}
                        />
                        <h3 className="ui horizontal divider">
                          Commentaires {this.props.store.comments ?
                            `(${this.props.store.comments.comments.length})`
                            : undefined}
                        </h3>
                        {blockedBy ?
                          <div className="ui small message">
                            <Link to={`/author/${defi.author.id}/${defi.author.urlIdentity}`}>
                              {defi.author.identity}</Link> vous a bloqué.
                        &nbsp;Vous ne pouvez pas participer aux commentaires.
                      </div>
                          : undefined}
                        {this.props.store.comments ?
                          <CommentBox
                            admin={admin}
                            newsfeedElement={false}
                            readonly={blockedBy}
                            comments={this.props.store.comments}
                          />
                          : undefined}
                      </div>
                      : undefined}
                  </div>
                  : <Loader message="Chargement des commentaires..." />
                }
              </div>
            </div>
          {/* </div> */}
          {/* <div className="six wide right floated grey column" style={{ paddingTop: '50px', minHeight: '1000px' }}>
            <div className="ui grid centered stackable">
              <div className="twelve wide column">
                <div className="ui horizontal divider">Vous pourriez aussi aimer...</div>
                <div className="ui list">
                  <DefiSimilar defis={this.props.store.mainDefi.similar} />
                </div>
                <div className="ui hidden divider" />
                <div className="ui info message">
                  <h2 className="header">Qu'est-ce qu'un défi littéraire ?</h2>
                  <p>
                    Un défi littéraire est une <b>consigne d'écriture</b> qui peut s'appuyer sur une <b>thématique</b> ou une <b>contrainte formelle</b>.
                  </p>
                  <p>Il permet de s'entraîner, de trouver l'inspiration mais aussi de décomplexer l'acte d'écriture.</p>
                </div>
              </div>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}
