import * as React from 'react';
const ReactPlayer = require('react-player');
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import BilanModel from '../../../../../models/write/BilanModel';

interface ChapterCongratulationsParcoursProps {
  showCongratulationsParcours: boolean;
  bilan: BilanModel;
  close: Function | any;
}

export default class ChapterCongratulationsParcours extends React.Component<ChapterCongratulationsParcoursProps, {}> {
  render() {
    const videos = this.props.bilan ?
      this.props.bilan.videos.map((video) => {
        return (<ReactPlayer key={`video_${video.id}`} url={video.url} playing width="100%" />);
      })
      : undefined;
    return (
      <Modal
        open={this.props.showCongratulationsParcours}
        onClose={this.props.close}
        size="small"
      >
        <div className="header">Félicitations !</div>
        <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          {this.props.bilan ?
            <div>
              {this.props.bilan.videos && this.props.bilan.videos.length > 0 ? 
                <div>
                  {videos} 
                  <div className="ui hidden divider" />
                </div>
              : undefined}
              <div dangerouslySetInnerHTML={{ __html: this.props.bilan.bilan }} />
            </div>
            : undefined}
          <div className="ui info message">
            Maintenant que votre roman est terminé, vous avez la possibilité de le publier sur l'Atelier des auteurs et partager auprès
            des autres auteurs et de vos amis.
          </div>
        </div>
        <div className="actions">
          <button className="ui button" onClick={this.props.close}>Fermer</button>
        </div>
      </Modal>
    );
  }
}
