import Config from './config';
import Tokenize from './algos/tokenize';
import { findTokens, analyseTokens } from './algos/find-tokens';
import { stem, stemWord } from './algos/stem';
import PosTag from './algos/pos-tag';
import lemmatize from './algos/lemmatize';

export default class NlpjsTFr {
  config: Config;
  sentence: string;
  tokenized: any;
  foundTokensInDicts: any;
  posTagged: any;
  stemmed: any;
  lemmatized: any;

  constructor(sentence: string, userConfig: any) {
    this.config = new Config(userConfig);

    this.sentence = sentence;
    this.tokenized = Tokenize(this);
    this.foundTokensInDicts = null;
    this.posTagged = null;
    this.stemmed = null;
    this.lemmatized = null;
  }

  posTagger() {
    if (this.foundTokensInDicts === null) {
      this.foundTokensInDicts = analyseTokens(this);
    }
    this.posTagged = PosTag(this);
    return this.posTagged;
  }

  lemmatizer() {
    if (this.foundTokensInDicts === null) {
      this.foundTokensInDicts = analyseTokens(this);
    }
    this.lemmatized = lemmatize(this);
    return this.lemmatized;
  }

  stemmer() {
    this.stemmed = stem(this);
    return this.stemmed;
  }

  wordStemmer(word: string) {
    const stemmedWord = stemWord(word);
    return stemmedWord;
  }

}
