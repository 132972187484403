import * as React from 'react';
import { Link } from 'react-router';
import Title from '../misc/Title';
const Mailto = require('react-mailto').default;

interface CGVState {
  showEmails: boolean;
}

export default class CGV extends React.Component<{}, CGVState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const contactEmail = this.state.showEmails ? 'contact@atelierdesauteurs.com' : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Conditions générales de vente " />
          <h1>Conditions générales de vente <small>(dernière mise à jour : le 06 juin 2022)</small></h1>
          <div className="ui basic segment">
            <h2>I - Objet des C.G.V.</h2>
            <p>L’objet de ces Conditions Générales de Vente est la définition des conditions de vente et de mise à disposition des services proposés à la vente sur le site internet www.atelierdesauteurs.com (« le Site »).</p>
            <p>L’Atelier des auteurs propose des contenus en ligne payants destinés à vous apporter des outils et conseils d’écriture sur des thématiques diverses (« le Service »).</p>
            <p>Ce Service est fourni par la société française Atelier des auteurs, SASU au capital de 5.000€ immatriculée sous le numéro 820 829 356 au RCS de Paris, dont le siège social se situe au 92 avenue de France, 75013 Paris.</p>
            <p>Les termes en majuscule ci-après ont la signification qui leur est donnée aux présentes ou dans les Conditions Générales d’Utilisation du Site.</p>

            <h2>II - Acceptation des C.G.V.</h2>
            <p>Toute personne souhaitant bénéficier du Service (« l’Utilisateur ») doit :</p>
            <ul>
              <li>être majeure ou être âgée d’au moins 16 ans et être munie dans ce cas d’une autorisation des titulaires de l’autorité parentale, que l’Atelier des auteurs se réserve le droit de vérifier à tout moment, à défaut, la souscription sera considérée comme non valide et pourra être refusée ou annulée ;</li>
              <li>et avoir accepté d’être liée aux présentes Conditions Générales de Vente ainsi qu’aux Conditions Générales d’Utilisation du Site. L’acceptation des CGV forme un contrat entre l’Utilisateur et l’Atelier des auteurs (« le Contrat »).</li>
            </ul>
            <p>L’Atelier des auteurs se réserve le droit de modifier, à tout moment, les présentes Conditions Générales de Vente et toute modification entrera alors en vigueur dès sa publication sur le Site.</p>
            <p>L’Utilisateur sera informé de tout changement des présentes Conditions Générales de Vente. Il incombe à l’Utilisateur de lire les Conditions Générales de Vente lors de sa souscription à l’une des offres payantes et lors des mises à jour qui lui sont signalées.</p>

            <h2>III - Souscription au Service</h2>
            <h4>A) Présentation des offres</h4>
            <p>Le Site propose deux offres payantes :</p>
            <ul>
              <li>Une offre à l’unité donnant accès à un seul atelier d’écriture thématique (ci-après « l’Offre unitaire »);</li>
              <li>Une offre annuelle sans reconduction tacite donnant accès à l’intégralité des ateliers proposés sur le Site. au cours de la période d’engagement (ci-après « le Pack Universel »). L'offre pourra faire l’objet d’une reconduction à la demande de l’Utilisateur aux tarifs définis sur le Site.</li>
            </ul>
            <p>Chaque offre est payable en une seule fois, au moment de la souscription ou de son renouvellement. Les contenus proposés par chaque offre sont accessibles par l’Utilisateur pendant la durée de validité de son Compte et/ou de la disponibilité du Site et/ou du Service.</p>
            
            <h4>B) Souscription</h4>
            <p>L’Utilisateur doit disposer d’un Compte et avoir accepté les C.G.U. et les présentes C.G.V. du Site.</p>
            <p>L’Utilisateur doit fournir un moyen de paiement à jour, validé et accepté.</p>
            <p>Lors de l’inscription, l’Utilisateur accédera à l’interface Stripe sécurisée lui permettant d’entrer ses coordonnées bancaires et de souscrire à une offre payante.</p>
            <p>Une fois la souscription validée, l’Utilisateur peut immédiatement bénéficier du Service.</p>
            <p>Afin de mettre régulièrement à jour les bases de données Utilisateurs gérées par l’Atelier des auteurs, il sera envoyé à l’Utilisateur souscripteur à l’Offre unitaire, tous les 5 (cinq) ans, un mail à l’adresse e-mail renseignée lors de la souscription, ou modifiée par la suite, par lequel il sera invité à valider la poursuite de sa souscription.</p>
            <p>A défaut de validation dans un délai de 1 (un) mois calendaire suivant la réception du mail, l’Utilisateur sera réputé inactif et sa souscription sera résiliée le jour suivant.</p>
            
            <h4>C) Prix</h4>
            <p>Les prix sont affichés sur le Site en euros, toutes taxes comprises. Le prix à payer par l’Utilisateur est celui indiqué au moment de la validation de sa souscription.</p>
            <p>L’Atelier des auteurs se réserve le droit de modifier le prix des offres à tout moment.</p>
            <p>Les souscriptions doivent être payées par carte bancaire (carte Bleue, carte Visa, Eurocard, Mastercard, etc). Si le paiement se fait par des cartes émises par des banques domiciliées hors de France, elles doivent être obligatoirement des cartes bancaires internationales.</p>
            <p>La souscription de l’Utilisateur ou son renouvellement ne seront pas pris en compte en cas de paiement incomplet.</p>

            <h4>D) Absence de droit de rétractation</h4>
            <p>Conformément à l’article L.221-28 1° du code de la consommation, l’Utilisateur  reconnaît et accepte expressément (i) que la fourniture du Service commence dès la validation de la souscription au Service, soit avant la fin du délai de quatorze jours francs prévu par le code de la consommation et (ii) reconnaît et accepte en conséquence de ne pas bénéficier du droit de rétractation lié à la vente en ligne ou à distance.</p>
            <p>En conséquence, aucune demande de rétractation et de remboursement associée fondée sur le droit de rétractation ne sera recevable.</p>

            <h4>E) Résiliation et fin de la souscription</h4>
            <p>L’Utilisateur peut résilier sa souscription à tout moment à partir de la page de « Paramètres » de son Compte, auquel cas il ne pourra plus accéder aux contenus de l’offre souscrite. </p>
            <p>En cas de souscription au Pack universel, et à défaut de renouvellement express par l’Utilisateur avec paiement du tarif définit sur le Site, ce dernier prendra fin à l’arrivée du terme initial.</p>
            <p>Lorsque l’inscription a été réalisée via un compte ouvert auprès de Facebook, toute résiliation dudit compte Facebook entraînera le blocage de l’accès au Compte de l’Utilisateur sur le Site. Nous vous invitons dans ce cas à contacter l’Atelier des auteurs à l’adresse suivante : contact@atelierdesauteurs.com</p>

            <h2>IV - Les obligations et responsabilités de l’Utilisateur</h2>
            <h4>A) Majorité</h4>
            <p>L’Utilisateur doit être âgé de 18 ans ou avoir atteint la majorité légale dans son pays pour s’abonner au Service, ou s’il a moins de 18 ans, être muni d’une autorisation des titulaires de l’autorité parentale. Le Service n’est pas ouvert aux personnes âgées de moins de 16 ans.</p>

            <h4>B) Utilisation du service</h4>
            <p>Pour accéder au Service, l’Utilisateur doit disposer d’un accès Internet haut débit ainsi que d’un appareil et d’un navigateur compatibles avec les fonctionnalités du Site et du Service.</p>
            <p>L’utilisation du Service est soumise aux présentes CGV et aux CGU du Site.</p>
            <p>Le Service est réservé à un usage uniquement personnel et non-commercial dans un cadre privé. Durant toute la durée de sa souscription, l’Atelier des auteurs accorde à l’Utilisateur une licence restreinte, non-exclusive et incessible lui permettant d’utiliser les fonctionnalités et de consulter le contenu proposé sur le Service. À l’exception de cette licence, aucun droit supplémentaire n’est accordé.</p>
            <p>L’Utilisateur s’engage et garantit son bon usage du Service conformément à sa destination, et notamment à ne pas le détourner à des fins commerciales, politiques, religieuses ou sectaires.</p>
            <p>L’Utilisateur s’engage à ne pas archiver, reproduire, distribuer, modifier, concéder, vendre les outils ou les contenus accessibles depuis le Service, sans l’accord explicite et préalable de l’Atelier des auteurs.</p>
            <p>L’Atelier des auteurs ne revendique aucun droit de propriété sur les œuvres originales produites par l’Utilisateur grâce aux outils et contenus du Service.</p>

            <h4>C) Identification</h4>
            <p>L’Utilisateur doit fournir les moyens de le contacter par l’intermédiaire d’une adresse e-mail valide. L’Utilisateur peut modifier cette adresse e-mail sur la page « Paramètres » de son Compte.</p>
            <p>L’Atelier des auteurs peut refuser ou invalider l’inscription d’un Utilisateur dont les informations se révéleraient inexactes.</p>
            <p>L’Utilisateur est seul responsable de ses moyens d’identification et de connexion au Site et au Service.</p>
            <p>En cas d’inscription au Site via ses identifiants Facebook, la validité du Compte de l’Utilisateur sur le Site sera conditionnée à celle de son compte Facebook. Ainsi, la résiliation du compte Facebook de l’Utilisateur, ou tout problème de connexion lié à Facebook, pourrait empêcher l’accès au Compte de l’Utilisateur sur le Site et l’accès au Service, ce dont l’Atelier des auteurs ne pourra en aucun cas être tenu responsable.</p>

            <h2>V - Notre Responsabilité</h2>
            <h4>A) Accès au Site et au Service : </h4>
            <p>L’Atelier des auteurs s'engage à tout mettre en œuvre pour assurer le bon fonctionnement du Site et du Service et son accessibilité par l’Utilisateur, mais elle n'est tenue qu'à une obligation de moyens concernant la continuité de l'accès au Site et au Service.</p>
            <p>L’Atelier des auteurs ne garantit pas la pérennité ou les performances du Site ni du Service.</p>
            <p>L’Atelier des auteurs se réserve expressément, sans indemnisation au bénéfice de l'Utilisateur, le droit de modifier et/ou supprimer à tout moment tout ou partie d’un contenu proposé sur le Service qui ne répondrait plus à ses objectifs éditoriaux, à l’exclusion des contenus d’ores et déjà payés par l’Utilisateur qui lui resteront accessibles sous réserve des limites définies au présent article.</p>
            <p>L’Atelier des auteurs pourra, sans indemnité au bénéfice de l'Utilisateur, interrompre l'accès au Site et/ou Service, temporairement ou définitivement, pour des raisons notamment de maintenance, de mise à jour, en cas d'urgence ou de force majeure ou en cas de modification de ses orientations stratégiques propres.</p>
            <p>L’Atelier des auteurs ne sera responsable que des dommages directs subis par l’Utilisateur, dont il sera établi qu’ils résultent de l’inexécution par elle de ses obligations.</p>
            <p>En revanche, l’Atelier des auteurs ne saurait en aucun cas être tenue responsable :</p>
            <ul>
              <li>Des dommages qui résulteraient du fait de l’Utilisateur, d’un tiers ou d’un cas de force majeure, de difficultés inhérentes au fonctionnement du réseau Internet et plus généralement des réseaux de télécommunication, quelle que soit leur nature ;</li>
              <li>Des dommages indirects résultant de l’utilisation ou de l’interruption du Service ou du Site, ceux-ci étant définis de façon non limitative comme les pertes d’exploitation (chiffre d’affaires, revenus ou bénéfices), les pertes d’opportunités, les préjudices d’image ou de réputation, préjudice commercial ou économique ;</li>
              <li>De la perte de données notamment des Contenus publiés sur le Site, l’Utilisateur devant veiller à réaliser des sauvegardes régulières sur ses terminaux personnels des Contenus qu’il publie sur le Site ;</li>
              <li>Des éventuelles dégradations de matériel, logiciel ou données (exemple : contamination virale) subies par l’Utilisateur du fait de son utilisation du Service et du Site.</li>
            </ul>
            <p>L’Atelier des auteurs ne donne aucune garantie, expresse ou implicite, que le SIte ou le Service puissent être exempts d'erreurs, de vice, de défauts ou bugs. L’Atelier des auteurs ne donne pas non plus la garantie que les fonctionnalités standards fournies via le Service  et le Site répondront aux besoins et attentes d'une utilisation spécifique, selon les contraintes personnelles d'un Utilisateur.</p>
            <p>Pour éviter autant que possible des désagréments, l’Utilisateur doit s’assurer de la réalisation régulière de sauvegardes concernant ses logiciels et données publiées sur le Site.</p>
            
            <div style={{border: "1px solid"}}>
            <h4>B) Garantie légale de conformité :</h4>
            <p>L’Atelier des auteurs est garant des défauts de conformité du Service dans les conditions prévues aux articles L.224-25-12 et s. du code de la consommation.</p>
            <p>L’Utilisateur décidant de mettre en œuvre la garantie de conformité :</p>
            <ul>
              <li>bénéficie d’un délai de 2 ans dès lors que la non-conformité apparait dans les 2 ans de la délivrance du Service ou d’un délai correspondant à la durée de la fourniture du Service, pour agir, sans préjudice de l’application des articles 2224 et suivants du code civil,</li>
              <li>peut demander la mise en conformité du Service sous réserve des conditions de coût prévues par l’art L.224-25-19 du Code de la Consommation, ou, à défaut, la réduction du prix ou la résolution du contrat,</li>
              <li>est dispensé de rapporter la preuve de l’existence du défaut de conformité durant les 12 mois suivants la délivrance du Service, ou pour la durée de la fourniture du Service.</li>
            </ul>
            <p>L’Utilisateur décidant de mettre en œuvre la garantie légale contre les vices cachés (articles 1641 et suivants du Code Civil) peut demander une réduction du prix de vente conformément à l’article 1644 du Code Civil.</p>
            </div>

            <h2>VI - Données personnelles</h2>
            <p>En créant un Compte sur le Site et en souscrivant à une offre payante, nous collectons et traitons des informations personnelles vous concernant (données d’identité, activité sur le Site, etc…).</p>
            <p>Pour en savoir plus sur les modalités de traitement de vos données personnelles, nous vous invitons à consulter notre Politique de Protection des Données Personnelles accessible en pied de page du Site.</p>

            <h2>VII - Suspension et/ou Résiliation anticipée</h2>
            <h4>A) Par l’Atelier des auteurs :</h4>
            <p>L’Atelier des auteurs se réserve le droit de suspendre et/ou résilier la souscription et/ou le Compte d’un Utilisateur, sans préavis ni formalité préalables, en cas de manquement de l’Utilisateur à toute disposition légale applicable ou à ses obligations prévues aux présentes C.G.V ou aux C.G.U. du Site, et notamment :</p>
            <ul>
              <li>non-respect des droits de propriété intellectuelle de l’Atelier des auteurs ou des droits des autres Membres ;</li>
              <li>fourniture d’informations inexactes au moment de l’inscription</li>
              <li>non-respect par l’Utilisateur de ses obligations définies à l’article IV.</li>
            </ul>
            <p>L’Utilisateur sera informé de ladite résiliation par courriel, à l’adresse qu’il a renseignée lors de son inscription, ou modifiée par la suite.</p>
            <p>Cette résiliation sera indépendante des dommages et intérêts que pourrait réclamer l’Atelier des auteurs en réparation des préjudices éventuellement subis du fait des manquements reprochés à l’Utilisateur.</p>
            <p>L’Atelier des auteurs se réserve le droit de refuser par la suite à l’Utilisateur la création d’un nouveau compte au sein du Service.</p>

            <h4>B) Par l’Utilisateur :</h4>
            <p>L’Utilisateur pourra résilier sa souscription et/ou son Compte sans préavis, ni indemnité, en cas de manquement de l’Atelier des auteurs aux obligations définies aux présentes.</p>

            <h4>C) Sort des Contenus :</h4>
            <p>La résiliation du Compte entraînera la suppression du Site des Contenus publiés par l’Utilisateur et, le cas échéant, leur mise à disposition au profit de l’Utilisateur dans les conditions définies à l’article L. 224-25-22 du Code de la Consommation, en cas de résolution du Contrat pour défaut de conformité.</p>            

            <h2>VIII - STIPULATIONS GENERALES</h2>
            <h4>A)  Indépendance des Parties :</h4>
            <p>Les Parties reconnaissent et acceptent que l’une d’elles ne pourra en aucun cas prendre un engagement au nom et/ou pour le compte de l'autre.</p>
            <p>En outre, chacune des Parties demeure seule responsable de ses actes, allégations, engagements et prestations.</p>
            <p>Les Parties reconnaissent et acceptent qu’elles agissent en toute indépendance l’une de l’autre et que le présent Contrat ne pourra en aucun cas être considéré comme établissant entre elles une société de fait, une société en participation ou tout autre situation entraînant une quelconque représentation réciproque ou une solidarité à l’égard de leurs créanciers respectifs.</p>
            
            <h4>B) Autonomie des stipulations contractuelles entre elles</h4>
            <p>Si l’une quelconque des stipulations des présentes CGV devait être déclarée nulle ou inapplicable, au regard d’une règle de droit en vigueur ou d’une décision judiciaire devenue définitive, elle sera réputée non écrite.</p>
            <p>Les autres stipulations des présentes resteront en vigueur en conservant toute leur force et leur portée pour autant qu’il soit possible, les Parties s’engageant, en tant que de besoin, à se rapprocher afin de remplacer la clause nulle par une clause valide, aussi proche que possible, dans son esprit, de celle qu’elle a vocation à remplacer.</p>
            
            <h4>C) Non renonciation</h4>
            <p>Le fait que l'une ou l'autre des Parties ne revendique pas l'application de l'une quelconque des stipulations du Contrat ou accepte son inexécution, que ce soit de manière permanente ou temporaire, ne pourra pas être interprété et ne constituera pas une renonciation par cette Partie à ses droits en vertu des présentes, n’affectera en aucune manière la validité de tout ou partie des présentes et ne portera pas atteinte aux droits de la Partie concernée d’agir en conséquence.</p>

            <h4>D) Force majeure</h4>
            <p>En cas de survenance d’un événement répondant aux conditions légales et/ou jurisprudentielles définissant la force majeure, l’Atelier des auteurs pourra être amenée à suspendre le Service.</p>
            <p>Les effets du Contrat seront alors suspendus et reprendront après l’extinction du cas de force majeure pour la durée du Contrat qui restait à courir au moment de la suspension, à moins que le retard résultant de la force majeure ne justifie sa résiliation ou résolution. Si l’empêchement est définitif, le Contrat sera résilié ou résolu de plein droit.</p>

            <h4>E) Loi applicable – Médiation :</h4>
            <p>Les présentes CGV sont soumises à la loi française.</p>
            <p>En cas de réclamation, l’Utilisateur pourra s'adresser en premier recours à l’Atelier des auteurs, par voie postale à l’adresse indiquée en-tête des présents ou par mail à l’adresse <Mailto email="contact@atelierdesauteurs.com" obfuscate={true}>{contactEmail}</Mailto>.</p>
            <p>En second recours et après avoir tenté, au préalable, de résoudre son litige par une réclamation écrite adressée à l’Atelier des auteurs, l’Atelier des auteurs et l’Utilisateur s’efforceront de trouver une solution amiable.</p>
            <p>A défaut d’accord amiable, l’Utilisateur a la possibilité de saisir gratuitement le médiateur de la consommation dont relève l’Atelier des auteurs, à savoir AME CONSO, dans un délai d’un an à compter de la réclamation écrite adressée au professionnel.</p>
            <p>La saisine du médiateur de la consommation devra s’effectuer :</p>
            <ul>
              <li>soit en complétant le formulaire prévu à cet effet sur le site internet de l’AME CONSO : www.mediationconso-ame.com ;</li>
              <li>soit par courrier adressé à l’AME CONSO, 11 Place Dauphine – 75001 PARIS.</li>
            </ul>
            <p>Si l’Utilisateur réside dans un pays membre de l'Union Européenne, en Norvège, en Islande ou au Liechtenstein, la Commission Européenne met à sa disposition une plateforme de règlement extrajudiciaire des litiges, consultable à l’adresse suivante : http://ec.europa.eu/consumers/odr/.</p>
          </div>
        </div>
      </div>
    );
  }
}
