import * as React from 'react';

export default class AlignJustifyIcon extends React.Component<{}, {}> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <title>AlignJustify</title>
        <path d="M3 21h18v-2H3v2zm0-4h18v-2H3v2zm0-4h18v-2H3v2zm0-4h18V7H3v2zm0-6v2h18V3H3z" fill="currentColor" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
