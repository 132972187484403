import { observable } from 'mobx';

export default class EditorModel {

  @observable id: number;
  @observable name: string;
  @observable url: string;
  @observable description: string;
  @observable characteristics: string;
  @observable highlightsTitle: string;
  @observable highlights: string;
  @observable highlightsImage: string;
  @observable image: string;
  @observable cnilEmail: string;

  constructor(id: number, name: string, url: string, description: string,
    characteristics: string, highlightsTitle: string, highlights: string, highlightsImage: string, 
    image: string, cnilEmail: string) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.description = description;
    this.characteristics = characteristics;
    this.highlightsTitle = highlightsTitle;
    this.highlights = highlights;
    this.highlightsImage = highlightsImage;
    this.image = image;
    this.cnilEmail = cnilEmail;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      url: this.url,
      description: this.description,
      characteristics: this.characteristics,
      highlightsTitle: this.highlightsTitle,
      highlights: this.highlights,
      highlightsImage: this.highlightsImage,
      image: this.image,
      cnilEmail: this.cnilEmail
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new EditorModel(object.id, object.name, object.url, object.description,
        object.characteristics, object.highlightsTitle, object.highlights, object.highlightsImage, 
        object.image, object.cnilEmail);
    }
    return undefined;
  }
}
