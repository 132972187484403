import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import RepetitionPopup from './RepetitionPopup';
import { findRepetitionsInParagraph } from '../../../../../../../utils/relecture/lisibilite';

function repetitionStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  const repetitions = findRepetitionsInParagraph(text);
  repetitions.forEach(repetition => repetition.occ.forEach(occ => callback(occ.start, occ.length)));
}

const repetitionSpan = (props: any) => (
  <RepetitionPopup
    decoratedText={props.decoratedText}
    children={props.children}
    />
);

const decorator = new CompositeDecorator([
  {
    strategy: repetitionStrategy,
    component: repetitionSpan,
  },
]);

export default decorator;
