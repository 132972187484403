import * as React from 'react';
import { inject, observer } from 'mobx-react';
const throttle = require('throttle-debounce').throttle;
import { Async } from 'react-select';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import ThankModel from '../../models/text/ThankModel';
import { intersperse } from '../../utils/utils';

interface ThanksProps {
  store?: Store;
  strangerMode: boolean;
}

interface ThanksState {
  active: boolean;
  selectedThank: ThankModel;
  selectedUser: { label: string, value: string };
  withMessage: boolean;
  message: string;
}

@inject('store') @observer
export default class Thanks extends React.Component<ThanksProps, ThanksState> {
  constructor(props: ThanksProps) {
    super(props);
    this.fetchUsers = throttle(1000, this.fetchUsers.bind(this));
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.saveThank = this.saveThank.bind(this);
    this.removeThank = this.removeThank.bind(this);
    this.state = {
      active: false,
      selectedThank: undefined,
      selectedUser: undefined,
      withMessage: false,
      message: ''
    };
  }
  fetchUsers(input: string, callback: Function) {
    const textId = this.props.store.text.meta.id;
    const allThanks = this.props.store.text.thanks.map(t => t.user.id);
    this.props.store.getRelations(input).then((relations: any) => {
      const options = relations.filter((r: any) => allThanks.indexOf(parseInt(r.value)) === -1).map((r: any) => ({
        value: r.value,
        label: r.label,
      }));

      callback(null, { options });
    });
  }
  showModal(selectedThank?: ThankModel) {
    this.setState({
      active: true,
      selectedThank: selectedThank,
      selectedUser: this.state.selectedUser,
      withMessage: selectedThank ?
        selectedThank.message !== undefined
        && selectedThank.message !== null
        && selectedThank.message.trim() !== ''
        : false,
      message: selectedThank ? selectedThank.message : ''
    });
  }
  hideModal() {
    this.setState({
      active: false,
      selectedThank: undefined,
      selectedUser: undefined,
      withMessage: false,
      message: ''
    });
  }
  saveThank() {
    const message = this.state.withMessage && this.state.message.trim().length > 0 ?
      this.state.message
      : undefined;
    const textId = this.props.store.text.meta.id;
    if (this.state.selectedThank) {
      this.props.store.thankEdit(textId, this.state.selectedThank.user.id, message).then((d: any) => {
        this.hideModal();
      });
    } else {
      const users = [{ userId: parseInt(this.state.selectedUser.value), message: this.state.message }];
      this.props.store.thankAdd(textId, users).then((d: any) => {
        this.hideModal();
      });
    }
  }
  removeThank() {
    const textId = this.props.store.text.meta.id;
    this.props.store.thankRemove(textId, this.state.selectedThank.user.id).then((d: any) => {
      this.hideModal();
    });
  }
  handleUserChange(value: { label: string, value: string }[]) {
    this.setState({
      active: this.state.active,
      selectedThank: this.state.selectedThank,
      selectedUser: value.length > 0 ? value[0] : undefined,
      withMessage: this.state.withMessage,
      message: this.state.message
    });
  }
  handleMessageChange(e: any) {
    this.setState({
      active: this.state.active,
      selectedThank: this.state.selectedThank,
      selectedUser: this.state.selectedUser,
      withMessage: this.state.withMessage,
      message: e.target.value
    });
  }
  render() {
    const connected = this.props.store.connected;
    const meta = this.props.store.text.meta;
    const thanks = this.props.store.text.thanks;
    const thanksList = intersperse(this.props.store.text.thanks.map(t => {
      return <a key={t.user.id} style={{ color: meta.linkColor, cursor: 'pointer' }} onClick={() => this.showModal(t)}
        data-tooltip="Voir le remerciement" data-inverted="" data-position="bottom center">
        {t.user.identity}
      </a>;
    }), ', ');
    const isAuthor = connected && connected.user && connected.user.id
      && connected.user.id === meta.author.id;
    const selectedThank = this.state.selectedThank;
    return (
      <div>
        {thanks.length > 0 || (isAuthor && !this.props.strangerMode) ?
          <Modal
            open={this.state.active}
            onClose={() => { }}
          >
            <div className="content">
              <div className="ui form">
                {isAuthor && !this.props.strangerMode ?
                  <div className="ui form">
                    <div className="field">
                      <label>Auteur remercié</label>
                      {this.state.selectedThank ?
                        <span>{selectedThank.user.identity}</span>
                        : <Async
                          loadOptions={this.fetchUsers}
                          multi={true}
                          value={this.state.selectedUser}
                          onChange={this.handleUserChange}
                          placeholder="..."
                          noResultsText="Aucun utilisateur trouvé"
                          searchPromptText="Entrez un nom d'utilisateur"
                          loadingPlaceholder="Chargement..."
                          ignoreCase={true}
                          ignoreAccents={false}
                        />
                      }
                    </div>
                    <div className="ui small message">
                      Saisissez les premières lettres du membre que vous souhaitez remercier puis sélectionnez-le dans la liste déroulante.
                    </div>
                    <div className="field">
                      <Checkbox
                        checked={this.state.withMessage}
                        onClick={() =>
                          this.setState({
                            active: this.state.active,
                            selectedThank: this.state.selectedThank,
                            selectedUser: this.state.selectedUser,
                            withMessage: !this.state.withMessage,
                            message: this.state.message ? this.state.message : ''
                          })
                        }
                        label="Ajouter un message"
                      />
                    </div>
                    {this.state.withMessage ?
                      <div className="field">
                        <label>Message</label>
                        <textarea
                          value={this.state.message}
                          rows={2}
                          style={{ height: '7em' }}
                          onChange={this.handleMessageChange}
                        />
                      </div>
                      : undefined}
                  </div>
                  :
                  <div>
                    {this.state.selectedThank ?
                      <div>
                        <div>
                          L'auteur remercie
                        &nbsp;<Link to={`/author/${selectedThank.user.id}/${selectedThank.user.urlIdentity}`}>
                            {selectedThank.user.identity}
                          </Link>
                        </div>
                        {selectedThank.message ?
                          <div className="ui segment">
                            {selectedThank.message}
                          </div>
                          : undefined}
                      </div>
                      : undefined}
                  </div>
                }
              </div>
            </div>
            <div className="actions">
              {isAuthor && !this.props.strangerMode && this.state.selectedThank ?
                <button className="ui blue approve button" onClick={this.saveThank}>
                  Enregistrer les modifications
              </button>
                : undefined}
              {isAuthor && !this.props.strangerMode && !this.state.selectedThank ?
                <button className="ui blue approve button" onClick={this.saveThank}>
                  Enregistrer
              </button>
                : undefined}
              {isAuthor && !this.props.strangerMode && this.state.selectedThank ?
                <button className="ui red approve button" onClick={this.removeThank}>
                  Supprimer
              </button>
                : undefined}
              <button className="ui cancel button" onClick={this.hideModal}>
                {isAuthor && !this.props.strangerMode ? 'Annuler' : 'Fermer'}
              </button>
            </div>
          </Modal>
          : undefined}
        {thanks.length > 0 || (isAuthor && !this.props.strangerMode) ?
          <div>
            <span style={{ color: meta.textColor }}>
              <span>Avec le soutien de&nbsp;</span>
              {thanks.length > 0 ?
                <span>&nbsp;{thanksList}&nbsp;</span>
                : undefined
              }
            </span>
            {isAuthor && !this.props.strangerMode ?
              <button
                className="ui tiny orange label"
                style={{ cursor: 'pointer', marginLeft: '15px' }}
                onClick={() => this.showModal()}
              >
                Remercier un auteur
              </button>
              : undefined}
          </div>
          : undefined
        }
      </div>
    );
  }
}
