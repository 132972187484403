import RelectureType from './RelectureType';

export default class RelectureModel {

  name: RelectureType;
  fn?: Function;

  constructor(name: RelectureType, fn?: Function) {
    this.name = name;
    this.fn = fn;
  }
}
