import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Mention from './Mention';
import UserModel from '../../models/UserModel';
import CommentModel from '../../models/comment/CommentModel';
import Store from '../../stores/Store';

interface CommentFormProps {
  store?: Store;
  comment?: CommentModel;
  textEdit: string;
  submit: any;
  cancel?: Function | any;
  author: UserModel;
  focus: boolean;
}

interface CommentFormState {
  text: string;
  textEdit: string;
  remaining: number;
  loading: boolean;
}

@inject('store') @observer
export default class CommentForm extends React.Component<CommentFormProps, CommentFormState> {
  constructor(props: CommentFormProps) {
    super(props);
    this.state = {
      text: this.props.textEdit,
      textEdit: '',
      remaining: (9000 - this.props.textEdit.length),
      loading: false
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleTextChange(text: string, remaining: number) {
    this.setState({
      text,
      textEdit: this.state.textEdit,
      remaining
    });
  }
  handleSubmit(e: any) {
    e.preventDefault();
    const text = this.state.text.trim();
    if (!text) {
      return;
    }
    this.setState({
      loading: true
    });
    if (this.props.comment) {
      this.props.submit(text);
    } else {
      this.props.submit(text).then((d: any) => {
        this.setState({
          text: '',
          textEdit: '',
          remaining: 9000,
          loading: false
        });
      });
    }
  }
  render() {
    return (
      <form
        className="ui reply form"
        onSubmit={this.handleSubmit}
        style={{ marginBottom: '50px' }}>
        <Mention
          text={this.state.text}
          remaining={this.state.remaining}
          handleTextChange={this.handleTextChange}
          author={this.props.author}
          focus={this.props.focus}
        />
        <div>
          <small>
            Vous pouvez citer un membre en encadrant son nom de deux signes @.
            <div>Exemple: @MarcelProust@</div>
          </small>
        </div>
        <div>
          {this.props.cancel
            ? <button
              type="button"
              className="ui right floated button"
              value="Annuler"
              onClick={this.props.cancel}
            >
              Annuler
            </button>
            : null}
          <button
            type="submit"
            className={'ui right floated primary submit ' + (this.state.loading ? ' loading' : '') + ' button'}
            disabled={this.state.remaining < 0 || this.state.remaining === 9000}
          >
            Publier
          </button>
        </div>
      </form>
    );
  }
}

