import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import UserModel from '../../models/UserModel';
import Store from '../../stores/Store';

interface ListFavoritesProps {
  title: string;
  users: UserModel[];
  active: boolean;
  close: Function | any;
}

@inject('store') @observer
export default class ListFavorites extends React.Component<ListFavoritesProps, {}> {
  render() {
    const users = this.props.users.map(u =>
      <div key={u.id} className="item">
        <img
          className="ui mini rounded image"
          src={u.avatarUrl}
          alt={`Image de profil de ${u.identity}`}
        />
        <div className="content">
          <div className="header">
            <Link to={`/author/${u.id}/${u.urlIdentity}`}>
              {u.identity}
            </Link>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <Modal onClose={this.props.close} open={this.props.active} size="small">
          <div className="header">
            {this.props.title}
          </div>
          <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            <div className="ui middle aligned list">
              {users}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
