import { observable } from 'mobx';

export default class VideoModel {

  @observable id: number;
  @observable name: string;
  @observable videoName: string;
  @observable index: number;
  @observable url: string;
  @observable mandatory: boolean;
  @observable visible: boolean;

  constructor(id: number, name: string, videoName: string, index: number, 
    url: string, mandatory: boolean, visible: boolean) {
    this.id = id;
    this.name = name;
    this.videoName = videoName;
    this.index = index;
    this.url = url;
    this.mandatory = mandatory;
    this.visible = false;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      videoName: this.videoName,
      index: this.index,
      url: this.url,
      mandatory: this.mandatory,
      visible: this.visible
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new VideoModel(object.id, object.name, object.videoName, object.index, 
        object.url, object.mandatory, object.visible);
    }
    return undefined;
  }
}
