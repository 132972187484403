import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import PopupWarning from './PopupWarning';
import { isSentenceTooLong } from '../../../../../../utils/relecture/lisibilite';

function sentenceStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  const regex = /[^\.!\?]+[\.!\?]+/g;
  let matchArr: RegExpExecArray;
  let start: number;
  while ((matchArr = regex.exec(text)) !== null) {
    if (isSentenceTooLong(matchArr[0])) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }
}

const sentenceSpan = (props: any) => (
  <PopupWarning
    body="Attention, votre phrase dépasse les 40 mots !"
    spanClass="write-warning-2"
    children={props.children}
  />
  );

const decorator = new CompositeDecorator([
  {
    strategy: sentenceStrategy,
    component: sentenceSpan,
  },
]);

export default decorator;
