import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Store from '../../stores/Store';
import NotificationsList from '../notification/NotificationsList';
import Sidebar from '../misc/Sidebar';
import ConnectedBanner from '../misc/ConnectedBanner';
import SearchBox from './SearchBox';

interface EmptyConnectedTopBarProps {
  store?: Store;
  location: { pathname: string };
}

interface EmptyConnectedTopBarState {
  showNotifications: boolean;
  notifications: string[];
}

@inject('store') @observer
export default class EmptyConnectedTopBar extends React.Component<EmptyConnectedTopBarProps, EmptyConnectedTopBarState> {
  _timer: any;

  constructor(props: EmptyConnectedTopBarProps) {
    super(props);
    this.startPolling = this.startPolling.bind(this);
    this.poll = this.poll.bind(this);
  }
  componentDidMount() {
    this.startPolling();
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that._timer = setInterval(that.poll.bind(self), 120000);
    }, 1000);
  }
  poll() {
    if (this.props.store.isAuthenticated()) {
      this.props.store.loadConnected();
    }
  }
  @action
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  render() {
    const connected = this.props.store.connected;

    return (
      <div>
        <div className="ui top fixed menu borderless" style={{ boxShadow: 'none', borderBottom: 'none' }}>
          <div className="ui ada-background-editis-bleu-nuit secondary container navbar menu home-padding" style={{
            width: '100%',
            borderBottom: 'none'
          }}> 
          </div>
        </div>
{/*         <ConnectedBanner />
 */}      </div >
    );
  }
}
