export default class BadgeModel {
  name: string;
  level: number;

  constructor(name: string, level: number) {
    this.name = name;
    this.level = level;
  }

  toJS() {
    return {
      name: this.name,
      level: this.level
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new BadgeModel(object.name, object.level);
    }
    return undefined;
  }
}
