import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../stores/Store';
import DefiModel from '../../models/DefiModel';
import DefiForm from './DefiForm';

interface NewDefiPageProps {
  store?: Store;
}

@inject('store') @observer
export default class NewDefiPage extends React.Component<NewDefiPageProps, {}> {
  static fetchData(store: Store) {
    return store.loadSettingsNotifications();
  }
  constructor(props: NewDefiPageProps) {
    super(props);
    this.createDefi = this.createDefi.bind(this);
  }
  createDefi(title: string, description: string,
    genre: number) {
    this.props.store.createDefi(
      title, description, genre
    ).then((d: DefiModel) => {
      browserHistory.push({
        pathname: `/defis/defi/${d.id}/${d.urlTitle}`
      });
    });
  }
  render() {
    return (
      <div className="ui grid container centered stackable" style={{ backgroundColor: '#fff' }}>
        <div className="ten wide column ui text" style={{ paddingTop: '30px', paddingBottom: '140px' }}>
          <Title title="Nouveau défi " />
          <div className="ui huge breadcrumb">
            <Link to="/defis" className="section">Défis</Link>
            <i className="fa fa-fw fa-chevron-right divider"></i>
            <div className="active section">
              Nouveau défi
            </div>
          </div>
          <div className="ui basic segment">
            <DefiForm submit={this.createDefi} />
          </div>
        </div>
      </div>
    );
  }
}
