import { observable } from 'mobx';

export default class ParcoursSummaryExampleModel {

  @observable id: number;
  @observable title: string;

  constructor(id: number, title: string) {
    this.id = id;
    this.title = title;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursSummaryExampleModel(object.id, object.title);
    }
    return undefined;
  }
}
