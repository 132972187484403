/**
  Fetch data from components mapping "static fetchData()"
  and injecting store, router params and query.
  Used on the server-side. It returns a Promise.
 */
export function fetchData(store: any, components: any, params: any, query: any) {
  return Promise.all(components
    .map((component: any) => {
      const p: any = [];
      if (component.main && component.main.wrappedComponent && component.main.wrappedComponent.fetchData) {
        p.push(component.main.wrappedComponent.fetchData(store, params, query));
      }
      if (component.topbar && component.topbar.wrappedComponent && component.topbar.wrappedComponent.fetchData) {
        p.push(component.topbar.wrappedComponent.fetchData(store, params, query));
      }
      if (component.wrappedComponent && component.wrappedComponent.fetchData) {
        p.push(component.wrappedComponent.fetchData(store, params, query));
      }
      return Promise.all(p);
    }));
}

/**
  Fetch data from components when the router matches the browser location.
  It also prevent the first page to re-fetch data already fetched from the server.
  Used on the client-side.
 */
export function fetchDataOnLocationMatch(history: any, routes: any, match: any, store: any) {
  let ssrLocation = store.ssrLocation;
  history.listen((e: any) => {
    const fullPath = e.pathname + e.search;
    if (fullPath !== ssrLocation) {
      match({ routes, location: e.pathname }, (error: any, redirect: any, props: any) => {
        if (props) {
          fetchData(store, props.components, props.params, e.query);
        }
      });
    }
    // enable subsequent fetches
    ssrLocation = false;
  });
}
