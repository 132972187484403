import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';

interface ProfileUserBlockProps {
  store?: Store;
}

interface ProfileUserBlockState {
  active: boolean;
}

@inject('store') @observer
export default class ProfileUserBlock extends React.Component<ProfileUserBlockProps, ProfileUserBlockState> {
  constructor(props: ProfileUserBlockProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.block = this.block.bind(this);
    this.unblock = this.unblock.bind(this);
    this.state = {
      active: false
    };
  }
  showModal() {
    this.setState({
      active: true
    });
  }
  hideModal() {
    this.setState({
      active: false
    });
  }
  block() {
    const profileId = this.props.store.profile.id;
    this.props.store.blockUser(profileId).then((d: any) => {
      this.hideModal();
    });
  }
  unblock() {
    const profileId = this.props.store.profile.id;
    this.props.store.unblockUser(profileId);
  }
  render() {
    const blocked = this.props.store.profile.user.blocked;
    return (
      <span>
        <Modal
          open={this.state.active}
          onClose={() => { } }
          >
          <div className="header">Confirmation</div>
          <div className="content">
            Confirmez-vous le blocage de cet utilisateur ?
            <br />
            Il ne pourra plus :
            <div className="ui bulleted list">
              <div className="item">Commenter, annoter ou aimer vos œuvres</div>
              <div className="item">Vous envoyer de messages privés</div>
              <div className="item">S'abonner à vous</div>
            </div>
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.block}
              >
              Confirmer
          </button>
            <button className="ui cancel button" onClick={this.hideModal}>Annuler</button>
          </div>
        </Modal>
        {blocked ?
          <button className="ui small active button" onClick={this.unblock}>
            <i className="fa fa-remove fa-fw"></i> Ne plus bloquer l'auteur
          </button>
          :
          <button className="ui small button" onClick={this.showModal}>
            <i className="fa fa-remove fa-fw"></i> Bloquer l'auteur
          </button>
        }
      </span>
    );
  }
}
