import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import { AsyncCreatable } from 'react-select';
const InfiniteScroll = require('react-infinite-scroll-component');
const throttle = require('throttle-debounce').throttle;
import Store from '../../stores/Store';

declare const __CLIENT__: any;

interface TalksPageProps {
  store?: Store;
  location: { query: { tag: string[], type: number } };
}

interface TalksPageState {
  enableComponents: boolean;
  loadingTalksError: boolean;
  loadingTalks: boolean;
}

@inject('store') @observer
export default class TalksPage extends React.Component<TalksPageProps, TalksPageState> {
  static fetchData(store: Store, params: void, query: { tag: string[], type: number }) {
    const p: any[] = [];
    p.push(store.loadTalks(query.tag, true));
    p.push(store.loadTalksPopularTags());
    return Promise.all(p);
  }
  constructor(props: TalksPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this);
    this.fetchTags = throttle(1000, this.fetchTags.bind(this));
    this.state = {
      enableComponents: false,
      loadingTalksError: false,
      loadingTalks: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  loadMoreTalks() {
    this.props.store.loadTalks(this.props.location.query.tag, false, (failed: boolean)=>{
      this.setState(
      {
        loadingTalksError: failed,
        loadingTalks: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingTalks: true
    },
    () => {this.loadMoreTalks()}    
    );
  }
  handleSelectTagChange(value: [{ create: boolean, label: string, value: string }]) {
    browserHistory.push({
      pathname: '/talks',
      query: {
        tag: value.map(t => t.value),
        type: this.props.location.query.type
      }
    });
  }
  fetchTags(input: string, callback: Function) {
    this.props.store.getTalksTagsBeginningWith(input).then((tags: any) => {
      const options = tags.map((t: string) => ({
        value: t,
        label: t,
      }));

      callback(null, { options });
    });
  }
  render() {
    const title: string = 'Toutes les discussions';

    const talk = this.props.store.mainTalk;
    const talks = talk.talks.map((t) => {
      const textTags = t.tags.map((tag) => {
        return <Link
          to={encodeURI(`/talks?tag=${tag}`)}
          key={Math.random() * 1000}
          className="ui label"
        >
          {tag}
        </Link>;
      });

      const readLabel = t.read ?
        <span>lu</span>
        : <span style={{ color: '#F2711C' }}>non lu</span>;
      return (
        <div key={Math.random() * 1000} className="ui item">
          <img className="ui tiny rounded image" src={t.author.avatarUrl}
            alt={`Image de profil de ${t.author.identity}`}
            style={{ height: '80px' }} />
          <div className="content">
            <Link
              to={`/talks/${t.id}/${t.urlTitle}`}
              className="header"
            >
              {t.locked ?
                <span className="fa fa-fw fa-lock" />
                : undefined}
              {t.title}
            </Link>
            <div className="description">
              Par <Link
                to={`/author/${t.author.id}/${t.author.urlIdentity}`}
              >
                {t.author.identity}
              </Link>
              <div className="right floated">
                {t.answers} réponse{t.answers > 1 ? 's' : undefined} | {t.lastMessageAgo}| {readLabel}
              </div>
            </div>
            <div className="extra">
              {t.permanent ?
                <span className="ui green label">Permanent</span>
                : undefined}
              {textTags}
            </div>
          </div>
        </div>
      );
    });

    const paramTag = this.props.location.query.tag;
    let selectedTags: string[];

    if (paramTag) {
      if (paramTag instanceof Array) {
        selectedTags = paramTag;
      } else {
        selectedTags = [paramTag];
      }
    } else {
      selectedTags = [];
    }

    const selectedTagsForm = selectedTags.map((t: string) => { return { label: t, value: t }; });

    const popularTags = talk.popularTags.map(t => {
      const st = selectedTags.slice();

      if (st.indexOf(t) !== -1) {
        const newTags = st
          .filter((s: string) => s !== t)
          .slice(-3)
          .map((s: string) => `tag=${s}`);

        const queryParam = newTags.join('&');

        return (
          <Link
            to={`/talks?${queryParam}`}
            key={Math.random() * 1000}
            className="ui blue label"
          >
            {t}
          </Link>
        );
      } else {
        st.push(t);
        const newTags = st.slice(-3).map((s: string) => `tag=${s}`);

        const queryParam = newTags.join('&');

        return (
          <Link
            to={`/talks?${queryParam}`}
            key={Math.random() * 1000}
            className="ui label"
          >
            {t}
          </Link>
        );
      }
    });

    const connected = this.props.store.connected;
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.mainTalk.end){
        if (this.state.loadingTalks){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingTalksError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid">{error}{message}</div>

    }
    return (
      <div>
      <Title title={'Discussions > ' + title + ' '} />
      <div className="ui two column stackable grid">
        <div className="ten wide column" style={{ backgroundColor: '#fff', paddingTop: '50px', paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <div className="ui massive breadcrumb">
                <div className="section">Discussions</div>
                <i className="fa fa-fw fa-chevron-right divider"></i>
                <div className="active section">{title}</div>
              </div>
              <div className="ui horizontal hidden divider" />
              <InfiniteScroll
                next={this.loadMore}
                hasMore={false}
                loader={<h4>Chargement...</h4>}
                endMessage={endMessage()}
              >
                <div className="ui relaxed divided unstackable items">
                  {talks}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <div className="six wide right floated grey column" style={{ paddingTop: '50px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <div className="ui basic">
                {connected && connected.user && connected.user.id ?
                  <Link to="/talks/new"
                    className="ui fluid primary button">
                    Nouvelle discussion
                </Link>
                  : undefined}
                <div className={`ui horizontal ${connected && connected.user && connected.user.id ? 'section' : ''} divider`}>
                  Mots-clés
                  </div>
                {this.state.enableComponents ?
                  <AsyncCreatable
                    loadOptions={this.fetchTags}
                    multi={true}
                    value={selectedTagsForm}
                    onChange={this.handleSelectTagChange}
                    promptTextCreator={(label: string) => `Ajouter mot-clé: ${label}`}
                    placeholder="Aucun mot-clé"
                    noResultsText="Aucun mot-clé"
                    loadingPlaceholder="Chargement..."
                    ignoreCase={true}
                    ignoreAccents={false}
                    aria-label="Mots-clés"
                    searchPromptText="Entrez un mot-clé"
                  />
                  : undefined}
                <h4>Les plus populaires</h4>
                <div className="ui labels">
                  {popularTags}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      </div>
    );
  }
}
