import * as React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface ContactFormProps {
  store?: Store;
  location: string;
}

interface ContactFormState {
  mail: string;
  mailError: boolean;
  invalidMail: boolean;
  text: string;
  textError: boolean;
  unknownError: boolean;
  loading: boolean;
  success: boolean;
  antiBot: string;
}

@inject('store') @observer
export default class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  constructor(props: ContactFormProps) {
    super(props);
    this.state = { 
      mail: '',
      mailError: false,
      invalidMail: false,
      text: '' ,
      textError: false,
      unknownError: false,
      loading: false,
      success: false,
      antiBot: ''
    };
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAntiBotChange = this.handleAntiBotChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  handleMailChange(e: any) {
    const mail = e.target.value;
    this.setState({
      mail: mail
    });
  }
  handleTextChange(e: any) {
    const text = e.target.value;
    this.setState({
      text: text
    });
  }
  handleAntiBotChange(e: any) {
    const text = e.target.value;
    this.setState({
      antiBot: text
    });
  }
  validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  postForm(event: any) {
    event.preventDefault();
    const textError = this.state.text.trim().length === 0;
    const mailError = this.state.mail.trim().length === 0;
    const invalidMail = !this.validateEmail(this.state.mail) && !(this.state.mail.trim().length === 0);
    const antiBot = this.state.antiBot.trim().length === 0
    this.setState({
      textError: textError,
      mailError: mailError,
      invalidMail: invalidMail
    })
    if (!textError && !mailError && !invalidMail  && antiBot) {
      this.props.store.sendContactMail(
        this.state.mail, this.state.text, this.props.location
      ).then((r: any) => {
        this.setState({
          mail: '',
          mailError: false,
          invalidMail: false,
          text: '' ,
          textError: false,
          unknownError: false,
          loading: false,
          success: true
        })
      }, (err: any) => {
        this.setState({
          unknownError: true,
          success: false,
          loading: false
        });
      });
    }
  }
  render() {
    return (

        <form onSubmit={this.postForm} className="ui form" style={{ overflow: 'auto' }}>
          {this.state.textError || this.state.mailError ?
            <div className="ui error message">
              <div className="header">Les champs marqués en rouge sont obligatoires.</div>
            </div>
            : (this.state.success ? 
            <div className="ui success message">
              <div className="header">Votre message a bien été envoyé</div>
            </div>
            : (this.state.invalidMail ?
              <div className="ui error message">
                <div className="header">L'email est invalide.</div>
              </div>
              : (this.state.unknownError ?
                <div className="ui error message">
                  <div className="header">Une erreur s'est produite. Veuillez réessayer.</div>
                </div>
              : undefined
              )
            )
            )
          }
          <div className={'field' + (this.state.mailError || this.state.invalidMail ? ' error' : '')}>
            <label>Votre email</label>
            <input
              type="text"
              placeholder="Email"
              value={this.state.mail}
              onChange={this.handleMailChange}
            />
            {this.state.invalidMail ? <label>L'email est invalide.</label> : undefined}
          </div>
          <div className={'field' + (this.state.textError ? ' error' : '')}>
            <textarea
              placeholder="Message"
              value={this.state.text}
              onChange={this.handleTextChange}
            />
          </div>
          <div className={'field' + (this.state.textError ? ' error' : '')}>
            <input className="sneaky-field"
              autoComplete="off"
              placeholder="Leave this field empty"
              value={this.state.antiBot}
              onChange={this.handleAntiBotChange}
            />
          </div>
        <button
            className={'ui submit primary right floated ' + (this.state.loading ? ' loading' : '') + ' button'}
            type="submit">
            Envoyer mon message
          </button>
        </form>
                
      );
  }
}
