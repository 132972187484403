import { observable } from 'mobx';
import TalkModel from './TalkModel';
import RelatedTalkModel from './RelatedTalkModel';

export default class MainTalkModel {

  @observable talks: TalkModel[];
  @observable count: number;
  @observable end: boolean;
  @observable popularTags: string[];
  @observable talk: TalkModel;
  @observable relatedTalks: RelatedTalkModel[];
  @observable linkedTalks: RelatedTalkModel[];

  constructor(talks: TalkModel[], count: number, end: boolean, popularTags: string[],
    talk: TalkModel, relatedTalks: RelatedTalkModel[], linkedTalks: RelatedTalkModel[]) {
    this.talks = talks || [];
    this.count = count;
    this.end = end;
    this.popularTags = popularTags || [];
    this.talk = talk;
    this.relatedTalks = relatedTalks || [];
    this.linkedTalks = linkedTalks || [];
  }

  resetTalks() {
    this.talks = [];
    this.count = 0;
    this.end = false;
  }

  setTalks(talks: TalkModel[]) {
    if (this.count === 0) {
      this.talks = talks;
    } else {
      this.talks = this.talks.concat(talks);
    }
    this.count = this.count + 1;
    this.end = (talks.length < 10);
  }

  setPopularTags(popularTags: string[]) {
    this.popularTags = popularTags;
  }

  setTalk(talk: TalkModel) {
    this.talk = talk;
  }

  setRelatedTalks(relatedTalks: RelatedTalkModel[]) {
    this.relatedTalks = relatedTalks;
  }

  setLinkedTalks(linkedTalks: RelatedTalkModel[]) {
    this.linkedTalks = linkedTalks;
  }

  toJS() {
    return {
      talks: this.talks.map(t => t.toJS()),
      count: this.count,
      end: this.end,
      popularTags: this.popularTags,
      talk: this.talk.toJS(),
      relatedTalks: this.relatedTalks.map(r => r.toJS()),
      linkedTalks: this.linkedTalks.map(r => r.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const talks = object.talks.map((t: any) => TalkModel.fromJS(t));
      const relatedTalks = object.relatedTalks.map((r: any) => RelatedTalkModel.fromJS(r));
      const linkedTalks = object.linkedTalks.map((r: any) => RelatedTalkModel.fromJS(r));
      return new MainTalkModel(talks, object.count, object.end, object.popularTags,
        TalkModel.fromJS(object.talk), relatedTalks, linkedTalks);
    }
    return undefined;
  }
}
