import * as React from 'react';
import { Link } from 'react-router';
import TextModel from '../../../../models/write/TextModel';

interface TextProps {
  text: TextModel;
}

export default class Text extends React.Component<TextProps, {}> {
  render() {
    const text = this.props.text;
    return (
      <tr key={text.id}>
        <td>
          <Link to={`/text/${text.id}/${text.urlText}`}>
            {text.title}
          </Link>
        </td>
        <td className="center aligned">
          {text.published ?
            <div className="ui blue small label">Publié</div>
            : <div className="ui small label">Non publié</div>}
        </td>
        <td className="center aligned">
          {text.fromDefi ? 'Oui' : 'Non'}
        </td>
        <td className="right aligned" title={`${text.views} lectures`}>
          {text.views}
        </td>
        <td className="right aligned" title={`${text.favorites} J'aime`}>
          {text.favorites}
        </td>
        <td className="right aligned" title={`${text.comments} commentaires`}>
          {text.comments}
        </td>
        <td className="right aligned" title={`${text.annotations} annotations`}>
          {text.annotations}
        </td>
        <td>
          {text.lastModificationDate}
        </td>
      </tr>
    );
  }
}
