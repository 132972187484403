export default class StatisticsModel {

  texts: number;
  defis: number;
  defisProposes: number;
  views: number;
  favorites: number;
  comments: number;
  annotations: number;
  myFavorites: number;
  myComments: number;
  myAnnotations: number;

  constructor(texts: number, defis: number, defisProposes: number, views: number, favorites: number,
    comments: number, annotations: number, myFavorites: number, myComments: number,
    myAnnotations: number) {
    this.texts = texts;
    this.defis = defis;
    this.defisProposes = defisProposes;
    this.views = views;
    this.favorites = favorites;
    this.comments = comments;
    this.annotations = annotations;
    this.myFavorites = myFavorites;
    this.myComments = myComments;
    this.myAnnotations = myAnnotations;
  }

  toJS() {
    return {
      texts: this.texts,
      defis: this.defis,
      defisProposes: this.defisProposes,
      views: this.views,
      favorites: this.favorites,
      comments: this.comments,
      annotations: this.annotations,
      myFavorites: this.myFavorites,
      myComments: this.myComments,
      myAnnotations: this.myAnnotations
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new StatisticsModel(object.texts, object.defis, object.defisProposes, object.views,
        object.favorites, object.comments, object.annotations, object.myFavorites,
        object.myComments, object.myAnnotations
      );
    }
    return undefined;
  }
}
