import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import FeedEvent from './FeedEvent';
/* import PostDiary from './PostDiary'; */
import FeedListModel from '../../models/feed/FeedListModel';
import FeedModel from '../../models/feed/FeedModel';
import Loader from '../misc/Loader';

const InfiniteScroll = require('react-infinite-scroll-component');

interface FeedProps {
  store?: Store;
  withForm: boolean;
  feed: FeedListModel;
  loadMoreFeed: Function;
}

interface FeedState {
  diary: string;
  diaryError: boolean;
  loadingFeedError: boolean;
  loadingFeed: boolean;
}

@inject('store') @observer
export default class Feed extends React.Component<FeedProps, FeedState> {
  constructor(props: FeedProps) {
    super(props);
    this.loadMoreFeed = this.loadMoreFeed.bind(this);
    this.state = {
      diary: '',
      diaryError: false,
      loadingFeedError: false,
      loadingFeed: false
    };
  }

  async loadMoreFeed() {
    this.props.loadMoreFeed( (failed: boolean)=>{
      this.setState(
      {
        loadingFeedError: failed,
        loadingFeed: false
      }
    )
  });
  }

  render() {
    const feed = this.props.feed;
    const connected = this.props.store.connected;
    if (feed && feed.elements) {
      const events = feed.elements.map((e: FeedModel) =>
        <FeedEvent key={Math.random()} event={e} connected={connected} />
      );

      const endMessage = () => {
        let message;
        let error;
        if (!this.props.feed.empty){
          if (this.state.loadingFeed){
            message = <h4>Chargement... </h4>
          }
          else{
            message = <button className="ui button" style={{marginBottom: '20px'}} onClick={
              () => {this.setState({
              loadingFeed: true
            }, () => {this.loadMoreFeed()}    
            )}}>Charger plus</button>
          }
          if (this.state.loadingFeedError){
            error = <h4>Erreur, veuillez réessayer</h4>
          }
        }
        else{
          message = <span>Vous êtes arrivé à la fin</span>
        }
      
        return <div className="ui large feed centered grid">{error}{message}<br/></div>
  
      }

      return (
        <div>
          <InfiniteScroll
            next={this.loadMoreFeed}
            hasMore={false}
            loader={<h4>Chargement...</h4>}
            endMessage={endMessage()}
          >
            <div className="ui large feed">
              
              <div className="ui event" />
              {events}
            </div>
          </InfiniteScroll >
        </div>
      );
    } else {
      return <Loader message="Chargement..." />;
    }
  }
}
