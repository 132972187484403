export function orderByObjId(array: any[]) {
  array.sort((a, b) => {
    return a.id - b.id;
  });
  return array;
}

export function removeDuplicateObjects(array: any[]) {
  return array.filter((item, pos) => {
    return array.indexOf(item) === pos;
  });
}
