import { observable } from 'mobx';
import ParcoursProductPartModel from './ParcoursProductPartModel';
import ProductAuthorModel from './ProductAuthorModel';
import ProductBulletPointModel from './ProductBulletPointModel';

export default class ProductModel {

  @observable id: number;
  @observable name: string;
  @observable description: string;
  @observable shortDescription: string;
  @observable image: string;
  @observable marketIdentifier: number;
  @observable productPrice: number;
  @observable stripeIdentifier: string;  
  @observable productOrder: number;
  @observable titleDescription: string;
  @observable objective: string;
  @observable authorsLink: string;
  @observable currentPrice: number;
  @observable promotionPercentage: number;
  @observable promoCode: string;
  @observable productVideoUrl: string;
  @observable shortName: string;
  @observable shortImage: string;
  @observable parcoursParts: ParcoursProductPartModel[]
  @observable productAuthors: ProductAuthorModel[]
  @observable productBulletPoints: ProductBulletPointModel[]


  constructor(id: number, name: string, description: string, image: string, active: boolean,  marketIdentifier: number, 
    productPrice: number, stripeIdentifier: string, productOrder: number, titleDescription: string, 
    objective: string, authorsLink: string, 
    currentPrice: number, promotionPercentage: number, promoCode: string, productVideoUrl: string,
    shortName: string, shortImage: string,
    shortDescription?: string, parcoursParts?: ParcoursProductPartModel[], productAuthors?: ProductAuthorModel[], 
    productBulletPoints?: ProductBulletPointModel[]) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.shortDescription = shortDescription ? shortDescription : "";
    this.image = image;
    this.marketIdentifier = marketIdentifier;
    this.productPrice = productPrice;
    this.stripeIdentifier = stripeIdentifier;
    this.productOrder = productOrder;
    this.titleDescription = titleDescription;
    this.objective = objective;
    this.authorsLink = authorsLink;
    this.currentPrice = currentPrice;
    this.promotionPercentage = promotionPercentage;
    this.promoCode = promoCode;
    this.productVideoUrl = productVideoUrl;
    this.shortName = shortName;
    this.shortImage = shortImage;
    this.parcoursParts = parcoursParts || [];
    this.productAuthors = productAuthors || [];
    this.productBulletPoints = productBulletPoints || [];
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      shortDescription: this.shortDescription,
      image: this.image,
      marketIdentifier: this.marketIdentifier,
      productPrice: this.productPrice,
      stripeIdentifier: this.stripeIdentifier,
      productOrder: this.productOrder,
      titleDescription:this.titleDescription,
      objective: this.objective,
      authorsLink:this.authorsLink,
      currentPrice:this.currentPrice,
      promotionPercentage:this.promotionPercentage,
      promoCode: this.promoCode,
      productVideoUrl: this.productVideoUrl,
      shortName: this.shortName,
      shortImage: this.shortImage,
      parcoursParts: this.parcoursParts.map(pp => pp.toJS()),
      productAuthors: this.productAuthors.map(pp => pp.toJS()),
      productBulletPoints: this.productBulletPoints.map(pp => pp.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const parcoursParts = object.parcoursParts ? object.parcoursParts.map((pp: any) => ParcoursProductPartModel.fromJS(pp)) : [];
      const productAuthors = object.productAuthors ? object.productAuthors.map((pp: any) => ProductAuthorModel.fromJS(pp)) : [];
      const productBulletPoints = object.productBulletPoints ? object.productBulletPoints.map((pp: any) => ProductBulletPointModel.fromJS(pp)) : [];
      return new ProductModel(
        object.id, object.name, object.description, object.image,  object.marketIdentifier, object.productPrice,
        object.stripeIdentifier, object.productOrder, object.titleDescription, object.objective, 
        object.authorsLink, object.marketDescription, object.currentPrice,
        object.promotionPercentage, object.promoCode, object.productVideoUrl, object.shortName, object.shortImage, 
        object.shortDescription, parcoursParts, productAuthors, productBulletPoints);
    }
    return undefined;
  }
}
