import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class TextModel {

  @observable user: UserModel;
  @observable message: string;

  constructor(user: UserModel, message: string) {
    this.user = user;
    this.message = message;
  }

  toJS() {
    return {
      user: this.user,
      message: this.message
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new TextModel(UserModel.fromJS(object.user), object.message);
    }
    return undefined;
  }
}
