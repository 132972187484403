import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import NewDraftModel from '../../../../../models/write/NewDraftModel';
import ParcoursModel from '../../../../../models/parcours/ParcoursModel';
import ParcoursSummaryContentModel from '../../../../../models/parcours/ParcoursSummaryContentModel';
import ParcoursSummary from '../../../choose/ParcoursSummary';
import Store from '../../../../../stores/Store';

interface PrologueModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface PrologueModalState {
  showTitle: boolean;
  title: string;
}

@inject('store') @observer
export default class PrologueModal
  extends React.Component<PrologueModalProps, PrologueModalState> {
  constructor(props: PrologueModalProps) {
    super(props);
    this.startParcours = this.startParcours.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.startParcours = this.startParcours.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleAnswerTextChange = this.handleAnswerTextChange.bind(this);
    this.hide = this.hide.bind(this);
    this.onSummaryChange = this.onSummaryChange.bind(this);

    this.state = {
      showTitle: false,
      title: ''
    };
  }
  startParcours() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const prologue = this.props.store.mainParcours.prologue;
    this.props.store.createNewTextFromParcours(parcours, this.state.title, prologue.parcoursTextId).then((draft: NewDraftModel) => {
      browserHistory.push({
        pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
        state: {
          newPart: true,
          showNewPart: true,
          showCongratulationsChapter: false
        }
      });
    });
  }
  previous() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const currentQuestion = this.props.store.mainParcours.currentParcours.currentQuestion;
    const currentSummary = this.props.store.mainParcours.currentParcours.currentSummary;

    if (this.state.showTitle) {
      this.setState({
        showTitle: false
      });
    } else if (currentQuestion) {
      const indexCurrentQuestion = parcours.filteredQuestions.map(q => q.id).indexOf(currentQuestion.id);
      if (indexCurrentQuestion > 0) {
        parcours.currentQuestion = parcours.filteredQuestions[indexCurrentQuestion - 1];
      } else {
        parcours.currentQuestion = undefined;
        this.setState({
          showTitle: true
        });
      }
    } else if (currentSummary) {
      parcours.currentSummary = false;
      parcours.currentQuestion = parcours.filteredQuestions[parcours.filteredQuestions.length - 1];
    }
  }
  next() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const currentQuestion = this.props.store.mainParcours.currentParcours.currentQuestion;

    if (!currentQuestion
      && !this.state.showTitle) {
      this.setState({
        showTitle: true
      });
    } else if (this.state.showTitle) {
      parcours.currentQuestion = parcours.filteredQuestions[0];
      this.setState({
        showTitle: false
      });
    } else {
      const indexCurrentQuestion = parcours.filteredQuestions.map(q => q.id).indexOf(currentQuestion.id);
      if (parcours.filteredQuestions[indexCurrentQuestion + 1]) {
        parcours.currentQuestion = parcours.filteredQuestions[indexCurrentQuestion + 1];
      } else {
        parcours.currentQuestion = undefined;
        parcours.currentSummary = true;
      }
    }
  }
  @action
  handleAnswerTextChange(e: any) {
    this.props.store.mainParcours.currentParcours.currentQuestion.answerText = e.target.value;
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  onSummaryChange(content: ParcoursSummaryContentModel, value: string) {
    this.props.store.mainParcours.currentParcours.summary.contents.filter((c: any) => c.index ===
      content.index)[0].body = value;
  }
  hide() {
    this.setState({
      showTitle: false,
      title: undefined
    });
    this.props.hide();
  }
  render() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const previous = parcours && (this.state.showTitle || parcours.currentQuestion || parcours.currentSummary);
    const next = parcours && !parcours.currentSummary;

    let question: JSX.Element;
    let summary: JSX.Element;

    if (parcours && parcours.currentQuestion) {
      const answers = parcours.currentQuestion.answers.map(a => {
        return (
          <div key={`answer_${a.id}`} className="field">
            <Checkbox
              radio
              checked={a.id === parcours.currentQuestion.answerId}
              onClick={() => {
                parcours.currentQuestion.answerId = a.id;
                parcours.filteredQuestions = ParcoursModel.filterQuestions(parcours.questions);
              }}
              label={a.label}
            />
          </div>
        );
      });
      question = (
        <div key={`question_${parcours.currentQuestion.id}`}>
          <h4 dangerouslySetInnerHTML={{ __html: parcours.currentQuestion.label }} />
          <div className="ui form">
            {answers.length > 0 ?
              answers
              : <div className="field">
                <input
                  type="text"
                  onChange={this.handleAnswerTextChange}
                  value={parcours.currentQuestion.answerText} />
              </div>
            }
          </div>
        </div>
      );
    }
    const title = (
      <div className="ui form">
        <div className="field">
          <label>Quel est le titre de votre œuvre ?</label>
          <input
            type="text"
            onChange={this.handleTitleChange}
            placeholder="Titre"
            value={this.state.title}
            autoFocus={true}
          />
        </div>
        <div className="ui message">
          Rassurez-vous, vous pourrez le modifier plus tard !
        </div>
      </div>
    );
    if (parcours && parcours.currentSummary) {
      summary =
        <div>
          <div className="ui info message">
            <div className="ui header">Voici le résumé-type d'une œuvre écrite grâce au parcours.</div>
            Changez les parties que vous avez déjà en tête et laissez les autres telles quelles, vous pourrez y revenir plus tard !
          </div>
          <ParcoursSummary parcoursId={parcours.id} summary={parcours.summary} summaryExamples={parcours.summaryExamples}
            onSummaryChange={this.onSummaryChange} />
        </div>;
    }
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Parcours - {parcours ? '"' + parcours.name + '"' : undefined}</div>
          <div className="content" style={parcours && parcours.currentSummary ? { overflowY: 'scroll', maxHeight: '400px', paddingBottom: '50px' } : { paddingBottom: '50px' }}>
            {parcours ?
              <div>
                {parcours.currentQuestion ?
                  question
                  : (parcours.currentSummary ?
                    summary
                    : (this.state.showTitle ?
                      title
                      : <div>
                        Vous voici prêt à entamer l'écriture de votre roman !
                        <br />Plus que quelques questions afin de mettre en place votre environnement d'écriture...
                      </div>
                    )
                  )
                }
              </div>
              : undefined
            }
          </div>
          <div className="actions">
            {previous ?
              <button className="ui primary button" onClick={this.previous}>Précédent</button>
              : undefined
            }
            {next ?
              <button className="ui primary button"
                onClick={this.next}
                disabled={(parcours.currentQuestion &&
                  (!parcours.currentQuestion.answerId && !parcours.currentQuestion.answerText))
                  || (this.state.showTitle && (!this.state.title || this.state.title.trim().length === 0))}>
                Suivant
                </button>
              : <button className="ui primary button" onClick={this.startParcours}
                disabled={parcours && parcours.currentQuestion &&
                  (!parcours.currentQuestion.answerId && !parcours.currentQuestion.answerText)}>
                Commencer l'écriture
                </button>
            }
            <button className="ui cancel button" onClick={this.hide}>Annuler</button>
          </div>
        </Modal>
      </div>
    );
  }
}
