const stopWords = [
  'au',
  'aux',
  'avec',
  'ce',
  'ces',
  'dans',
  'de',
  'des',
  'du',
  'elle',
  'en',
  'et',
  'eux',
  'il',
  'je',
  'la',
  'le',
  'leur',
  'lui',
  'ma',
  'mais',
  'me',
  'même',
  'mes',
  'moi',
  'mon',
  'ne',
  'nos',
  'notre',
  'nous',
  'on',
  'ou',
  'par',
  'pas',
  'pour',
  'qu',
  'que',
  'qui',
  'sa',
  'se',
  'ses',
  'son',
  'sur',
  'ta',
  'te',
  'tes',
  'toi',
  'ton',
  'tu',
  'un',
  'une',
  'vos',
  'votre',
  'vous',
  'c',
  'd',
  'j',
  'l',
  'à',
  'm',
  'n',
  's',
  't',
  'y',
  'été',
  'étée',
  'étées',
  'étés',
  'étant',
  'suis',
  'es',
  'est',
  'sommes',
  'êtes',
  'sont',
  'serai',
  'seras',
  'sera',
  'serons',
  'serez',
  'seront',
  'serais',
  'serait',
  'serions',
  'seriez',
  'seraient',
  'étais',
  'était',
  'étions',
  'étiez',
  'étaient',
  'fus',
  'fut',
  'fûmes',
  'fûtes',
  'furent',
  'sois',
  'soit',
  'soyons',
  'soyez',
  'soient',
  'fusse',
  'fusses',
  'fût',
  'fussions',
  'fussiez',
  'fussent',
  'ayant',
  'eu',
  'eue',
  'eues',
  'eus',
  'ai',
  'as',
  'avons',
  'avez',
  'ont',
  'aurai',
  'auras',
  'aura',
  'aurons',
  'aurez',
  'auront',
  'aurais',
  'aurait',
  'aurions',
  'auriez',
  'auraient',
  'avais',
  'avait',
  'avions',
  'aviez',
  'avaient',
  'eut',
  'eûmes',
  'eûtes',
  'eurent',
  'aie',
  'aies',
  'ait',
  'ayons',
  'ayez',
  'aient',
  'eusse',
  'eusses',
  'eût',
  'eussions',
  'eussiez',
  'eussent',
  'ceci',
  'cela',
  'celà',
  'cet',
  'cette',
  'ici',
  'ils',
  'les',
  'leurs',
  'quel',
  'quels',
  'quelle',
  'quelles',
  'sans',
  'soi',
];

export default stopWords;
