import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';

interface TextCanvasProps {
  store?: Store;
}

interface TextCanvasState {
  modalActive: boolean;
  activeTab: number;
}

@inject('store') @observer
export default class TextCanvas extends React.Component<TextCanvasProps, TextCanvasState> {
  constructor(props: TextCanvasProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.state = {
      modalActive: false,
      activeTab: 0
    };
  }
  showModal() {
    this.setState({
      modalActive: true
    });
  }
  hideModal() {
    this.setState({
      modalActive: false
    });
  }
  onTabChange(tab: number) {
    this.setState({
      activeTab: tab
    });
  }
  render() {
    const canvas = this.props.store.write.currentText.infos.canvas;
    const allNotesItems = canvas.notes && canvas.notes.length > 0 ?
      canvas.notes.map((note) => {
        return (
          <Menu.Item key={`item_${note.id}`} name={String(note.id)} active={this.state.activeTab === note.id} className="nopadding"
            onClick={() => this.onTabChange(note.id)}>
            {note.title}
          </Menu.Item>
        );
      })
      : [];
    const allNotesBodies = canvas.notes && canvas.notes.length > 0 ?
      canvas.notes.map((note) => {
        return (
          <div key={`body_${note.id}`}>
            {this.state.activeTab === note.id ?
              <div dangerouslySetInnerHTML={{ __html: note.description }} />
              : undefined}
          </div>
        );
      })
      : [];
    return (
      <div
        className="ui item basic button"
        onClick={this.showModal}
        title="Canevas"
      >
        <Modal
          open={this.state.modalActive}
          size="small"
          onClose={this.hideModal}
        >
          <div className="header">Canevas associé : {canvas.title}</div>
          <div className="content">
            {canvas.notes && canvas.notes.length > 0 ?
              <div style={{ marginTop: '-20px' }}>
                <Menu
                  activeIndex={this.state.activeTab}
                  secondary
                  pointing
                  fluid
                  widths={(canvas.notes.length + 1) as any}
                  size="small"
                  className="stackable"
                >
                  <Menu.Item name="1" active={this.state.activeTab === 0} className="nopadding"
                    onClick={() => this.onTabChange(0)}>
                    Présentation
                  </Menu.Item>
                  {allNotesItems}
                </Menu>
                {this.state.activeTab === 0 ?
                  <div dangerouslySetInnerHTML={{ __html: canvas.description }} />
                  : undefined}
                {allNotesBodies}
              </div>
              :
              <div dangerouslySetInnerHTML={{ __html: canvas.description }} />
            }
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.hideModal}>Fermer</button>
          </div>
        </Modal>
        Canevas
      </div>
    );
  }
}
