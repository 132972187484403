import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import SettingsAdminPremiumModalProps from './SettingsAdminPremiumModal';
import Select from 'react-select'
import { Link } from 'react-router';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import PremiumModel from '../../models/settings/PremiumModel';

const InfiniteScroll = require('react-infinite-scroll-component');

interface SearchType {
  value : string;
  label: string;
  index: number;
}

interface SettingsPageAdminSearchPageProps {
  store: Store;
}

interface SettingsPageAdminSearchPageState {
  searchType: SearchType;
  userInfo: string;
  loadingUserInfo: boolean;
  loadingMoreUserInfo: boolean;
  page: number;
  premiumModalActive: boolean;
  premiumInfos: PremiumModel[];
  usernameModal: string;
  userId: number;
  searchPremium: boolean;
}

@inject('store') @observer
export default class SettingsPageAdminSearchPage extends React.Component<SettingsPageAdminSearchPageProps, SettingsPageAdminSearchPageState> {
  constructor(props: SettingsPageAdminSearchPageProps) {
    super(props);
    this.handleUserInfoChange = this.handleUserInfoChange.bind(this);
    this.handleChangeSearchType = this.handleChangeSearchType.bind(this);
    this.loadMoreSearchUsersBy = this.loadMoreSearchUsersBy.bind(this);
    this.searchUsersBy = this.searchUsersBy.bind(this);
    this.openPremiumModal = this.openPremiumModal.bind(this);
    this.hidePremiumModal = this.hidePremiumModal.bind(this);
    this.updateUserPremium = this.updateUserPremium.bind(this);
    this.handleChangeSearchPremium = this.handleChangeSearchPremium.bind(this);
    this.state = {
      searchType: {value: 'email', label: 'Email', index: 1},
      userInfo: '',
      loadingUserInfo: false,
      loadingMoreUserInfo: false,
      page: 0,
      premiumModalActive: false,
      premiumInfos: [],
      usernameModal: "",
      userId: null,
      searchPremium: false
    };
  }
  openPremiumModal(premiumInfos: PremiumModel[], usernameModal: string, userId: number) {
    this.setState({
      premiumModalActive: true,
      premiumInfos: premiumInfos,
      usernameModal: usernameModal,
      userId: userId
    })
  }
  hidePremiumModal() {
    this.setState({
      premiumModalActive: false,
      premiumInfos: [],
      usernameModal: "",
      userId: null
    })
  }
  updateUserPremium(id: string, premium: PremiumModel[]) {
     this.setState({
      premiumInfos: premium
    }) 
     this.props.store.updateSearchUsersByResult(parseInt(id), premium)
 }
  handleUserInfoChange(e: any) {
    const userInfo = e.target.value;
    this.setState({
        userInfo: userInfo
    });
  }
  handleChangeSearchType(selectedType: SearchType) {
    this.setState({
      ...this.state,
      searchType: selectedType
    });
  }
  handleChangeSearchPremium() {
    this.setState({
      ...this.state,
      searchPremium: !this.state.searchPremium
    })
  }
  @action searchUsersBy(event: any) {
    event.preventDefault();
    this.setState({
      ...this.state,
      page: 0,
      loadingUserInfo: true
    })
    this.props.store.searchUsersBy(this.state.userInfo, this.state.searchType.value, 0, this.state.searchPremium).then((d: any) => {
      this.setState({
        ...this.state,
        page: 1,
        loadingUserInfo: false
      });
    });
  }
  @action loadMoreSearchUsersBy(event: any) {
    this.setState({
      ...this.state,
      loadingMoreUserInfo: true
    })
    this.props.store.searchUsersBy(this.state.userInfo, this.state.searchType.value, this.state.page, this.state.searchPremium).then((d: any) => {
      this.setState({
        ...this.state,
        page: this.state.page+1,
        loadingMoreUserInfo: false
      });
    });
  }
  render() {
    const searchedUsersElement = this.props.store.searchedUsersByInfo.map((user, i) => {
      let isPremium = false;
      user.premium.map((p) => {
        if ((p.marketIdentifier != 0 || p.allInclusive) && p.active)
          isPremium = true;
      })
      return (
        <tr key={user.id}>
        <td>
          <Link to={`/author/${user.id}/${user.urlIdentity}`}>
            {user.id}
          </Link>
        </td>
        <td className="center aligned">
          {user.email}
        </td>
        <td className="center aligned">
          {user.username ? user.username : "/"}
        </td>
        <td className="center aligned">
          {user.firstName ? user.firstName : "/"}
        </td>
        <td className="center aligned">
          {user.lastName ? user.lastName : "/"}
        </td>
        <td className="center aligned">
          {user.ip ? user.ip : "/"}
        </td>
        <td className="center aligned">
          {user.dateCreation ? user.dateCreation : "/"}
        </td>
        <td className="center aligned">
          {user.birthDate ? user.birthDate : "/"}
        </td>
        <td className="center aligned">
          {user.provider ? 
          user.provider === "facebook" ? <i className={"fa fa-facebook teal icon"}/> : <i className={"fa fa-times disabled icon"}/> 
          : "/"}
        </td>
        <td className="center aligned">
          {isPremium ?
            <i style={{color: "green"}} className="fa fa-check green"/>
          :  
            <i style={{color: "red"}} className="fa fa-times red"/>
          }
          <i
            className="ui fa fa-eye"
            style={{cursor: "pointer", color: "#2185D0"}}
            onClick={() => this.openPremiumModal(user.premium, user.username, user.id)}
          />
        </td>
      </tr>
      );
    });
    const endMessage = () => {
      let message;
      let error;
      if (this.props.store.searchedUsersByInfo.length === this.state.page*15){
        if (this.state.loadingMoreUserInfo){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMoreSearchUsersBy}>Charger plus</button>
        }
        /* if (this.state.loadingMoreUserInfo){
          error = <h4>Erreur, veuillez réessayer</h4>
        } */
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
      return <div className="ui large feed centered grid">{error}{message}</div>
    }
    const connected = this.props.store.connected;
    const isAdmin = connected && connected.user && connected.user.admin;
    const searchTypeOption: SearchType[] = [];
    searchTypeOption.push({index: 1, value: 'email', label: 'Email'});
    searchTypeOption.push({index: 2, value: 'id', label: 'Id'});
    searchTypeOption.push({index: 3, value: 'ip', label: 'Ip'});
    return (
      <div className="ui centered grid">
        {isAdmin ?
          <div className="ui fifteen wide column">
            <div>
              <h3>Recherche par 
              <Select
                clearable={false}
                value={this.state.searchType}
                onChange={this.handleChangeSearchType}
                options={searchTypeOption}
              ></Select>
                </h3>
              <form onSubmit={this.searchUsersBy} className="ui reply form" style={{ padding: '10px' }}>
                <div className="field">
                  <label>{this.state.searchType.label}</label>
                  <input
                    type="text"
                    value={this.state.userInfo}
                    onChange={this.handleUserInfoChange}
                  />
                </div>
                <Checkbox
                  checked={this.state.searchPremium}
                  onClick={this.handleChangeSearchPremium}
                  label={
                    <label>Utilisateurs premium
                    </label>}
                  />
                <button
                  className={"ui blue submit right floated" + (this.state.loadingUserInfo ? ' loading' : '') + " button"}
                
                  type="submit"
                >
                  Rechercher
                </button>
              </form>
            </div>
            <div>
            <InfiniteScroll
              next={this.loadMoreSearchUsersBy}
              hasMore={false}
              loader={<h4>Chargement...</h4>}
              endMessage={endMessage()}
              style={{width: "100%"}}
            >
              <table style={{fontSize: "0.9rem", overflowWrap: "break-word", marginTop: "15px"}} className="ui fixed stackable table teal">
                <thead>
                  <tr>
                    <th className="one wide center aligned">
                      Id
                    </th>
                    <th className="three wide center aligned">
                      Email
                    </th>
                    <th className="two wide center aligned">
                      Username
                    </th>
                    <th className="two wide center aligned">
                      Prénom
                    </th>
                    <th className="two wide center aligned">
                      Nom
                    </th>
                    <th className="two wide center aligned">
                      Ip
                    </th>
                    <th className="one wide center aligned">
                      Date d'inscription
                    </th>
                    <th className="one wide center aligned">
                      Date de naissance
                    </th>
                    <th className="One wide center aligned">
                      <i className={"fa fa-facebook"} />
                    </th>
                    <th className="One wide center aligned">
                      <i className={"fa fa-dollar"} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchedUsersElement.length > 0
                    ? searchedUsersElement
                    : <tr>
                      <td colSpan={6} className="center aligned">
                        Aucun résultat
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
              </InfiniteScroll>
              <SettingsAdminPremiumModalProps 
                active={this.state.premiumModalActive} hide={this.hidePremiumModal} update = {this.updateUserPremium}
                title={this.state.usernameModal}
                premiumInfos={this.state.premiumInfos}
                id={this.state.userId}
                />
            </div>
          </div>
          : undefined
        }
      </div>
    );
  }
}
