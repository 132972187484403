import { observable } from 'mobx';
import DraftModel from './DraftModel';
import DraftDefiModel from './DraftDefiModel';
import TextModel from './TextModel';
import CurrentTextModel from './CurrentTextModel';
import StructureModel from './StructureModel';
import TrainingModel from './TrainingModel';
import TrainingInstructionsModel from './TrainingInstructionsModel';

export default class WriteModel {

  @observable drafts: DraftModel[];
  @observable draftDefis: DraftDefiModel[];
  @observable texts: TextModel[];
  @observable currentText: CurrentTextModel;
  @observable newStructure: StructureModel;
  @observable loading: boolean;
  @observable blocking: boolean;

  @observable page: number;
  @observable end: boolean;
  @observable criteria: string;
  @observable order: string;

  @observable trainingInstructions: TrainingInstructionsModel;
  @observable training: TrainingModel;

  constructor(drafts: DraftModel[], draftDefis: DraftDefiModel[], texts: TextModel[],
    currentText: CurrentTextModel, newStructure: StructureModel, loading: boolean,
    blocking: boolean, page: number, end: boolean, criteria: string,
    order: string, trainingInstructions: TrainingInstructionsModel, training: TrainingModel) {
    this.drafts = drafts || [];
    this.draftDefis = draftDefis || [];
    this.texts = texts || [];
    if (currentText) {
      this.currentText = currentText;
    } else {
      this.currentText = new CurrentTextModel(undefined, undefined, undefined, undefined,
        undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        undefined, undefined, undefined, undefined);
    }
    this.newStructure = newStructure;
    this.loading = loading;
    this.blocking = blocking;
    this.page = page;
    this.end = end;
    this.criteria = criteria;
    this.order = order;
    this.trainingInstructions = trainingInstructions;
    this.training = training;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  enableBlocking() {
    this.blocking = true;
  }

  disableBlocking() {
    this.blocking = false;
  }

  setDrafts(drafts: DraftModel[], draftDefis: DraftDefiModel[]) {
    this.drafts = drafts.map(d =>
      new DraftModel(d.id, d.title, d.urlText, d.chapters, d.lastModificationDate)
    );
    this.draftDefis = draftDefis.map(d =>
      new DraftDefiModel(d.id, d.title, d.urlText, d.chapters, d.lastModificationDate,
        d.defiTitle, d.deadline)
    );
  }

  setTexts(first: boolean, texts: TextModel[]) {
    const newTexts = texts.map(t =>
      new TextModel(t.id, t.title, t.urlText, t.fromDefi, t.views, t.favorites,
        t.comments, t.annotations, t.countChapters,
        t.published, t.datePublication, t.lastModificationDate));

    if (first || this.page === 0) {
      // New
      this.texts = newTexts;
      this.page = 1;
    } else {
      this.texts = this.texts.concat(newTexts);
      this.page = this.page + 1;
    }

    this.end = texts.length < 20;
  }

  clearCurrentText() {
    this.currentText = new CurrentTextModel(undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined);
  }

  setTrainingInstructions(trainingInstructions: TrainingInstructionsModel, keepPitch: boolean) {
    if (keepPitch) {
      this.trainingInstructions.constraint = trainingInstructions.constraint;
    } else {
      this.trainingInstructions = trainingInstructions;
    }
  }

  toJS() {
    return {
      drafts: this.drafts.map(d => d.toJS()),
      draftDefis: this.draftDefis.map(d => d.toJS()),
      texts: this.texts.map(t => t.toJS()),
      currentText: this.currentText.toJS(),
      newStructure: this.newStructure.toJS(),
      loading: this.loading,
      blocking: this.blocking,
      trainingInstructions: this.trainingInstructions,
      training: this.training.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const drafts = object.drafts.map((d: any) => DraftModel.fromJS(d));
      const draftDefis = object.draftDefis.map((d: any) => DraftDefiModel.fromJS(d));
      const texts = object.texts.map((t: any) => TextModel.fromJS(t));
      const currentText = CurrentTextModel.fromJS(object.currentText);
      const newStructure = StructureModel.fromJS(object.newStructure);
      const training = TrainingModel.fromJS(object.training);
      return new WriteModel(drafts, draftDefis, texts, currentText, newStructure, object.loading,
        object.blocking, object.page, object.end, object.criteria, object.order, object.trainingInstructions,
        training);
    }
    return undefined;
  }

}
