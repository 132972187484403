import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import * as bowser from 'bowser';
const ReactPlayer = require('react-player');
const LazyLoad = require('react-lazy-load').default;
import Store from '../../stores/Store';
import AlertModel from '../../models/AlertModel';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Title from '../misc/Title';
import '../ada.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import NewsletterWidget from './NewsletterWidget';



interface CommunautePageAdaProps {
  store?: Store;
}

@inject('store') @observer
export default class CommunautePageAda extends React.Component<CommunautePageAdaProps, {}> {
  constructor(props: CommunautePageAdaProps) {
    super(props);
  }

  signUp() {
    browserHistory.push({
      pathname: '/signup'
    });
  }
  
  render() {
    const connected = this.props.store.connected;
    const isConnected = connected && connected.user && connected.user.id
    return (
      <div style={{backgroundColor: '#fff', paddingBottom: '100px'}}> 
      <Title title="La communauté" scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            '@type': "Organization",
            "url": "https://www.atelierdesauteurs.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://scribay.s3.amazonaws.com/assets/logo_square.png"
            }
          })
        }]} />    
        {/* <NewsletterWidget/>   */} 
        <div className="ui" style={{ marginTop: '-25px' }}>
          <div className="communaute-ada" style={{ paddingBottom: "120px"}}>
              <div className="ui container">
                <div style={{ color: "white", maxWidth: '500px' }}>
                  <h1 className="ada-title-font ada-museo-500" style={{lineHeight: "4.3rem", textTransform: "uppercase", paddingTop: "135px", fontSize: '4.5rem' }}>
                    N'écrivez plus jamais seul
                  </h1>
                  <h2 className="ada-title-font ada-museo-300" style={{ marginTop:"-5px", fontSize: '2rem' }}>Trouvez l'inspiration, écrivez,</h2>
                  <h2 className="ada-title-font ada-museo-300" style={{ marginTop:"-20px", fontSize: '2rem' }}>et progressez au sein d'une</h2>
                  <h2 className="ada-title-font ada-museo-300" style={{ marginTop:"-20px", fontSize: '2rem' }}>communauté accueillante</h2>
                  {!isConnected ?
                    <button
                      className="ui button ada-background editis-bleu-nuit" 
                      onClick={() => this.signUp()} 
                      style={{ cursor: 'pointer', color: "white"}}
                    >
                      Rejoindre la communauté
                    </button>
                  : undefined}
                </div>
              </div>
            </div>
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "45px"}}>
          <h4 className="ui horizontal divider hide-tablet ada-divider">Créez, échangez, progressez ensemble</h4>
          <h4 className="ui horizontal hide-tablet-large ada-divider">Créez, échangez, progressez ensemble</h4>
          <div className="ui middle aligned relaxed stackable grid">
            <div className="seven wide left floated column" style={{overflow: "hidden"}}>
              <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/commu.jpg"
                alt="Two books" width="100%" />
            </div>
            <div className="hide-large seven wide column ada-text editis-bleu-nuit" 
              style={{textAlign:"left", fontSize: "1.2rem" }}>
                Commentez les textes d’autres membres.
                Présentez vos textes à la communauté et recueilliez l’avis de vos pairs.
            </div>
            <div className="hide-mobile seven wide column ada-text editis-bleu-nuit" 
              style={{borderLeft: "solid", borderWidth:"1px", textAlign:"left", paddingTop: "65px", paddingBottom: "65px", fontSize: "1.2rem" }}>
                Commentez les textes d’autres membres.
                Présentez vos textes à la communauté et recueilliez l’avis de vos pairs.
            </div>
          </div>
          {/* <div className="ui two column very relaxed grid stackable" style={{paddingBottom: '30px'}}>
            <div className="eight wide column">
                <div className="ui centered grid">
                  <div className="ada-image-title ada-museo-500" style={{width:"70%"}}>
                    <p>Commentez les textes</p>
                    <p style={{marginTop:"-15px"}}>d'autres membres</p>
                    </div>
                </div>
                <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/comment.jpg"
                  alt="Two books" width="100%" />
                  <div className="ui centered grid">
                    <div className="row ada-image-subtitle">
                      <p>Commentez, annotez, corrigez</p>
                    </div>
                </div>
            </div>
            <div className="eight wide column">
                <div className="ui centered grid">
                  <div className="ada-image-title ada-museo-500" style={{width:"50%"}}>
                    <p>Présentez vos textes</p>
                    <p style={{marginTop:"-15px"}}>à la communauté</p>
                  </div>
                </div>
            <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/commu.jpg"
                  alt="Two book" width="100%" />
                  <div className="ui centered grid">
                  <div className="row ada-image-subtitle"><p>Recueillez l'avis de vos pairs</p></div>
                </div>
            </div>
          </div> */}
        </div>
        <div className="ada-background editis-beige-clair">
          <div className="ui center aligned container"  style={{paddingBottom: "45px"}}>
            <h4 className="ui horizontal divider hide-tablet ada-divider">Entraide et bienveillance</h4>
            <h4 className="ui horizontal hide-tablet-large ada-divider">Entraide et bienveillance</h4>
            <div className="ui middle aligned relaxed stackable grid">
              <div className="seven wide left floated column" style={{overflow: "hidden"}}>
                <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/Proust.png"
                  alt="Two books" width="100%" />
              </div>
              <div className="hide-large seven wide column ada-text editis-bleu-nuit" 
                style={{textAlign:"left", fontSize: "1.2rem" }}>
                <p>
                  Les membres mis en avant ne sont pas les plus populaires, mais les plus investis auprès de la communauté.
                </p>
              </div>
              <div className="hide-mobile seven wide column ada-text editis-bleu-nuit" 
                style={{borderLeft: "solid", borderWidth:"1px", textAlign:"left", paddingTop: "65px", paddingBottom: "65px", fontSize: "1.2rem" }}>
                <p>
                  Les membres mis en avant ne sont pas les plus populaires, mais les plus investis auprès de la communauté.
                </p>
              </div>
            </div>
          </div>
        </div>
      <div className="ui center aligned container">
      <h4 className="ui horizontal divider hide-tablet ada-divider">Oubliez la page blanche</h4>
      <h4 className="ui horizontal hide-tablet-large ada-divider">Oubliez la page blanche</h4>
        <div className="ui middle aligned relaxed stackable grid">
          <div className="seven wide right floated column hide-large" style={{overflow: "hidden"}}>
            <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/défi_littéraire.png"
              alt="Two books" width="100%" />
          </div>
          <div className="hide-large seven wide column ada-text editis-bleu-nuit" style={{textAlign:"left", fontSize: "1.2rem" }}>
            <p>
              Respirez un bon coup. Parcourez les défis littéraires de l'Atelier. Relevez celui qui vous fait envie. Ecrivez.
            </p>
            <p style={{marginTop: "-12px"}}>
              C'est aussi simple que cela.
            </p>
          </div>
          <div className="hide-mobile seven wide column ada-text editis-bleu-nuit" 
          style={{borderRight: "solid", borderWidth:"1px", textAlign:"right", paddingTop: "65px", paddingBottom: "65px", fontSize: "1.2rem" }}>
            <p>
              Respirez un bon coup. Parcourez les défis littéraires de l'Atelier. Relevez celui qui vous fait envie. Ecrivez.
            </p>
            <p style={{marginTop: "-12px"}}>
              C'est aussi simple que cela
            </p>
          </div>
            <div className="seven wide right floated column hide-mobile" style={{overflow: "hidden"}}>
                <img className="ada-top-lef-rounded-picture" src="https://scribay.s3.amazonaws.com/assets/défi_littéraire.png"
                  alt="Two books" width="100%" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
