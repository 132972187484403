import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../../../stores/Store';
import PreventAccess from './PreventAccess';
const cookie = new (require('react-cookie')).Cookies();

interface TextStatisticsButtonState {
  activeModal: boolean;
}

interface TextStatisticsButtonProps {
  store?: Store;
}

@inject('store') @observer
export default class TextStatisticsButton extends React.Component<TextStatisticsButtonProps, TextStatisticsButtonState> {
  constructor(props: TextStatisticsButtonProps) {
    super(props);
    this.state = {
      activeModal: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.goToStats = this.goToStats.bind(this);
  }
  showModal() {
    this.setState({
      activeModal: true
    });
  }
  hideModal() {
    this.setState({
      activeModal: false
    });
  }
  goToStats() {
    const text = this.props.store.write.currentText;
    const parcours = this.props.store.mainParcours;
    const textId = text.id ? text.id : parcours.status ? parcours.status.textId && parcours.status.textId : undefined;
    const urlText = text.infos && text.infos.urlText ? text.infos.urlText : parcours.status ? parcours.status.textUrl && parcours.status.textUrl : undefined;
    const chapterId = text && text.currentChapter ? text.currentChapter.id : undefined;
    browserHistory.push({
      pathname: `/text/${textId}/${urlText}/statistics/write`,
      query: {
        chapter: chapterId
      }
    });
  }
  render() {
    const text = this.props.store.write.currentText;
    const parcours = this.props.store.mainParcours;
    const textId = text.id ? text.id : parcours.status ? parcours.status.textId && parcours.status.textId : undefined;
    const urlText = text.infos && text.infos.urlText ? text.infos.urlText : parcours.status ? parcours.status.textUrl && parcours.status.textUrl : undefined;
    const chapterId = text && text.currentChapter ? text.currentChapter.id 
    : parcours && parcours.status && parcours.status.chapterId ? 
    parcours.status.chapterId : undefined;
    const cookieExists = cookie && cookie.get && cookie.get(`prevent_stats_${text.id}`);
    return chapterId ?
      (cookieExists ? 
          <Link
            to={`/text/${textId}/${urlText}/statistics/write?chapter=${chapterId}`}
            className="ui item basic button"
            title="Statistiques"
          >
            <i className="fa fa-bar-chart" style={{ marginRight: '5px' }} />
            Statistiques
          </Link>
        : 
          <button onClick={this.showModal} className="ui item basic button"
            title="Statistiques">
            <PreventAccess id="stats" active={this.state.activeModal}
              goToLink={this.goToStats} hide={this.hideModal} />
              <i className="fa fa-bar-chart" style={{ marginRight: '5px' }} />
              Statistiques
          </button>
      ) : <div></div>;
  }
}
