import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import UserModel from '../../../../../models/UserModel';

interface AddThanksModalProps {
  users: { user: UserModel, active: boolean }[];
  addThanksModal: boolean;
  publish: Function | any;
  cancelAddThanks: Function | any;
  addToThanks: Function | any;
  removeFromThanks: Function | any;
}

export default class AddThanksModal extends React.Component<AddThanksModalProps, {}> {
  constructor(props: AddThanksModalProps) {
    super(props);
  }
  render() {
    const users = this.props.users.map(u => {
      return (
        <div key={u.user.id} className="item">
          <div className="content">
            <div className="header">
              <Checkbox
                checked={u.active}
                toggle
                onClick={() => {
                  if (u.active) {
                    this.props.removeFromThanks(u.user.id);
                  } else {
                    this.props.addToThanks(u.user.id);
                  }
                } }
                className={!u.active ? 'inactive' : ''}
                label={u.user.identity}
                />
            </div>
          </div>
        </div>
      );
    });
    return (
      <Modal
        open={this.props.addThanksModal}
        onClose={() => { } }
        >
        <div className="header">Une dernière chose ...</div>
        <div className="content">
          Depuis la dernière version de ce chapitre, certains auteurs ont publié des commentaires ou annotations.
          <br />
          Nous nous sommes dit que vous voudriez peut-être les remercier publiquement pour leur soutien !
          <div className="ui relaxed list">
            {users}
          </div>
        </div>
        <div className="actions">
          <button className="ui primary submit button" onClick={this.props.publish}>Publier</button>
          <button className="ui cancel button" onClick={this.props.cancelAddThanks}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
