const etreConjug = [
  'a été',
  'ai été',
  'aie été',
  'aient été',
  'aies été',
  'ait été',
  'as été',
  'aura été',
  'aurai été',
  'auraient été',
  'aurais été',
  'aurait été',
  'auras été',
  'aurez été',
  'auriez été',
  'aurions été',
  'aurons été',
  'auront été',
  'avaient été',
  'avais été',
  'avait été',
  'avez été',
  'aviez été',
  'avions été',
  'avons été',
  'ayez été',
  'ayons été',
  'es',
  'est',
  'eurent été',
  'eus été',
  'eusse été',
  'eussent été',
  'eusses été',
  'eussiez été',
  'eussions été',
  'eut été',
  'eûmes été',
  'eût été',
  'eûtes été',
  'furent',
  'fus',
  'fusse',
  'fussent',
  'fusses',
  'fussiez',
  'fussions',
  'fut',
  'fûmes',
  'fût',
  'fûtes',
  'ont été',
  'sera',
  'serai',
  'seraient',
  'serais',
  'serait',
  'seras',
  'serez',
  'seriez',
  'serions',
  'serons',
  'seront',
  'soient',
  'sois',
  'soit',
  'sommes',
  'sont',
  'soyez',
  'soyons',
  'suis',
  'étaient',
  'étais',
  'était',
  'étiez',
  'étions',
  'êtes'
];

export default etreConjug;
