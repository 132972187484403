import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import BadgeModel from '../../models/BadgeModel';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface BadgeModalProps {
  badge: BadgeModel;
  active: boolean;
  hideModal: any | Function;
}

export default class BadgeModal extends React.Component<BadgeModalProps, {}> {
  render() {
    const badge = this.props.badge;
    const badgeName = <FormattedMessage
      id={`badge.${badge.name}.name`}
      defaultMessage={`Nom ${badge.name}`}
    />;
    const badgeDescription = <FormattedHTMLMessage
      id={`badge.${badge.name}.description`}
      defaultMessage={`Description ${badge.name}`}
      values={{ 0: badge.level }}
    />;
    let badgeUrl: string;
    if (badge.level) {
      badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/${badge.name}/${badge.level}.png`;
    } else {
      if (badge.name.indexOf('defi') !== -1) {
        badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/defis/${badge.name.split('_')[1]}.png`;
      } else {
        badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/misc/${badge.name}.png`;
      }
    }
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={this.props.hideModal}
          size="small"
        >
          <div className="header">Badge <b>{badgeName}</b></div>
          <div className="ui content items">
            <div className="item">
              <div className="ui tiny image">
                <img src={badgeUrl} alt={`Image du badge ${badgeName}`} />
              </div>
              <div className="content">
                <a className="header">{badgeName}</a>
                <div>{badgeDescription}</div>
              </div>
            </div>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hideModal}>Fermer</button>
          </div>
        </Modal>
      </div>
    );
  }
}

