import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';
import TextParams from './TextParams';
import StructureList from './StructureList';
import ChapterLike from './ChapterLike';
import ChapterRead from './ChapterRead';
import ChapterModeration from './ChapterModeration';
import TextChapterDownload from './TextChapterDownload';
import ChapterLists from './ChapterLists';
import ChapterShare from './ChapterShare';
import ChapterAnnotationsSwitch from './ChapterAnnotationsSwitch';
import ChapterStep from '../write/text/chapter/toolbar/ChapterStep';

interface SubTextChapterBarProps {
  store?: Store;
  params: TextParams;
  location: {
    state: {
      showStepModal: boolean
    }
  };
}

@inject('store') @observer
export default class SubTextChapterBar extends React.Component<SubTextChapterBarProps, {}> {
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected;
    const chapterIndex = text && text.meta && text.meta.structure && text.chapter ?
      (text.meta.structure.chapters && text.meta.structure.chapters.length > 0 ?
        text.meta.structure.chapters.map(c => c.id).indexOf(text.chapter.id) + 1
        : [].concat.apply([], text.meta.structure.parts.map(p => (p.chapters as any).toJS())).map((c: any) => c.id).indexOf(text.chapter.id) + 1
      )
      : undefined;
    return (
      <div
        className="ui secondary stackable menu chapter-menu">
        <div className="item" style={{ border: 'none' }}>
          <StructureList
            textId={this.props.params.textId}
            title={text.meta.title}
            urlText={text.meta.urlText}
            userIdentity={text.meta.author.identity}
            structure={text.meta.structure}
          />
          {text.chapter ?
            <ChapterStep
              textId={text.meta.id}
              chapter={text.chapter}
              position="bottom center"
              readonly={(!connected || !connected.user || !connected.user.id)
                || connected.user.id !== text.meta.author.id}
              forceShow={this.props.location.state && this.props.location.state.showStepModal}
              readMode={true}
            />
            : undefined}
        </div>
        {text && text.meta && text.chapter ?
          <div className="right menu" style={{ border: 'none' }}>
            <div className="item">
              <ChapterLike topbar />
              {text.meta.openToExport ?
                <TextChapterDownload icon chapter />
                : undefined}
              <ChapterAnnotationsSwitch />
              <ChapterLists />
              <ChapterModeration />
              {connected && connected.user && connected.user.id === text.meta.author.id ?
                <div>
                  <Link
                    to={`/text/${text.meta.id}/${text.meta.urlText}/chapter/${text.chapter.id}/edit`}
                    className="ui orange icon button"
                    data-tooltip="Modifier" data-inverted="" data-position="bottom center"
                  >
                    <i className="fa fa-edit" />
                  </Link>
                </div>
                : undefined
              }
            </div>
            <div className="item">
              <div>
                <ChapterShare textTitle={text.meta.title} chapterIndex={chapterIndex} type="facebook" />
              </div>
              <div>
                <ChapterShare textTitle={text.meta.title} chapterIndex={chapterIndex} type="twitter" />
              </div>
            </div>
            {connected && connected.user && connected.user.id !== text.meta.author.id ?
              <div className="item">
                <ChapterRead />
              </div>
              : undefined}
          </div>
          : undefined}
      </div>
    );
  }
}
