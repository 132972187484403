import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../stores/Store';

interface TitleModalProps {
  active: boolean;
  title: string;
  handleTitleChange: Function | any;
  cancel: Function | any;
  next: Function | any;
  store?: Store;
}

@inject('store') @observer
export default class TitleModal extends React.Component<TitleModalProps, {}> {
  constructor(props: TitleModalProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Nouvelle œuvre</div>
          <div className="content">
            <div className="ui form">
              <div className="field">
                <label>Quel est le titre de votre œuvre ?</label>
                <input
                  type="text"
                  onChange={this.props.handleTitleChange}
                  placeholder="Titre"
                  value={this.props.title}
                  autoFocus={true}
                />
              </div>
              <div className="ui message">
                Rassurez-vous, vous pourrez le modifier plus tard !
              </div>
            </div>
          </div>
          <div className="actions">
            <button
              className="ui blue approve button"
              onClick={this.props.next}
              disabled={!this.props.title || this.props.title.trim().length === 0}
            >
              Suivant
            </button>
            <button className="ui button" onClick={this.props.cancel}>
              Annuler
          </button>
          </div>
        </Modal>
      </div>
    );
  }
}
