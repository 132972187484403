import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';

interface ChapterVersionsProps {
  store?: Store;
}

interface ChapterVersionsState {
  showVersions: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class ChapterVersions extends React.Component<ChapterVersionsProps, ChapterVersionsState> {
  constructor(props: ChapterVersionsProps) {
    super(props);
    this.showVersions = this.showVersions.bind(this);
    this.hideVersions = this.hideVersions.bind(this);
    this.state = {
      showVersions: false
    };
  }
  showVersions() {
    this.setState({
      showVersions: true
    });
  }
  hideVersions() {
    this.setState({
      showVersions: false
    });
  }
  render() {
    const text = this.props.store.text;
    const versions = text.chapter.versions.map(v => {
      return (
        <li key={`version_${v.id}`}>
          <Link to={`/text/${text.meta.id}/${text.meta.urlText}/chapter/${text.chapter.id}?version=${v.id}`}>
            {v.date}
          </Link>
        </li>
      );
    });
    return (
      <div>
        <div className="ui hidden section divider" />
        <h3 className="ui horizontal divider">
          Versions
        </h3>
        {this.state.showVersions ?
          <ol className="reverse" style={{ counterReset: `item ${versions.length + 1}` }}>
            {versions}
          </ol>
          :
          <div className="ui basic center aligned segment">
            Ce chapitre compte {versions.length} versions.
            <div>
              <button onClick={this.showVersions} className="ui small button" style={{ marginTop: '10px' }}>
                Afficher la liste des versions
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}
