import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
import { Line } from 'react-chartjs-2';

interface ReadIndicatorProps {
  store?: Store;
  chapterId?: number;
  title: string;
}

@inject('store') @observer
export default class ReadIndicator extends React.Component<ReadIndicatorProps, {}> {
  render() {
    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsRead;
    const rows = statistics.map((statistic) => {
      return (
        <tr key={`stat_read_${statistic.year}${statistic.month}`}>
          <td>
            {statistic.year}
          </td>
          <td>
            {statistic.month}
          </td>
          <td className={'right aligned' + (statistic.statistic > 0 ? ' positive' : '')}>
            {statistic.statistic}
          </td>
          <td className="right aligned">
            {statistic.cumulated}
          </td>
        </tr>
      );
    });

    const labels = statistics.map(stat => `${stat.month}/${stat.year}`);

    const simple = statistics.map(stat => stat.statistic);
    const dataSimple = {
      labels: labels,
      datasets: [
        {
          label: this.props.title,
          backgroundColor: 'rgb(54, 162, 235)',
          borderColor: 'rgb(54, 162, 235)',
          fill: false,
          data: simple
        }
      ]
    };

    const cumul = statistics.map(stat => stat.cumulated);
    const dataCumul = {
      labels: labels,
      datasets: [
        {
          label: `Cumul ${this.props.title}`,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          fill: false,
          data: cumul
        }
      ]
    };

    const options = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };

    return (
      <div>
        {statistics.length > 0 ?
          <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
            <div className="seven wide column" style={{ paddingBottom: '80px' }}>
              <div className="ui grid centered stackable">
                <div className="twelve wide column">
                  <table className="ui center aligned celled table">
                    <thead>
                      <tr>
                        <th>Année</th>
                        <th>Mois</th>
                        <th>{this.props.title}</th>
                        <th>Cumul</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="nine wide column">
              <div className="ui grid stackable">
                <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '45px' }}>
                  <Line data={dataSimple} options={options} />
                  <div className="ui hidden divider" />
                  <Line data={dataCumul} options={options} />
                </div>
              </div>
            </div>
          </div>
          : <div>
            <div className="ui container message">
              {this.props.chapterId ?
                <div>Le chapitre n'est pas publié.</div>
                : <div>L'œuvre n'est pas publiée.</div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}
