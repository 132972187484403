import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ChatDiscussion from '../chat/ChatDiscussion';
import ChatParams from '../chat/ChatParams';
import MessageSocketModel from '../../models/chat/MessageSocketModel';
import Store from '../../stores/Store';

interface ChatPopupsProps {
  store?: Store;
  pathname: string;
}

interface ChatPopupsState {
  discussionActives: number[];
}

@inject('store') @observer
export default class ChatPopups extends React.Component<ChatPopupsProps, ChatPopupsState> {
  constructor(props: ChatPopupsProps) {
    super(props);
    this.displayPopup = this.displayPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.state = { discussionActives: [] };
  }
  displayPopup(msg: MessageSocketModel) {
    const connected = this.props.store.connected;
    if (msg.discussionId === this.props.store.chat.discussion.id) {
      if (this.state.discussionActives.indexOf(msg.discussionId) === -1
        && connected && connected.user && connected.user.id !== msg.message.user.id
        && this.props.pathname !== `/messages/discussion/${msg.discussionId}`
      ) {
        this.props.store.loadDiscussion(msg.discussionId, true).then((disc: any) => {
          const newDiscussionActives = this.state.discussionActives;
          newDiscussionActives.push(msg.discussionId);
          this.setState({
            discussionActives: newDiscussionActives
          });
        });
      }
    }
  }
  // componentDidMount() {
  //   const connected = this.props.store.connected;
  //   if (this.props.store.socket) {
  //     this.props.store.socket.on('new message ack', this.displayPopup);
  //   }
  // }
  closePopup(discussionId: number) {
    this.setState({
      discussionActives: this.state.discussionActives.filter(d => d !== discussionId)
    });
  }
  render() {
    const allDiscussions = this.state.discussionActives.map((d: number) => {
      return (
        <div key={d} className="popup-box chat-popup">
          <ChatDiscussion
            params={new ChatParams(d)}
            popup
            closePopup={() => this.closePopup(d)}
          />
        </div>
      );
    });
    return (
      <div>
        {allDiscussions}
      </div>
    );
  }
}
