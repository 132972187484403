import { observable } from 'mobx';
import ParcoursStatusModel from './ParcoursStatusModel';

export default class AllParcoursModel {

  @observable allParcours: ParcoursStatusModel[];
  @observable pageAllParcours: number;
  @observable endAllParcours: boolean;
  @observable loading: boolean;

  constructor(allParcours: ParcoursStatusModel[], pageAllParcours: number, endAllParcours: boolean) {
    this.allParcours = allParcours || [];
    this.pageAllParcours = pageAllParcours;
    this.endAllParcours = endAllParcours;
    this.loading = false;
  }

  isEmpty() {
    return this.allParcours.length == 0;
  }

  addParcours(parcours: ParcoursStatusModel) {
    if (this.pageAllParcours !== 0){
      const parcoursWithoutId = this.allParcours.filter(p => p.textId !== parcours.textId);
      parcoursWithoutId.push(parcours);
      this.allParcours = parcoursWithoutId;
    }
    else
      this.allParcours.push(parcours);
  }

  setAllParcours(allParcours: ParcoursStatusModel[]) {
    if (this.pageAllParcours === 0) {
      this.allParcours = allParcours;
    } else {
      this.allParcours = this.allParcours.concat(allParcours);
    }
    this.pageAllParcours = this.pageAllParcours + 1;
    this.endAllParcours = allParcours.length === 0;
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
        allParcours: this.allParcours.map(p => p.toJS()),
        pageAllParcours: this.pageAllParcours,
        endAllParcours: this.endAllParcours
    };
  }

  static fromJS(object: any) {
    if (object) {
        const allParcours = object.allParcours.map((ap: any) => ParcoursStatusModel.fromJS(ap));
        return new AllParcoursModel(allParcours, object.pageAllParcours, object.endAllParcours);
    }
    return undefined;
  }
}
