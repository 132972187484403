import * as React from 'react';
import { Link } from 'react-router';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import ReadingListsLastText from './ReadingListsLastText';
const InfiniteScroll = require('react-infinite-scroll-component');

interface AllReadingListsLastTextsProps {
  store?: Store;
}

interface AllReadingListsLastTextsState {
  loadingAllReadingListsLastTextsError: boolean;
  loadingAllReadingListsLastTexts: boolean;
}

@inject('store') @observer
export default class AllReadingListsLastTexts extends React.Component<AllReadingListsLastTextsProps, AllReadingListsLastTextsState> {
  static fetchData(store: Store) {
    return store.loadAllListsTexts();
  }
  constructor(props: AllReadingListsLastTextsProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      loadingAllReadingListsLastTextsError: false,
      loadingAllReadingListsLastTexts: false
    };
  }
  loadMoreReadingListsLastTexts() {
    this.props.store.loadAllListsTexts((failed: boolean)=>{
      this.setState(
      {
        loadingAllReadingListsLastTextsError: failed,
        loadingAllReadingListsLastTexts: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingAllReadingListsLastTexts: true
    },
    () => {this.loadMoreReadingListsLastTexts()}    
    );
  }
  render() {
    const texts = this.props.store.home.allListsTexts.map(t =>
      <ReadingListsLastText key={Math.random() * 1000} text={t} withDate />
    );
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.home.endListsTexts){
        if (this.state.loadingAllReadingListsLastTexts){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingAllReadingListsLastTextsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid">{error}{message}</div>

    }
    return (
      <div className="ui grid" style={{ backgroundColor: '#fff' }}>
        <div className="ui text container" style={{ minHeight: '90vh', paddingTop: '50px', paddingBottom: '60px' }}>
          <div>
            <Title title="Toutes mes lectures " />
            <h1 className="ui large header">
              Toutes mes lectures
              <div className="sub header">
                Ici apparaissent toutes vos œuvres au sein de vos listes de lecture <Link to="/lists">(voir mes listes)</Link>.
              </div>
            </h1>
            {this.props.store.home.loading && this.props.store.home.pageListsTexts === 0 ?
              <Loader message="Chargement..." />
              :
              <InfiniteScroll
                next={this.loadMore}
                hasMore={false}
                loader={<h4>Chargement...</h4>}
                endMessage={endMessage()}
              >
                <div className="ui very relaxed items">
                  {texts}
                </div>
              </InfiniteScroll>
            }
          </div>
        </div >
      </div>
    );
  }
}
