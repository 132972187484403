import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { scroller } from 'react-scroll';
import { browserHistory, Link } from 'react-router';
import * as bowser from 'bowser';
const cookie = new (require('react-cookie')).Cookies();
import Store from '../../stores/Store';
import HomeFeed from './HomeFeed';
import MediaQuery from 'react-responsive';
import CurrentTexts from './CurrentTexts';
import CurrentDefis from './CurrentDefis';
import CurrentTrainings from './CurrentTrainings';
import CurrentParcours from './CurrentParcours';
import CurrentTalks from './CurrentTalks';
import IamPremium from './IamPremium';
import DiscoverPremium from './DiscoverPremium';
import ReactivatePremium from './ReactivatePremium';
import ReadingListsLastTexts from './ReadingListsLastTexts';
import WritingProgram from '../writingprogram/WritingProgram';
import WritingProgramInit from '../writingprogram/WritingProgramInit';
import WelcomeModal from './WelcomeModal';
import PremiumModal from './PremiumModal';
import EndWelcomeModal from './EndWelcomeModal';
import Loader from '../misc/Loader';
import homeSteps from '../tour/homeSteps';
import homeMobileSteps from '../tour/homeMobileSteps';
import Title from '../misc/Title';
import AuthorBioModal from './AuthorBioModal';
import AuthorsSlider from '../misc/AuthorsSlider';


interface HomeConnectedProps {
  store?: Store;
  welcome: boolean;
  newPremium: boolean;
}

interface HomeConnectedState {
  welcome: boolean;
  endWelcome: boolean;
  tour: boolean;
  newPremium: boolean;
  initWritingProgram: boolean;
  enableComponents: boolean;
  showHelpUX: boolean;
  author: number;
  authorModalActive: boolean;
}

require('react-joyride/lib/react-joyride-compiled.css');

declare const __CLIENT__: any;

@inject('store') @observer
export default class HomeConnected extends React.Component<HomeConnectedProps, HomeConnectedState> {
  refs: {
    joyride: any;
  };

  constructor(props: HomeConnectedProps) {
    super(props);
    this.startTuto = this.startTuto.bind(this);
    this.hideWelcome = this.hideWelcome.bind(this);
    this.tourCallback = this.tourCallback.bind(this);
    this.hideEndWelcome = this.hideEndWelcome.bind(this);
    this.hidePremiumAndStartProgramInit = this.hidePremiumAndStartProgramInit.bind(this);
    this.saveInitWritingProgram = this.saveInitWritingProgram.bind(this);
    this.hideHelpUX = this.hideHelpUX.bind(this);
    this.showAuthorModal = this.showAuthorModal.bind(this);
    this.hideAuthorModal = this.hideAuthorModal.bind(this);

    const newUser = this.props.store.connected && this.props.store.connected.newUser;
    this.state = {
      welcome: this.props.welcome && newUser,
      endWelcome: false,
      tour: false,
      newPremium: this.props.newPremium,
      initWritingProgram: false,
      enableComponents: false,
      showHelpUX: true,
      author: undefined,
      authorModalActive: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  hideAuthorModal() {
    this.setState({
      author: 0,
      authorModalActive: false
    });
  }
  showAuthorModal(author: number) {
    this.setState({
      author: author,
      authorModalActive: true
    });
  }
  startTuto() {
    scroller.scrollTo('feed_all', {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -100
    });
    this.refs.joyride.reset(true);
    this.setState({
      tour: true,
      welcome: false
    });
  }
  tourCallback(value: { type: string, isTourSkipped: boolean }) {
    if (value.type === 'finished' && !value.isTourSkipped) {
      this.setState({
        endWelcome: true
      });
    }
  }
  hideWelcome() {
    this.setState({
      welcome: false
    });
  }
  hideEndWelcome() {
    this.setState({
      endWelcome: false
    });
  }
  hidePremiumAndStartProgramInit() {
    this.setState({
      newPremium: false,
      initWritingProgram: true
    });
  }
  saveInitWritingProgram() {
    this.setState({
      initWritingProgram: false
    });
  }
  hideHelpUX() {
    cookie.set('help_ux', '1', { maxAge: 259200 });
    this.setState({
      showHelpUX: false
    });
  }
  school() {
    browserHistory.push({
      pathname: '/ecole'
    });
  }
  render() {
    const connected = this.props.store.connected;
    const Joyride = !bowser.msie && !bowser.safari ? require('react-joyride').default : undefined;
    const cookieShowHelpUX = cookie && cookie.get && cookie.get('help_ux');
    return (
      <div>
        <Title title="Tableau de bord" />
        <MediaQuery minWidth={767}>
          {Joyride ?
            <Joyride
              ref="joyride"
              steps={homeSteps}
              run={this.state.tour}
              autoStart={this.state.tour}
              type="continuous"
              scrollOffset={200}
              locale={{
                back: 'Précédent',
                close: 'Quitter',
                last: 'Fin',
                next: 'Suivant',
                skip: 'Quitter'
              }}
              showSkipButton={true}
              callback={this.tourCallback}
            />
          : undefined}
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          {Joyride ?
            <Joyride
              ref="joyride"
              steps={homeMobileSteps}
              run={this.state.tour}
              autoStart={this.state.tour}
              type="continuous"
              allowClicksThruHole={true}
              scrollOffset={200}
              locale={{
                back: 'Précédent',
                close: 'Quitter',
                last: 'Fin',
                next: 'Suivant',
                skip: 'Quitter'
              }}
              showSkipButton={true}
              callback={this.tourCallback}
            />
          : undefined}
        </MediaQuery>
        {this.props.store.isAuthenticated() && connected && connected.user && connected.user.id ?
          <div>
            {/* <div className="ui two column stackable mobile reversed grid"
              style={{ marginTop: '-30px' }}>
              <div className="nine wide column" style={{ paddingBottom: '80px' }}> */}
                <div className="ui grid container centered stackable" style={{ backgroundColor: '#fff' }}>
                  <div className="twelve wide column" style={{ paddingTop: '30px' }}>


                  <div>{/* 
                      {connected.premium.premium && !cookieShowHelpUX && this.state.showHelpUX && connected.premium.init ?
                        <div className="ui warning message">
                          <i className="fa fa-times close icon" onClick={this.hideHelpUX} title="Ne plus afficher" />
                          Vous êtes sur la page <b>Communauté</b>.
                          <br />
                          Pour rejoindre la page <b>Académie</b>, où vous pourrez suivre le Parcours Narratif et échanger avec les autres membres de l'Académie, cliquez sur le bouton <i className="fa fa-university" /> dans la barre du haut.
                        </div>
                        : undefined} */}
                      {/* The académie is being remade so it is unavailable for now */}
                      {/* {connected.premium.premium ?
                        <div>
                          <IamPremium />
                          <div className="ui hidden divider" />
                        </div>
                        : <div>
                          {connected.premium.premiumEnd ?
                            <ReactivatePremium />
                            : <DiscoverPremium />
                          }
                          <div className="ui hidden section divider" />
                        </div>} */}
                    </div>
                    {/* <CurrentDefis />
                    <CurrentTexts />
                    {connected.premium.premium ?
                      <CurrentTrainings />
                      : undefined
                    }
                    <CurrentTalks />
                    {this.state.enableComponents ?
                      <ReadingListsLastTexts />
                      : undefined} */}



                    <EndWelcomeModal
                      active={this.state.endWelcome}
                      hide={this.hideEndWelcome}
                    />
                    <WelcomeModal
                      active={this.state.welcome}
                      hide={this.hideWelcome}
                      startTuto={this.startTuto}
                    />
                    {/* <PremiumModal
                      active={this.state.newPremium}
                      hide={this.hidePremiumAndStartProgramInit}
                    />
                    <WritingProgramInit
                      active={this.state.initWritingProgram}
                      hide={this.saveInitWritingProgram}
                      firstInit
                    /> */}
                    {connected.premium.premium ?
                      /* <span>
                      <WritingProgram />
                      <CurrentParcours />
                      </span> */
                      undefined
                    : 
                      <div className="ui center aligned container ">
                        <h4 className="ui horizontal hide-tablet divider ada-divider">Vous souhaitez écrire votre premier roman ?</h4>
                        <h4 className="ui horizontal hide-tablet-large ada-divider">Vous souhaitez écrire votre premier roman ?</h4>
                        <div className="ada-image-subtitle" style={{marginTop: "-40px"}}>
                          <p>L’école de l’Atelier des Auteurs est faite pour vous ! </p>
                          <p style={{marginTop:"-5px"}}>Rejoignez l’un de nos ateliers d’écriture et écrivez votre œuvre de la première à la dernière ligne.</p>
                        </div>
                        <AuthorsSlider showAuthorModal= {this.showAuthorModal}/>
                        <AuthorBioModal active={this.state.authorModalActive} hide={this.hideAuthorModal} author={this.state.author}/>
                        <button
                          className="ui button ada-background editis-bleu-nuit" 
                          onClick={() => this.school()} 
                          style={{ cursor: 'pointer', color: "white", marginBottom: "20px", marginTop: "-15px"}}
                          id="decouvrez_notre_ecole_decriture_home_slider_connected"
                        >
                          <i className="fa fa-angle-right"></i> Découvrir notre école d'écriture
                        </button>
                      </div>
                    }
                    {this.state.enableComponents ?
                      <HomeFeed />
                      : undefined}
                  </div>
                </div>
              {/* </div>
              <div className="seven wide column grey">
                <div className="ui grid centered stackable">
                  <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '30px' }}>
                    
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          : <div className="ui one column grid stackable" style={{ marginTop: '-30px' }}>
            <div className="sixteen wide column">
              <Loader message="Chargement..." />
            </div>
          </div>
        }
      </div>
    );
  }
}
