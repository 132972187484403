import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../../stores/Store';
import RelectureType from '../../../../../models/write/RelectureType';

interface RelectureItemProps {
  selected: RelectureType;
  relecture: { name: RelectureType, warning: boolean };
  label: string;
  changeRelectureItem: Function | any;
}

class RelectureItem extends React.Component<RelectureItemProps, {}> {
  render() {
    let className: string;
    if (this.props.selected === this.props.relecture.name) {
      className = 'active teal item';
    } else {
      className = 'item';
    }
    return (
      <div className={className}
        style={{ cursor: 'pointer' }}
        onClick={() => this.props.changeRelectureItem(this.props.relecture.name)}
      >
        {this.props.label}
      </div>
    );
  }
}

interface RelectureMenuProps {
  store?: Store;
}

@inject('store') @observer
export default class RelectureMenu extends React.Component<RelectureMenuProps, {}> {
  constructor(props: RelectureMenuProps) {
    super(props);
    this.findRelecture = this.findRelecture.bind(this);
    this.changeRelectureItem = this.changeRelectureItem.bind(this);
  }
  findRelecture(relecture: RelectureType) {
    return this.props.store.write.currentText.currentChapter.relectureSteps
      .map(rs => { return { name: rs.r.name, warning: rs.warning }; })
      .filter(s => s.name === relecture)[0];
  }
  changeRelectureItem(relecture: RelectureType) {
    this.props.store.saveChapter(false, false).then((d: any) => {
      this.props.store.write.currentText.currentChapter.changeRelectureItem(relecture);
    });
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;

    const selected = chapter.relectureStep;

    // lisibilite
    const sentenceSize = this.findRelecture(RelectureType.SentenceSize);
    const paragraphSize = this.findRelecture(RelectureType.ParagraphSize);

    // lourdeurs
    const adverbs = this.findRelecture(RelectureType.Adverbs);
    const negative = this.findRelecture(RelectureType.Negative);
    const passive = this.findRelecture(RelectureType.Passive);
    const repetitions = this.findRelecture(RelectureType.Repetitions);
    const verbeTerne = this.findRelecture(RelectureType.VerbeTernes);

    // style
/*     const spellcheck = this.findRelecture(RelectureType.Spellcheck);
 */    const ponctuation = this.findRelecture(RelectureType.Punctuation);

    return (
      <div className="ui right fixed vertical menu"
        style={{ top: '100px', borderTop: '1px solid rgba(34, 36, 38, 0.15)', overflowY: 'scroll', maxHeight: '75%' }}
      >
        <div className="item">
          <h4 className="ui header">Lisibilité</h4>
          <div className="menu">
            {sentenceSize ?
              <RelectureItem
                relecture={sentenceSize}
                label="Longueur des phrases"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
            {paragraphSize ?
              <RelectureItem
                relecture={paragraphSize}
                label="Longueur des paragraphes"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
          </div>
        </div>
        <div className="item">
          <h4 className="ui header">Style</h4>
          <div className="menu">
            {adverbs ?
              <RelectureItem
                relecture={adverbs}
                label="Adverbes en -ment"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
            {negative ?
              <RelectureItem
                relecture={negative}
                label="Forme négative"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
            {passive ?
              <RelectureItem
                relecture={passive}
                label="Forme passive"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
            {repetitions ?
              <RelectureItem
                relecture={repetitions}
                label="Répétitions"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
            {verbeTerne ?
              <RelectureItem
                relecture={verbeTerne}
                label="Verbes ternes"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
          </div>
        </div>
        <div className="item">
          <h4 className="ui header">Correcteur</h4>
          <div className="menu">
            {/* {spellcheck ?
              <RelectureItem
                relecture={spellcheck}
                label="Orthographe & Grammaire"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined} */}
            {ponctuation ?
              <RelectureItem
                relecture={ponctuation}
                label="Ponctuation"
                selected={selected}
                changeRelectureItem={this.changeRelectureItem}
              />
              : undefined}
          </div>
        </div>
      </div>
    );
  }
}
