import * as React from 'react';
import Title from '../misc/Title';
import { Link, browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
const InfiniteScroll = require('react-infinite-scroll-component');
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface SearchPageProps {
  store?: Store;
  location: any;
}

interface SearchPageState {
  loadingSearchPageError: boolean;
  loadingSearchPage: boolean;
}

@inject('store') @observer
export default class SearchPage extends React.Component<SearchPageProps, SearchPageState> {
  static fetchData(store: Store, params: any, query: any) {
    return store.searchAll(query.text, query.cat);
  }
  constructor(props: SearchPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.state = {
      loadingSearchPageError: false,
      loadingSearchPage: false
    };
  }
  loadMoreSearchPage() {
    const text = this.props.location.query.text;
    const cat = this.props.location.query.cat;
    this.props.store.searchAll(text, cat, true, (failed: boolean)=>{
      this.setState(
      {
        loadingSearchPageError: failed,
        loadingSearchPage: false
      }
    )
  });
  }
  loadMore() {
    this.setState({
      loadingSearchPage: true
    },
    () => {this.loadMoreSearchPage()}    
    );
  }
  onTabChange(newTab: string) {
    const text = this.props.location.query.text;
    browserHistory.push({
      pathname: '/search',
      query: {
        text: text,
        cat: newTab
      }
    });
  }
  render() {
    const text = this.props.location.query.text;
    const cat = this.props.location.query.cat;
    const search = this.props.store.search;

    const users = search.pageValues.filter(v => v.user !== null).map(v => v.user);
    const texts = search.pageValues.filter(v => v.text !== null).map(v => v.text);
    const defis = search.pageValues.filter(v => v.defi !== null).map(v => v.defi);
    const talks = search.pageValues.filter(v => v.talk !== null).map(v => v.talk);

    let list: any[];
    switch (cat) {
      case 'text':
        list = texts.map(t => {
          return (
            <div key={'spage_' + t.id} className="item">
              <img className="ui image rounded" src={t.id > 3000 ? t.cover : 'https://s3-eu-west-1.amazonaws.com/scribay/assets/nocover.png'} style={{ width: '50px', height: '80px' }}
                alt={`Image de couverture de ${t.title}`} />
              <div className="middle aligned content">
                <Link
                  to={`/text/${t.id}/${t.urlText}`}
                  className="header"
                >
                  {t.title}
                </Link>
                <div className="description">{t.author.identity}</div>
              </div>
            </div>
          );
        });
        break;
      case 'defi':
        list = defis.map(defi => {
          return (
            <div key={'spage_' + defi.id} className="item">
              <div className="middle aligned content">
                <Link
                  to={`/defis/defi/${defi.id}/${defi.urlTitle}`}
                  className="header"
                >{defi.title}
                </Link>
              </div>
            </div>
          );
        });
        break;
      case 'talk':
        list = talks.map(talk => {
          return (
            <div key={'spage_' + talk.id} className="item">
              <div className="middle aligned content">
                <Link
                  to={`/talks/${talk.id}/${talk.urlTitle}`}
                  className="header"
                >
                  {talk.title}
                </Link>
              </div>
            </div>
          );
        });
        break;
      default:
        list = users.map(user => {
          return (
            <div key={'spage_' + user.id} className="item">
              <img className="ui image tiny rounded" src={user.avatarUrl} style={{ height: '80px' }}
                alt={`Image de profil de ${user.identity}`} />
              <div className="middle aligned content">
                <Link
                  to={`/author/${user.id}/${user.urlIdentity}`}
                  className="header"
                >
                  {user.identity}
                </Link>
              </div>
            </div>
          );
        });
        break;
    }

    const endMessage = () => {
      let message;
      let error;
      if (!search.end){
        if (this.state.loadingSearchPage){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingSearchPageError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid" style={{ marginBottom: '30px'}}>{error}{message}</div>

    }

    return (
      <div className="ui container">
        <Title title="Recherche " />
        <h2>Votre recherche : {text}</h2>
        <Menu
          secondary
          pointing
        >
          <Menu.Item name="user" active={cat === 'user'}
            onClick={() => this.onTabChange('user')}>
            Auteurs
          </Menu.Item>
          <Menu.Item name="text" active={cat === 'text'}
            onClick={() => this.onTabChange('text')}>
            Œuvres
          </Menu.Item>
          <Menu.Item name="defi" active={cat === 'defi'}
            onClick={() => this.onTabChange('defi')}>
            Défis
          </Menu.Item>
          <Menu.Item name="talk" active={cat === 'talk'}
            onClick={() => this.onTabChange('talk')}>
            Discussions
          </Menu.Item>
        </Menu>
        <div className="ui sixteen wide column grid row">
          <div className="ui basic segment">
            {search.searching && list && list.length === 0 ?
              <Loader message="Chargement..." />
              : <InfiniteScroll
                next={this.loadMore}
                hasMore={false}
                loader={<h4>Chargement...</h4>}
                endMessage={endMessage()}
              >
                <div className="ui items">
                  {list && list.length > 0 ?
                    list
                    : <div className="item">
                      Aucun résultat trouvé.
                      </div>
                  }
                </div>
              </InfiniteScroll>
            }
          </div>
        </div>
      </div>
    );
  }
}
