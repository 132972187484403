import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Store from '../../../../../stores/Store';
import ChapterVideo from './ChapterVideo';
import VideoModel from '../../../../../models/write/VideoModel';

interface ChapterInstructionsProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface ChapterInstructionsState {
  activeTab: number;
  activeVideo: boolean;
  selectedVideo: VideoModel;
}

@inject('store') @observer
export default class ChapterInstructions extends React.Component<ChapterInstructionsProps, ChapterInstructionsState> {
  constructor(props: ChapterInstructionsProps) {
    super(props);
    this.state = {
      activeTab: 3,
      activeVideo: false,
      selectedVideo: undefined
    };
    this.openVideo = this.openVideo.bind(this);
    this.closeVideo = this.closeVideo.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }
  onTabChange(activeTab: number) {
    this.setState({
      activeTab: activeTab,
      activeVideo: this.state.activeVideo,
      selectedVideo: this.state.selectedVideo
    });
  }
  openVideo(video: VideoModel) {
    this.setState({
      activeTab: this.state.activeTab,
      activeVideo: true,
      selectedVideo: video
    });
  }
  closeVideo() {
    this.setState({
      activeTab: this.state.activeTab,
      activeVideo: false,
      selectedVideo: undefined
    });
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    const instructions = chapter.instructions;
    /* const parcoursVideos = instructions.parcoursVideos.map(video => {
      return (
        <div key={`video_parcours_${video.id}`} className="item">
          <i className="fa fa-video-camera icon"></i>
          <div className="content">
            <a style={{ cursor: 'pointer' }} onClick={() => this.openVideo(video)}>{video.name}</a>
          </div>
        </div>
      );
    });
    const partVideos = instructions.partVideos.map(video => {
      return (
        <div key={`part_parcours_${video.id}`} className="item">
          <i className="fa fa-video-camera icon"></i>
          <div className="content">
            <a style={{ cursor: 'pointer' }} onClick={() => this.openVideo(video)}>{video.name}</a>
          </div>
        </div>
      );
    }); */
    return (
      <div>
        <ChapterVideo
          active={this.state.activeVideo}
          close={this.closeVideo}
          video={this.state.selectedVideo}
          step={false}
        />
        <Modal onClose={this.props.hide} open={this.props.active}>
          <div className="content">
            {/* <Menu
              activeIndex={this.state.activeTab}
              secondary
              pointing
              fluid
              widths={3}
              size="small"
              className="stackable"
            >
              <Menu.Item name="1" active={this.state.activeTab === 1} className="nopadding"
                onClick={() => this.onTabChange(1)}>
                Parcours
              </Menu.Item>
              <Menu.Item name="2" active={this.state.activeTab === 2} className="nopadding"
                onClick={() => this.onTabChange(2)}>
                Partie
              </Menu.Item>
              <Menu.Item name="3" active={this.state.activeTab === 3} className="nopadding"
                onClick={() => this.onTabChange(3)}>
                Chapitre
              </Menu.Item>
            </Menu>
            <div className="ui basic segment">
              {this.state.activeTab === 1 ?
                <div>
                  <div dangerouslySetInnerHTML={{ __html: instructions.parcoursInstructions }} />
                  {parcoursVideos && parcoursVideos.length > 0 ?
                    <div className="ui list">
                      {parcoursVideos}
                    </div>
                    : undefined}
                </div>
                : (this.state.activeTab === 2 ?
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: instructions.partInstructions }} />
                    {partVideos && partVideos.length > 0 ?
                      <div className="ui list">
                        {partVideos}
                      </div>
                      : undefined}
                  </div>
                  : <div dangerouslySetInnerHTML={{ __html: instructions.chapterInstructions }} />
                )
              }
            </div> */}
            <div dangerouslySetInnerHTML={{ __html: chapter.partInstruction }} />
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
        </Modal>
        
      </div >
    );
  }
}

