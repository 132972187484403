import * as React from 'react';

export default class H2Icon extends React.Component<{}, {}> {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <title>H2</title>
        <path d="M5 4v3h5.5v12h3V7H19V4z" fill="currentColor" />
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="m16.156 17.84 1.8916-2.0974c0.25133-0.26644 0.47621-0.53971 0.68786-0.80615s0.39023-0.53288 0.54235-0.79932c0.15212-0.26644 0.27117-0.53288 0.35716-0.79932 0.08598-0.26644 0.12567-0.53971 0.12567-0.80615 0-0.36209-0.05953-0.69684-0.17858-0.99744-0.11905-0.3006-0.29102-0.55338-0.51589-0.75833-0.22488-0.21179-0.50928-0.36892-0.83336-0.48506-0.33731-0.10931-0.71431-0.16396-1.1376-0.16396-0.45637 0-0.86643 0.07515-1.2236 0.21862s-0.6614 0.34842-0.8995 0.6012c-0.24472 0.25278-0.42991 0.54654-0.55558 0.88814-0.11905 0.3211-0.17858 0.66269-0.18519 1.0248h1.4154c0.0066-0.21179 0.03307-0.40991 0.08598-0.59437 0.05953-0.19812 0.15212-0.36892 0.26456-0.51239 0.11905-0.14347 0.27117-0.25278 0.44975-0.33476 0.17858-0.08198 0.39684-0.12297 0.63494-0.12297 0.20503 0 0.38361 0.03416 0.53573 0.10248 0.15212 0.06832 0.2844 0.1708 0.39022 0.29377 0.10582 0.12297 0.18519 0.27327 0.24472 0.44407 0.05291 0.1708 0.08598 0.35525 0.08598 0.55338 0 0.1503-0.01984 0.29377-0.05291 0.44407-0.03968 0.1503-0.09921 0.30743-0.19181 0.47823-0.0926 0.1708-0.21165 0.36209-0.37038 0.56704-0.15212 0.20495-0.34393 0.44407-0.58203 0.70368l-2.758 3.1085v1.0179h5.7079v-1.1682z" fill="currentColor" />
      </svg>
    );
  }
}
