import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as bowser from 'bowser';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Suggestions from './Suggestions';
const ReactGA = require('react-ga');
const cookie = new (require('react-cookie')).Cookies();

interface WelcomeModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface WelcomeModalState {
  step: number;
  notificationsEmail: boolean;
  communicationsEmail: boolean;
}

@inject('store') @observer
export default class WelcomeModal extends React.Component<WelcomeModalProps, WelcomeModalState> {
  constructor(props: WelcomeModalProps) {
    super(props);
    this.next = this.next.bind(this);
    this.checkCommunicationsEmail = this.checkCommunicationsEmail.bind(this);
    this.checkNotificationsEmail = this.checkNotificationsEmail.bind(this);
    this.state = {
      step: 1,
      notificationsEmail: false,
      communicationsEmail: false
    };
  }
  checkCommunicationsEmail() {
    this.setState({
      communicationsEmail: !this.state.communicationsEmail
    });
  }
  checkNotificationsEmail() {
    this.setState({
      notificationsEmail: !this.state.notificationsEmail
    });
  }
  next() {
    if (this.state.step === 1) {
      this.props.store.loadSuggestions().then((d: any) => {
        this.setState({
          step: 2
        });
      });
    } if (this.state.step === 2) {
      this.props.store.loadSettingsEmails().then((d: any) => {
        this.props.store.mainSettings.emails.communicationsEmail = this.state.communicationsEmail;
        this.props.store.mainSettings.emails.newsletterEmail = this.state.communicationsEmail;
        this.props.store.mainSettings.emails.tipsEmail = this.state.communicationsEmail;
        this.props.store.saveSettingsEmails(true).then((d2: any) => {
          this.props.store.loadSettingsNotifications().then((d3: any) => {
            this.props.store.mainSettings.notifications.email = this.state.notificationsEmail;
            this.props.store.saveSettingsNotifications(true).then((d4: any) => {
              this.props.hide()
            });
          });
        });
      });
    } else {
      this.setState({
        step: this.state.step + 1
      });
    }
  }
  render() {
    const newUser = this.props.store.connected && this.props.store.connected.newUser;
    if (this.props.active && this.state.step === 1 && newUser) {
      const cookieAudience = cookie && cookie.get && cookie.get('user-has-accepted-cookies-audience');
      if (cookieAudience) {
        ReactGA.modalview('/signup/ok');
      }
    }
    return (
      <Modal
        open={this.props.active}
        size="small"
      >
        <div className="header">
          {this.state.step === 1 ?
            <span>Bienvenue au sein de l’Atelier des Auteurs !</span>
          : 
            <span>Emails</span>
          }
        </div>
        <div className="content">
          {this.state.step === 1 ?
            <div>
              <p>
                Félicitations ! Vous pouvez désormais vous lancer dans un nouveau cours d'écriture à partir de la page d'accueil de l'école.
              </p>
              <p>
                Vous avez la moindre question ? Vous recevrez prochainement un message privé de l’un des administrateurs de la plateforme afin de vous accueillir. Vous pourrez retrouver ce message en cliquant sur la petite enveloppe en haut à droite de votre écran.
              </p>
              <p>
                Nous vous souhaitons d’excellentes séances d'écriture !
              </p>
            </div>
          : 
            <div>
              <div className="ui info message">
                Nous envoyons régulièrement des emails pour donner des nouvelles de la communauté ou informer les auteurs de leurs notifications reçues.
              </div>
              <div>
              <div className="ui form">
                  <div className="field">
                  <label>Je souhaite recevoir des emails :</label>
                  </div>
                  <div className="field">
                  <Checkbox
                      checked={this.state.communicationsEmail}
                      onClick={() => this.checkCommunicationsEmail()}
                      label="De conseils d'écriture et sur l'actualité de l'Atelier des auteurs"
                  />
                  </div>
                  <div className="field">
                  <Checkbox
                      checked={this.state.notificationsEmail}
                      onClick={() => this.checkNotificationsEmail()}
                      label="De notifications. Par exemple : lorsqu'un lecteur vous commente. Les notifications sont regroupées afin de ne pas submerger votre boite mail."
                  />
                  </div>
              </div>
              </div>
            </div>
          }
        </div>
        <div className="actions">
          {this.state.step === 1 ?
            <button className="ui primary button" onClick={this.next}>Passer à la suite</button>
        :
            <button className="ui primary button" onClick={this.next}>Valider mes préférences</button>
         }
        </div>
      </Modal>
    );
  }
}
