import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class MessageModel {

  @observable id: number;
  @observable discussionId: number;
  @observable user: UserModel;
  @observable message: string;
  @observable date: string;

  constructor(id: number, discussionId: number, user: UserModel, message: string, date: string) {
    this.id = id;
    this.discussionId = discussionId;
    this.user = user;
    this.message = message;
    this.date = date;
  }

  toJS() {
    return {
      id: this.id,
      discussionId: this.discussionId,
      user: this.user.toJS(),
      message: this.message,
      date: this.date
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new MessageModel(object.id, object.discussionid,
        UserModel.fromJS(object.user), object.message, object.date);
    }
    return undefined;
  }
}
