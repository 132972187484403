import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../../../stores/Store';

interface ConfirmPublishProps {
  store?: Store;
  confirmPublishModal: boolean;
  toggleConfirmPublish: any;
  confirmPublish: any;
}

@inject('store') @observer
export default class ConfirmPublish extends React.Component<ConfirmPublishProps, {}> {
  constructor(props: ConfirmPublishProps) {
    super(props);
    this.toggleConfirmPublish = this.toggleConfirmPublish.bind(this);
  }
  toggleConfirmPublish() {
    this.props.toggleConfirmPublish();
  }
  render() {
    const text = this.props.store.write.currentText;
    return (
      <Modal
        open={this.props.confirmPublishModal}
        onClose={() => { } }
        >
        <div className="header">Confirmation</div>
        <div className="content">
          {!text.infos.published ?
            <div>
              L'œuvre <b>{text.infos.title}</b> n'est pas publiée.
              <br />La publication de ce chapitre entraînera <b>automatiquement</b> la publication de l'œuvre.
              <div className="ui warning message">
                Nous vous conseillons de&nbsp;
              <Link to={`/text/${text.id}/${text.infos.urlText}`}>
                  vérifier les caractéristiques de l'œuvre (titre, apparence, résumé,...)
              </Link>
                &nbsp;avant la publication !
              </div>
            </div>
            : <div>
              Confirmez-vous la publication de ce chapitre ?
            </div>}
        </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.props.confirmPublish}
            >
            {text.infos.published ? 'Oui' : `Publier l'œuvre et le chapitre`}
          </button>
          {!text.infos.published ?
            <Link to={`/text/${text.id}/${text.infos.urlText}`} className="ui button">
              Vérifier les caractéristiques de l'œuvre
           </Link>
            : undefined}
          <button className="ui button" onClick={this.toggleConfirmPublish}>
            {text.infos.published ? 'Non' : 'Annuler'}
          </button>
        </div>
      </Modal>
    );
  }
}
