import { observable } from 'mobx';
import WritingProgramModel from './WritingProgramModel';

export default class MainWritingProgramModel {

  @observable wp: WritingProgramModel;

  @observable newWp: {
    day?: number;
    words?: number;
    defis?: number;
    texts?: number;
    trainings?: number;
    chapters?: number;
    linkedTextId?: number;
  }[];

  @observable allTexts: { id: number, title: string, urlText: string }[];


  constructor(wp: WritingProgramModel, newWp: {
    day?: number;
    words?: number;
    defis?: number;
    texts?: number;
    trainings?: number;
    chapters?: number;
    linkedTextId?: number
  }[], allTexts: { id: number, title: string, urlText: string }[]) {
    this.wp = wp;
    this.newWp = newWp || [];
    this.allTexts = allTexts || [];
  }

  toJS() {
    return {
      wp: this.wp.toJS(),
      newWp: this.newWp,
      allTexts: this.allTexts
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new MainWritingProgramModel(WritingProgramModel.fromJS(object.wp),
        object.newWp, object.allTexts);
    }
    return undefined;
  }

}