import { observable } from 'mobx';
import CanvasStructureModel from './CanvasStructureModel';
import CanvasCharacterModel from './CanvasCharacterModel';
import CanvasNoteModel from './CanvasNoteModel';
import FullCanvasCharacterModel from './FullCanvasCharacterModel';

export default class FullCanvasModel {

  @observable id: number;
  @observable title: string;
  @observable description: string;
  @observable image: string;
  @observable notes: CanvasNoteModel[];
  @observable createdAt: string;
  @observable updatedAt: string;

  @observable structure: CanvasStructureModel;
  @observable characters: CanvasCharacterModel[];
  @observable currentCharacter: FullCanvasCharacterModel;

  constructor(id: number, title: string, description: string, image: string, notes: CanvasNoteModel[], 
    createdAt: string, updatedAt: string, structure: CanvasStructureModel,
    characters: CanvasCharacterModel[], currentCharacter: FullCanvasCharacterModel) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.image = image;
    this.notes = notes || [];
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.structure = structure;
    this.characters = characters || [];
    this.currentCharacter = currentCharacter;
  }

  setStructure(structure: CanvasStructureModel) {
    this.structure = structure;
  }

  setCharacters(characters: CanvasCharacterModel[]) {
    this.characters = characters.map(c => new CanvasCharacterModel(c.id, c.name, c.image));
  }

  setCurrentCharacter(currentCharacter: FullCanvasCharacterModel) {
    this.currentCharacter = new FullCanvasCharacterModel(currentCharacter.id, currentCharacter.name,
      currentCharacter.image, currentCharacter.description);
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      notes: this.notes.map(n => n.toJS()),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      structure: this.structure.toJS(),
      characters: this.characters.map(n => n.toJS()),
      currentCharacter: this.currentCharacter.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const structure = CanvasStructureModel.fromJS(object.structure);
      const characters = object.characters.map((n: any) => CanvasCharacterModel.fromJS(n));
      const notes = object.notes.map((n: any) => CanvasNoteModel.fromJS(n));
      const currentCharacter = FullCanvasCharacterModel.fromJS(object.currentCharacter);
      return new FullCanvasModel(object.id, object.title, object.description, object.image, notes,
        object.createdAt, object.updatedAt, structure, characters, currentCharacter);
    }
    return undefined;
  }
}
