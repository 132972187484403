import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import * as superagent from 'superagent';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Length from './Length';
import Pos from './Pos';
import Sentences from './Sentences';
import DialogueRatio from './DialogueRatio';
import Lexical from './Lexical';
import Duration from './Duration';
import WordCloud from './WordCloud';
import Loader from '../../../misc/Loader';
import Title from '../../../misc/Title';

interface TextStatisticsWriteProps {
  store?: Store;
  params: TextParams;
  location: {
    query: {
      chapter: number,
      stat: string
    }
  };
}

interface TextStatisticsWriteState {
  computing: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class TextStatisticsWrite extends React.Component<TextStatisticsWriteProps, TextStatisticsWriteState> {
  timerCompute: any;
  timerReload: any;

  static fetchData(store: Store, params: TextParams, query: { chapter: number, stat: string }) {
    if (store.isAuthenticated()) 
      store.loadConnected()
    const text = store.write.currentText;
    const stat = query && query.stat ? query.stat : 'duration';
    const promise1 = text && text.id && text.id == params.textId ?
      Promise.resolve()
      : store.loadTextInfos(params.textId);

    return promise1.then((d: any) => {
      if (stat === 'topwords') {
        const p: any[] = [];
        p.push(store.loadTextStatisticsTopWords(query.chapter));
        p.push(store.loadTextStatisticsWrite('duration'));
        return Promise.all(p);
      } else {
        return store.loadTextStatisticsWrite(stat);
      }
    });
  }
  constructor(props: TextStatisticsWriteProps) {
    super(props);
    this.pollCompute = this.pollCompute.bind(this);
    this.pollReload = this.pollReload.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.computeStatistics = this.computeStatistics.bind(this);
    this.reloadStatistics = this.reloadStatistics.bind(this);
    this.forceReloadStatistics = this.forceReloadStatistics.bind(this);
    this.state = {
      computing: false
    };
  }
  componentDidMount() {
    this.startPolling();
  }
  componentWillUnmount() {
    if (this.timerCompute || this.timerReload) {
      clearInterval(this.timerCompute);
      clearInterval(this.timerReload);
      this.timerCompute = null;
      this.timerReload = null;
    }
  }
  pollCompute() {
    this.computeStatistics();
  }
  pollReload() {
    if (__CLIENT__) {
      this.reloadStatistics();
    }
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that.timerCompute = setInterval(that.pollCompute.bind(self), 10000);
      that.timerReload = setInterval(that.pollReload.bind(self), 5000);
    }, 1000);
  }
  computeStatistics() {
    if (__CLIENT__) {
      const currentText = this.props.store.write.currentText;
      if (currentText && currentText.statisticsWrite && currentText.statisticsWrite.length > 0) {
        const stats = currentText.statisticsWrite;
        const chaptersNotComputed = stats.filter(stat => !stat.stat && stat.exists).map(stat => stat.chapter.id);
        if (chaptersNotComputed.length > 0) {
          superagent
            .post(`/wtext/${currentText.id}/all`)
            .send(chaptersNotComputed)
            .end((err3, res3) => {
            });
        }
      }
    }
  }
  reloadStatistics() {
    if (__CLIENT__) {
      const currentText = this.props.store.write.currentText;
      if (currentText && currentText.statisticsWrite && currentText.statisticsWrite.length > 0) {
        const stats = currentText.statisticsWrite;
        const chaptersNotComputed = stats.filter(stat => (!stat.stat || stat.stat.statistic == null) && stat.exists).map(stat => stat.chapter.id);
        if (chaptersNotComputed.length > 0) {
          const selectedStat = this.props.location && this.props.location.query && this.props.location.query.stat ?
            this.props.location.query.stat
            : 'duration';
          this.props.store.loadTextStatisticsWrite(selectedStat);
        }
      }
    }
  }
  forceReloadStatistics() {
    this.setState({
      computing: true
    });
    const selectedStat = this.props.location && this.props.location.query && this.props.location.query.stat ?
      this.props.location.query.stat
      : 'duration';

    const p: any[] = [];
    if (selectedStat === 'topwords') {
      p.push(this.props.store.loadTextStatisticsTopWords(this.props.location.query.chapter));
      p.push(this.props.store.loadTextStatisticsWrite('duration'));
    } else {
      p.push(this.props.store.loadTextStatisticsWrite(selectedStat));
    }
    Promise.all(p).then((d: any) => {
      this.setState({
        computing: false
      });
    });
  }
  render() {
    const selectedStat = this.props.location && this.props.location.query && this.props.location.query.stat ?
      this.props.location.query.stat
      : 'duration';

    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsWrite;
    const chapterId = this.props.location && this.props.location.query && this.props.location.query.chapter ?
      this.props.location.query.chapter : undefined;
    return (
      <div>
        {this.props.store.write.loading || !currentText || !statistics ?
          <Loader message="Chargement..." />
          :
          <div>
            {currentText && currentText.infos ?
              <Title title={`${currentText.infos.title} - Statistiques`} />
              : undefined}
            <div className="ui two column stackable mobile reversed grid">
              <div className="seven wide column">
                <div className="ui grid centered stackable">
                  <div className="twelve wide column">
                    <div className="ui large form">
                      {currentText && currentText.infos ?
                        <div className="inline field">
                          <label>Indicateur</label>
                          <Dropdown
                            selection
                            value={selectedStat}
                            onChange={(e: any, { value }) => {
                              const query = this.props.location && this.props.location.query && this.props.location.query.chapter ?
                                { chapter: this.props.location.query.chapter, stat: value }
                                : { stat: value };
                              browserHistory.push({
                                pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/write`, query: query
                              });
                            }}
                            options={[
                              { text: 'Durée', value: 'duration' },
                              { text: 'Longueur', value: 'length' },
                              { text: 'Catégorie de mots', value: 'pos' },
                              { text: 'Type de phrases', value: 'sentences' },
                              { text: 'Dialogues', value: 'dialogueRatio' },
                              { text: 'Richesse lexicale', value: 'lexical' },
                              { text: 'Nuage de mots', value: 'topwords' }
                            ]}
                          />
                        </div>
                        : undefined}
                    </div>
                  </div>
                </div>
              </div>
              <div className="eight wide column">
                <button className="ui basic right floated button" onClick={this.forceReloadStatistics}>
                  Actualiser les données
                </button>
              </div>
            </div>
            {currentText && currentText.infos ?
              <div style={{ paddingBottom: '80px' }}>
                <div className="ui section hidden divider" />
                {this.state.computing ?
                  <Loader message="Veuillez patienter..." />
                  :
                  <div>
                    {selectedStat === 'duration' ?
                      <Duration chapterId={chapterId} />
                      : selectedStat === 'length' ?
                        <Length chapterId={chapterId} />
                        : selectedStat === 'pos' ?
                          <Pos chapterId={chapterId} />
                          : selectedStat === 'sentences' ?
                            <Sentences chapterId={chapterId} />
                            : selectedStat === 'dialogueRatio' ?
                              <DialogueRatio chapterId={chapterId} />
                              : selectedStat === 'lexical' ?
                                <Lexical chapterId={chapterId} />
                                : selectedStat === 'topwords' ?
                                  <WordCloud chapterId={chapterId} />
                                  : undefined}
                  </div>
                }
              </div>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
