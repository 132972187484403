import { observable } from 'mobx';
import UserModel from '../UserModel';

class ReadingListTextChapterModel {

  @observable id: number;
  @observable title: string;
  @observable step: number;
  @observable lastDate: string;
  @observable isNew: boolean;
  @observable read: boolean;

  constructor(id: number, title: string, step: number, lastDate: string, isNew: boolean, read: boolean) {
    this.id = id;
    this.title = title;
    this.step = step;
    this.lastDate = lastDate;
    this.isNew = isNew;
    this.read = read;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      step: this.step,
      lastDate: this.lastDate,
      isNew: this.lastDate,
      read: this.read
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ReadingListTextChapterModel(
        object.id, object.title, object.step, object.lastDate, object.isNew, object.read
      );
    }
    return undefined;
  }
}

export default class ReadingListTextModel {

  @observable id: number;
  @observable title: string;
  @observable urlTitle: string;
  @observable cover: string;
  @observable user: UserModel;
  @observable chapters: ReadingListTextChapterModel[];

  constructor(id: number, title: string, urlTitle: string, cover: string,
    user: UserModel, chapters: ReadingListTextChapterModel[]) {
    this.id = id;
    this.title = title;
    this.urlTitle = urlTitle;
    this.cover = cover;
    this.user = user;
    this.chapters = chapters || [];
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlTitle: this.urlTitle,
      cover: this.cover,
      user: this.user.toJS(),
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const chapters = object.chapters.map((c: any) => ReadingListTextChapterModel.fromJS(c));
      return new ReadingListTextModel(
        object.id, object.title, object.urlTitle, object.cover,
        UserModel.fromJS(object.user), chapters
      );
    }
    return undefined;
  }
}
