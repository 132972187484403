import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const ReactPlayer = require('react-player');
import Store from '../../../stores/Store';
import ParcoursModel from '../../../models/parcours/ParcoursModel';
import TextModel from '../../../models/write/TextModel';

interface NewParcoursModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  passFirstSlide?: boolean;
  parcoursId?: number;
}

interface NewParcoursModalState {
  enteringTitle: boolean;
  title: string;
}

@inject('store') @observer
export default class NewParcoursModal
  extends React.Component<NewParcoursModalProps, NewParcoursModalState> {
  constructor(props: NewParcoursModalProps) {
    super(props);
    this.next = this.next.bind(this);
    this.startParcours = this.startParcours.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {
        enteringTitle: false,
        title: ""
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  startParcours(parcours: ParcoursModel) {
    const title = this.state.title === "" ? parcours.name : this.state.title;
    this.props.store.startParcours(this.props.parcoursId, title).then((text: TextModel) => {
      browserHistory.push({
        pathname: `/parcours/${text.id}/${text.urlText}`
      });
    });
  }
  next() {
    this.setState ({
        enteringTitle: true
    });
  }
  hide() {
    this.setState({
        enteringTitle: false,
        title: ""
    });
    this.props.hide();
  }
  render() {
    const parcours = this.props.store.allParcours[this.props.store.allParcours.map((e) => { return e.id; }).indexOf(this.props.parcoursId)];
    const next = parcours && !this.state.enteringTitle && parcours.projectName;

    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Parcours - {parcours ? '"' + parcours.name + '"' : undefined}</div>
          <div className="content" style={{ paddingBottom: '50px' }}>
            {parcours ?
                this.state.enteringTitle ?
                    <div>
                        <label>Choisissez un titre pour votre roman </label>
                        <div className="ui input">
                          <input 
                              type="text"
                              name="title"
                              placeholder="Titre"
                              onChange={this.handleTitleChange}
                              value={this.state.title}
                          />
                        </div>
                        <p>(Vous pourrez le modifier plus tard !)</p>
                    </div> 
                :
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: parcours.description }} />
                    </div>
              : undefined
            }
          </div>
          <div className="actions">
            {next ?
                <button className="ui primary button"
                    onClick={this.next}>
                    Suivant
                </button>
              : 
                this.state.title !== "" || !parcours.projectName ?
                    <button className="ui primary button" onClick={() => this.startParcours(parcours)}>
                        Commencer le parcours
                    </button>
                :
                <button className="ui primary button" disabled={true}>
                    Entrez un titre avant de continuer
                </button>
            }
            <button className="ui cancel button" onClick={this.hide}>Annuler</button>
          </div>
        </Modal>
      </div>
    );
  }
}
