let env: any;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  env = { isProduction: false };
} else {
  env = { isProduction: true };
}

const host = process.env.HOST || '127.0.0.1';
const port = process.env.PORT || '3000';
const apiHost = process.env.APIHOST || '127.0.0.1';
const apiPort = process.env.APIPORT || '9000';
const allInclusiveUrl = process.env.ALL_INCLUSIVE_URL || 'price_1Kb6zVFGIePPBRt5jiI413i4'
const stripeSecret = process.env.STRIPE_SECRET || 'sk_test_hPvKisAZQX3UU2wuFuWsPVIN'

class Config {

  host: string = host;
  port: string = port;
  apiHost: string = apiHost;
  apiPort: string = apiPort;
  allInclusiveUrl: string = allInclusiveUrl;
  stripeSecret: string = stripeSecret;
  app: any = {
    title: 'Atelier des Auteurs',
    description: 'Tout le monde a une histoire à raconter',
    head: {
      titleTemplate: 'Atelier des Auteurs - %s',
      meta: [
        { name: 'description', content: 'Tout le monde a une histoire à raconter.' },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'Atelier des Auteurs' },
        { property: 'og:image', content: '' },
        { property: 'og:locale', content: 'fr_FR' },
        { property: 'og:title', content: 'Atelier des Auteurs' },
        { property: 'og:description', content: 'Tout le monde a une histoire à raconter.' },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: '@atelierdesauteurs' },
        { property: 'og:creator', content: '@atelierdesauteurs' },
        { property: 'og:image:width', content: '200' },
        { property: 'og:image:height', content: '200' }
      ]
    }
  };
}

const config = new Config();
export default config;
