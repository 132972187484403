import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import VolatileLogin from '../auth/VolatileLogin';
import Title from '../misc/Title';
const LazyLoad = require('react-lazy-load').default;
const Typist = require('react-typist').default;
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'
import AuthorBioModal from '../home/AuthorBioModal';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import EditorsSlider from '../misc/EditorsSlider';
import AuthorsSlider from '../misc/AuthorsSlider';




interface PartnersAndAuthorsProps {
  store?: Store;
}

interface PartnersAndAuthorsState {
  typing: boolean;
  author: number;
  authorModalActive: boolean;
}




@inject('store') @observer
export default class PartnersAndAuthors extends React.Component<PartnersAndAuthorsProps, PartnersAndAuthorsState> {
  constructor(props: PartnersAndAuthorsProps) {
    super(props);
/*     this.restartTyping = this.restartTyping.bind(this);
 */    this.state = {
      typing: true,
      author: 0,
      authorModalActive: false
    };
    this.hideAuthorModal = this.hideAuthorModal.bind(this);
    this.showAuthorModal = this.showAuthorModal.bind(this);
  }
  hideAuthorModal() {
    this.setState({
      author: 0,
      authorModalActive: false
    });
  }
  showAuthorModal(author: number) {
    this.setState({
      author: author,
      authorModalActive: true
    });
  }
  
 
  render() {
    
    const metaProperties = [
      {
        name: 'description',
        content: 'L\'Atelier des auteurs est la plateforme d’écriture pensée pour vous. Trouvez l’inspiration, écrivez et progressez au sein d’une communauté bienveillante.'
      }
    ];
    return (
      <div style={{backgroundColor: '#fff'}}>
        <Title title="Nos partenaires" meta={metaProperties} scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://www.atelierdesauteurs.com',
            logo: {
              '@type': 'ImageObject',
              url: 'https://scribay.s3.amazonaws.com/assets/logo_square.png'
            }
          })
        }]} />
        <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
          <div className="ui centered grid">
            <div className="row ada-image-subtitle" style={{marginTop: "15px"}}>
              <h1>Nos partenaires</h1>

              <p>
                L'Atelier des Auteurs fait partie d'un groupe composé de plus d'une cinquantaine d'éditeurs. 
                Son école d'écriture et ses ateliers ont été façonnés en partenariat avec des professionnels de l'édition et des écrivains.
              </p>
            </div>
          </div>
        </div>
        <div className="ada-background editis-beige-clair" style={{paddingBottom: "50px"}}>
          <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Nos auteurs partenaires</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Nos auteurs partenaires</h4>
            {<AuthorsSlider showAuthorModal= {this.showAuthorModal}/>}
            {<AuthorBioModal active={this.state.authorModalActive} hide={this.hideAuthorModal} author={this.state.author}/>}
          </div>
        </div>
        <div className="ui center aligned container"  style={{paddingBottom: "50px"}}>
            <h4 className="ui horizontal ada-divider  hide-tablet divider ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            <h4 className="ui horizontal ada-divider hide-tablet-large ada-museo-300">Plus de 50 maisons d'édition partenaires de l'Atelier des Auteurs</h4>
            <div className="ui centered grid">
              <div className="row ada-image-subtitle" style={{marginTop: "-15px"}}><p>Vous êtes membre de notre école ? Si vous le souhaitez, vous pouvez rendre accessibles les textes de votre choix à nos éditeurs partenaires.</p></div>
            </div>
            {<EditorsSlider/>}
        </div>        
      </div>
    );
  }
}
