import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import RelatedTalkModel from '../../models/talk/RelatedTalkModel';
import TalkPageForm from './TalkPageForm';

interface NewTalkPageProps {
  store?: Store;
}

@inject('store') @observer
export default class NewTalkPage extends React.Component<NewTalkPageProps, {}> {
  constructor(props: NewTalkPageProps) {
    super(props);
    this.createNewDiscussion = this.createNewDiscussion.bind(this);
  }
  createNewDiscussion(title: string, message: string, tags: string[], linked: string[]) {
    this.props.store.createNewDiscussion(
      title, message, tags, linked, undefined, undefined
    ).then((t: RelatedTalkModel) => {
      browserHistory.push({
        pathname: `/talks/${t.id}/${t.urlTitle}`
      });
    });
  }
  render() {
    return (
      <div className="ui grid container centered stackable" style={{ backgroundColor: '#fff', paddingTop: '20px', paddingBottom: '90px' }}>
        <div className="ten wide column">
          <Title title="Nouvelle discussion " />
          <h2 className="ui header">Nouvelle discussion</h2>
          <TalkPageForm submit={this.createNewDiscussion} />
        </div>
      </div>
    );
  }
}
