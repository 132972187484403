import * as React from 'react';

import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import ListCarousel from '../readinglist/ListCarousel';
import Loader from '../misc/Loader';
import ProfileParams from './ProfileParams';

interface ProfileListsProps {
  store: Store;
  params: ProfileParams;
}

@inject('store') @observer
export default class ProfileLists extends React.Component<ProfileListsProps, {}> {
  static fetchData(store: Store, params: ProfileParams) {
    return store.loadProfileLists(params.authorId);
  }
  render() {
    const profile = this.props.store.profile;
    return (
      <div className="row" style={{ paddingTop: '50px' }}>
        <div className="ui container">
          {profile.loading ?
            <Loader message="Chargement..." />
            : <ListCarousel lists={profile.lists} />
          }
        </div>
      </div>
    );
  }
}