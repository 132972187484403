import UserModel from '../UserModel';

export default class ProfileRelationsModel {

  user: UserModel;
  relations: UserModel[];

  constructor(user: UserModel, relations: UserModel[]) {
    this.user = user;
    this.relations = relations;
  }

  toJS() {
    return {
      user: this.user.toJS(),
      relations: this.relations.map(r => r.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const relations = object.relations.map((u: any) => UserModel.fromJS(u));
      return new ProfileRelationsModel(
        UserModel.fromJS(object.user), relations
      );
    }
    return undefined;
  }
}
