import { observable } from 'mobx';
import AlertModel from './AlertModel';

export default class MainAlertsModel {

  @observable alerts: AlertModel[];

  constructor(alerts: AlertModel[]) {
    this.alerts = alerts;
  }

  addAlert(alert: AlertModel) {
    const alertsWithoutId = this.alerts.filter(a => a.id !== alert.id);
    alertsWithoutId.push(alert);
    this.alerts = alertsWithoutId;
  }

  addAlerts(alerts: AlertModel[]) {
    const alertsWithoutId = this.alerts.filter(a => alerts.map((n: AlertModel) => n.id).indexOf(a.id) === -1);
    alertsWithoutId.push(...alerts);
    this.alerts = alertsWithoutId;
  }

  removeAlert(alertId: string) {
    this.alerts = this.alerts.filter(a => a.id !== alertId);
  }

  toJS() {
    return {
      alerts: this.alerts
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new MainAlertsModel(object.alerts);
    }
    return undefined;
  }
}
