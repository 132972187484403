import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'


function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
    className="fa fa-angle-left hide-tablet ada-carousel-left"
      style={{ ...style, marginLeft: "-15px" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="fa fa-angle-right hide-tablet ada-carousel-right"
      style={{ ...style, marginRight: "-15px" }}
      onClick={onClick}
    />
  );
}

export default class EditorsSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false
          }
        }
      ]
    };
    var settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: false
          }
        }
      ]
    };
    return (
      <div>
        <div className="ui centered grid">
              <div className="row ada-image-subtitle" style={{marginTop: "-25px", marginBottom: "25px"}}>
                <p>
                  Nos ateliers d'écriture ont été conçus en partenariat avec le monde de l'édition.
                </p>
                <p style={{marginTop: "-10px"}}>
                  Vous êtes membre de notre école ? Vous pouvez rendre accessibles les textes de votre choix à nos éditeurs partenaires 
                </p>
              </div>
            </div>
      <div className="ada-logo" style={{width: "96%"}}>
        <span className="hide-tablet">
            <Slider {...settings}>
        <div>
          <img src="https://scribay.s3.amazonaws.com/assets/1018.png"
                  alt="plume"  width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/1221.jpg"
                  alt="1221" width="100%" />
          </div>
          <div style={{verticalAlign: "center"}}>
          <img src="https://scribay.s3.amazonaws.com/assets/404Editions.png"
                  alt="404editions" width="100%"  />
          </div>
          < div  className="ada-logo">
          <img src="https://scribay.s3.amazonaws.com/assets/Agrume.jpg"
            alt="Agrume" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Archipel.jpg"
                  alt="Archipel" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Belfond.png"
                        alt="Belfond" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Bordas.jpg"
                        alt="Bordas" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Bruitmonde.png"
                  alt="Bruitdumonde" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/CLEinternational.jpg"
                  alt="CLEInternational" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/ChercheMidi.png"
                  alt="ChercheMidi" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Daesign.jpg"
                  alt="Daesign" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Decouverte.jpg"
                  alt="Decouverte" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Dragon.jpg"
                  alt="Dragon" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/EHO.jpg"
                  alt="EHO" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Empreinte.jpg"
                  alt="Empreinte" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Escales.jpg"
                  alt="Escales" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/First.jpg"
                  alt="First" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Fleuve.jpg"
                  alt="Fleuve" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Grund.jpg"
                  alt="Grund" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Hemma.jpg"
                  alt="Hemma" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/HorsCollection.jpg"
                  alt="HorsCollection" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Julliard.jpg"
                  alt="Juillard" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Kurokawa.jpg"
                  alt="Kurokawa" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Languechat.jpg"
                  alt="Languechat" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/LeRobert.jpg"
                  alt="LeRobert" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Lizzie.jpg"
                  alt="Lizzie" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/LonelyPlanet.jpg"
                  alt="LonelyPlanet" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/MDI.jpg"
                  alt="MDI" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nathan.jpg"
                  alt="Nathan" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nil.jpg"
                  alt="Nil" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nimba.jpg"
                  alt="Nimba" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/PKJ.jpg"
                  alt="PKJ" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Perrin.jpg"
                  alt="Perrin" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Phileas.png"
                  alt="Phileas" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Plon.jpg"
                  alt="Plon" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Pocket.jpg"
                  alt="Pocket" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Poulpe.jpg"
                  alt="Poulpe" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Pressesdelacite.png"
                  alt="Pressedelacite" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/RETZ.jpg"
                  alt="RETZ" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/RobertLaffont.png"
                  alt="RobertLaffont" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/SEGUIER.png"
                  alt="Seguier" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Seghers.png"
                  alt="Seghers" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Slalom.jpg"
                  alt="Slalom" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Solar.jpg"
                  alt="Solar" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Sonatine.jpg"
                  alt="Sonatine" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Syros.jpg"
                  alt="Syros" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Tana.jpg"
                  alt="Tana" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Télémaque.png"
                  alt="Télémaque" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/XO.jpg"
                  alt="XO" width="100%"  />
          </div>
        </Slider>
        </span>
        <span className="hide-tablet-large">
            <Slider {...settings2}>
        <div>
          <img src="https://scribay.s3.amazonaws.com/assets/1018.png"
                  alt="plume"  width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/1221.jpg"
                  alt="1221" width="100%" />
          </div>
          <div style={{verticalAlign: "center"}}>
          <img src="https://scribay.s3.amazonaws.com/assets/404Editions.png"
                  alt="404editions" width="100%"  />
          </div>
          < div  className="ada-logo">
          <img src="https://scribay.s3.amazonaws.com/assets/Agrume.jpg"
            alt="Agrume" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Archipel.jpg"
                  alt="Archipel" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Belfond.png"
                        alt="Belfond" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Bordas.jpg"
                        alt="Bordas" width="100%" />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Bruitmonde.png"
                  alt="Bruitdumonde" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/CLEinternational.jpg"
                  alt="CLEInternational" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/ChercheMidi.png"
                  alt="ChercheMidi" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Daesign.jpg"
                  alt="Daesign" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Decouverte.jpg"
                  alt="Decouverte" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Dragon.jpg"
                  alt="Dragon" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/EHO.jpg"
                  alt="EHO" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Empreinte.jpg"
                  alt="Empreinte" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Escales.jpg"
                  alt="Escales" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/First.jpg"
                  alt="First" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Fleuve.jpg"
                  alt="Fleuve" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Grund.jpg"
                  alt="Grund" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Hemma.jpg"
                  alt="Hemma" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/HorsCollection.jpg"
                  alt="HorsCollection" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Julliard.jpg"
                  alt="Juillard" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Kurokawa.jpg"
                  alt="Kurokawa" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Languechat.jpg"
                  alt="Languechat" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/LeRobert.jpg"
                  alt="LeRobert" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Lizzie.jpg"
                  alt="Lizzie" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/LonelyPlanet.jpg"
                  alt="LonelyPlanet" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/MDI.jpg"
                  alt="MDI" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nathan.jpg"
                  alt="Nathan" width="100%"  />
          </div>

          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nil.jpg"
                  alt="Nil" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Nimba.jpg"
                  alt="Nimba" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/PKJ.jpg"
                  alt="PKJ" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Perrin.jpg"
                  alt="Perrin" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Phileas.png"
                  alt="Phileas" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Plon.jpg"
                  alt="Plon" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Pocket.jpg"
                  alt="Pocket" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Poulpe.jpg"
                  alt="Poulpe" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Pressesdelacite.png"
                  alt="Pressedelacite" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/RETZ.jpg"
                  alt="RETZ" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/RobertLaffont.png"
                  alt="RobertLaffont" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/SEGUIER.png"
                  alt="Seguier" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Seghers.png"
                  alt="Seghers" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Slalom.jpg"
                  alt="Slalom" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Solar.jpg"
                  alt="Solar" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Sonatine.jpg"
                  alt="Sonatine" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Syros.jpg"
                  alt="Syros" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Tana.jpg"
                  alt="Tana" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/Télémaque.png"
                  alt="Télémaque" width="100%"  />
          </div>
          <div>
          <img src="https://scribay.s3.amazonaws.com/assets/XO.jpg"
                  alt="XO" width="100%"  />
          </div>
        </Slider>
        </span>
      </div>
      </div>
    );
  }
}