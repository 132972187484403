import RelectureModel from './RelectureModel';
import RelectureType from './RelectureType';
import {
  sentenceWarning, paragraphWarning, adverbWarning, negativeWarning, passiveWarning, repetitionWarning,
  punctuationWarning, verbesTerneWarning
}
  from '../../utils/relecture/lisibilite';

const allRelectures = [
  new RelectureModel(RelectureType.Adverbs, adverbWarning),
  new RelectureModel(RelectureType.Negative, negativeWarning),
  new RelectureModel(RelectureType.ParagraphSize, paragraphWarning),
  new RelectureModel(RelectureType.Passive, passiveWarning),
  new RelectureModel(RelectureType.Punctuation, punctuationWarning),
  new RelectureModel(RelectureType.Repetitions, repetitionWarning),
  new RelectureModel(RelectureType.SentenceSize, sentenceWarning),
/*   new RelectureModel(RelectureType.Spellcheck),
 */  new RelectureModel(RelectureType.VerbeTernes, verbesTerneWarning),

];

export default allRelectures;
