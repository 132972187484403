import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const DropzoneComponent = require('react-dropzone-component');
import Store from '../../../../stores/Store';
import FullCanvasCharacterModel from '../../../../models/canvas/FullCanvasCharacterModel';
import CharacterFullModel from '../../../../models/write/CharacterFullModel';
import actions from '../../../editor/actions';
import styleMap from '../../../editor/styleMap';
import blockStyles from '../../../editor/blockStyles';

interface CharacterFormProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  goToChapter?: Function | any;
  textId?: number;
  chapterIndex?: number;
  canvasId?: number;
  currentCharacter: CharacterFullModel | FullCanvasCharacterModel;
}

interface CharacterFormState {
  activeTab: number;
  removeAvatar: boolean;
  loading: boolean;
}

require('dropzone/dist/min/dropzone.min.css');
require('react-dropzone-component/styles/filepicker.css');

declare const dropzone: any;
declare const file: any;

@inject('store') @observer
export default class CharacterForm extends React.Component<CharacterFormProps, CharacterFormState> {
  constructor(props: CharacterFormProps) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.saveImageIntoState = this.saveImageIntoState.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.save = this.save.bind(this);
    this.state = {
      activeTab: 1,
      removeAvatar: false,
      loading: false
    };
  }
  onTabChange(activeTab: number) {
    this.setState({ activeTab: activeTab });
  }
  @action
  handleNameChange(e: any) {
    this.props.currentCharacter.updateName(e.target.value);
  }
  @action
  handleRemoveImage() {
    this.props.currentCharacter.image = null;
    this.props.currentCharacter.imageFile = null;
    this.setState({
      removeAvatar: true
    });
  }
  @action
  saveImageIntoState(f: File) {
    this.props.currentCharacter.imageFile = f;
  }
  @action
  handleDescriptionChange(value: any) {
    this.props.currentCharacter.updateDescriptionState(value);
  }
  @action
  save() {
    const character = this.props.currentCharacter;
    const nameError = character.name.trim().length === 0;

    if (!nameError) {
      if (character.id) {
        if (this.props.textId) {
          this.setState({
            loading: true
          });
          this.props.store.updateCharacter(this.props.textId, character.id,
            character.name, character.description).then((d: any) => {
              if (character.imageFile) {
                this.props.store.postCharacterImage(this.props.textId, character.id, character.imageFile)
                  .then((c: any) => {
                    this.props.store.loadCharacters(this.props.textId, this.props.chapterIndex).then((all: any) => {
                      this.props.hide();
                    });
                  });
              } else if (this.state.removeAvatar) {
                this.props.store.removeCharacterImage(this.props.textId, character.id)
                  .then((c: any) => {
                    this.props.store.loadCharacters(this.props.textId, this.props.chapterIndex).then((all: any) => {
                      this.props.hide();
                    });
                  });
              } else {
                this.props.store.loadCharacters(this.props.textId, this.props.chapterIndex).then((all: any) => {
                  this.props.hide();
                });
              }
            });
        } else {
          // Canvas
        }
      } else {
        if (this.props.textId) {
          this.setState({
            loading: true
          });
          this.props.store.createCharacter(this.props.textId, character.name, character.description)
            .then((created: any) => {
              if (character.imageFile) {
                this.props.store.postCharacterImage(this.props.textId, created.id, character.imageFile)
                  .then((c: any) => {
                    this.props.store.loadCharacters(this.props.textId, this.props.chapterIndex).then((all: any) => {
                      this.props.hide();
                    });
                  });
              } else {
                this.props.store.loadCharacters(this.props.textId, this.props.chapterIndex).then((all: any) => {
                  this.props.hide();
                });
              }
            });
        } else {
          // Canvas
        }
      }
    } else {
      character.nameError = nameError;
    }
  }
  render() {
    const character = this.props.currentCharacter;
    const header = character.id
      ? 'Modifier le personnage'
      : 'Nouveau personnage';
    const componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: true,
      postUrl: 'no-url',
      maxFiles: 1,
    };
    const eventHandlers = {
      init(dropzone: any) {
        if (character.image) {
          const mockFile = { name: 'Image', size: 30000 };
          dropzone.emit('addedfile', mockFile);
          dropzone.emit('thumbnail', mockFile,
            `https://s3-eu-west-1.amazonaws.com/scribay/characters/${character.image}.jpg`);
          dropzone.emit('complete', mockFile);
          dropzone.options.maxFiles = 1;
        }
      },
      addedfile: this.saveImageIntoState
    };
    const that = this;
    const djsConfig = {
      maxFiles: (character.image ? 0 : 1),
      acceptedFiles: 'image/jpg,image/jpeg,image/png,image/gif',
      thumbnailWidth: 100,
      thumbnailHeight: 160,
      autoProcessQueue: false,
      resizefile() {
        const resizeInfo = {
          srcX: 0,
          srcY: 0,
          trgX: 0,
          trgY: 0,
          srcWidth: file.width,
          srcHeight: file.height,
          trgWidth: this.options.thumbnailWidth,
          trgHeight: this.options.thumbnailHeight,
        };
        return resizeInfo;
      },
      reset(f: any) {
        that.handleRemoveImage();
      },
      uploadMultiple: false,
      addRemoveLinks: true,
      dictDefaultMessage: 'Cliquez ici ou glissez-déposez des fichiers dans cet emplacement.',
      dictFallbackMessage: 'Votre navigateur ne supporte pas le glisser-déposer.',
      dictRemoveFile: 'Supprimer',
      dictCancelUpload: 'Annuler',
      dictCancelUploadConfirmation: 'Confirmez-vous l\'arrêt du transfert ?',
      dictMaxFilesExceeded: 'Une seule image est autorisée.',
    };
    let chapters: JSX.Element[];
    if (this.props.textId) {
      if ((character as CharacterFullModel).chapters.length > 0) {
        chapters = (character as CharacterFullModel).chapters.map(c => {
          return (
            <div key={'chapchar' + c.id} className="item">
              {c.index} - <a onClick={() => this.props.goToChapter(c.id)} style={{ cursor: 'pointer' }}>{c.title}</a>
            </div>
          );
        });
      } else {
        chapters = [
          <div key="chapcharnone" className="item">
            Aucun
        </div>
        ];
      }
    }
    return (
      <Modal open={this.props.active} size="small" onClose={() => { }}>
        <div className="header">{header}</div>
        <div className="content">
          <div style={{ marginTop: '-20px' }}>
            <Menu
              activeIndex={this.state.activeTab}
              secondary
              pointing
              fluid
              widths={character.id ? 3 : 2}
              size="small"
              className="stackable"
            >
              <Menu.Item name="1" active={this.state.activeTab === 1} className="nopadding"
                onClick={() => this.onTabChange(1)}>
                Identité
              </Menu.Item>
              <Menu.Item name="2" active={this.state.activeTab === 2} className="nopadding"
                onClick={() => this.onTabChange(2)}>
                Description
              </Menu.Item>
              {/* {character.id && this.props.textId ?
                <Menu.Item name="3" active={this.state.activeTab === 3} className="nopadding"
                  onClick={() => this.onTabChange(3)}>
                  Chapitres
              </Menu.Item>
                : undefined} */}
            </Menu>
            {this.state.activeTab === 1 ?
              <form onSubmit={this.save} className="ui form">
                <div className={'inline field' + (character.nameError ? ' error' : '')}>
                  <label>Nom</label>
                  <input type="text" placeholder="Nom"
                    value={character.name} onChange={this.handleNameChange} />
                </div>
                <div className="field" style={{ maxWidth: '600px' }}>
                  <label>Image</label>
                  <DropzoneComponent
                    key={character.image || Math.random() * 1000}
                    config={componentConfig}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                  />
                  <span>200Ko maximum. L'image sera automatiquement redimensionnée au format carré.</span>
                </div>
              </form>
              : undefined}
            {this.state.activeTab === 2 ?
              <form onSubmit={this.save} className="ui form">
                <div className="field">
                  <label>Description</label>
                  <div className="embedded small" style={{ paddingRight: '20px' }}>
                    <div className="ui segment" style={{ height: '100%' }}>
                      <MegadraftEditor
                        editorState={character.descriptionState}
                        actions={actions}
                        placeholder="Description"
                        onChange={this.handleDescriptionChange}
                        sidebarRendererFn={() => { return <div />; }}
                        customStyleMap={styleMap}
                        blockStyles={blockStyles}
                        spellcheck={false}
                      />
                    </div>
                  </div>
                </div>
              </form>
              : undefined}
            {/* {this.state.activeTab === 3 && this.props.textId ?
              <div>
                <h4>Apparaît dans les chapitres :</h4>
                <div className="ui list">
                  {chapters}
                </div>
              </div>
              : undefined} */}
          </div>
        </div>
        <div className="actions">
          <button className={'ui primary submit ' + (this.state.loading ? ' loading' : '') + ' button'}
            onClick={this.save}
            type="submit"
          >
            Enregistrer
          </button>
          <button className="ui cancel button" onClick={() => this.props.hide()}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}
