import { observable } from 'mobx';
import ParcoursQuestionAnswerModel from './ParcoursQuestionAnswerModel';

export default class ParcoursQuestionModel {

  @observable id: number;
  @observable label: string;
  @observable character: string;
  @observable inspire: string;
  @observable answers: ParcoursQuestionAnswerModel[];

  @observable answerId: number;
  @observable answerText: string;

  constructor(id: number, label: string, character: string,
    inspire: string, answers: ParcoursQuestionAnswerModel[], answerId: number,
    answerText: string) {
    this.id = id;
    this.label = label;
    this.character = character;
    this.inspire = inspire;
    this.answers = answers;
    this.answerId = answerId;
    this.answerText = answerText;
  }

  toJS() {
    return {
      id: this.id,
      label: this.label,
      character: this.character,
      inspire: this.inspire,
      answers: this.answers.map(a => a.toJS()),
      answerId: this.answerId,
      answerText: this.answerText
    };
  }

  static fromJS(object: any) {
    if (object) {
      const answers = object.answers.map((a: any) => ParcoursQuestionAnswerModel.fromJS(a));
      return new ParcoursQuestionModel(object.id, object.label, object.character, object.inspire,
        answers, object.answerId, object.answerText);
    }
    return undefined;
  }
}
