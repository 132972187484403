import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
const cookie = new (require('react-cookie')).Cookies();
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import Title from '../misc/Title';
import ParcoursPageContent from '../write/text/parcours/ParcoursPageContent';
import AllUserParcoursPageContent from '../write/text/parcours/AllUserParcoursPageContent';
import NewParcoursChoicePageContent from '../write/text/parcours/NewParcoursChoicePageContent';
import WritingProgram from '../writingprogram/WritingProgram';
import WelcomeModalPremium from './WelcomeModalPremium';
import '../ada.css';

interface HomePremiumProps {
  store?: Store;
  welcome: boolean;

}

interface HomePremiumState {
  showEndPremium: boolean;
  welcome: boolean;
}

@inject('store') @observer
export default class HomePremium extends React.Component<HomePremiumProps, HomePremiumState> {
  constructor(props: HomePremiumProps) {
    super(props);
    this.hideEndPremium = this.hideEndPremium.bind(this);
    this.hideWelcome = this.hideWelcome.bind(this);
    const newUser = this.props.store.connected && this.props.store.connected.newUser;
    this.state = {
      showEndPremium: true,
      welcome: this.props.welcome && newUser,
    };
  }
  componentDidMount() {
    this.props.store.loadAllUserParcours();
    this.props.store.loadAllParcours();
    this.props.store.loadAllCardsDisconnected();
  }
  hideEndPremium() {
    cookie.set('hide_premium', '1', { maxAge: 259200 });
    this.setState({
      showEndPremium: false
    });
  }
  hideWelcome() {
    this.setState({
      welcome: false
    });
  }
  render() {
    const wp = this.props.store.mainWritingProgram;
    const todayObjectives = wp && wp.wp ? wp.wp.days.filter(r => r.today && r.objectives && r.objectives.length > 0) : [];
    const success = todayObjectives.length > 0
      && todayObjectives[0].objectives.length > 0
      && todayObjectives[0].objectives.filter(o =>
        (o.objWords && (o.words === null || o.words < o.objWords))
        || (o.objDefis && (o.defis === null || o.defis < o.objDefis))
        || (o.objTexts && (o.texts === null || o.texts < o.objTexts))
        || (o.objChapters && (o.chapters === null || o.chapters < o.objChapters))
        || (o.objTrainings && (o.trainings === null || o.trainings < o.objTrainings))
      ).length === 0;

    const cookieShowEndPremium = cookie && cookie.get && cookie.get('hide_premium');
    const currentPromotion = this.props.store.mainSettings.admin.currentPromotion;
    const allUserParcours = this.props.store.allUserParcours; 
    return (
      <div className="ui ada-background editis-beige-clair" style={{ minHeight: '90vh'}}>
      <div className="ui grid" style={{backgroundColor: allUserParcours.allParcours.length == 0 ? "white" : undefined}}>
        <div className="ui container" style={{marginTop: '15px', marginBottom: '20px'}}>
          <Title title="Tableau de bord" />
          <div className="ui" style={{ marginBottom: '40px'}}>
            {/* The académie is being remade so it is unavailable for now */}
            {/* {(this.props.store.connected.premium && this.props.store.connected.premium.premium
              && this.props.store.connected.premium.premiumEnd) && !cookieShowEndPremium && this.state.showEndPremium ?
              <div className="ui small warning message" style={{ overflow: 'auto' }}>
                <i className="fa fa-times close icon" onClick={this.hideEndPremium} title="Ne plus afficher" />
                Votre période d'essai prendra fin le {this.props.store.connected.premium.premiumEnd}.
                <br />
                {currentPromotion ?
                  <div><b>Jusqu'au {currentPromotion.until}, <span dangerouslySetInnerHTML={{ __html: currentPromotion.content }} /></b></div>
                  : undefined}
                <Link
                  to="/settings/academie"
                  className="ui right floated small warning basic button"
                >
                  Prolonger mon abonnement
                </Link>
              </div >
              : undefined} */}
            <div className="ui horizontal hide-tablet divider ada-divider-chill" style={{marginBottom:"0px"}} >Mon rythme d'écriture</div>
            <div className="ui horizontal hide-tablet-large ada-divider-chill" style={{marginBottom:"0px"}} >Mon rythme d'écriture</div>
              {/* {todayObjectives.length > 0 && success ?
                <div className="ui header">Félicitations ! Vous avez atteint tous vos objectifs du jour.</div>
                : (todayObjectives.length > 0 ?
                  <div className="ui header">Il vous reste {todayObjectives.length === 1 ? 'un objectif' : 'plusieurs objectifs'} en cours pour aujourd'hui. Bon courage !</div>
                  : undefined)
              } */}

              <WritingProgram premium />
          </div>
        </div>
      </div>
      {allUserParcours.allParcours.length != 0 ?
        <div className="ui grid" style={{backgroundColor:"white"}}>
          <div className="ui container" style={{marginTop: '30px', marginBottom: '50px'}}>
          
            <div>
              <div className="ui horizontal hide-tablet divider ada-divider-chill">
                  Vos textes et exercices en cours
              </div>
              <div className="ui horizontal hide-tablet-large ada-divider-chill">
                  Vos textes et exercices en cours
              </div>
              <AllUserParcoursPageContent/>
            </div>
            
            </div>
            </div>
            :
            undefined }
            <WelcomeModalPremium
              active={this.state.welcome}
              hide={this.hideWelcome}
            />
            
            <div className="ui grid">
            <div className="ui container" style={{marginTop: '15px', marginBottom: '20px'}}>
            <div>
              
              <NewParcoursChoicePageContent/>
            </div>
            
          {/*  <ParcoursPageContent
            parcoursId={parcours.id}
            text={currentText && currentText.infos ?
              { id: currentText.id, title: currentText.infos.urlText, chapter: undefined }
              : undefined}
            pinnedPrologue={pinnedPrologue}
          /> */}
        </div>
      </div>
      </div>
    );
  }
}
