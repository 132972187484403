import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class SuggestionModel {

  @observable user: UserModel;
  @observable count: number;
  @observable followed: boolean;

  constructor(user: UserModel, count: number, followed: boolean) {
    this.user = user;
    this.count = count;
    this.followed = followed;
  }

  toJS() {
    return {
      user: this.user.toJS(),
      count: this.count,
      followed: this.followed
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SuggestionModel(UserModel.fromJS(object.user), object.count, object.followed);
    }
    return undefined;
  }
}
