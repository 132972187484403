import * as React from 'react';
import { Link } from 'react-router';
import ReadingListModel from '../../models/readinglist/ReadingListModel';

interface ReadingListListProps {
  lists: ReadingListModel[];
  withAuthor?: boolean;
}

export default class ReadingListList extends React.Component<ReadingListListProps, {}> {
  render() {
    const items = this.props.lists.map(list => {
      return (
        <div key={Math.random() * 1000} className="item">
          <div className="ui mini image">
            <Link to={`/list/${list.id}/${list.urlName}`}>
              <img src={list.cover} alt={`Image de la liste ${list.name}`} />
            </Link>
          </div>
          <div className="middle aligned content">
            <Link to={`/list/${list.id}/${list.urlName}`} className="header">
              {list.name}
            </Link>
            {this.props.withAuthor ?
              <div className="description">
                {list.user.identity}
              </div>
              : undefined}
          </div>
        </div>
      );
    });
    return (
      <div className="ui items">
        {items}
      </div>
    );
  }
}
