import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { browserHistory, Link } from 'react-router';
import { AsyncCreatable } from 'react-select';
const InfiniteScroll = require('react-infinite-scroll-component');
const throttle = require('throttle-debounce').throttle;
import ReadQueryParams from './ReadQueryParams';
import Store from '../../stores/Store';
import TextGallery from '../misc/text/TextGallery';
import Loader from '../misc/Loader';

declare const __CLIENT__: any;

interface ReadPageProps {
  store?: Store;
  location: {
    query: ReadQueryParams
  };
}

interface ReadPageState {
  enableComponents: boolean;
  loadingTextsError: boolean;
  loadingTexts: boolean;
}

@inject('store') @observer
export default class ReadPage extends React.Component<ReadPageProps, ReadPageState> {
  static contextTypes = {
    intl: React.PropTypes.object.isRequired,
  };

  static fetchData(store: Store, params: void, query: ReadQueryParams) {
    const p: any[] = [];
    p.push(store.loadTextsRead(query, true));
    p.push(store.loadReadPopularTags());
    p.push(store.loadReadingListsLight());
    return Promise.all(p);
  }
  constructor(props: ReadPageProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.fetchTags = throttle(1000, this.fetchTags.bind(this));
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this);
    this.state = {
      enableComponents: false,
      loadingTextsError: false,
      loadingTexts: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }

  async syncTextloader() {
    this.props.store.loadTextsRead(this.props.location.query, false, (failed: boolean)=>{
      this.setState(
      {
        loadingTextsError: failed,
        loadingTexts: false
      }
    )
  });
  }
  
  loadMore() {
    this.setState({
      loadingTexts: true
    },
    () => {this.syncTextloader()}    
    );
  }

  
  fetchTags(input: string, callback: Function) {
    this.props.store.getReadTagsBeginningWith(input).then((tags: any) => {
      const options = tags.map((t: string) => ({
        value: t,
        label: t,
      }));

      callback(null, { options });
    });
  }
  handleSelectTagChange(value: [{ create: boolean, label: string, value: string }]) {
    const query = this.props.location.query;
    browserHistory.push({
      pathname: '/read', query: {
        size: query.size,
        genre: query.genre,
        licence: query.licence,
        tag: value.map(t => t.value),
        mine: query.mine,
        readingList: query.readingList,
        onlyDefis: !(query.onlyDefis && query.onlyDefis === 'true'),
        action: query.action,
        period: query.period
      }
    });
  }
  render() {
    const connected = this.props.store.connected;
    const isConnected = connected && connected.user && connected.user.id;

    const genres: Array<any> = [];
    genres.push({
      text: 'sans préférence',
      value: '0'
    });

    for (let i = 1; i <= 20; i++) {
      genres.push(
        {
          text: this.context.intl.formatMessage({ id: `genre.${i}` }),
          value: String(i)
        }
      );
    }

    const allLists = this.props.store.read.lists;

    const query = this.props.location.query;
    const size = query.size ? query.size : '0';
    const genre = query.genre ? query.genre : '0';
    const licence = query.licence ? query.licence : '0';
    const mine = query.mine && isConnected ? query.mine : 'false';
    const readingList = query.readingList && isConnected ? parseInt(query.readingList) : 0;
    const onlyDefis = query.onlyDefis ? query.onlyDefis : 'false';

    // tags
    const paramTag = this.props.location.query.tag;
    let selectedTags: string[];

    if (paramTag) {
      if (paramTag instanceof Array) {
        selectedTags = paramTag;
      } else {
        selectedTags = [paramTag];
      }
    } else {
      selectedTags = [];
    }
    const tags = selectedTags && selectedTags.length > 0 ?
      '&' + selectedTags.map(t => `tag= ${t}`).join('&')
      : '';
    const selectedTagsForm = selectedTags.map((t: string) => { return { label: t, value: t }; });

    const action = query.action ? query.action : 'recent';
    const period = query.period ? query.period : 'week';

    const all = (!mine || mine === 'false') && (!readingList || readingList === 0);
    const mainParams = `/read?size=${size}&genre=${genre}&licence=${licence}${tags}`;

    let title: string;
    if (mine && mine === 'true') {
      title = 'Mes œuvres';
    } else if (readingList !== 0) {
      const listName = allLists.filter(l => l.id === readingList)[0];
      if (listName) {
        title = `Liste "${listName.name}"`;
      } else {
        title = 'Liste de lecture';
      }
    } else {
      title = 'Toutes les œuvres';
    }

    const lists = allLists.map(l => {
      return {
        text: l.name,
        value: l.id,
        active: l.id === readingList
      };
    });

    const popularTags = this.props.store.read.popularTags.map(t => {
      const st = selectedTags.slice();

      if (st.indexOf(t) !== -1) {
        const newTags = st
          .filter((s: string) => s !== t)
          .slice(-3)
          .map((s: string) => `tag=${s}`);

        const queryParam = '&' + newTags.join('&');

        return (
          <Link
            to={`/read?size=${size}&genre=${genre}&licence=${licence}${queryParam}`}
            key={Math.random() * 1000}
            className="ui blue label"
          >
            {t}
          </Link>
        );
      } else {
        st.push(t);
        const newTags = st.slice(-3).map((s: string) => `tag=${s}`);

        const queryParam = '&' + newTags.join('&');

        return (
          <Link
            to={`/read?size=${size}&genre=${genre}&licence=${licence}${queryParam}`}
            key={Math.random() * 1000}
            className="ui label"
          >
            {t}
          </Link>
        );
      }
    });
    const endMessage = () => {
      let message;
      let error;
      if (!this.props.store.read.end){
        if (this.state.loadingTexts){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={this.loadMore}>Charger plus</button>
        }
        if (this.state.loadingTextsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Vous êtes arrivé à la fin</span>
      }
    
      return <div className="ui large feed centered grid">{error}{message}</div>

    }
    return (
      <div>
        <Title title={`Lire > ${title}  `} />
        <div className="ui two column grid stackable">
          <div className="eleven wide grey column" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
            <div className="ui grid centered stackable">
              <div className="fourteen wide column">
                <div className="ui massive breadcrumb">
                  <div className="section">Lire</div>
                  <i className="fa fa-fw fa-chevron-right divider"></i>
                  <div className="active section">{title}</div>
                </div>
                <div style={{ marginTop: '30px' }}>
                  {this.props.store.read.texts.length > 0 ?
                    <InfiniteScroll
                      next={this.loadMore}
                      hasMore={false}
                      loader={<h4>Chargement...</h4>}
                      endMessage={endMessage()}
                    >
                      <div style={{ padding: '20px 20px 10px 10px' }}>
                        <TextGallery texts={this.props.store.read.texts} twoPerRow />
                      </div>
                    </InfiniteScroll>
                    : (this.props.store.read.loading ?
                      <Loader message="Veuillez patienter" />
                      :
                      <div className="ui message warning">
                        Aucune œuvre ne correspond à ces critères.
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="five wide column ada-background-editis-beige-clair">
            <div className="ui grid centered stackable">
              <div className="fourteen wide column"
                style={{ textAlign: 'left', paddingTop: '50px', paddingBottom: '80px' }}>
                {isConnected ?
                  <div>
                    <div>
                      <Link to="/lists" className="ui fluid primary button">
                        Toutes mes listes de lecture
                      </Link>
                    </div>
                    <br/>
                    <div>
                      <Link to="/readings" className="ui fluid primary button">
                        Toutes mes lectures
                      </Link>
                    </div>
                    <div className="ui basic segment">
                      <div className="ui horizontal divider ada-museo-300">Filtres</div>
                      <div className="ui vertical fluid secondary menu" style={{ margin: '1rem 0em' }}>
                        <Link to={mainParams} className={'item' + (all ? ' active' : '')}>
                          Toutes les œuvres
                        </Link>
                        <Link to={`${mainParams}&mine=true`} className={'item' + (mine && mine === 'true' ? ' active' : '')}>
                          Mes œuvres
                        </Link>
                      </div>
                    </div>
                  </div>
                  : undefined}
                {isConnected ?
                  <div className="ui fluid secondary menu">
                    <Dropdown
                      text="Mes listes de lecture"
                      options={lists}
                      pointing
                      onChange={(e: any, { value }) => {
                        browserHistory.push({
                          pathname: '/read', query: {
                            size: size,
                            genre: genre,
                            licence: licence,
                            tag: selectedTags,
                            mine: false,
                            readingList: value,
                            onlyDefis: onlyDefis,
                            action: action,
                            period: period
                          }
                        });
                      }}
                      className={'fluid item' + (readingList && readingList !== 0 ? ' active' : '')}
                    />
                  </div>
                  : undefined}
                <div className="ui basic aligned segment">
                  <div className="ui horizontal divider ada-museo-300">Uniquement ...</div>
                  <div className="ui form">
                    <div className="field">
                      <Checkbox
                        checked={onlyDefis && onlyDefis === 'true'}
                        onClick={() =>
                          browserHistory.push({
                            pathname: '/read', query: {
                              size: size,
                              genre: genre,
                              licence: licence,
                              tag: selectedTags,
                              mine: mine,
                              readingList: readingList,
                              onlyDefis: !(onlyDefis && onlyDefis === 'true'),
                              action: action,
                              period: period
                            }
                          })
                        }
                        label="les réponses à des défis"
                      />
                    </div>
                  </div>
                </div>
                <div className="ui basic aligned segment">
                  <div className="ui horizontal divider ada-museo-300">Filtrer par</div>
                  <div className="ui form">
                    <div className="inline field">
                      <label>Taille</label>
                      <Dropdown
                        selection
                        value={size}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/read', query: {
                              genre: genre,
                              licence: licence,
                              tag: selectedTags,
                              size: value
                            }
                          });
                        }}
                        options={[
                          {
                            value: '0',
                            text: 'toutes'
                          },
                          {
                            value: '1',
                            text: 'court'
                          },
                          {
                            value: '2',
                            text: 'moyen'
                          },
                          {
                            value: '3',
                            text: 'long'
                          },
                          {
                            value: '4',
                            text: 'très long'
                          },
                        ]}
                      />
                    </div>
                    <div className="inline field">
                      <label>Genre</label>
                      <Dropdown
                        selection
                        value={genre}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/read', query: {
                              size: size,
                              licence: licence,
                              tag: selectedTags,
                              genre: value
                            }
                          });
                        }}
                        options={genres}
                      />
                    </div>
                    <div className="inline field">
                      <label>Licence</label>
                      <Dropdown
                        selection
                        value={licence}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/read', query: {
                              size: size,
                              licence: value,
                              tag: selectedTags,
                              genre: genre
                            }
                          });
                        }}
                        options={[
                          {
                            value: '0',
                            text: 'sans importance'
                          },
                          {
                            value: '1',
                            text: 'Tous droits réservés'
                          },
                          {
                            value: '2',
                            text: 'Creative Commons'
                          }
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="ui basic aligned segment">
                  <div className="ui horizontal divider ada-museo-300">Mots-clés</div>
                  {this.state.enableComponents ?
                    <AsyncCreatable
                      loadOptions={this.fetchTags}
                      multi={true}
                      value={selectedTagsForm}
                      onChange={this.handleSelectTagChange}
                      promptTextCreator={(label: string) => `Ajouter mot-clé: ${label}`}
                      placeholder="Aucun mot-clé"
                      noResultsText="Aucun mot-clé"
                      loadingPlaceholder="Chargement..."
                      ignoreCase={true}
                      ignoreAccents={false}
                      aria-label="Mots-clés"
                      searchPromptText="Entrez un mot-clé"
                    />
                    : undefined}
                  <h4>Les plus populaires</h4>
                  <div className="ui labels">
                    {popularTags}
                  </div>
                </div>
                <div className="ui basic center aligned segment">
                  <div className="ui horizontal divider ada-museo-300">Trier par</div>
                  <div className="field">
                    <Dropdown
                      selection
                      value={action}
                      onChange={(e: any, { value }) => {
                        browserHistory.push({
                          pathname: '/read', query: {
                            size: size,
                            genre: genre,
                            licence: licence,
                            tag: selectedTags,
                            mine: mine,
                            readingList: readingList,
                            onlyDefis: onlyDefis,
                            action: value,
                            period: (value === 'view' || value === 'rating' || value === 'comment'
                              ? 'week'
                              : undefined)
                          }
                        });
                      }}
                      options={[
                        {
                          value: 'recent',
                          text: 'Les plus récents'
                        },
                        {
                          value: 'view',
                          text: 'Les plus lus'
                        },
                        {
                          value: 'rating',
                          text: 'Les plus appréciés'
                        },
                        {
                          value: 'comment',
                          text: 'Les plus commentés'
                        }
                      ]}
                    />
                    {action === 'view' || action === 'rating' || action === 'comment' ?
                      <Dropdown
                        selection
                        value={period}
                        onChange={(e: any, { value }) => {
                          browserHistory.push({
                            pathname: '/read', query: {
                              size: size,
                              genre: genre,
                              licence: licence,
                              tag: selectedTags,
                              mine: mine,
                              readingList: readingList,
                              onlyDefis: onlyDefis,
                              action: action,
                              period: value
                            }
                          });
                        }}
                        options={[
                          {
                            value: 'week',
                            text: 'de la semaine'
                          },
                          {
                            value: 'month',
                            text: 'du mois'
                          },
                          {
                            value: 'year',
                            text: 'de l\'année'
                          },
                          {
                            value: 'alltime',
                            text: 'de toujours'
                          }
                        ]}
                      />
                      : undefined}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}
