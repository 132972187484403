import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import ChatView from './ChatView';
import ChatDiscussionTypingMessage from './ChatDiscussionTypingMessage';
import DiscussionModel from '../../models/chat/DiscussionModel';
import Store from '../../stores/Store';

interface ChatDiscussionPopupProps {
  store?: Store;
  message: string;
  postMessage: Function | any;
  handleTextChange: Function | any;
  closePopup: Function | any;
  remainingCharacters: number;
}

@inject('store') @observer
export default class ChatDiscussionPopup extends React.Component<ChatDiscussionPopupProps, {}> {
  constructor(props: ChatDiscussionPopupProps) {
    super(props);
    this.openDiscussion = this.openDiscussion.bind(this);
  }
  openDiscussion() {
    const discussionId = this.props.store.chat.discussion.id;
    this.props.closePopup(discussionId);
    this.props.store.chat.discussion = new DiscussionModel(undefined, undefined, undefined,
      undefined, undefined, 0, false, undefined);
    browserHistory.push({
      pathname: `/messages/discussion/${discussionId}`
    });
  }
  render() {
    const discussion = this.props.store.chat.discussion;
    const users = discussion.targetUsers
      .map(tu => tu.label)
      .splice(0, 3)
      .join(', ')
      + (discussion.targetUsers.length > 3 ? ',...' : '');
    return (
      <div>
        <div className="popup-head">
          <span className="popup-head-right">
            <i
              className="fa fa-fw fa-times"
              onClick={this.props.closePopup}
              style={{ cursor: 'pointer ' }}
            />
          </span>
          Conversation avec {users}
        </div>
        <div>
          <ChatView
            popup
          />
          <div className="ui comments">
            <form onSubmit={this.props.postMessage} className="ui reply form" style={{ padding: '10px' }}>
              <div className={(this.props.remainingCharacters < 0 ? 'error' : '') + ' field'} style={{ margin: '0em 0em 1.4em' }}>
                <textarea
                  value={this.props.message}
                  rows={2}
                  style={{ height: '5em' }}
                  onChange={this.props.handleTextChange}
                />
              </div>
              {this.props.remainingCharacters < 0 ?
                <span style={{ color: '#912d2b' }}>{this.props.remainingCharacters} caractères</span>
                : undefined}
              <button onClick={this.openDiscussion}
                type="button"
                className="ui tiny button"
              >
                Voir la conversation
              </button>
              <button
                className="ui blue submit tiny right floated button"
                disabled={discussion.targetUsers.length === 0 || this.props.remainingCharacters < 0 || this.props.remainingCharacters === 5000}
                type="submit"
                style={{ marginBottom: '10px ' }}
              >
                Poster
                </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
