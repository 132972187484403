import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import * as bowser from 'bowser';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import AlertModel from '../../models/AlertModel';
import Store from '../../stores/Store';

const questions = [
  {
    value: '1',
    label: 'Écrivez-vous avec régularité ?',
    radio: true,
    answers: [
      { value: '1', label: 'Non' },
      { value: '2', label: 'Oui, une heure par jour' },
      { value: '3', label: 'Oui, plusieurs heures par jour' },
    ]
  },
  {
    value: '2',
    label: 'Avez-vous déjà terminé un roman ?',
    radio: true,
    answers: [
      { value: '1', label: 'Oui' },
      { value: '2', label: 'Non' }
    ]
  },
  {
    value: '3',
    label: 'Connaissez-vous parfois des difficultés pour trouver l’inspiration ?',
    radio: true,
    answers: [
      { value: '1', label: 'Oui' },
      { value: '2', label: 'Non' }
    ]
  },
  {
    value: '4',
    label: 'Quels sont vos principales difficultés lorsque vous souhaitez écrire ?',
    radio: false,
    answers: [
      { value: '1', label: 'Trouver le temps pour écrire' },
      { value: '2', label: 'Ne pas procrastiner (remettre au lendemain)' },
      { value: '3', label: 'Ne pas me disperser entre plusieurs projets' },
      { value: '4', label: 'Prendre confiance en moi' }
    ]
  }
];

interface WritingProgramInitProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  firstInit?: boolean;
}

interface WritingProgramInitState {
  step: number;
  q1: string[];
  q2: string[];
  q3: string[];
  q4: string[];
  wordsPerDay: number;
  withTrainings: boolean;
}

class WritingProgramQuestionProps {
  question: string;
  handleAnswerChange: Function;
  selectedValue: string[];
}
class WritingProgramQuestion extends React.Component<WritingProgramQuestionProps, {}> {
  render() {
    const question = questions.filter(q => q.value === this.props.question)[0];
    const answers = question.answers.map((answer) => {
      return (
        <div key={`q_${question.value}_${answer.value}`} className="field">
          <div>
            {question.radio ?
              <Checkbox
                radio
                checked={this.props.selectedValue.indexOf(answer.value) !== -1}
                onClick={() => this.props.handleAnswerChange(answer.value)}
                label={answer.label}
              />
              : <Checkbox
                checked={this.props.selectedValue.indexOf(answer.value) !== -1}
                onClick={() => this.props.handleAnswerChange(answer.value)}
                label={answer.label}
              />
            }
          </div>
        </div>
      );
    });
    return (
      <div className="ui grouped fields">
        <label>{question.label}</label>
        {answers}
      </div>
    );
  }
}

@inject('store') @observer
export default class WritingProgramInit extends React.Component<WritingProgramInitProps, WritingProgramInitState> {
  constructor(props: WritingProgramInitProps) {
    super(props);
    this.state = {
      step: 1,
      q1: ['1'],
      q2: ['1'],
      q3: ['1'],
      q4: [],
      wordsPerDay: 0,
      withTrainings: false
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.initProgram = this.initProgram.bind(this);
  }
  next() {
    if (this.state.step < 4) {
      this.setState({
        step: this.state.step + 1
      });
    } else {
      this.setState({
        step: this.state.step + 1,
        wordsPerDay: this.state.q1[0] === '3' ? 500 : 250,
        withTrainings: true
      });
    }
  }
  previous() {
    this.setState({
      step: this.state.step - 1
    });
  }
  initProgram() {
    let questionnaire: { label: string, answer: string[] }[] = [];

    const q1 = questions.filter(q => q.value === '1')[0];
    const q2 = questions.filter(q => q.value === '2')[0];
    const q3 = questions.filter(q => q.value === '3')[0];
    const q4 = questions.filter(q => q.value === '4')[0];

    const reg = this.state.q1[0] === '1';
    const novel = this.state.q2[0] === '2';
    const inspi = this.state.q3[0] === '1';
    const conf = this.state.q4.indexOf('4') !== -1;

    this.props.store.initWritingProgram(this.state.wordsPerDay, this.state.withTrainings,
      reg, novel, inspi, conf).then((d: any) => {
        this.props.store.loadConnected().then((d: any) => {
          this.props.store.loadCurrentWritingProgram().then((w: any) => {
            this.props.store.addAlert(new AlertModel(
              'cancelProgram',
              'Votre programme d\'écriture est lancé, bon courage !',
              '',
              'success',
              5
            ));
            this.setState({
              step: 1,
              q1: ['1'],
              q2: ['1'],
              q3: ['1'],
              q4: [],
              wordsPerDay: 0,
              withTrainings: false
            });
            if (this.props.firstInit) {
              browserHistory.push({
                pathname: '/'
              });
            } else {
              this.props.hide();
            }
          });
        });
      });
  }
  render() {
    const newUser = this.props.store.connected && this.props.store.connected.newUser;
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
        size="small"
      >
        <div className="header">
          Rythmes d’écriture
        </div>
        <div className="content">
          <div className="ui form">
            {this.state.step === 1 ?
              <div>
                <div className="ui info message">
                  Cette courte série de questions vous permettra d'ajuster votre programme d'écriture selon vos envies.
                </div>
                <WritingProgramQuestion
                  question="1"
                  handleAnswerChange={(value: string) => this.setState({ q1: [value] })}
                  selectedValue={this.state.q1}
                />
              </div>
              : (this.state.step === 2 ?
                <WritingProgramQuestion
                  question="2"
                  handleAnswerChange={(value: string) => this.setState({ q2: [value] })}
                  selectedValue={this.state.q2}
                />
                : (this.state.step === 3 ?
                  <WritingProgramQuestion
                    question="3"
                    handleAnswerChange={(value: string) => this.setState({ q3: [value] })}
                    selectedValue={this.state.q3}
                  />
                  : (this.state.step === 4 ?
                    <WritingProgramQuestion
                      question="4"
                      handleAnswerChange={(value: string) => {
                        let newValues: string[] = [];
                        if (this.state.q4.indexOf(value) === -1) {
                          newValues = this.state.q4;
                          newValues.push(value);
                        } else {
                          newValues = this.state.q4.filter(v => v !== value);
                        }
                        this.setState({ q4: newValues });
                      }}
                      selectedValue={this.state.q4}
                    />
                    : (this.state.step === 5 ?
                      <div>
                        <p>Nous vous conseillons d'écrire <b>chaque jour</b>, à hauteur de <b>{this.state.wordsPerDay}</b> mots par jour. Ce rythme vous permettra de développer vos routines d'écriture par un effort régulier.</p>
                      </div>
                      : undefined
                    )
                  )
                )
              )
            }
          </div>
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
          {this.state.step > 1 ?
            <button className="ui cancel button" onClick={this.previous}>Précédent</button>
            : undefined}
          {this.state.step === 5 ?
            <button className="ui primary button" onClick={this.initProgram}>En route !</button>
            : <button className="ui primary button" onClick={this.next}>Suivant</button>
          }
        </div>
      </Modal >
    );
  }
}
