import { observable } from 'mobx';

export default class CanvasCharacterModel {

  @observable id: number;
  @observable name: string;
  @observable image: string;

  constructor(id: number, name: string, image: string) {
    this.id = id;
    this.name = name;
    this.image = image;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      image: this.image
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CanvasCharacterModel(object.id, object.name, object.image);
    }
    return undefined;
  }
}
