import { observable } from 'mobx';

class HeatMapDetail {
  @observable comments: number;
  @observable annotations: number;
  @observable texts: number;
  @observable chapters: number;
  @observable versions: number;
  @observable talks: number;
  @observable diaries: number;

  constructor(comments: number, annotations: number, texts: number, chapters: number,
    versions: number, talks: number, diaries: number) {
    this.comments = comments;
    this.annotations = annotations;
    this.texts = texts;
    this.chapters = chapters;
    this.versions = versions;
    this.talks = talks;
    this.diaries = diaries;
  }

  toJS() {
    return {
      comments: this.comments,
      annotations: this.annotations,
      texts: this.texts,
      chapters: this.chapters,
      versions: this.versions,
      talks: this.talks,
      diaries: this.diaries,
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new HeatMapDetail(object.comments, object.annotations, object.texts, object.chapters,
        object.versions, object.talks, object.diaries);
    }
    return undefined;
  }
}

export default class ProfileHeatMapDetail {
  @observable readableDate: string;
  @observable heatMap: HeatMapDetail;

  constructor(readableDate: string, heatMap: HeatMapDetail) {
    this.readableDate = readableDate;
    this.heatMap = heatMap;
  }

  toJS() {
    return {
      readableDate: this.readableDate,
      heatMap: this.heatMap.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ProfileHeatMapDetail(object.readableDate, HeatMapDetail.fromJS(object.heatMap));
    }
    return undefined;
  }
}
