import UserModel from './UserModel';

export default class LightTextModel {
  id: number;
  title: string;
  summary: string;
  defi: string;
  cover: string;
  author: UserModel;
  urlText: String;
  firstChapterId: number;
  dateCreation: string;

  constructor(id: number, title: string, summary: string, defi: string, cover: string,
    author: UserModel, urlText: string, firstChapterId: number, dateCreation: string) {
    this.id = id;
    this.title = title;
    this.summary = summary;
    this.defi = defi;
    this.cover = cover;
    this.author = author;
    this.urlText = urlText;
    this.firstChapterId = firstChapterId;
    this.dateCreation = dateCreation;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      summary: this.summary,
      defi: this.defi,
      cover: this.cover,
      author: this.author.toJS(),
      urlText: this.urlText,
      firstChapterId: this.firstChapterId,
      dateCreation: this.dateCreation
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new LightTextModel(object.id, object.title, object.summary,
        object.defi, object.cover, UserModel.fromJS(object.author), object.urlText, 
        object.firstChapterId, object.dateCreation);
    }
    return undefined;
  }
}
