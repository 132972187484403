import { observable } from 'mobx';

export default class ParcoursQuestionAnswerModel {

  @observable id: number;
  @observable label: string;
  @observable character: string;
  @observable trigger: number;

  constructor(id: number, label: string, character: string, trigger: number) {
    this.id = id;
    this.label = label;
    this.character = character;
    this.trigger = trigger;
  }

  toJS() {
    return {
      id: this.id,
      label: this.label,
      character: this.character,
      trigger: this.trigger
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursQuestionAnswerModel(object.id, object.label, object.character, object.trigger);
    }
    return undefined;
  }
}
