import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import AlertModel from '../../models/AlertModel';
import Store from '../../stores/Store';

interface TextRemoveProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface TextRemoveState {
  title: string;
}

@inject('store') @observer
export default class TextRemove extends React.Component<TextRemoveProps, TextRemoveState> {
  constructor(props: TextRemoveProps) {
    super(props);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.removeText = this.removeText.bind(this);
    this.state = {
      title: '',
    };
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  removeText() {
    this.props.store.removeText().then((d: any) => {
      this.props.store.addAlert(new AlertModel(
        'removeText',
        `Texte ${this.props.store.text.meta.title} supprimé !`,
        '',
        'success',
        5
      ));
      browserHistory.push({
        pathname: '/write'
      });
    });
  }
  render() {
    const defi = this.props.store.text.infos.defi;
    const defiInfo = defi ?
      (
        <div className="ui message">
          <div className="header">Avertissement</div>
          Cette œuvre est liée au défi <b>{defi.title}</b>.
        <br />La suppression de l'œuvre entraînera l'abandon définitif du défi.
       </div>
      )
      : undefined;
    const sameTitle = this.state.title.trim()
      === 'SUPPRIMER';
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          <p>
            Confirmez-vous la suppression de cette œuvre ?
                  <br />Si oui, entrez SUPPRIMER dans le champ ci-dessous :
                </p>
          <div className="ui form">
            <div className="fields">
              <div className="field">
                <input
                  type="text"
                  name="first-name"
                  placeholder="Entrez SUPPRIMER"
                  onChange={this.handleTitleChange}
                  value={this.state.title}
                  aria-label="Entrez SUPPRIMER"
                />
              </div>
            </div>
          </div>
          {defiInfo}
        </div>
        <div className="actions">
          {sameTitle ?
            <button className="ui primary button" onClick={this.removeText}>Supprimer définitivement</button>
            : undefined}
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
