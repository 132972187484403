import * as React from 'react';

export default class NotFound extends React.Component<{}, {}> {
  render() {
    return (
      <div className="ui container">
        <h1>404</h1>
        <p>Ce n'est pas la page que vous recherchez !</p>
        <p>Nous vous proposons de revenir en <a href="https://www.atelierdesauteurs.com">page d'accueil</a>.</p>
      </div>
    );
  }
}
