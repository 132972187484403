import { observable } from 'mobx';
import CanvasModel from './CanvasModel';
import FullCanvasModel from './FullCanvasModel';

export default class MainCanvasModel {

  @observable allCanvas: CanvasModel[];
  @observable canvas: FullCanvasModel;

  constructor(allCanvas: CanvasModel[], canvas: FullCanvasModel) {
    this.allCanvas = allCanvas || [];
    this.canvas = canvas;
  }

  setAllCanvas(allCanvas: CanvasModel[]) {
    this.allCanvas = allCanvas;
  }

  setCanvas(canvas: FullCanvasModel) {
    this.canvas = new FullCanvasModel(canvas.id, canvas.title, canvas.description, canvas.image, canvas.notes,
      canvas.createdAt, canvas.updatedAt, undefined, undefined, undefined);
  }

  toJS() {
    return {
      allCanvas: this.allCanvas.map(c => c.toJS()),
      canvas: this.canvas.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      const allCanvas = object.allCanvas.map((c: any) => CanvasModel.fromJS(c));
      return new MainCanvasModel(allCanvas, FullCanvasModel.fromJS(object.canvas));
    }
    return undefined;
  }
}
