import * as React from 'react';
import Title from '../misc/Title';
const Mailto = require('react-mailto').default;

interface MentionsState {
  showEmails: boolean;
}

export default class Mentions extends React.Component<{}, MentionsState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const adminEmail = this.state.showEmails ? 'admin@atelierdesauteurs.com' : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Mentions légales " />
          <h1>Mentions légales</h1>
          <div className="ui basic segment">
            <h2>Nous contacter</h2>
            <Mailto email="contact@atelierdesauteurs.com" obfuscate={true}>
              Nous contacter
            </Mailto>

            <h2>Editeur du site</h2>

            <p>L’Atelier des auteurs, Société par actions simplifiée au capital social de 5000 euros, immatriculée au RCS de Nanterre sous le n° 820 829 356 dont le siège social se situe 92, avenue de France.</p>
            <p>contact@atelierdesauteurs.com</p>
            <p>06.21.68.10.70</p>
            <ul>
              <li>Directeur de publication : Stéphane Watelet</li>
            </ul>

            <h2>Administration du site</h2>
            <p>L’Atelier des Auteurs (www.atelierdesauteurs.com) est un site exploité par L’Atelier des Auteurs SASU, société immatriculée 820 829 356 au RCS de Paris, dont le siège social se situe au :</p>
            <address>
              92 Avenue de France
              <br />75013 Paris
            </address>

            <p>L’entreprise l'Atelier des Auteurs SAS est dirigée par Stéphane Watelet (Président).</p>
            <p>Comme cela est exposé dans les C.G.U., l'Atelier des Auteurs est une plateforme hébergeant des contenus publiés par ses membres. Les membres sont responsables des contenus qu’ils mettent en ligne sur la plateforme, et qui sont uniquement modérés a posteriori sur signalement auprès de la modération du site.</p>

            <h2>Modération du site</h2>
            <p>Un bouton « Signaler » se trouve sur chaque page de présentation d’une œuvre.</p>
            <p>Vous pouvez aussi nous envoyer un email à {adminEmail}.</p>

            <h2>Informations sur l'hébergeur du site</h2>
            <p>Le site est hébergé par Amazon Web Services dont les coordonnées sont les suivantes :</p>
            <address>
              Amazon Web Services, Inc.
                      <br />P.O. Box 81226
                      <br />Seattle, WA 98108-1226
                      <br />http://aws.amazon.com
                      <br />(206) 266-1000
                  </address>
            <p>Conformément aux dispositions de la loi française n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, l'hébergeur dispose des coordonnées du gestionnaire du site.</p>
          </div>
        </div>
      </div>
    );
  }
}
