import { observable } from 'mobx';

export default class SettingsAccount {

  @observable email: string;
  @observable facebookUser: boolean;
  @observable identifiant: string;
  @observable currentPassword: string;
  @observable newPassword: string;
  @observable confirmPassword: string;

  @observable loading: boolean;

  constructor(email: string, facebookUser: boolean, identifiant: string,
    currentPassword: string, newPassword: string, confirmPassword: string) {
    this.email = email;
    this.facebookUser = facebookUser;
    this.identifiant = identifiant;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.confirmPassword = confirmPassword;
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      email: this.email,
      facebookUser: this.facebookUser,
      identifiant: this.identifiant,
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SettingsAccount(
        object.email, object.facebookUser, object.identifiant,
        object.currentPassword, object.newPassword, object.confirmPassword
      );
    }
    return undefined;
  }
}
