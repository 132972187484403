import { observable } from 'mobx';

class CurrentParcoursChapterModel {
  @observable partName: string;
  @observable chapterTitle: string;
  @observable textId: number;
  @observable textTitle: string;
  @observable textUrl: string;
  @observable chapterId: number;

  constructor(partName: string,
    chapterTitle: string, textId: number,
    textTitle: string, textUrl: string, chapterId: number) {
    this.partName = partName;
    this.chapterTitle = chapterTitle;
    this.textId = textId;
    this.textTitle = textTitle;
    this.textUrl = textUrl;
    this.chapterId = chapterId;
  }

  toJS() {
    return {
      partName: this.partName,
      chapterTitle: this.chapterTitle,
      textId: this.textId,
      textTitle: this.textTitle,
      textUrl: this.textUrl,
      chapterId: this.chapterId
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentParcoursChapterModel(object.partName, object.chapterTitle,
        object.textId, object.textTitle, object.textUrl, object.chapterId);
    }
    return undefined;
  }
}

class CurrentParcoursPrologueModel {
  @observable id: number;
  @observable index: number;

  constructor(id: number, index: number) {
    this.id = id;
    this.index = index;
  }

  toJS() {
    return {
      id: this.id,
      index: this.index
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentParcoursPrologueModel(object.id, object.index);
    }
    return undefined;
  }
}

export default class CurrentParcoursModel {

  @observable id: number;
  @observable name: string;
  @observable image: string;
  @observable chapter: CurrentParcoursChapterModel;
  @observable prologue: CurrentParcoursPrologueModel;


  constructor(id: number, name: string, image: string, chapter: CurrentParcoursChapterModel,
    prologue: CurrentParcoursPrologueModel) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.chapter = chapter;
    this.prologue = prologue;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      image: this.image,
      chapter: this.chapter.toJS(),
      prologue: this.prologue.toJS()
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentParcoursModel(object.id, object.name, object.image,
        CurrentParcoursChapterModel.fromJS(object.chapter),
        CurrentParcoursPrologueModel.fromJS(object.prologue));
    }
    return undefined;
  }
}
