export default class Config {
  userConfig: Config;
  tagTypes: { name: string, minimumLength: number }[];
  strictness: boolean;
  perfLog: boolean;
  debug: boolean;

  constructor(userConfig: any) {
    this.userConfig = userConfig;
    this.tagTypes = [{ name: 'adj', minimumLength: 0 }, { name: 'adv', minimumLength: 0 }, { name: 'art', minimumLength: 0 },
    { name: 'con', minimumLength: 0 }, { name: 'nom', minimumLength: 0 }, { name: 'ono', minimumLength: 0 },
    { name: 'pre', minimumLength: 0 }, { name: 'ver', minimumLength: 0 }, { name: 'pro', minimumLength: 0 }];
    this.strictness = false;
    this.perfLog = false;
    this.debug = false;
    if (userConfig) {
      this.tagTypes = userConfig.tagTypes || this.tagTypes;
      this.strictness = userConfig.strictness || this.strictness;
      this.perfLog = userConfig.perfLog || this.perfLog;
      this.debug = userConfig.debug || this.debug;
    }
  }
}
