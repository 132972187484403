import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import StructureParcoursModel from '../../models/write/StructureParcoursModel';
import StructurePartParcoursModel from '../../models/write/StructurePartParcoursModel';
import ParcoursModel from '../../models/parcours/ParcoursModel';
import Store from '../../stores/Store';

interface TextWithParcoursActiveStructureProps {
  store?: Store;
  afterSave?: Function;
  textId?: number;
  urlText?: string;
  structure: StructureParcoursModel;
  parcours: ParcoursModel;
  scrollable?: boolean;
}

@inject('store') @observer
export default class TextWithParcoursActiveStructure
  extends React.Component<TextWithParcoursActiveStructureProps, {}> {
  constructor(props: TextWithParcoursActiveStructureProps) {
    super(props);
    this.goToChapter = this.goToChapter.bind(this);
  }
  goToChapter(chapterId: number) {
    if (this.props.afterSave) {
      this.props.afterSave();
    }
    const urlText = this.props.urlText;
    browserHistory.push({
      pathname: `/text/${this.props.textId}/${urlText}/chapter/${chapterId}/edit`,
      state: {
        newPart: false,
        showCongratulationsChapter: false
      }
    });
  }
  render() {
    let parts: JSX.Element[];
    let chapters: JSX.Element[];
    const parcours = this.props.parcours;
    const structure = this.props.structure;

    const inEditMode = structure.parts.filter(p => p.editMode).length > 0;

    parts = [].concat.apply([], structure.parts.map((part, i) => {
      if (part.chapters.length > 0) {
        chapters = part.chapters.map((chapter, j) => {
          return (
            <tr key={`part_${i}_chapter_${j}`}>
              {j === 0 ?
                <td rowSpan={part.chapters.length}>
                  {part.title}
                </td>
                : undefined}
              <td className={'center aligned' + (chapter.disabled ? ' disabled' : '')}>
                {chapter.title}
                {!chapter.disabled ?
                  <span style={{ float: 'right' }}>
                    <button
                      title="Modifier le chapitre"
                      className="ui mini icon orange button"
                      style={{ marginRight: '5px' }}
                      onClick={() => this.goToChapter(chapter.id)}
                    >
                      <i className="fa fa-edit" />
                    </button>
                  </span>
                  : undefined}
              </td>
            </tr>
          );
        });
      } else {
        return (
          <tr key={`part_${i}`} className="disabled">
            <td>
              {part.title}
            </td>
            <td className="center aligned">
              Finissez les parties précédentes pour déverrouiller cette partie !
            </td>
          </tr>
        );
      }
      return chapters;
    }));
    return (
      <div>
        <div className="ui form">
          <div className="field">
            <div className="ui basic segment"
              style={this.props.scrollable ?
                { paddingBottom: '20px', maxHeight: '400px', overflowX: 'scroll', overflowY: 'scroll' }
                : { overflowX: 'scroll' }}>
              <div>
                <div className="ui info message">
                  Cette œuvre est basée sur le parcours <b>{parcours.name}</b>.
                </div>
                <table className="ui celled structured table">
                  <thead>
                    <tr>
                      <th>Partie</th>
                      <th>Chapitre</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parts}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
