import { observable } from 'mobx';
import LightTextModel from '../LightTextModel';
import UserModel from '../UserModel';

export default class DefiReponseModel {

  @observable active: boolean;
  @observable deadline: string;
  @observable text: LightTextModel;
  @observable author: UserModel;
  @observable cancelledAt: string;

  constructor(active: boolean, deadline: string, text: LightTextModel, author: UserModel, cancelledAt: string) {
    this.active = active;
    this.deadline = deadline;
    this.text = text;
    this.author = author;
    this.cancelledAt = cancelledAt;
  }

  toJS() {
    return {
      active: this.active,
      deadline: this.deadline,
      text: this.text.toJS(),
      author: this.author.toJS(),
      cancelledAt: this.cancelledAt
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new DefiReponseModel(object.active, object.deadline,
        LightTextModel.fromJS(object.text), UserModel.fromJS(object.author), object.cancelledAt);
    }
    return undefined;
  }
}
