import * as React from 'react';
const xpath = require('xpath');
import { inject, observer } from 'mobx-react';
import * as jsdiff from 'diff';
const Diff2Html = require('diff2html').Diff2Html;
const hogan = require('hogan.js');
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { DOMParser } from 'xmldom';
import Store from '../../../../../stores/Store';

interface ChapterCompareProps {
  store?: Store;
}

interface ChapterCompareState {
  showModal: boolean;
  diff: string;
}

require('diff2html/dist/diff2html.min.css');

@inject('store') @observer
export default class ChapterCompare extends React.Component<ChapterCompareProps, ChapterCompareState> {
  constructor(props: ChapterCompareProps) {
    super(props);
    this.onCompare = this.onCompare.bind(this);
    this.state = { showModal: false, diff: '' };
    this.close = this.close.bind(this);
  }
  getTextContent(html: string) {
    const doc = new DOMParser().parseFromString('<div>' + html + '</div>');
    const nodes = xpath.select('//*[self::p or self::h1 or self::h2 or self::h3 or self::blockquote or self::li]', doc);
    const newTextContent = nodes.map((n: any) => {
      if (n.nodeName === 'li' && n.parentNode.nodeName === 'ul') {
        return '\u2014' + ' ' + n.textContent;
      } else {
        return n.textContent;
      }
    }).join('\n');
    return newTextContent;
  }
  onCompare() {
    this.props.store.saveChapter(false, false).then((d: any) => {
      this.props.store.compareVersions().then((compare: any) => {
        const textTitle = this.props.store.write.currentText.infos.title;

        const diff = jsdiff.createPatch(
          textTitle,
          this.getTextContent(compare.currentTextContent),
          this.getTextContent(compare.newTextContent),
          compare.currentTitle.trim(),
          compare.newTitle.trim(),
          undefined
        );
        const templateEmpty = hogan.compile(
          '<tr> \
                <td class="{{diffParser.LINE_TYPE.INFO}}"> \
                    <div class="{{contentClass}} {{diffParser.LINE_TYPE.INFO}}"> \
                        Aucun changement. \
                    </div> \
                </td> \
              </tr>'
        );
        const templateChanged = hogan.compile(
          '<span class="d2h-tag d2h-changed d2h-changed-tag">CHANGEMENT</span>'
        );

        const htmlDiff = Diff2Html.getPrettyHtml(
          diff,
          {
            outputFormat: 'side-by-side',
            templates: {
              'generic-empty-diff': templateEmpty,
              'tag-file-changed': templateChanged
            }
          });
        this.setState({
          showModal: true,
          diff: htmlDiff,
        });
      });
    });
  }
  close() {
    this.setState({
      showModal: false,
      diff: ''
    });
  }
  render() {
    return (
      <button
        className="ui button hide-mobile"
        onClick={this.onCompare}
        title="Comparer"
        data-tooltip="Comparer" data-inverted="" data-position="top center"
      >
        <Modal onClose={this.close} open={this.state.showModal} size="large">
          <div className="header">Comparer les versions</div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: this.state.diff }}
          />
          <div className="actions">
            <button className="ui cancel button" onClick={this.close}>Fermer</button>
          </div>
        </Modal>
        <i className="fa fa-arrows-h" />
      </button>
    );
  }
}
