import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ClassicTextToolbar from './ClassicTextToolbar';
import RelectureTextToolbar from './RelectureTextToolbar';
import Store from '../../../../stores/Store';
import ChapterParams from '../ChapterParams';

interface TextToolbarProps {
  store?: Store;
  params: ChapterParams;
  location: any;
}

@inject('store') @observer
export default class TextToolbar extends React.Component<TextToolbarProps, {}> {
  static fetchData(store: Store, params: ChapterParams) {
    const p: any[] = [];
    p.push(store.loadConnected());
    p.push(store.loadTextInfos(params.textId));
    return Promise.all(p);
  }
  render() {
    const infos = this.props.store.write.currentText.infos;
    const user = this.props.store.connected.user;
    const currentChapter = this.props.store.write.currentText.currentChapter;
    let cpt: JSX.Element;
    if (infos && user && user.id) {
      if (currentChapter && currentChapter.relectureMode) {
        cpt = <RelectureTextToolbar />;
      } else {
        cpt = <ClassicTextToolbar params={this.props.params} location={this.props.location} />;
      }
    } else {
      return <div></div>;
    }

    return cpt;
  }
}
