import { observable } from 'mobx';

export default class ProductAuthorModel {

  @observable id: number;
  @observable productId: number;
  @observable name: string;
  @observable index: number;
  @observable url: string;
  @observable bio: string;
  @observable genre: string;


  constructor(id: number, productId: number, name: string, index: number,
    url: string, bio: string, genre: string) {
      this.id = id
      this.productId = productId
      this.name = name
      this.index = index
      this.url = url
      this.bio = bio
      this.genre = genre
    }
  toJS() {
    return {
      id: this.id,
      productId: this.productId,
      name: this.name,
      index: this.index,
      url: this.url,
      bio: this.bio,
      genre: this.genre
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ProductAuthorModel(
        object.id,
        object.productId,
        object.name,
        object.index,
        object.url,
        object.bio,
        object.genre
        );
    }
    return undefined;
  }
}
