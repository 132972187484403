import AdverbDecorator from './AdverbDecorator';
import NegativeDecorator from './NegativeDecorator';
import PassiveDecorator from './PassiveDecorator';
import SentenceDecorator from './SentenceDecorator';
import ParagraphDecorator from './ParagraphDecorator';
import RepetitionDecorator from './repetition/RepetitionDecorator';
import VerbeTerneDecorator from './VerbeTerneDecorator';
import PunctuationMissingSpaceDecorator from './PunctuationMissingSpaceDecorator';
import PunctuationExtraSpaceDecorator from './PunctuationExtraSpaceDecorator';
import RelectureType from '../../../../../../models/write/RelectureType';

const decorators: { name: number, label: string, dec: any[], onSelect?: Function }[] = [
  { name: RelectureType.SentenceSize, label: 'Phrases trop longues', dec: [SentenceDecorator] },
  { name: RelectureType.ParagraphSize, label: 'Paragraphes trop longs', dec: [ParagraphDecorator] },
  { name: RelectureType.Adverbs, label: 'Adverbes en -ment', dec: [AdverbDecorator] },
  { name: RelectureType.Negative, label: 'Forme négative', dec: [NegativeDecorator] },
  { name: RelectureType.Passive, label: 'Forme passive', dec: [PassiveDecorator] },
  { name: RelectureType.Repetitions, label: 'Répétitions', dec: [RepetitionDecorator] },
  { name: RelectureType.VerbeTernes, label: 'Verbes ternes', dec: [VerbeTerneDecorator] },
  /* {
    name: RelectureType.Spellcheck, label: 'Orthographe & Grammaire', dec: [SpellcheckDecorator],
    onSelect: updateSpellcheckErrors
  }, */
  {
    name: RelectureType.Punctuation, label: 'Ponctuation',
    dec: [PunctuationMissingSpaceDecorator, PunctuationExtraSpaceDecorator]
  }
];

export default decorators;
