import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import * as bowser from 'bowser';
const Recaptcha = require('react-grecaptcha');
import Store from '../../stores/Store';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

interface LoginProps {
  store?: Store;
  location: {
    query: {
      p: number;
    }
  };
}

interface LoginState {
  username: string;
  usernameError: boolean;
  password: string;
  passwordError: boolean;
  recaptcha: boolean;
  recaptchaResponse: string;
  loginError: boolean;
  blockedError: boolean;
  loading: boolean;
  CGUNotChecked: boolean;
}

@inject('store') @observer
export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      username: '',
      usernameError: false,
      password: '',
      passwordError: false,
      recaptcha: false,
      recaptchaResponse: '',
      loginError: false,
      blockedError: false,
      loading: false,
      CGUNotChecked: true
    };
    this.goToSignUp = this.goToSignUp.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmCGUChange = this.handleConfirmCGUChange.bind(this);
    this.postForm = this.postForm.bind(this);
    this.fillRecaptcha = this.fillRecaptcha.bind(this);
  }
  componentDidMount() {
    if (this.props.store.paymentTunnel.product == undefined && this.props.location.query.p !== undefined) {
      this.props.store.loadPaymentTunnelProduct(this.props.location.query.p)
    }
  }
  goToSignUp() {
    this.props.store.paymentTunnel.product ? 
    browserHistory.push({
      pathname: '/signUp',
      search: '?p='+this.props.store.paymentTunnel.product.id
    })
    :
    browserHistory.push({
      pathname: '/signUp'
    })
  }
  handleConfirmCGUChange() {
    this.setState({
      ...this.state,
      CGUNotChecked: !this.state.CGUNotChecked
    });
  }
  handleUsernameChange(e: any) {
    const usernameError = this.state.usernameError && e.target.value.trim().length === 0;
    this.setState({
      username: e.target.value,
      usernameError: usernameError,
      password: this.state.password,
      passwordError: this.state.passwordError,
      loginError: this.state.loginError
    });
  }
  handlePasswordChange(e: any) {
    const passwordError = this.state.passwordError && e.target.value.trim().length === 0;
    this.setState({
      username: this.state.username,
      usernameError: this.state.usernameError,
      password: e.target.value,
      passwordError: passwordError,
      loginError: this.state.loginError
    });
  }
  postForm(event: any) {
    event.preventDefault();
    if ((window as any).grecaptcha) {
      (window as any).grecaptcha.reset();
    }

    const usernameError = this.state.username.trim().length === 0;
    const passwordError = this.state.password.trim().length === 0;

    if (!usernameError && !passwordError) {
      this.setState({
        loading: true
      });
      this.props.store.login(
        this.state.username, this.state.password, this.state.recaptchaResponse
      ).then((res: any) => {
        if (this.props.store.paymentTunnel.registering)
          window.location.href = '/paymenthub?p='+this.props.store.paymentTunnel.product.id;
        else
          window.location.href = '/?m=w';
      }, (err: any) => {
        if (err.status === 429) {
          this.setState({
            username: '',
            usernameError: false,
            password: '',
            passwordError: false,
            loginError: false,
            blockedError: false,
            recaptcha: true,
            recaptchaResponse: '',
            loading: false
          });
        } else if (err.status === 403) {
          this.setState({
            username: '',
            usernameError: false,
            password: '',
            passwordError: false,
            loginError: false,
            blockedError: true,
            recaptcha: false,
            recaptchaResponse: '',
            loading: false
          });
        } else {
          this.setState({
            username: '',
            usernameError: false,
            password: '',
            passwordError: false,
            loginError: true,
            blockedError: false,
            recaptcha: false,
            recaptchaResponse: '',
            loading: false
          });
        }
      });
    } else {
      this.setState({
        username: this.state.username,
        usernameError: usernameError,
        password: this.state.password,
        passwordError: passwordError,
        loginError: this.state.loginError,
        loading: false
      });
    }
  }
  fillRecaptcha(recaptchaResponse: string) {
    this.setState({
      recaptchaResponse: recaptchaResponse
    });
  }
  render() {
    const paymentTunnel = this.props.store.paymentTunnel;
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <Title title="Connexion " />
        <div className="ui text container" style={{ paddingTop: '50px', paddingBottom: '80px',  minHeight: '90vh' }}>
          <div className={(this.props.store.paymentTunnel.registering ? '' : 'centered') +' ui stackable grid'}>
          <div className="ten wide column">
              <h1 className="ui header">Connexion</h1>
            </div>
          </div>
          {(bowser.msie && Number(bowser.version) < 11) || (bowser.safari && Number(bowser.version) < 9) ?
            <div className="ui warning message">
              <div className="header">Oh non, votre navigateur n'est pas compatible !</div>
              <p>L'Atelier des auteurs repose sur des technologies modernes afin de proposer une expérience riche et fluide.</p>
              <p>Nous vous invitons à utiliser une version récente de <a target="_blank" rel="noopener noreferrer" href="https://www.mozilla.org/fr/firefox/new/">Mozilla Firefox</a> ou <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">Google Chrome</a>.</p>
            </div>
            :
            <div className={(this.props.store.paymentTunnel.registering ? '' : 'centered') +' ui stackable grid'}>
              <div className="ten wide column">
                <div className="ui very padded segment">
                  <h3 className="ui center aligned header">Je me suis inscrit(e) avec Facebook.</h3>
                  {/* !this.state.CGUNotChecked ? */
                    this.props.store.paymentTunnel.registering ?
                      <a href={"/auth/login2/facebook/"+this.props.store.paymentTunnel.product.id} className="ui facebook fluid button" >
                        <i className="fa fa-fw fa-facebook"></i> Connexion avec Facebook
                      </a>
                    :
                      <a href="/auth/login/facebook" className="ui facebook fluid button" >
                        <i className="fa fa-fw fa-facebook"></i> Connexion avec Facebook
                      </a>
                      /* <a href="/auth/login/facebook" className="ui facebook fluid button" >
                        <i className="fa fa-fw fa-facebook"></i> Connexion avec Facebook
                      </a> */
                  /* :
                    <span className="ui fluid" data-tooltip="Vous devez accepter les conditions ci-dessous pour continuer">
                      <a className="ui facebook fluid disabled button">
                        <i className="fa fa-fw fa-facebook"></i> Connexion avec Facebook
                      </a>
                    </span> */
                  }
                  {/* <br/>
                  <Checkbox
                    checked={!this.state.CGUNotChecked}
                    onClick={this.handleConfirmCGUChange}
                    label={
                      <label>Je dispose déjà d'un compte Atelier des auteurs créé à l'aide de mon compte Facebook. J'ai déjà lu et accepté les
                        <a href="/cgu" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold' }}>
                        &nbsp;Conditions Générales d'Utilisation
                        </a>.
                      </label>}
                    /> */}
                </div>
                <div className="ui horizontal divider">ou</div>
                <div className="ui very padded segment" style={{ overflow: 'auto' }}>
                  <h3 className="ui center aligned header">
                    Je me suis inscrit(e) avec un nom d'utilisateur et un mot de passe.
              </h3>
                  <form onSubmit={this.postForm} className="ui form">
                    {this.state.usernameError || this.state.passwordError ?
                      <div className="ui error message">
                        <div className="header">Les champs marqués en rouge sont obligatoires.</div>
                      </div>
                      : this.state.loginError ?
                        <div className="ui error message">
                          <div className="header">Le nom d'utilisateur n'existe pas ou le mot de passe est incorrect.</div>
                        </div>
                        : this.state.blockedError ?
                          <div className="ui error message">
                            <div className="header">Ce compte est bloqué.</div>
                          </div>
                          : this.state.recaptcha ?
                            <div className="ui error message">
                              <div className="header">
                                Pour des raisons de sécurité, nous vous invitons à remplir la "captcha" ci-dessous pour vous connecter.
                          </div>
                            </div>
                            : undefined
                    }
                    <div className={'field' + (this.state.usernameError ? ' error' : '')}>
                      <label>Nom d'utilisateur ou adresse email</label>
                      <input
                        type="text"
                        name="first-name"
                        placeholder="Nom d'utilisateur"
                        onChange={this.handleUsernameChange}
                        value={this.state.username}
                      />
                    </div>
                    <div className={'field' + (this.state.passwordError ? ' error' : '')}>
                      <label>Mot de passe</label>
                      <input
                        type="password"
                        name="last-name"
                        placeholder="Mot de passe"
                        onChange={this.handlePasswordChange}
                        value={this.state.password}
                      />
                    </div>
                    {this.state.recaptcha ?
                      <div style={{ marginBottom: '10px' }}>
                        <Recaptcha
                          sitekey="6LegPxkUAAAAAHRgtmFENQp3jhZj4RNrutj-iBSI"
                          callback={(response: string) => { this.fillRecaptcha(response); }}
                          expiredCallback={() => { }}
                          locale="fr-FR"
                        />
                      </div>
                      : undefined}
                    <Link className="ui right floated button"
                      to="/forgotpassword">
                      Mot de passe ou identifiant oublié
                </Link>
                    <button
                      className={'ui submit primary right floated ' + (this.state.loading ? ' loading' : '') + ' button'}
                      type="submit"
                      disabled={this.state.recaptcha && this.state.recaptchaResponse === ''}
                    >
                      Connexion
                    </button>
                  </form>
                </div>
                <div>Pas encore inscrit ?&nbsp;
                <a onClick={this.goToSignUp} style={{ cursor: "pointer", fontWeight: 'bold' }}>Créer un compte</a>

                </div>
              </div>
              {this.props.store.paymentTunnel.registering ?
            <div className="six wide column">
              <div className="ui segment">
                <h3 style={{paddingBottom: "5px", borderBottom: "solid 1px"}} className="ui header">{paymentTunnel.product.name}</h3>
                <div className="ui relaxed list">
                  <div className="ui two column grid">
                  <div className="item column float left">
                    <div className="content">Prix de l'article</div>
                  </div>
                  <div className="item column float right">
                    <div style={{textAlign: "right"}} className="content">{Math.round(paymentTunnel.product.currentPrice/1.2)} €</div>
                  </div>
                  <div className="ui row" style={{marginTop: "-10px"}}>
                    <div className="item column float left">
                      <div className="content">TVA <span style={{fontSize: "0.8rem"}}>20%</span></div>
                    </div>
                    <div className="item column float right">
                      <div style={{textAlign: "right"}} className="content">{Math.round(paymentTunnel.product.currentPrice/1.2*0.2)} €</div>
                    </div>
                  </div>
                  <div className="ui row ada-background-editis-beige-foncé ada-text-editis-blanc">
                    <div className="item column float left">
                      <div className="content">Total TTC</div>
                    </div>
                    <div className="item column float right">
                      <div style={{fontSize: "1.4rem", textAlign: "right"}} className="content">{paymentTunnel.product.currentPrice} €</div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : undefined}
            </div>
          }
        </div>
      </div>
    );
  }
}
