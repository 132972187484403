import { observable } from 'mobx';

export default class EditorQuestionModel {

  @observable question: string;
  @observable idx: number;
  @observable multiple: boolean;
  @observable large: boolean;
  @observable mandatory: boolean;
  @observable answers: { answer: string, selected: boolean }[];

  @observable textAnswer: string;

  constructor(question: string, idx: number, multiple: boolean, large: boolean, mandatory: boolean,
    answers: { answer: string, selected: boolean }[], textAnswer: string) {
    this.question = question;
    this.idx = idx;
    this.multiple = multiple;
    this.large = large;
    this.mandatory = mandatory;
    this.answers = answers || [];
    this.textAnswer = textAnswer;
  }

  toJS() {
    return {
      question: this.question,
      idx: this.idx,
      multiple: this.multiple,
      large: this.large,
      mandatory: this.mandatory,
      answers: this.answers,
      textAnswer: this.textAnswer
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new EditorQuestionModel(object.question, object.idx, object.multiple, object.large,
        object.mandatory, object.answers, object.textAnswer);
    }
    return undefined;
  }
}
