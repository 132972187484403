import { observable } from 'mobx';

export default class ProfileUserModel {

  @observable id: number;
  @observable identity: string;
  @observable avatar: string;
  @observable age: string;
  @observable localisation: string;
  @observable website: string;
  @observable twitter: string;
  @observable facebook: string;
  @observable backgroundColor: string;
  @observable textColor: string;
  @observable linkColor: string;

  @observable followed: boolean;
  @observable blocked: boolean;
  @observable suspended: boolean;

  @observable avatarTemp: string;
  @observable avatarFileTemp: File;

  @observable oldBackgroundColor: string;
  @observable oldTextColor: string;
  @observable oldLinkColor: string;

  @observable facebookUser: string;

  constructor(id: number, identity: string, avatar: string, age: string, localisation: string,
    website: string, twitter: string, facebook: string, backgroundColor: string, textColor: string,
    linkColor: string, followed: boolean, blocked: boolean, suspended: boolean, facebookUser: string) {
    this.id = id;
    this.identity = identity;
    this.avatar = avatar;
    this.age = age;
    this.localisation = localisation;
    this.website = website;
    this.twitter = twitter;
    this.facebook = facebook;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.linkColor = linkColor;
    this.followed = followed;
    this.blocked = blocked;
    this.suspended = suspended;

    this.oldBackgroundColor = backgroundColor;
    this.oldTextColor = textColor;
    this.oldLinkColor = linkColor;

    this.facebookUser = facebookUser;
  }

  toJS() {
    return {
      id: this.id,
      identity: this.identity,
      avatar: this.avatar,
      age: this.age,
      localisation: this.localisation,
      website: this.website,
      twitter: this.twitter,
      facebook: this.facebook,
      backgroundColor: this.backgroundColor,
      textColor: this.textColor,
      linkColor: this.linkColor,
      followed: this.followed,
      blocked: this.blocked,
      suspended: this.suspended,
      facebookUser: this.facebookUser
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ProfileUserModel(object.id, object.identity, object.avatar, object.age,
        object.localisation, object.website, object.twitter, object.facebook, object.backgroundColor,
        object.textColor, object.linkColor, object.followed, object.blocked, object.suspended,
        object.facebookUser);
    }
    return undefined;
  }

}
