import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { animateScroll } from 'react-scroll';
import * as bowser from 'bowser';
const cookie = new (require('react-cookie')).Cookies();
import ConfirmPublish from './ConfirmPublish';
import CannotPublish from './CannotPublish';
import ConfirmCancelEdition from './ConfirmCancelEdition';
import ChapterTime from './ChapterTime';
import ChapterCompare from './ChapterCompare';
import ChapterSave from './ChapterSave';
import ChapterSavePremium from './ChapterSavePremium';
import ChapterStep from './ChapterStep';
import ChapterInstructions from './ChapterInstructions';
import ChapterChecks from './ChapterChecks';
import ChapterGenerators from './ChapterGenerators';
import ChapterQuestions from './ChapterQuestions';
import ChapterCongratulationsChapter from './ChapterCongratulationsChapter';
import ChapterCongratulationsParcours from './ChapterCongratulationsParcours';
import ChapterNewPart from './ChapterNewPart';
import ChapterVideo from './ChapterVideo';
import ChapterNewChapter from './ChapterNewChapter';
import NewPartSummary from './NewPartSummary';
import CharactersList from '../../characters/CharactersList';
import ChapterDescription from './ChapterDescription';
import ChapterRemove from './ChapterRemove';
import AddThanksModal from './AddThanksModal';
import AlertNewDefi from './AlertNewDefi';
import UserModel from '../../../../../models/UserModel';
import AlertModel from '../../../../../models/AlertModel';
import QuestionModel from '../../../../../models/write/QuestionModel';
import VideoModel from '../../../../../models/write/VideoModel';
import BilanModel from '../../../../../models/write/BilanModel';
import Store from '../../../../../stores/Store';
import ChapterParams from '../../ChapterParams';
import PreventAccess from '../../../text/toolbar/PreventAccess';
import NotePad from '../../toolbar/NotePad';


interface Peripetie {
  title: string;
  perturb: string;
  action: string;
  resol: string;
}

interface ChapterToolbarProps {
  store?: Store;
  showStatistics: Function;
  showHelp: Function | any;
  params: ChapterParams;
  location: {
    state: {
      newPart: boolean,
      showCongratulationsChapter: boolean,
      showNewPart: boolean,
      newDefi: boolean
    }
  };
}

interface ChapterToolbarState {
  confirmPublishModal: boolean;
  confirmCancelEditionModal: boolean;
  cannotPublishModal: boolean;
  addThanksModal: boolean;
  usersToThank: { user: UserModel, active: boolean }[];
  descriptionActive: boolean;
  selectedChapter: number;
  showCharacters: boolean;
  showChecks: boolean;
  showChecksWarning: boolean;
  showQuestions: boolean;
  showRemove: boolean;
  canRemove: boolean;
  showInstructions: boolean;
  showNotePad: boolean;
  showPeripetie: boolean;
  peripetie: Peripetie;
  questions: QuestionModel[];
  newPart: boolean;
  showCongratulationsChapter: boolean;
  showNewPart: boolean;
  currentPartVideo: VideoModel;
  showNewChapter: boolean;
  showPartSummary: boolean;
  showCongratulationsParcours: boolean;
  parcoursBilan: BilanModel;
  newDefi: boolean;
  loading: boolean;
  activePreventAccess: boolean;
}

@inject('store') @observer
export default class ChapterToolbar extends React.Component<ChapterToolbarProps, ChapterToolbarState> {
  constructor(props: ChapterToolbarProps) {
    super(props);
    this.state = {
      confirmPublishModal: false,
      confirmCancelEditionModal: false,
      cannotPublishModal: false,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: false,
      showChecks: false,
      showChecksWarning: false,
      showQuestions: false,
      showRemove: false,
      canRemove: false,
      showInstructions: false,
      showNotePad: false,
      showPeripetie: false,
      peripetie: undefined,
      questions: [],
      newPart: false,
      showCongratulationsChapter: false,
      showNewPart: false,
      currentPartVideo: undefined,
      showNewChapter: false,
      showPartSummary: false,
      showCongratulationsParcours: false,
      parcoursBilan: undefined,
      newDefi: false,
      loading: false,
      activePreventAccess: false
    };
    this.onPublish = this.onPublish.bind(this);
    this.toggleConfirmPublish = this.toggleConfirmPublish.bind(this);
    this.confirmPublish = this.confirmPublish.bind(this);
    this.onCancelEdition = this.onCancelEdition.bind(this);
    this.publish = this.publish.bind(this);
    this.cancelAddThanks = this.cancelAddThanks.bind(this);
    this.addToThanks = this.addToThanks.bind(this);
    this.removeFromThanks = this.removeFromThanks.bind(this);
    this.toggleConfirmCancelEdition = this.toggleConfirmCancelEdition.bind(this);
    this.confirmCancelEdition = this.confirmCancelEdition.bind(this);
    this.toggleCannotPublish = this.toggleCannotPublish.bind(this);
    this.handleChangePublishStatus = this.handleChangePublishStatus.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescription = this.hideDescription.bind(this);
    this.showNotes = this.showNotes.bind(this);
    this.hideNotes = this.hideNotes.bind(this);
    this.showCharacters = this.showCharacters.bind(this);
    this.hideCharacters = this.hideCharacters.bind(this);
    this.showChecks = this.showChecks.bind(this);
    this.hideChecks = this.hideChecks.bind(this);
    this.showRemove = this.showRemove.bind(this);
    this.hideRemove = this.hideRemove.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.showInstructions = this.showInstructions.bind(this);
    this.hideInstructions = this.hideInstructions.bind(this);
    this.showPeripetie = this.showPeripetie.bind(this);
    this.hidePeripetie = this.hidePeripetie.bind(this);
    this.generatePeripetie = this.generatePeripetie.bind(this);
    this.nextChapter = this.nextChapter.bind(this);
    this.confirmNextChapter = this.confirmNextChapter.bind(this);
    this.saveQuestions = this.saveQuestions.bind(this);
    this.hideQuestions = this.hideQuestions.bind(this);
    this.handleAnswerQuestion = this.handleAnswerQuestion.bind(this);
    this.startNewChapter = this.startNewChapter.bind(this);
    this.startNewPart = this.startNewPart.bind(this);
    this.showNextVideo = this.showNextVideo.bind(this);
    this.showNewChapter = this.showNewChapter.bind(this);
    this.startNewChapter = this.startNewChapter.bind(this);
    this.hideStartNewChapter = this.hideStartNewChapter.bind(this);
    this.hideCongratulationsParcours = this.hideCongratulationsParcours.bind(this);
    this.hideAlertNewDefi = this.hideAlertNewDefi.bind(this);
    this.checkRelectureMode = this.checkRelectureMode.bind(this);
    this.enableRelectureMode = this.enableRelectureMode.bind(this);
    this.hidePreventAccess = this.hidePreventAccess.bind(this);
  }
  componentDidMount() {
    if (this.props.location.state) {
      const inited = this.props.store.write.currentText.currentChapter.inited;
      this.setState({
        confirmPublishModal: this.state.confirmPublishModal,
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: !inited && this.props.location.state.newPart,
        showCongratulationsChapter: !inited && this.props.location.state.showCongratulationsChapter,
        showNewPart: !inited && this.props.location.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan,
        newDefi: !inited && this.props.location.state.newDefi
      });
      this.props.store.write.currentText.currentChapter.inited = true;
    }
  }
  componentWillReceiveProps(nextProps: ChapterToolbarProps) {
    const nextLocState = nextProps.location.state;
    if (nextLocState
      && (!this.props.location.state ||
        ((nextLocState.showCongratulationsChapter
          && nextLocState.showCongratulationsChapter !== this.props.location.state.showCongratulationsChapter)
          || (nextLocState.showNewPart
            && nextLocState.showNewPart !== this.props.location.state.showNewPart)))
      || this.props.params.chapterId !== nextProps.params.chapterId) {
      this.setState({
        confirmPublishModal: this.state.confirmPublishModal,
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: false,
        questions: [],
        newPart: nextProps.location.state ? nextProps.location.state.newPart : false,
        showCongratulationsChapter: nextProps.location.state ?
          nextProps.location.state.showCongratulationsChapter : false,
        showNewPart: nextProps.location.state ? nextProps.location.state.showNewPart : false,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan,
        newDefi: nextProps.location.state ? nextProps.location.state.newDefi : false
      });
    }
  }
  onPublish() {
    const chapter = this.props.store.write.currentText.currentChapter;
    if (chapter.title && chapter.title.trim().length > 0
      && chapter.textContentState.getCurrentContent().getPlainText().trim().length > 0) {
      this.setState({
        confirmPublishModal: true,
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        addThanksModal: false,
        usersToThank: [],
        descriptionActive: false,
        selectedChapter: undefined,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: this.state.newPart,
        showCongratulationsChapter: this.state.showCongratulationsChapter,
        showNewPart: this.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    } else {
      this.setState({
        cannotPublishModal: true,
        confirmPublishModal: this.state.confirmPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        addThanksModal: false,
        usersToThank: [],
        descriptionActive: false,
        selectedChapter: undefined,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: this.state.newPart,
        showCongratulationsChapter: this.state.showCongratulationsChapter,
        showNewPart: this.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    }
  }
  onCancelEdition() {
    this.setState({
      confirmCancelEditionModal: true,
      confirmPublishModal: this.state.confirmPublishModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  toggleConfirmPublish() {
    this.setState({
      confirmPublishModal: false,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  confirmPublish() {
    this.props.store.loadUsersToThank().then((d: UserModel[]) => {
      if (d.length > 0) {
        this.setState({
          confirmPublishModal: false,
          confirmCancelEditionModal: this.state.confirmCancelEditionModal,
          cannotPublishModal: this.state.cannotPublishModal,
          addThanksModal: true,
          usersToThank: d.map(u => { return { user: u, active: true }; }),
          descriptionActive: false,
          selectedChapter: undefined,
          showCharacters: this.state.showCharacters,
          showChecks: this.state.showChecks,
          showChecksWarning: this.state.showChecksWarning,
          showQuestions: this.state.showQuestions,
          questions: this.state.questions,
          newPart: this.state.newPart,
          showCongratulationsChapter: this.state.showCongratulationsChapter,
          showNewPart: this.state.showNewPart,
          currentPartVideo: this.state.currentPartVideo,
          showNewChapter: this.state.showNewChapter,
          showCongratulationsParcours: this.state.showCongratulationsParcours,
          parcoursBilan: this.state.parcoursBilan
        });
      } else {
        this.publish();
      }
    });
  }
  cancelAddThanks() {
    this.setState({
      confirmPublishModal: this.state.confirmPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  addToThanks(userId: number) {
    const newUsers = this.state.usersToThank.map(u => {
      if (u.user.id === userId) {
        u.active = true;
      }
      return u;
    });
    this.setState({
      confirmPublishModal: this.state.confirmPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: newUsers,
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  removeFromThanks(userId: number) {
    const newUsers = this.state.usersToThank.map(u => {
      if (u.user.id === userId) {
        u.active = false;
      }
      return u;
    });
    this.setState({
      confirmPublishModal: this.state.confirmPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: newUsers,
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  publish() {
    this.props.store.saveChapter(false, true).then((d: any) => {
      this.props.store.publishChapter().then((d: any) => {
        const text = this.props.store.write.currentText;
        const textId = text.id;

        const usersToThank = this.state.usersToThank
          .filter(u => u.active)
          .map(u => { return { userId: u.user.id, message: undefined }; });
        if (usersToThank.length > 0) {
          this.props.store.thankAdd(textId, usersToThank).then((d: any) => {
            this.setState({
              confirmPublishModal: false,
              confirmCancelEditionModal: this.state.confirmCancelEditionModal,
              cannotPublishModal: this.state.cannotPublishModal,
              addThanksModal: false,
              usersToThank: [],
              descriptionActive: false,
              selectedChapter: undefined,
              showCharacters: this.state.showCharacters,
              showChecks: this.state.showChecks,
              showChecksWarning: this.state.showChecksWarning,
              showQuestions: this.state.showQuestions,
              questions: this.state.questions,
              newPart: this.state.newPart,
              showCongratulationsChapter: this.state.showCongratulationsChapter,
              showNewPart: this.state.showNewPart,
              currentPartVideo: this.state.currentPartVideo,
              showNewChapter: this.state.showNewChapter,
              showCongratulationsParcours: this.state.showCongratulationsParcours,
              parcoursBilan: this.state.parcoursBilan
            });
          }).then((r: any) => {
            browserHistory.push({
              pathname: `/text/${text.id}/${text.infos.urlText}/chapter/${text.currentChapter.id}`,
              state: {
                showStepModal: true
              }
            });
          });
        } else {
          browserHistory.push({
            pathname: `/text/${text.id}/${text.infos.urlText}/chapter/${text.currentChapter.id}`,
            state: {
              showStepModal: true
            }
          });
        }
      });
    });
  }
  toggleConfirmCancelEdition() {
    this.setState({
      confirmCancelEditionModal: false,
      confirmPublishModal: this.state.confirmPublishModal,
      cannotPublishModal: this.state.cannotPublishModal,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  confirmCancelEdition() {
    this.props.store.resetChapter().then((d: any) => {
      animateScroll.scrollToTop();
    });
  }
  toggleCannotPublish() {
    this.setState({
      cannotPublishModal: false,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: false,
      usersToThank: [],
      descriptionActive: false,
      selectedChapter: undefined,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  startNewPart() {
    const currentText = this.props.store.write.currentText;
    browserHistory.push({
      pathname: `/parcours/${currentText.id}/${currentText.infos.urlText}`
    }); 
  }
  showNotes() {
    const currentTextId = this.props.store.write.currentText.id;
    this.props.store.loadNotes(currentTextId).then((d: any) => {
      this.setState({
        showNotePad: true
      });
    });
  }
  hideNotes() {
    this.setState({
      showNotePad: false
    });
  }
  showNextVideo() {
    const partVideos = this.props.store.write.currentText.currentChapter.instructions.partVideos
      .filter(pv => pv.mandatory);

    let newShowPartSummary: boolean;
    let newCurrentPartVideo: VideoModel;
    if (!partVideos || partVideos.length === 0) {
      newShowPartSummary = true;
      newCurrentPartVideo = undefined;
    } else {
      const currentPartVideo = this.state.currentPartVideo;
      if (!currentPartVideo) {
        newShowPartSummary = false;
        newCurrentPartVideo = partVideos[0];
      } else {
        const idx = partVideos.map(v => v.id).indexOf(currentPartVideo.id);
        if (partVideos[idx + 1]) {
          newShowPartSummary = false;
          newCurrentPartVideo = partVideos[idx + 1];
        } else {
          newShowPartSummary = true;
          newCurrentPartVideo = undefined;
        }
      }
    }

    if (newShowPartSummary) {
      const text = this.props.store.write.currentText;

      const p: any[] = [];
      p.push(this.props.store.loadTextParcoursSummary(text.id, text.currentChapter.id));
      Promise.all(p).then((r: any) => {
        this.setState({
          cannotPublishModal: this.state.cannotPublishModal,
          confirmCancelEditionModal: this.state.confirmCancelEditionModal,
          confirmPublishModal: this.state.confirmPublishModal,
          addThanksModal: this.state.addThanksModal,
          usersToThank: this.state.usersToThank,
          descriptionActive: this.state.descriptionActive,
          selectedChapter: this.state.selectedChapter,
          showCharacters: this.state.showCharacters,
          showChecks: this.state.showChecks,
          showChecksWarning: this.state.showChecksWarning,
          showQuestions: this.state.showQuestions,
          questions: this.state.questions,
          newPart: false,
          showCongratulationsChapter: false,
          showNewPart: false,
          currentPartVideo: newCurrentPartVideo,
          showNewChapter: false,
          showPartSummary: newShowPartSummary,
          showCongratulationsParcours: this.state.showCongratulationsParcours,
          parcoursBilan: this.state.parcoursBilan
        });
      });
    } else {
      this.setState({
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        confirmPublishModal: this.state.confirmPublishModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: false,
        showCongratulationsChapter: false,
        showNewPart: false,
        currentPartVideo: newCurrentPartVideo,
        showNewChapter: false,
        showPartSummary: newShowPartSummary,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    }
  }
  showNewChapter() {
    this.setState({
      showNewChapter: true,
      showPartSummary: false
    });
  }
  startNewChapter() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: undefined,
      showCongratulationsChapter: false,
      showNewPart: false,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: true,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  hideStartNewChapter() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: undefined,
      showCongratulationsChapter: false,
      showNewPart: false,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: false,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  handleChangePublishStatus(val: string) {
    if (val === 'publish') {
      this.onPublish();
    } else {
      this.onCancelEdition();
    }
  }
  showDescription() {
    const chapterId = this.props.store.write.currentText.currentChapter.id;
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: true,
      selectedChapter: chapterId,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  hideDescription() {
    this.props.store.loadTextStructure(this.props.store.write.currentText.id).then((d: any) => {
      this.setState({
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        confirmPublishModal: this.state.confirmPublishModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: false,
        selectedChapter: undefined,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: this.state.newPart,
        showCongratulationsChapter: this.state.showCongratulationsChapter,
        showNewPart: this.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    });
  }
  showCharacters() {
    const currentTextId = this.props.store.write.currentText.id;
    const chapterIndex = this.props.store.write.currentText.currentChapter.index;
    this.props.store.loadCharacters(currentTextId, chapterIndex).then((d: any) => {
      this.setState({
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        confirmPublishModal: this.state.confirmPublishModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        showCharacters: true,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: this.state.questions,
        newPart: this.state.newPart,
        showCongratulationsChapter: this.state.showCongratulationsChapter,
        showNewPart: this.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    });
  }
  hideCharacters() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: false,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  showChecks() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: this.state.showCharacters,
      showChecks: true,
      showChecksWarning: false,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  hideChecks() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: this.state.showCharacters,
      showChecks: false,
      showChecksWarning: false,
      showQuestions: this.state.showQuestions,
      questions: this.state.questions,
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  showRemove() {
    this.props.store.canRemoveChapter().then((canRemove: boolean) => {
      this.setState({
        showRemove: true,
        canRemove: canRemove
      });
    });
  }
  hideRemove() {
    this.setState({
      showRemove: false,
      canRemove: false
    });
  }
  confirmRemove() {
    this.props.store.removeChapter().then((newChapterId: number) => {
      this.setState({
        showRemove: false,
        canRemove: false
      });
      const currentText = this.props.store.write.currentText;
      this.props.store.addAlert(new AlertModel(
        'removeChapter',
        'Chapitre supprimé !',
        '',
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/text/${currentText.id}/${currentText.infos.urlText}/chapter/${newChapterId}/edit`,
        state: {
          newPart: false,
          showCongratulationsChapter: false
        }
      });
    });
  }
  showInstructions() {
    this.setState({
      showInstructions: true
    });
  }
  hideInstructions() {
    this.setState({
      showInstructions: false
    });
  }
  showPeripetie() {
    this.props.store.generatePeripetie().then((peripetie: Peripetie) => {
      this.setState({
        showPeripetie: true,
        peripetie: peripetie
      });
    });
  }
  hidePeripetie() {
    this.setState({
      showPeripetie: false,
      peripetie: undefined
    });
  }
  generatePeripetie() {
    this.props.store.generatePeripetie().then((peripetie: Peripetie) => {
      this.setState({
        peripetie: peripetie
      });
    });
  }
  nextChapter() {
    const currentText = this.props.store.write.currentText;
    const chapter = currentText.currentChapter;

    this.setState({
      loading: true
    });

    this.props.store.saveChapter(false, true).then((d: any) => {
      if (chapter.title && chapter.title.trim().length > 0
        && chapter.textContentState.getCurrentContent().getPlainText().trim().length > 0) {
        const notChecks = chapter.checks.filter(c => c.answers.filter(a => a.checked === false).length > 0).length > 0;
        if (notChecks) {
          this.setState({
            confirmPublishModal: false,
            cannotPublishModal: this.state.cannotPublishModal,
            confirmCancelEditionModal: this.state.confirmCancelEditionModal,
            addThanksModal: false,
            usersToThank: [],
            descriptionActive: false,
            selectedChapter: undefined,
            showCharacters: this.state.showCharacters,
            showChecks: true,
            showChecksWarning: true,
            showQuestions: this.state.showQuestions,
            questions: this.state.questions,
            newPart: this.state.newPart,
            showCongratulationsChapter: this.state.showCongratulationsChapter,
            showNewPart: this.state.showNewPart,
            currentPartVideo: this.state.currentPartVideo,
            showNewChapter: this.state.showNewChapter,
            showCongratulationsParcours: this.state.showCongratulationsParcours,
            parcoursBilan: this.state.parcoursBilan,
            loading: false
          });
        } else {
          this.props.store.prenextChapter().then((r: { questions: QuestionModel[] }) => {
            if (r.questions.length > 0) {
              this.setState({
                cannotPublishModal: this.state.cannotPublishModal,
                confirmCancelEditionModal: this.state.confirmCancelEditionModal,
                confirmPublishModal: this.state.confirmPublishModal,
                addThanksModal: this.state.addThanksModal,
                usersToThank: this.state.usersToThank,
                descriptionActive: this.state.descriptionActive,
                selectedChapter: this.state.selectedChapter,
                showCharacters: this.state.showCharacters,
                showChecks: this.state.showChecks,
                showChecksWarning: this.state.showChecksWarning,
                showQuestions: true,
                questions: r.questions,
                newPart: this.state.newPart,
                showCongratulationsChapter: this.state.showCongratulationsChapter,
                showNewPart: this.state.showNewPart,
                currentPartVideo: this.state.currentPartVideo,
                showNewChapter: this.state.showNewChapter,
                showCongratulationsParcours: this.state.showCongratulationsParcours,
                parcoursBilan: this.state.parcoursBilan,
                loading: false
              });
            } else {
              this.confirmNextChapter([]);
            }
          });
        }
      } else {
        this.setState({
          cannotPublishModal: true,
          confirmPublishModal: this.state.confirmPublishModal,
          confirmCancelEditionModal: this.state.confirmCancelEditionModal,
          addThanksModal: false,
          usersToThank: [],
          descriptionActive: false,
          selectedChapter: undefined,
          showCharacters: this.state.showCharacters,
          showChecks: this.state.showChecks,
          showChecksWarning: this.state.showChecksWarning,
          showQuestions: this.state.showQuestions,
          questions: this.state.questions,
          newPart: this.state.newPart,
          showCongratulationsChapter: this.state.showCongratulationsChapter,
          showNewPart: this.state.showNewPart,
          currentPartVideo: this.state.currentPartVideo,
          showNewChapter: this.state.showNewChapter,
          showCongratulationsParcours: this.state.showCongratulationsParcours,
          parcoursBilan: this.state.parcoursBilan,
          loading: false
        });
      }
    });
  }
  saveQuestions() {
    const notAnswered = this.state.questions.filter(c => !c.answerId).length > 0;

    if (!notAnswered) {
      const answers = this.state.questions.map(q => {
        return { questionId: q.id, answerId: q.answerId };
      });
      this.confirmNextChapter(answers);
    }
  }
  @action
  confirmNextChapter(answers: { questionId: number, answerId: number }[]) {
    const currentText = this.props.store.write.currentText;

    this.props.store.nextChapter(answers).then((v:
      {
        status: { createdChapterId: number, newPart: boolean, parcoursBilan: BilanModel },
        levels: { levelType: string, week: boolean, obj: number }[]
      }) => {
      const programAlerts = this.props.store.generateProgramAlerts(v.levels);
      this.props.store.addAlerts(programAlerts);

      if (v.status && v.status.createdChapterId) {
        /* browserHistory.push({
          pathname: `/text/${currentText.id}/${currentText.infos.urlText}/chapter/${v.status.createdChapterId}/edit`,
          state: {
            newPart: v.status.newPart,
            showCongratulationsChapter: true
          }
        }); */  //old button didn't move the user from the editor to the hub
        /* browserHistory.push({
          pathname: `/parcours/${currentText.id}/${currentText.infos.urlText}`
        }); */
        this.setState({...this.state, showCongratulationsChapter: true});
      } else {
        this.props.store.write.currentText.infos.parcours.active = false;
        this.setState({
          cannotPublishModal: this.state.cannotPublishModal,
          confirmCancelEditionModal: this.state.confirmCancelEditionModal,
          confirmPublishModal: this.state.confirmPublishModal,
          addThanksModal: this.state.addThanksModal,
          usersToThank: this.state.usersToThank,
          descriptionActive: this.state.descriptionActive,
          selectedChapter: this.state.selectedChapter,
          showCharacters: this.state.showCharacters,
          showChecks: this.state.showChecks,
          showChecksWarning: this.state.showChecksWarning,
          showQuestions: this.state.showQuestions,
          questions: this.state.questions,
          newPart: this.state.newPart,
          showCongratulationsChapter: this.state.showCongratulationsChapter,
          showNewPart: this.state.showNewPart,
          currentPartVideo: this.state.currentPartVideo,
          showNewChapter: this.state.showNewChapter,
          showCongratulationsParcours: true,
          parcoursBilan: v.status.parcoursBilan,
          loading: false
        });
      }
    });
  }
  hideQuestions() {
    this.setState({
      cannotPublishModal: this.state.cannotPublishModal,
      confirmCancelEditionModal: this.state.confirmCancelEditionModal,
      confirmPublishModal: this.state.confirmPublishModal,
      addThanksModal: this.state.addThanksModal,
      usersToThank: this.state.usersToThank,
      descriptionActive: this.state.descriptionActive,
      selectedChapter: this.state.selectedChapter,
      showCharacters: this.state.showCharacters,
      showChecks: this.state.showChecks,
      showChecksWarning: this.state.showChecksWarning,
      showQuestions: false,
      questions: [],
      newPart: this.state.newPart,
      showCongratulationsChapter: this.state.showCongratulationsChapter,
      showNewPart: this.state.showNewPart,
      currentPartVideo: this.state.currentPartVideo,
      showNewChapter: this.state.showNewChapter,
      showCongratulationsParcours: this.state.showCongratulationsParcours,
      parcoursBilan: this.state.parcoursBilan
    });
  }
  handleAnswerQuestion(questionId: number, answerId: number) {
    const newQuestions = this.state.questions;
    if (newQuestions.filter(q => q.id === questionId)[0].answers.filter(a => a.id === answerId).length > 0) {
      newQuestions.filter(q => q.id === questionId)[0].answerId = answerId;
      this.setState({
        cannotPublishModal: this.state.cannotPublishModal,
        confirmCancelEditionModal: this.state.confirmCancelEditionModal,
        confirmPublishModal: this.state.confirmPublishModal,
        addThanksModal: this.state.addThanksModal,
        usersToThank: this.state.usersToThank,
        descriptionActive: this.state.descriptionActive,
        selectedChapter: this.state.selectedChapter,
        showCharacters: this.state.showCharacters,
        showChecks: this.state.showChecks,
        showChecksWarning: this.state.showChecksWarning,
        showQuestions: this.state.showQuestions,
        questions: newQuestions,
        newPart: this.state.newPart,
        showCongratulationsChapter: this.state.showCongratulationsChapter,
        showNewPart: this.state.showNewPart,
        currentPartVideo: this.state.currentPartVideo,
        showNewChapter: this.state.showNewChapter,
        showCongratulationsParcours: this.state.showCongratulationsParcours,
        parcoursBilan: this.state.parcoursBilan
      });
    }
  }
  hideCongratulationsParcours() {
    const currentText = this.props.store.write.currentText;
    browserHistory.push({
      pathname: `/parcours/${currentText.id}/${currentText.infos.urlText}`
    }); 
  }
  hideAlertNewDefi() {
    this.setState({
      newDefi: false
    });
  }
  checkRelectureMode() {
    const text = this.props.store.write.currentText;
    const cookieExists = cookie && cookie.get && cookie.get(`prevent_relecture_${text.id}`);
    if (cookieExists) {
      this.enableRelectureMode();
    } else {
      this.setState({
        activePreventAccess: true
      });
    }
  }
  enableRelectureMode() {
    const t = this;
    t.props.store.saveChapter(false, false).then((d: any) => {
      t.props.store.write.currentText.currentChapter.enableRelectureMode();
    });
  }
  hidePreventAccess() {
    this.setState({
      activePreventAccess: false
    });
  }
  render() {
    const currentText = this.props.store.write.currentText;
    const chapter = currentText.currentChapter;
    const infos = currentText.infos;
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;

    const items: JSX.Element[] = [];
    if (chapter && infos) {
      if (premium) {
        if (!(infos.parcours && infos.parcours.active))
          items.push(<Dropdown.Item key="item_1" value={1} text="Description" onClick={this.showDescription} />);
        items.push(<Dropdown.Item key="item_2" value={2} text="Personnages" onClick={this.showCharacters} />);
        items.push(<Dropdown.Item key="item_3" value={3} text="Relecture auto." onClick={this.enableRelectureMode} />);
        if (infos.parcours && infos.parcours.active) {
          items.push(<Dropdown.Item key="item_8" value={8} text="Notes" onClick={this.showNotes} />);
          items.push(<Dropdown.Item key="item_4" value={4} text="Consignes" onClick={this.showInstructions} />);
          if (chapter.checks && chapter.checks.length > 0) {
            items.push(<Dropdown.Item key="item_5" value={5} text="L'essentiel" onClick={this.showChecks} />);
          }
          if (chapter.generators && chapter.generators.length > 0) {
            items.push(<Dropdown.Item key="item_6" value={6} text="Générateur de péripeties" onClick={this.showPeripetie} />);
          }
        } else {
          items.push(<Dropdown.Item key="item_6" value={6} text="Générateur de péripeties" onClick={this.showPeripetie} />);
        }
      }
      if (!(infos.parcours && infos.parcours.active))
        items.push(<Dropdown.Item key="item_7" value={7} text="Aide" onClick={this.props.showHelp} />);
    };

    return (
      <div>
        {
          chapter && infos ?
            <div className="ui bottom fixed stackable small secondary menu chapter-bar"
              style={{ backgroundColor: '#eee', borderTop: '1px solid #ccc' }}
            >
              <ConfirmPublish
                confirmPublishModal={this.state.confirmPublishModal}
                toggleConfirmPublish={this.toggleConfirmPublish}
                confirmPublish={this.confirmPublish}
              />
              <ConfirmCancelEdition
                confirmCancelEditionModal={this.state.confirmCancelEditionModal}
                toggleConfirmCancelEdition={this.toggleConfirmCancelEdition}
                confirmCancelEdition={this.confirmCancelEdition}
              />
              <CannotPublish
                cannotPublishModal={this.state.cannotPublishModal}
                toggleCannotPublish={this.toggleCannotPublish}
              />
              {premium && infos.parcours ?
                <ChapterCongratulationsChapter
                  showCongratulationsChapter={this.state.showCongratulationsChapter}
                  startNewPart={this.startNewPart}
                />
                : undefined}
              {premium && infos.parcours && chapter.instructions ?
                <ChapterNewPart
                  instructions={chapter.instructions}
                  partName={chapter.part}
                  showNewPart={this.state.showNewPart}
                  showNextVideo={this.showNextVideo}
                />
                : undefined}
              {premium && infos.parcours ?
                <NewPartSummary
                  active={this.state.showPartSummary}
                  showNextModal={this.showNewChapter}
                />
                : undefined
              }
              {premium && infos.parcours ?
                <ChapterVideo
                  active={this.state.currentPartVideo !== undefined}
                  video={this.state.currentPartVideo}
                  close={this.showNextVideo}
                  step
                />
                : undefined}
              {premium && infos.parcours && chapter.instructions ?
                <ChapterNewChapter
                  showNewChapter={this.state.showNewChapter}
                  instructions={chapter.instructions}
                  chapterTitle={chapter.title}
                  hide={this.hideStartNewChapter}
                />
                : undefined}
              <AddThanksModal
                addThanksModal={this.state.addThanksModal}
                publish={this.publish}
                cancelAddThanks={this.cancelAddThanks}
                addToThanks={this.addToThanks}
                removeFromThanks={this.removeFromThanks}
                users={this.state.usersToThank}
              />
              {premium ?
                <ChapterDescription
                  hideModal={this.hideDescription}
                  active={this.state.descriptionActive}
                  textId={currentText.id}
                  chapterId={this.state.selectedChapter}
                />
                : undefined}
              {premium && infos.parcours ?
                <ChapterQuestions
                  active={this.state.showQuestions}
                  questions={this.state.questions}
                  close={this.hideQuestions}
                  save={this.saveQuestions}
                  handleAnswerQuestion={this.handleAnswerQuestion}
                />
                : undefined}
              {premium && infos.parcours ?
                <ChapterCongratulationsParcours
                  showCongratulationsParcours={this.state.showCongratulationsParcours}
                  bilan={this.state.parcoursBilan}
                  close={this.hideCongratulationsParcours}
                />
                : undefined}
              <AlertNewDefi
                active={this.state.newDefi}
                hide={this.hideAlertNewDefi}
              />
               {premium && infos.parcours && infos.parcours.active ?
                undefined
              :
                <div className="hide-mobile item">
                  <span>
                    Chapitre {chapter.index} /&nbsp;
                  {chapter.status === 'PUBLISHED' || chapter.status === 'INPROGRESS' ?
                      <a
                        href={`/text/${currentText.id}/${infos.urlText}/chapter/${chapter.id}`}
                        target="_blank" rel="noopener noreferrer"
                      >
                        {chapter.title.length > 10 ? chapter.title.substr(0, 10) + '...' : chapter.title}
                      </a>
                      : <span>{chapter.title && chapter.title !== '' ?
                        (chapter.title.length > 10 ? chapter.title.substr(0, 10) + '...' : chapter.title)
                        : 'Sans titre'}
                      </span>
                    }
                  </span>
                </div>
              }
              <div className="hide-mobile item">
                {premium && infos.parcours && infos.parcours.active ?
                  undefined
                :
                  <ChapterStep
                    textId={currentText.id} chapter={chapter}
                    position="top center" readonly={false}
                    readMode={false}
                  />
                }
                <ChapterTime />
              </div>
              <div className={'hide-mobile ui icon buttons item' + (bowser.msie ? ' ie' : '')}>
                {premium && !(infos.parcours && infos.parcours.active) ?
                  <button
                    onClick={this.showDescription}
                    data-tooltip="Description" data-inverted="" data-position="top center"
                    className="ui button chapter-description"
                  >
                    <i className="fa fa-info" />
                  </button>
                  : undefined}
                {premium ?
                  <button
                    onClick={this.showCharacters}
                    data-tooltip="Personnages" data-inverted="" data-position="top center"
                    className="ui button chapter-characters"
                  >
                    <i className="fa fa-users" />
                  </button>
                  : undefined}
                  {premium && infos.parcours && infos.parcours.active ?
                   <button
                    onClick={this.showNotes}
                    data-tooltip="Notes" data-inverted="" data-position="top center"
                    className="ui button chapter-characters"
                  >
                    <i className="fa fa-sticky-note-o" style={{ marginRight: '5px' }} />
                  </button>
                    : undefined}
                  {premium && infos.parcours && infos.parcours.active ?
                    <NotePad textId={currentText.id} active={this.state.showNotePad} hide={this.hideNotes} />
                    : undefined}
                  {premium ?
                    <CharactersList
                      textId={currentText.id}
                      active={this.state.showCharacters}
                      hide={this.hideCharacters}
                      chapterIndex={chapter.index}
                    />
                    : undefined}
                {premium && infos.parcours && infos.parcours.active && chapter.partInstruction ?
                  <ChapterInstructions active={this.state.showInstructions} hide={this.hideInstructions} />
                  : undefined}
                {premium && infos.parcours && infos.parcours.active && chapter.partInstruction ?
                  <div className="item">
                    <button
                      className="ui button"
                      onClick={this.showInstructions}
                      title="Consignes"
                    >
                      Consignes
                    </button>
                  </div>
                  : undefined}
                {premium && infos.parcours && infos.parcours.active && chapter.checks && chapter.checks.length > 0 ?
                  <ChapterChecks
                    active={this.state.showChecks}
                    showChecksWarning={this.state.showChecksWarning}
                    open={this.showChecks}
                    close={this.hideChecks}
                    nextChapter={this.nextChapter}
                    loading={this.state.loading}
                  />
                  : undefined}
                {premium && infos.parcours && infos.parcours.active && chapter.checks && chapter.checks.length > 0 ?
                  <div className="item">
                    <button
                      className="ui button"
                      onClick={this.showChecks}
                      title="L'essentiel"
                      data-tooltip="L'essentiel" data-inverted="" data-position="top center"
                    >
                      <i className="fa fa-fw fa-check" />
                    </button>
                  </div>
                  : undefined}
                {premium && infos.parcours && chapter.generators && chapter.generators.length > 0 ?
                  <ChapterGenerators comp={chapter.generators[0].comp} active={this.state.showPeripetie}
                    peripetie={this.state.peripetie} hide={this.hidePeripetie}
                    generatePeripetie={this.generatePeripetie} />
                  : undefined}
                {premium && !(infos.parcours && infos.parcours.active) ?
                  <ChapterGenerators active={this.state.showPeripetie}
                    peripetie={this.state.peripetie} hide={this.hidePeripetie}
                    generatePeripetie={this.generatePeripetie} />
                  : undefined}
                {premium &&
                  (!(infos.parcours && infos.parcours.active) || (infos.parcours && chapter.generators && chapter.generators.length > 0)) ?
                  <button
                    className="ui button"
                    onClick={this.showPeripetie}
                    title="Générateur de péripeties"
                    data-tooltip="Générateur de péripeties" data-inverted="" data-position="top center"
                  >
                    <i className="fa fa-fw fa-lightbulb-o" />
                  </button>
                  : undefined}
                {premium ?
                  <PreventAccess id="relecture" active={this.state.activePreventAccess}
                    goToLink={this.enableRelectureMode} hide={this.hidePreventAccess} />
                  : undefined
                }
                {premium ?
                  <button
                    className="ui button chapter-relecture"
                    onClick={this.checkRelectureMode}
                    data-tooltip="Relecture auto." data-inverted="" data-position="top center"
                    title="Relecture auto."
                  >
                    <i className="fa fa-wrench" />
                  </button>
                  : undefined}

              </div>
                {premium && infos.parcours && infos.parcours.active ?
                                
                <div className="hide-tablet item">
                  <span>
                   {chapter.part}
                   </span>
                </div>
                :
                undefined
              }
              <div className={'ui right icon buttons item' + (bowser.msie ? ' ie' : '')}>
                {chapter.status === 'PUBLISHED' ?
                  <button
                    className="ui label green button chapter-published">
                    Publié
                  </button>
                  :
                  (chapter.status === 'INPROGRESS' ?
                    <Dropdown
                      trigger={
                        <div className="ui orange label button chapter-inprogress">En cours</div>
                      }
                      pointing="bottom left"
                      className="label orange notpointing"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item key="publish" value="publish" text="Publier les modifications"
                          onClick={() => this.handleChangePublishStatus('publish')} />
                        <Dropdown.Item key="cancel" value="cancel" text="Annuler les modifications"
                          onClick={() => this.handleChangePublishStatus('cancel')} />
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    (premium && infos.parcours && infos.parcours.active ?
                      (chapter.lastChapter ?
                        <button
                          className={'ui label green  ' + (this.state.loading ? ' loading' : '') + ' button'}
                          onClick={() => this.nextChapter()}
                          style={{ cursor: 'pointer', margin: '0.5em' }}
                        >
                          Passer à la suite
                    </button>
                        : undefined
                      )
                      : <button
                        className="ui label green button chapter-publish"
                        onClick={() => this.handleChangePublishStatus('publish')}
                        style={{ cursor: 'pointer' }}
                      >
                        Publier
                  </button>
                    )
                  )
                }
                {chapter.status === 'INPROGRESS' ?
                  <ChapterCompare />
                  : undefined
                }
                {!infos.parcours || !infos.parcours.active ?
                  <ChapterRemove
                    active={this.state.showRemove}
                    open={this.showRemove}
                    cancel={this.hideRemove}
                    confirm={this.confirmRemove}
                    canRemove={this.state.canRemove}
                  />
                  : undefined}
                {premium && infos.parcours && infos.parcours.active ?
                  <ChapterSavePremium />
                :
                  <ChapterSave />
                }
                {!bowser.msie && !bowser.safari && !(infos.parcours && infos.parcours.active) ?
                  <button
                    className="ui hide-mobile button"
                    onClick={this.props.showHelp}
                    title="Aide"
                    data-tooltip="Aide" data-inverted="" data-position="top right"
                  >
                    <div className="fa fa-fw fa-question-circle" />
                  </button>
                  : undefined}
                <Dropdown
                  trigger={<div className="ui label button nohover" style={{ backgroundColor: '#E0E1E2' }}>
                    <i className="fa fa-fw fa-plus" title="Plus" />
                  </div>}
                  pointing="bottom right" className="hide-large label notpointing"
                  style={{ marginLeft: 0, backgroundColor: '#E0E1E2' }}>
                  <Dropdown.Menu>
                    {items}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            : null
        }
      </div >
    );
  }
}
