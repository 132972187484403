import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';

interface MultipleAuthorsBioModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  title: string;
  content: string;
}


@inject('store') @observer
export default class MultipleAuthorsBioModal
  extends React.Component<MultipleAuthorsBioModalProps, {}> {
  constructor(props: MultipleAuthorsBioModalProps) {
    super(props);
    
  }
  
  render() {
    
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">{this.props.title}</div>
          <div dangerouslySetInnerHTML={{ __html: this.props.content }} className="content" style={{overflowY: "scroll", maxHeight: '400px' }}>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      );
    
  
   
  }
}
