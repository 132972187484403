import * as React from 'react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { browserHistory } from 'react-router';
import LightStructureModel from '../../models/text/LightStructureModel';
import LightChapterModel from '../../models/text/LightChapterModel';

interface StructureListProps {
  textId: number;
  urlText: string;
  title: string;
  userIdentity: string;
  structure: LightStructureModel;
}

export default class StructureList extends React.Component<StructureListProps, {}> {
  chapterRead(chapter: LightChapterModel) {
    return chapter.read !== undefined && chapter.read !== null ?
      (chapter.read === true ?
        '(lu)'
        : '(non lu)'
      )
      : '';
  }
  render() {
    const structure = this.props.structure;
    let items: JSX.Element[] = [];

    items.push(<Dropdown.Item
      key="structure_back"
      text="Retour à l'œuvre"
      value="structure_back"
      onClick={() => browserHistory.push({
        pathname: `/text/${this.props.textId}/${this.props.urlText}`
      })}
    />);
    items.push(<Dropdown.Divider key={Math.random() * 1000} />);
    if (structure.parts && structure.parts.length > 0) {
      structure.parts.forEach(part => {
        items.push(<Dropdown.Header key={`structure_${Math.random() * 1000}`} content={part.title} />);
        part.chapters.forEach(chapter => {
          items.push(<Dropdown.Item
            key={`structure_${chapter.id}`}
            text={chapter.title}
            description={this.chapterRead(chapter)}
            value={`structure_${chapter.id}`}
            onClick={() => browserHistory.push({
              pathname: `/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}`
            })}
          />);
        });
      });
    } else {
      structure.chapters.forEach(chapter => {
        items.push(<Dropdown.Item
          key={`structure_${chapter.id}`}
          text={chapter.title}
          description={this.chapterRead(chapter)}
          value={`structure_${chapter.id}`}
          onClick={() => browserHistory.push({
            pathname: `/text/${this.props.textId}/${this.props.urlText}/chapter/${chapter.id}`
          })}
        />);
      });
    }

    return (
      <Dropdown
        scrolling={true}
        fluid
        className="small"
        icon={null}
        trigger={
          <span style={{ cursor: 'pointer' }}>
            <div style={{ fontSize: '1.5em' }}>
              {this.props.title}
              <i className="fa fa-fw fa-caret-down" />
            </div>
            <div>de {this.props.userIdentity}</div>
          </span>
        }
      >
        <Dropdown.Menu>
          {items}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

