import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';

interface ConfirmAbandonDefiProps {
  store?: Store;
  active: boolean;
  confirm: Function | any;
  cancel: Function | any;
}

@inject('store') @observer
export default class ConfirmAbandonDefi extends React.Component<ConfirmAbandonDefiProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          Confirmez-vous l'abandon de ce défi ?
        </div>
        <div className="actions">
          <button
            className="ui primary approve button" onClick={this.props.confirm}
          >
            Oui
          </button>
          <button className="ui button" onClick={this.props.cancel}>
            Non
          </button>
        </div>
      </Modal>
    );
  }
}
