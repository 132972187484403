import { observable } from 'mobx';
import ProfileUserModel from './ProfileUserModel';
import QuestionModel from './QuestionModel';
import FullTextModel from '../FullTextModel';
import UserModel from '../UserModel';
import BadgeModel from '../BadgeModel';
import DefiModel from '../DefiModel';
import ProfileRelationsModel from './ProfileRelationsModel';
import StatisticsModel from './StatisticsModel';
import FeedListModel from '../feed/FeedListModel';
import ReadingListModel from '../readinglist/ReadingListModel';
import TalkModel from '../talk/TalkModel';
import ProfileHeatMap from './ProfileHeatMap';
import ProfileHeatMapDetail from './ProfileHeatMapDetail';

export default class ProfileModel {

  id: number;
  @observable user: ProfileUserModel;
  @observable description: string;
  @observable questionnaire: QuestionModel[];
  @observable texts: FullTextModel[];
  @observable countAbonnes: number;
  @observable abonnes: UserModel[];
  @observable countAbonnements: number;
  @observable abonnements: UserModel[];
  @observable statistics: StatisticsModel;
  @observable badges: BadgeModel[];
  @observable proposedDefis: DefiModel[];
  @observable heatMap: ProfileHeatMap[];
  @observable heatMapDetail: ProfileHeatMapDetail;
  @observable feed: FeedListModel;
  @observable lists: ReadingListModel[];
  @observable talks: TalkModel[];
  @observable relations: ProfileRelationsModel;

  @observable loading: boolean;
  @observable page: number;
  @observable end: boolean;
  @observable action: string;
  @observable period: string;
  @observable beingDeleted: boolean;


  constructor(id: number, user: ProfileUserModel, description: string,
    questionnaire: QuestionModel[], texts: FullTextModel[], countAbonnes: number, abonnes: UserModel[],
    countAbonnements: number, abonnements: UserModel[], statistics: StatisticsModel, badges: BadgeModel[],
    proposedDefis: DefiModel[], heatMap: ProfileHeatMap[], heatMapDetail: ProfileHeatMapDetail,
    feed: FeedListModel, lists: ReadingListModel[], talks: TalkModel[],
    relations: ProfileRelationsModel, loading: boolean, page: number, end: boolean, action: string,
    period: string, beingDeleted:boolean) {
    this.id = id;
    this.user = user;
    this.description = description;
    this.questionnaire = questionnaire || [];
    this.texts = texts || [];
    this.countAbonnes = countAbonnes;
    this.abonnes = abonnes || [];
    this.countAbonnements = countAbonnements;
    this.abonnements = abonnements || [];
    this.statistics = statistics;
    this.badges = badges || [];
    this.proposedDefis = proposedDefis || [];
    this.heatMap = heatMap || [];
    this.heatMapDetail = heatMapDetail;
    this.feed = feed;
    this.lists = lists || [];
    this.talks = talks || [];
    this.relations = relations;
    this.loading = loading;
    this.page = page;
    this.end = end;
    this.beingDeleted = beingDeleted;
    this.action = action;
    this.period = period;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  setProfileMain(id: number, user: ProfileUserModel) {
    this.id = id;
    this.user = user;
  }

  setProfileAuthor(description: string, questionnaire: QuestionModel[],
    statistics: StatisticsModel, talks: TalkModel[], badges: BadgeModel[]) {
    this.description = description;
    this.questionnaire = questionnaire;
    this.statistics = statistics;
    this.talks = talks;
    this.badges = badges;
  }

  setProfileTexts(texts: FullTextModel[]) {
    if (this.page === 0) {
      // New
      this.texts = texts;
      this.page = 1;
    } else {
      this.texts = this.texts.concat(texts);
      this.page = this.page + 1;
    }

    this.end = texts.length < 12;
  }

  setProfileSocial(countAbonnes: number, abonnes: UserModel[],
    countAbonnements: number, abonnements: UserModel[]) {
    this.countAbonnes = countAbonnes;
    this.abonnes = abonnes;
    this.countAbonnements = countAbonnements;
    this.abonnements = abonnements;
  }

  setProfileRelations(profileRelations: ProfileRelationsModel) {
    this.relations = profileRelations;
  }

  setProfileLists(lists: ReadingListModel[]) {
    this.lists = lists;
  }

  setActivity(badges: BadgeModel[], proposedDefis: DefiModel[],
    heatMap: ProfileHeatMap[]) {
    this.badges = badges;
    this.proposedDefis = proposedDefis;
    this.heatMap = heatMap;
  }

  setStatistics(statistics: StatisticsModel) {
    this.statistics = statistics;
  }

  setHeatMapDetail(heatMapDetail: ProfileHeatMapDetail) {
    this.heatMapDetail = heatMapDetail;
  }

  setFeed(feed: FeedListModel) {
    this.feed = feed;
  }

  fillFeed(feed: FeedListModel) {
    this.feed.elements = this.feed.elements.concat(feed.elements);
    this.feed.empty = feed.empty;
    this.feed.beforeDate = feed.beforeDate;
  }

  setLists(lists: any) {
    this.lists = lists;
  }

  toJS() {
    return {
      id: this.id,
      user: this.user.toJS(),
      description: this.description,
      questionnaire: this.questionnaire.map(q => q.toJS()),
      texts: this.texts.map(t => t.toJS()),
      countAbonnes: this.countAbonnes,
      abonnes: this.abonnes.map(a => a.toJS()),
      countAbonnements: this.countAbonnements,
      abonnements: this.abonnements.map(a => a.toJS()),
      statistics: this.statistics,
      badges: this.badges.map(b => b.toJS()),
      proposedDefis: this.proposedDefis.map(b => b.toJS()),
      heatMap: this.heatMap.map(h => h.toJS()),
      heatMapDetail: this.heatMapDetail.toJS,
      feed: this.feed.toJS(),
      lists: this.lists.map(l => l.toJS()),
      talks: this.talks.map(t => t.toJS()),
      relations: this.relations.toJS(),
      loading: this.loading,
      page: this.page,
      end: this.end,
      beingDeleted: this.beingDeleted,
      action: this.action,
      period: this.period
    };
  }

  static fromJS(object: any) {
    if (object) {
      const texts = object.texts.map((t: any) => FullTextModel.fromJS(t));
      const questions = object.questionnaire.map((q: any) => QuestionModel.fromJS(q));
      const abonnes = object.abonnes.map((u: any) => UserModel.fromJS(u));
      const abonnements = object.abonnements.map((u: any) => UserModel.fromJS(u));
      const badges = object.badges.map((b: any) => BadgeModel.fromJS(b));
      const proposedDefis = object.proposedDefis.map((d: any) => DefiModel.fromJS(d));
      const heatMap = object.heatMap.map((h: any) => ProfileHeatMap.fromJS(h));
      const feed = FeedListModel.fromJS(object.feed);
      const lists = object.lists.map((l: any) => ReadingListModel.fromJS(l));
      const talks = object.talks.map((t: any) => TalkModel.fromJS(t));

      return new ProfileModel(object.id, ProfileUserModel.fromJS(object.user),
        object.description, questions, texts, object.countAbonnes, abonnes, object.countAbonnements,
        abonnements, StatisticsModel.fromJS(object.statistics),
        badges, proposedDefis, heatMap, ProfileHeatMapDetail.fromJS(object.heatMapDetail),
        feed, lists, talks, ProfileRelationsModel.fromJS(object.relations), object.loading,
        object.page, object.end,
        object.action, object.period, object.beingDeleted);
    }
    return undefined;
  }
}
