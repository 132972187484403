import * as React from 'react';
import { inject, observer } from 'mobx-react';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const MegadraftIcons = Megadraft.MegadraftIcons;
import UnderlineIcon from '../../../../editor/UnderlineIcon';
import StrikeIcon from '../../../../editor/StrikeIcon';
import TiretIcon from '../../../../editor/TiretIcon';
import H1Icon from '../../../../editor/H1Icon';
import H2Icon from '../../../../editor/H2Icon';
import AlignLeftIcon from '../../../../editor/AlignLeftIcon';
import AlignCenterIcon from '../../../../editor/AlignCenterIcon';
import AlignRightIcon from '../../../../editor/AlignRightIcon';
import AlignJustifyIcon from '../../../../editor/AlignJustifyIcon';
import styleMap from '../../../../editor/styleMap';
import blockStyles from '../../../../editor/blockStyles';
import Store from '../../../../../stores/Store';

const actions = [
  { type: 'inline', label: 'B', style: 'BOLD', icon: MegadraftIcons.BoldIcon },
  { type: 'inline', label: 'I', style: 'ITALIC', icon: MegadraftIcons.ItalicIcon },
  { type: 'inline', label: 'U', style: 'UNDERLINE', icon: UnderlineIcon },
  { type: 'inline', label: 'S', style: 'STRIKETHROUGH', icon: StrikeIcon },
  { type: 'separator' },
  { type: 'block', label: 'UL', style: 'unordered-list-item', icon: TiretIcon },
  { type: 'separator' },
  { type: 'block', label: 'H1', style: 'header-one', icon: H1Icon },
  { type: 'block', label: 'H2', style: 'header-two', icon: H2Icon },
  { type: 'separator' },
  { type: 'block', label: 'LEFT', style: 'align-left', icon: AlignLeftIcon },
  { type: 'block', label: 'CENTER', style: 'align-center', icon: AlignCenterIcon },
  { type: 'block', label: 'RIGHT', style: 'align-right', icon: AlignRightIcon },
  { type: 'block', label: 'JUSTIFY', style: 'align-justify', icon: AlignJustifyIcon },
];

interface TextEditorProps {
  store?: Store;
}

@inject('store') @observer
export default class TextEditor extends React.Component<TextEditorProps, {}> {

  keyBindings: any[];

  constructor(props: TextEditorProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }
  onChange(value: any) {
    this.props.store.write.currentText.currentChapter.updateTextContentState(value);
  }
  render() {
    return (
      <div className="ui read text container write-editor" style={{ padding: '20px 0px 90vh 0px' }}>
        <MegadraftEditor
          editorState={this.props.store.write.currentText.currentChapter.textContentState}
          actions={actions}
          placeholder="Votre texte"
          onChange={this.onChange}
          keyBindings={this.keyBindings}
          sidebarRendererFn={() => { return <div />; }}
          customStyleMap={styleMap}
          blockStyles={blockStyles}
          spellcheck={false}
        />
      </div>
    );
  }
}
