const adverbs = [
  'abjectement',
  'abominablement',
  'abondamment',
  'abruptement',
  'absolument',
  'abstraitement',
  'absurdement',
  'abusivement',
  'académiquement',
  'accessoirement',
  'accidentellement',
  'âcrement',
  'acrimonieusement',
  'acrobatiquement',
  'activement',
  'actuellement',
  'adéquatement',
  'administrativement',
  'admirablement',
  'admirativement',
  'adorablement',
  'adroitement',
  'affablement',
  'affectivement',
  'affectueusement',
  'affirmativement',
  'affreusement',
  'agilement',
  'agréablement',
  'agressivement',
  'aigrement',
  'aimablement',
  'aisément',
  'aléatoirement',
  'alertement',
  'algébriquement',
  'allégoriquement',
  'allégrement',
  'allègrement',
  'allusivement',
  'alphabétiquement',
  'alternativement',
  'altièrement',
  'ambigument',
  'ambitieusement',
  'amèrement',
  'amicalement',
  'amoureusement',
  'amplement',
  'anachroniquement',
  'analytiquement',
  'anaphoriquement',
  'anarchiquement',
  'anatomiquement',
  'ancestralement',
  'anciennement',
  'anecdotiquement',
  'angéliquement',
  'animalement',
  'annuellement',
  'anonymement',
  'anormalement',
  'antérieurement',
  'anthropologiquement',
  'anticonstitutionnellement',
  'anxieusement',
  'apparemment',
  'approbativement',
  'approximativement',
  'âprement',
  'arbitrairement',
  'architecturalement',
  'ardemment',
  'ardûment',
  'aristocratiquement',
  'arithmétiquement',
  'arrogamment',
  'artificiellement',
  'artisanalement',
  'artistement',
  'artistiquement',
  'assidûment',
  'assurément',
  'astrologiquement',
  'astronomiquement',
  'astucieusement',
  'asymétriquement',
  'atrocement',
  'attentivement',
  'aucunement',
  'audacieusement',
  'austèrement',
  'authentiquement',
  'automatiquement',
  'autoritairement',
  'autrement',
  'avantageusement',
  'avarement',
  'avaricieusement',
  'aveuglément',
  'avidement',
  'balistiquement',
  'banalement',
  'barbarement',
  'bassement',
  'béatement',
  'bellement',
  'belliqueusement',
  'bénévolement',
  'bénignement',
  'benoîtement',
  'bestialement',
  'bêtement',
  'bibliquement',
  'bienheureusement',
  'bienveillamment',
  'bigrement',
  'bilatéralement',
  'biochimiquement',
  'biologiquement',
  'bizarrement',
  'bonassement',
  'bonnement',
  'botaniquement',
  'bouffonnement',
  'bougrement',
  'bourgeoisement',
  'bravement',
  'brièvement',
  'brillamment',
  'brusquement',
  'brutalement',
  'bruyamment',
  'budgétairement',
  'bureaucratiquement',
  'câlinement',
  'calligraphiquement',
  'calmement',
  'canaillement',
  'candidement',
  'canoniquement',
  'capitalement',
  'capricieusement',
  'carrément',
  'catastrophiquement',
  'catégoriquement',
  'catholiquement',
  'cauteleusement',
  'cavalièrement',
  'cellulairement',
  'censément',
  'cérébralement',
  'cérémonieusement',
  'certainement',
  'chaleureusement',
  'chaotiquement',
  'charitablement',
  'charnellement',
  'chastement',
  'chaudement',
  'chèrement',
  'chétivement',
  'chevaleresquement',
  'chichement',
  'chimériquement',
  'chimiquement',
  'chiquement',
  'chirurgicalement',
  'chouettement',
  'chrétiennement',
  'chroniquement',
  'chronologiquement',
  'cinématographiquement',
  'cinquièmement',
  'circulairement',
  'civilement',
  'clairement',
  'clandestinement',
  'classiquement',
  'cliniquement',
  'cocassement',
  'coléreusement',
  'collectivement',
  'comiquement',
  'comment',
  'commercialement',
  'commodément',
  'communément',
  'comparativement',
  'complaisamment',
  'complémentairement',
  'complètement',
  'compulsivement',
  'conceptuellement',
  'concrètement',
  'concurremment',
  'conditionnellement',
  'confidentiellement',
  'conformément',
  'confortablement',
  'confraternellement',
  'confusément',
  'congénitalement',
  'congrûment',
  'conjointement',
  'conjugalement',
  'connement',
  'consciemment',
  'consciencieusement',
  'consécutivement',
  'conséquemment',
  'considérablement',
  'constamment',
  'constitutionnellement',
  'contemplativement',
  'continuellement',
  'continûment',
  'contractuellement',
  'contradictoirement',
  'contrairement',
  'convenablement',
  'conventionnellement',
  'conventuellement',
  'convulsivement',
  'copieusement',
  'coquettement',
  'coquinement',
  'cordialement',
  'correctement',
  'corrélativement',
  'cosmiquement',
  'coupablement',
  'courageusement',
  'couramment',
  'courtement',
  'courtoisement',
  'coûteusement',
  'coutumièrement',
  'craintivement',
  'crânement',
  'crapuleusement',
  'criminellement',
  'crucialement',
  'cruellement',
  'crûment',
  'culturellement',
  'cupidement',
  'curieusement',
  'cycliquement',
  'cyniquement',
  'dangereusement',
  'débilement',
  'décemment',
  'décidément',
  'décisivement',
  'dédaigneusement',
  'défavorablement',
  'défectueusement',
  'défensivement',
  'définitivement',
  'dégueulassement',
  'délibérément',
  'délicatement',
  'délicieusement',
  'déloyalement',
  'démesurément',
  'démocratiquement',
  'démographiquement',
  'démoniaquement',
  'démonstrativement',
  'déplorablement',
  'déraisonnablement',
  'dérisoirement',
  'dernièrement',
  'désagréablement',
  'désastreusement',
  'désespérément',
  'désordonnément',
  'déterminément',
  'détestablement',
  'deuxièmement',
  'dévotement',
  'dévotieusement',
  'dextrement',
  'diablement',
  'diaboliquement',
  'diagonalement',
  'diamétralement',
  'didactiquement',
  'différemment',
  'difficilement',
  'diffusément',
  'dignement',
  'diligemment',
  'diplomatiquement',
  'directement',
  'discrètement',
  'disgracieusement',
  'dispendieusement',
  'disproportionnément',
  'distinctement',
  'distraitement',
  'diversement',
  'divinement',
  'dixièmement',
  'docilement',
  'doctement',
  'domestiquement',
  'doublement',
  'doucement',
  'doucereusement',
  'doucettement',
  'douillettement',
  'douloureusement',
  'douteusement',
  'douzièmement',
  'dramatiquement',
  'drastiquement',
  'droitement',
  'drôlement',
  'dubitativement',
  'dûment',
  'durablement',
  'durement',
  'écologiquement',
  'économiquement',
  'effectivement',
  'efficacement',
  'effrayamment',
  'effrontément',
  'effroyablement',
  'également',
  'égoïstement',
  'électivement',
  'électoralement',
  'électriquement',
  'électroniquement',
  'élégamment',
  'éloquemment',
  'éminemment',
  'émotionnellement',
  'emphatiquement',
  'empiriquement',
  'énergiquement',
  'enfantinement',
  'énigmatiquement',
  'ennuyeusement',
  'énormément',
  'entièrement',
  'épaissement',
  'épatamment',
  'éperdument',
  'éphémèrement',
  'épisodiquement',
  'épouvantablement',
  'équitablement',
  'espièglement',
  'essentiellement',
  'esthétiquement',
  'éternellement',
  'éthiquement',
  'ethniquement',
  'étonnamment',
  'étourdiment',
  'étrangement',
  'étroitement',
  'étymologiquement',
  'euphoniquement',
  'évasivement',
  'éventuellement',
  'évidemment',
  'exactement',
  'exagérément',
  'excellemment',
  'exceptionnellement',
  'excessivement',
  'exclusivement',
  'exécrablement',
  'exemplairement',
  'exhaustivement',
  'exotiquement',
  'expérimentalement',
  'expertement',
  'explicitement',
  'exponentiellement',
  'expressément',
  'expressivement',
  'exquisément',
  'extatiquement',
  'extérieurement',
  'extraordinairement',
  'extrêmement',
  'fabuleusement',
  'facétieusement',
  'fâcheusement',
  'facilement',
  'fadement',
  'faiblement',
  'fallacieusement',
  'fameusement',
  'familièrement',
  'fanatiquement',
  'fantasmatiquement',
  'fantasquement',
  'fantastiquement',
  'fantomatiquement',
  'farouchement',
  'fastidieusement',
  'fastueusement',
  'fatalement',
  'faussement',
  'favorablement',
  'fébrilement',
  'féeriquement',
  'félinement',
  'fermement',
  'férocement',
  'fervemment',
  'fichtrement',
  'fictivement',
  'fidèlement',
  'fielleusement',
  'fièrement',
  'fiévreusement',
  'figurativement',
  'figurément',
  'filialement',
  'finalement',
  'financièrement',
  'finement',
  'fiscalement',
  'fixement',
  'flegmatiquement',
  'fluidement',
  'follement',
  'foncièrement',
  'fonctionnellement',
  'fondamentalement',
  'forcément',
  'formellement',
  'formidablement',
  'fortement',
  'fortuitement',
  'fougueusement',
  'foutrement',
  'foutument',
  'fragilement',
  'fraîchement',
  'franchement',
  'fraternellement',
  'frauduleusement',
  'frénétiquement',
  'fréquemment',
  'frileusement',
  'frivolement',
  'froidement',
  'frugalement',
  'fugacement',
  'fugitivement',
  'funestement',
  'furieusement',
  'furtivement',
  'futilement',
  'gaiement',
  'gaillardement',
  'gaîment',
  'galamment',
  'gauchement',
  'gauloisement',
  'généralement',
  'généreusement',
  'génétiquement',
  'génialement',
  'gentiment',
  'géographiquement',
  'géologiquement',
  'géométriquement',
  'glacialement',
  'glauquement',
  'globalement',
  'glorieusement',
  'gloutonnement',
  'goulûment',
  'gourmandement',
  'gracieusement',
  'graduellement',
  'grammaticalement',
  'grandement',
  'grandiosement',
  'grassement',
  'gratuitement',
  'gravement',
  'grièvement',
  'grossièrement',
  'grotesquement',
  'habilement',
  'habituellement',
  'haineusement',
  'hardiment',
  'hargneusement',
  'harmonieusement',
  'harmoniquement',
  'hasardeusement',
  'hâtivement',
  'hautainement',
  'hautement',
  'hebdomadairement',
  'hébraïquement',
  'héréditairement',
  'hermétiquement',
  'héroïquement',
  'heureusement',
  'hideusement',
  'hiérarchiquement',
  'hiératiquement',
  'historiquement',
  'honnêtement',
  'honorablement',
  'honteusement',
  'horizontalement',
  'horriblement',
  'hostilement',
  'huitièmement',
  'humainement',
  'humblement',
  'humidement',
  'hygiéniquement',
  'hyperboliquement',
  'hypnotiquement',
  'hypocritement',
  'hypothétiquement',
  'hystériquement',
  'idéalement',
  'identiquement',
  'idéologiquement',
  'idiotement',
  'ignoblement',
  'ignominieusement',
  'illégalement',
  'illégitimement',
  'illicitement',
  'illisiblement',
  'illogiquement',
  'illusoirement',
  'illustrement',
  'imaginairement',
  'imbécilement',
  'immanquablement',
  'immédiatement',
  'immensément',
  'immodérément',
  'immoralement',
  'immuablement',
  'impalpablement',
  'imparablement',
  'impardonnablement',
  'imparfaitement',
  'impartialement',
  'impassiblement',
  'impatiemment',
  'impeccablement',
  'impérativement',
  'imperceptiblement',
  'impérieusement',
  'impersonnellement',
  'impertinemment',
  'imperturbablement',
  'impétueusement',
  'impitoyablement',
  'implacablement',
  'implicitement',
  'impoliment',
  'importunément',
  'imprévisiblement',
  'improprement',
  'imprudemment',
  'impudemment',
  'impudiquement',
  'impulsivement',
  'impunément',
  'inadéquatement',
  'inavouablement',
  'incessamment',
  'incidemment',
  'inclusivement',
  'incoerciblement',
  'incommensurablement',
  'incommodément',
  'incomparablement',
  'incomplètement',
  'incompréhensiblement',
  'inconcevablement',
  'inconditionnellement',
  'inconfortablement',
  'incongrûment',
  'inconsciemment',
  'inconsidérément',
  'incontestablement',
  'incorrectement',
  'incorrigiblement',
  'incroyablement',
  'incurablement',
  'indécemment',
  'indécrottablement',
  'indéfectiblement',
  'indéfiniment',
  'indéfinissablement',
  'indéniablement',
  'indépendamment',
  'indescriptiblement',
  'indéterminément',
  'indiciblement',
  'indifféremment',
  'indignement',
  'indirectement',
  'indiscrètement',
  'indiscutablement',
  'indissolublement',
  'indistinctement',
  'individuellement',
  'indivisément',
  'indolemment',
  'indubitablement',
  'indûment',
  'industriellement',
  'industrieusement',
  'inébranlablement',
  'ineffablement',
  'ineffaçablement',
  'inégalement',
  'inélégamment',
  'inéluctablement',
  'ineptement',
  'inépuisablement',
  'inespérément',
  'inévitablement',
  'inexactement',
  'inexorablement',
  'inexplicablement',
  'inexprimablement',
  'inexpugnablement',
  'inextricablement',
  'infailliblement',
  'infantilement',
  'infatigablement',
  'infectement',
  'inférieurement',
  'infernalement',
  'infiniment',
  'infinitésimalement',
  'inflexiblement',
  'informatiquement',
  'ingénieusement',
  'ingénument',
  'inglorieusement',
  'ingratement',
  'inhabituellement',
  'inhumainement',
  'inimaginablement',
  'inimitablement',
  'initialement',
  'injurieusement',
  'injustement',
  'inlassablement',
  'innocemment',
  'innombrablement',
  'inopinément',
  'inopportunément',
  'inoubliablement',
  'insatiablement',
  'insensément',
  'insensiblement',
  'inséparablement',
  'insidieusement',
  'insolemment',
  'insolitement',
  'insondablement',
  'insoucieusement',
  'instamment',
  'instantanément',
  'instinctivement',
  'insuffisamment',
  'insupportablement',
  'intarissablement',
  'intégralement',
  'intellectuellement',
  'intelligemment',
  'intelligiblement',
  'intempestivement',
  'intensément',
  'intensivement',
  'intentionnellement',
  'intérieurement',
  'interminablement',
  'internationalement',
  'interrogativement',
  'intimement',
  'intolérablement',
  'intrépidement',
  'intrinsèquement',
  'intuitivement',
  'inutilement',
  'invariablement',
  'inversement',
  'invinciblement',
  'invisiblement',
  'involontairement',
  'invraisemblablement',
  'ironiquement',
  'irrationnellement',
  'irréductiblement',
  'irréellement',
  'irréfutablement',
  'irrégulièrement',
  'irrémédiablement',
  'irréparablement',
  'irréprochablement',
  'irrésistiblement',
  'irrésolument',
  'irrespectueusement',
  'irrévérencieusement',
  'irréversiblement',
  'irrévocablement',
  'isolément',
  'jalousement',
  'jeunement',
  'joliment',
  'journellement',
  'jovialement',
  'joyeusement',
  'judiciairement',
  'judicieusement',
  'juridiquement',
  'justement',
  'laborieusement',
  'lâchement',
  'laconiquement',
  'laidement',
  'lamentablement',
  'langoureusement',
  'languissamment',
  'largement',
  'lascivement',
  'latéralement',
  'légalement',
  'légèrement',
  'légitimement',
  'lentement',
  'lestement',
  'libéralement',
  'librement',
  'licitement',
  'linéairement',
  'linguistiquement',
  'lisiblement',
  'littérairement',
  'littéralement',
  'localement',
  'logiquement',
  'lointainement',
  'longitudinalement',
  'longuement',
  'louablement',
  'lourdement',
  'loyalement',
  'lucidement',
  'lugubrement',
  'lumineusement',
  'luxueusement',
  'machinalement',
  'magiquement',
  'magistralement',
  'magnétiquement',
  'magnifiquement',
  'maigrement',
  'majestueusement',
  'majoritairement',
  'maladivement',
  'maladroitement',
  'malaisément',
  'malencontreusement',
  'malhabilement',
  'malheureusement',
  'malhonnêtement',
  'malicieusement',
  'malignement',
  'malproprement',
  'malsainement',
  'maniaquement',
  'manifestement',
  'manuellement',
  'marginalement',
  'maritalement',
  'massivement',
  'matériellement',
  'maternellement',
  'mathématiquement',
  'matinalement',
  'maussadement',
  'mauvaisement',
  'mécaniquement',
  'méchamment',
  'médicalement',
  'médiocrement',
  'méditativement',
  'mélancoliquement',
  'mélodieusement',
  'mêmement',
  'mensongèrement',
  'mensuellement',
  'mentalement',
  'méprisamment',
  'merveilleusement',
  'mesquinement',
  'métaphoriquement',
  'métaphysiquement',
  'météoriquement',
  'méthodiquement',
  'méticuleusement',
  'microscopiquement',
  'mièvrement',
  'mignardement',
  'mignonnement',
  'militairement',
  'minablement',
  'minutieusement',
  'miraculeusement',
  'misérablement',
  'miséricordieusement',
  'miteusement',
  'modérément',
  'modestement',
  'modiquement',
  'moelleusement',
  'moindrement',
  'mollement',
  'momentanément',
  'mondainement',
  'mondialement',
  'monotonement',
  'monstrueusement',
  'monumentalement',
  'moqueusement',
  'moralement',
  'morbidement',
  'mornement',
  'morosement',
  'mortellement',
  'moyennement',
  'muettement',
  'mûrement',
  'musicalement',
  'mutuellement',
  'mystérieusement',
  'mystiquement',
  'naïvement',
  'narcissiquement',
  'narquoisement',
  'nationalement',
  'naturellement',
  'nécessairement',
  'négativement',
  'négligemment',
  'nerveusement',
  'nettement',
  'neurologiquement',
  'neuvièmement',
  'niaisement',
  'nigaudement',
  'noblement',
  'nominalement',
  'nommément',
  'nonchalamment',
  'normalement',
  'nostalgiquement',
  'notablement',
  'notamment',
  'notoirement',
  'nouvellement',
  'nuitamment',
  'nullement',
  'nûment',
  'numériquement',
  'objectivement',
  'obligatoirement',
  'obligeamment',
  'obliquement',
  'obscènement',
  'obscurément',
  'obséquieusement',
  'obsessionnellement',
  'obstinément',
  'occasionnellement',
  'occultement',
  'odieusement',
  'offensivement',
  'officiellement',
  'officieusement',
  'oisivement',
  'ombrageusement',
  'onctueusement',
  'ontologiquement',
  'onzièmement',
  'opiniâtrement',
  'opportunément',
  'oralement',
  'ordinairement',
  'organiquement',
  'orgueilleusement',
  'originairement',
  'originalement',
  'originellement',
  'ostensiblement',
  'ostentatoirement',
  'outrageusement',
  'ouvertement',
  'pacifiquement',
  'paillardement',
  'paisiblement',
  'pâlement',
  'paraboliquement',
  'paradoxalement',
  'parallèlement',
  'parcimonieusement',
  'pareillement',
  'paresseusement',
  'parfaitement',
  'parodiquement',
  'partialement',
  'particulièrement',
  'partiellement',
  'passablement',
  'passagèrement',
  'passionnellement',
  'passionnément',
  'passivement',
  'paternellement',
  'pâteusement',
  'pathétiquement',
  'pathologiquement',
  'patiemment',
  'patriotiquement',
  'pauvrement',
  'pécuniairement',
  'pédestrement',
  'peinardement',
  'péjorativement',
  'pénalement',
  'pénardement',
  'péniblement',
  'pensivement',
  'péremptoirement',
  'perfidement',
  'périlleusement',
  'périodiquement',
  'pernicieusement',
  'perpendiculairement',
  'perpétuellement',
  'persévéramment',
  'personnellement',
  'pertinemment',
  'perversement',
  'pesamment',
  'petitement',
  'peureusement',
  'phénoménalement',
  'philosophiquement',
  'phonétiquement',
  'physiologiquement',
  'physiquement',
  'picturalement',
  'piètrement',
  'pieusement',
  'piteusement',
  'pitoyablement',
  'placidement',
  'plaintivement',
  'plaisamment',
  'platement',
  'platoniquement',
  'plausiblement',
  'pleinement',
  'poétiquement',
  'poliment',
  'politiquement',
  'pompeusement',
  'ponctuellement',
  'populairement',
  'posément',
  'positivement',
  'possiblement',
  'postérieurement',
  'potentiellement',
  'poussivement',
  'pragmatiquement',
  'pratiquement',
  'préalablement',
  'précairement',
  'précautionneusement',
  'précédemment',
  'précieusement',
  'précipitamment',
  'précisément',
  'précocement',
  'préférablement',
  'préliminairement',
  'prématurément',
  'premièrement',
  'présentement',
  'présomptueusement',
  'prestement',
  'prétendument',
  'préventivement',
  'primitivement',
  'primordialement',
  'princièrement',
  'principalement',
  'privément',
  'probablement',
  'prochainement',
  'prodigieusement',
  'professionnellement',
  'profondément',
  'profusément',
  'progressivement',
  'promptement',
  'prophétiquement',
  'proportionnellement',
  'proportionnément',
  'proprement',
  'prosaïquement',
  'proverbialement',
  'providentiellement',
  'provincialement',
  'provisoirement',
  'prudemment',
  'psychiquement',
  'psychologiquement',
  'publicitairement',
  'publiquement',
  'pudiquement',
  'puérilement',
  'puissamment',
  'purement',
  'pyramidalement',
  'quantitativement',
  'quasiment',
  'quatorzièmement',
  'quatrièmement',
  'quiètement',
  'quinzièmement',
  'quotidiennement',
  'racialement',
  'radicalement',
  'radieusement',
  'rageusement',
  'raidement',
  'raisonnablement',
  'rapidement',
  'rarement',
  'rarissimement',
  'rationnellement',
  'récemment',
  'réciproquement',
  'redoutablement',
  'réellement',
  'réglementairement',
  'regrettablement',
  'régulièrement',
  'relativement',
  'religieusement',
  'remarquablement',
  'résolument',
  'respectivement',
  'respectueusement',
  'rétroactivement',
  'rétrospectivement',
  'révérencieusement',
  'rêveusement',
  'révolutionnairement',
  'richement',
  'ridiculement',
  'rigidement',
  'rigoureusement',
  'risiblement',
  'rituellement',
  'robustement',
  'roidement',
  'romantiquement',
  'rondement',
  'royalement',
  'rudement',
  'rythmiquement',
  'sacrément',
  'sadiquement',
  'sagement',
  'sainement',
  'saintement',
  'salement',
  'salutairement',
  'sarcastiquement',
  'sardoniquement',
  'satiriquement',
  'sauvagement',
  'savamment',
  'savoureusement',
  'scandaleusement',
  'schématiquement',
  'sciemment',
  'scientifiquement',
  'scolairement',
  'scrupuleusement',
  'sèchement',
  'secondairement',
  'secondement',
  'secrètement',
  'séculairement',
  'seizièmement',
  'sélectivement',
  'semblablement',
  'semestriellement',
  'sempiternellement',
  'sénilement',
  'sensationnellement',
  'sensément',
  'sensiblement',
  'sensuellement',
  'sentencieusement',
  'sentimentalement',
  'séparément',
  'septièmement',
  'séquentiellement',
  'sereinement',
  'sérieusement',
  'servilement',
  'seulement',
  'sévèrement',
  'sexuellement',
  'significativement',
  'silencieusement',
  'similairement',
  'simplement',
  'simultanément',
  'sincèrement',
  'singulièrement',
  'sinistrement',
  'sinueusement',
  'sixièmement',
  'sobrement',
  'socialement',
  'sociologiquement',
  'soigneusement',
  'solennellement',
  'solidairement',
  'solidement',
  'solitairement',
  'sombrement',
  'sommairement',
  'somnambuliquement',
  'somptueusement',
  'songeusement',
  'sonorement',
  'sordidement',
  'sottement',
  'soudainement',
  'souplement',
  'sourdement',
  'sournoisement',
  'souterrainement',
  'souverainement',
  'soyeusement',
  'spacieusement',
  'spasmodiquement',
  'spatialement',
  'spécialement',
  'spécieusement',
  'spécifiquement',
  'spectaculairement',
  'sphériquement',
  'spirituellement',
  'splendidement',
  'spontanément',
  'sporadiquement',
  'sportivement',
  'stablement',
  'statistiquement',
  'stérilement',
  'stoïquement',
  'stratégiquement',
  'strictement',
  'structuralement',
  'structurellement',
  'studieusement',
  'stupidement',
  'suavement',
  'subconsciemment',
  'subitement',
  'subjectivement',
  'sublimement',
  'subrepticement',
  'subséquemment',
  'subsidiairement',
  'substantiellement',
  'subtilement',
  'successivement',
  'succinctement',
  'suffisamment',
  'superbement',
  'superficiellement',
  'supérieurement',
  'superlativement',
  'superstitieusement',
  'supposément',
  'suprêmement',
  'surabondamment',
  'sûrement',
  'surnaturellement',
  'suspicieusement',
  'symboliquement',
  'symétriquement',
  'synthétiquement',
  'systématiquement',
  'tacitement',
  'tactilement',
  'tactiquement',
  'talentueusement',
  'tardivement',
  'techniquement',
  'technologiquement',
  'télépathiquement',
  'téléphoniquement',
  'tellement',
  'témérairement',
  'temporairement',
  'temporellement',
  'tenacement',
  'tendancieusement',
  'tendrement',
  'terriblement',
  'territorialement',
  'textuellement',
  'théâtralement',
  'thématiquement',
  'théologiquement',
  'théoriquement',
  'tièdement',
  'timidement',
  'topographiquement',
  'totalement',
  'traditionnellement',
  'tragiquement',
  'traîtreusement',
  'tranquillement',
  'transitoirement',
  'transversalement',
  'treizièmement',
  'triangulairement',
  'trimestriellement',
  'triomphalement',
  'triplement',
  'tristement',
  'trivialement',
  'troisièmement',
  'trompeusement',
  'tumultueusement',
  'typiquement',
  'tyranniquement',
  'ultérieurement',
  'ultimement',
  'unanimement',
  'uniformément',
  'unilatéralement',
  'uniment',
  'uniquement',
  'universellement',
  'urgemment',
  'usuellement',
  'utilement',
  'vachement',
  'vaguement',
  'vaillamment',
  'vainement',
  'valablement',
  'valeureusement',
  'validement',
  'vaniteusement',
  'véhémentement',
  'vélocement',
  'venimeusement',
  'verbalement',
  'véridiquement',
  'véritablement',
  'vertement',
  'verticalement',
  'vertigineusement',
  'vertueusement',
  'vicieusement',
  'victorieusement',
  'vigilamment',
  'vigoureusement',
  'vilainement',
  'vilement',
  'vindicativement',
  'violemment',
  'virginalement',
  'virilement',
  'virtuellement',
  'viscéralement',
  'visiblement',
  'visuellement',
  'vitement',
  'vivement',
  'vocalement',
  'volontairement',
  'volubilement',
  'voluptueusement',
  'voracement',
  'vraiment',
  'vraisemblablement',
  'vulgairement'
];

export default adverbs;
