import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface ConfirmCancelEditionProps {
  confirmCancelEditionModal: boolean;
  toggleConfirmCancelEdition: any;
  confirmCancelEdition: any;
}

export default class ConfirmCancelEdition extends React.Component<ConfirmCancelEditionProps, {}> {
  constructor(props: ConfirmCancelEditionProps) {
    super(props);
    this.toggleConfirmCancelEdition = this.toggleConfirmCancelEdition.bind(this);
  }
  toggleConfirmCancelEdition() {
    this.props.toggleConfirmCancelEdition();
  }
  render() {
    return (
      <Modal
        open={this.props.confirmCancelEditionModal}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          Confirmez-vous l'annulation des modifications en cours et le retour&nbsp;
          à l'état précédent du chapitre ?
          <br />Les modifications seront définitivement perdues.
        </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.props.confirmCancelEdition}
          >
            Oui
          </button>
          <button className="ui cancel button" onClick={this.toggleConfirmCancelEdition}>Non</button>
        </div>
      </Modal>
    );
  }
}
