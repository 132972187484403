import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Store from '../../stores/Store';
import DefiModel from '../../models/DefiModel';
import DefiForm from './DefiForm';
import DefiParams from './DefiParams';
import Loader from '../misc/Loader';

interface EditDefiPageProps {
  store?: Store;
  params: DefiParams;
}

@inject('store') @observer
export default class EditDefiPage extends React.Component<EditDefiPageProps, {}> {
  static fetchData(store: Store, params: DefiParams) {
    const p: any[] = [];
    p.push(store.loadSettingsNotifications());
    p.push(store.loadDefi(params.defiId, true));
    return Promise.all(p);
  }
  constructor(props: EditDefiPageProps) {
    super(props);
    this.updateDefi = this.updateDefi.bind(this);
  }
  updateDefi(title: string, description: string,
    genre: number) {
    this.props.store.updateDefi(
      this.props.params.defiId, title, description, genre
    ).then((d: DefiModel) => {
      browserHistory.push({
        pathname: `/defis/defi/${this.props.params.defiId}/${this.props.params.urlDefi}`
      });
    });
  }
  render() {
    const mainDefi = this.props.store.mainDefi;
    return (
      <div className="ui text container" style={{ paddingBottom: '80px' }}>
        {mainDefi && mainDefi.defi && mainDefi.defi.id ?
          <div>
            <Title title={`Modifier le défi ${mainDefi.defi.title} `} />
            <div className="ui huge breadcrumb">
              <Link to={`/defis/defi/${mainDefi.defi.id}/${mainDefi.defi.urlTitle}`}
                className="section">{mainDefi.defi.title}
              </Link>
              <i className="fa fa-fw fa-chevron-right divider"></i>
              <div className="active section">
                Modifier
              </div>
            </div>
            <div className="ui basic segment">
              <DefiForm submit={this.updateDefi} defi={mainDefi.defi} />
            </div>
          </div>
          : <Loader message="Chargement..." />
        }
      </div>
    );
  }
}
