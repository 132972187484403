import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';
import { scroller } from 'react-scroll';
import AnnotationModel from '../../models/text/AnnotationModel';

interface ChapterAnnotationsProps {
  store?: Store;
}

interface ChapterAnnotationsState {
  showAnnotations: boolean;
  annotations: AnnotationModel[];
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class ChapterAnnotations extends React.Component<ChapterAnnotationsProps, ChapterAnnotationsState> {
  constructor(props: ChapterAnnotationsProps) {
    super(props);
    this.showAnnotations = this.showAnnotations.bind(this);
    this.moveToAnnotation = this.moveToAnnotation.bind(this);
    this.state = {
      showAnnotations: false,
      annotations: []
    };
  }
  showAnnotations() {
    this.props.store.loadChapterAnnotations().then((annotations: any) => {
      this.setState({
        annotations: annotations.rows,
        showAnnotations: true
      });
    });
  }
  moveToAnnotation(annotationId: number) {
    const annotationAnchor = `ann_${annotationId}`;
    scroller.scrollTo(annotationAnchor, {
      offset: -150,
      duration: 500,
      delay: 100,
      smooth: true
    });
  }
  render() {
    const text = this.props.store.text;
    const annotations = this.state.annotations.map((annotation) => {
      return (
        <li key={`annotation_${annotation.id}`} className="item">
          <div className="header">
            {annotation.quote}
            <span className="ui right floated small label">{annotation.category}</span>
            <button onClick={() => this.moveToAnnotation(annotation.id)} className="ui right floated small blue label">Voir</button>
          </div>
          <div>{annotation.text}</div>
        </li>
      );
    });
    return (
      <div>
        <div className="ui hidden section divider" />
        <h3 className="ui horizontal divider">
          Annotations
        </h3>
        {this.state.showAnnotations ?
          (annotations.length > 0 ?
            <ul className="ui list">
              {annotations}
            </ul>
            : <span>Aucune annotation.</span>
          )
          :
          <div className="ui basic center aligned segment">
            <div>
              <button onClick={this.showAnnotations} className="ui small button">
                Afficher les annotations
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}
