import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';

interface ChatIndexDiscussionProps {
  store?: Store;
}

interface ChatIndexDiscussionState {
  enableComponents: boolean;
  message: string;
}

@inject('store') @observer
export default class ChatIndexDiscussion extends React.Component<ChatIndexDiscussionProps, ChatIndexDiscussionState> {
  render() {
    return (
      <div className="ui basic comments segment">
        <div className="ui very padded center aligned segment" style={{ marginTop: '100px' }}>
          <h3>Aucune discussion sélectionnée</h3>
        </div>
      </div>
    );
  }
}
