import * as React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
const HotKeys = require('react-hotkeys').HotKeys;
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
const MegadraftIcons = Megadraft.MegadraftIcons;
import UnderlineIcon from '../../../../editor/UnderlineIcon';
import StrikeIcon from '../../../../editor/StrikeIcon';
import TiretIcon from '../../../../editor/TiretIcon';
import H1Icon from '../../../../editor/H1Icon';
import H2Icon from '../../../../editor/H2Icon';
import AlignLeftIcon from '../../../../editor/AlignLeftIcon';
import AlignCenterIcon from '../../../../editor/AlignCenterIcon';
import AlignRightIcon from '../../../../editor/AlignRightIcon';
import AlignJustifyIcon from '../../../../editor/AlignJustifyIcon';
import styleMap from '../../../../editor/styleMap';
import blockStyles from '../../../../editor/blockStyles';
import Store from '../../../../../stores/Store';
import Loader from '../../../../misc/Loader';

interface ProloguePageParams {
  parcoursId: number;
  prologueId: number;
}

interface ProloguePageProps {
  store?: Store;
  params: ProloguePageParams;
  location: { chapter: number };
}

const actions = [
  { type: 'inline', label: 'B', style: 'BOLD', icon: MegadraftIcons.BoldIcon },
  { type: 'inline', label: 'I', style: 'ITALIC', icon: MegadraftIcons.ItalicIcon },
  { type: 'inline', label: 'U', style: 'UNDERLINE', icon: UnderlineIcon },
  { type: 'inline', label: 'S', style: 'STRIKETHROUGH', icon: StrikeIcon },
  { type: 'separator' },
  { type: 'block', label: 'UL', style: 'unordered-list-item', icon: TiretIcon },
  { type: 'separator' },
  { type: 'block', label: 'H1', style: 'header-one', icon: H1Icon },
  { type: 'block', label: 'H2', style: 'header-two', icon: H2Icon },
  { type: 'separator' },
  { type: 'block', label: 'LEFT', style: 'align-left', icon: AlignLeftIcon },
  { type: 'block', label: 'CENTER', style: 'align-center', icon: AlignCenterIcon },
  { type: 'block', label: 'RIGHT', style: 'align-right', icon: AlignRightIcon },
  { type: 'block', label: 'JUSTIFY', style: 'align-justify', icon: AlignJustifyIcon },
];

interface ProloguePageState {
  enableComponents: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class ProloguePage extends React.Component<ProloguePageProps, ProloguePageState> {

  _timer: any;

  static fetchData(store: Store, params: ProloguePageParams, query: { chapter: number }) {
    const p: any[] = [];
    p.push(store.loadConnected());
    p.push(store.loadParcoursPrerequisites(params.parcoursId));
    p.push(store.loadPrologue(params.parcoursId, params.prologueId, query.chapter));
    return Promise.all(p);
  }
  constructor(props: ProloguePageProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.poll = this.poll.bind(this);
    this.state = {
      enableComponents: false
    };
  }
  componentDidMount() {
    this.startPolling();
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  @observable
  onChange(value: any) {
    this.props.store.mainParcours.prologue.updateTextContentState(value);
  }
  poll() {
    this.save();
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that._timer = setInterval(that.poll.bind(self), 30000);
    }, 1000);
  }
  save() {
    const parcours = this.props.store.mainParcours.currentParcours;
    const prologue = this.props.store.mainParcours.prologue;
    const textHtml = this.props.store.mainParcours.prologue.getHTML();
    this.props.store.savePrologue(parcours.id, prologue.id, textHtml);
  }
  render() {
    const prologue = this.props.store.mainParcours.prologue;
    const prologueEl = <div></div>;

    const handlers = {
      save: (event: any) => {
        event.preventDefault();
        this.save();
      }
    };

    return (
      <div>
        {prologue ?
          <div>
            <HotKeys keyMap={{ save: ['command+s', 'ctrl+s'] }} handlers={handlers}>
              <div className="ui text container chapter-content">
                <div className="ui read text container write-editor" style={{ padding: '20px 0px 90vh 0px' }}>
                  {this.state.enableComponents ?
                    <MegadraftEditor
                      editorState={prologue.textContentState}
                      actions={actions}
                      placeholder="Votre texte"
                      onChange={this.onChange}
                      sidebarRendererFn={() => { return <div />; }}
                      customStyleMap={styleMap}
                      blockStyles={blockStyles}
                      spellcheck={false}
                    />
                    : undefined}
                </div>
              </div>
            </HotKeys>
          </div>
          : <div />}
      </div>
    );
  }
}
