import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import SettingsPageAdminPromotion from './SettingsPageAdminPromotion';

interface SettingsPageAdminProps {
  store: Store;
}

interface SettingsPageAdminState {
  users: string;
  message: string;
  remainingCharacters: number;
  notifMessage: string;
  notifLink: string;
  messageSent: boolean;
  notifSent: boolean;
}

@inject('store') @observer
export default class SettingsPageAdmin extends React.Component<SettingsPageAdminProps, SettingsPageAdminState> {
  static fetchData(store: Store) {
    const p: any[] = [];
    p.push(store.getCurrentPromotion());
    p.push(store.getAllPromotions());
    return Promise.all(p);
  }
  constructor(props: SettingsPageAdminProps) {
    super(props);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.sendMP = this.sendMP.bind(this);
    this.handleNotifMessage = this.handleNotifMessage.bind(this);
    this.handleNotifLink = this.handleNotifLink.bind(this);
    this.sendNotifs = this.sendNotifs.bind(this);
    this.state = {
      users: '',
      message: '',
      remainingCharacters: 5000,
      notifMessage: '',
      notifLink: '',
      messageSent: false,
      notifSent: false
    };
  }
  handleUsers(e: any) {
    const users = e.target.value;
    this.setState({
      users: users
    });
  }
  handleTextChange(e: any) {
    const message = e.target.value;
    this.setState({
      message: message,
      remainingCharacters: 5000 - message.length
    });
  }
  @action sendMP(event: any) {
    event.preventDefault();
    this.setState({
      messageSent: false
    });
    this.props.store.sendMPAuto(this.state.users, this.state.message).then((d: any) => {
      this.setState({
        users: '',
        message: '',
        remainingCharacters: 5000,
        messageSent: true,
        notifSent: false,
        notifMessage: '',
        notifLink: ''
      });
    });
  }
  handleNotifMessage(e: any) {
    const notifMessage = e.target.value;
    this.setState({
      notifMessage: notifMessage
    });
  }
  handleNotifLink(e: any) {
    const notifLink = e.target.value;
    this.setState({
      notifLink: notifLink
    });
  }
  @action sendNotifs(event: any) {
    event.preventDefault();
    this.setState({
      notifSent: false
    });
    this.props.store.sendNotifs(this.state.notifMessage, this.state.notifLink).then((d: any) => {
      this.setState({
        users: '',
        message: '',
        remainingCharacters: 5000,
        messageSent: false,
        notifSent: true,
        notifMessage: '',
        notifLink: ''
      });
    });
  }
  render() {
    const connected = this.props.store.connected;
    const isAdmin = connected && connected.user && connected.user.admin;
    return (
      <div>
        {isAdmin ?
          <div>
            <div>
              <h3>Envoi groupé de MP</h3>
              {this.state.messageSent ?
                <div className="ui success message">
                  Messages envoyés !
          </div>
                : undefined}
              <form onSubmit={this.sendMP} className="ui reply form" style={{ padding: '10px' }}>
                <div className="field">
                  <label>Utilisateurs</label>
                  <input
                    type="text"
                    value={this.state.users}
                    onChange={this.handleUsers}
                  />
                </div>
                <div className={(this.state.remainingCharacters < 0 ? 'error' : '') + ' field'} style={{ margin: '0em 0em 1.4em' }}>
                  <textarea
                    value={this.state.message}
                    rows={2}
                    style={{ height: '7em' }}
                    onChange={this.handleTextChange}
                  />
                </div>
                {this.state.remainingCharacters < 0 ?
                  <span style={{ color: '#912d2b' }}>{this.state.remainingCharacters} caractères</span>
                  : undefined}
                <button
                  className="ui blue submit right floated button"
                  disabled={this.state.remainingCharacters < 0 || this.state.remainingCharacters === 5000 || this.state.users.trim().length === 0}
                  type="submit"
                >
                  Envoyer
                </button>
              </form>
            </div>
            <div style={{ marginTop: '50px' }}>
              <h3>Envoi de notifications</h3>
              {this.state.notifSent ?
                <div className="ui success message">
                  Notifications envoyées !
          </div>
                : undefined}
              <form className="ui reply form" style={{ padding: '10px' }}>
                <div className="field">
                  <label>Message (faire court !)</label>
                  <input
                    type="text"
                    value={this.state.notifMessage}
                    onChange={this.handleNotifMessage}
                  />
                </div>
                <div className="field">
                  <label>Lien</label>
                  <input
                    type="text"
                    value={this.state.notifLink}
                    onChange={this.handleNotifLink}
                  />
                </div>
                <button
                  className="ui blue submit right floated button"
                  type="submit"
                  onClick={this.sendNotifs}
                >
                  Envoyer
                </button>
              </form>
            </div>
            <SettingsPageAdminPromotion />
          </div>
          : undefined
        }
      </div>
    );
  }
}
