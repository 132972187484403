import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { scroller } from 'react-scroll';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import CommentModel from '../../models/comment/CommentModel';
import CommentListModel from '../../models/comment/CommentListModel';
import Store from '../../stores/Store';

interface CommentBoxProps {
  store?: Store;
  memberId?: number;
  readonly: boolean;
  newsfeedElement: boolean;
  admin: boolean;
  comments: CommentListModel;
}

@inject('store') @observer
export default class CommentBox extends React.Component<CommentBoxProps, {}> {
  constructor(props: CommentBoxProps) {
    super(props);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
  }
  handleCommentSubmit(text: string) {
    return this.props.store.handleCommentSubmit(this.props.comments, text).then((commentId: number) => {
      const commentAnchor = `comment_${commentId}`;
      scroller.scrollTo(commentAnchor, {
        duration: 500,
        delay: 100,
        smooth: true
      });
    });
  }
  render() {
    const author = this.props.store.connected.user;

    const style = this.props.newsfeedElement ?
      { textAlign: 'left', maxWidth: 'inherit', paddingLeft: '8px', paddingRight: '8px' }
      : { textAlign: 'left' };
    return (
      <div className="ui comments" style={style}>
        {(String(this.props.memberId) && author && String(author.id) === String(this.props.memberId)
          && !this.props.readonly && !this.props.newsfeedElement)
          || (!this.props.memberId && author && !this.props.readonly
            && !this.props.newsfeedElement && this.props.comments)
          ? <CommentForm
            author={author}
            submit={this.handleCommentSubmit}
            textEdit=""
            focus={false}
          />
          : null}
        <CommentList
          author={author}
          memberId={this.props.memberId}
          readonly={this.props.readonly}
          newsfeedElement={this.props.newsfeedElement}
          admin={this.props.admin}
          comments={this.props.comments}
        />
      </div>
    );
  }
}
