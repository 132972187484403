import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import BadgeModel from '../../models/BadgeModel';
import Store from '../../stores/Store';

interface NewBadgesProps {
  store?: Store;
}

@inject('store') @observer
export default class NewBadges extends React.Component<NewBadgesProps, {}> {
  constructor(props: NewBadgesProps) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
  }
  @action
  hideModal() {
    this.props.store.connected.badges = [];
  }
  render() {
    const badges = this.props.store.connected && this.props.store.connected.badges ?
      this.props.store.connected.badges.map((badge: BadgeModel) => {
        const badgeName = <FormattedMessage
          id={`badge.${badge.name}.name`}
          defaultMessage={`Nom ${badge.name}`}
        />;
        const badgeDescription = <FormattedHTMLMessage
          id={`badge.${badge.name}.description`}
          defaultMessage={`Description ${badge.name}`}
          values={{ 0: badge.level }}
        />;
        let badgeUrl: string;
        if (badge.level) {
          badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/${badge.name}/${badge.level}.png`;
        } else {
          if (badge.name.indexOf('defi') !== -1) {
            badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/defis/${badge.name.split('_')[1]}.png`;
          } else {
            badgeUrl = `https://s3-eu-west-1.amazonaws.com/scribay/badges/small/misc/${badge.name}.png`;
          }
        }
        return (
          <div key={`badge_${Math.random() * 1000}`} className="item">
            <div className="ui tiny image">
              <img src={badgeUrl} alt={`Image du badge ${badgeName}`} />
            </div>
            <div className="content">
              <a className="header">{badgeName}</a>
              <div>{badgeDescription}</div>
            </div>
          </div>
        );
      })
      : [];

    const title = badges.length > 1 ?
      <span>Vous avez obtenu les badges suivants :</span>
      : <span>Vous avez obtenu le badge suivant :</span>;

    const newUser = this.props.store.connected && this.props.store.connected.newUser;

    return (
      <div>
        <Modal
          open={!newUser && badges.length > 0}
          onClose={this.hideModal}
          size="small"
        >
          <div className="header">Félicitations !</div>
          <div className="ui content">
            {title}
            <div className="ui items">
              {badges}
            </div>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.hideModal}>Fermer</button>
          </div>
        </Modal>
      </div>
    );
  }
}

