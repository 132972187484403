import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import { thousandsSeparator } from '../../../../../utils/utils';
import Store from '../../../../../stores/Store';

interface ChapterTimeProps {
  store?: Store;
}

@inject('store') @observer
export default class ChapterTime extends React.Component<ChapterTimeProps, {}> {
  render() {
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;
    const chapter = this.props.store.write.currentText.currentChapter;
    const time = chapter.time;
    const tooLong = false/* premium && chapter.chapterTooLong; */
    const timeString = chapter.timeString;
    const words = chapter.words ?
      ` (${thousandsSeparator(chapter.words)} mot${chapter.words > 1 ? 's' : ''} - ${thousandsSeparator(chapter.size)} signe${chapter.size > 1 ? 's' : ''})`
      : '';

    const timeSpan = 
      <span id="chapter-time" className={'ui label ' + (tooLong ? 'red' : '')}>
        <span><i className="fa fa-fw fa-clock-o" /> {time}</span>
        <span style={{ marginLeft: '10px' }}>{words}</span>
      </span>;
    return (
      <Popup
        trigger={timeSpan}
        position="bottom center"
        size="large"
      >
          <strong>{timeString}</strong>
      </Popup>
    );
  }
}

