import { observable } from 'mobx';
import UserModel from '../UserModel';

class ParcoursProgressMineModel {
  parcoursChapterId: number;
  visible: boolean;
}

class ParcoursChapterModel {
  @observable id: number;
  @observable index: number;
  @observable title: string;
  @observable users: UserModel[];
  @observable invisibleUsers: number;
}

class ParcoursPartModel {
  @observable index: number;
  @observable title: string;
  chapters: ParcoursChapterModel[];
}

class ParcoursProgressAll {
  @observable parts: ParcoursPartModel[];
}

export default class ParcoursProgressModel {

  @observable mine: ParcoursProgressMineModel;
  @observable all: ParcoursProgressAll;

  constructor(mine: ParcoursProgressMineModel, all: ParcoursProgressAll) {
    this.mine = mine;
    this.all = all;
  }

  toJS() {
    return {
      mine: this.mine,
      all: this.all
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ParcoursProgressModel(object.mine, object.all);
    }
    return undefined;
  }
}
