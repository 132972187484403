import * as React from 'react';

interface LoaderProps {
  message: string;
}

export default class Loader extends React.Component<LoaderProps, {}> {
  render() {
    return (
      <div
        className="ui basic segment"
        style={{ height: '200px', zIndex: 10 }}
        >
        <div className="ui active inverted dimmer">
          <div className="ui medium text loader">{this.props.message}</div>
        </div>
      </div>
    );
  }
}
