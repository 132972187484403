import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import ReadingListForm from './ReadingListForm';
import Loader from '../misc/Loader';
import Store from '../../stores/Store';

interface ReadingListsTextModalProps {
  store?: Store;
  active: boolean;
  hideModal: any;
}

interface ReadingListsTextModalState {
  formMode: boolean;
}

@inject('store') @observer
export default class ReadingListsTextModal
  extends React.Component<ReadingListsTextModalProps, ReadingListsTextModalState> {
  constructor(props: ReadingListsTextModalProps) {
    super(props);
    this.selectList = this.selectList.bind(this);
    this.unselectList = this.unselectList.bind(this);
    this.newList = this.newList.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.save = this.save.bind(this);

    this.state = {
      formMode: false
    };
  }
  @action selectList(readingListId: number) {
    const lists = this.props.store.mainReadingList.lists
      .filter(l => l.id === readingListId);
    if (lists.length > 0) {
      lists[0].selected = true;
    }
  }
  @action unselectList(readingListId: number) {
    const lists = this.props.store.mainReadingList.lists
      .filter(l => l.id === readingListId);
    if (lists.length > 0) {
      lists[0].selected = false;
    }
  }
  newList() {
    this.setState({
      formMode: true
    });
  }
  cancelForm() {
    this.props.store.loadMyReadingListsForText(this.props.store.text.meta.id).then((d: any) => {
      this.setState({
        formMode: false
      });
    });
  }
  save() {
    const currentText = this.props.store.text.meta;
    const selectedLists = this.props.store.mainReadingList.lists
      .filter(l => l.selected).map(l => l.id);
    this.props.store.saveReadingListsForText(currentText.id, selectedLists).then((d: any) => {
      if (selectedLists.length > 0) {
        currentText.inReadingList = true;
      } else {
        currentText.inReadingList = false;
      }
      this.props.hideModal();
    });
  }
  render() {
    const mainReadingList = this.props.store.mainReadingList;
    let lists: any[];
    if (mainReadingList.lists.length > 0) {
      lists = mainReadingList.lists.map(list => {
        return <tr key={list.id}>
          <td>
            <Checkbox
              checked={list.selected}
              onClick={(e: any) => {
                if (list.selected) {
                  this.unselectList(list.id);
                } else {
                  this.selectList(list.id);
                }
              }}
            />
          </td>
          <td>
            <b>{list.name}</b>
          </td>
          <td>
            {list.pub ? 'Oui' : 'Non'}
          </td>
          <td>
            <a href={`/list/${list.id}/${list.urlName}`}
              target="_blank" rel="noopener noreferrer">
              Voir
            </a>
          </td>
        </tr>;
      });
    } else {
      lists = [
        <tr key="none" >
          <td colSpan={4} className="center aligned">Aucune liste</td>
        </tr>
      ];
    }
    return (
      <div>
        <ReadingListForm
          active={this.state.formMode}
          hideModal={this.cancelForm}
        />
        <Modal
          open={this.props.active}
          onClose={() => { }}
        >
          <div className="header">Listes</div>
          <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            <div className="ui basic segment">
              {mainReadingList.loading
                ? <Loader message="Chargement..." />
                : <div>
                  <div>
                    Sélectionnez les listes où vous souhaitez ajouter cette œuvre :
                  </div>
                  <table className="ui orange table">
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}></th>
                        <th>Nom</th>
                        <th style={{ width: '100px' }}>Public</th>
                        <th style={{ width: '100px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {lists}
                    </tbody>
                  </table>
                  <button
                    className="ui right floated orange button"
                    onClick={this.newList}
                  >
                    Nouvelle liste
                  </button>
                </div>
              }
            </div>
          </div>
          <div className="actions">
            <button className="ui primary button" onClick={this.save}>
              Enregistrer
            </button>
            <button className="ui cancel button" onClick={this.props.hideModal}>
              Fermer
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
