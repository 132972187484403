import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface StartHelpProps {
  active: boolean;
  startHelp: Function | any;
}

export default class StartHelp extends React.Component<StartHelpProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.startHelp}
        size="small"
      >
        <div className="header">Assistant</div>
        <div className="content">
          Cet assistant va vous présenter l'éditeur.
        </div>
        <div className="actions">
          <button className="ui primary button" onClick={this.props.startHelp}>Commencer</button>
        </div>
      </Modal>
    );
  }
}
