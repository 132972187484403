import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import NewDraftModel from '../../models/write/NewDraftModel';
import DefiRemove from './DefiRemove';
import Store from '../../stores/Store';

interface DefiPageNorAuthorNotRepondantActiveProps {
  store?: Store;
}

interface DefiPageNorAuthorNotRepondantActiveState {
  modalActive: boolean;
  activeDeleteDefiModal: boolean;
  notConnectedModalActive: boolean;
  step: number;
  title: string;
}

@inject('store') @observer
export default class DefiPageNorAuthorNotRepondantActive
  extends React.Component<DefiPageNorAuthorNotRepondantActiveProps, DefiPageNorAuthorNotRepondantActiveState> {
  constructor(props: DefiPageNorAuthorNotRepondantActiveProps) {
    super(props);
    this.openDefi = this.openDefi.bind(this);
    this.closeDefi = this.closeDefi.bind(this);
    this.acceptDefi = this.acceptDefi.bind(this);
    this.next = this.next.bind(this);
    this.cancel = this.cancel.bind(this);
    this.confirmAcceptDefi = this.confirmAcceptDefi.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.showNotConnectedModalActive = this.showNotConnectedModalActive.bind(this);
    this.hideNotConnectedModalActive = this.hideNotConnectedModalActive.bind(this);
    this.showDeleteDefiModal = this.showDeleteDefiModal.bind(this);
    this.cancelDeleteDefiModal = this.cancelDeleteDefiModal.bind(this);
    this.deleteDefi = this.deleteDefi.bind(this);
    this.state = {
      modalActive: false,
      activeDeleteDefiModal: false,
      notConnectedModalActive: false,
      step: undefined,
      title: ''
    };
  }
  openDefi() {
    this.props.store.openDefi();
  }
  closeDefi() {
    this.props.store.closeDefi();
  }
  acceptDefi() {
    this.setState({
      modalActive: true,
      step: 1,
      title: ''
    });
  }
  next() {
    this.setState({
      step: 2
    });
  }
  cancel() {
    this.setState({
      modalActive: false,
      step: undefined,
      title: ''
    });
  }
  confirmAcceptDefi() {
    this.props.store.acceptDefi(this.state.title).then((draft: NewDraftModel) => {
      browserHistory.push({
        pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
        state: {
          newDefi: true,
          newPart: false,
          showCongratulationsChapter: false
        }
      });
    });
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  showNotConnectedModalActive() {
    this.setState({
      notConnectedModalActive: true
    });
  }
  hideNotConnectedModalActive() {
    this.setState({
      notConnectedModalActive: false
    });
  }
  showDeleteDefiModal() {
    this.setState({ activeDeleteDefiModal: true });
  }
  cancelDeleteDefiModal() {
    this.setState({
      activeDeleteDefiModal: false
    });
  }
  deleteDefi() {//As an administrator
    this.props.store.deleteDefi().then(() => {
      browserHistory.push({
        pathname: '/defis'
      });
    });
  }
  render() {
    const defi = this.props.store.mainDefi.defi;
    const connected = this.props.store.connected;
    return (
      <div className="ui stackable container">
        <Modal
          open={this.state.modalActive}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Confirmation</div>
          {this.state.step ?
            <div className="content">
              {this.state.step === 1 ?
                <div>
                  Confirmez-vous le choix de ce défi ?
              </div>
                :
                <div>
                  <div className="ui form">
                    <div className="field">
                      <label>Quel sera le titre de votre œuvre ?</label>
                      <input
                        type="text"
                        onChange={this.handleTitleChange}
                        placeholder={`Réponse à "${defi.title}"`}
                        value={this.state.title}
                      />
                      <div className="ui message">
                        Si vous ne connaissez pas encore le titre, laissez le champ vide, vous pourrez y revenir plus tard !
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            : undefined
          }
          {this.state.step ?
            <div className="actions">
              {this.state.step === 1 ?
                <div>
                  <button className="ui blue approve button" onClick={this.next}>
                    Oui
                  </button>
                  <button className="ui cancel button" onClick={this.cancel}>Non</button>
                </div>
                :
                <div>
                  <button className="ui blue approve button" onClick={this.confirmAcceptDefi}>
                    Commencer le défi
                  </button>
                  <button className="ui cancel button" onClick={this.cancel}>Annuler</button>
                </div>
              }
            </div>
            : undefined}
        </Modal>
        <Modal
          open={this.state.notConnectedModalActive}
          onClose={this.hideNotConnectedModalActive}
          size="small"
        >
          <div className="content">
            Seuls les membres de l'Atelier des auteurs ont le droit de relever un défi. L'inscription est gratuite !
        </div>
          <div className="actions">
            <Link to="/login" className="ui cancel button">Je me connecte</Link>
            <Link to="/signup" className="ui blue approve button">Je m'inscris</Link>
          </div>
        </Modal>
        {connected && connected.user && connected.user.id && connected.user.admin ?
        <DefiRemove
          delete={true}
          modalActive={this.state.activeDeleteDefiModal}
          handleRemove={this.deleteDefi}
          hideModal={this.cancelDeleteDefiModal}
        />
        : undefined}
        {defi && defi.available ?
          <div>
            {defi.myReponse ?
              <div className="ui message warning" style={{ marginTop: '20px' }}>
                {!defi.myReponse.active && defi.myReponse.text ?
                  <div>
                    Vous avez répondu à ce défi le {defi.myReponse.text.dateCreation} avec l'œuvre&nbsp;
                        <Link
                      to={`/text/${defi.myReponse.text.id}/${defi.myReponse.text.urlText}`}>
                      {defi.myReponse.text.title}
                    </Link>.
                        {defi.available ?
                      <div>Relevez-le de nouveau en l'abordant d'une nouvelle façon !</div>
                      : undefined}
                  </div>
                  : <div>
                    {defi.myReponse.cancelledAt ?
                      <div>Vous avez abandonné ce défi le {defi.myReponse.cancelledAt}.</div>
                      : <div>Vous n'avez pas relevé ce défi à temps.</div>
                    }
                    {defi.available ?
                      <div>Retentez votre chance !</div>
                      : undefined}
                  </div>
                }
              </div>
              : undefined}
            <div className="ui center aligned basic segment">
            {connected && connected.user && connected.user.id && connected.user.admin ?
            <button className="ui red button" onClick={this.showDeleteDefiModal}>
              Supprimer le défi et ses textes
            </button>
            : undefined}
            {connected && connected.user 
                && connected.user.admin ?
                  <button className="ui orange button" onClick={this.closeDefi}>Désactiver</button>
              : undefined}
              {connected && connected.user && connected.user.id ?
                <button className="ui primary button" onClick={this.acceptDefi}>Relever ce défi</button>
                :
                <button className="ui primary button" onClick={this.showNotConnectedModalActive}>Relever ce défi</button>
              }
            </div>
          </div>
          : <div>
              <div className="ui message warning" style={{ marginTop: '20px' }}>
              Ce défi est actuellement <b>indisponible</b>.
              </div>
              {connected && connected.user 
                && connected.user.admin ?
                <div className="ui center aligned basic segment">
                  <button className="ui green button" onClick={this.openDefi}>Activer</button>
                </div>
              : undefined}
            </div>

        }
      </div>
    );
  }
}
