import * as React from 'react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';

interface PopupWarningProps {
  body: string;
  spanClass: string;
  children: any;
}

export default class PopupWarning extends React.Component<PopupWarningProps, {}> {
  render() {
    return (
      <Popup
        trigger={
          <span
            title="Cliquez pour afficher"
            className={this.props.spanClass}
            >
            {this.props.children}
          </span>
        }
        on="click"
        position="bottom center"
        size="large"
        >
        <strong>{this.props.body}</strong>
      </Popup>
    );
  }
}
