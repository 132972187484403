import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../../stores/Store';

interface ChapterSaveProps {
  store?: Store;
}

@inject('store') @observer
export default class ChapterSave extends React.Component<ChapterSaveProps, {}> {
  constructor(props: ChapterSaveProps) {
    super(props);
    this.onSave = this.onSave.bind(this);
  }
  onSave() {
    this.props.store.saveChapter(true, false);
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    return (
      <button
        className={'ui ' + (chapter.saved ? ' active' : '') + ' button chapter-save'}
        onClick={this.onSave}
        title="Sauvegarder"
        data-tooltip="Sauvegarder" data-inverted="" data-position="top center"
        >
        <div className="fa fa-fw fa-save" />
        {chapter.saved ?
          <div className="fa fa-fw fa-check" />
          : undefined}
      </button>
    );
  }
}
