import { observable } from 'mobx';
import DefiTextModel from './DefiTextModel';
import FullCanvasModel from '../canvas/FullCanvasModel';
import ParcoursModel from '../parcours/ParcoursModel';
import ParcoursSummaryModel from '../parcours/ParcoursSummaryModel';
import LightParcoursPrologueModel from '../parcours/LightParcoursPrologueModel';
import ParcoursSummaryExampleModel from '../parcours/ParcoursSummaryExampleModel';

export default class CurrentTextInfosModel {

  @observable title: string;
  @observable published: boolean;
  @observable urlText: string;
  @observable defi: DefiTextModel;
  @observable canvas: FullCanvasModel;
  @observable parcours: ParcoursModel;
  @observable parcoursSummary: {
    text: ParcoursSummaryModel,
    part: ParcoursSummaryModel,
    prologues: LightParcoursPrologueModel[]
  };
  @observable parcoursSummaryExamples: ParcoursSummaryExampleModel[];

  constructor(title: string, published: boolean, urlText: string, defi: DefiTextModel, canvas: FullCanvasModel,
    parcours: ParcoursModel, parcoursSummary: {
      text: ParcoursSummaryModel,
      part: ParcoursSummaryModel,
      prologues: LightParcoursPrologueModel[]
    }, parcoursSummaryExamples: ParcoursSummaryExampleModel[]) {
    this.title = title;
    this.published = published;
    this.urlText = urlText;
    this.defi = defi;
    this.canvas = canvas;
    this.parcours = parcours;
    this.parcoursSummary = parcoursSummary || { text: undefined, part: undefined, prologues: [] };
    this.parcoursSummaryExamples = parcoursSummaryExamples || [];
  }

  toJS() {
    return {
      title: this.title,
      published: this.published,
      urlText: this.urlText,
      defi: this.defi.toJS(),
      canvas: this.canvas.toJS(),
      parcours: this.parcours.toJS(),
      parcoursSummary: {
        text: this.parcoursSummary.text.toJS(),
        part: this.parcoursSummary.part.toJS(),
        prologues: this.parcoursSummary.prologues.map(p => p.toJS())
      },
      parcoursSummaryExamples: this.parcoursSummaryExamples.map(p => p.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const defi = DefiTextModel.fromJS(object.defi);
      const canvas = FullCanvasModel.fromJS(object.canvas);
      const parcours = ParcoursModel.fromJS(object.parcours);
      const parcoursSummary = {
        text: ParcoursSummaryModel.fromJS(object.parcoursSummary.text),
        part: ParcoursSummaryModel.fromJS(object.parcoursSummary.part),
        prologues: object.parcoursSummary.prologues.map((p: any) => LightParcoursPrologueModel.fromJS(p))
      };
      const parcoursSummaryExamples = object.parcoursSummaryExamples.map((p: any) => ParcoursSummaryExampleModel.fromJS(p));
      return new CurrentTextInfosModel(object.title, object.published, object.urlText,
        defi, canvas, parcours, parcoursSummary, parcoursSummaryExamples);
    }
    return undefined;
  }
}
