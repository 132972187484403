import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
const Waypoint = require('react-waypoint');
import Store from '../../stores/Store';
import TextParams from './TextParams';
import TextLicence from './TextLicence';
import DefiRead from './DefiRead';
import Duration from './Duration';
import Toc from './Toc';
import ChapterShare from './ChapterShare';
import ChapterLists from './ChapterLists';
import TextChapterDownload from './TextChapterDownload';
import Thanks from './Thanks';
import TextTitle from './TextTitle';
import TextSummary from './TextSummary';
import TextTalks from './TextTalks';
import TextFavorites from './TextFavorites';
import TextGenresTags from './TextGenresTags';
import TextCover from './TextCover';
import TextRemove from './TextRemove';
import TextReindex from './TextReindex';
import TextStructure from './TextStructure';
import TextAccessibility from './TextAccessibility';
import TransformToChapter from './TransformToChapter';
import ChaptersToPublish from './ChaptersToPublish';
import Feed from '../feed/Feed';
import NotePad from '../write/text/toolbar/NotePad';
import CharactersList from '../write/text/characters/CharactersList';
import AlertModel from '../../models/AlertModel';
import NotConnectedBandeau from '../misc/NotConnectedBandeau';
const date = require('date-and-time');
const countdown = require('countdown');
countdown.setLabels(
  ' milliseconde| seconde| minute| heure| jour| semaine| mois| année| décennie| siècle| millénaire',
  ' millisecondes| secondes| minutes| heures| jours| semaines| mois| années| décennies| siècles| millénaires',
  ' et ',
  ', ',
  'maintenant');

interface TextInfosPageProps {
  store: Store;
  params: TextParams;
}

interface TextInfosPageState {
  enableComponents: boolean;
  countDown: string;
  activeTransformToChapter: boolean;
  activeChaptersToPublish: boolean;
  otherTexts: { id: number, title: string, urlText: string }[];
  activeRemove: boolean;
  activeReindex: boolean;
  strangerMode: boolean;
  showNotePad: boolean;
  showCharacters: boolean;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class TextInfosPage extends React.Component<TextInfosPageProps, TextInfosPageState> {
  _timer: any;

  static fetchData(store: Store, params: TextParams) {
    return store.loadInfos(params.textId).then((d: any) => {
      const p: any[] = [];
      if (store.text.infos.datePublication) {
        p.push(store.loadThanks(params.textId));
        p.push(store.loadTextTalks(params.textId));
      }
      return Promise.all(p);
    });
  }
  constructor(props: TextInfosPageProps) {
    super(props);
    this.showTransformToChapter = this.showTransformToChapter.bind(this);
    this.hideTransformToChapter = this.hideTransformToChapter.bind(this);
    this.showRemove = this.showRemove.bind(this);
    this.showReindex = this.showReindex.bind(this);
    this.hideRemove = this.hideRemove.bind(this);
    this.hideReindex = this.hideReindex.bind(this);
    this.transformToChapter = this.transformToChapter.bind(this);
    this.enableStrangerMode = this.enableStrangerMode.bind(this);
    this.disableStrangerMode = this.disableStrangerMode.bind(this);
    this.publish = this.publish.bind(this);
    this.confirmPublish = this.confirmPublish.bind(this);
    this.cancelPublish = this.cancelPublish.bind(this);
    this.loadMoreFeed = this.loadMoreFeed.bind(this);
    this.showNotes = this.showNotes.bind(this);
    this.hideNotes = this.hideNotes.bind(this);
    this.showCharacters = this.showCharacters.bind(this);
    this.hideCharacters = this.hideCharacters.bind(this);
    this.updateCountDown = this.updateCountDown.bind(this);

    this.state = {
      enableComponents: false,
      countDown: undefined,
      activeTransformToChapter: false,
      activeChaptersToPublish: false,
      otherTexts: [],
      activeRemove: false,
      activeReindex: false,
      strangerMode: false,
      showNotePad: false,
      showCharacters: false
    };
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true,
        countDown: this.state.countDown,
        activeTransformToChapter: this.state.activeTransformToChapter,
        activeChaptersToPublish: this.state.activeChaptersToPublish,
        otherTexts: this.state.otherTexts,
        activeRemove: this.state.activeRemove,
        activeReindex: this.state.activeReindex,
        strangerMode: this.state.strangerMode,
        showNotePad: this.state.showNotePad,
        showCharacters: this.state.showCharacters
      });
      this.startPolling();
    }
  }
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  startPolling() {
    const that = this;
    setTimeout(function () {
      that._timer = setInterval(that.updateCountDown.bind(self), 0);
    }, 1000);
  }
  updateCountDown() {
    const infos = this.props.store.text.infos;
    if (infos && infos.defi && infos.defi.active) {
      const strDeadline = infos.defi.deadline + ' 23:59';
      const now: Date = new Date();
      const deadline: Date = date.parse(strDeadline, 'DD/MM/YYYY HH:mm');

      const countDown = countdown(now, deadline, undefined, 3).toString();

      this.setState({
        enableComponents: this.state.enableComponents,
        countDown: countDown,
        activeTransformToChapter: this.state.activeTransformToChapter,
        activeChaptersToPublish: this.state.activeChaptersToPublish,
        otherTexts: this.state.otherTexts,
        activeRemove: this.state.activeRemove,
        strangerMode: this.state.strangerMode,
        showNotePad: this.state.showNotePad,
        showCharacters: this.state.showCharacters
      });
    }
  }
  showTransformToChapter() {
    this.props.store.getOtherTexts().then((texts: { id: number, title: string, urlText: string }[]) => {
      this.setState({
        enableComponents: this.state.enableComponents,
        countDown: this.state.countDown,
        activeTransformToChapter: true,
        activeChaptersToPublish: this.state.activeChaptersToPublish,
        otherTexts: texts,
        activeRemove: false,
        strangerMode: this.state.strangerMode,
        showNotePad: this.state.showNotePad,
        showCharacters: this.state.showCharacters
      });
    });
  }
  hideTransformToChapter() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: false,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: [],
      activeRemove: false,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  showRemove() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: false,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: true,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  showReindex() {
    this.setState({
      activeReindex: true
    });
  }
  hideRemove() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: false,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: false,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  hideReindex() {
    this.setState({
      activeReindex: false
    });
  }
  transformToChapter(targetTextId: string) {
    this.props.store.transformToChapter(targetTextId).then((d: any) => {
      const urlText = this.state.otherTexts.filter(t => String(t.id) === targetTextId)[0].urlText;
      this.props.store.addAlert(new AlertModel(
        'transformToChapter',
        'Transformé en chapitre !',
        '',
        'success',
        5
      ));
      browserHistory.push({
        pathname: `/text/${targetTextId}/${urlText}`
      });
    });
  }
  enableStrangerMode() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: true,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  disableStrangerMode() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: false,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  publish() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: true,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  confirmPublish(selectedChapters: number[]) {
    this.props.store.text.enableLoading();
    this.props.store.publishText(selectedChapters).then((d: any) => {
      this.props.store.text.disableLoading();
      this.setState({
        enableComponents: this.state.enableComponents,
        countDown: this.state.countDown,
        activeTransformToChapter: this.state.activeTransformToChapter,
        activeChaptersToPublish: false,
        otherTexts: this.state.otherTexts,
        activeRemove: this.state.activeRemove,
        strangerMode: this.state.strangerMode,
        showNotePad: this.state.showNotePad,
        showCharacters: this.state.showCharacters
      });
    });
  }
  cancelPublish() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: false,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: this.state.showCharacters
    });
  }
  loadMoreFeed(_callback?: Function) {
    const beforeDate = this.props.store.text.feed.beforeDate;
    this.props.store.loadMoreTextFeed(this.props.params.textId, beforeDate, _callback);
  }
  showNotes() {
    const textId = this.props.store.text.meta.id;
    this.props.store.loadNotes(textId).then((d: any) => {
      this.setState({
        enableComponents: this.state.enableComponents,
        countDown: this.state.countDown,
        activeTransformToChapter: this.state.activeTransformToChapter,
        activeChaptersToPublish: this.state.activeChaptersToPublish,
        otherTexts: this.state.otherTexts,
        activeRemove: this.state.activeRemove,
        strangerMode: this.state.strangerMode,
        showNotePad: true,
        showCharacters: this.state.showCharacters
      });
    });
  }
  hideNotes() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: this.state.strangerMode,
      showNotePad: false,
      showCharacters: this.state.showCharacters
    });
  }
  showCharacters() {
    const textId = this.props.store.text.meta.id;
    this.props.store.loadCharacters(textId).then((d: any) => {
      this.setState({
        enableComponents: this.state.enableComponents,
        countDown: this.state.countDown,
        activeTransformToChapter: this.state.activeTransformToChapter,
        activeChaptersToPublish: this.state.activeChaptersToPublish,
        otherTexts: this.state.otherTexts,
        activeRemove: this.state.activeRemove,
        strangerMode: this.state.strangerMode,
        showNotePad: this.state.showNotePad,
        showCharacters: true
      });
    });
  }
  hideCharacters() {
    this.setState({
      enableComponents: this.state.enableComponents,
      countDown: this.state.countDown,
      activeTransformToChapter: this.state.activeTransformToChapter,
      activeChaptersToPublish: this.state.activeChaptersToPublish,
      otherTexts: this.state.otherTexts,
      activeRemove: this.state.activeRemove,
      strangerMode: this.state.strangerMode,
      showNotePad: this.state.showNotePad,
      showCharacters: false
    });
  }
  render() {
    const text = this.props.store.text;
    const connected = this.props.store.connected;
    const premium = connected && connected.premium && connected.premium.premium;
    const userId = connected && connected.user && connected.user.id ? connected.user.id : undefined;
    const isAuthor = text && text.meta && text.meta.author
      && userId
      && userId === text.meta.author.id;
    const isAdmin = connected && connected.user && connected.user.admin;

    const canTransformToChapter = text && text.infos && text.infos.structure
      && ((text.infos.structure.chapters && text.infos.structure.chapters.length === 1)
        || (text.infos.structure.parts.length === 1 && text.infos.structure.parts[0].chapters.length === 1))
      && !text.infos.defi;

    const firstChapter = text && text.meta && text.meta.structure ?
      (text.meta.structure.parts && text.meta.structure.parts.length > 0 ?
        text.meta.structure.parts[0].chapters[0]
        : (text.meta.structure.chapters && text.meta.structure.chapters.length > 0 ?
          text.meta.structure.chapters[0]
          : undefined)
      )
      : undefined;
    const chaptersCount = text && text.meta && text.meta.structure ?
      (text.meta.structure.parts && text.meta.structure.parts.length > 0 ?
        [].concat.apply([], text.meta.structure.parts.map(p => (p.chapters as any).toJS())).length
        : text.meta.structure.chapters.length
      )
      : undefined;

    return (
      <div style={{ marginTop: '-30px' }}>
        {text && text.meta && text.infos ?
          <div style={{ backgroundColor: '#f8f8f8', paddingBottom: '80px' }}>
            <div style={{
              backgroundColor: text.meta.backgroundColor,
              paddingTop: '40px',
              paddingBottom: '100px',
              minHeight: '100vh'
            }}>
              <div className="ui container items">
                <div className="ui basic center aligned segment" style={{ color: text.meta.textColor }}>
                  {isAuthor ?
                    <div className="ui text container" style={{ marginBottom: '50px', textAlign: 'left' }}>
                      <div className="ui info message">
                        <div className="header">Vous êtes l'auteur de cette œuvre.</div>
                        {text.meta.published ?
                          <span>Publiée le {text.infos.datePublication}</span>
                          : (premium && text.infos.parcours && text.infos.parcours.active ?
                            <div>
                              Elle est basée sur le parcours <b>{text.infos.parcours.name}</b>.
                              Terminez ce parcours pour pouvoir la publier et la rendre accessible à vos lecteurs !
                            </div>
                            : <span>
                              <ChaptersToPublish
                                showChaptersToPublish={this.state.activeChaptersToPublish}
                                cancelChaptersToPublish={this.cancelPublish}
                                confirmChaptersToPublish={this.confirmPublish}
                              />
                              {text.infos.defi && text.infos.defi.active ?
                                <div>
                                  Vous ne l'avez pas encore publiée. Publiez-la pour la rendre accessible à vos lecteurs ... et réussir le défi !
                                </div>
                                :
                                <div>
                                  Vous ne l'avez pas encore publiée. Publiez-la pour la rendre accessible à vos lecteurs !
                              </div>
                              }
                            </span>
                          )
                        }
                        <div className="ui list">
                          {text.meta.published ?
                            <div className="item">
                              {this.state.strangerMode ?
                                <button className="ui small primary button" onClick={this.disableStrangerMode}>
                                  Quitter le mode "Lecteur"
                              </button>
                                : <button className="ui small primary button" onClick={this.enableStrangerMode}>
                                  Voir en tant que lecteur
                              </button>
                              }
                            </div>
                            :
                            (!text.infos.parcours || !text.infos.parcours.active || !premium ?
                              <div className="item" style={{ textAlign: 'center' }}>
                                <button className="ui small primary button" onClick={this.publish}>
                                  Publier
                                </button>
                              </div>
                              : undefined)
                          }
                        </div>
                        {text.infos.defi && text.infos.defi.active ?
                          <div className="ui basic center aligned segment">
                            <h3 className="ui horizontal divider">
                              En réponse au défi "{text.infos.defi.title}"
                            </h3>
                            <div>
                              {text.infos.defi.deadline && this.state.countDown ?
                                <span>Il vous reste {this.state.countDown} pour publier votre réponse à ce défi.</span>
                                : undefined}
                              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Link
                                  to={`/defis/defi/${text.infos.defi.id}/${text.infos.defi.urlTitle}`}
                                  className="ui mini primary button"
                                >
                                  Voir le détail du défi
                                </Link>
                              </div>
                            </div>
                          </div>
                          : undefined}
                      </div>
                    </div>
                    : undefined
                  }
                  <TextTitle strangerMode={this.state.strangerMode} />
                  <div>
                    <h2>
                      de&nbsp;
                        <Link
                        to={`/author/${text.meta.author.id}/${text.meta.author.urlIdentity}`}
                        style={{ color: text.meta.linkColor }}
                      >
                        <img className="ui image avatar" src={text.meta.author.avatarUrl}
                          alt={`Image de profil de ${text.meta.author.identity}`} />
                        {text.meta.author.identity}
                      </Link>
                    </h2>
                    {text.meta.published ?
                      <Thanks strangerMode={this.state.strangerMode} />
                      : undefined}
                    {text.meta.published && (isAuthor || text.infos.favorites > 0) ?
                      <TextFavorites />
                      : undefined}
                  </div>
                </div>
                {isAuthor && canTransformToChapter && !this.state.strangerMode ?
                  <TransformToChapter
                    active={this.state.activeTransformToChapter}
                    hide={this.hideTransformToChapter}
                    transformToChapter={this.transformToChapter}
                    otherTexts={this.state.otherTexts.map(t => { return { value: String(t.id), text: t.title }; })}
                  />
                  : undefined}
                  { isAdmin ?
                  <TextReindex
                    active={this.state.activeReindex}
                    hide={this.hideReindex}
                  />
                  : undefined}
                {(isAuthor && !this.state.strangerMode) || isAdmin ?
                  <TextRemove
                    active={this.state.activeRemove}
                    hide={this.hideRemove}
                  />
                  : undefined}
                <div className="ui items">
                  <div className="item">
                    {(isAuthor && !this.state.strangerMode) || text.infos.cover ?
                      <TextCover strangerMode={this.state.strangerMode} />
                      : undefined}
                    <div
                      className={(isAuthor && !this.state.strangerMode || text.infos.cover ?
                        'content'
                        : 'ui content text container')}
                    >
                      <TextSummary strangerMode={this.state.strangerMode} />
                      <TextGenresTags strangerMode={this.state.strangerMode} />
                      <TextLicence strangerMode={this.state.strangerMode} />
                      {text.meta.sensibleContent && (!isAuthor || this.state.strangerMode) ?
                        <div className="item" style={{ color: text.meta.textColor }} >
                          <i className="fa fa-fw fa-fire" /> Contenu sensible
                        </div>
                        : undefined
                      }
                      {isAuthor && !this.state.strangerMode ?
                        <TextAccessibility />
                        : undefined
                      }
                      {premium && isAuthor && !this.state.strangerMode ?
                        <NotePad
                          textId={text.meta.id}
                          active={this.state.showNotePad}
                          hide={this.hideNotes}
                        />
                        : undefined}
                      {premium && isAuthor && !this.state.strangerMode ?
                        <CharactersList
                          textId={text.meta.id}
                          active={this.state.showCharacters}
                          hide={this.hideCharacters}
                        />
                        : undefined}
                      {isAuthor && !premium && !this.state.strangerMode ?
                        <div>
                          {canTransformToChapter ?
                            <button
                              onClick={this.showTransformToChapter}
                              className="ui item right floated small button"
                            >
                              Transformer en chapitre
                            </button>
                            : undefined}
                          <button
                            onClick={this.showRemove}
                            className="ui item active red right floated small button"
                          >
                            Supprimer
                            </button>
                        </div>
                        : undefined}
                      
                      {isAdmin ?
                        <button
                          onClick={this.showRemove}
                          className="ui item active red right floated small button"
                        >
                          Supprimer
                            </button>
                        : undefined
                      }
                      {isAdmin ?
                        <button
                          onClick={this.showReindex}
                          className="ui item active green right floated small button"
                        >
                          Réindexer
                            </button>
                        : undefined
                      }
                      {isAuthor && premium && !this.state.strangerMode ?
                        <div className={canTransformToChapter
                          ? 'ui inverted mini four item menu'
                          : 'ui inverted mini three item menu'}>
                          {canTransformToChapter ?
                            <button
                              onClick={this.showTransformToChapter}
                              className="ui item button"
                            >
                              Transformer en chapitre
                            </button>
                            : undefined}
                          <button
                            onClick={() => this.showNotes()}
                            className="ui item button"
                          >
                            Notes
                          </button>
                          <button
                            onClick={() => this.showCharacters()}
                            className="ui item button"
                          >
                            Personnages
                          </button>
                          <button
                            onClick={this.showRemove}
                            className="ui item active red right floated small button"
                          >
                            Supprimer
                            </button>
                        </div>
                        : undefined}
                    </div>
                  </div>
                </div>
                <div className="ui basic center aligned segment" style={{ color: text.meta.textColor }}>
                  <Duration
                    words={text.infos.wordsPublic}
                    chaptersCount={chaptersCount}
                  />
                  {text.meta.published && firstChapter ?
                    <Link
                      to={`/text/${text.meta.id}/${text.meta.urlText}/chapter/${firstChapter.id}`}
                      className="ui large orange button"
                      style={{ marginTop: '10px' }}
                    >
                      Commencer la lecture
                  </Link>
                    : <p>Pas de chapitre disponible</p>}
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: '30px' }}>
              <div className="ui container center aligned">
                {text.meta.published ?
                  <div className="ui vertical fitted compact secondary menu"
                    style={{
                      position: 'fixed',
                      right: '0px',
                      top: '30%',
                      zIndex: 200
                    }}
                  >
                    {connected && connected.user && connected.user.id ?
                      <div className="item">
                        <ChapterLists icon vertical />
                      </div>
                      : undefined}
                    {text.meta.openToExport ?
                      <div className="item">
                        <TextChapterDownload icon vertical />
                      </div>
                      : undefined}
                    <div className="item">
                      <ChapterShare textTitle={text.meta.title} type="facebook" vertical />
                    </div>
                    <div className="item">
                      <ChapterShare textTitle={text.meta.title} type="twitter" vertical />
                    </div>
                  </div>
                  : undefined}
                <div>
                  {isAuthor ?
                    <TextStructure strangerMode={this.state.strangerMode} />
                    : <Toc
                      textId={this.props.params.textId}
                      urlText={this.props.params.urlText}
                      structure={text.meta.structure}
                    />
                  }
                </div>
                {text.infos.defi ?
                  <div>
                    <h3 className="ui section horizontal section large divider">
                      <i className="fa fa-fw fa-trophy" style={{ marginRight: '10px' }} />
                      En réponse au défi
                  </h3>
                    <div className="ui text container" style={{ textAlign: 'left' }}>
                      <DefiRead
                        defi={text.infos.defi}
                        isAuthor={isAuthor}
                        strangerMode={this.state.strangerMode}
                        userId={userId}
                      />
                    </div>
                  </div>
                  : undefined}
                {text.meta.published ?
                  <div style={{ overflow: 'auto' }}>
                    <h3 className="ui section horizontal section large divider">
                      <i className="fa fa-comment-o" style={{ marginRight: '10px' }} />
                      Commentaires & Discussions
                  </h3>
                    <TextTalks />
                  </div>
                  : undefined}
                {text.meta.published && this.state.enableComponents ?
                  <div>
                    <Waypoint
                      key={'feed' + text.meta.id}
                      onEnter={() => this.props.store.loadTextFeed(this.props.params.textId)}
                      scrollableAncestor={window}
                    />
                    <h3 className="ui section horizontal section large divider">
                      <i className="fa fa-feed" style={{ marginRight: '10px' }} />
                      Activité
                  </h3>
                    <div className="ui text container" style={{ textAlign: 'left' }}>
                      <Feed
                        feed={text.feed}
                        withForm={false}
                        loadMoreFeed={this.loadMoreFeed}
                      />
                    </div>
                  </div>
                  : undefined}
              </div>
            </div>
            <NotConnectedBandeau
              title="Des milliers d'œuvres vous attendent."
              body="Sur l'Atelier des auteurs, dénichez des pépites littéraires et aidez leurs auteurs à les améliorer grâce à vos commentaires."
            />
          </div>
          : undefined}
      </div>
    );
  }
}
