import { observable } from 'mobx';

export default class SettingsNotificationsModel {

  @observable email: boolean;
  @observable desktop: boolean;

  @observable loading: boolean;

  constructor(email: boolean, desktop: boolean) {
    this.email = email;
    this.desktop = desktop;
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  toJS() {
    return {
      email: this.email,
      desktop: this.desktop
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SettingsNotificationsModel(object.email, object.desktop);
    }
    return undefined;
  }
}
