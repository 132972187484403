import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import { inject, observer } from 'mobx-react';
const InfiniteScroll = require('react-infinite-scroll-component');
import Store from '../../stores/Store';
import { CallBackFunction } from 'react-notification-system';

interface ChatUsersProps {
  store?: Store;
}

interface ChatUsersState {
  loadingDiscussionsError: boolean;
  loadingDiscussions: boolean;
}

@inject('store') @observer
export default class ChatUsers extends React.Component<ChatUsersProps, ChatUsersState> {
  constructor(props: ChatUsersProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.enableNewMode = this.enableNewMode.bind(this);
    this.state = {
      loadingDiscussionsError: false,
      loadingDiscussions: false
    };
  }

  async syncDiscussionloader() {
    this.props.store.loadAllDiscussions(false, (failed: boolean)=>{
      this.setState(
      {
        loadingDiscussionsError: failed,
        loadingDiscussions: false
      }
    )
  });
  }
  
  loadMore() {
    this.setState({
      loadingDiscussions: true
    },
    () => {this.syncDiscussionloader()}    
    );
  }

  enableNewMode() {
    browserHistory.push({
      pathname: '/messages'
    });
  }
  
  render() {
    const allDiscussions = this.props.store.chat.allDiscussions;
    const discussions = allDiscussions.discussions.map(d => {
      const allUsers = d.targetUsers.map(u => u.identity).join(', ');
      return (
        <div key={Math.random() * 1000} className={'item' + (!d.read ? ' orange' : '')}>
          <Link to={`/messages/discussion/${d.id}`} className="ui mini rounded image">
            <img
              src={d.targetUsers[0].avatarUrl}
              alt={`Image de profil de ${d.targetUsers[0].identity}`}
            />
          </Link>
          <div className="content">
            <Link to={`/messages/discussion/${d.id}`} className="header">
              {allUsers}
            </Link>
            <Link to={`/messages/discussion/${d.id}`} className="description">
              {d.lastMessageDate}
            </Link>
          </div>
        </div>
      );
    });

    const endMessage = () => {
      let message;
      let error;
      if (!allDiscussions.end){
        if (this.state.loadingDiscussions){
          message = <h4>Chargement... </h4>
        }
        else{
          message = <button className="ui button" onClick={
            () => {this.setState({
              loadingDiscussions: true
          }, () => {this.loadMore()}    
          )}}>Charger plus</button>
        }
        if (this.state.loadingDiscussionsError){
          error = <h4>Erreur, veuillez réessayer</h4>
        }
      }
      else{
        message = <span>Pas d'autre discussion</span>
      }
    
      return <div  style={{ marginBottom: '50px' }} className="ui large feed centered grid">{error}{message}</div>

    }


    return (
      <div>
        <Link
          className="ui fluid primary button"
          to="/messages/new"
        >
          Nouvelle discussion
        </Link>
        {discussions.length > 0 ?
          <div className="ui segment" style={{ height: '600px', overflowY: 'scroll', overflowX: 'hidden'}}>
            <InfiniteScroll
              next={this.loadMore}
              hasMore={false}
              loader={<h4>Chargement..</h4>}
              endMessage={endMessage()}
            >
              <div className="ui very relaxed list selection">
                {discussions}
              </div>
            </InfiniteScroll>
          </div>
          : <h4>Aucune discussion</h4>
        }
      </div>
    );
  }
}