import { observable } from 'mobx';
import CanvasChapterModel from './CanvasChapterModel';

export default class CanvasPartModel {

  @observable title: string;
  @observable chapters: CanvasChapterModel[];

  constructor(title: string, chapters: CanvasChapterModel[]) {
    this.title = title;
    this.chapters = chapters;
  }

  toJS() {
    return {
      title: this.title,
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const chapters = object.chapters.map((c: any) => CanvasChapterModel.fromJS(c));
      return new CanvasPartModel(object.title, chapters);
    }
    return undefined;
  }
}
