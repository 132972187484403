import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import RelatedTalkModel from '../../models/talk/RelatedTalkModel';
import TalkPageForm from '../talk/TalkPageForm';
import TextParams from './TextParams';

interface NewTalkTextPageProps {
  store?: Store;
  params: TextParams;
}

@inject('store') @observer
export default class NewTalkTextPage extends React.Component<NewTalkTextPageProps, {}> {
  static fetchData(store: Store, params: TextParams) {
    return store.loadMeta(params.textId);
  }
  constructor(props: NewTalkTextPageProps) {
    super(props);
    this.createNewDiscussion = this.createNewDiscussion.bind(this);
  }
  createNewDiscussion(title: string, message: string, tags: string[], linked: string[]) {
    const meta = this.props.store.text.meta;
    this.props.store.createNewDiscussion(
      title, message, tags, linked, this.props.params.textId, undefined
    ).then((t: RelatedTalkModel) => {
      browserHistory.push({
        pathname: `/text/${meta.id}/${meta.urlText}/talks/${t.id}/${t.urlTitle}`
      });
    });
  }
  render() {
    const meta = this.props.store.text.meta;
    const title = meta ? `Nouvelle discussion sur l'œuvre ${meta.title}` : '';
    return (
      <div className="ui read container" style={{ paddingBottom: '80px' }}>
        {meta ?
          <div>
            <Title title={title + ' '} />
            <h2 className="ui header">{title}</h2>
            <TalkPageForm submit={this.createNewDiscussion} text={{ id: meta.id, urlText: meta.urlText }} />
          </div>
          : undefined}
      </div>
    );
  }
}
