import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
import { Line } from 'react-chartjs-2';
import { getLexicalLabelForValue } from '../../../../utils/relecture/lisibilite';

interface LexicalProps {
  store?: Store;
  chapterId?: number;
}

@inject('store') @observer
export default class Lexical extends React.Component<LexicalProps, {}> {
  render() {
    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsWrite;
    const filteredStatistics = statistics.filter(stat => stat.chapter.title !== "");
    const rows = filteredStatistics.map((statistic) => {
      if (statistic.stat && statistic.stat.statistic !== undefined && statistic.stat.statistic !== null && statistic.exists && statistic.chapter.title !== "") {
        return (
          <tr key={`stat_lexical_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId == statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td className="right aligned">
              <span>{Math.round(statistic.stat.statistic * 100) / 100}</span>
            </td>
            <td>{getLexicalLabelForValue(statistic.stat.statistic)}</td>
          </tr>
        )
      } else if (statistic.chapter.title !== ""){
        return (
          <tr key={`stat_lexical_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId === statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td colSpan={2}>
              {statistic.exists ?
                <span><span className="ui small active inline loader" style={{ marginRight: '10px' }} /> Calcul en cours...</span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      }
      else {
        return(undefined)
      }
    });
    const stillLoading = filteredStatistics.filter(stat => (!stat.stat || stat.stat.statistic == null) && stat.exists).length > 0;
    const withValues = filteredStatistics.filter(stat => stat.stat && stat.stat.statistic && stat.exists).length > 0;
    if (!stillLoading) {
      const values = filteredStatistics.filter(stat => stat.stat && stat.stat.statistic && stat.exists).map(s => s.stat.statistic);
      const avg = values.length > 0 ? Math.round(values.reduce((a, b) => a + b) / values.length) : 0;
      if (values.length > 0) {
        rows.push(
          <tr key="stat_lexical_total">
            <td><b>Moyenne</b></td>
            <td className="right aligned">
              <span><b>{avg}</b></span>
            </td>
            <td><b>{getLexicalLabelForValue(avg)}</b></td>
          </tr>
        );
      } else {
        rows.push(
          <tr key="stat_dur_total">
            <td><b>Moyenne</b></td>
            <td colSpan={2}>
              -
            </td>
          </tr>
        );
      }
    }

    let data = {};
    if (!stillLoading && withValues) {
      const labels = filteredStatistics.filter(stat => stat.stat && stat.exists).map(stat => `${stat.chapter.title.substring(0, 25)}`);
      const values = filteredStatistics.filter(stat => stat.stat && stat.exists).map(stat => Math.round(stat.stat.statistic * 100) / 100);
      data = {
        labels: labels,
        datasets: [
          {
            label: 'Richesse lexicale',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            fill: false,
            data: values
          }
        ]
      };
    }

    return (
      <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
        <div className="seven wide column" style={{ paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <table className="ui center aligned celled table">
                <thead>
                  <tr>
                    <th>-</th>
                    <th colSpan={2}>Richesse lexicale</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
              <div className="ui info message">
                <div className="header">Pourquoi cet indicateur est important</div>
                <p>La richesse lexicale représente la diversité de mots que vous employez dans un texte. Plus cet indicateur est élevé et plus un texte est riche sur le plan lexical.</p>
                <p>Cet indicateur peut être important selon vos objectifs en termes de lisibilité et de style.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="nine wide column">
          <div className="ui grid stackable">
            <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '45px' }}>
              {stillLoading ?
                <Loader message="Calcul des statistiques en cours..." />
                : (withValues ?
                  <Line data={data} />
                  : <div className="ui message">
                    Pas de graphique (chapitres vides)
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
