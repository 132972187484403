import * as React from 'react';

interface MentionListItemProps {
  callback: any;
  itemId: string;
  itemIdentity: string;
  word: string;
  focus: boolean;
}

export default class MentionListItem extends React.Component<MentionListItemProps, {}> {
  constructor(props: MentionListItemProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e: any) {
    e.preventDefault();
    this.props.callback(this.props.itemId, this.props.itemIdentity, this.props.word);
  }
  render() {
    const classes = this.props.focus
      ? 'focus'
      : '';
    return (
      <li className="mention__list__item">
        <a
          id={this.props.itemId}
          className={classes}
          ref="selectable"
          href="#"
          onClick={this.handleClick}
        >
          {this.props.itemIdentity}
        </a>
      </li>
    );
  }
}
