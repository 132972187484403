import { observable } from 'mobx';

export default class WritingProgramObjectiveModel {

  @observable objectiveId: number;
  @observable resultId: number;
  @observable linkedText: {
    id: number;
    title: string;
    urlText: string;
  };
  @observable objWords: number;
  @observable objDefis: number;
  @observable objTexts: number;
  @observable objChapters: number;
  @observable objTrainings: number;
  @observable words: number;
  @observable defis: number;
  @observable texts: number;
  @observable chapters: number;
  @observable trainings: number;

  constructor(objectiveId: number, resultId: number, linkedText: { id: number, title: string, urlText: string },
    objWords: number, objDefis: number, objTexts: number, objChapters: number, objTrainings: number,
    words: number, defis: number, texts: number, chapters: number, trainings: number) {
    this.objectiveId = objectiveId;
    this.resultId = resultId;
    this.linkedText = linkedText;
    this.objWords = objWords;
    this.objDefis = objDefis;
    this.objTexts = objTexts;
    this.objChapters = objChapters;
    this.objTrainings = objTrainings;
    this.words = words;
    this.defis = defis;
    this.texts = texts;
    this.chapters = chapters;
    this.trainings = trainings;
  }

  toJS() {
    return {
      objectiveId: this.objectiveId,
      resultId: this.resultId,
      linkedText: this.linkedText,
      objWords: this.objWords,
      objDefis: this.objDefis,
      objTexts: this.objTexts,
      objChapters: this.objChapters,
      objTrainings: this.objTrainings,
      words: this.words,
      defis: this.defis,
      texts: this.texts,
      chapters: this.chapters,
      trainings: this.trainings
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new WritingProgramObjectiveModel(object.objectiveId, object.resultId,
        object.linkedText, object.objWords, object.objDefis, object.objTexts, object.objChapters, object.objTrainings,
        object.words, object.defis, object.texts, object.chapters, object.trainings);
    }
    return undefined;
  }
}
