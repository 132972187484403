import * as React from 'react';
import { Link } from 'react-router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import CalendarHeatmap from 'react-calendar-heatmap';
import Store from '../../stores/Store';
import ProfileParams from './ProfileParams';
import Loader from '../misc/Loader';
import ProfileBadges from './ProfileBadges';
import ProfileHeatMap from '../../models/profile/ProfileHeatMap';
import HeatMapDetail from './HeatMapDetail';

interface ProfileActivityProps {
  store: Store;
  params: ProfileParams;
}

@inject('store') @observer
export default class ProfileActivity extends React.Component<ProfileActivityProps, {}> {
  _timer: any;

  static fetchData(store: Store, params: ProfileParams) {
    return store.loadProfileActivity(params.authorId);
  }
  constructor(props: ProfileActivityProps) {
    super(props);
    this.onHeatMapClick = this.onHeatMapClick.bind(this);
    this.getHeatMapClassForValue = this.getHeatMapClassForValue.bind(this);
    this.startPolling = this.startPolling.bind(this);
    this.loadProfileStats = this.loadProfileStats.bind(this);
  }
  componentDidMount() {
    this.startPolling();
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that._timer = setInterval(that.loadProfileStats.bind(self), 1000);
    }, 1000);
  }
  loadProfileStats() {
    this.props.store.loadProfileStats(this.props.params.authorId).then((d: any) => {
      if (this.props.store.profile.statistics && this.props.store.profile.statistics.myFavorites !== undefined) {
        clearInterval(this._timer);
        this._timer = null;
      }
    });
  }
  @action
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  onHeatMapClick(heatMap: ProfileHeatMap) {
    if (heatMap) {
      return this.props.store.loadProfileHeatMapDetail(heatMap.date);
    } else {
      this.props.store.profile.heatMapDetail = undefined;
    }
  }
  getHeatMapClassForValue(heatMap: ProfileHeatMap) {
    if (heatMap) {
      if (heatMap.count > 15) {
        return 'color-github-4';
      }
      if (heatMap.count > 10) {
        return 'color-github-3';
      }
      if (heatMap.count > 5) {
        return 'color-github-2';
      }
      return 'color-github-1';
    }
    return 'color-github-0';
  }
  render() {
    const profile = this.props.store.profile;
    const badges = profile.badges;
    const statistics = profile.statistics;
    const defis = profile.proposedDefis.map((d) => {
      return (
        <div key={`defi_${d.id}`} className="item">
          <i className="fa fa-trophy icon"></i>
          <div className="content">
            <Link className="header" to={`/defis/defi/${d.id}/${d.urlTitle}`}>
              {d.title}
            </Link>
          </div>
        </div>
      );
    });

    const now = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return (
      <div className="ui text container" style={{ paddingTop: '20px' }}>
        {badges ?
          <div>
            {statistics && statistics.myFavorites !== undefined ?
              <div>
                <h3 className="ui section horizontal large divider">
                  Statistiques
              </h3>
                <div className="ui basic segment two column stackable grid">
                  <div className="column">
                    <table className="ui definition table">
                      <tbody>
                        <tr>
                          <td>Œuvres publiées</td>
                          <td>{statistics.texts}</td>
                        </tr>
                        <tr>
                          <td>Défis réussis</td>
                          <td>{statistics.defis}</td>
                        </tr>
                        <tr>
                          <td>"J'aime" reçus</td>
                          <td>{statistics.favorites}</td>
                        </tr>
                        <tr>
                          <td>Commentaires reçus</td>
                          <td>{statistics.comments}</td>
                        </tr>
                        <tr>
                          <td>Annotations reçues</td>
                          <td>{statistics.annotations}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="column">
                    <table className="ui definition table">
                      <tbody>
                        <tr>
                          <td>Lectures reçues</td>
                          <td>{statistics.views}</td>
                        </tr>
                        <tr>
                          <td>Défis proposés</td>
                          <td>{statistics.defisProposes}</td>
                        </tr>
                        <tr>
                          <td>Œuvres appréciées</td>
                          <td>{statistics.myFavorites}</td>
                        </tr>
                        <tr>
                          <td>Commentaires publiés</td>
                          <td>{statistics.myComments}</td>
                        </tr>
                        <tr>
                          <td>Annotations publiées</td>
                          <td>{statistics.myAnnotations}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              : <Loader message="Calcul des statistiques..." />
            }
            <div>
              <h3 className="ui section horizontal large divider">
                Tableau d'activité
              </h3>
              <CalendarHeatmap
                startDate={oneYearAgo}
                endDate={now}
                values={(profile.heatMap as any).toJS()}
                onClick={this.onHeatMapClick}
                classForValue={this.getHeatMapClassForValue}
              />
              {profile.heatMapDetail ?
                <HeatMapDetail heatMapDetail={profile.heatMapDetail} />
                : undefined}
            </div>
            {defis.length > 0 ?
              <div>
                <h3 className="ui section horizontal large divider">
                  Défis proposés
              </h3>
                <div className="ui relaxed list">
                  {defis}
                </div>
              </div>
              : undefined}
            <div>
              <h3 className="ui section horizontal large divider">
                Badges
              </h3>
              <div className="ui center aligned container">
                <ProfileBadges badges={profile.badges} />
              </div>
            </div>
          </div>
          : <Loader message="Chargement..." />
        }
      </div>
    );
  }
}
