import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface SettingsPageProps {
  store: Store;
  location: any;
}

interface SettingsPageState {
  activeTab: string;
}

@inject('store') @observer
export default class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
  constructor(props: SettingsPageProps) {
    super(props);
    const paths = props.location.pathname.split('/');

    let activeTab: string;
    if (paths.length === 3) {
      activeTab = paths[paths.length - 1];
    } else {
      activeTab = 'preferences';
    }

    this.state = { activeTab: activeTab };
    this.onTabChange = this.onTabChange.bind(this);
  }
  onTabChange(newTab: string) {
    if (newTab) {
      let activeTab = '';
      if (newTab !== 'preferences') {
        activeTab = `/${newTab}`;
      }
      this.setState({ activeTab: newTab });
      browserHistory.push({
        pathname: `/settings${activeTab}`
      });
    }
  }
  render() {
    const connected = this.props.store.connected;
    return (
      <div className="ui grid" style={{ backgroundColor: '#fff'}}>
        <div className="ui text container" style={{ minHeight: '90vh', paddingTop: '30px', paddingBottom: '160px' }}>
          <Title title="Paramètres " />
          <h1>Paramètres</h1>
          <Menu
            secondary
            pointing
            stackable
          >
            <Menu.Item name="preferences" active={this.state.activeTab === 'preferences'}
              onClick={() => this.onTabChange('preferences')}>
              Préférences
            </Menu.Item>
            <Menu.Item name="account" active={this.state.activeTab === 'account'}
              onClick={() => this.onTabChange('account')}>
              Compte
            </Menu.Item>
            <Menu.Item name="emails" active={this.state.activeTab === 'emails'}
              onClick={() => this.onTabChange('emails')}>
              Emails
            </Menu.Item>
            <Menu.Item name="notifications" active={this.state.activeTab === 'notifications'}
              onClick={() => this.onTabChange('notifications')}>
              Notifications
            </Menu.Item>
            <Menu.Item name="premium" active={this.state.activeTab === 'premium'}
              onClick={() => this.onTabChange('premium')}>
              L'Académie
            </Menu.Item> 
            {connected && connected.user && connected.user.admin ?
              <Menu.Item name="admin" active={this.state.activeTab === 'admin'}
                onClick={() => this.onTabChange('admin')}>
                Communication
            </Menu.Item>
              : undefined}
            {connected && connected.user && connected.user.admin ?
              <Menu.Item name="admin_search" active={this.state.activeTab === 'admin_search'}
                onClick={() => this.onTabChange('admin_search')}>
                Recherche
            </Menu.Item>
              : undefined}
          </Menu>
          {this.props.store.mainSettings.loading ?
            <div className="ui sixteen wide column grid row">
              <div className="ui container">
                <Loader message="Chargement..." />
              </div>
            </div>
            : this.props.children
          }
        </div>
      </div>
    );
  }
}
