const helpers = require('../helpers');

export default function lemmatize(that: { foundTokensInDicts: any[] }) {
  const lemmas: any[] = [];
  let currentWord = null;
  that.foundTokensInDicts.forEach((wordObj: any, index: number) => {
    currentWord = {
      id: wordObj.id,
      word: wordObj.word,
      lemma: wordObj.lemma,
      stop: wordObj.stop
    };
    lemmas.push(currentWord);
  });
  return helpers.removeDuplicateObjects(lemmas);
}
