const Snowball = require('snowball');

export function stem(that: { tokenized: any[] }) {
  const stemmer = new Snowball('French');
  const stemmedObjects: any[] = [];
  that.tokenized.forEach((token, index) => {
    stemmer.setCurrent(token);
    stemmer.stem();
    const stem = stemmer.getCurrent();
    stemmedObjects.push({
      id: index,
      word: token,
      stem: stem
    });
  });
  return stemmedObjects;
}

export function stemWord(word: string) {
  const stemmer = new Snowball('French');
  stemmer.setCurrent(word);
  stemmer.stem();
  const stem = stemmer.getCurrent();
  return {
    word: word,
    stem: stem
  };
}
