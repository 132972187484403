import { observable } from 'mobx';
import SearchItemModel from './SearchItemModel';
import SearchCategoryEnum from './SearchCategoryEnum';

export default class SearchModel {

  @observable searching: boolean;
  @observable count: number;
  @observable end: boolean;
  @observable category: SearchCategoryEnum;
  @observable listValues: SearchItemModel[];
  @observable pageValues: SearchItemModel[];

  constructor(searching: boolean, count: number, end: boolean,
    category: SearchCategoryEnum, listValues: SearchItemModel[], pageValues: SearchItemModel[]) {
    this.searching = searching;
    this.count = count;
    this.end = end;
    this.category = category;
    this.listValues = listValues || [];
    this.pageValues = pageValues || [];
  }

  startSearch() {
    this.searching = true;
  }

  resetListValues() {
    this.searching = false;
    this.listValues = [];
  }

  resetPageValues() {
    this.searching = false;
    this.pageValues = [];
    this.count = 0;
    this.end = false;
  }

  setListValues(listValues: SearchItemModel[]) {
    this.listValues = listValues;
    this.searching = false;
  }

  setPageValues(pageValues: SearchItemModel[]) {
    this.pageValues = pageValues;
    this.searching = false;
    this.count = this.count + 1;
    this.end = false;
    if (pageValues.length < 20) {
      this.end = true;
    }
  }

  addPageValues(pageValues: SearchItemModel[]) {
    this.pageValues = this.pageValues.concat(pageValues);
    this.count = this.count + 1;
    if (pageValues.length < 20) {
      this.end = true;
    }
  }

  toJS() {
    return {
      searching: this.searching,
      count: this.count,
      end: this.end,
      category: this.category.toString,
      listValues: this.listValues.map(v => v.toJS()),
      pageValues: this.pageValues.map(v => v.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const category: SearchCategoryEnum = (<any>SearchCategoryEnum)[object.category];
      const listValues = object.listValues.map((u: any) => SearchItemModel.fromJS(u));
      const pageValues = object.pageValues.map((u: any) => SearchItemModel.fromJS(u));

      return new SearchModel(object.searching, object.count,
        object.end, category, listValues, pageValues);
    }
    return undefined;
  }
}
