import { observable } from 'mobx';

export default class StructureChapterModel {

  @observable id: number;
  @observable title: string;
  @observable index: number;
  @observable status: string;
  @observable step: number;
  @observable description: string;
  @observable empty: boolean;
  @observable views: number;
  @observable favorites: number;
  @observable comments: number;
  @observable annotations: number;

  constructor(id: number, title: string, index: number, status: string, step: number, description: string,
    empty: boolean, views: number, favorites: number, comments: number, annotations: number) {
    this.id = id;
    this.title = title;
    this.index = index;
    this.status = status;
    this.step = step;
    this.description = description;
    this.empty = empty;
    this.views = views;
    this.favorites = favorites;
    this.comments = comments;
    this.annotations = annotations;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      index: this.index,
      status: this.status,
      step: this.step,
      description: this.description,
      empty: this.empty,
      views: this.views,
      favorites: this.favorites,
      comments: this.comments,
      annotations: this.annotations
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new StructureChapterModel(object.id, object.title, object.index,
        object.status, object.step, object.description, object.empty,
        object.views, object.favorites, object.comments, object.annotations);
    }
    return undefined;
  }
}
