import * as React from 'react';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Feed from '../feed/Feed';
import Suggestions from './Suggestions';
import Loader from '../misc/Loader';

interface PinTabProps {
  store?: Store;
  tab: number;
}

@inject('store') @observer
class PinTab extends React.Component<PinTabProps, {}> {
  constructor(props: PinTabProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(e: any) {
    e.preventDefault();
    this.props.store.pinFeedTab(this.props.tab);
  }
  render() {
    const connected = this.props.store.connected;
    const pinTab = connected && connected.preferences && connected.preferences.feedTab ?
      connected.preferences.feedTab
      : 1;
    const tabSelected = pinTab === this.props.tab;
    return (
      <span
        data-tooltip={tabSelected ?
          'Cet onglet est affiché par défaut.'
          : 'Afficher cet onglet par défaut'
        }
        data-inverted="" data-position="bottom center"
        style={{ marginLeft: '10px' }}
      >
        <button
          className={'ui icon basic ' + (tabSelected ? 'active disabled ' : '') + ' button'}
          onClick={this.onClick}
        >
          <i className="fa fa-thumb-tack" />
        </button>
      </span>
    );
  }
}

interface HomeFeedProps {
  store?: Store;
}

interface HomeFeedState {
  onlyNewMembers: boolean;
  onlyTextsAndChapters: boolean;
  onlyDiaries: boolean;
}

@inject('store') @observer
export default class HomeFeed extends React.Component<HomeFeedProps, HomeFeedState> {
  constructor(props: HomeFeedProps) {
    super(props);
    props.store.loadFeed();
    this.loadMoreFeed = this.loadMoreFeed.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onlyNewMembers = this.onlyNewMembers.bind(this);
    this.onlyTextsAndChapters = this.onlyTextsAndChapters.bind(this);
    this.onlyDiaries = this.onlyDiaries.bind(this);
    this.state = {
      onlyNewMembers: false,
      onlyTextsAndChapters: false,
      onlyDiaries: false
    };
  }
  loadMoreFeed(_callback?: Function) {
    const activeTab = this.props.store.connected.activeTab;
    const beforeDate = this.props.store.home.feed.beforeDate;
    const feedType = activeTab === 1 ? 'all' : 'mine';
    if (!_callback)
      this.props.store.loadMoreFeed(feedType, this.state.onlyNewMembers,
        this.state.onlyTextsAndChapters, this.state.onlyDiaries, beforeDate);
    else
      this.props.store.loadMoreFeed(feedType, this.state.onlyNewMembers,
        this.state.onlyTextsAndChapters, this.state.onlyDiaries, beforeDate, _callback);
  }
  @action
  onTabChange(newTab: number) {
    const feedType = newTab === 1 ? 'all' : 'mine';
    this.props.store.loadFeed(feedType, this.state.onlyNewMembers,
    this.state.onlyTextsAndChapters, this.state.onlyDiaries);
    this.props.store.connected.activeTab = newTab;
     
  }
  onlyNewMembers() {
    const activeTab = this.props.store.connected.activeTab;
    const feedType = activeTab === 1 ? 'all' : 'mine';
    this.props.store.loadFeed(feedType, !this.state.onlyNewMembers, false, false);
    this.setState({
      onlyNewMembers: !this.state.onlyNewMembers,
      onlyTextsAndChapters: false,
      onlyDiaries: false
    });
  }
  onlyTextsAndChapters() {
    const activeTab = this.props.store.connected.activeTab;
    const feedType = activeTab === 1 ? 'all' : 'mine';
    this.props.store.loadFeed(feedType, false, !this.state.onlyTextsAndChapters, false);
    this.setState({
      onlyNewMembers: false,
      onlyTextsAndChapters: !this.state.onlyTextsAndChapters,
      onlyDiaries: false
    });
  }
  onlyDiaries() {
    const activeTab = this.props.store.connected.activeTab;
    const feedType = activeTab === 1 ? 'all' : 'mine';
    this.props.store.loadFeed(feedType, false, false, !this.state.onlyDiaries);
    this.setState({
      onlyNewMembers: false,
      onlyTextsAndChapters: false,
      onlyDiaries: !this.state.onlyDiaries
    });
  }
  render() {
    const connected = this.props.store.connected;
    const activeTab = connected && connected.activeTab ?
      connected.activeTab
      : 1;
    return (
      <div>
          <h2 className="ui horizontal ada-museo-300 divider">
            <i className="fa fa-fw fa-feed" style={{ marginRight: '5px' }} />
            Fil d'actualités
        </h2>
        <Menu
          activeIndex={activeTab}
          secondary
          pointing
          fluid
          widths={2}
          className="stackable"
        >
          <Menu.Item id="feed_all" name="1" active={activeTab === 1} className="nopadding"
            onClick={() => this.onTabChange(1)}>
            Toute l'actualité
            {activeTab === 1 ?
              <PinTab tab={1} />
              : undefined}
          </Menu.Item>
          <Menu.Item id="feed_mine" name="2" active={activeTab === 2} className="nopadding"
            onClick={() => this.onTabChange(2)}>
            Mes abonnements et lectures
              {activeTab === 2 ?
              <PinTab tab={2} />
              : undefined}
          </Menu.Item>
        </Menu>
        {this.props.store.home.loading ?
          <Loader message="Chargement..." />
          :
          <div>
            <div className="ui basic segment">
              {activeTab === 1 ?
                <button className={'ui olive ' + (!this.state.onlyNewMembers ? 'basic' : '') + ' button'}
                  onClick={this.onlyNewMembers}>
                  À accueillir !
                </button>
                  : undefined}
                <button className={'ui green ' + (!this.state.onlyTextsAndChapters ? 'basic' : '') + ' button'}
                  onClick={this.onlyTextsAndChapters}>
                  Œuvres et chapitres
                </button>
            </div>
            {this.props.store.home.feed.elements.length > 0 ?
              <Feed
                feed={this.props.store.home.feed}
                withForm={activeTab === 1}
                loadMoreFeed={this.loadMoreFeed}
              />
              : activeTab === 1 ?
              <div className="ui message">
                  Aucun résultat !
              </div>
              :
              <div>
                <div className="ui message">
                  Abonnez-vous à d'autres auteurs et ajoutez des œuvres dans vos listes
                de lecture pour remplir ce panneau !
              </div>
                <Suggestions />
              </div>
            }
          </div>
        }
      </div>
    );
  }
}
