import { observable } from 'mobx';
const DraftJs = require('draft-js');
const EditorState = DraftJs.EditorState;
import convertFromHtml from '../../components/editor/convertFromHtml';
import convertToHtml from '../../components/editor/convertToHtml';
import TrainingInstructionsModel from './TrainingInstructionsModel';

export default class TrainingModel {

  @observable instructions: TrainingInstructionsModel;
  @observable textContentState: any;

  constructor(instructions: TrainingInstructionsModel) {
    this.instructions = instructions;
    let body: string;
    if (instructions && instructions.constraint && instructions.constraint.incipit) {
      body = `<p>${instructions.constraint.incipit.text}</p>`;
    } else if (instructions && instructions.constraint && instructions.constraint.explicit) {
      body = `<p>${instructions.constraint.explicit.text}</p>`;
    } else {
      body = '<p></p>';
    }

    const contentState = convertFromHtml(body);
    this.textContentState = EditorState.createWithContent(contentState);
  }

  updateTextContentState(newTextContentState: any) {
    this.textContentState = newTextContentState;
  }

  getHTML() {
    return convertToHtml(this.textContentState);
  }

  getRaw() {
    return this.textContentState.getCurrentContent().getPlainText().trim();
  }

  toJS() {
    return {
      instructions: this.instructions
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new TrainingModel(object.instructions);
    }
    return undefined;
  }
}
