import * as React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Async } from 'react-select';
const throttle = require('throttle-debounce').throttle;
import Store from '../../stores/Store';
import MessageModel from '../../models/chat/MessageModel';

declare const __CLIENT__: any;

interface ChatNewDiscussionProps {
  store?: Store;
  location: { query: number };
}

interface ChatNewDiscussionState {
  enableComponents: boolean;
  loading: boolean;
  message: string;
  remainingCharacters: number;
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@inject('store') @observer
export default class ChatNewDiscussion extends React.Component<ChatNewDiscussionProps, ChatNewDiscussionState> {
  refs: {
    [string: string]: any;
    chat: any;
  };
  static fetchData(store: Store, params: void, query: { id: number }) {
    if (query && query.id) {
      store.loadProfileLight(query.id).then((targetUser: any) => {
        return store.resetDiscussion(targetUser);
      });
    } else {
      return store.resetDiscussion();
    }
  }
  constructor(props: ChatNewDiscussionProps) {
    super(props);
    this.fetchUsers = throttle(1000, this.fetchUsers.bind(this));
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.postMessage = this.postMessage.bind(this);
    this.enableNewMode = this.enableNewMode.bind(this);
    this.state = {
      enableComponents: false,
      loading: false,
      message: '',
      remainingCharacters: 5000
    };
  }
  fetchUsers(input: string, callback: Function) {
    this.props.store.getRelations(input).then((relations: any) => {
      const options = relations.map((r: any) => ({
        value: r.value,
        label: r.label,
      }));

      callback(null, { options });
    });
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true,
        message: this.state.message
      });
    }
  }
  handleUsersChange(value: { label: string, value: string }[]) {
    this.props.store.chat.discussion.targetUsers = value;
  }
  handleTextChange(e: any) {
    const message = e.target.value;
    this.setState({
      enableComponents: true,
      message: message,
      remainingCharacters: 5000 - message.length
    });
  }
  postMessage(event: any) {
    event.preventDefault();
    if (this.state.remainingCharacters >= 0 && this.state.message.trim().length > 0
      && this.props.store.chat.discussion.targetUsers.length > 0) {
      this.setState({
        loading: true
      });
      const users = this.props.store.chat.discussion.targetUsers.map(u => u.value);
      this.props.store.createDiscussion(this.state.message, users).then((m: MessageModel) => {
        delay(1000).then(() => {
          browserHistory.push({
            pathname: `/messages/discussion/${m.discussionId}`
          });
        });
      });
    }
  }
  enableNewMode() {
    this.setState({
      enableComponents: true,
      message: this.state.message
    });
  }
  render() {
    const discussion = this.props.store.chat.discussion;

    return (
      <div className="ui basic comments segment">
        <div className="ui basic segment">
          <h2 className="ui header">Conversation avec</h2>
          {this.state.enableComponents ?
            <Async
              loadOptions={this.fetchUsers}
              multi={true}
              value={(discussion.targetUsers as any).toJS()}
              onChange={this.handleUsersChange}
              placeholder="..."
              noResultsText="Aucun utilisateur trouvé"
              searchPromptText="Entrez un nom d'utilisateur"
              loadingPlaceholder="Chargement..."
              ignoreCase={true}
              ignoreAccents={false}
              aria-label="Entrez un nom d'utilisateur"
            />
            : undefined}
          <div className="ui divider" />
          <div>
            <div className="ui comments">
              <form onSubmit={this.postMessage} className="ui reply form" style={{ padding: '10px' }}>
                <div className={(this.state.remainingCharacters < 0 ? 'error' : '') + ' field'}>
                  <textarea
                    value={this.state.message}
                    rows={2}
                    style={{ height: '7em' }}
                    onChange={this.handleTextChange}
                  />
                </div>
                {this.state.remainingCharacters < 0 ?
                  <span style={{ color: '#912d2b' }}>{this.state.remainingCharacters} caractères</span>
                  : undefined}
                <button
                  className={'ui blue submit right floated ' + (this.state.loading ? ' loading' : '') + ' button'}
                  disabled={discussion.targetUsers.length === 0 || this.state.remainingCharacters < 0 || this.state.remainingCharacters === 5000}
                  type="submit"
                >
                  Poster
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
