import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import TextStructureForm from '../write/TextStructureForm';
import StructureParcoursModel from '../../models/write/StructureParcoursModel';
import TextWithParcoursActiveStructure from '../write/TextWithParcoursActiveStructure';
import Toc from './Toc';

interface TextStructureProps {
  store?: Store;
  strangerMode: boolean;
}

@inject('store') @observer
export default class TextStructure
  extends React.Component<TextStructureProps, {}> {
  constructor(props: TextStructureProps) {
    super(props);
    this.reloadStructure = this.reloadStructure.bind(this);
  }
  reloadStructure() {
    return this.props.store.loadInfosStructure();
  }
  render() {
    const premium = this.props.store.connected.premium.premium;
    const text = this.props.store.text;
    return (
      <div>
        {this.props.strangerMode ?
          <Toc
            textId={text.meta.id}
            urlText={text.meta.urlText}
            structure={text.meta.structure}
          />
          :
          <div>
            <h3 className="ui section horizontal large divider">
              <i className="fa fa-fw fa-list" style={{ marginRight: '10px' }} />
              Table des matières
            </h3>
            <div style={{ paddingBottom: '50px' }}>
              {premium && text.infos && text.infos.parcours && text.infos.parcours.active ?
                <TextWithParcoursActiveStructure
                  textId={text.meta.id}
                  urlText={text.meta.urlText}
                  structure={text.infos.structure as any}
                  parcours={text.infos.parcours}
                />
                :
                <TextStructureForm
                  textId={text.meta.id}
                  urlText={text.meta.urlText}
                  published={text.meta.published}
                  structure={text.infos.structure}
                  reload={this.reloadStructure}
                />
              }
            </div>
          </div>
        }
      </div>
    );
  }
}
