import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const Accordion = require('semantic-ui-react/dist/commonjs/modules/Accordion').default;
import Store from '../../../../../stores/Store';

interface ChapterStepProps {
  store?: Store;
  textId: number;
  chapter: { step: number };
  position: string;
  readonly: boolean;
  forceShow?: boolean;
  readMode: boolean;
}

interface ChapterStepState {
  showModal: boolean;
  afterPublish: boolean;
  step: number;
}

@inject('store') @observer
export default class ChapterStep extends React.Component<ChapterStepProps, ChapterStepState> {
  constructor(props: ChapterStepProps) {
    super(props);
    this.state = {
      showModal: this.props.forceShow ? this.props.forceShow : false,
      afterPublish: this.props.forceShow,
      step: this.props.forceShow ? this.props.chapter.step : undefined,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onAccordionChange = this.onAccordionChange.bind(this);
    this.saveStep = this.saveStep.bind(this);
  }
  open() {
    this.setState({ showModal: true, step: this.props.chapter.step });
  }
  close() {
    this.setState({ showModal: false, afterPublish: undefined, step: undefined });
  }
  onAccordionChange(step: number) {
    this.setState({
      showModal: true,
      step: step
    });
  }
  @action
  saveStep() {
    this.props.store.changeStep(this.props.textId, this.props.chapter, this.state.step).then((d: any) => {
      this.close();
    });
  }
  render() {
    let stepStr;

    const titleStep1 = 'Premier jet';
    const titleStep2 = 'Correction / Relecture';
    const titleStep3 = 'Texte final';

    const descStep1 = <div>
      Le statut <b>Premier jet</b> s'adresse aux chapitres récemment écrits.
                <br />
      Il n'est pas encore l'heure de se pencher sur l'orthographe ou la grammaire : priorité à l'intrigue et aux idées générales !
                <div className="ui basic segment">
        Le chapitre est ouvert aux annotations :
                  <div className="ui bulleted list">
          <div className="item">Intrigue & Idées</div>
        </div>
      </div>
    </div>;

    const descStep2 = <div>
      Le statut <b>Correction / Relecture</b> s'adresse aux chapitres presque terminés !
                <br />
      Place aux dernières corrections et relectures !
                <div className="ui basic segment">
        Le chapitre est ouvert aux annotations :
                  <div className="ui bulleted list">
          <div className="item">Ortho. & Grammaire</div>
          <div className="item">Style</div>
        </div>
      </div>
    </div>;

    const descStep3 = <div>
      Le statut <b>Texte Final</b> s'adresse aux chapitres terminés.
                <br />
      Il n'est plus possible de l'annoter et s'adresse à ceux qui veulent passer un bon moment de lecture !
    </div>;

    switch (this.props.chapter.step) {
      case 1:
        stepStr = titleStep1;
        break;
      case 2:
        stepStr = titleStep2;
        break;
      case 3:
        stepStr = titleStep3;
        break;
    }

    return (
      <div style={this.props.readMode ? { display: 'table', marginRight: '30px' } : {}}>
        <Modal onClose={this.close} open={this.state.showModal} size="small">
          <div className="header">Statut du chapitre</div>
          {this.props.readonly ?
            <div className="content">
              Le statut d'un chapitre permet d'indiquer l'état d'avancement du texte et les retours attendus.
              {this.state.step === 1 ?
                <div className="ui basic segment">
                  <h3 className="ui top attached header">{titleStep1}</h3>
                  <div className="ui attached segment">
                    {descStep1}
                  </div>
                </div>
                : (this.state.step === 2 ?
                  <div className="ui basic segment">
                    <h3 className="ui top attached header">{titleStep2}</h3>
                    <div className="ui attached segment">
                      {descStep2}
                    </div>
                  </div>
                  : <div className="ui basic segment">
                    <h3 className="ui top attached header">{titleStep3}</h3>
                    <div className="ui attached segment">
                      {descStep3}
                    </div>
                  </div>)
              }
            </div>
            :
            <div className="content">
              {this.state.afterPublish ?
                <div className="ui info message">
                  Félicitations, votre chapitre est publié !
                  <br />Vous avez maintenant la possibilité de modifier le statut de ce chapitre.
                </div>
                : undefined
              }

              Le statut d'un chapitre permet d'indiquer l'état d'avancement du texte et les retours attendus.
              <div className="ui basic segment">
                <Accordion styled inverted>
                  <Accordion.Title active={this.state.step === 1} onClick={() => this.onAccordionChange(1)}
                    className={this.state.step === 1 ? 'accordion-title-success' : undefined}
                  >
                    {titleStep1}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.step === 1}
                    className={this.state.step === 1 ? 'accordion-content-success' : undefined}>
                    {descStep1}
                  </Accordion.Content>
                  <Accordion.Title active={this.state.step === 2} onClick={() => this.onAccordionChange(2)}
                    className={this.state.step === 2 ? 'accordion-title-success' : undefined}>
                    {titleStep2}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.step === 2}
                    className={this.state.step === 2 ? 'accordion-content-success' : undefined}>
                    {descStep2}
                  </Accordion.Content>
                  <Accordion.Title active={this.state.step === 3} onClick={() => this.onAccordionChange(3)}
                    className={this.state.step === 3 ? 'accordion-title-success' : undefined}>
                    {titleStep3}
                  </Accordion.Title>
                  <Accordion.Content active={this.state.step === 3}
                    className={this.state.step === 3 ? 'accordion-content-success' : undefined}>
                    {descStep3}
                  </Accordion.Content>
                </Accordion>
              </div>
            </div>
          }
          {this.props.readonly ?
            <div className="actions">
              <button className="ui cancel button" onClick={this.close}>Fermer</button>
            </div>
            :
            <div className="actions">
              <button className="ui blue approve button" onClick={this.saveStep}>Enregistrer</button>
              <button className="ui cancel button" onClick={this.close}>Annuler</button>
            </div>
          }
        </Modal>
        <button className="ui icon basic blue button chapter-status"
          data-tooltip={this.props.readonly ?
            'En savoir plus sur le statut du chapitre' : 'Cliquer pour changer le statut du chapitre'
          } data-inverted="" data-position={this.props.position}
          onClick={this.open}
        >
          <i className="fa fa-fw fa-cog" />{stepStr}
        </button>
      </div >
    );
  }
}

