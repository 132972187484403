import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../stores/Store';

interface ProfileFormIdentityProps {
  store?: Store;
}

interface ProfileFormIdentityState {
  editMode: boolean;
  identityError: boolean;
  identityType: number;
  username: string;
  firstName: string;
  lastName: string;
  facebookUser: boolean;
}

interface ProfileFormIdentityJson {
  identityType: number;
  username: string;
  firstName: string;
  lastName: string;
  facebookUser: boolean;
}

@inject('store') @observer
export default class ProfileFormIdentity extends React.Component<ProfileFormIdentityProps, ProfileFormIdentityState> {
  constructor(props: ProfileFormIdentityProps) {
    super(props);
    this.startEdit = this.startEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.handleChangeIdentityType = this.handleChangeIdentityType.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);

    this.state = {
      editMode: false,
      identityError: false,
      identityType: undefined,
      username: undefined,
      firstName: undefined,
      lastName: undefined,
      facebookUser: undefined
    };
  }
  startEdit() {
    const profileId = this.props.store.profile.id;
    this.props.store.getProfileIdentity(profileId).then((d: ProfileFormIdentityJson) => {
      this.setState({
        editMode: true,
        identityError: false,
        identityType: d.identityType,
        username: d.username || '',
        firstName: d.firstName || '',
        lastName: d.lastName || '',
        facebookUser: d.facebookUser
      });
    });
  }
  cancelEdit() {
    this.setState({
      editMode: false,
      identityError: false,
      identityType: undefined,
      username: undefined,
      firstName: undefined,
      lastName: undefined,
      facebookUser: undefined
    });
  }
  saveEdit(e: any) {
    e.preventDefault();
    const profileId = this.props.store.profile.id;
    this.props.store.updateProfileIdentity(profileId, this.state.identityType,
      this.state.username, this.state.firstName, this.state.lastName).then((identity: string) => {
        this.props.store.profile.user.identity = identity;
        this.setState({
          editMode: false,
          identityError: false,
          identityType: undefined,
          username: undefined,
          firstName: undefined,
          lastName: undefined,
          facebookUser: undefined
        });
      });
  }
  handleChangeIdentityType(identityType: number) {
    this.setState({
      editMode: this.state.editMode,
      identityError: this.state.identityError,
      identityType: identityType,
      username: this.state.username,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      facebookUser: this.state.facebookUser
    });
  }
  handleUsernameChange(e: any) {
    this.setState({
      editMode: this.state.editMode,
      identityError: this.state.identityError,
      identityType: e.target.value.trim() === '' && this.state.identityType === 2 ?
        1 :
        this.state.identityType,
      username: e.target.value,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      facebookUser: this.state.facebookUser
    });
  }
  handleFirstNameChange(e: any) {
    this.setState({
      editMode: this.state.editMode,
      identityError: this.state.identityError,
      identityType: e.target.value.trim() === '' && this.state.identityType !== 2 ?
        2 :
        this.state.identityType,
      username: this.state.username,
      firstName: e.target.value,
      lastName: this.state.lastName,
      facebookUser: this.state.facebookUser
    });
  }
  handleLastNameChange(e: any) {
    this.setState({
      editMode: this.state.editMode,
      identityError: this.state.identityError,
      identityType: e.target.value.trim() === '' && this.state.identityType !== 2 ?
        2 :
        this.state.identityType,
      username: this.state.username,
      firstName: this.state.firstName,
      lastName: e.target.value,
      facebookUser: this.state.facebookUser
    });
  }
  render() {
    const connected = this.props.store.connected;
    const profile = this.props.store.profile;
    const isAuthor = profile && profile.user
      && connected && connected.user && connected.user.id
      && connected.user.id === profile.user.id;
    return (
      <div>
        {isAuthor && this.state.editMode ?
          <div className="ui segment" style={{ paddingBottom: '40px', color: 'rgba(0, 0, 0, 0.87)' }}>
            <form onSubmit={this.saveEdit} className="ui equal width form">
              {this.state.identityError ?
                <div className="ui error message">
                  <div className="header">
                    Il y a une incohérence dans le choix de votre identité.
                  </div>
                  <br />Vous devez choisir entre :
                  <ul>
                    <li>Prénom + Nom</li>
                    <li>Pseudonyme</li>
                    <li>Prénom + Pseudonyme + Nom</li>
                  </ul>
                </div>
                : undefined
              }
              <div className="field">
                <label>Je veux apparaître sous l'identité</label>
                <Dropdown
                  selection
                  value={this.state.identityType}
                  onChange={(e: any, { value }) => this.handleChangeIdentityType(parseInt(String(value)))}
                  options={
                    [
                      {
                        value: 1,
                        text: (this.state.firstName ? this.state.firstName : '')
                        + ' ' + (this.state.lastName ? this.state.lastName : '') + ' (prénom + nom)',
                        disabled: (!this.state.firstName || this.state.firstName.trim() === '')
                        || (!this.state.lastName || this.state.lastName.trim() === '')
                      },
                      {
                        value: 2,
                        text: (this.state.username ? this.state.username : '') + ' (pseudonyme seul)',
                        disabled: !this.state.username || this.state.username.trim() === ''
                      },
                      {
                        value: 3,
                        text: (this.state.firstName ? this.state.firstName : '') + ' '
                        + (this.state.username ? this.state.username : '') + ' '
                        + (this.state.lastName ? this.state.lastName : '') + ' (prénom + pseudonyme + nom)',
                        disabled: (!this.state.firstName || this.state.firstName.trim() === '')
                        || (!this.state.lastName || this.state.lastName.trim() === '')
                        || (!this.state.username || this.state.username.trim() === '')
                      }
                    ]
                  }
                />
              </div>
              <div className="field">
                <label>Pseudonyme</label>
                {this.state.facebookUser ?
                  <input type="text" placeholder="Pseudonyme"
                    value={this.state.username} onChange={this.handleUsernameChange} />
                  : <span>{this.state.username}</span>
                }
              </div>
              <div className="fields">
                <div className="field">
                  <label>Prénom</label>
                  <input type="text" placeholder="Prénom"
                    value={this.state.firstName} onChange={this.handleFirstNameChange} />
                </div>
                <div className="field">
                  <label>Nom</label>
                  <input type="text" placeholder="Nom"
                    value={this.state.lastName} onChange={this.handleLastNameChange} />
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelEdit}
                >
                  Annuler
                </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveEdit}
                  type="submit"
                  disabled={
                    ((!this.state.firstName || this.state.firstName.trim().length === 0)
                    && (!this.state.lastName || this.state.lastName.trim().length === 0)
                    && (!this.state.username || this.state.username.trim().length === 0))
                    || ((!this.state.username || this.state.username.trim().length === 0) && this.state.identityType === 2)
                    || (((!this.state.firstName || this.state.firstName.trim().length === 0)
                    || (!this.state.lastName || this.state.lastName.trim().length === 0)) && this.state.identityType === 1)
                    || (((!this.state.firstName || this.state.firstName.trim().length === 0)
                    || (!this.state.lastName || this.state.lastName.trim().length === 0)
                    || (!this.state.username || this.state.username.trim().length === 0)) && this.state.identityType === 3)
                    }
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
          :
          <span
            className="ui large header"
          >
            <h1
              className="ui huge header"
              style={{ color: profile.user.textColor }}
            >
              {profile.user.identity}
              {isAuthor ?
                <button className="ui small orange label" onClick={this.startEdit} style={{ marginLeft: '15px' }}>
                  Modifier mon identité
                </button>
                : undefined
              }
            </h1>
          </span>
        }
      </div>
    );
  }
}
