import { observable } from 'mobx';
import LightChapterModel from './LightChapterModel';

export default class LightPartModel {
  @observable title: string;
  @observable chapters: LightChapterModel[];

  constructor(title: string, chapters: LightChapterModel[]) {
    this.title = title;
    this.chapters = chapters;
  }

  toJS() {
    return {
      title: this.title,
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const chapters = object.chapters.map((c: any) => LightChapterModel.fromJS(c));
      return new LightPartModel(object.title, chapters);
    }
    return undefined;
  }
}
