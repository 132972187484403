import * as superagent from 'superagent';
import config from '../config';

const methods: any = ['get', 'post', 'put', 'patch', 'del'];
declare const __SERVER__: any;

const protocol = process.env.HTTPS && process.env.HTTPS === 'true' ?
  'https'
  : 'http';

function formatUrl(path: any) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return `${protocol}://${config.apiHost}:${config.apiPort + adjustedPath}`;
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return `/api${adjustedPath}`;
}

interface Params {
  params: any;
  data: any;
  headers: any;
  files: any;
  fields: any;
}
const p: Params = {
  params: undefined,
  data: undefined,
  headers: undefined,
  files: undefined,
  fields: undefined
};

interface Body { body: any; }
const b: any = { body: undefined };

export default class ApiClient {

  tokenAlt: string;
  clientIp: string;
  [method: string]: any;

  constructor(req: any, res: any) {
    methods.forEach((m: any) => {
      this[m] = (path: any, { params, data, headers, files, fields }: { params: any, data: any, headers: any, files: any, fields: any } = p) => new Promise((resolve, reject) => {
        const request = superagent(m, formatUrl(path));

        if (__SERVER__ && req.get('cookie')) {
          request.set('cookie', req.get('cookie'));
        }

        if (headers) {
          request.set(headers);
        }

        if (this.tokenAlt) {
          request.set('X-XSRF-TOKEN', this.tokenAlt);
        }

        if (this.clientIp) {
          request.set('ClientIP', this.clientIp);
        }

        if (files) {
          files.forEach((file: any) => request.attach(file.key, file.value));
        }

        if (fields) {
          fields.forEach((item: any) => request.field(item.key, item.value));
        }

        if (data) {
          request.send(data);
        }

        request.withCredentials();

        request.end((err: any, body: any) => {
          /* const jwtToken = typeof body !== undefined ? body.header['x-user-token'] : undefined;
          const xsrfToken = typeof body !== undefined ? body.header['x-xsrf-token'] : undefined;
          if (jwtToken && xsrfToken && jwtToken !== res.cookie('tokenAda') && xsrfToken !== res.cookie('tokenaltAda')) {
            console.log('test');
            res.cookie('tokenaltAda', xsrfToken, { maxAge: 604800000, httpOnly: true });
            res.cookie('tokenAda', jwtToken, { maxAge: 604800000, httpOnly: true });
          } */
          (err ? reject(err) : resolve(this.form(body)))
        });
      });
    });
  }

  setTokenAlt(tokenalt: string) {
    this.tokenAlt = tokenalt;
  }

  form ({ body } = b) {
    return body;
  }

  setClientIp(clientIp: string) {
    this.clientIp = clientIp;
  }
}
