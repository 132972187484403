import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface ReadingListRemoveProps {
  active: boolean;
  confirmRemove: Function;
  hideModal: any;
}

export default class ReadingListRemove extends React.Component<ReadingListRemoveProps, {}> {
  constructor(props: ReadingListRemoveProps) {
    super(props);
    this.confirmRemove = this.confirmRemove.bind(this);
  }
  confirmRemove() {
    this.props.confirmRemove();
  }
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => {}}
        >
        <div className="header">Confirmation</div>
        <div className="content">
          Confirmez-vous la suppression de cette liste ?
          </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.confirmRemove}
            >
            Oui
          </button>
          <button className="ui cancel button" onClick={this.props.hideModal}>Non</button>
        </div>
      </Modal>
    );
  }
}
