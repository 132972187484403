import { observable, action, computed } from 'mobx';
import * as superagent from 'superagent';
import * as io from 'socket.io-client';
import ConnectedModel from '../models/ConnectedModel';
import MainAlertsModel from '../models/MainAlertsModel';
import PaymentTunnelModel from '../models/PaymentTunnelModel';
import AlertModel from '../models/AlertModel';
import HomeModel from '../models/home/HomeModel';
import ProfileModel from '../models/profile/ProfileModel';
import CommentListModel from '../models/comment/CommentListModel';
import CommentModel from '../models/comment/CommentModel';
import ReadModel from '../models/text/ReadModel';
import TextModel from '../models/text/TextModel';
import WriteModel from '../models/write/WriteModel';
import TrainingModel from '../models/write/TrainingModel';
import SearchModel from '../models/search/SearchModel';
import ChatModel from '../models/chat/ChatModel';
import MainNotificationsModel from '../models/notification/MainNotificationsModel';
import MainTalkModel from '../models/talk/MainTalkModel';
import MainDefiModel from '../models/defi/MainDefiModel';
import MainSettingsModel from '../models/settings/MainSettingsModel';
import ReadQueryParams from '../components/read/ReadQueryParams';
import DefisQueryParams from '../components/defis/DefisQueryParams';
import MainReadingListModel from '../models/readinglist/MainReadingListModel';
import SettingsPreferencesModel from '../models/settings/SettingsPreferencesModel';
import MainWritingProgramModel from '../models/writingprogram/MainWritingProgramModel';
import WritingProgramModel from '../models/writingprogram/WritingProgramModel';
import MainCanvasModel from '../models/canvas/MainCanvasModel';
import MainParcoursModel from '../models/parcours/MainParcoursModel';
import AllUserParcoursModel from '../models/parcours/AllUserParcoursModel';
import MainEditorModel from '../models/editors/MainEditorModel';
import ParcoursModel from '../models/parcours/ParcoursModel';
import ProductModel from '../models/product/ProductModel';
import CardModel from '../models/product/CardModel';
import TalkModel from '../models/talk/TalkModel';
import UserSearchedByInfoModel from '../models/settings/UserSearchedByInfoModel';
import PremiumModel from '../models/settings/PremiumModel';
import config from '../config';
var jwtDecode = require('jwt-decode');

const protocol = process.env.HTTPS && process.env.HTTPS === 'true' ?
  'https'
  : 'http';

declare const __SERVER__: any;
declare const __CLIENT__: any;

export default class Store {

  @observable ssrLocation: string;

  @observable ip: string;

  @observable language: string;

  @observable vapidKey: string;

  @observable isConnected: boolean;

  @observable isHttps: boolean;

  @observable mainAlerts: MainAlertsModel;

  // User
  @observable connected: ConnectedModel;

  // Home
  @observable home: HomeModel;

  // Profile
  @observable profile: ProfileModel;

  // Read
  @observable read: ReadModel;
  @observable text: TextModel;
  @observable comments: CommentListModel;

  // Write
  @observable write: WriteModel;

  // Search
  @observable search: SearchModel;

  // Search Users by Email
  @observable searchedUsersByInfo: UserSearchedByInfoModel[];

  // Chat
  @observable chat: ChatModel;

  // Notifications
  @observable mainNotifications: MainNotificationsModel;

  // Talk
  @observable mainTalk: MainTalkModel;

  // Defi
  @observable mainDefi: MainDefiModel;

  // Settings
  @observable mainSettings: MainSettingsModel;

  // Reading lists
  @observable mainReadingList: MainReadingListModel;

  // Writing program
  @observable mainWritingProgram: MainWritingProgramModel;

  // Canvas
  @observable mainCanvas: MainCanvasModel;

  // Parcours
  @observable mainParcours: MainParcoursModel;
  @observable allParcours: ParcoursModel[];
  @observable allCards: CardModel[];
  @observable currentCard: CardModel;
  @observable allProduct: ProductModel[];
  @observable currentProduct: ProductModel;
  @observable previouslyVisitedProduct: ProductModel;
  @observable allUserParcours: AllUserParcoursModel;

  // Comparatif éditeurs
  @observable mainEditor: MainEditorModel;

  @observable paymentTunnel: PaymentTunnelModel;

  socket: SocketIOClient.Socket;

  apiClient: any;

  cookies: any;

  static init(apiClient: any, ssrLocation: string, language: string, ip: string,
    vapidKey: string, isConnected: boolean, isHttps: boolean, cookies: any) {
    const store = new Store();
    store.ssrLocation = ssrLocation;
    store.ip = ip;
    store.language = language;
    store.vapidKey = vapidKey;
    store.isConnected = isConnected;
    store.isHttps = isHttps;
    store.mainAlerts = new MainAlertsModel([]);
    store.connected = new ConnectedModel(undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    store.home = new HomeModel(undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, 0, false, undefined, undefined);
    store.profile = new ProfileModel(undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined,
      false, 0, false, 'view', 'alltime', false);
    store.read = new ReadModel(undefined, undefined, undefined, false, 0, false);
    store.text = new TextModel(undefined, undefined, undefined, undefined,
      undefined, undefined, undefined);
    store.comments = new CommentListModel(undefined, undefined, undefined, undefined);
    store.write = new WriteModel(undefined, undefined, undefined, undefined, undefined,
      false, false, 0, false, 'title', 'asc', undefined, undefined);
    store.search = new SearchModel(false, 0, false, undefined, undefined, undefined);
    store.searchedUsersByInfo = [];
    store.chat = new ChatModel(undefined, undefined);
    store.mainNotifications = new MainNotificationsModel(0, undefined, undefined);
    store.mainTalk = new MainTalkModel(undefined, 0, false, undefined, undefined, undefined, undefined);
    store.mainDefi = new MainDefiModel(undefined, false, 0, false, undefined, undefined);
    store.mainSettings = new MainSettingsModel(undefined, undefined, undefined, undefined, undefined);
    store.mainReadingList = new MainReadingListModel(undefined, undefined, undefined,
      undefined, undefined);
    store.mainWritingProgram = new MainWritingProgramModel(undefined, undefined, undefined);
    store.mainCanvas = new MainCanvasModel(undefined, undefined);
    store.allParcours = [];
    store.allCards = [];
    store.currentCard = undefined;
    store.allProduct = [];
    store.currentProduct = new ProductModel(undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    store.previouslyVisitedProduct = new ProductModel(undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    store.mainParcours = new MainParcoursModel(undefined, undefined, undefined, undefined, [], undefined, undefined);
    store.allUserParcours = new AllUserParcoursModel(undefined, 0, false);
    store.mainEditor = new MainEditorModel(undefined, undefined);
    store.paymentTunnel = new PaymentTunnelModel(undefined, undefined);
    store.apiClient = apiClient;
    store.cookies = cookies;
    return store;
  }

  isAuthenticated() {
    return (__SERVER__ && this.cookies.get('tokenAda') !== undefined && this.cookies.get('tokenaltAda') !== undefined && Math.floor(new Date(jwtDecode(this.cookies.get('tokenAda')).exp*1000).getTime() - new Date().getTime()) / (1000) > (172800))
      || (__CLIENT__ && this.isConnected);
  }

  areCookiesExpired() {
    return (__SERVER__ && this.cookies.get('tokenAda') !== undefined && this.cookies.get('tokenaltAda') !== undefined && Math.floor(new Date(jwtDecode(this.cookies.get('tokenAda')).exp*1000).getTime() - new Date().getTime()) / (1000) < (172800));
  }

  expireCookies() {
    if (__SERVER__ && this.cookies.get('tokenAda') !== undefined && this.cookies.get('tokenaltAda') !== undefined && new Date(jwtDecode(this.cookies.get('tokenAda')).exp*1000) < new Date())
    {
      this.cookies.remove('tokenAda');
      this.cookies.remove('tokenaltAda');
    }
    return (__SERVER__ && this.cookies.get('tokenAda') !== undefined && this.cookies.get('tokenaltAda') !== undefined && new Date(jwtDecode(this.cookies.get('tokenAda')).exp*1000) < new Date());
  }

  initSocketConnection(parcoursId: number) {
    if (__CLIENT__ && !this.socket) {
      const protocol = this.isHttps ?
        'https://'
        : 'http://';
      const host = protocol + window.location.hostname;
      this.socket = io(host, { path: '/sock/chat', query: `parcoursId=${parcoursId}` });
    }
  }

  disconnectConnection() {
    if (__CLIENT__ && this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  @action addAlert(alert: AlertModel) {
    this.mainAlerts.addAlert(alert);
  }

  @action addAlerts(alerts: AlertModel[]) {
    this.mainAlerts.addAlerts(alerts);
  }

  @action removeAlert(alertId: string) {
    this.mainAlerts.removeAlert(alertId);
  }

  @action loadHomeStats() {
    return this.apiClient.get('/home/stats').then((s: any) => {
      this.home.homeStats = s;
    });
  }

  @action loadExplore() {
    return this.apiClient.get('/home/explore').then((e: any) => {
      this.home.explore = e;
    });
  }

  @action login(username: string, password: string, recaptchaResponse?: string) {
    let b: any = { body: undefined };

    return new Promise((resolve, reject) => {
      superagent
        .post('/auth/login')
        .send({ username: username, password: password, recaptchaResponse: recaptchaResponse })
        .end((err: any, { body } = b) => (err ? reject(err) : resolve(body)));
    });
  }

  @action loginFacebook() {
    return new Promise((resolve, reject) => {
      superagent
        .get('/auth/login/facebook');
    });
  }

  @action signUp(username: string, email: string, password: string, birthDate: Date) {
    let b: any = { body: undefined };
    var moment = require('moment');
    return new Promise((resolve, reject) => {
      superagent
        .post('/auth/signup')
        .send({ username: username, email: email, password: password, birthDate:  moment(birthDate).format("DD/MM/YYYY")})
        .end((err: any, { body } = b) => {
          (err ? reject(err) : resolve(body))
        });
    });
  }

  @action sendContactMail(mail: string, text: string, location: string) {
    const data = {
      mail: mail,
      text: text,
      location: location
    };
    return this.apiClient.post('/user/contact', { data: data });
  }

  @action loadConnectedOnly() {
    const m = this.connected;
    return this.apiClient.get('/user/connected').then((d: any) => {
      if (d.isBlocked) {
        this.cookies.remove('tokenAda');
        this.cookies.remove('tokenaltAda');
      } else {
        m.setConnected(d.user, d.notifications, d.messages, d.preferences, d.active, m.activeTab,
          d.email, d.newEmail, d.blockedBy, d.alertAltruiste, d.badges,
          d.desktopNotifications, d.newUser, d.showNotifBanner, d.premium, d.containers);
        // this.initSocketConnection();
      }
    });
  }

  @action loadConnected() {
    const m = this.connected;
    /* if (this.cookies && Math.floor(new Date(jwtDecode(this.cookies.get('tokenAda')).exp*1000).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24) < 7) {   
      return this.apiClient.get('/user/connected/refresh').then((d: any) => {
        if (d.isBlocked) {
          this.cookies.remove('tokenAda');
          this.cookies.remove('tokenaltAda');
        } else {
          m.setConnected(d.user, d.notifications, d.messages, d.preferences, d.active, m.activeTab,
            d.email, d.newEmail, d.blockedBy, d.alertAltruiste, d.badges,
            d.desktopNotifications, d.newUser, d.showNotifBanner, d.premium, d.containers);
          // this.initSocketConnection();
        }
      });
    }
    else { */
      return this.apiClient.get('/user/connected').then((d: any) => {
        if (d.isBlocked) {
          this.cookies.remove('tokenAda');
          this.cookies.remove('tokenaltAda');
        } else {
          m.setConnected(d.user, d.notifications, d.messages, d.preferences, d.active, m.activeTab,
            d.email, d.newEmail, d.blockedBy, d.alertAltruiste, d.badges,
            d.desktopNotifications, d.newUser, d.showNotifBanner, d.premium, d.containers);
          // this.initSocketConnection();
        }
      });
    /* } */
  }

  @computed get countNotificationsAndMessages() {
    if (this.connected && this.connected.user && this.connected.user.id) {
      return this.connected.notificationsCount + this.connected.messagesCount;
    }
    return 0;
  }

  @action loadNotifications() {
    const m = this.connected;
    return this.apiClient.get('/user/notificationsNotRead').then((n: any) => {
      m.setNotifications(n);
    });
  }

  // Home
  @action loadFeed(feedType?: string, onlyNewMembers?: boolean, onlyTextsAndChapters?: boolean, onlyDiaries?: boolean) {
    const h = this.home;
    h.enableLoading();
    const url = feedType ?
      `/home/feed?feedType=${feedType}&onlyNewMembers=${onlyNewMembers}&onlyTextsAndChapters=${onlyTextsAndChapters}&onlyDiaries=${onlyDiaries}`
      : this.connected.activeTab ? `/home/feed?feedType=${this.connected.activeTab === 1 ? 'all' : 'mine'}&onlyNewMembers=false&onlyTextsAndChapters=false&onlyDiaries=false` 
      : `/home/feed`;
    if (!feedType && this.connected.activeTab)
      feedType = this.connected.activeTab === 1 ? 'all' : 'mine';
    return this.apiClient.get(url).then((f: any) => {
      h.setFeed(f);
      if (feedType === 'all') 
        h.disableLoading();
      else if (feedType === 'mine' && f.empty)
        this.loadSuggestions().then((r: any) => {
          h.disableLoading();
        });
      else
        h.disableLoading();
    });
  }

  @action loadMoreFeed(feedType: string, onlyNewMembers: boolean, onlyTextsAndChapters: boolean,
    onlyDiaries: boolean, beforeDate: string, _callback?: Function) {
    const m = this.home;
    return this.apiClient.get(`/home/feed?feedType=${feedType}&onlyNewMembers=${onlyNewMembers}&onlyTextsAndChapters=${onlyTextsAndChapters}&onlyDiaries=${onlyDiaries}&beforeDate=${encodeURIComponent(beforeDate)}`).then((f: any) => {
      m.fillFeed(f);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action pinFeedTab(pintab: number) {
    return this.apiClient.post(`/home/feed/pintab/${pintab}`).then((t: any) => {
      this.connected.preferences.feedTab = pintab;
    });
  }

  @action loadCurrentTexts() {
    const m = this.home;
    return this.apiClient.get('/home/currentTexts').then((t: any) => {
      m.setCurrentTexts(t.texts);
    });
  }

  @action loadCurrentDefis() {
    const m = this.home;
    return this.apiClient.get('/home/currentDefis').then((t: any) => {
      m.setCurrentDefis(t.defis);
    });
  }

  @action loadCurrentTalks() {
    const m = this.home;
    return this.apiClient.get('/home/currentTalks').then((t: any) => {
      m.setCurrentTalks(t.talks);
    });
  }

  @action loadCurrentParcours() {
    const m = this.home;
    return this.apiClient.get('/home/currentParcours').then((p: any) => {
      m.setCurrentParcours(p);
    });
  }

  @action loadCurrentParcoursText(textId: number) {
    const m = this.mainParcours;
    
    
    return this.apiClient.get(`/home/currentParcours/text/${textId}`).then((p: any) => {
      for (let key in p) {
        if (p[key]['videos'][0])
          p[key]['videos'][0].visible = true;//ugly preloading of the first video of each chapter
      }
      m.clearStatus();
      m.setStatus(p[0]);
      for (let key in p) {
        m.addPreviousStatus(p[key]);
      }
      this.write.clearCurrentText();
    });
  }

  @action clearCurrentParcoursText() {
    const m = this.mainParcours;
    m.clearStatus();
  }

  @action clearCurrentProduct() {
    this.currentProduct = new ProductModel(undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
      undefined, undefined, undefined, undefined);
  }

  @action loadAllUserParcours() {
    return this.apiClient.get(`/home/allUserParcours`).then((p: any) => {
      this.allUserParcours = AllUserParcoursModel.fromJS({'allParcours' : p, 'pageAllParcours' : 0, 'endAllParcours' : false});
    });
  }

  @action markParcoursTraining() {
    return this.apiClient.get(`/home/currentParcours/forcetraining`);
  }

  @action loadLastListsTexts() {
    const m = this.home;
    m.lastListsTextsLoading = true;
    return this.apiClient.get('/home/liststexts/last').then((t: any) => {
      m.setLastListsTexts(t.lists);
      m.lastListsTextsLoading = false;
    });
  }

  @action loadAllListsTexts(_callback?: Function) {
    const m = this.home;
    m.enableLoading();
    return this.apiClient.get(`/home/liststexts/all?page=${m.pageListsTexts}`).then((t: any) => {
      m.setAllListsTexts(t);
      m.disableLoading();
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action loadSuggestions() {
    const m = this.home;
    return this.apiClient.get(`/home/suggestions`).then((t: any) => {
      m.setSuggestions(t.suggestions);
    });
  }

  @action saveContainers(containers: string[]) {
    const data = {
      containers: containers
    };
    return this.apiClient.post('/home/containers', { data: data });
  }

  @action switchUI() {
    return this.apiClient.post('/home/switchui').then((p: any) => {
      return this.loadConnectedOnly().then((d: any) => {
        if (this.connected.premium.premiumUI) {
          if (this.connected.premium.pinnedText) {
            return this.loadTextInfos(this.connected.premium.pinnedText).then((d: any) => {
              const p: any[] = [];
              const parcoursId = this.write.currentText.infos.parcours.id;
              p.push(this.loadParcoursPrerequisites(parcoursId));
              p.push(this.loadParcoursProgress(parcoursId));
              p.push(this.loadParcoursTalks(parcoursId, [], true));
              p.push(this.loadCurrentWritingProgram());
              return Promise.all(p);
            });
          } else {
            const p: any[] = [];
            const parcoursId = 1;
            p.push(this.loadParcoursPrerequisites(parcoursId));
            p.push(this.loadParcoursProgress(parcoursId));
            p.push(this.loadParcoursTalks(parcoursId, [], true));
            p.push(this.loadCurrentWritingProgram());
            return Promise.all(p);
          }
        } else {
          const p: any[] = [];
          p.push(this.loadCurrentTexts());
          p.push(this.loadCurrentDefis());
          p.push(this.loadCurrentTalks());
          p.push(this.loadCurrentWritingProgram());
          p.push(this.loadLastListsTexts());
          return Promise.all(p);
        }
      });
    });
  }

  @action loadProfileMain(userId: number) {
    const m = this.profile;
    m.setProfileMain(undefined, undefined);
    return this.apiClient.get(`/profile/${userId}/main`).then((p: any) => {
      m.setProfileMain(userId, p);
    });
  }

  @action loadProfileLight(userId: number) {
    return this.apiClient.get(`/profile/${userId}/light`);
  }

  @action getProfileIdentity(userId: number) {
    return this.apiClient.get(`/profile/${userId}/edit/identity`);
  }

  @action updateProfileIdentity(userId: number, identityType: number, username: string, firstName: string,
    lastName: string) {
    const data = {
      identityType: identityType,
      username: username,
      firstName: firstName,
      lastName: lastName,
      facebookUser: false
    };
    return this.apiClient.post(`/profile/${userId}/edit/identity`, { data: data });
  }

  @action updateProfileDescription(userId: number, description: string) {
    const data = {
      description: description
    };
    return this.apiClient.post(`/profile/${userId}/edit/description`, { data: data });
  }

  @action updateProfileWhere(userId: number, localisation: string, website: string,
    twitter: string, facebook: string) {
    const data = {
      localisation: localisation,
      website: website,
      twitter: twitter,
      facebook: facebook
    };
    return this.apiClient.post(`/profile/${userId}/edit/where`, { data: data });
  }

  @action updateProfileColors(userId: number, backgroundColor: string, textColor: string,
    linkColor: string) {
    const data = {
      backgroundColor: backgroundColor,
      textColor: textColor,
      linkColor: linkColor
    };
    return this.apiClient.post(`/profile/${userId}/edit/colors`, { data: data });
  }

  @action updateProfileAvatar(replaceImageWithFacebook: boolean) {
    const profile = this.profile.user;
    const file = profile.avatarFileTemp;

    if (replaceImageWithFacebook) {
      return this.apiClient.post(`/profile/${profile.id}/edit/avatar/facebook`)
        .then((d: any) => {
          profile.avatar
            = `https://graph.facebook.com/${profile.facebookUser}/picture?width=150&height=150`;
          profile.avatarTemp = undefined;
          profile.avatarFileTemp = undefined;
        });
    } else if (file) {
      return this.apiClient.post(
        `/profile/${profile.id}/edit/avatar`,
        {
          files: [
            { key: 'avatar', value: file }
          ]
        }).then((d: any) => {
          profile.avatar = d;
          profile.avatarTemp = undefined;
          profile.avatarFileTemp = undefined;
        });
    } else {
      return this.apiClient.post(`/profile/${profile.id}/edit/avatar/remove`)
        .then((d: any) => {
          profile.avatar = undefined;
          profile.avatarTemp = undefined;
          profile.avatarFileTemp = undefined;
        });
    }
  }

  @action loadProfileAuthor(userId: number) {
    const m = this.profile;
    m.description = undefined;
    m.questionnaire = [];
    m.texts = [];
    m.lists = [];
    m.statistics = undefined;
    m.badges = [];
    m.enableLoading();
    return this.apiClient.get(`/profile/${userId}/author`).then((p: any) => {
      m.setProfileAuthor(p.description, p.questionnaire, p.statistics, p.talks, p.badges);
      m.disableLoading();
    });
  }

  @action loadProfileAuthorTexts(userId: number) {
    const p = this.profile;

    return this.apiClient.get(`/profile/${userId}/author/texts`).then((t: any) => {
      p.setProfileTexts(t);
    });
  }

  @action loadProfileAuthorLists(userId: number) {
    const p = this.profile;

    return this.apiClient.get(`/profile/${userId}/author/lists`).then((l: any) => {
      p.setProfileLists(l);
    });
  }

  @action loadProfileTexts(userId: number, _callback?: Function) {
    const p = this.profile;
    if (p.page === 0) {
      p.enableLoading();
    }
    return this.apiClient.get(`/profile/${userId}/texts?action=${p.action}&period=${p.period}&page=${p.page}`)
      .then((t: any) => {
        p.setProfileTexts(t);
        p.disableLoading();
        if (_callback) {_callback(false)};
      }, (error: any) => {
        if (_callback) {_callback(true)};
        console.log(error)
      });
  }

  @action loadProfileSocial(userId: number) {
    const m = this.profile;
    m.enableLoading();
    return this.apiClient.get(`/profile/${userId}/social`).then((p: any) => {
      m.setProfileSocial(p.countAbonnes, p.abonnes, p.countAbonnements, p.abonnements);
      m.disableLoading();
    });
  }

  @action loadProfileSocialAbonnes(userId: number) {
    const m = this.profile;
    m.enableLoading();
    return this.apiClient.get(`/profile/${userId}/social/abonnes`).then((p: any) => {
      m.setProfileRelations(p);
      m.disableLoading();
    });
  }

  @action loadProfileSocialAbonnements(userId: number) {
    const m = this.profile;
    m.enableLoading();
    return this.apiClient.get(`/profile/${userId}/social/abonnements`).then((p: any) => {
      m.setProfileRelations(p);
      m.disableLoading();
    });
  }

  @action loadProfileLists(userId: number) {
    const m = this.profile;
    m.enableLoading();
    return this.apiClient.get(`/profile/${userId}/lists`).then((p: any) => {
      m.setProfileLists(p);
      m.disableLoading();
    });
  }

  @action followUser(userId: number) {
    return this.apiClient.post(`/profile/${userId}/social/follow`);
  }

  @action unfollowUser(userId: number) {
    return this.apiClient.post(`/profile/${userId}/social/unfollow`);
  }

  @action blockUser(userId: number) {
    return this.apiClient.post(`/profile/${userId}/social/block`).then((p: any) => {
      this.profile.user.blocked = true;
      this.profile.user.followed = false;
    });
  }

  @action unblockUser(userId: number) {
    return this.apiClient.post(`/profile/${userId}/social/unblock`).then((p: any) => {
      this.profile.user.blocked = false;
    });
  }

  @action suspendUser(userId: number, weeks: number) {
    const data = { weeks: weeks };
    return this.apiClient.post(`/profile/${userId}/social/suspend`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((p: any) => {
      this.profile.user.suspended = true;
    });
  }

  @action unsuspendUser(userId: number) {
    return this.apiClient.post(`/profile/${userId}/social/unsuspend`).then((p: any) => {
      this.profile.user.suspended = false;
    });
  }

  @action deleteUser(userId: number, deleteMailjet: boolean) {
    return this.apiClient.post(`/profile/${userId}/social/delete`, {
      data: {deleteMailjet: deleteMailjet},
      headers: { 'Content-Type': 'application/json' }
    }).then((p: any) => {
      if (p.hasOwnProperty('success') && p['success'] === true)
        this.profile.beingDeleted = true;
      else
        this.profile.beingDeleted = false;
    });
  }

  @action promoteUser(userId: number) {
    return this.apiClient.post(`/admin/promote`, {
      data: {id: userId},
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action loadProfileDiary(userId: number) {
    /* toRemove */
    const data = { memberId: String(userId) };
    return this.apiClient.post(`/profile/diaries`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((d: any) => {
        this.comments = new CommentListModel(d.comments.map((c: any) => new CommentModel(c.id, c.userId, c.userIdentity,
          c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
          c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode, c.shownAnswers,
          c.textEdit, c.isNew)), 'diary', d.authorText, d.authorDefi);
      });
  }

  @action loadQuestionnaire(userId: number) {
    this.profile.enableLoading();
    return this.apiClient.get(`/profile/${userId}/questionnaire`).then((q: any) => {
      this.profile.questionnaire = q;
      this.profile.disableLoading();
    });
  }

  @action saveQuestionnaire(userId: number) {
    const data = {
      questions: this.profile.questionnaire
    };

    this.profile.enableLoading();
    return this.apiClient.post(`/profile/${userId}/questionnaire`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((q: any) => {
        this.addAlert(new AlertModel(
          'questionnaire',
          'Questionnaire sauvegardé ! Félicitations !',
          '',
          'success',
          5
        ));
      });
  }

  @action loadProfileActivity(userId: number) {
    const m = this.profile;
    return this.apiClient.get(`/profile/${userId}/activity`).then((a: any) => {
      m.setActivity(a.badges, a.proposedDefis, a.heatMap);
    });
  }

  @action loadProfileStats(userId: number) {
    const m = this.profile;
    return this.apiClient.get(`/profile/${userId}/activity/stats`).then((s: any) => {
      if (s && s.myFavorites !== undefined) {
        m.setStatistics(s);
      }
    });
  }

  @action loadProfileHeatMapDetail(date: string) {
    const m = this.profile;
    return this.apiClient.get(`/profile/${m.id}/activity/heatmap/${date}`).then((h: any) => {
      m.setHeatMapDetail(h);
    });
  }

  @action loadProfileFeed(userId: number) {
    const m = this.profile;
    return this.apiClient.get(`/profile/${userId}/activity/feed`).then((f: any) => {
      m.setFeed(f);
    });
  }

  @action loadMoreProfileFeed(userId: number, beforeDate: string, _callback?: Function) {
    const p = this.profile;
    return this.apiClient.get(`/profile/${userId}/activity/feed?beforeDate=${encodeURIComponent(beforeDate)}`).then((f: any) => {
      p.fillFeed(f);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }


  /**
   * Text
   */
  @action loadTextsRead(params: ReadQueryParams, reset?: boolean, _callback?: Function) {
    if (reset) {
      this.read.resetTexts();
    }

    const action = (params.action ? params.action : 'recent');
    const period = (params.period ? params.period : 'week');
    const size = (params.size ? params.size : '0');
    const licence = (params.licence ? params.licence : '0');
    const genre = (params.genre ? params.genre : '0');

    let tagsParams: string[];
    if (params.tag) {
      if (params.tag instanceof Array) {
        tagsParams = params.tag;
      } else {
        tagsParams = [params.tag];
      }
    } else {
      tagsParams = [];
    }

    const tags = tagsParams.length > 0 ?
      '&' + tagsParams.map((t: string) => `tag=${encodeURIComponent(t)}`).join('&')
      : '';
    const onlyDefis = (params.onlyDefis ? params.onlyDefis : 'false');
    const readingList = (params.readingList ? params.readingList : '0');
    const mine = (params.mine ? params.mine : 'false');
    if (!(this.read.page >= 0)) this.read.page = 1;//Added as a quick fix, when the page is accessed from the url, this.read.page is undefined
    const url = `/read?action=${action}&period=${period}&size=${size}&licence=${licence}&genre=${genre}${tags}&onlyDefis=${onlyDefis}&readingList=${readingList}&mine=${mine}&page=${this.read.page}`;
    return this.apiClient.get(url).then((t: any) => {
      this.read.setTexts(t);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action loadReadPopularTags() {
    return this.apiClient.get(`/read/tags`).then((t: any) => {
      this.read.setPopularTags(t.tags);
    });
  }

  @action getReadTagsBeginningWith(input: string) {
    return this.apiClient.get(
      `/read/tags/find?query=${encodeURIComponent(input)}`
    );
  }

  @action loadReadingListsLight() {
    return this.apiClient.get(`/lists`).then((l: any) => {
      this.read.setLists(l);
    });
  }

  @action loadMeta(textId: number) {
    const t = this.text;
    t.meta = undefined;
    return this.apiClient.get(`/read/text/${textId}/meta`).then((d: any) => {
      t.setMeta(d);
    });
  }

  @action loadInfos(textId: number) {
    const t = this.text;
    t.infos = undefined;
    return this.apiClient.get(`/read/text/${textId}/infos`).then((d: any) => {
      t.setInfos(d);
    });
  }

  @action loadInfosStructure() {
    const t = this.text;
    return this.apiClient.get(`/read/text/${t.meta.id}/infos/structure`).then((s: any) => {
      t.infos.setStructure(s);
    });
  }

  @action loadTextLikes(textId: number) {
    const t = this.text;
    return this.apiClient.get(`/read/text/${textId}/likes`).then((d: any) => {
      t.setTextLikes(d);
    });
  }

  @action loadThanks(textId: number) {
    const t = this.text;
    this.text.thanks = [];
    return this.apiClient.get(`/read/text/${textId}/thanks`).then((th: any) => {
      t.setThanks(th);
    });
  }

  /* @action loadRecommandations(textId: number) {
    const t = this.text;
    this.text.recommandations = [];
    return this.apiClient.get(`/read/text/${textId}/recommandations`).then((r: any) => {
      t.setRecommandations(r);
    });
  } */

  @action loadTextFeed(textId: number) {
    const t = this.text;
    t.feed = undefined;
    return this.apiClient.get(`/read/text/${textId}/feed`).then((f: any) => {
      t.setFeed(f);
    });
  }

  @action loadMoreTextFeed(textId: number, beforeDate: string, _callback?: Function) {
    const t = this.text;
    return this.apiClient.get(`/read/text/${textId}/feed?beforeDate=${encodeURIComponent(beforeDate)}`).then((f: any) => {
      t.fillFeed(f);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action loadTextTalks(textId: number) {
    const url = `/read/text/${textId}/talks/all`;
    this.text.talkOrCommentSummary = [];
    return this.apiClient.get(url).then((t: any) => {
      this.text.setTalkOrCommentSummary(t);
    });
  }

  @action loadChapter(textId: number, chapterId: number, chapterVersionId?: number) {
    const t = this.text;
    t.chapter = undefined;
    let url: string;
    if (chapterVersionId) {
      url = `/read/text/${textId}/chapter/${chapterId}?chapterVersionId=${chapterVersionId}`;
    } else {
      url = `/read/text/${textId}/chapter/${chapterId}`;
    }
    return this.apiClient.get(url).then((d: any) => {
      t.setChapter(d);
      this.comments = new CommentListModel(undefined, undefined, undefined, undefined);
    });
  }

  @action loadComments(textId: number, chapterId: number) {
    this.comments.loading = true;
    const data = { textId: String(textId), chapterId: String(chapterId) };
    return this.apiClient.post(`/read/comments`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((d: any) => {
        this.comments = new CommentListModel(d.comments.map((c: any) => new CommentModel(c.id, c.userId, c.userIdentity,
          c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
          c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode, c.shownAnswers,
          c.textEdit, c.isNew)), 'text', d.authorText, d.authorDefi);
      });
  }

  @action loadChapterLikes() {
    const t = this.text;
    return this.apiClient.get(`/read/text/${t.meta.id}/chapter/${t.chapter.id}/likes`).then((d: any) => {
      t.setChapterLikes(d);
    });
  }

  @action loadChapterAnnotations() {
    const t = this.text;
    return this.apiClient.get(`/read/at/search?chapterVersionId=${t.chapter.version}`);
  }

  @action likeChapter() {
    const c = this.text;
    return this.apiClient.post(`/read/text/${c.meta.id}/chapter/${c.chapter.id}/like`).then((d: any) => {
      c.chapter.like();
    });
  }

  @action unlikeChapter() {
    const c = this.text;
    return this.apiClient.post(`/read/text/${c.meta.id}/chapter/${c.chapter.id}/like/del`).then((d: any) => {
      c.chapter.unlike();
    });
  }

  @action markChapterAsRead() {
    const c = this.text;
    return this.apiClient.post(`/read/text/${c.meta.id}/chapter/${c.chapter.id}/read`).then((d: any) => {
      c.chapter.markAsRead();
    });
  }

  @action markChapterAsUnread() {
    const c = this.text;
    return this.apiClient.post(`/read/text/${c.meta.id}/chapter/${c.chapter.id}/read/del`).then((d: any) => {
      c.chapter.markAsUnread();
    });
  }

  @action alertModeration(userIdentity: string, reasons: string) {
    const c = this.text;
    const data = {
      userIdentity: userIdentity,
      reasons: reasons
    };
    return this.apiClient.post(`/read/text/${c.meta.id}/chapter/${c.chapter.id}/moderation`, {
      data: data
    });
  }

  /**
   * Comments
   */
  @action handleInitAnswer(comment: CommentModel) {
    comment.setAnswerMode(true);
  }

  @action handleCancelAnswer(comment: CommentModel) {
    comment.setAnswerMode(false);
  }

  @action handleInitEdit(restPath: string, comment: CommentModel) {
    const data = { commentId: String(comment.id) };
    return this.apiClient.post(`${restPath}/initedit`, { data: data })
      .then((d: any) => {
        comment.enableEdit(d.message);
      });
  }

  @action handleCancelEdit(comment: CommentModel) {
    comment.disableEdit();
  }

  @action handleSubmitEdit(restPath: string, comment: CommentModel, text: string) {
    const data = { commentId: String(comment.id), text: text };
    return this.apiClient.post(`${restPath}/edit`, { data: data })
      .then((d: { message: string }) => {
        comment.validEdit(d.message);
      });
  }

  @action handleCommentSubmit(comments: CommentListModel, text: string, parentId?: number) {
    let data: {};
    switch (comments.commentType) {
      /* case 'diary':
        const profilePageRegex = /(.*\/author.*\/diary$)/g;
        const url = window.location.href;
        const memberId = profilePageRegex.test(url) ? this.profile.id : undefined;
        if (parentId) {
          if (memberId) {
            data = {
              memberId: String(memberId), text: text,
              parentId: String(parentId)
            };
          } else {
            data = {
              text: text, parentId: String(parentId)
            };
          }
        } else {
          if (memberId) {
            data = { memberId: String(memberId), text: text };
          } else {
            data = { text: text };
          }
        }
        break; */
      case 'text':
        const textId = this.text.meta.id;
        const chapterId = this.text.chapter.id;
        if (parentId) {
          data = {
            textId: String(textId), chapterId: String(chapterId), text: text,
            parentId: String(parentId)
          };
        } else {
          data = { textId: String(textId), chapterId: String(chapterId), text: text };
        }
        break;
      case 'defi':
        const defiId = this.mainDefi.defi.id;
        if (parentId) {
          data = {
            defiId: String(defiId), text: text,
            parentId: String(parentId)
          };
        } else {
          data = { defiId: String(defiId), text: text };
        }
        break;
      case 'talk':
        const talkParentId = this.mainTalk.talk.id;
        if (parentId) {
          data = {
            talkParentId: String(talkParentId), text: text,
            parentId: String(parentId)
          };
        } else {
          data = { talkParentId: String(talkParentId), text: text };
        }
        break;
      default:
    }

    return this.apiClient.post(`${comments.restPath()}/new`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((d: CommentModel) => {
      const newComment = new CommentModel(d.id, d.userId, d.userIdentity, d.userUrl, d.userAvatar,
        d.userAdmin, d.title, d.text, d.chapterName, d.chapterTitle,
        d.chapterUrl, d.ago, d.childs, d.score, d.voted, d.answerMode, d.editMode,
        d.shownAnswers, d.textEdit, d.isNew);
      if (parentId) {
        const parent = comments.findComment(parentId);
        parent.comment.setAnswerMode(false);

        if (parent.parentId) {
          const grandParent = comments.findComment(parent.parentId);
          grandParent.comment.childs.push(newComment);
        } else {
          parent.comment.childs.push(newComment);
        }
      } else {
        comments.comments.unshift(newComment);
      }

      this.addAlert(new AlertModel(
        'newComment',
        'Commentaire posté !',
        '',
        'success',
        5
      ));

      return newComment.id;
    });
  }

  @action postDiaryOnFeed(text: string) {
    const connectedId = this.connected.user.id;
    const data = {
      text: text, memberId: String(connectedId)
    };
    return this.apiClient.post('/profile/diaries/new', {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((d: CommentModel) => {
      this.home.feed.addDiary(d);

      this.addAlert(new AlertModel(
        'newComment',
        'Journal posté !',
        '',
        'success',
        5
      ));

      return d.id;
    });
  }

  @action handleRemove(comments: CommentListModel, comment: CommentModel) {
    const data = { commentId: String(comment.id) };
    return this.apiClient.post(`${comments.restPath()}/remove`, { data: data })
      .then((d: any) => {
        switch (comments.commentType) {
          /* case 'diary':
            const profileId = this.profile.id;
            if (profileId) {
              this.loadProfileDiary(profileId);
            } else {
              this.loadFeed();
            }
            break; */
          case 'text':
            const textId = this.text.meta.id;
            const chapterId = this.text.chapter.id;
            this.loadComments(textId, chapterId);
            break;
          case 'defi':
            const defiId = this.mainDefi.defi.id;
            this.loadDefiComments(defiId);
            break;
          case 'talk':
            const talkParentId = this.mainTalk.talk.id;
            this.loadTalkMessages(talkParentId);
            break;
        }
      });
  }

  @action handleUpvote(restPath: string, comment: CommentModel) {
    const data = { commentId: String(comment.id) };
    return this.apiClient.post(`${restPath}/upvote`, { data: data })
      .then((d: any) => {
        comment.upVote();
      });
  }

  @action handleUnUpvote(restPath: string, comment: CommentModel) {
    const data = { commentId: String(comment.id) };
    return this.apiClient.post(`${restPath}/unupvote`, { data: data })
      .then((d: any) => {
        comment.unUpVote();
      });
  }

  /**
   * Write
   */
  @action loadDrafts() {
    return this.apiClient.get('/write/drafts').then((d: any) => {
      this.write.setDrafts(d.drafts, d.draftDefis);
    });
  }

  @action loadTexts(first: boolean, _callback?: Function) {
    const write = this.write;
    if (first) {
      write.page = 0;
    }
    write.enableLoading();
    return this.apiClient.get(
      `/write/texts?criteria=${write.criteria}&order=${write.order}&page=${write.page}`
    ).then((t: any) => {
      this.write.setTexts(first, t);
      this.write.disableLoading();
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action generatePeripetie() {
    return this.apiClient.post(`/write/texts/generator/peripetie`);
  }

  @action generateTrainingInstructions(constraint: boolean, keepPitch: boolean,
    text: { id: number, url: string, chapterId: number }) {
    return this.apiClient.get(`/write/texts/training/pitch?constraint=${constraint}`).then((trainingInstructions: any) => {
      this.write.setTrainingInstructions(trainingInstructions, keepPitch);
      this.write.trainingInstructions.text = text;
    });
  }

  @action validateTraining(words: number) {
    const data = { words: words };
    return this.apiClient.post('/write/texts/training/validate', { data: data });
  }

  generateProgramAlerts(result: { levelType: string, week: boolean, obj: number }[]): AlertModel[] {
    if (result && result.length > 0) {
      return result.map((r) => {
        const trainingType = r.levelType === 'words' ?
          `mot${r.obj > 1 ? 's' : ''}`
          : (r.levelType === 'defis' ?
            `défi${r.obj > 1 ? 's' : ''}`
            : (r.levelType === 'texts' ?
              `œuvre${r.obj > 1 ? 's' : ''}`
              : (r.levelType === 'trainings' ?
                `entraînement${r.obj > 1 ? 's' : ''}` :
                (r.levelType === 'chapters' ?
                  `chapitre${r.obj > 1 ? 's' : ''}` :
                  ''))));

        const period = r.week ? 'semaine' : 'jour';
        return new AlertModel(
          'showAlertObjective',
          'Objectif atteint !',
          `Félicitations, vous avez atteint votre objectif de ${r.obj} ${trainingType}/${period}`,
          'success',
          5
        );
      });
    } else {
      return [];
    }
  }

  @action startTraining() {
    this.write.training = new TrainingModel(this.write.trainingInstructions);
  }

  @action createNewTextTraining(title: string, textContent: string) {
    const data = {
      title: title,
      textContent: textContent
    };
    return this.apiClient.post('/write/texts/new/training', { data: data });
  }


  @action loadTextInfos(textId: number) {
    const currentText = this.write.currentText;
    currentText.id = undefined;
    currentText.infos = undefined;
    return this.apiClient.get(`/write/texts/text/${textId}/infos`).then((t: any) => {
      currentText.init(textId, t);
    });
  }

  @action loadTextStructure(textId: number) {
    return this.apiClient.get(`/write/texts/text/${textId}/structure`).then((c: any) => {
      this.write.currentText.setStructure(c);
    });
  }

  @action loadTextParcoursSummary(textId: number, chapterId: number) {
    return this.apiClient.get(`/write/texts/text/${textId}/parcourssummary?chapterId=${chapterId}`).then((ps: any) => {
      this.write.currentText.infos.parcoursSummary = ps;
    });
  }

  @action saveTextParcoursSummary(textId: number, chapterId: number) {
    const data = this.write.currentText.infos.parcoursSummary;
    return this.apiClient.post(`/write/texts/text/${textId}/parcourssummary?chapterId=${chapterId}`, { data: data });
  }

  @action saveTextTitle(title: string) {
    const meta = this.text.meta;
    const data = {
      title: title
    };
    return this.apiClient.post(`/write/texts/text/${meta.id}/title`,
      { data: data }).then((t: string) => {
        meta.title = t;
      });
  }

  @action saveParcoursTextTitle(title: string) {
    const status = this.mainParcours.status;
    const data = {
      title: title
    };
    return this.apiClient.post(`/write/texts/text/${status.textId}/title`,
      { data: data }).then((t: string) => {
        status.textTitle = t;
      });
  }
  @action saveParcoursTextOpenedToPublishers(openedToPublishers: boolean) {
    const meta = this.text.meta;
    const textId = meta.id;
    const data = {
      openedToPublishers: openedToPublishers
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/editoraccessibility`, { data: data })
      .then((d: any) => {
        this.text.meta.openedToPublishers = d;

        this.addAlert(new AlertModel(
          'saveAccessibility',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      });
  }

  @action saveTextStructure(textId: number, twoLevels: boolean,
    structure: {
      parts: { title: string, chapters: { id: number, title: string }[] }[],
      chapters: { id: number, title: string }[]
    }) {
    const data = {
      twoLevels: twoLevels,
      parts: structure.parts,
      chapters: structure.chapters
    };

    return this.apiClient.post(
      `/write/texts/text/${textId}/structure`, { data: data }
    );
  }

  @action saveTextStructureParcours(textId: number, parts: { index: number, title: string }[]) {
    const data = {
      parts: parts
    };

    return this.apiClient.post(
      `/write/texts/text/${textId}/structure`, { data: data }
    )
      .then((d: any) => {
        this.addAlert(new AlertModel(
          'saveChapters',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      });
  }

  @action saveTextColors() {
    const meta = this.text.meta;
    const data = {
      backgroundColor: meta.backgroundColor,
      textColor: meta.textColor,
      linkColor: meta.linkColor
    };
    return this.apiClient.post(
      `/write/texts/text/${meta.id}/colors`, { data: data })
      .then((d: any) => {
        meta.oldBackgroundColor = meta.backgroundColor;
        meta.oldTextColor = meta.textColor;
        meta.oldLinkColor = meta.linkColor;
        this.addAlert(new AlertModel(
          'saveColors',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      });
  }

  @action saveTextSummary(summary: string) {
    const textId = this.text.meta.id;
    const infos = this.text.infos;
    const data = {
      summary: summary
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/summary`, { data: data })
      .then((d: any) => {
        infos.summary = d.summary.trim().length > 0 ? d.summary : undefined;
      });
  }

  @action saveTextCover(base64: string) {
    const textId = this.text.meta.id;
    const infos = this.text.infos;
    const file = infos.coverFileTemp;

    if (base64) {
      const data = {
        base64: base64
      };
      return this.apiClient.post(
        `/write/texts/text/${textId}/cover/base64`, { data: data }
      ).then((d: any) => {
        infos.cover = d;
        infos.coverTemp = undefined;
        infos.coverFileTemp = undefined;
      });
    } else {
      if (file) {
        return this.apiClient.post(
          `/write/texts/text/${textId}/cover`,
          {
            files: [
              { key: 'cover', value: file }
            ]
          }).then((d: any) => {
            infos.cover = d;
            infos.coverTemp = undefined;
            infos.coverFileTemp = undefined;
          }, (error: any) => {
            console.log(error)
            this.addAlert(new AlertModel(
              'errorUploadCover',
              'Une erreur s\'est produite, veuillez vérifier que votre fichier n\'est pas trop volumineux.',
              '',
              'error',
              5
            ));
          });
      } else {
        return this.apiClient.post(`/write/texts/text/${textId}/cover/remove`)
          .then((d: any) => {
            infos.cover = undefined;
            infos.coverTemp = undefined;
            infos.coverFileTemp = undefined;
          });
      }
    }
  }

  @action generateCover(image: string, font: string, color: string) {
    const textId = this.text.meta.id;
    const data = {
      image: image,
      font: font,
      color: color
    };
    return this.apiClient.post(`/write/texts/text/${textId}/cover/generate`, { data: data });
  }

  @action saveTextAccessibility(pub: boolean, users: { label: string, value: string }[], sensibleContent: boolean,
    openToComments: boolean, openToExport: boolean, openedToPublishers: boolean, showOldVersions: boolean) {
    const meta = this.text.meta;
    const textId = meta.id;
    const data = {
      pub: pub,
      users: users.map(u => u.value),
      sensibleContent: sensibleContent,
      openToComments: openToComments,
      openToExport: openToExport,
      openedToPublishers: openedToPublishers,
      showOldVersions: showOldVersions
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/accessibility`, { data: data })
      .then((d: any) => {
        meta.pub = pub;
        meta.users = users;
        meta.sensibleContent = sensibleContent;
        meta.openToComments = openToComments;
        meta.openToExport = openToExport;
        meta.openedToPublishers = openedToPublishers;
        meta.showOldVersions = showOldVersions;

        this.addAlert(new AlertModel(
          'saveAccessibility',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      });
  }

  @action loadAllGenres() {
    const textId = this.text.meta.id;
    return this.apiClient.get(`/write/texts/text/${textId}/genrestags`);
  }

  @action saveTextGenresTags(genres: string[], tags: string[]) {
    const textId = this.text.meta.id;
    const data = {
      genres: genres,
      tags: tags
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/genrestags`, { data: data })
      .then((d: any) => {
        this.text.infos.genres = genres.map(g => parseInt(g));
        this.text.infos.tags = tags;
      });
  }

  @action saveTextLicence(licence: number) {
    const textId = this.text.meta.id;
    const data = {
      licence: licence,
    };

    return this.apiClient.post(
      `/write/texts/text/${textId}/licence`, { data: data }
    ).then((d: any) => {
      this.text.infos.licence = licence;
    });
  }

  @action createNewChapter(textId: number) {
    return this.apiClient.post(
      `/write/texts/text/${textId}/newchapter`
    );
  }

  @action createNewPart(textId: number) {
    return this.apiClient.post(
      `/write/texts/text/${textId}/newpart`
    );
  }

  //Thanks
  @action loadUsersToThank() {
    return this.apiClient.get(
      `/write/texts/text/${this.write.currentText.id}/chapter/${this.write.currentText.currentChapter.id}/thanks/toadd`
    );
  }

  @action thankAdd(textId: number, users: { userId: number, message: string }[]) {
    const data = {
      users: users
    };
    return this.apiClient.post(
      `/read/text/${textId}/thanks/add`, { data: data }
    ).then((d: any) => this.loadThanks(textId));
  }

  @action thankEdit(textId: number, userId: number, message: string) {
    const data = {
      userId: userId,
      message: message
    };
    return this.apiClient.post(
      `/read/text/${textId}/thanks/edit`, { data: data }
    ).then((d: any) => this.loadThanks(textId));
  }

  @action thankRemove(textId: number, userId: number) {
    const data = {
      userId: userId
    };
    return this.apiClient.post(
      `/read/text/${textId}/thanks/remove`, { data: data }
    ).then((d: any) => this.loadThanks(textId));
  }

  // Transform to chapter
  @action getOtherTexts() {
    return this.apiClient.get(`/write/texts/text/${this.text.meta.id}/othertexts`);
  }

  @action transformToChapter(targetTextId: string) {
    const data = {
      targetTextId: targetTextId
    };
    return this.apiClient.post(`/write/texts/text/${this.text.meta.id}/transformtochapter`, { data: data });
  }

  // Pdf
  @action downloadPdf(filename: string) {
    if (this.isAuthenticated) {
      const url = `${window.location.protocol}//${window.location.host}/download/write/pdf/${encodeURIComponent(filename)}/`;
      window.open(url);
    }
  }

  // Notes
  @action loadNotes(textId: number) {
    return this.apiClient.get(`/write/texts/text/${textId}/notes`).then((notes: any) => {
      this.write.currentText.setNotes(notes);
    });
  }

  @action loadNote(textId: number, noteId: number) {
    const currentText = this.write.currentText;
    return this.apiClient.get(`/write/texts/text/${textId}/notes/note/${noteId}`).then((note: any) => {
      currentText.setCurrentNote(note);
    });
  }

  @action createNote(textId: number, title: string, message: string) {
    const data = {
      title: title,
      message: message
    };
    return this.apiClient.post(`/write/texts/text/${textId}/notes/note`, { data: data });
  }

  @action updateNote(textId: number, noteId: number, title: string, message: string) {
    const data = {
      title: title,
      message: message
    };
    return this.apiClient.post(`/write/texts/text/${textId}/notes/note/${noteId}`, { data: data });
  }

  @action removeNote(textId: number, noteId: number) {
    return this.apiClient.post(`/write/texts/text/${textId}/notes/note/${noteId}/remove`).then((n: any) => {
      this.loadNotes(textId);
    });
  }

  // Characters
  @action loadCharacters(textId: number, chapterId?: number) {
    return this.apiClient.get(`/write/texts/text/${textId}/characters`).then((characters: any) => {
      this.write.currentText.setCharacters(characters, chapterId);
    });
  }

  @action loadCharacter(textId: number, characterId: number) {
    const currentText = this.write.currentText;
    return this.apiClient.get(
      `/write/texts/text/${textId}/characters/${characterId}`).then((character: any) => {
        currentText.setCurrentCharacter(character);
      });
  }

  @action createCharacter(textId: number, name: string, description: string) {
    const data = {
      name: name,
      description: description
    };
    return this.apiClient.post(`/write/texts/text/${textId}/characters`, { data: data });
  }

  @action updateCharacter(textId: number, characterId: number, name: string, description: string) {
    const data = {
      name: name,
      description: description
    };
    return this.apiClient.post(`/write/texts/text/${textId}/characters/${characterId}`, { data: data });
  }

  @action postCharacterImage(textId: number, characterId: number, file: File) {
    return this.apiClient.post(
      `/write/texts/text/${textId}/characters/${characterId}/image`,
      {
        files: [
          { key: 'image', value: file }
        ]
      }
    );
  }

  @action removeCharacterImage(textId: number, characterId: number) {
    return this.apiClient.post(
      `/write/texts/text/${textId}/characters/${characterId}/image/remove`
    );
  }

  @action removeCharacter(textId: number, characterId: number) {
    return this.apiClient.post(
      `/write/texts/text/${textId}/characters/${characterId}/remove`).then((n: any) => {
        this.loadCharacters(textId);
      });
  }

  @action addRemoveCharactersToChapter(characters: number[]) {
    const currentText = this.write.currentText;
    const data = {
      characters: characters
    };
    return this.apiClient.post(
      `/write/texts/text/${currentText.id}/chapter/${currentText.currentChapter.id}/characters`, { data: data }
    );
  }

  @action loadTextStatisticsWrite(stat: string) {
    const currentText = this.write.currentText;
    return this.apiClient.get(
      `/write/texts/text/${currentText.id}/statistics/write?statistic=${stat}`
    ).then((stats: any[]) => {
      currentText.setStatisticsWrite(stats);
    });
  }

  @action loadTextStatisticsTopWords(chapterId: number) {
    const currentText = this.write.currentText;
    const url = chapterId ? `/write/texts/text/${currentText.id}/statistics/write/topwords?chapterId=${chapterId}`
      : `/write/texts/text/${currentText.id}/statistics/write/topwords`;
    return this.apiClient.get(url).then((words: any) => {
      currentText.setTopWords(words);
    });
  }

  @action loadTextStatisticsRead(stat: string, chapterId: number) {
    const currentText = this.write.currentText;
    const url = chapterId ? `/write/texts/text/${currentText.id}/statistics/read?statistic=${stat}&chapter=${chapterId}`
      : `/write/texts/text/${currentText.id}/statistics/read?statistic=${stat}`;
    return this.apiClient.get(url).then((stats: any[]) => {
      currentText.setStatisticsRead(stats);
    });
  }

  @action loadTextStatisticsCompareList() {
    const currentText = this.write.currentText;
    return this.apiClient.get(`/write/texts/text/${currentText.id}/statistics/compare`).then((stats: any[]) => {
      currentText.setStatisticsCompareList(stats);
    });
  }

  @action loadTextStatisticsCompare(author: string) {
    const currentText = this.write.currentText;
    return this.apiClient.get(`/write/texts/text/${currentText.id}/statistics/compare/author?author=${author}`).then((stats: any) => {
      currentText.setStatisticsCompare(stats);
    });
  }

  @action loadTextStatisticsCompareAuthors() {
    const currentText = this.write.currentText;
    return this.apiClient.get(`/write/texts/text/${currentText.id}/statistics/compare/authors`).then((stats: any[]) => {
      currentText.setStatisticsCompareAuthors(stats);
    });
  }

  @action publishText(chapters: number[]) {
    const textId = this.text.meta.id;
    const data = {
      chapters: chapters
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/publish`, { data: data }
    ).then((result: { levelType: string, week: boolean, obj: number }[]) => {
      const p: any[] = [];
      p.push(this.loadMeta(textId));
      p.push(this.loadInfos(textId));
      p.push(this.loadThanks(textId));
      p.push(this.loadTextTalks(textId));
      return Promise.all(p).then((p: any) => {
        const programAlerts = this.generateProgramAlerts(result);
        programAlerts.push(
          new AlertModel(
            'publishText',
            'Félicitations, votre œuvre est publiée !',
            '',
            'success',
            5
          )
        );

        this.addAlerts(programAlerts);
      });
    });
  }

  @action removeText() {
    return this.apiClient.post(
      `/write/texts/text/${this.text.meta.id}/remove`
    );
  }

  @action reindexText() {
    return this.apiClient.post(
      `/admin/reindex/text/${this.text.meta.id}`
    );
  }

  // Chapter
  @action loadTextChapter(textId: number, chapterId: number) {
    this.write.enableLoading();
    const currentText = this.write.currentText;
    currentText.currentChapter = undefined;
    return this.apiClient.get(`/write/texts/text/${textId}/chapter/${chapterId}`).then((c: any) => {
      currentText.setCurrentChapter(c);
    });
  }

  @action loadAnnotations(textId: number, chapterId: number) {
    return this.apiClient.get(`/write/texts/text/${textId}/chapter/${chapterId}/annotations`);
  }

  @action saveChapter(withMessage: boolean, withSaveStats: boolean) {
    if (!this.write.blocking) {
      this.write.enableBlocking();
      const textId = this.write.currentText.id;
      const chapter = this.write.currentText.currentChapter;
      const premium = this.connected.premium.premium;

      // Force convert to html
      chapter.convertToHTML();

      const data = {
        title: chapter.title,
        textContent: chapter.textContent
      };
      return this.apiClient.post(
        `/write/texts/text/${textId}/chapter/${chapter.id}/save`, { data: data })
        .then((result: { levelType: string, week: boolean, obj: number }[]) => {
          const programAlerts = this.generateProgramAlerts(result);
          if (withMessage) {
            programAlerts.push(
              new AlertModel(
                'saveChapter',
                'Sauvegardé !',
                '',
                'success',
                1
              )
            );
          }
          this.addAlerts(programAlerts);
          if (withSaveStats && premium) {
            this.saveChapterStatistics();
          }
          chapter.saved = true;
          this.write.disableBlocking();
        }, (err: any) => {
          this.write.disableBlocking();
        });
    }
  }

  @action saveChapterFromHub(withMessage: boolean, withSaveStats: boolean) {
    if (!this.write.blocking) {
      this.write.enableBlocking();
      const textId = this.mainParcours.status.textId;
      const chapterId = this.mainParcours.status.chapterId;
      const premium = this.connected.premium.premium;

      const data = {
        title: "",
        textContent: ""
      };
      return this.apiClient.post(
        `/write/texts/text/${textId}/chapter/${chapterId}/save`, { data: data })
        .then((result: { levelType: string, week: boolean, obj: number }[]) => {
          const programAlerts = this.generateProgramAlerts(result);
          if (withMessage) {
            programAlerts.push(
              new AlertModel(
                'saveChapter',
                'Sauvegardé !',
                '',
                'success',
                1
              )
            );
          }
          this.addAlerts(programAlerts);
          if (withSaveStats && premium) {
            this.saveChapterStatistics();
          }
          this.write.disableBlocking();
        }, (err: any) => {
          this.write.disableBlocking();
        });
    }
  }

  @action saveChapterStatistics() {
    const textId = this.write.currentText.id;
    const chapter = this.write.currentText.currentChapter;

    return new Promise((resolve, reject) => {
      superagent
        .post(`/wtext/${textId}/all`)
        .send([chapter.id])
        .then((d: any) => {
          chapter.saved = true;
        });
    });
  }

  @action saveChapterStatisticsFromHub() {
    const textId = this.mainParcours.status.textId;
    const chapterId = this.mainParcours.status.chapterId;

    return new Promise((resolve, reject) => {
      superagent
        .post(`/wtext/${textId}/all`)
        .send([chapterId]);
    });
  }

 

  

  @action publishChapter() {
    if (!this.write.blocking) {
      this.write.enableBlocking();
      const text = this.write.currentText;
      const chapter = this.write.currentText.currentChapter;
      return this.apiClient.post(
        `/write/texts/text/${text.id}/chapter/${chapter.id}/publish`)
        .then((result: { levelType: string, week: boolean, obj: number }[]) => {
          const alerts = this.generateProgramAlerts(result);
          if (text.infos.published) {
            alerts.push(new AlertModel(
              'publishChapter',
              'Chapitre publié !',
              '',
              'success',
              5
            ));
          } else {
            alerts.push(new AlertModel(
              'publishChapter',
              'Œuvre et chapitre publiés !',
              '',
              'success',
              5
            ));
          }

          this.addAlerts(alerts);
          chapter.status = 'PUBLISHED';
          this.write.disableBlocking();
        }, (err: any) => {
          this.write.disableBlocking();
        });
    }
  }

  @action resetChapter() {
    if (!this.write.blocking) {
      this.write.enableBlocking();
      const textId = this.write.currentText.id;
      const chapterId = this.write.currentText.currentChapter.id;
      return this.apiClient.post(
        `/write/texts/text/${textId}/chapter/${chapterId}/reset`).then((d: any) => {
          this.write.disableBlocking();
          this.loadTextChapter(textId, chapterId);
          this.addAlert(new AlertModel(
            'publishChapter',
            'Modifications annulées !',
            '',
            'success',
            1
          ));
        }, (err: any) => {
          this.write.disableBlocking();
        });
    }
  }

  @action canRemoveChapter() {
    const textId = this.write.currentText.id;
    const chapterId = this.write.currentText.currentChapter.id;
    return this.apiClient.post(`/write/texts/text/${textId}/chapter/${chapterId}/canremove`);
  }

  @action removeChapter() {
    const textId = this.write.currentText.id;
    const chapterId = this.write.currentText.currentChapter.id;
    return this.apiClient.post(`/write/texts/text/${textId}/chapter/${chapterId}/remove`);
  }

  @action prenextChapter() {
    const text = this.write.currentText;
    const chapter = this.write.currentText.currentChapter;
    return this.apiClient.post(
      `/write/texts/text/${text.id}/chapter/${chapter.id}/prenext`
    );
  }

  @action nextChapter(answers: { questionId: number, answerId: number }[]) {
    const text = this.write.currentText;
    const chapter = this.write.currentText.currentChapter;
    const data = {
      answers: answers
    };
    return this.apiClient.post(
      `/write/texts/text/${text.id}/chapter/${chapter.id}/next`, { data: data }
    );
  }

  @action nextChapterFromHub(answers: { questionId: number, answerId: number }[]) {
    const textId = this.mainParcours.status.textId;
    const chapterId = this.mainParcours.status.chapterId;
    const data = {
      answers: answers
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/chapter/${chapterId}/next`, { data: data }
    );
  }

  @action transferTextToScribay(text: { id: number, url: string, chapterId: number }) {
    const textId = this.mainParcours.status.textId;
    const chapterId = this.mainParcours.status.chapterId;
    const data = {
      text: text
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/chapter/${chapterId}/next`, { data: data }
    );
  }

  @action checkParcoursChapter(checkId: number) {
    const text = this.write.currentText;
    const chapter = this.write.currentText.currentChapter;
    return this.apiClient.post(
      `/write/texts/text/${text.id}/chapter/${chapter.id}/check/${checkId}`
    );
  }

  @action changeStep(textId: number, chapter: any, step: number) {
    const data = {
      step: step
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/chapter/${chapter.id}/step`, { data: data }
    ).then((d: any) => {
      chapter.step = step;
      chapter.showAnnotations = true;
      this.addAlert(new AlertModel(
        'changeStep',
        'Statut du chapitre changé !',
        '',
        'success',
        1
      ));
    });
  }

  @action compareVersions() {
    const textId = this.write.currentText.id;
    const chapterId = this.write.currentText.currentChapter.id;
    return this.apiClient.get(
      `/write/texts/text/${textId}/chapter/${chapterId}/compare`
    );
  }

  @action loadChapterDescription(textId: number, chapterId: number) {
    return this.apiClient.get(
      `/write/texts/text/${textId}/chapter/${chapterId}/description`
    );
  }

  @action saveChapterDescription(textId: number, chapterId: number, description: string) {
    const data = {
      description: description
    };
    return this.apiClient.post(
      `/write/texts/text/${textId}/chapter/${chapterId}/description`, { data: data }
    );
  }

  @action createNewTextBlank(title: string) {
    const data = {
      title: title
    };
    return this.apiClient.post('/write/texts/new/blank', { data: data });
  }

  @action createNewTextCustom(title: string, twoLevels: boolean,
    parts: { title: string, chapters: { title: string }[] }[], chapters: { title: string }[]) {
    const data = {
      title: title,
      twoLevels: twoLevels,
      parts: parts,
      chapters: chapters
    };
    return this.apiClient.post('/write/texts/new/custom', { data: data });
  }

  @action createNewTextFromCanvas(canvasId: number, title: string) {
    const data = {
      title: title,
      canvasId: canvasId
    };
    return this.apiClient.post('/write/texts/new/canvas', { data: data });
  }

  @action loadParcoursPrerequisites(parcoursId: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}`).then((parcours: any) => {
      this.mainParcours.setCurrentParcours(parcours);
    });
  }

  @action loadParcoursSummaryExamples(parcoursId: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/examples`).then((ps: any) => {
      this.write.currentText.infos.parcoursSummaryExamples = ps;
    });
  }

  @action loadParcoursSummaryExamplesForIndex(parcoursId: number, index: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/examples/index/${index}`);
  }

  @action loadParcoursSummaryExample(parcoursId: number, exampleId: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/examples/example/${exampleId}`);
  }

  @action loadParcoursProgress(parcoursId: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/progress`).then((pp: any) => {
      this.mainParcours.parcoursProgress = pp;
    });
  }

  @action changeParcoursProgressVisible(parcoursId: number) {
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/progress/visible`).then((pp: any) => {
      this.loadParcoursProgress(parcoursId);
    });
  }

  // Talk
  @action loadParcoursTalks(parcoursId: number, tag?: string[], reset?: boolean) {
    if (reset) {
      this.mainTalk.resetTalks();
    }

    let selectedTags: any;
    if (tag) {
      if (tag instanceof Array) {
        selectedTags = tag;
      } else {
        selectedTags = [tag];
      }
    } else {
      selectedTags = [];
    }

    let url: string;
    if (selectedTags.length > 0) {
      const queryParams = selectedTags.map((t: string) => `tag=${encodeURIComponent(t)}`);
      url = `/write/texts/new/parcours/${parcoursId}/talks/all?${queryParams.join('&')}&count=${this.mainTalk.count}`;
    } else {
      url = `/write/texts/new/parcours/${parcoursId}/talks/all?count=${this.mainTalk.count}`;
    }
    return this.apiClient.get(url).then((t: any) => {
      this.mainTalk.setTalks(t);
    });
  }

  @action sendParcoursMessage(parcoursId: number, message: string) {
    const data = { message: message };
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/discussion`,
      { data: data });
  }

  @action loadParcoursDiscussion(parcoursId: number, first: boolean) {
    if (first) {
      this.mainParcours.discussion.count = 0;
      this.mainParcours.discussion.loading = true;
/*       this.connected.messagesCount = 0;
 */    }
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/discussion?count=${this.chat.discussion.count}`).then((d: any) => {
      this.mainParcours.discussion.setDiscussion(d, this.connected.user.id);
      this.chat.discussion.loading = false;
    });
  }

  @action startPrologue(parcoursId: number) {
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/startPrologue`);
  }

  @action startParcours(parcoursId: number, title?: string) {
    const data = title?
      {
        title: title
      } 
    :
      {
        title: "No title"
      };
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/startParcours`, { data: data });
  }

  @action loadPrologue(parcoursId: number, textPrologueId: number, chapterId: number) {
    return this.apiClient.get(`/write/texts/new/parcours/${parcoursId}/prologue/${textPrologueId}`).then((prologue: any) => {
      this.mainParcours.setPrologue(textPrologueId, prologue, chapterId);
    });
  }

  @action savePrologue(parcoursId: number, textPrologueId: number, textContent: string) {
    const data = {
      textContent: textContent
    };
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/prologue/${textPrologueId}`, { data: data })
      .then((result: { levelType: string, week: boolean, obj: number }[]) => {
        const programAlerts = this.generateProgramAlerts(result);
        programAlerts.push(
          new AlertModel(
            'savePrologue',
            'Sauvegardé !',
            '',
            'success',
            1
          )
        );
        this.addAlerts(programAlerts);
      });
  }

  @action nextPrologue(parcoursId: number, textPrologueId: number) {
    return this.apiClient.post(`/write/texts/new/parcours/${parcoursId}/prologue/${textPrologueId}/next`);
  }

  @action createNewTextFromParcours(parcours: ParcoursModel, title: string, parcoursTextId: number) {
    const answers = parcours.questions.map(q => {
      return {
        questionId: q.id,
        answerId: q.answers && q.answers.length > 0 ? q.answerId : undefined,
        answerText: q.answerText
      };
    });

    const data = {
      parcoursTextId: parcoursTextId,
      title: title,
      answers: answers,
      summary: parcours.summary.contents
    };
    return this.apiClient.post(`/write/texts/new/parcours/${parcours.id}/confirm`, { data: data });
  }

  @action initSearch() {
    this.search.startSearch();
  }

  @action resetListValues() {
    this.search.resetListValues();
  }

  @action resetPageValues() {
    this.search.resetPageValues();
  }

  // Search
  @action searchAll(value: string, cat?: string, notFirst?: boolean, _callback?: Function) {
    let url: string;
    if (cat && (cat === 'user' || cat === 'text' || cat === 'defi' || cat === 'talk')) {
      if (!notFirst)
        this.search.count = 0;
      url = `/search?value=${encodeURIComponent(value)}&cat=${cat}&count=${this.search.count}`;
    } else {
      url = `/search?value=${encodeURIComponent(value)}`;
    }

    return this.apiClient.get(url).then((r: any) => {
      if (cat && (cat === 'user' || cat === 'text' || cat === 'defi' || cat === 'talk')) {
        if (this.search.count === 0) {
          this.search.setPageValues(r.values);
        } else {
          this.search.addPageValues(r.values);
        }
      } else {
        this.search.setListValues(r.values);
      }
      if (_callback) {_callback(false)};
      }, (error: any) => {
        if (_callback) {_callback(true)};
        console.log(error)
      });
  }

  //Chat
  @action loadAllDiscussions(first: boolean, _callback?: Function) {
    if (first) {
      this.chat.allDiscussions.discussions = [];
      this.chat.allDiscussions.count = 0;
    }
    return this.apiClient.get(`/user/messages?count=${this.chat.allDiscussions.count}`).then((d: any[]) => {
      this.chat.allDiscussions.setDiscussions(d);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action findDiscussion(targetUser: number) {
    return this.apiClient.get(`/user/messages/find?targetUser=${targetUser}`);
  }

  @action loadDiscussion(discussionId: number, first: boolean) {
    if (first) {
      this.chat.discussion.count = 0;
      this.chat.discussion.loading = true;
/*       this.connected.messagesCount = 0;
 */    }
    return this.apiClient.get(`/user/messages/discussion/${discussionId}?count=${this.chat.discussion.count}`).then((d: any) => {
      this.loadConnectedOnly();
      this.chat.discussion.setDiscussion(d, this.connected.user.id);
      this.chat.discussion.loading = false;
      if (this.chat.allDiscussions && this.chat.allDiscussions.discussions
        && this.chat.allDiscussions.discussions.length > 0) {
        const discussions = this.chat.allDiscussions.discussions.slice();
        const ids = discussions.map(ad => String(ad.id));
        const idx = ids.indexOf(String(discussionId));
        if (idx !== -1) {
          discussions[idx].read = true;
          this.chat.allDiscussions.discussions = discussions;
        }
      }
    });
  }

  @action resetDiscussion(targetUser?: any) {
    this.chat.discussion.resetDiscussion(targetUser);
  }

  @action createDiscussion(message: string, users: string[]) {
    const data = {
      message: message,
      targetUsers: users
    };
    return this.apiClient.post(`/user/messages/discussion`,
      { data: data });
  }

  @action sendMessage(message: string) {
    const data = { message: message };
    return this.apiClient.post(`/user/messages/discussion/${this.chat.discussion.id}`,
      { data: data });
  }

  @action updateDiscussionUsers(users: string[]) {
    const data = { users: users };
    return this.apiClient.post(`/user/messages/discussion/${this.chat.discussion.id}/users`,
      { data: data });
  }

  @action makeDiscussionRead(discussionId: number) {
    return this.apiClient.post(`/user/messages/discussion/${discussionId}/read`);
  }

  // Notifications
  @action loadAllNotifications(first: boolean, _callback?: Function) {
    let url: string;
    if (first || !this.mainNotifications.idAfter) {
      url = '/user/notifications';
    } else {
      url = `/user/notifications?idAfter=${this.mainNotifications.idAfter}`;
    }
    return this.apiClient.get(url).then((n: { notifications: any[], end: boolean }) => {
      this.mainNotifications.setNotifications(first || !this.mainNotifications.idAfter, n.notifications, n.end);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action getRelations(input: string) {
    return this.apiClient.get(
      `/user/relations?query=${encodeURIComponent(input)}`
    );
  }

  // Talk
  @action loadTalks(tag?: string[], reset?: boolean, _callback?: Function) {
    if (reset) {
      this.mainTalk.resetTalks();
    }

    let selectedTags: any;
    if (tag) {
      if (tag instanceof Array) {
        selectedTags = tag;
      } else {
        selectedTags = [tag];
      }
    } else {
      selectedTags = [];
    }

    let url: string;
    if (selectedTags.length > 0) {
      const queryParams = selectedTags.map((t: string) => `tag=${encodeURIComponent(t)}`);
      url = `/talks/all?${queryParams.join('&')}&count=${this.mainTalk.count}`;
    } else {
      url = `/talks/all?count=${this.mainTalk.count}`;
    }
    return this.apiClient.get(url).then((t: any) => {
      this.mainTalk.setTalks(t);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action loadTalksPopularTags() {
    return this.apiClient.get(`/talks/tags`).then((t: any) => {
      this.mainTalk.setPopularTags(t.tags);
    });
  }

  @action getTalksTagsBeginningWith(input: string) {
    return this.apiClient.get(
      `/talks/tags/find?query=${encodeURIComponent(input)}`
    );
  }

  @action loadRelatedTalks(talkId: number) {
    this.mainTalk.setRelatedTalks([]);
    return this.apiClient.get(`/talks/talk/${talkId}/related`).then((t: any) => {
      this.mainTalk.setRelatedTalks(t.talks);
    });
  }

  @action loadLinkedTalks(talkId: number) {
    return this.apiClient.get(`/talks/talk/${talkId}/linked`).then((t: any) => {
      this.mainTalk.setLinkedTalks(t.talks);
    });
  }

  @action fetchTalks(notTalk: number, query: string, textId?: number) {
    const url = textId ?
      `/talks/fetch?query=${encodeURIComponent(query)}&notTalk=${notTalk}&textId=${textId}`
      : `/talks/fetch?query=${encodeURIComponent(query)}&notTalk=${notTalk}`;
    return this.apiClient.get(url);
  }

  @action loadTalk(talkParentId: number, textId: number, parcoursId: number) {
    let url = `/talks/talk/${talkParentId}`;
    if (textId) {
      url = url + `?textId=${textId}`;
    } else if (parcoursId) {
      url = url + `?parcoursId=${parcoursId}`;
    }
    return this.apiClient.get(url).then((t: any) => {
      this.mainTalk.setTalk(t);
    });
  }

  @action loadTalkMessages(talkParentId: number) {
    let url = `/talks/comments`;
    const data = { talkParentId: String(talkParentId) };
    return this.apiClient.post(url, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((d: any) => {
        this.comments = new CommentListModel(d.comments.map((c: any) => new CommentModel(c.id, c.userId, c.userIdentity,
          c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
          c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode, c.shownAnswers,
          c.textEdit, c.isNew)), 'talk', d.authorText, d.authorDefi);
      });
  }

  @action createNewDiscussion(title: string, message: string,
    tags: string[], linked: string[], textId: number, parcoursId: number) {
    let data: {};
    if (textId) {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked,
        textId: textId
      };
    } else if (parcoursId) {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked,
        parcoursId: parcoursId
      };
    } else {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked
      };
    }
    return this.apiClient.post(`/talks/new `, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action updateDiscussion(talkParentId: number, title: string, message: string,
    tags: string[], linked: string[], textId: number, parcoursId: number) {
    let data: {};
    if (textId) {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked,
        textId: textId
      };
    } else if (parcoursId) {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked,
        parcoursId: parcoursId
      };
    } else {
      data = {
        title: title,
        message: message,
        tags: tags.map(t => t.trim()).filter((elem, index, self) => {
          return index === self.indexOf(elem);
        }),
        linked: linked
      };
    }

    return this.apiClient.post(`/talks/talk/${talkParentId}`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action removeDiscussion(talk: TalkModel) {
    return this.apiClient.post(`/talks/talk/${talk.id}/remove`);
  }

  @action blockDiscussion(talk: TalkModel, blockMessage: string) {
    const data = {
      blockMessage: blockMessage
    };
    return this.apiClient.post(`/talks/talk/${talk.id}/block`, { data: data });
  }

  @action unblockDiscussion(talk: TalkModel) {
    return this.apiClient.post(`/talks/talk/${talk.id}/unblock`);
  }

  @action pinDiscussion(talk: TalkModel) {
    return this.apiClient.post(`/talks/talk/${talk.id}/pin`);
  }

  @action unpinDiscussion(talk: TalkModel) {
    return this.apiClient.post(`/talks/talk/${talk.id}/unpin`);
  }

  // Défi
  @action loadDefis(params: DefisQueryParams, reset?: boolean, _callback?: Function) {
    if (reset) {
      this.mainDefi.resetDefis();
    }

    const type = (params.type ? params.type : 'all');
    const available = (params.available ? params.available : true);
    const genre = (params.genre ? params.genre : 0);

    const sort = (params.sort ? params.sort : 'date');
    const order = (params.order ? params.order : 'desc');

    const url = `/defis?typeDefi=${type}&available=${available}&genre=${genre}&sort=${sort}&order=${order}&count=${this.mainDefi.count}`;
    return this.apiClient.get(url).then((t: any) => {
      this.mainDefi.setDefis(t);
      if (_callback) {_callback(false)};
    }, (error: any) => {
      if (_callback) {_callback(true)};
      console.log(error)
    });
  }

  @action loadDefi(defiId: number, edit: boolean) {
    const t = this;
    t.mainDefi.defi = undefined;
    return this.apiClient.get(`/defis/defi/${defiId}?edit=${edit}`).then((d: any) => {
      t.mainDefi.setDefi(d);
    });
  }

  @action loadDefiTexts(defiId: number) {
    return this.apiClient.get(`/defis/defi/${defiId}/texts`).then((t: any) => {
      this.mainDefi.defi.setTexts(t);
    });
  }

  @action loadDefiSimilar(defiId: number) {
    return this.apiClient.get(`/defis/defi/${defiId}/related`).then((d: any) => {
      this.mainDefi.setSimilar(d);
    });
  }

  @action removeDefi() {
    const defi = this.mainDefi.defi;
    if (!defi.inprogress && defi.reponsesObj.length === 0) {
      return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/del`).then(() => {
        this.addAlert(new AlertModel(
          'removeDefi',
          'Défi supprimé !',
          `Le défi ${defi.title} est supprimé.`,
          'success',
          5
        ));
        return defi.title;
      });
    }
  }
  @action deleteDefi() {//As an administrator
    const defi = this.mainDefi.defi;
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/remove`).then(() => {
      this.addAlert(new AlertModel(
        'removeDefi',
        'Défi supprimé !',
        `Le défi ${defi.title} est supprimé.`,
        'success',
        5
      ));
      return defi.title;
    });
  }

  @action acceptDefi(title: string) {
    const data = {
      title: title
    };
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/accept`, { data: data });
  }

  @action abandonDefi() {
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/abandon`).then((d: any) => {
      this.loadDefi(this.mainDefi.defi.id, false);
    });
  }

  @action openDefi() {
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/open`).then((d: any) => {
      this.mainDefi.defi.available = true;
    });
  }

  @action closeDefi() {
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/close`).then((d: any) => {
      this.mainDefi.defi.available = false;
    });
  }

  @action giveInterpretationDefi(title: string) {
    const data = {
      title: title
    };
    return this.apiClient.post(`/defis/defi/${this.mainDefi.defi.id}/interpretation`, { data: data });
  }

  @action createDefi(title: string, description: string,
    genre: number) {
    const data = {
      title: title,
      description: description,
      genre: genre
    };
    return this.apiClient.post(`/defis/new `, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action updateDefi(defiId: number, title: string, description: string,
    genre: number) {
    const data = {
      title: title,
      description: description,
      genre: genre
    };
    return this.apiClient.post(`/defis/defi/${defiId}`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action loadDefiComments(defiId: number) {
    const data = { defiId: String(defiId) };
    return this.apiClient.post(`/defis/comments`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((d: any) => {
        this.comments = new CommentListModel(d.comments.map((c: any) => new CommentModel(c.id, c.userId, c.userIdentity,
          c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
          c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode, c.shownAnswers,
          c.textEdit, c.isNew)), 'defi', d.authorText, d.authorDefi);
      });
  }

  @action loadSettingsAccount() {
    this.mainSettings.enableLoading();
    return this.apiClient.get(`/settings/account`).then((p: any) => {
      this.mainSettings.setAccount(p);
    });
  }

  @action saveSettingsAccountEmail() {
    const profile = this.mainSettings.account;
    profile.enableLoading();
    console.log(profile.email);
    const data = {
      email: profile.email
    };
    return this.apiClient.post(`/settings/account/changeemail`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action saveSettingsAccountPassword() {
    const profile = this.mainSettings.account;
    profile.enableLoading();
    const data = {
      currentPassword: (profile.currentPassword.trim().length > 0 ? profile.currentPassword : undefined),
      newPassword: (profile.newPassword.trim().length > 0 ? profile.newPassword : undefined),
    };
    return this.apiClient.post(`/settings/account/changepassword`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  @action loadSettingsPreferences() {
    this.mainSettings.enableLoading();
    return this.apiClient.get(`/settings/preferences`).then((p: any) => {
      this.mainSettings.setPreferences(p);
    });
  }

  @action saveSettingsPreferences() {
    const preferences = this.mainSettings.preferences;
    preferences.enableLoading();
    const data = {
      defaultSensibleContent: preferences.defaultSensibleContent,
      fontFamily: preferences.fontFamily,
      fontSize: preferences.fontSize,
      fontSpaced: preferences.fontSpaced,
      annotations: preferences.annotations
    };
    return this.apiClient.post(`/settings/preferences`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((d: any) => {
      preferences.disableLoading();
      this.connected.preferences = new SettingsPreferencesModel(preferences.defaultSensibleContent,
        preferences.fontFamily, preferences.fontSize, preferences.fontSpaced, preferences.annotations,
        preferences.feedTab);
      this.addAlert(new AlertModel(
        'saveSettingsPreferences',
        'Sauvegardé !',
        '',
        'success',
        5
      ));
    }, (err: any) => {
      preferences.disableLoading();
      this.addAlert(new AlertModel(
        'errorSettingsPreferences',
        'Une erreur s\'est produite, veuillez réessayer.',
        '',
        'error',
        5
      ));
    });
  }

  @action loadSettingsEmails() {
    this.mainSettings.enableLoading();
    return this.apiClient.get(`/settings/emails`).then((p: any) => {
      this.mainSettings.setEmails(p);
    });
  }

  @action saveSettingsEmails(noAlert?: boolean) {
    const emails = this.mainSettings.emails;
    emails.enableLoading();
    const data = {
      communicationsEmail: emails.communicationsEmail,
      newsletterEmail: emails.newsletterEmail,
      tipsEmail: emails.tipsEmail,
      boughtAParcoursNews: emails.boughtAParcoursNews,
      allInclusiveNews: emails.allInclusiveNews
    };
    return this.apiClient.post(`/settings/emails`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((d: any) => {
      emails.disableLoading();
      if (!noAlert) {
        this.addAlert(new AlertModel(
          'saveSettingsEmails',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      }
    }, (err: any) => {
      emails.disableLoading();
      if (!noAlert) {
        this.addAlert(new AlertModel(
          'errorSettingsEmails',
          'Une erreur s\'est produite, veuillez réessayer.',
          '',
          'error',
          5
        ));
      }
    });
  }

  @action loadSettingsNotifications() {
    this.mainSettings.enableLoading();
    return this.apiClient.get(`/settings/notifications`).then((p: any) => {
      this.mainSettings.setNotifications(p);
    });
  }

  @action saveSettingsNotifications(noAlert?: boolean) {
    const notifications = this.mainSettings.notifications;
    notifications.enableLoading();
    const data = {
      email: notifications.email,
      desktop: notifications.desktop
    };
    return this.apiClient.post(`/settings/notifications`, {
      data: data,
      headers: { 'Content-Type': 'application/json' }
    }).then((d: any) => {
      notifications.disableLoading();
      if (!noAlert) {
        this.addAlert(new AlertModel(
          'saveSettingsNotifications',
          'Sauvegardé !',
          '',
          'success',
          5
        ));
      }
    }, (err: any) => {
      notifications.disableLoading();
      if (!noAlert) {
        this.addAlert(new AlertModel(
          'errorSettingsNotifications',
          'Une erreur s\'est produite, veuillez réessayer.',
          '',
          'error',
          5
        ));
      }
    });
  }

  @action enablePremium() {
    return this.apiClient.post(`/settings/premium/start`);
  }

  @action buyParcours(product: ProductModel) {
    this.paymentTunnel.setProduct(product);
  }

  @action setRegistering(product: ProductModel) {
    this.paymentTunnel.setRegistering();
    this.paymentTunnel.setProduct(product);
  }

  @action setRegisteringAny() {
    this.paymentTunnel.setRegistering();
  }

  @action buyWhole() {
    return this.apiClient.post(`/settings/premium/all`).then((a: any) => {
      this.loadConnectedOnly();
    });
  }

  // reading lists
  @action loadReadingLists() {
    this.mainReadingList.enableLoading();
    return this.apiClient.get(`/lists/full`).then((rl: any) => {
      this.mainReadingList.setReadingLists(rl);
      this.mainReadingList.disableLoading();
    });
  }

  @action loadMyReadingListsForText(textId: number) {
    this.mainReadingList.enableLoading();
    return this.apiClient.get(`/lists/text/${textId}`).then((rl: any) => {
      this.mainReadingList.setReadingLists(rl);
      this.mainReadingList.disableLoading();
    });
  }

  @action createReadingList(name: string, pub: boolean, ordered: boolean) {
    const data = {
      name: name,
      pub: pub,
      ordered: ordered
    };
    return this.apiClient.post(
      `/lists/new `, { data: data }
    );
  }

  @action getReadingList(readingListId: number) {
    this.mainReadingList.enableLoading();
    return this.apiClient.get(`/lists/list/${readingListId}`).then((rl: any) => {
      this.mainReadingList.setCurrentList(rl);
      this.mainReadingList.disableLoading();
    });
  }

  @action editReadingList(readingListId: number, name: string,
    pub: boolean, ordered: boolean) {
    const data = {
      name: name,
      pub: pub,
      ordered: ordered
    };
    return this.apiClient.post(
      `/lists/list/${readingListId}/edit`, { data: data }
    );
  }

  @action removeReadingList(readingListId: number) {
    return this.apiClient.post(
      `/lists/list/${readingListId}/remove`
    );
  }

  @action loadReadingListTexts(readingListId: number) {
    this.mainReadingList.enableLoading();
    return this.apiClient.get(`/lists/list/${readingListId}/texts`).then((t: any) => {
      this.mainReadingList.setTexts(t);
      this.mainReadingList.disableLoading();
    });
  }

  @action loadReadingListSimilar(readingListId: number) {
    return this.apiClient.get(`/lists/list/${readingListId}/similar`).then((t: any) => {
      this.mainReadingList.setSimilar(t);
    });
  }

  @action loadReadingListSameUser(readingListId: number) {
    return this.apiClient.get(`/lists/list/${readingListId}/sameuser`).then((t: any) => {
      this.mainReadingList.setSameUser(t);
    });
  }

  @action addTextToReadingList(readingListId: number, textId: number) {
    const data = {
      textId: textId
    };
    return this.apiClient.post(
      `/lists/list/${readingListId}/texts/add`, { data: data }
    );
  }

  @action removeTextFromReadingList(readingListId: number, textId: number) {
    const data = {
      textId: textId
    };
    return this.apiClient.post(
      `/lists/list/${readingListId}/texts/remove`, { data: data }
    );
  }

  @action upTextInReadingList(readingListId: number, textId: number) {
    const data = {
      textId: textId
    };
    return this.apiClient.post(
      `/lists/list/${readingListId}/texts/up`, { data: data }
    );
  }

  @action downTextInReadingList(readingListId: number, textId: number) {
    const data = {
      textId: textId
    };
    return this.apiClient.post(
      `/lists/list/${readingListId}/texts/down`, { data: data }
    );
  }

  @action saveReadingListsForText(textId: number, readingListIds: number[]) {
    const data = {
      lists: readingListIds
    };
    return this.apiClient.post(
      `/lists/text/${textId}`, { data: data }
    );
  }

  @action sendConfirmChangeEmail() {
    return this.apiClient.post('/settings/account/email').then((d: any) => {
      this.addAlert(new AlertModel(
        'sendConfirmEmail',
        'Email envoyé !',
        '',
        'success',
        5
      ));
    });
  }

  @action cancelChangeEmail() {
    return this.apiClient.post('/settings/account/cancelchangeemail').then((d: any) => {
      this.addAlert(new AlertModel(
        'cancelChangeEmail',
        'Modification annulée !',
        '',
        'success',
        5
      ));
    });
  }

  @action forgotPassword(email: string) {
    const data = {
      email: email
    };
    return this.apiClient.post('/user/forgotpassword', { data: data });
  }

  @action resetPassword(email: string, token: string, password: string) {
    const data = {
      email: email,
      token: token,
      password: password
    };
    return this.apiClient.post('/user/resetpassword', { data: data });
  }

  @action loadCurrentWritingProgram() {
    return this.apiClient.get('/write/program/current').then((result: MainWritingProgramModel) => {
      const objectives: {
        day: number;
        words: number;
        defis: number;
        texts: number;
        trainings: number;
        chapters: number;
        linkedTextId: number;
      }[] = [];
      if (result && result.wp && result.wp.startedAt) {
        const wp = result.wp;
        if (wp.week) {
          wp.week.objectives.forEach((obj) => {
            objectives.push({
              day: undefined,
              words: obj.objWords,
              defis: obj.objDefis,
              texts: obj.objTexts,
              trainings: obj.objTrainings,
              chapters: obj.objChapters,
              linkedTextId: obj.linkedText ? obj.linkedText.id : undefined
            });
          });
        }
        if (wp.days) {
          wp.days.forEach((wpDay) => {
            wpDay.objectives.forEach((obj) => {
              objectives.push({
                day: wpDay.day,
                words: obj.objWords,
                defis: obj.objDefis,
                texts: obj.objTexts,
                trainings: obj.objTrainings,
                chapters: obj.objChapters,
                linkedTextId: obj.linkedText ? obj.linkedText.id : undefined
              });
            });
          });
        }
      }
      this.mainWritingProgram = new MainWritingProgramModel(result.wp, objectives, undefined);
    });
  }

  @action cancelWritingProgram() {
    return this.apiClient.post('/write/program/current/cancel').then((d: any) => {
      this.mainWritingProgram.wp = new WritingProgramModel(undefined, undefined, undefined,
        undefined, undefined, undefined, undefined);
    });
  }

  @action initWritingProgram(words: number, withTrainings: boolean,
    reg: boolean, novel: boolean, inspi: boolean, conf: boolean) {
    const data = {
      words: words,
      withTrainings: withTrainings,
      reg: reg,
      novel: novel,
      inspi: inspi,
      conf: conf
    };
    return this.apiClient.post('/write/program/init', { data: data });
  }

  @action createNewWritingProgram(objectives: {
    day?: number;
    words?: number;
    defis?: number;
    texts?: number;
    trainings?: number;
    chapters?: number;
  }[]) {
    const data = {
      objectives: objectives
    };
    return this.apiClient.post('/write/program/new', { data: data });
  }

  @action createWritingProgramHelp(form: {
    label: string,
    answers: {
      label: string,
      freeText: string
    }[],
    freeText: string
  }[]) {
    const data = {
      form: form
    };
    return this.apiClient.post('/write/program/help', { data: data });
  }

  @action loadProgramAllTexts() {
    return this.apiClient.get('/write/program/alltexts').then((texts: any[]) => {
      this.mainWritingProgram.allTexts = texts;
    });
  }

  sortAllParcours(allParcours: ParcoursModel[], connected: ConnectedModel) {
    let marketIdentifiers = connected.premium.marketIdentifier ? connected.premium.marketIdentifier : 0
    let sortedParcoursList: ParcoursModel[] = [];
    allParcours.map((p) => {
      if ((p.marketIdentifier & marketIdentifiers) != 0)
        sortedParcoursList.unshift(p);
      else
        sortedParcoursList.push(p);
    })
    return sortedParcoursList;
  }

  sortAllProduct(allProduct: ProductModel[], connected: ConnectedModel) {
    let marketIdentifiers = connected.premium.marketIdentifier ? connected.premium.marketIdentifier : 0
    let sortedProductList: ProductModel[] = [];
    allProduct.map((p) => {
      if ((p.marketIdentifier & marketIdentifiers) != 0)
        sortedProductList.unshift(p);
      else
        sortedProductList.push(p);
    })
    return sortedProductList;
  }

  @action loadAllParcours() {
    return this.apiClient.get('/write/parcours').then((p: any) => {
      this.allParcours = this.sortAllParcours(p, this.connected);
      this.mainParcours.setAllParcours(p, this.connected);
    });
  }

  @action loadProduct(productId: number) {
    return this.apiClient.get(`/write/product/${productId}`).then((p: any) => {
      this.currentProduct ? this.previouslyVisitedProduct = this.currentProduct : "";
      this.currentProduct = p;
    });
  }

  @action loadPaymentTunnelProduct(productId: number) {
    return this.apiClient.get(`/write/product/${productId}`).then((p: any) => {
      this.paymentTunnel.setProduct(p);
      this.paymentTunnel.setRegistering();
    });
  }

  @action loadCard(productId: number) {
    return this.apiClient.get(`/write/card/${productId}`).then((c: any) => {
      this.currentCard = c;
    });
  }

  @action loadAllParcoursDisconnected() {
    return this.apiClient.get('/write/allParcours').then((p: any) => {
      this.allParcours = p;
      this.mainParcours.setAllParcours(p);
    });
  }
  @action loadAllCardsDisconnected() {
    return this.apiClient.get('/write/allCards').then((c: any) => {
      this.allCards = c;
    });
  }
  @action loadAllProductDisconnected() {
    return this.apiClient.get('/write/allProduct').then((p: any) => {
      this.allProduct = p;
    });
  }

  // Canvas
  @action loadAllCanvas() {
    return this.apiClient.get('/canvas').then((c: any) => {
      this.mainCanvas.setAllCanvas(c);
    });
  }

  @action loadCanvas(canvasId: number) {
    return this.apiClient.get(`/canvas/${canvasId}`).then((c: any) => {
      this.mainCanvas.setCanvas(c);
    });
  }

  @action loadCanvasStructure(canvasId: number) {
    return this.apiClient.get(`/canvas/${canvasId}/structure`).then((s: any) => {
      this.mainCanvas.canvas.setStructure(s);
    });
  }

  @action loadCanvasCharacters(canvasId: number) {
    return this.apiClient.get(`/canvas/${canvasId}/characters`).then((characters: any) => {
      this.mainCanvas.canvas.setCharacters(characters);
    });
  }

  @action loadCanvasCharacter(canvasId: number, characterId: number) {
    return this.apiClient.get(
      `/canvas/${canvasId}/characters/${characterId}`).then((character: any) => {
        this.mainCanvas.canvas.setCurrentCharacter(character);
      });
  }

  // Editors
  @action loadAllEditors() {
    return this.apiClient.get('/editors').then((editors: any) => {
      this.mainEditor.setAllEditors(editors);
    });
  }

  @action loadEditorQuestions(editorId: number) {
    return this.apiClient.get(`/editors/editor/${editorId}/questions`).then((questions: any) => {
      this.mainEditor.form.setQuestions(questions);
      this.mainEditor.form.questionId = undefined;
    });
  }

  @action editorContact(editorId: number) {
    const form = this.mainEditor.form;
    const name = form.name;
    const email = form.email;
    const questions: { question: string, answer: string }[] = form.questions.map((question) => {
      return {
        question: question.question,
        answer: question.answers.length > 0 ?
          question.answers.filter(a => a.selected === true)[0].answer
          : (question.textAnswer ? question.textAnswer : '')
      };
    });

    const data = {
      email: email,
      name: name,
      questions: questions
    };
    return this.apiClient.post(`/editors/editor/${editorId}`, { data: data });
  }

  @action searchUsersBy(userInfo: string, searchType: string, page: number, searchPremium: boolean) {
    const data = {
      userInfo: userInfo,
      page: page,
      searchPremium: searchPremium
    };
    if (searchType === 'id')
      return this.apiClient.post('/settings/admin/search_by_id', { data: data }).then((u: any) => {
        if (page === 0)
          this.searchedUsersByInfo = u;
        else
          u.map((singleUser: any) => this.searchedUsersByInfo.push(singleUser))
      });
    else if (searchType === 'ip')
      return this.apiClient.post('/settings/admin/search_by_ip', { data: data }).then((u: any) => {
        if (page === 0)
          this.searchedUsersByInfo = u;
        else
          u.map((singleUser: any) => this.searchedUsersByInfo.push(singleUser))
      });
    else
      return this.apiClient.post('/settings/admin/search_by_mail', { data: data }).then((u: any) => {
        if (page === 0)
          this.searchedUsersByInfo = u;
        else
        u.map((singleUser: any) => this.searchedUsersByInfo.push(singleUser))
      });
  }
   @action updateSearchUsersByResult(id: number, premium: PremiumModel[]) {
    this.searchedUsersByInfo.map((user) => {
      if (user.id == id){
        user.premium = premium;
      }
    })
  } 

  @action sendMPAuto(users: string, message: string) {
    const data = {
      users: users,
      message: message
    };
    return this.apiClient.post('/settings/admin/mp', { data: data });
  }

  @action sendNotifs(message: string, link: string) {
    const data = {
      message: message,
      link: link
    };
    return this.apiClient.post('/settings/admin/notif', { data: data });
  }

  @action getCurrentPromotion() {
    return this.apiClient.get('/home/promotion').then((promotion: any) => {
      this.mainSettings.admin.currentPromotion = promotion;
    });
  }

  @action getAllPromotions() {
    return this.apiClient.get('/home/promotion/all').then((allPromotions: any) => {
      this.mainSettings.admin.allPromotions = allPromotions;
    });
  }

  @action createPromotion(content: string, until: string) {
    const data = {
      content: content,
      until: until
    };
    return this.apiClient.post('/home/promotion', { data: data });
  }

  @action deletePromotion(promotionId: number) {
    return this.apiClient.post(`/home/promotion/${promotionId}`);
  }

}
