import { observable } from 'mobx';
import SettingsAccountModel from './SettingsAccountModel';
import SettingsPreferencesModel from './SettingsPreferencesModel';
import SettingsEmailsModel from './SettingsEmailsModel';
import SettingsNotificationsModel from './SettingsNotificationsModel';
import SettingsAdminModel from './SettingsAdminModel';

export default class MainSettingsModel {

  @observable account: SettingsAccountModel;
  @observable preferences: SettingsPreferencesModel;
  @observable emails: SettingsEmailsModel;
  @observable notifications: SettingsNotificationsModel;
  @observable admin: SettingsAdminModel;

  @observable loading: boolean;

  constructor(account: SettingsAccountModel, preferences: SettingsPreferencesModel,
    emails: SettingsEmailsModel, notifications: SettingsNotificationsModel,
    admin: SettingsAdminModel) {
    this.account = account;
    this.preferences = preferences;
    this.emails = emails;
    this.notifications = notifications;
    this.admin = admin || new SettingsAdminModel(undefined, undefined);
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  setAccount(account: SettingsAccountModel) {
    this.account = new SettingsAccountModel(account.email, account.facebookUser, account.identifiant, '', '', '');
    this.disableLoading();
  }

  setPreferences(preferences: SettingsPreferencesModel) {
    this.preferences = new SettingsPreferencesModel(
      preferences.defaultSensibleContent, preferences.fontFamily, preferences.fontSize,
      preferences.fontSpaced, preferences.annotations, preferences.feedTab);
    this.disableLoading();
  }

  setEmails(emails: SettingsEmailsModel) {
    this.emails = new SettingsEmailsModel(emails.communicationsEmail, emails.newsletterEmail, emails.tipsEmail, 
    emails.boughtAParcoursNews, emails.allInclusiveNews);
    this.disableLoading();
  }

  setNotifications(notifications: SettingsNotificationsModel) {
    this.notifications = new SettingsNotificationsModel(notifications.email, notifications.desktop);
    this.disableLoading();
  }

  toJS() {
    return {
      account: this.account.toJS(),
      preferences: this.preferences.toJS(),
      emails: this.emails.toJS(),
      notifications: this.notifications.toJS(),
      admin: this.admin.toJS(),
      loading: this.loading,
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new MainSettingsModel(
        SettingsAccountModel.fromJS(object.account), SettingsPreferencesModel.fromJS(object.preferences),
        SettingsEmailsModel.fromJS(object.emails), SettingsNotificationsModel.fromJS(object.notifications),
        SettingsAdminModel.fromJS(object.admin)
      );
    }
    return undefined;
  }
}
