import { observable } from 'mobx';
import LightPartModel from './LightPartModel';
import LightChapterModel from './LightChapterModel';

export default class LightStructureModel {

  @observable parts: LightPartModel[];
  @observable chapters: LightChapterModel[];

  constructor(parts: LightPartModel[], chapters: LightChapterModel[]) {
    this.parts = parts || [];
    this.chapters = chapters || [];
  }

  toJS() {
    return {
      id: this.parts.map(p => p.toJS()),
      chapters: this.chapters.map(c => c.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const parts = object.parts.map((p: any) => LightPartModel.fromJS(p));
      const chapters = object.chapters.map((c: any) => LightChapterModel.fromJS(c));
      return new LightStructureModel(parts, chapters);
    }
    return undefined;
  }
}
