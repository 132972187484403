const verbesTernes = [
  'a été',
  'ai été',
  'aie été',
  'aient été',
  'aies été',
  'ait été',
  'as été',
  'aura été',
  'aurai été',
  'auraient été',
  'aurais été',
  'aurait été',
  'auras été',
  'aurez été',
  'auriez été',
  'aurions été',
  'aurons été',
  'auront été',
  'avaient été',
  'avais été',
  'avait été',
  'avez été',
  'aviez été',
  'avions été',
  'avons été',
  'ayez été',
  'ayons été',
  'es',
  'est',
  'eurent été',
  'eus été',
  'eusse été',
  'eussent été',
  'eusses été',
  'eussiez été',
  'eussions été',
  'eut été',
  'eûmes été',
  'eût été',
  'eûtes été',
  'furent',
  'fus',
  'fusse',
  'fussent',
  'fusses',
  'fussiez',
  'fussions',
  'fut',
  'fûmes',
  'fût',
  'fûtes',
  'ont été',
  'sera',
  'serai',
  'seraient',
  'serais',
  'serait',
  'seras',
  'serez',
  'seriez',
  'serions',
  'serons',
  'seront',
  'soient',
  'sois',
  'soit',
  'sommes',
  'sont',
  'soyez',
  'soyons',
  'suis',
  'étaient',
  'étais',
  'était',
  'étiez',
  'étions',
  'êtes',

  'a',
  'a eu',
  'ai',
  'ai eu',
  'aie',
  'aie eu',
  'aient',
  'aient eu',
  'aies',
  'aies eu',
  'ait',
  'ait eu',
  'as',
  'as eu',
  'aura',
  'aura eu',
  'aurai',
  'aurai eu',
  'auraient',
  'auraient eu',
  'aurais',
  'aurais eu',
  'aurait',
  'aurait eu',
  'auras',
  'auras eu',
  'aurez',
  'aurez eu',
  'auriez',
  'auriez eu',
  'aurions',
  'aurions eu',
  'aurons',
  'aurons eu',
  'auront',
  'auront eu',
  'avaient',
  'avaient eu',
  'avais',
  'avais eu',
  'avait',
  'avait eu',
  'avez',
  'avez eu',
  'aviez',
  'aviez eu',
  'avions',
  'avions eu',
  'avons',
  'avons eu',
  'ayez',
  'ayez eu',
  'ayons',
  'ayons eu',
  'eurent',
  'eurent eu',
  'eus',
  'eus eu',
  'eusse',
  'eusse eu',
  'eussent',
  'eussent eu',
  'eusses',
  'eussiez',
  'eussiez eu',
  'eussions',
  'eussions eu',
  'eut',
  'eut eu',
  'eûmes',
  'eûmes eu',
  'eût',
  'eût eu',
  'eûtes',
  'eûtes eu',
  'ont',
  'ont eu',
  'usses eu',

  'a fait',
  'ai fait',
  'aie fait',
  'aient fait',
  'aies fait',
  'ait fait',
  'as fait',
  'aura fait',
  'aurai fait',
  'auraient fait',
  'aurais fait',
  'aurait fait',
  'auras fait',
  'aurez fait',
  'auriez fait',
  'aurions fait',
  'aurons fait',
  'auront fait',
  'avaient fait',
  'avais fait',
  'avait fait',
  'avez fait',
  'aviez fait',
  'avions fait',
  'avoir fait',
  'avons fait',
  'ayez fait',
  'ayons fait',
  'eurent fait',
  'eus fait',
  'eusse fait',
  'eussent fait',
  'eusses fait',
  'eussiez fait',
  'eussions fait',
  'eut fait',
  'eûmes fait',
  'eût fait',
  'eûtes fait',
  'faire',
  'fais',
  'faisaient',
  'faisais',
  'faisait',
  'faisiez',
  'faisions',
  'faisons',
  'fait',
  'faites',
  'fasse',
  'fassent',
  'fasses',
  'fassiez',
  'fassions',
  'fera',
  'ferai',
  'feraient',
  'ferais',
  'ferait',
  'feras',
  'ferez',
  'feriez',
  'ferions',
  'ferons',
  'feront',
  'firent',
  'fis',
  'fisse',
  'fissent',
  'fisses',
  'fissiez',
  'fissions',
  'fit',
  'font',
  'fîmes',
  'fît',
  'fîtes',
  'ont fait',

  'a dit',
  'ai dit',
  'aie dit',
  'aient dit',
  'aies dit',
  'ait dit',
  'as dit',
  'aura dit',
  'aurai dit',
  'auraient dit',
  'aurais dit',
  'aurait dit',
  'auras dit',
  'aurez dit',
  'auriez dit',
  'aurions dit',
  'aurons dit',
  'auront dit',
  'avaient dit',
  'avais dit',
  'avait dit',
  'avez dit',
  'aviez dit',
  'avions dit',
  'avoir dit',
  'avons dit',
  'ayant dit',
  'ayez dit',
  'ayons dit',
  'dira',
  'dirai',
  'diraient',
  'dirais',
  'dirait',
  'diras',
  'dire',
  'dirent',
  'direz',
  'diriez',
  'dirions',
  'dirons',
  'diront',
  'dis',
  'disaient',
  'disais',
  'disait',
  'disant',
  'dise',
  'disent',
  'dises',
  'disiez',
  'disions',
  'disons',
  'disse',
  'dissent',
  'disses',
  'dissiez',
  'dissions',
  'dit',
  'dites',
  'dîmes',
  'dît',
  'dîtes',
  'en ayant dit',
  'en disant',
  'eurent dit',
  'eus dit',
  'eusse dit',
  'eussent dit',
  'eusses dit',
  'eussiez dit',
  'eussions dit',
  'eut dit',
  'eûmes dit',
  'eût dit',
  'eûtes dit',
  'ont dit',

  'm\'étais trouvé',
  'me fus trouvé',
  'me fusse trouvé',
  'me serai trouvé',
  'me serais trouvé',
  'me sois trouvé',
  'me suis trouvé',
  'me trouvai',
  'me trouvais',
  'me trouvasse',
  'me trouve',
  'me trouverai',
  'me trouverais',
  'nous fussions trouvés',
  'nous fûmes trouvés',
  'nous trouvassions',
  'nous trouverions',
  'nous trouverons',
  'nous trouvions',
  'nous trouvons',
  'nous trouvâmes',
  'nous étions trouvés',
  's\'est trouvé',
  's\'étaient trouvés',
  's\'était trouvé',
  'se furent trouvés',
  'se fussent trouvés',
  'se fut trouvé',
  'se fût trouvé',
  'se sera trouvé',
  'se seraient trouvés',
  'se serait trouvé',
  'se seront trouvés',
  'se soient trouvés',
  'se soit trouvé',
  'se sont trouvés',
  'se trouva',
  'se trouvaient',
  'se trouvait',
  'se trouvassent',
  'se trouve',
  'se trouvent',
  'se trouvera',
  'se trouveraient',
  'se trouverait',
  'se trouveront',
  'se trouvât',
  'se trouvèrent',
  'serez trouvés',
  'seriez trouvés',
  'serions trouvés',
  'serons trouvés',
  'sommes trouvés',
  'soyez trouvés',
  'soyons trouvés',
  't\'es trouvé',
  't\'étais trouvé',
  'te fus trouvé',
  'te fusses trouvé',
  'te serais trouvé',
  'te seras trouvé',
  'te sois trouvé',
  'te trouvais',
  'te trouvas',
  'te trouvasses',
  'te trouverais',
  'te trouveras',
  'te trouves',
  'vous fussiez trouvés',
  'vous fûtes trouvés',
  'vous trouvassiez',
  'vous trouverez',
  'vous trouveriez',
  'vous trouvez',
  'vous trouviez',
  'vous trouvâtes',
  'vous étiez trouvés',
  'vous êtes trouvés',
];

export default verbesTernes;
