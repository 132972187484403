import * as React from 'react';
import { inject, observer } from 'mobx-react';
import TextEditor from './TextEditor';
import ChapterTitle from './ChapterTitle';
import RelectureMenu from './RelectureMenu';
import Loader from '../../../../misc/Loader';
import Store from '../../../../../stores/Store';

interface FullEditorProps {
  store?: Store;
}

@inject('store') @observer
export default class FullEditor extends React.Component<FullEditorProps, {}> {
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;

    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text container chapter-content">
          {!chapter.relectureMode ?
            <ChapterTitle />
            : undefined}
          {chapter.relectureLoading ?
            <Loader message="Veuillez patienter..." />
            : <TextEditor />
          }
          {chapter.relectureMode ?
            <RelectureMenu />
            : undefined}
        </div>
      </div>
    );
  }
}
