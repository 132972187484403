import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Title from '../misc/Title';
import * as superagent from 'superagent';
const Iframe = require('react-iframe').default;
import Loader from '../misc/Loader';
import Store from '../../stores/Store';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { browserHistory } from 'react-router';
import MultipleAuthorBioModal from './MultipleAuthorBioModal';
import TrailerModal from './TrailerModal';

interface PaymentHubPageProps {
  store?: Store;
  location: {
    query: {
      p: number;
    }
  };
}

interface PaymentHubPageState {
  total: number;
  loading: boolean;
  stripeIdentifier: string;
  retractationRight: boolean;
  notifyMe: boolean;
  trailerModalActive: boolean;
  trailerContent: string; 
  trailerTitle: string;
  authorBioModalActive: boolean;
  authorBioContent: string; 
  authorBioTitle: string;
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class PaymentHubPage extends React.Component<PaymentHubPageProps, PaymentHubPageState> {
  static fetchData(store: Store) {
    return store.loadConnected();
  }
  constructor(props: PaymentHubPageProps) {
    super(props);
    this.state = {
        total: 0,
        stripeIdentifier: "",
        loading: true,
        retractationRight: true,
        notifyMe: false,
        trailerModalActive: false,
        trailerContent: undefined,
        trailerTitle: undefined,
        authorBioModalActive: false,
        authorBioContent: undefined,
        authorBioTitle: undefined
    };
    this.checkOrUncheckRetractation = this.checkOrUncheckRetractation.bind(this);
    this.checkOrUncheckNotifyMe = this.checkOrUncheckNotifyMe.bind(this);
    this.hideAuthorBioModal = this.hideAuthorBioModal.bind(this);
    this.activateAuthorBioModal = this.activateAuthorBioModal.bind(this);
    this.hideTrailerModal = this.hideTrailerModal.bind(this);
    this.activateTrailerModal = this.activateTrailerModal.bind(this);
  }
  componentDidMount() {
    if (this.props.store.paymentTunnel.product !== undefined) {
      this.props.store.loadProduct(this.props.store.paymentTunnel.product.id).then((r: any) => {
        this.props.store.loadCard(this.props.store.currentProduct.id).then((r: any) => {
          this.setState({loading: false})
        });});
    }
    else {
      this.props.store.loadProduct(this.props.location.query.p).then((r: any) => {
      this.props.store.loadCard(this.props.store.currentProduct.id).then((r: any) => {
        this.setState({loading: false})
      });});
    }
      
  }
  componentWillUnmount() {
    this.props.store.clearCurrentProduct();
  }
  activateAuthorBioModal(content: string, title: string) {
    this.setState({authorBioContent: content, authorBioTitle: title, authorBioModalActive: true})
  }
  activateTrailerModal(trailer: string, title: string) {
    this.setState({trailerContent: trailer, trailerTitle: title, trailerModalActive: true})
  }
  hideAuthorBioModal() {
    this.setState({authorBioContent: undefined, authorBioTitle: undefined, authorBioModalActive: false})
  }
  hideTrailerModal() {
    this.setState({trailerContent: undefined, trailerTitle: undefined, trailerModalActive: false})
  }
  checkOrUncheckRetractation () {
    this.setState({retractationRight: !this.state.retractationRight});
  }
  checkOrUncheckNotifyMe () {
    this.setState({notifyMe: !this.state.notifyMe});
  }  

  render() {
    const connected = this.props.store.connected;
    const allParcours = this.props.store.allParcours; 
    const currentProduct = this.props.store.currentProduct;
    const currentCard = this.props.store.currentCard
    return (
      <div>
        <Title title="Récapitulatif "/>
        <div className="ui text container" style={{ paddingTop: '50px', paddingBottom: '80px',  minHeight: '90vh' }}>
          <h1 className="ui header">Récapitulatif</h1>
          <MultipleAuthorBioModal 
                active={this.state.authorBioModalActive} hide={this.hideAuthorBioModal}
                content={this.state.authorBioContent} title={this.state.authorBioTitle}/>
          <TrailerModal 
                active={this.state.trailerModalActive} hide={this.hideTrailerModal}
                content={this.state.trailerContent} title={this.state.trailerTitle}/>
          {currentProduct && currentCard ? 
          <div className="ui stackable grid">
            <div className="nine wide column">
              
              


            <div className="ui card ada-parcours-card-recap">
            <div className="ui image">
                <img src={`${currentCard.image}`} />
            </div>
            {/* {currentCard.marketIdentifier ? */}
            <div className="content ada-card-border ada-parcours-name">
              <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: currentCard.name }} />
            </div>{/* :
            <div className="content ada-card-border ada-parcours-name-soon">
              <span style={{color: "white", textAlign: "center"}} className="header" dangerouslySetInnerHTML={{ __html: currentCard.name }} />
            </div>} */}
            <div className="content ada-card-border ada-card-else">
              {currentCard.authorsLink !== "" ? 
                <div style={{textAlign: "center", flex: 0, flexGrow: 1}}>
                  <b><p className="ada-learn-more" onClick={() => this.activateAuthorBioModal(currentCard.authorsDescription, currentCard.authorsLink)}>
                  <i className="fa fa-angle-right"/> <u>{currentCard.authorsLink}</u>
                  </p></b>
                </div> 
              : undefined}
              {currentCard.titleDescription !== "" ? <div style={{flex: 0, flexGrow: 1}} className="ada-parcours-title" dangerouslySetInnerHTML={{ __html: currentCard.titleDescription }} /> : undefined}
              {currentCard.titleDescription !== "" ? <div className="ui divider"></div> : undefined}
              <div style={{flex: 0, flexGrow: 1}} dangerouslySetInnerHTML={{ __html: currentCard.shortDescription }} />
              {currentCard.trailerUrl !== "" && currentCard.trailerUrl !== null ?  <b><p style={{textAlign: "center"}} className="ada-learn-more"  onClick={() => this.activateTrailerModal(currentCard.trailerUrl, "Trailer")}>
                <i className="fa fa-angle-right"/><u> BANDE-ANNONCE</u></p></b> 
              : undefined}
             {/*  {currentCard.objective !== "" ? <div className="ui divider"></div> : undefined}
              {currentCard.objective !== "" ? <div dangerouslySetInnerHTML={{ __html: currentCard.objective }} /> : undefined} */}
              
                <div className="ui divider"></div>
              {/* {currentCard.feather !== 0 ? <p>Taille du projet : {this.feather(currentCard.feather, currentCard.id)} </p>  : undefined}
              {currentCard.estimatedLength !== "" ? <div dangerouslySetInnerHTML={{ __html: currentCard.estimatedLength }} /> : undefined} */}
{/*               {currentCard.videosDescription !== "" ?<p><span style={{marginRight: "5px"}} className="fa fa-youtube-play"></span><span  dangerouslySetInnerHTML={{ __html: currentCard.videosDescription }} /></p> : undefined}
 */}
              {currentCard.marketIdentifier ? 
                currentCard.productPrice !== 0 ?
                <span>
                  <p>Accès sans limitation de durée à l’atelier. Sans abonnement ou renouvellement.</p>
                
                    </span>
                :
                  undefined
                  :
                  <span><p>Accédez à tous nos ateliers d'écriture pour une durée d'un an, sans engagement.</p></span>
                }
              
              </div>
          </div>
          <button className='ui button' style={{marginTop: "15px"}} onClick={() => browserHistory.push({pathname: '/'})}>Retour à l'accueil</button>
            </div>
            <div className="seven wide column">
              <div className="ui segment">
                <h3 style={{paddingBottom: "5px", borderBottom: "solid 1px"}} className="ui header">{currentProduct.name}</h3>
                <div className="ui relaxed list">
                  <div className="ui two column grid">
                  <div className="item column float left">
                    <div className="content">Prix de l'article</div>
                  </div>
                  <div className="item column float right">
                    <div style={{textAlign: "right"}} className="content">{Math.round(currentProduct.currentPrice/1.2)} €</div>
                  </div>
                  <div className="ui row" style={{marginTop: "-10px"}}>
                    <div className="item column float left">
                      <div className="content">TVA <span style={{fontSize: "0.8rem"}}>20%</span></div>
                    </div>
                    <div className="item column float right">
                      <div style={{textAlign: "right"}} className="content">{Math.round(currentProduct.currentPrice/1.2*0.2)} €</div>
                    </div>
                  </div>
                  <div className="ui row ada-background-editis-beige-foncé ada-text-editis-blanc">
                    <div className="item column float left">
                      <div className="content">Total TTC</div>
                    </div>
                    <div className="item column float right">
                      <div style={{fontSize: "1.4rem", textAlign: "right"}} className="content">{currentProduct.currentPrice} €</div>
                    </div>
                    
                    </div>

                  </div>
                </div>
              </div>
              <form action="/create-checkout-session" method="POST">
                <input type="hidden" name="userId" value={connected.user.id}></input>
                <div className="ui checkbox">
                  <input type="checkbox" name="notifyMe" onChange={() => this.checkOrUncheckNotifyMe()} checked={this.state.notifyMe}></input>
                  <label>J’accepte de recevoir par email des conseils d’écriture et des informations sur l’école.</label>
                </div>
                {/* <div className="ui checkbox">
                  <input type="checkbox" name="example" style={{marginBottom: "15px"}} onChange={() => this.checkOrUncheckRetractation()} checked={this.state.retractationRight}/>
                  <label>Je reconnais que mon droit de rétractation légal prend fin dans 14 jours ou dès que je commence à utiliser l’offre sélectionnée, selon ce qui se produit en premier.</label>
                </div> */}
                <input type="hidden" name="marketIdentifier" value={currentProduct.marketIdentifier}></input>
                <input type="hidden" name="stripeIdentifier" value={currentProduct.stripeIdentifier}></input>
                <input type="hidden" name="productId" value={currentProduct.id}></input>
{/*                 {this.state.retractationRight !== false ?
 */}                  <button className='ui button ada-background editis-bleu' style={{float: "right", marginTop: "15px", color: "white"}} type="submit">Procéder au paiement</button>
               {/*  :
                  <button className='ui button disabled' style={{float: "right", marginTop: "15px"}}>Procéder au paiement</button>
                } */}
              </form>
            </div>
          </div>
        : <Loader message="Chargement..." />}
        </div>
      }
      </div>
    );
  }
}
