import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface CommentRemoveProps {
  handleRemove: Function;
}

interface CommentRemoveState {
  showModal: boolean;
}

export default class CommentRemove extends React.Component<CommentRemoveProps, CommentRemoveState> {
  constructor(props: CommentRemoveProps) {
    super(props);
    this.state = { showModal: false };
    this.handleRemove = this.handleRemove.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  handleRemove() {
    this.props.handleRemove();
  }
  open() {
    this.setState({ showModal: true });
  }
  close() {
    this.setState({ showModal: false });
  }
  render() {
    return (
      <span>
        <a onClick={this.open} style={{ margin: '0em 0.75em 0em 0em' }}>Supprimer</a>
        <Modal onClose={this.close} open={this.state.showModal}>
          <div className="header">Confirmation</div>
          <div className="content">
            Confirmez-vous la suppression de ce commentaire ?
            </div>
          <div className="actions">
            <button className="ui blue approve button" onClick={this.handleRemove}>Oui</button>
            <button className="ui cancel button" onClick={this.close}>Non</button>
          </div>
        </Modal>
      </span>
    );
  }
}
