import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { Async } from 'react-select';
const throttle = require('throttle-debounce').throttle;
import Store from '../../stores/Store';

interface TextAccessibilityProps {
  store?: Store;
}

interface TextAccessibilityState {
  accessibilityEditMode: boolean;
  pub: boolean;
  users: { label: string, value: string }[];
  sensibleContent: boolean;
  openToComments: boolean;
  openToExport: boolean;
  openedToPublishers: boolean;
  showOldVersions: boolean;
}

require('react-select/dist/react-select.min.css');

@inject('store') @observer
export default class TextAccessibility extends React.Component<TextAccessibilityProps, TextAccessibilityState> {
  constructor(props: TextAccessibilityProps) {
    super(props);
    this.fetchUsers = throttle(1000, this.fetchUsers.bind(this))
    this.startAccessibilityEdit = this.startAccessibilityEdit.bind(this);
    this.cancelAccessibilityEdit = this.cancelAccessibilityEdit.bind(this);
    this.handleCheckboxPublic = this.handleCheckboxPublic.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleCheckboxSensibleContent = this.handleCheckboxSensibleContent.bind(this);
    this.handleCheckboxOpenToComments = this.handleCheckboxOpenToComments.bind(this);
    this.handleCheckboxOpenToExport = this.handleCheckboxOpenToExport.bind(this);
    this.handleCheckboxOpenedToPublishers = this.handleCheckboxOpenedToPublishers.bind(this);
    this.handleCheckboxShowOldVersions = this.handleCheckboxShowOldVersions.bind(this);
    this.saveAccessibility = this.saveAccessibility.bind(this);
    this.state = {
      accessibilityEditMode: false,
      pub: false,
      users: [],
      sensibleContent: false,
      openToComments: false,
      openToExport: false,
      openedToPublishers: false,
      showOldVersions: false,
    };
  }
  startAccessibilityEdit() {
    const meta = this.props.store.text.meta;
    this.setState({
      accessibilityEditMode: true,
      pub: meta.pub,
      users: (meta.users as any).toJS(),
      sensibleContent: meta.sensibleContent,
      openToComments: meta.openToComments,
      openToExport: meta.openToExport,
      openedToPublishers: meta.openedToPublishers,
      showOldVersions: meta.showOldVersions
    });
  }
  cancelAccessibilityEdit() {
    this.setState({
      accessibilityEditMode: false,
      pub: false,
      users: [],
      sensibleContent: false,
      openToComments: false,
      openToExport: false,
      openedToPublishers: false,
      showOldVersions: false
    });
  }
  fetchUsers(input: string, callback: Function) {
    this.props.store.getRelations(input).then((relations: any) => {
      const options = relations.map((r: any) => ({
        value: r.value,
        label: r.label,
      }));

      callback(null, { options });
    });
  }
  handleCheckboxPublic(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: !this.state.pub,
      users: this.state.users,
      sensibleContent: this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleUsersChange(value: { label: string, value: string }[]) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: value,
      sensibleContent: this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleCheckboxSensibleContent(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: this.state.users,
      sensibleContent: !this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleCheckboxOpenToComments(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: this.state.users,
      sensibleContent: this.state.sensibleContent,
      openToComments: !this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleCheckboxOpenToExport(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: this.state.users,
      sensibleContent: this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: !this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleCheckboxOpenedToPublishers(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: this.state.users,
      sensibleContent: this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: !this.state.openedToPublishers,
      showOldVersions: this.state.showOldVersions
    });
  }
  handleCheckboxShowOldVersions(e: any) {
    this.setState({
      accessibilityEditMode: true,
      pub: this.state.pub,
      users: this.state.users,
      sensibleContent: this.state.sensibleContent,
      openToComments: this.state.openToComments,
      openToExport: this.state.openToExport,
      openedToPublishers: this.state.openedToPublishers,
      showOldVersions: !this.state.showOldVersions
    });
  }
  saveAccessibility(e: any) {
    e.preventDefault();
    this.props.store.saveTextAccessibility(this.state.pub, this.state.users,
      this.state.sensibleContent, this.state.openToComments,
      this.state.openToExport, this.state.openedToPublishers, this.state.showOldVersions).then((d: any) => {
        this.setState({
          accessibilityEditMode: false,
          pub: false,
          users: [],
          sensibleContent: false,
          openToComments: false,
          openToExport: false,
          openedToPublishers: false,
          showOldVersions: false
        });
      });
  }
  render() {
    const defaultSensibleContent = this.props.store.connected.preferences
      && this.props.store.connected.preferences.defaultSensibleContent;
    const erotique = this.props.store.text.infos
      && this.props.store.text.infos.genres
      && this.props.store.text.infos.genres.indexOf(7) !== - 1;
    const meta = this.props.store.text.meta;
    const users = meta.users.map(u => {
      return <div key={Math.random() * 1000} className="ui label">{u.label}</div>;
    });
    return (
      <div>
        <div className="ui divider" />
        {this.state.accessibilityEditMode ?
          <div className="ui segment" style={{ paddingBottom: '40px', marginBottom: '20px' }}>
            <form
              className="ui form"
              onSubmit={this.saveAccessibility}
            >
              <div className="ui message warning">
                Les options suivantes vous permettent de mieux préciser encore
              l'accessibilité de votre œuvre.
                </div>
              <div className="field">
                <Checkbox
                  checked={this.state.pub}
                  onClick={this.handleCheckboxPublic}
                  label="Accessible à tous"
                />
              </div>
              {!this.state.pub ?
                <div className="field">
                  <label>Visible par</label>
                  <Async
                    loadOptions={this.fetchUsers}
                    multi={true}
                    value={this.state.users}
                    onChange={this.handleUsersChange}
                    placeholder="Moi uniquement"
                    noResultsText="Aucun utilisateur trouvé"
                    searchPromptText="Entrez un nom d'utilisateur"
                    loadingPlaceholder="Chargement..."
                    ignoreCase={true}
                    ignoreAccents={false}
                  />
                  <div className="ui message">
                    Vous pouvez sélectionner :
                     <ul className="ui list">
                      <li>Vos abonnés</li>
                      <li>Vos abonnements</li>
                    </ul>
                  </div>
                </div>
                : undefined}
              <div className={'ui field ' + (!defaultSensibleContent || erotique ? 'disabled' : '')}>
                <Checkbox
                  checked={this.state.sensibleContent}
                  onClick={this.handleCheckboxSensibleContent}
                  readOnly={!defaultSensibleContent || erotique}
                  label="Contenu sensible"
                />
              </div>
              {!defaultSensibleContent ?
                <div className="ui small message warning">
                  Pour activer l'option Contenu sensible, vous devez au préalable
                      cocher la case <b>Accéder aux œuvres avec un contenu sensible</b> dans
                      &nbsp;<Link to="/settings">Paramètres > Préférences</Link>.
                  </div>
                : undefined}
              {erotique ?
                <div className="ui small message warning">
                  La sélection du genre Érotique active automatiquement l'option "Contenu sensible".
                  </div>
                : undefined}
              <div className="ui field">
                <Checkbox
                  checked={this.state.openToComments}
                  onClick={this.handleCheckboxOpenToComments}
                  label="Autoriser les commentaires"
                />
              </div>
              <div className="ui field">
                <Checkbox
                  checked={this.state.openToExport}
                  onClick={this.handleCheckboxOpenToExport}
                  label="Autoriser le téléchargement PDF / EPUB (liseuse)"
                />
              </div>
              { this.props.store.connected.premium && (this.props.store.connected.premium.allInclusive || this.props.store.connected.premium.marketIdentifier != 0) ?
              <div className="ui field">
                <Checkbox
                  checked={this.state.openedToPublishers}
                  onClick={this.handleCheckboxOpenedToPublishers}
                  label="Offrir la possibilité à un éditeur de lire cette œuvre"
                />
              </div> : undefined}
              <div className="ui message warning">
                Sur l'Atelier des auteurs, rien ne se perd ! Lorsque vous modifiez votre texte, l'ancienne version
              est sauvegardée.Vous pouvez ainsi suivre plus facilement
              les évolutions de votre texte
              et mesurer le chemin parcouru depuis sa première version.
              <p>
                  Vous avez ici la possibilité de rendre ces anciennes versions publiques ou non.
              </p>
              </div>
              <div className="ui field">
                <Checkbox
                  checked={this.state.showOldVersions}
                  onClick={this.handleCheckboxShowOldVersions}
                  label=" Rendre publiques les anciennes versions des chapitres"
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelAccessibilityEdit}
                >
                  Annuler
              </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveAccessibility}
                  type="submit"
                >
                  Enregistrer
            </button>
              </div>
            </form>
          </div>
          :
          <div style={{ paddingBottom: '40px', color: meta.textColor }}>
            <div className="ui relaxed list">
              {meta.pub ?
                <div className="item">
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} /> Accessible à tous
                </div>
                :
                <div className="item">
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} /> Visible par
                  {users.length > 0 ?
                    <span style={{ marginLeft: '10px' }}>
                      {users}
                    </span>
                    : <div className="ui small label" style={{ marginLeft: '10px' }}>Moi uniquement</div>
                  }
                </div>
              }
              {meta.sensibleContent ?
                <div className="item">
                  <i className="fa fa-fw fa-fire" style={{ color: meta.linkColor }} /> Contenu sensible
                 </div>
                : undefined
              }
              {meta.openToComments ?
                <div className="item">
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} /> Commentaires autorisés
              </div>
                : <div className="item">
                  <i className="fa fa-fw fa-close" style={{ color: meta.linkColor }} /> Commentaires bloqués
              </div>
              }
              {meta.openToExport ?
                <div className="item">
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} /> Téléchargement autorisé
              </div>
                : <div className="item">
                  <i className="fa fa-fw fa-close" style={{ color: meta.linkColor }} /> Téléchargement bloqué
              </div>
              }
              { this.props.store.connected.premium && (this.props.store.connected.premium.allInclusive || this.props.store.connected.premium.marketIdentifier != 0) ?
              meta.openedToPublishers ?
                <div className="item">
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} /> Accessible aux éditeurs
              </div>
                : <div className="item">
                  <i className="fa fa-fw fa-close" style={{ color: meta.linkColor }} /> Non-accessible aux éditeurs
              </div>
              : undefined}
              {meta.showOldVersions ?
                <div>
                  <i className="fa fa-fw fa-check" style={{ color: meta.linkColor }} />&nbsp;
                  Anciennes versions des chapitres accessibles
              </div>
                : <div className="item">
                  <i className="fa fa-fw fa-close" style={{ color: meta.linkColor }} />&nbsp;
                  Anciennes versions des chapitres non accessibles
              </div>
              }
            </div>
            <button className="ui right floated orange small label" onClick={this.startAccessibilityEdit}>
              Modifier les paramètres d'accessibilité
            </button>
          </div>
        }
      </div>
    );
  }
}
