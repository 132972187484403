import NewsfeedModel from './NewsfeedModel';
import { observable } from 'mobx';
import CommentModel from '../comment/CommentModel';
import CommentListModel from '../comment/CommentListModel';

export default class FeedModel {

  @observable newsfeed: NewsfeedModel;
  @observable diary: CommentListModel;

  constructor(newsfeed: NewsfeedModel, diary: CommentListModel) {
    this.newsfeed = newsfeed;
    let d: CommentListModel;
    if (diary) {
      const comments = diary.comments.map(c => new CommentModel(
        c.id, c.userId, c.userIdentity,
        c.userUrl, c.userAvatar, c.userAdmin, c.title, c.text, c.chapterName, c.chapterTitle,
        c.chapterUrl, c.ago, c.childs, c.score, c.voted, c.answerMode, c.editMode,
        c.shownAnswers, c.textEdit, c.isNew
      ));
      d = new CommentListModel(comments, 'diary', diary.authorText,
        diary.authorDefi);
    }
    this.diary = d;
  }

  toJS() {
    return {
      newsfeed: this.newsfeed.toJS(),
      diary: this.diary.toJS()
    };
  }

  static fromJS(object: any) {
    return new FeedModel(
      NewsfeedModel.fromJS(object.newsfeed),
      CommentListModel.fromJS(object.diary)
    );
  }

}
