import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../../../stores/Store';
const cookie = new (require('react-cookie')).Cookies();

interface PreventAccessProps {
  store?: Store;
  id: string;
  active: boolean;
  goToLink: Function | any;
  hide: Function | any;
}

interface PreventAccessState {
  checked: boolean;
}

@inject('store') @observer
export default class PreventAccess extends React.Component<PreventAccessProps, PreventAccessState> {
  constructor(props: PreventAccessProps) {
    super(props);
    this.state = {
      checked: false
    };
    this.check = this.check.bind(this);
    this.goToLink = this.goToLink.bind(this);
  }
  check() {
    this.setState({ checked: !this.state.checked });
  }
  goToLink(e: any) {
    e.preventDefault();
    const text = this.props.store.write.currentText;
    if (this.state.checked) {
      cookie.set(`prevent_${this.props.id}_${text.id}`, '1', { maxAge: 2629746 });
    }
    this.props.goToLink();
  }
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
        size="small"
      >
        <div className="header">Accès déconseillé</div>
        <div className="content">
          L'accès à cette section est déconseillé durant l'écriture du premier jet d'un texte.
        <br />A ce niveau, il est préférable de se concentrer sur l'histoire plutôt que sur le style.
        <div style={{ marginTop: '15px' }}>
            <a href="#" style={{ fontSize: '1.1rem' }} onClick={this.goToLink}
            >
              Je souhaite quand même y accéder
      </a>
            <br />
            <Checkbox
              checked={this.state.checked}
              onClick={this.check}
              label="Ne plus afficher ce message"
              style={{ marginTop: '10px' }}
            />
          </div>
        </div>
        <div className="actions">
          <button className="ui button" onClick={this.props.hide}>
            Fermer
    </button>
        </div>
      </Modal>
    );
  }
}
