import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const Progress = require('semantic-ui-react/dist/commonjs/modules/Progress').default;
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import WritingProgramInit from './WritingProgramInit';
import WritingProgramAdvice from './WritingProgramAdvice';
import Store from '../../stores/Store';
import WritingProgramPeriodModel from '../../models/writingprogram/WritingProgramPeriodModel';
import AlertModel from '../../models/AlertModel';

interface WritingProgramProps {
  store?: Store;
  premium?: boolean;
}

interface WritingProgramState {
  modalCancelActive: boolean;
  showAll: boolean;
  premiumMode: boolean;
  initWritingProgram: boolean;
  advice: boolean;
}

interface WritingProgramObjectiveProps {
  period: WritingProgramPeriodModel;
  nolabel?: boolean;
}

class WritingProgramObjective
  extends React.Component<WritingProgramObjectiveProps, {}> {
  constructor(props: WritingProgramObjectiveProps) {
    super(props);
    this.progressBar = this.progressBar.bind(this);
  }
  calcRatio(value: number, objective: number) {
    if (value) {
      const ratio = Math.round(value / objective * 100);
      return ratio > 100 ? 100 : ratio;
    }
    return 0;
  }
  progressBar(ratio: number, label: string) {
    return (
      <Progress percent={ratio} progress className={(ratio === 100 ? 'success' : 'warning') + (!this.props.period.day ? ' large' : '')}>
        {label}
      </Progress>
    );
  }
  render() {
    const period = this.props.period;
    const objectives: JSX.Element[] = [];
    period.objectives.forEach((obj) => {
      if (obj.objWords) {
        const ratio = this.calcRatio(obj.words, obj.objWords);
        objectives.push(
          <div key={`words_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Écrire ${obj.objWords} mots (${(obj.words ? obj.words : 0)}/${obj.objWords})`)}
          </div >
        );
      }
      if (obj.objDefis) {
        const ratio = this.calcRatio(obj.defis, obj.objDefis);
        objectives.push(
          <div key={`defis_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Répondre à ${obj.objDefis} défi${obj.objDefis > 1 ? 's' : ''} (${(obj.defis ? obj.defis : 0)}/${obj.objDefis})`)}
          </div>
        );
      }
      if (obj.objTexts) {
        const ratio = this.calcRatio(obj.texts, obj.objTexts);
        objectives.push(
          <div key={`texts_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Publier ${obj.objTexts} œuvre${obj.objTexts > 1 ? 's' : ''} (${(obj.texts ? obj.texts : 0)}/${obj.objTexts})`)}
          </div>
        );
      }
      if (obj.objChapters) {
        const ratio = this.calcRatio(obj.chapters, obj.objChapters);
        objectives.push(
          <div key={`chapters_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Terminer ${obj.objChapters} chapitre${obj.objChapters > 1 ? 's' : ''} (${(obj.chapters ? obj.chapters : 0)}/${obj.objChapters}) dans l'œuvre "${obj.linkedText.title}"`)}
          </div>
        );
      }
      if (obj.objTrainings) {
        const ratio = this.calcRatio(obj.trainings, obj.objTrainings);
        objectives.push(
          <div key={`trainings_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Faire ${obj.objTrainings} entraînement${obj.objTrainings > 1 ? 's' : ''} (${(obj.trainings ? obj.trainings : 0)}/${obj.objTrainings})`)}
          </div>
        );
      }
    });

    let day: string;
    if (period.day) {
      switch (period.day) {
        case 1:
          day = 'Lundi';
          break;
        case 2:
          day = 'Mardi';
          break;
        case 3:
          day = 'Mercredi';
          break;
        case 4:
          day = 'Jeudi';
          break;
        case 5:
          day = 'Vendredi';
          break;
        case 6:
          day = 'Samedi';
          break;
        case 7:
          day = 'Dimanche';
          break;
      }
    }
    return (
      <div className={period.day ? 'item' : ''}>
        {day && !this.props.nolabel ?
          <div className="header">{day}</div>
          : undefined}
        <div className="ui middle relaxed aligned list">
          {objectives.length > 0 ?
            objectives
            : <div className="item">
              <div className="content">
                <i className="fa fa-square-o" style={{ marginRight: '10px' }} />
                Aucun objectif
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

@inject('store') @observer
export default class WritingProgram
  extends React.Component<WritingProgramProps, WritingProgramState> {
  constructor(props: WritingProgramProps) {
    super(props);
    this.showAll = this.showAll.bind(this);
    this.unshowAll = this.unshowAll.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.cancelProgram = this.cancelProgram.bind(this);
    this.validCancelProgram = this.validCancelProgram.bind(this);
    this.startNewProgram = this.startNewProgram.bind(this);
    this.saveInitWritingProgram = this.saveInitWritingProgram.bind(this);
    this.showAdvice = this.showAdvice.bind(this);
    this.hideAdvice = this.hideAdvice.bind(this);
    this.state = {
      modalCancelActive: false,
      showAll: false,
      premiumMode: this.props.premium,
      initWritingProgram: false,
      advice: false
    };
  }
  showAll() {
    this.setState({
      showAll: true
    });
  }
  unshowAll() {
    this.setState({
      showAll: false
    });
  }
  showDetail() {
    this.setState({
      showAll: true,
      premiumMode: false
    });
  }
  hideModal() {
    this.setState({
      modalCancelActive: false,
    });
  }
  cancelProgram() {
    this.setState({
      modalCancelActive: true
    });
  }
  validCancelProgram() {
    this.props.store.cancelWritingProgram().then((c: any) => {
      this.props.store.loadCurrentWritingProgram().then((w: any) => {
        this.hideModal();
        this.props.store.addAlert(new AlertModel(
          'cancelProgram',
          'Votre programme d\'écriture est arrêté.',
          '',
          'success',
          5
        ));
      });
    });
  }
  startNewProgram() {
    this.setState({
      initWritingProgram: true
    });
  }
  saveInitWritingProgram() {
    this.setState({
      initWritingProgram: false
    });
  }
  showAdvice() {
    this.setState({
      advice: true
    });
  }
  hideAdvice() {
    this.setState({
      advice: false
    });
  }
  render() {
    const connected = this.props.store.connected;
    const wp = this.props.store.mainWritingProgram;
    return (
      <div>
        {/* {connected && connected.premium && !connected.premium.init ?
          <div className="ui warning message">
            <div className="header">Félicitations, vous êtes maintenant membre de l'Académie !</div>
            <p>Terminez votre inscription en répondant à une série de courtes questions qui nous permettront de mettre en place votre environnement d'écriture !</p>
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={this.startNewProgram}
                className="ui orange button"
              >
                Terminer mon inscription
              </button>
            </div>
          </div>
          : undefined} */}
        <WritingProgramInit
          active={this.state.initWritingProgram}
          hide={this.saveInitWritingProgram}
        />
        {!this.props.premium ?
          <h2 className="ui horizontal divider">
            <i className="fa fa-fw fa-dashboard" style={{ marginRight: '5px' }} />
            Mon programme d'écriture
        </h2>
          : undefined}
        <div className="ui basic segment" style={{ paddingBottom: '50px' }}>
          <div>
            {wp && wp.wp && wp.wp.startedAt ?
              <Modal onClose={this.hideModal} open={this.state.modalCancelActive} size="small">
                <div className="content">
                  Confirmez-vous l'arrêt du programme en cours ?
              </div>
                <div className="actions">
                  <button className="ui primary submit button" onClick={this.validCancelProgram}>Oui</button>
                  <button className="ui cancel button" onClick={this.hideModal}>Annuler</button>
                </div>
              </Modal>
              : undefined}
            {wp && wp.wp && wp.wp.startedAt && wp.wp.reg !== undefined && wp.wp.reg !== null ?
              <WritingProgramAdvice active={this.state.advice} hide={this.hideAdvice} />
              : undefined}
          </div>
          {wp && wp.wp && wp.wp.startedAt ?
            <div>
              {wp.wp.week && !this.state.premiumMode ?
                <div>
                  <div className="ui header">
                    {this.state.showAll ? 'Objectif hebdomadaire' : 'Cette semaine'}
                  </div>
                  <WritingProgramObjective period={wp.wp.week} />
                </div>
                : undefined}
              {wp.wp.week && !this.state.premiumMode && wp.wp.week.objectives && wp.wp.week.objectives.length > 0
                && wp.wp.days.filter(r => r.day && r.objectives && r.objectives.length > 0).length > 0 ?
                <div className="ui hidden section divider" />
                : undefined}
              {wp.wp.days.filter(r => r.day && r.objectives && r.objectives.length > 0).length > 0 ?
                <div>
                  {!this.state.premiumMode ?
                    <div className="ui header">
                      {this.state.showAll ? 'Objectifs quotidiens' : 'Aujourd\'hui'}
                    </div>
                    : undefined}
                  {this.state.showAll ?
                    <div className="ui middle aligned list">
                      {wp.wp.days.filter(r => r.day === 1).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 1)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 2).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 2)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 3).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 3)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 4).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 4)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 5).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 5)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 6).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 6)[0]} />
                        : undefined}
                      {wp.wp.days.filter(r => r.day === 7).length > 0 ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.day === 7)[0]} />
                        : undefined}
                    </div>
                    :
                    <div className="ui middle aligned list">
                      {wp.wp.days.filter(r => r.today && r.objectives && r.objectives.length > 0)[0] ?
                        <WritingProgramObjective period={wp.wp.days.filter(r => r.today)[0]} nolabel />
                        : <div className="item">
                          <div className="content">
                            Aucun objectif aujourd'hui
                        </div>
                        </div>
                      }
                    </div>
                  }
                </div>
                : undefined}
              {/* {this.state.premiumMode ?
                <div style={{ marginTop: '10px' }}>
                  <button
                    type="button"
                    className="ui icon right floated small button"
                    onClick={this.showDetail}
                  >
                    <i className="fa fa-fw fa-bars" /> Voir le détail du programme
                </button>
                </div>
                : */}
                <div style={{ marginTop: '10px' }}>
                  <button
                    type="button"
                    className="ui icon right floated red small button"
                    onClick={this.cancelProgram}
                  >
                    <i className="fa fa-fw fa-times" /> Arrêter
                </button>
                  <Link
                    to="/program"
                    className="ui icon right floated orange small button"
                  >
                    <i className="fa fa-fw fa-edit" /> Modifier mes objectifs
                </Link>
                  {this.state.showAll ?
                    <button
                      type="button"
                      className="ui icon right floated small button"
                      onClick={this.unshowAll}
                    >
                      <i className="fa fa-fw fa-bars" /> Réduire
            </button>
                    :
                    <button
                      type="button"
                      className="ui icon right floated small button"
                      onClick={this.showAll}
                    >
                      <i className="fa fa-fw fa-bars" /> Ma semaine
                </button>
                  }
                </div>
              {/* } */}
              <div>
                {wp && wp.wp && wp.wp.reg !== undefined && wp.wp.reg !== null ?
                  <button
                    type="button"
                    className="ui icon right floated small button"
                    onClick={this.showAdvice}
                  >
                    <i className="fa fa-fw fa-hand-o-up" /> Conseils d'écriture
                </button>
                  : undefined}
              </div>
            </div>
            : <div>
{/*                 Vous n'avez défini aucun programme d'écriture.
 */}              
                <p>Ecrire avec régularité est la clef de la réussite !</p>
                <p>Vous pouvez définir un objectif d'écriture en mots par jour sur l'Atelier des Auteurs.</p>
                <p>Il vous suffit de cliquer sur "Etablissez votre objectif !".</p>
              <div>
                <button
                  onClick={this.startNewProgram}
                  className="ui right floated small button"
                >
                  Établissez votre objectif
            </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
