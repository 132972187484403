import { observable } from 'mobx';
import LightChapterModel from './LightChapterModel';
import ChapterVersionModel from './ChapterVersionModel';
import UserModel from '../UserModel';

export default class ChapterModel {
  @observable id: number;
  @observable title: string;
  @observable textContent: string;
  @observable words: number;
  @observable version: number;
  @observable versions: ChapterVersionModel[];
  @observable nextChapter: number;
  @observable favorites: number;
  @observable favorited: boolean;
  @observable read: boolean;
  @observable step: number;

  @observable showAnnotations: boolean;
  @observable loaded: boolean;

  @observable userFavorites: UserModel[];

  constructor(id: number, title: string, textContent: string, words: number,
    version: number, versions: ChapterVersionModel[], nextChapter: number,
    favorites: number, favorited: boolean, read: boolean, step: number, showAnnotations: boolean,
    userFavorites: UserModel[]) {
    this.id = id;
    this.title = title;
    this.textContent = textContent;
    this.words = words;
    this.version = version;
    this.versions = versions;
    this.nextChapter = nextChapter;
    this.favorites = favorites;
    this.favorited = favorited;
    this.read = read;
    this.step = step;

    this.showAnnotations = showAnnotations;
    this.loaded = false;

    this.userFavorites = userFavorites || [];
  }

  like() {
    if (!this.favorited) {
      this.favorited = true;
      this.favorites = this.favorites + 1;
    }
  }

  unlike() {
    if (this.favorited) {
      this.favorited = false;
      this.favorites = this.favorites - 1;
    }
  }

  markAsRead() {
    if (!this.read) {
      this.read = true;
    }
  }

  markAsUnread() {
    if (this.read) {
      this.read = false;
    }
  }

  makeLoaded() {
    this.loaded = true;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      textContent: this.textContent,
      words: this.words,
      version: this.version,
      versions: this.versions.map(v => v.toJS()),
      nextChapter: this.nextChapter,
      favorites: this.favorites,
      favorited: this.favorited,
      read: this.read,
      step: this.step,
      showAnnotations: this.showAnnotations,
      userFavorites: this.userFavorites.map(u => u.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const versions = object.versions.map((v: any) => ChapterVersionModel.fromJS(v));
      const userFavorites = object.userFavorites.map((u: any) => UserModel.fromJS(u));
      return new ChapterModel(object.id, object.title, object.textContent, object.words,
        object.version, versions, object.nextChapter, object.favorites,
        object.favorited, object.read, object.step, object.showAnnotations, userFavorites);
    }
    return undefined;
  }
}
