import * as React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';

interface WritingProgramAdviceProps {
  active: boolean;
  hide: Function | any;
  store?: Store;
}

@inject('store') @observer
export default class WritingProgramAdvice extends React.Component<WritingProgramAdviceProps, {}> {
  constructor(props: WritingProgramAdviceProps) {
    super(props);
  }
  render() {
    const wp = this.props.store.mainWritingProgram.wp;
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
          size="small"
        >
          <div className="header">Conseils</div>
          <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
            {wp.reg !== undefined && wp.reg !== null ?
              <div className="ui basic segment">
                <h3 className="ui header">La priorité : développer une habitude d'écriture.</h3>
                <p>
                  La motivation est très importante mais peut s'épuiser. L'enthousiasme également. Mais les routines restent ancrées en nous. Cette comparaison va probablement vous surprendre, mais lorsque vos séances d'écriture vous sembleront aussi naturelles que votre brossage de dents matinal, vous ne risquerez plus de procrastiner.
                </p>
                <p>
                  C'est pourquoi nous vous proposons un rythme d'écriture destiné à développer une routine d'écriture. 250 mots correspondent environ à une page. Cela peut sembler peu mais l'objectif est d'intégrer peu à peu une habitude liée à l'écriture. Vous pourrez écrire 500 ou 1000 mots certains jours, seulement 250 mots d'autres jours, mais le plus important est de ne jamais tomber en-dessous de 250 mots.
                </p>
                <p>
                  Au début, vous dépasserez sûrement vos objectifs. Puis vous pourriez (éventuellement) envisager de ne pas écrire les jours suivants. Malgré cela, nous vous conseillons d'écrire chaque jour sans réduire votre rythme. Le temps d'intégration d'une habitude varie d'un individu à l'autre mais je ne peux que vous conseiller de vous forcer à écrire avec régularité, même les jours où cela vous semblera difficile.
                </p>
                <p>
                  Peu à peu, vous vous dégagerez plus de temps pour écrire, si cette routine est bien intégrée. Vous pourrez alors intensifier le rythme. C'est pourquoi nous proposons cette méthode : elle permet de partir d'une activité minimale et de lui faire prendre de l'importance.
                </p>
              </div>
              : undefined
            }
            {wp.novel ?
              <div className="ui basic segment">
                <h3 className="ui header">Concentrez-vous sur un parcours</h3>
                <p>
                  Puisque vous n'avez jamais terminé de roman, la finalisation d’un parcours peut être la bonne occasion pour vous de vous concentrer enfin sur un roman que vous mènerez à bien.
                </p>
                <p>
                Le parcours vous permettra d'achever un roman tout en étant accompagné au fil d'une multitude de petites étapes. Chaque partie comprend de nombreuses indications afin d'encadrer votre progression et vous aider à avancer à votre rythme. Vous éviterez ainsi de vous disperser tout en acquérant des outils au fil de vos avancées. Cela peut répondre à différents besoins que vous affichez : le parcours est un compagnon de route, une formation et un tremplin.
                </p>
                <p>
                Chaque étape peut faire la taille que vous souhaitez. Le plus important est que vous avanciez avec régularité. Nous vous conseillons de ne pas penser à un public éventuel, à la future vente de votre histoire ou même aux jugements extérieurs. C'est votre histoire, votre roman. Cette œuvre vous est propre et vous devez vous concentrer dessus, un chapitre après l'autre.
                </p>
              </div>
              :
              <div className="ui basic segment">
                <h3 className="ui header">A propos des parcours d’écriture</h3>
                <p>
                Chaque parcours vous permettra d'achever un récit tout en étant accompagné au fil d'une multitude de petites étapes. Chacune de ces étapes comprend de nombreuses indications afin d'encadrer votre progression et vous aider à avancer à votre rythme. Vous éviterez ainsi de vous disperser tout en acquérant des outils au fil de vos avancées. Cela peut répondre à différents besoins que vous affichez : le parcours est un compagnon de route, une formation et un tremplin.
                  </p>
                <p>
                Chaque étape peut faire la taille que vous souhaitez. Le plus important est que vous avanciez avec régularité. Nous vous conseillons de ne pas penser à un public éventuel, à la future vente de votre histoire ou même aux jugements extérieurs. C'est votre histoire, votre roman. Cette œuvre vous est propre et vous devez vous concentrer dessus, un chapitre après l'autre.
              </p>
                <p>
                Si vous avez déjà une certaine expérience de l'écriture, vous trouverez peut-être trop simples certains procédés proposés. Ces parcours ont été conçus en priorité pour les débutants et les aspirants auteurs ayant toujours rêvé d'écrire sans jamais oser se lancer. Mais vous pouvez également vous prêter à l'exercice en entamant une démarche de réflexion sur vos propres pratiques. Adopter des méthodes différentes et être confronté à des choix narratifs précis peut être stimulant et offrir un nouveau regard sur ses techniques d'écriture.
              </p>
              </div>
            }
            {wp.inspi ?
              <div className="ui basic segment">
                <h3 className="ui header">Vous nous exposez des difficultés pour trouver l'inspiration.</h3>
                <p>
                  “On ne peut pas attendre que l’inspiration vienne. Il faut courir après avec une massue.” (Jack London)
                </p>
                <p>
                  Nous vous invitons à ne pas vous soucier d’inspiration et à écrire chaque jour sans vous soucier du rendu final... jusqu’à la fin de votre premier jet. Beaucoup d’apprentis écrivains peuvent complexer sur la qualité de leurs idées ou la façon dont ils les expriment. Mais l’imagination est un muscle et ça n’est qu’en la faisant travailler que vous réussirez à la développer.
                </p>
                <p>
                  N’attendez pas l’inspiration : prenez une bonne inspiration et continuez à écrire en suivant les consignes de votre parcours. Si cela ne vous semble pas bon : aucun problème ! Vous avez déjà fait le plus important pour aujourd’hui : écrire. 
                </p>
              </div>
              : undefined
            }
            {wp.conf ?
              <div className="ui basic segment">
                <h3 className="ui header">Une simple suggestion si vous manquez de confiance en vous</h3>
                <p>
                  Nous vous invitons à vous forcer à avancer lors de l'écriture de vos récits. Ne vous attardez pas sur vos fautes, les tournures de vos phrases ou même des détails. Avancez, tout simplement. La relecture se fera <u>toujours</u> dans un second temps.
                </p>
                <p>
                  Nous vous proposons cela car des auteurs affirmant manquer de confiance en eux ont parfois tendance à trop s'attarder sur leurs textes en cours de rédaction. Ils se jugent, se dévalorisent ou ont peur d'avancer. J'ignore si c'est votre cas. Mais si ça l'est, vous pouvez dépasser cela peu à peu.
                </p>
                <p>
                  N'ayez pas peur non plus de « raconter des bêtises » ou de mal raconter votre histoire. Ne craignez pas de faire des choix, aussi : vous devrez tout le temps en faire. Racontez une histoire, tout simplement.
                </p>
                <p>
                  En vous forçant à avancer, votre premier jet ne sera sûrement pas à la hauteur de l'œuvre dont vous rêviez. Mais cela sera un premier jet : vous devrez sûrement le relire, réécrire des parties, en ajouter d'autres... ou vous pourrez même débuter un nouveau récit une fois votre histoire terminée.
                </p>
              </div>
              : undefined
            }
          </div>
          <div className="actions">
            <button className="ui button" onClick={this.props.hide}>
              Fermer
          </button>
          </div>
        </Modal>
      </div>
    );
  }
}
