export default function posTag(that: { foundTokensInDicts: any[] }) {
  const taggedWords: any[] = [];
  let currentWord: any = null;
  that.foundTokensInDicts.forEach((wordObj: any, index: number) => {
    if (currentWord === null) {
      currentWord = {
        id: wordObj.id,
        word: wordObj.word,
        pos: [wordObj.pos]
      };
    } else {
      currentWord.pos.push(wordObj.pos);
    }
    if (that.foundTokensInDicts[index + 1] == null
      || !that.foundTokensInDicts[index + 1].id || !currentWord.id || (that.foundTokensInDicts[index + 1].id !== currentWord.id)) {
      taggedWords.push(currentWord);
      currentWord = null;
    }
  });
  return taggedWords;
}
