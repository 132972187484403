import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import Store from '../../stores/Store';
import ListFavorites from './ListFavorites';
import ReadingListsTextModal from '../readinglist/ReadingListsTextModal';

interface ChapterLikeProps {
  store?: Store;
  topbar?: boolean;
}

interface ChapterLikeState {
  readingListPopupActive: boolean;
  readingListModalActive: boolean;
  detailActive: boolean;
}

@inject('store') @observer
export default class ChapterLike extends React.Component<ChapterLikeProps, ChapterLikeState> {
  constructor(props: ChapterLikeProps) {
    super(props);
    this.likeChapter = this.likeChapter.bind(this);
    this.unlikeChapter = this.unlikeChapter.bind(this);
    this.handleOpenReadingListPopup = this.handleOpenReadingListPopup.bind(this);
    this.handleCloseReadingListPopup = this.handleCloseReadingListPopup.bind(this);
    this.showReadingListModal = this.showReadingListModal.bind(this);
    this.hideReadingListModal = this.hideReadingListModal.bind(this);
    this.showDetail = this.showDetail.bind(this);
    this.hideDetail = this.hideDetail.bind(this);

    this.state = {
      readingListPopupActive: false,
      readingListModalActive: false,
      detailActive: false
    };
  }
  likeChapter() {
    this.props.store.likeChapter();
  }
  unlikeChapter() {
    this.props.store.unlikeChapter();
  }
  handleOpenReadingListPopup() {
    this.setState({
      readingListPopupActive: !this.props.store.text.chapter.favorited
    });
  }
  handleCloseReadingListPopup() {
    this.setState({
      readingListPopupActive: false
    });
  }
  showReadingListModal() {
    const textId = this.props.store.text.meta.id;
    this.props.store.loadMyReadingListsForText(textId).then((d: any) => {
      this.setState({
        readingListPopupActive: false,
        readingListModalActive: true
      });
    });
  }
  hideReadingListModal() {
    this.setState({
      readingListModalActive: false
    });
  }
  showDetail() {
    this.props.store.loadChapterLikes().then((r: any) => {
      this.setState({ detailActive: true });
    });
  }
  hideDetail() {
    this.setState({ detailActive: false });
  }
  render() {
    const { connected, text } = this.props.store;
    const blockedBy = text && text.meta && connected && connected.user && connected.user.id
      && connected.blockedBy.indexOf(text.meta.author.id) !== -1;
    return (
      <div>
        {this.props.store.text && this.props.store.text.chapter ?
          <div>
            <ListFavorites
              title="Chapitre apprécié par"
              users={this.props.store.text.chapter.userFavorites}
              close={this.hideDetail}
              active={this.state.detailActive}
            />
            <ReadingListsTextModal
              active={this.state.readingListModalActive}
              hideModal={this.hideReadingListModal}
            />
            {this.props.topbar ?
              <Popup
                open={this.state.readingListPopupActive}
                trigger={
                  this.props.store.text.chapter.favorited ?
                    (
                      <div className="ui labeled button" tabIndex={0}>
                        {!blockedBy ?
                          <button className="ui red icon button" onClick={this.unlikeChapter}
                            data-tooltip="Je n'aime plus" data-inverted="" data-position="bottom center">
                            <i className="fa fa-fw fa-heartbeat" />
                          </button>
                          : null}
                        <button className="ui basic red left pointing label" onClick={this.showDetail}
                          data-tooltip="Voir le détail" data-inverted="" data-position="bottom center">
                          {this.props.store.text.chapter.favorites}
                        </button>
                      </div>
                    )
                    :
                    (
                      <div className="ui labeled button" tabIndex={0}>
                        {!blockedBy ?
                          <button className="ui basic red icon button" onClick={this.likeChapter}
                            data-tooltip="J'aime" data-inverted="" data-position="bottom center">
                            <i className="fa fa-fw fa-heart" />
                          </button>
                          : null}
                        {this.props.store.text.chapter.favorites > 0 ?
                          <button className="ui basic red left left pointing label" onClick={this.showDetail}
                            data-tooltip="Voir le détail" data-inverted="" data-position="bottom center">
                            {this.props.store.text.chapter.favorites}
                          </button>
                          : undefined}
                      </div>
                    )
                }
                content={
                  <button className="ui mini button" onClick={this.showReadingListModal}>
                    <i className="fa fa-list icon"></i> Ajouter à une liste de lecture
                      </button>
                }
                on="click"
                onOpen={this.handleOpenReadingListPopup}
              />
              :
              (!blockedBy ?
                <Popup
                  open={this.state.readingListPopupActive}
                  trigger={
                    (this.props.store.text.chapter.favorited ?
                      <button className="ui red button" onClick={this.unlikeChapter} style={{ width: '200px' }}>
                        <i className="fa fa-fw fa-heartbeat" /> Je n'aime plus
                  </button>
                      :
                      <button className="ui button" onClick={this.likeChapter} style={{ width: '200px' }}>
                        <i className="fa fa-fw fa-heart" /> J'aime
                      </button>
                    )
                  }
                  content={
                    <button className="ui mini button" onClick={this.showReadingListModal}>
                      <i className="fa fa-list icon"></i> Ajouter à une liste de lecture
                      </button>
                  }
                  onOpen={this.handleOpenReadingListPopup}
                  onClose={this.handleCloseReadingListPopup}
                  on="click"
                />
                : null)
            }
          </div>
          : undefined}
      </div>
    );
  }
}
