import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../stores/Store';
import NewTextStructureForm from './NewTextStructureForm';

interface NewTextStructureModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  title: string;
  linkedToProgram: boolean;
}

@inject('store') @observer
export default class NewTextStructureModal
  extends React.Component<NewTextStructureModalProps, {}> {
  constructor(props: NewTextStructureModalProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Nouvelle œuvre</div>
          <div className="content" style={{ paddingBottom: '50px' }}>
            <NewTextStructureForm
              afterSave={this.props.hide}
              title={this.props.title}
              linkedToProgram={this.props.linkedToProgram}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
