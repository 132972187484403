import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface CannotPublishProps {
  cannotPublishModal: boolean;
  toggleCannotPublish: Function;
}

export default class CannotPublish extends React.Component<CannotPublishProps, {}> {
  constructor(props: CannotPublishProps) {
    super(props);
    this.toggleCannotPublish = this.toggleCannotPublish.bind(this);
  }
  toggleCannotPublish() {
    this.props.toggleCannotPublish();
  }
  render() {
    return (
      <Modal
        open={this.props.cannotPublishModal}
        onClose={this.toggleCannotPublish}
        >
        <div className="header">Erreur</div>
        <div className="content">
          Vous devez saisir un titre et un corps de chapitre.
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.toggleCannotPublish}>Fermer</button>
        </div>
      </Modal>
    );
  }
}
