import { observable, computed } from 'mobx';

export default class SettingsPreferences {

  @observable defaultSensibleContent: boolean;
  @observable fontFamily: string;
  @observable fontSize: string;
  @observable fontSpaced: string;
  @observable annotations: boolean;
  @observable feedTab: number;
  @observable loading: boolean;

  constructor(defaultSensibleContent: boolean, fontFamily: string, fontSize: string,
    fontSpaced: string, annotations: boolean, feedTab: number) {
    this.defaultSensibleContent = defaultSensibleContent;
    this.fontFamily = fontFamily;
    this.fontSize = fontSize;
    this.fontSpaced = fontSpaced;
    this.annotations = annotations;
    this.feedTab = feedTab;
    this.loading = false;
  }

  enableLoading() {
    this.loading = true;
  }

  disableLoading() {
    this.loading = false;
  }

  @computed get computedFontFamily() {
    switch (this.fontFamily) {
      case 'OpenSans':
        return 'Open Sans,Helvetica,Arial,sans-serif';
      case 'LibreBaskerville':
        return 'Libre Baskerville,Helvetica,Arial,sans-serif';
      case 'DroidSerif':
        return 'Droid Serif,Helvetica,Arial,sans-serif';
      case 'DroidSans':
        return 'Droid Sans,Helvetica,Arial,sans-serif';
      default:
        return 'Open Sans,Helvetica,Arial,sans-serif';
    }
  }

  @computed get computedFontSize() {
    switch (this.fontSize) {
      case 'small':
        return '14px';
      case 'medium':
        return '18px';
      case 'big':
        return '23px';
      default:
        return '18px';
    }
  }

  @computed get computedFontSpaced() {
    switch (this.fontSpaced) {
      case 'small':
        return '1';
      case 'medium':
        return '1.5';
      case 'big':
        return '2';
      default:
        return '1.5';
    }
  }

  toJS() {
    return {
      defaultSensibleContent: this.defaultSensibleContent,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
      fontSpaced: this.fontSpaced,
      annotations: this.annotations,
      feedTab: this.feedTab
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new SettingsPreferences(
        object.defaultSensibleContent, object.fontFamily, object.fontSize,
        object.fontSpaced, object.annotations, object.feedTab
      );
    }
    return undefined;
  }
}
