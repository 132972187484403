import { observable } from 'mobx';

export default class CurrentTalkModel {

  @observable id: number;
  @observable title: string;
  @observable urlTitle: string;
  @observable textId: number;
  @observable textTitle: string;
  @observable urlText: string;
  @observable parcoursId: number;
  @observable parcoursName: string;
  @observable lastMessageAgo: string;

  constructor(id: number, title: string, urlTitle: string, textId: number, textTitle: string,
    urlText: string, parcoursId: number, parcoursName: string, lastMessageAgo: string) {
    this.id = id;
    this.title = title;
    this.urlTitle = urlTitle;
    this.textId = textId;
    this.textTitle = textTitle;
    this.urlText = urlText;
    this.parcoursId = parcoursId;
    this.parcoursName = parcoursName;
    this.lastMessageAgo = lastMessageAgo;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlTitle: this.urlTitle,
      textId: this.textId,
      textTitle: this.textTitle,
      urlText: this.urlText,
      parcoursId: this.parcoursId,
      parcoursName: this.parcoursName,
      lastMessageAgo: this.lastMessageAgo
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentTalkModel(object.id, object.title, object.urlTitle, object.textId,
        object.textTitle, object.urlText, object.parcoursId, object.parcoursName, object.lastMessageAgo);
    }
    return undefined;
  }
}
