import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';
const URLSafeBase64 = require('urlsafe-base64');

interface ConnectedBannerProps {
  store?: Store;
}

interface ConnectedBannerState {
  enableComponents: boolean;
  clickedOnBanner: boolean;
}

declare const __CLIENT__: any;
declare var Notification: any;

@inject('store') @observer
export default class ConnectedBanner extends React.Component<ConnectedBannerProps, ConnectedBannerState> {
  constructor(props: ConnectedBannerProps) {
    super(props);
    this.state = {
      enableComponents: false,
      clickedOnBanner: false
    };
    this.notifyMe = this.notifyMe.bind(this);
    this.cancelNotifs = this.cancelNotifs.bind(this);
  }
  componentDidMount() {
    if (__CLIENT__) {
      this.setState({
        enableComponents: true
      });
    }
  }
  notifyMe() {
    Notification.requestPermission((permission: any) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        // const notification = new Notification('Et voilà !', {
        //   body: 'Les notifications sont activées.',
        //   icon: 'icons/icon-512x512.png'
        // });
        (navigator as any).serviceWorker.ready.then((reg: any) => {
          return reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: URLSafeBase64.decode(this.props.store.vapidKey)
          });
        }).then((subscription: any) => {
          const subscriptionData = subscription.toJSON();
          if (this.props.store.isAuthenticated()) {
            this.props.store.apiClient.post('/user/registernotif', { data: subscriptionData })
              .then((response: any) => {
              }).catch((err: string) => {
                console.log('Could not register subscription into app server', err);
              });
          }
        }).catch((subscriptionErr: any) => {
          // Check for a permission prompt issue
          console.log('Subscription failed ' + subscriptionErr);
        });
      } else {
        this.cancelNotifs();
      }
      this.setState({
        clickedOnBanner: true
      });
    });
  }
  @action
  cancelNotifs() {
    this.props.store.loadSettingsNotifications().then((notifs: any) => {
      this.props.store.mainSettings.notifications.desktop = false;
      this.props.store.saveSettingsNotifications().then((r: any) => {
        this.props.store.connected.desktopNotifications = false;
      });
    });
  }
  render() {
    const connected = this.props.store.connected;
    let banner: JSX.Element;
    if (connected && connected.user && connected.user.id) {
      if (connected.newEmail) {
        banner = (
          <div className="inactive-banner">
            Veuillez confirmer votre adresse email.
              <Link to="/settings/account"> Modifier mon email / Renvoyer l'email de confirmation</Link>
          </div>
        );
      } else if (!connected.email) {
        banner = (
          <div className="inactive-banner">
            Pour profiter pleinement de l'Atelier des auteurs, <Link to="">renseignez votre adresse email</Link>.
          </div>
        );
      } /* else if (this.state.enableComponents && connected.desktopNotifications && 'Notification' in window
        && !this.state.clickedOnBanner
        && ((Notification.permission !== 'granted' && Notification.permission !== 'denied') || connected.showNotifBanner)) {
        banner = (
          <div className="inactive-banner">
            Scribay a besoin de votre permission pour <a onClick={this.notifyMe} style={{ cursor: 'pointer' }}>activer les notifications instantanées</a>.
            <span style={{ float: 'right' }} data-tooltip="Cela ne m'intéresse pas !" data-inverted="" data-position="left center">
              <span onClick={this.cancelNotifs} style={{ cursor: 'pointer' }}>
                <i className="fa fa-close" />
              </span>
            </span>
          </div>
        );
      } */
    }
    return (
      <div>
        {banner}
      </div>
    );
  }
}
