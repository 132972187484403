import { observable } from 'mobx';
import CheckValueModel from './CheckValueModel';

export default class CheckModel {

  @observable id: number;
  @observable label: string;
  @observable answers: CheckValueModel[];

  constructor(id: number, label: string, answers: CheckValueModel[]) {
    this.id = id;
    this.label = label;
    this.answers = answers;
  }

  toJS() {
    return {
      id: this.id,
      label: this.label,
      answers: this.answers.map(a => a.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const answers = object.answers.map((a: any) => CheckValueModel.fromJS(a));
      return new CheckModel(object.id, object.label, answers);
    }
    return undefined;
  }
}
