import * as React from 'react';
import { inject, observer } from 'mobx-react';
import ContentEditable from '../../../../misc/ContentEditable';
import Store from '../../../../../stores/Store';

interface ChapterTitleProps {
  store?: Store;
}

interface ChapterTitleState {
  title: string;
}

@inject('store') @observer
export default class ChapterTitle extends React.Component<ChapterTitleProps, ChapterTitleState> {
  constructor(props: ChapterTitleProps) {
    super(props);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onTitleBlur = this.onTitleBlur.bind(this);
    this.state = {
      title: this.props.store.write.currentText.currentChapter.title
    };
  }
  onTitleChange(e: any) {
    const title = e.target.value;
    this.setState({
      title: title
    });
  }
  onTitleBlur() {
    this.props.store.write.currentText.currentChapter.updateTitle(this.state.title.replace('<br>', ''));
  }
  render() {
    return (
      <div style={{ marginTop: '30px' }}>
        <h1 className="ui large header">
          <ContentEditable
            placeholder="Titre du chapitre"
            html={this.props.store.write.currentText.currentChapter.title}
            disabled={false}
            onChange={this.onTitleChange}
            onBlur={this.onTitleBlur}
            ref="chapter-title"
            className="chapter-title"
          />
          <div className="ui divider" />
        </h1>
      </div>
    );
  }
}
