import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import * as superagent from 'superagent';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface SettingsPagePremiumProps {
  store: Store;
}

@inject('store') @observer
export default class SettingsPagePremium extends React.Component<SettingsPagePremiumProps, {}> {
  static fetchData(store: Store) {
    return store.loadSettingsEmails();
  }
  constructor(props: SettingsPagePremiumProps) {
    super(props);
    this.goToChargeBee = this.goToChargeBee.bind(this);
    this.handleChangeBoughtAParcoursNews = this.handleChangeBoughtAParcoursNews.bind(this);
    this.handleChangeAllInclusiveNews = this.handleChangeAllInclusiveNews.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  goToChargeBee() {
    const connected = this.props.store.connected;
    if (connected && connected.premium && connected.premium.premium) {
      superagent
        .post('/premium/connect')
        .send({ user: { premiumId: connected.premium.premiumId } })
        .end((err, res: any) => {
          window.location.href = res.body.portal_session.access_url;
        });
    }
  }
  @action handleChangeBoughtAParcoursNews() {
    this.props.store.mainSettings.emails.boughtAParcoursNews =
      !this.props.store.mainSettings.emails.boughtAParcoursNews;
  }
  @action handleChangeAllInclusiveNews() {
    this.props.store.mainSettings.emails.allInclusiveNews =
      !this.props.store.mainSettings.emails.allInclusiveNews;
  }
  postForm(event: any) {
    event.preventDefault();
    this.props.store.saveSettingsEmails();
  }
  render() {
    const connected = this.props.store.connected;
    const form = this.props.store.mainSettings.emails;
    return (
      <div>
        {connected && connected.premium && connected.premium.premium ?
          <div>
            <div className="ui success message">
              <div className="header">
                Vous êtes membre de l'Académie.
              </div>
              
              
              {/* 
              {this.props.store.connected.premium.premiumEnd ?
                <div>
                  Votre période d'essai prendra fin le {this.props.store.connected.premium.premiumEnd}.
                  <br />
                  {currentPromotion ?
                    <h4>Jusqu'au {currentPromotion.until}, <span dangerouslySetInnerHTML={{ __html: currentPromotion.content }} /></h4>
                    : undefined}
                  <div>
                    <Link to="/academie/sign" className="ui primary button">
                      Prolonger mon abonnement
                  </Link>
                  </div>
                </div>
                : undefined
              } */}
            </div>
            {/* {connected.premium.premiumId ?
              <div>
                <button className="ui primary button" onClick={this.goToChargeBee}>Accéder à l'espace de gestion de mon abonnement</button>
                <div style={{ color: 'rgba(0, 0, 0, 0.4)', fontSize: '0.9em', float: 'left' }}>
                  Vous serez redirigé vers le site de notre partenaire qui gère le système d'abonnement.
                </div>
              </div>
              : undefined} */}
              {this.props.store.mainSettings.loading ?
                <Loader message="Chargement..." />
                :
                connected.premium.allInclusive ?
                  <form onSubmit={this.postForm} className="ui equal width form">
                    <div className="field" style={{ maxWidth: '600px' }}>
                      <Checkbox
                        checked={form.allInclusiveNews}
                        onClick={this.handleChangeAllInclusiveNews}
                        label="Conseils et nouveautés Premium"
                        />
                    </div>
                    <button className="ui submit primary right floated button"
                      type="submit" style={{ marginTop: '25px' }}>
                      Sauvegarder les modifications
                    </button>
                  </form>
                :
                  <form onSubmit={this.postForm} className="ui equal width form">
                    <div className="field" style={{ maxWidth: '600px' }}>
                      <Checkbox
                        checked={form.boughtAParcoursNews}
                        onClick={this.handleChangeBoughtAParcoursNews}
                        label="Conseils et nouveautés Premium"
                        />
                    </div>
                    <button className="ui submit primary right floated button"
                      type="submit" style={{ marginTop: '25px' }}>
                      Sauvegarder les modifications
                    </button>
                  </form>
              }
          </div>
          :
          <div className="ui message">
            {this.props.store.connected.premium.premiumEnd ?
              <div className="header">Votre abonnement à l'Académie a expiré.</div>
              :
              <div className="header">Vous n'êtes pas membre de l'Académie.</div>
            }
           {/*  {currentPromotion ?
              <h4>Jusqu'au {currentPromotion.until}, <span dangerouslySetInnerHTML={{ __html: currentPromotion.content }} /></h4>
              : undefined} */}
            {this.props.store.connected.premium.premiumEnd ?
              <Link to="/academie/sign" className="ui primary button">
                Réactiver mon abonnement
                  </Link>
              :
              <Link to="/ecole" className="ui primary button" style={{ marginTop: '10px' }}>Découvrir l'école</Link>
            }
          </div>
        }
      </div>
    );
  }
}
