import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
import { Pie } from 'react-chartjs-2';

interface SentencesProps {
  store?: Store;
  chapterId?: number;
}

@inject('store') @observer
export default class Sentences extends React.Component<SentencesProps, {}> {
  render() {
    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsWrite;
    const flteredStatistics = statistics.filter(stat => stat.chapter.title !== "")
    const rows = flteredStatistics.map((statistic) => {
      if (statistic.stat && statistic.stat.sentencesTypes && statistic.exists && statistic.chapter.title !== "") {
        const sentences = statistic.stat.sentencesTypes;
        return (
          <tr key={`stat_sentence_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId == statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td className="right aligned">
              {sentences.total > 0 ? Math.round(sentences.negatives / sentences.total * 100) : '-'}
            </td>
            <td className="right aligned">
              {sentences.total > 0 ? Math.round(sentences.passives / sentences.total * 100) : '-'}
            </td>
            <td className="right aligned">
              {sentences.total > 0 ? Math.round(sentences.ternes / sentences.total * 100) : '-'}
            </td>
            <td className="right aligned">
              {sentences.total > 0 ? Math.round(sentences.nonVerbales / sentences.total * 100) : '-'}
            </td>
          </tr>
        )
      } else if(statistic.chapter.title !== "") {
        return (
          <tr key={`stat_sentence_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId === statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td colSpan={4}>
              {statistic.exists ?
                <span><span className="ui small active inline loader" style={{ marginRight: '10px' }} /> Calcul en cours...</span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      }
      else {
        return(undefined)
      }
    });
    const stillLoading = flteredStatistics.filter(stat => (!stat.stat || !stat.stat.sentencesTypes) && stat.exists).length > 0;
    const withValues = flteredStatistics.filter(stat => stat.stat && stat.stat.sentencesTypes && stat.exists).length > 0;
    if (!stillLoading) {
      const filteredStats = flteredStatistics.filter(s => (s.stat && s.stat.sentencesTypes && s.stat.sentencesTypes.total) > 0 && s.exists);
      if (filteredStats.length > 0) {
        const negatives = filteredStats.map(s => Math.round(s.stat.sentencesTypes.negatives / s.stat.sentencesTypes.total * 100))
          .reduce((a, b) => a + b);
        const passives = filteredStats.map(s => Math.round(s.stat.sentencesTypes.passives / s.stat.sentencesTypes.total * 100))
          .reduce((a, b) => a + b);
        const ternes = filteredStats.map(s => Math.round(s.stat.sentencesTypes.ternes / s.stat.sentencesTypes.total * 100))
          .reduce((a, b) => a + b);
        const nonVerbales = filteredStats.map(s => Math.round(s.stat.sentencesTypes.nonVerbales / s.stat.sentencesTypes.total * 100))
          .reduce((a, b) => a + b);
        rows.push(
          <tr key="stat_sentence_total">
            <td><b>Moyenne</b></td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(negatives / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(passives / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(ternes / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
            <td className="right aligned">
              {filteredStats.length > 0 ?
                <span><b>{Math.round(nonVerbales / filteredStats.length)}</b></span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      } else {
        rows.push(
          <tr key="stat_pos_total">
            <td><b>Moyenne</b></td>
            <td colSpan={4}>
              -
            </td>
          </tr>
        );
      }
    }

    let data: {};
    const selectedChapter = this.props.chapterId ? this.props.chapterId : statistics[0].chapter.id;
    if (!stillLoading && withValues) {
      const labels = ['Negatives', 'Passives', 'Ternes', 'Non verbales', 'Autres'];
      const ft = flteredStatistics.filter(stat => stat.exists && stat.chapter.id == selectedChapter);
      if (ft.length > 0) {
        const sentences = ft[0].stat.sentencesTypes;
        const values = [
          sentences.total > 0 ? Math.round(sentences.negatives / sentences.total * 100) : 0,
          sentences.total > 0 ? Math.round(sentences.passives / sentences.total * 100) : 0,
          sentences.total > 0 ? Math.round(sentences.ternes / sentences.total * 100) : 0,
          sentences.total > 0 ? Math.round(sentences.nonVerbales / sentences.total * 100) : 0,
          sentences.total > 0 ? Math.round((sentences.total - (sentences.negatives + sentences.passives + sentences.ternes + sentences.nonVerbales))
            / sentences.total * 100) : 0,
        ];
        data = {
          labels: labels,
          datasets: [
            {
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(54, 162, 235)', 'rgb(75, 192, 192)', 'rgb(255, 159, 64)'],
              data: values
            }
          ]
        };
      }
    }

    return (
      <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
        <div className="seven wide column" style={{ paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <table className="ui center aligned celled table">
                <thead>
                  <tr>
                    <th>-</th>
                    <th>% Négatives</th>
                    <th>% Passives</th>
                    <th>% Ternes</th>
                    <th>% non verbales</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
              <div className="ui info message">
                <div className="header">Pourquoi cet indicateur est important</div>
                <p>Les types de phrases composant un texte font partie intégrante du style d’un auteur et d’une œuvre.</p>
                <p>La surreprésentation de certains types de phrases peuvent résulter d’un effet de style mais peuvent être aussi perçus comme des lourdeurs ou des maladresses.</p>
                <p>Les <b>phrases négatives et passives</b> peuvent alourdir la lecture d’un texte si elles sont trop nombreuses.</p>
                <p>Les <b>verbes ternes</b> sont accessibles à un lectorat élargi mais leur profusion peut être lassante.</p>
                <p>Les <b>phrases non-verbales</b> peuvent faire leur effet mais aussi fatiguer des lecteurs.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="nine wide column">
          <div className="ui grid stackable">
            <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '45px' }}>
              {stillLoading ?
                <Loader message="Calcul des statistiques en cours..." />
                :
                <div className="ui center aligned container">
                  {data ?
                    <Pie data={data} />
                    : <div className="ui message">
                      Pas de graphique (chapitre vide)
                  </div>
                  }
                  <div style={{ marginTop: '20px' }}>
                    <Dropdown
                      selection
                      value={String(selectedChapter)}
                      onChange={(e: any, { value }) => {
                        browserHistory.push({
                          pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/write`,
                          query: {
                            chapter: value,
                            stat: 'sentences'
                          }
                        });
                      }}
                      options={flteredStatistics.map((stat) => {
                        return { text: `${stat.chapter.title.substring(0, 25)}`, value: String(stat.chapter.id) };
                      })}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
