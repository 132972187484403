import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import ReadingListsTextModal from '../readinglist/ReadingListsTextModal';

interface ChapterListsProps {
  store?: Store;
  icon?: boolean;
  vertical?: boolean;
}

interface ChapterListsState {
  modalActive: boolean;
}

@inject('store') @observer
export default class ChapterLists extends React.Component<ChapterListsProps, ChapterListsState> {
  constructor(props: ChapterListsProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      modalActive: false
    };
  }
  showModal() {
    const textId = this.props.store.text.meta.id;
    this.props.store.loadMyReadingListsForText(textId).then((d: any) => {
      this.setState({
        modalActive: true
      });
    });
  }
  hideModal() {
    this.setState({
      modalActive: false
    });
  }
  render() {
    const inReadingList = this.props.store.text
      && this.props.store.text.meta
      && this.props.store.text.meta.inReadingList;
    return (
      <div>
        <ReadingListsTextModal
          active={this.state.modalActive}
          hideModal={this.hideModal}
        />
        <div>
          <button className={'ui icon ' + (inReadingList ? 'active ' : '') + 'button'} onClick={this.showModal}
            data-tooltip="Liste de lectures" data-inverted=""
            data-position={this.props.vertical ? 'left center' : 'bottom center'}
          >
            {this.props.icon ?
              <i className="fa fa-list" />
              : <span><i className="fa fa-list" /> Liste</span>
            }
          </button>
        </div>
      </div>
    );
  }
}
