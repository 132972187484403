import * as React from 'react';
import TextCard from './TextCard';
import FullTextModel from '../../../models/FullTextModel';

interface TextCarouselProps {
  texts: FullTextModel[];
}

export default class TextCarousel extends React.Component<TextCarouselProps, {}> {
  render() {
    const childElements = this.props.texts.map(text => <TextCard key={text.id} text={text} />);

    return (
      <div className="ui three stackable cards">
        {childElements}
      </div>
    );
  }
}
