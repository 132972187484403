import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Store from '../../stores/Store';
import NotificationsList from '../notification/NotificationsList';
import Sidebar from '../misc/Sidebar';
import ConnectedBanner from '../misc/ConnectedBanner';
import SearchBox from './SearchBox';

interface ConnectedTopBarAdaProps {
  store?: Store;
  atelier: boolean;
  location: { pathname: string };
  posessed: boolean;
  buyParcours: Function | any;
  buyParcoursText: string;

}

interface ConnectedTopBarAdaState {
  showNotifications: boolean;
  notifications: string[];
}

@inject('store') @observer
export default class ConnectedTopBarAda extends React.Component<ConnectedTopBarAdaProps, ConnectedTopBarAdaState> {
  _timer: any;

  constructor(props: ConnectedTopBarAdaProps) {
    super(props);
    this.startPolling = this.startPolling.bind(this);
    this.poll = this.poll.bind(this);
    this.onMenuChange = this.onMenuChange.bind(this);
    this.switchUI = this.switchUI.bind(this);
  }
  componentDidMount() {
    this.startPolling();
  }
  startPolling() {
    const that = this;
    setTimeout(() => {
      that._timer = setInterval(that.poll.bind(self), 120000);
    }, 1000);
  }
  poll() {
    if (this.props.store.isAuthenticated()) {
      this.props.store.loadConnected();
    }
  }
  @action
  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
  onMenuChange(value: number) {
    const connected = this.props.store.connected;
    switch (value) {
      case 1:
        browserHistory.push({
          pathname: `/author/${connected.user.id}/${connected.user.urlIdentity}`
        });
        break;
      case 2:
        browserHistory.push({
          pathname: '/settings'
        });
        break;
      case 3:
        window.location.href = '/auth/logout';
        break;
      default:
        break;
    }
  }
  switchUI() {
    this.props.store.switchUI().then((p: any) => {
      browserHistory.push({
        pathname: '/'
      });
    });
  }
  render() {
    const connected = this.props.store.connected;

    return (
      <div>
        <div className="hide-large">
          <Sidebar />
        </div>
        <div className="ui top fixed menu borderless home-padding ada-background-editis-bleu-nuit" style={{ boxShadow: 'none', borderBottom: 'none' }}>
            {connected && connected.newUser ?
              <Link
                id="dashboard"
                className="item"
                to="/?m=w"
                title="Tableau de bord">
                <i className="fa fa-home" style={{ fontSize: '1.3em', color: '#fff' }}></i>
              </Link>
            :
              <Link
                id="dashboard"
                className="item"
                to="/"
                title="Tableau de bord">
                <i className="fa fa-home" style={{ fontSize: '1.3em', color: '#fff' }}></i>
              </Link>
            }
            {!this.props.atelier ?
              <Link
                id="write"
                style={{ color: '#fff' }}
                className="item hide-mobile ada-museo-300 "
                to="/write"
                title="Écrire">
                Écrire
              </Link>
            : undefined }
            {!this.props.atelier ?
              <Link
                id="read"
                style={{ color: '#fff' }}
                className="item hide-mobile ada-museo-300 "
                to="/read"
                title="Lire">
                Lire
              </Link>
            : undefined }
            {!this.props.atelier ?
              <Link
                id="talks"
                style={{ color: '#fff' }}
                className="item hide-mobile ada-museo-300 "
                to="/talks"
                title="Échanger">
                Échanger
              </Link>
            : undefined }
            {!this.props.atelier ?
              <Link
                id="relever_defi"
                style={{ color: '#fff' }}
                className="item hide-mobile ada-museo-300 "
                to="/defis"
                title="Défis">
                Défis
              </Link>
            : undefined }
            {!this.props.atelier ?
              <SearchBox mobile={false} />
            : undefined }
            {this.props.atelier ?
              this.props.posessed ?
              <div className="sub header">
                <div style={{paddingTop: "0.6rem"}}>
                  <button
                    className="ui primary button"
                    onClick={this.props.buyParcours}
                    style={{ cursor: 'pointer', backgroundColor: '#f0ad4e'}}
                  >
                    Accéder à l'atelier
                  </button>
                </div>
              </div>
            :
            <div className="sub header">
              <div style={{paddingTop: "0.6rem"}}>
                <button
                  className="ui primary button ada-background editis-bleu" 
                  onClick={this.props.buyParcours} 
                  style={{ cursor: 'pointer'}}
                  id={"buy_this_workshop_navbar"}
                >
                  {this.props.buyParcoursText}
                </button>
              </div>
            </div>
            : undefined }
            <div className="right menu" style={{ border: 'none' }}>
              <div className="item">
                {connected.premium.premium ?
                  <span style={{ marginRight: '10px' }}>
                    <Button
                      toggle
                      onClick={this.switchUI}
                      className={'ui label ' + (connected.premium.premiumUI ? 'violet' : '')}
                      data-tooltip={connected.premium.premiumUI ? 'Basculer vers la Communauté' : 'Basculer vers les ateliers'}
                      data-inverted="" data-position="bottom left"
                      
                    >
                      <i className="hide-mobile fa fa-arrow-right" />
                      <span className="hide-mobile" style={{marginRight: "5px"}}> Vos ateliers </span><i className="fa fa-university" />
                    </Button>
                  </span>
                  : undefined}
                <NotificationsList />
                <span style={{ marginRight: '10px' }}>
                  <Link
                    id="messages"
                    to="/messages"
                    className={'ui label' + (connected.messagesCount > 0 ? ' orange' : '')}
                  >
                    <i
                      className="fa fa-envelope"
                      style={{ marginRight: '5px' }}
                    />
                    {connected.messagesCount}
                  </Link>
                </span>
                <div id="profile">
                  <Dropdown icon={null} trigger={
                    <div
                      className={'ui label'}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    >
                      <i
                        className="fa fa-user"
                        style={{ marginRight: '5px' }}
                      />
                      <i className="fa fa-caret-down"></i>
                    </div>
                  }
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item value={1} text="Mon profil" onClick={() => this.onMenuChange(1)} />
                      <Dropdown.Item value={2} text="Paramètres" onClick={() => this.onMenuChange(2)} />
                      <Dropdown.Item value={3} text="Déconnexion" onClick={() => this.onMenuChange(3)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
        </div>
        <ConnectedBanner />
      </div >
    );
  }
}
