import { observable } from 'mobx';

export default class TalkOrCommentSummaryModel {

  @observable lastMessageAgo: string;
  @observable answers: number;
  @observable talkId: number;
  @observable talkTitle: string;
  @observable urlTalk: string;
  @observable chapterId: number;
  @observable chapterTitle: string;

  constructor(lastMessageAgo: string, answers: number, talkId: number, talkTitle: string,
    urlTalk: string, chapterId: number, chapterTitle: string) {
    this.lastMessageAgo = lastMessageAgo;
    this.answers = answers;
    this.talkId = talkId;
    this.talkTitle = talkTitle;
    this.urlTalk = urlTalk;
    this.chapterId = chapterId;
    this.chapterTitle = chapterTitle;
  }

  toJS() {
    return {
      lastMessageAgo: this.lastMessageAgo,
      answers: this.answers,
      talkId: this.talkId,
      talkTitle: this.talkTitle,
      urlTalk: this.urlTalk,
      chapterId: this.chapterId,
      chapterTitle: this.chapterTitle
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new TalkOrCommentSummaryModel(object.lastMessageAgo, object.answers, object.talkId, object.talkTitle,
        object.urlTalk, object.chapterId, object.chapterTitle);
    }
    return undefined;
  }
}
