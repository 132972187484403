import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import CommentChildren from './CommentChildren';
import CommentForm from './CommentForm';
import CommentVote from './CommentVote';
import CommentAnswer from './CommentAnswer';
import CommentAnswers from './CommentAnswers';
import CommentRemove from './CommentRemove';
import CommentEdit from './CommentEdit';
import UserModel from '../../models/UserModel';
import CommentModel from '../../models/comment/CommentModel';
import CommentListModel from '../../models/comment/CommentListModel';
import Store from '../../stores/Store';

interface CommentProps {
  store?: Store;
  comment: CommentModel;
  commentChildren: CommentModel[];
  memberId?: number;
  parentId?: number;
  author?: UserModel;
  authorText?: number;
  authorDefi?: number;
  readonly: boolean;
  admin: boolean;
  newsfeedElement: boolean;
  comments: CommentListModel;
}

@inject('store') @observer
export default class Comment extends React.Component<CommentProps, {}> {
  constructor(props: CommentProps) {
    super(props);
    this.handleShowAnswers = this.handleShowAnswers.bind(this);
    this.handleInitEdit = this.handleInitEdit.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleShowAnswers() {
    this.props.comment.showAnswers();
  }
  handleInitEdit() {
    this.props.store.handleInitEdit(this.props.comments.restPath(), this.props.comment);
  }
  handleCancelEdit() {
    this.props.store.handleCancelEdit(this.props.comment);
  }
  handleSubmitEdit(text: string) {
    return this.props.store.handleSubmitEdit(this.props.comments.restPath(), this.props.comment, text);
  }
  handleRemove() {
    this.props.store.handleRemove(this.props.comments, this.props.comment);
  }
  render() {
    let children: any;
    if (((!this.props.newsfeedElement || this.props.comment.shownAnswers)
      && this.props.commentChildren.length > 0) || this.props.comment.answerMode) {
      children = <CommentChildren
        comment={this.props.comment}
        memberId={this.props.memberId}
        authorText={this.props.authorText}
        authorDefi={this.props.authorDefi}
        author={this.props.author}
        commentChildren={this.props.commentChildren}
        readonly={this.props.readonly}
        newsfeedElement={this.props.newsfeedElement}
        admin={this.props.admin}
        comments={this.props.comments}
      />;
    } else {
      children = <div></div>;
    }

    let cpt: any;
    if (this.props.comment.editMode && !this.props.readonly) {
      cpt = (<div className="comment">
        <span id={`comment_${this.props.comment.id}`} className="anchor"></span>
        <CommentForm
          author={this.props.author}
          submit={this.handleSubmitEdit}
          comment={this.props.comment}
          textEdit={this.props.comment.textEdit}
          cancel={this.handleCancelEdit}
          focus
        />
        {children}
      </div>);
    } else {
      cpt = (<div className={'comment' + (this.props.comment.isNew ? ' new' : '')}>
        <span id={`comment_${this.props.comment.id}`} className="anchor"></span>
        {this.props.comment.userId ?
          <Link to={`/author/${this.props.comment.userId}/${this.props.comment.userUrl}`}
            className="avatar"
          >
            <img src={this.props.comment.userAvatar} alt={`Image de profil de ${this.props.comment.userIdentity}`} />
          </Link>
          :
          <span className="avatar">
            <img src={this.props.comment.userAvatar} alt={`Image de profil de ${this.props.comment.userIdentity}`} />
          </span>
        }
        <div className="content">
          <div className="content">
            {this.props.comment.userId ?
              <Link to={`/author/${this.props.comment.userId}/${this.props.comment.userUrl}`}
                className="author">
                {this.props.comment.userIdentity}
              </Link>
              : <span className="author">{this.props.comment.userIdentity}</span>
            }
            <div className="metadata">
              {this.props.comment.userAdmin
                ? <span className="sp orange">(administrateur) </span>
                : null}
              {this.props.authorDefi === this.props.comment.userId
                ? <span className="orange">(auteur du défi) </span>
                : null}
              {this.props.authorText === this.props.comment.userId
                ? <span className="orange">(auteur de l'œuvre) </span>
                : null}
            </div>
            <div className="metadata" style={this.props.newsfeedElement ? { float: 'right' } : {}}>
              <span>{this.props.comment.ago}</span>
            </div>
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: this.props.comment.text }}
            />
          </div>
          <div className="actions">
            {!this.props.readonly && this.props.comment.userId ?
              <CommentVote
                author={this.props.author}
                comment={this.props.comment}
                restPath={this.props.comments.restPath()}
              />
              : undefined}
            {!this.props.readonly ?
              <CommentAnswer
                comment={this.props.comment}
                author={this.props.author}
                chapterUrl={this.props.comment.chapterUrl}
              />
              : undefined}
            <span>
              <span>
                {(this.props.author && !this.props.readonly
                  && (this.props.author.id === this.props.comment.userId))
                  ? <CommentEdit handleInitEdit={this.handleInitEdit} />
                  : null}
                {((this.props.author
                  && (this.props.author.id === this.props.comment.userId)) || this.props.admin)
                  ? <CommentRemove
                    handleRemove={this.handleRemove}
                  />
                  : null}
                {this.props.newsfeedElement && this.props.commentChildren.length > 0
                  ? <CommentAnswers
                    handleShowAnswers={this.handleShowAnswers}
                    countAnswers={this.props.commentChildren.length}
                  />
                  : null}
              </span>
            </span>
          </div>
        </div>
        {children}
      </div>
      );
    }

    return cpt;
  }
}
