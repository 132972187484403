import * as React from 'react';

import NewsfeedEvent from './NewsfeedEvent';
import FeedModel from '../../models/feed/FeedModel';
import ConnectedModel from '../../models/ConnectedModel';
import CommentBox from '../comment/CommentBox';

interface FeedEventProps {
  event: FeedModel;
  connected: ConnectedModel;
}

export default class FeedEvent extends React.Component<FeedEventProps, {}> {
  render() {
    const e = this.props.event;
    let o: any;
    if (e.newsfeed) {
      o = <NewsfeedEvent event={e.newsfeed} />;
    } else if (e.diary) {
      const connected = this.props.connected;
      const blockedBy = connected && connected.user && connected.user.id
        && connected.blockedBy.indexOf(e.diary.comments[0].userId) !== -1;
      const admin = connected && connected.user && connected.user.admin;
      o = <CommentBox
        admin={admin}
        newsfeedElement={true}
        readonly={blockedBy}
        comments={e.diary}
      />;
    } else {
      return <div />;
    }
    return o;
  }
}
