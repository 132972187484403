import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import NewDraftModel from '../../../models/write/NewDraftModel';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../../stores/Store';

interface ConfirmChangeStructureProps {
  active: boolean;
  confirmChange: Function | any;
  cancelChange: Function | any;
}

class ConfirmChangeStructure extends React.Component<ConfirmChangeStructureProps, {}> {
  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={() => { }}
      >
        <div className="header">Confirmation</div>
        <div className="content">
          Confirmez-vous le retour à une structure à un niveau ?
          <br />Les chapitres ajoutés seront gardés mais les parties créées seront supprimées.
        </div>
        <div className="actions">
          <button
            className="ui blue approve button" onClick={this.props.confirmChange}
          >
            Confirmer
          </button>
          <button className="ui button" onClick={this.props.cancelChange}>
            Annuler
          </button>
        </div>
      </Modal>
    );
  }
}

interface NewTextStructureFormProps {
  store?: Store;
  afterSave: Function | any;
  title: string;
  linkedToProgram: boolean;
}

interface StructurePart {
  title: string;
  titleEdit: string;
  chapters?: StructureChapter[];
  editMode: boolean;
}

interface StructureChapter {
  title: string;
  titleEdit: string;
  editMode?: boolean;
}

interface NewTextStructureFormState {
  twoLevels: boolean;
  structure: {
    parts: StructurePart[];
    chapters: StructureChapter[];
  };
  selectedChapter: number;
  confirmChangeActive: boolean;
}

@inject('store') @observer
export default class NewTextStructureForm
  extends React.Component<NewTextStructureFormProps, NewTextStructureFormState> {
  constructor(props: NewTextStructureFormProps) {
    super(props);
    this.createNewText = this.createNewText.bind(this);
    this.handleTwoLevelsChange = this.handleTwoLevelsChange.bind(this);
    this.confirmTwoLevelsChange = this.confirmTwoLevelsChange.bind(this);
    this.cancelTwoLevelsChange = this.cancelTwoLevelsChange.bind(this);
    this.upChapter = this.upChapter.bind(this);
    this.downChapter = this.downChapter.bind(this);
    this.removeChapter = this.removeChapter.bind(this);
    this.changePartEditMode = this.changePartEditMode.bind(this);
    this.startEditPart = this.startEditPart.bind(this);
    this.cancelEditPart = this.cancelEditPart.bind(this);
    this.validEditPart = this.validEditPart.bind(this);
    this.handlePartTitleChange = this.handlePartTitleChange.bind(this);
    this.changeChapterEditMode = this.changeChapterEditMode.bind(this);
    this.startEditChapter = this.startEditChapter.bind(this);
    this.cancelEditChapter = this.cancelEditChapter.bind(this);
    this.validEditChapter = this.validEditChapter.bind(this);
    this.handleChapterTitleChange = this.handleChapterTitleChange.bind(this);
    this.newPart = this.newPart.bind(this);
    this.newChapter = this.newChapter.bind(this);

    this.state = {
      twoLevels: false,
      structure: {
        parts: [],
        chapters: [
          {
            title: 'Chapitre 1',
            titleEdit: '',
            editMode: false
          }
        ]
      },
      selectedChapter: undefined,
      confirmChangeActive: false
    };
  }
  createNewText() {
    this.props.store.createNewTextCustom(
      this.props.title, this.state.twoLevels,
      this.state.structure.parts.map((p) => {
        return {
          title: p.title,
          chapters: p.chapters.map((c) => { return { title: c.title }; })
        };
      }),
      this.state.structure.chapters.map((c) => { return { title: c.title }; })
    ).then((draft: NewDraftModel) => {
      if (this.props.linkedToProgram) {
        const objectives = [
          { chapters: 1, linkedTextId: draft.textId },
          { day: 1, words: 250 },
          { day: 2, words: 250 },
          { day: 3, words: 250 },
          { day: 4, words: 250 },
          { day: 5, words: 250 },
          { day: 6, words: 250 },
          { day: 7, words: 250 }
        ];
        this.props.store.cancelWritingProgram().then((c: any) => {
          this.props.store.createNewWritingProgram(objectives).then((d: any) => {
            browserHistory.push({
              pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
              state: {
                newPart: false,
                showCongratulationsChapter: false
              }
            });
          });
        });
      } else {
        browserHistory.push({
          pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
          state: {
            newPart: false,
            showCongratulationsChapter: false
          }
        });
      }
    });
  }
  handleTwoLevelsChange() {
    if (this.state.twoLevels) {
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: this.state.structure,
        selectedChapter: this.state.selectedChapter,
        confirmChangeActive: true
      });
    } else {
      this.confirmTwoLevelsChange();
    }
  }
  cancelTwoLevelsChange() {
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: this.state.structure,
      selectedChapter: this.state.selectedChapter,
      confirmChangeActive: false
    });
  }
  confirmTwoLevelsChange() {
    const newTwoLevels = !this.state.twoLevels;
    const newStructure: {
      parts: StructurePart[];
      chapters: StructureChapter[];
    } = newTwoLevels ?
        {
          parts: [
            {
              title: 'Partie 1',
              titleEdit: '',
              chapters: this.state.structure.chapters.map(c => {
                const newChapter = c;
                newChapter.titleEdit = '';
                newChapter.editMode = false;
                return newChapter;
              }),
              editMode: false
            }
          ],
          chapters: []
        }
        :
        {
          parts: [],
          chapters: [].concat.apply([], this.state.structure.parts.map(p => p.chapters.map(c => {
            const newChapter = c;
            newChapter.titleEdit = '';
            newChapter.editMode = false;
            return newChapter;
          })))
        };

    this.setState({
      twoLevels: newTwoLevels,
      structure: newStructure,
      selectedChapter: undefined,
      confirmChangeActive: false
    });
  }
  upChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      if (chapterIndex === 0) {
        // move to previous part
        const chapter = parts[partIndex].chapters[chapterIndex];
        parts[partIndex].chapters.shift();
        parts[partIndex - 1].chapters.push(chapter);
      } else {
        // keep in current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        const previousChapter = parts[partIndex].chapters[chapterIndex - 1];
        parts[partIndex].chapters[chapterIndex - 1] = chapter;
        parts[partIndex].chapters[chapterIndex] = previousChapter;
      }
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts.filter(p => p.chapters.length > 0),
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      const previousChapter = chapters[chapterIndex - 1];
      chapters[chapterIndex - 1] = chapter;
      chapters[chapterIndex] = previousChapter;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  downChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const partsLength = parts[partIndex].chapters.length;
      if (chapterIndex === partsLength - 1) {
        // move to next part
        // current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        parts[partIndex].chapters.pop();
        parts[partIndex + 1].chapters.unshift(chapter);
      } else {
        // keep in current part
        const chapter = parts[partIndex].chapters[chapterIndex];
        const nextChapter = parts[partIndex].chapters[chapterIndex + 1];
        parts[partIndex].chapters[chapterIndex + 1] = chapter;
        parts[partIndex].chapters[chapterIndex] = nextChapter;
      }
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts.filter(p => p.chapters.length > 0),
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      const nextChapter = chapters[chapterIndex + 1];
      chapters[chapterIndex + 1] = chapter;
      chapters[chapterIndex] = nextChapter;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  removeChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const part = parts[partIndex];
      part.chapters.splice(chapterIndex, 1);
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      chapters.splice(chapterIndex, 1);
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  changePartEditMode(partIndex: number, editMode: boolean) {
    const parts = this.state.structure.parts;
    const part = parts[partIndex];
    part.editMode = editMode;
    part.titleEdit = editMode ? part.title : '';
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  startEditPart(partIndex: number, chapterIndex: number) {
    this.changePartEditMode(partIndex, true);
  }
  cancelEditPart(partIndex: number, chapterIndex: number) {
    this.changePartEditMode(partIndex, false);
  }
  validEditPart(partIndex: number, chapterIndex: number) {
    const parts = this.state.structure.parts;
    const part = parts[partIndex];
    part.title = part.titleEdit;
    part.editMode = false;
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  handlePartTitleChange(partIndex: number, e: any) {
    const parts = this.state.structure.parts;
    parts[partIndex].titleEdit = e.target.value;
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  changeChapterEditMode(partIndex: number, chapterIndex: number, editMode: boolean) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.editMode = editMode;
      chapter.titleEdit = editMode ? chapter.title : '';
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.editMode = editMode;
      chapter.titleEdit = editMode ? chapter.title : '';
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  startEditChapter(partIndex: number, chapterIndex: number) {
    this.changeChapterEditMode(partIndex, chapterIndex, true);
  }
  cancelEditChapter(partIndex: number, chapterIndex: number) {
    this.changeChapterEditMode(partIndex, chapterIndex, false);
  }
  validEditChapter(partIndex: number, chapterIndex: number) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.title = chapter.titleEdit;
      chapter.editMode = false;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.title = chapter.titleEdit;
      chapter.editMode = false;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  handleChapterTitleChange(partIndex: number, chapterIndex: number, e: any) {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapter = parts[partIndex].chapters[chapterIndex];
      chapter.titleEdit = e.target.value;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      const chapter = chapters[chapterIndex];
      chapter.titleEdit = e.target.value;
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  newPart() {
    const parts = this.state.structure.parts;
    const chapters = [].concat.apply([], this.state.structure.parts.map(p => p.chapters));
    const newPart: StructurePart = {
      title: `Partie ${parts.length + 1}`,
      titleEdit: '',
      chapters: [
        {
          title: `Chapitre ${chapters.length + 1}`,
          titleEdit: '',
          editMode: false
        }
      ],
      editMode: false
    };
    parts.push(newPart);
    this.setState({
      twoLevels: this.state.twoLevels,
      structure: {
        parts: parts,
        chapters: []
      },
      selectedChapter: undefined,
      confirmChangeActive: this.state.confirmChangeActive
    });
  }
  newChapter() {
    if (this.state.twoLevels) {
      const parts = this.state.structure.parts;
      const chapters = [].concat.apply([], parts.map(p => p.chapters));
      const lastPart = parts[parts.length - 1];
      lastPart.chapters.push({
        title: `Chapitre ${chapters.length + 1}`,
        titleEdit: '',
        editMode: false
      });
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: parts,
          chapters: []
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    } else {
      const chapters = this.state.structure.chapters;
      chapters.push({
        title: `Chapitre ${chapters.length + 1}`,
        titleEdit: '',
        editMode: false
      });
      this.setState({
        twoLevels: this.state.twoLevels,
        structure: {
          parts: [],
          chapters: chapters
        },
        selectedChapter: undefined,
        confirmChangeActive: this.state.confirmChangeActive
      });
    }
  }
  render() {
    let parts: JSX.Element[];
    let chapters: JSX.Element[];

    const inEditMode = (this.state.twoLevels &&
      this.state.structure.parts.filter(p => p.editMode || p.chapters.filter(c => c.editMode).length > 0).length > 0
      || (!this.state.twoLevels && this.state.structure.chapters.filter(c => c.editMode).length > 0));

    if (this.state.twoLevels) {
      parts = [].concat.apply([], this.state.structure.parts.map((part, i) => {
        chapters = part.chapters.map((chapter, j) => {
          return (
            <tr key={`part_${i}_chapter_${j}`}>
              {j === 0 ?
                <td rowSpan={part.chapters.length}>
                  {part.editMode ?
                    <div>
                      <input
                        ref="titleField"
                        type="text"
                        onChange={(e) => this.handlePartTitleChange(i, e)}
                        value={part.titleEdit}
                        style={{ width: '300px' }}
                        autoFocus={true}
                      />
                    </div>
                    :
                    part.title}
                  {inEditMode ?
                    (part.editMode ?
                      <div
                        className="ui one column stackable form"
                        style={{ marginBottom: '5px', marginTop: '10px' }}
                      >
                        <div className="inline fields">
                          <div className="field">
                            <button
                              className="ui blue mini button"
                              onClick={() => this.validEditPart(i, j)}
                              type="submit"
                            >
                              Enregistrer
                        </button>
                          </div>
                          <div className="field">
                            <button
                              type="button"
                              className="ui mini button"
                              onClick={() => this.cancelEditPart(i, j)}
                            >
                              Annuler
                        </button>
                          </div>
                        </div>
                      </div>
                      : undefined)
                    :
                    <span style={{ float: 'right' }}>
                      <button
                        title="Modifier le titre"
                        className="ui mini icon orange button"
                        style={{ marginRight: '5px' }}
                        onClick={() => this.startEditPart(i, j)}
                      >
                        <i className="fa fa-edit" />
                      </button>
                    </span>
                  }
                </td>
                : undefined}
              <td className="center aligned">
                {chapter.editMode ?
                  <div>
                    <input
                      ref="titleField"
                      type="text"
                      onChange={(e) => this.handleChapterTitleChange(i, j, e)}
                      value={chapter.titleEdit}
                      style={{ width: '300px' }}
                      autoFocus={true}
                    />
                  </div>
                  : <span>
                    {chapter.title && chapter.title.trim().length > 0 ?
                      chapter.title :
                      <span style={{ color: '#ddd' }}>Sans titre</span>
                    }
                    {!inEditMode ?
                      <button
                        title="Modifier le titre"
                        className="ui mini icon orange button"
                        style={{ marginRight: '5px', float: 'right' }}
                        onClick={() => this.startEditChapter(i, j)}
                      >
                        <i className="fa fa-edit" />
                      </button>
                      : undefined}
                  </span>
                }
              </td>
              {inEditMode
                || this.state.structure.parts.length > 1 || this.state.structure.parts[0].chapters.length > 1 ?
                <td>
                  {inEditMode ?
                    (chapter.editMode ?
                      <div
                        className="ui one column stackable center aligned grid form"
                        style={{ marginBottom: '5px', marginTop: '10px' }}
                      >
                        <div className="inline fields">
                          <div className="field">
                            <button
                              className="ui blue mini button"
                              onClick={() => this.validEditChapter(i, j)}
                              type="submit"
                            >
                              Enregistrer
                        </button>
                          </div>
                          <div className="field">
                            <button
                              type="button"
                              className="ui mini button"
                              onClick={() => this.cancelEditChapter(i, j)}
                            >
                              Annuler
                        </button>
                          </div>
                        </div>
                      </div>
                      : undefined)
                    : <span style={{ float: 'right' }}>
                      <button
                        title="Supprimer"
                        className="ui mini icon red button"
                        style={{ marginRight: '5px' }}
                        onClick={() => this.removeChapter(i, j)}
                      >
                        <i className="fa fa-remove" />
                      </button>
                      {i > 0 || j > 0 ?
                        <button
                          title="Monter"
                          className="ui mini icon blue button"
                          style={{ marginRight: '5px' }}
                          onClick={() => this.upChapter(i, j)}
                        >
                          <i className="fa fa-arrow-up">
                          </i>
                        </button>
                        : null}
                      {i < this.state.structure.parts.length - 1 || j < part.chapters.length - 1 ?
                        <button
                          title="Descendre"
                          className="ui mini icon blue button"
                          style={{ marginRight: '5px' }}
                          onClick={() => this.downChapter(i, j)}
                        >
                          <i className="fa fa-arrow-down">
                          </i>
                        </button>
                        : null}
                    </span>
                  }
                </td>
                : undefined}
            </tr>
          );
        });
        return chapters;
      }));
    } else {
      chapters = this.state.structure.chapters.map((chapter, i) => {

        return (
          <tr key={`chapter_${i}`}>
            <td className="center aligned">
              {chapter.editMode ?
                <div>
                  <input
                    ref="titleField"
                    type="text"
                    onChange={(e) => this.handleChapterTitleChange(undefined, i, e)}
                    value={chapter.titleEdit}
                    style={{ width: '300px' }}
                    autoFocus={true}
                  />
                </div>
                : <span>
                  {chapter.title && chapter.title.trim().length > 0 ?
                    chapter.title :
                    <span style={{ color: '#ddd' }}>Sans titre</span>
                  }
                  {!inEditMode ?
                    <button
                      title="Modifier le titre"
                      className="ui mini icon orange button"
                      style={{ marginRight: '5px', float: 'right' }}
                      onClick={() => this.startEditChapter(undefined, i)}
                    >
                      <i className="fa fa-edit" />
                    </button>
                    : undefined}
                </span>
              }
            </td>
            {inEditMode || this.state.structure.chapters.length > 1 ?
              <td>
                {inEditMode ?
                  (chapter.editMode ?
                    <div
                      className="ui one column stackable center aligned grid form"
                      style={{ marginBottom: '5px', marginTop: '10px' }}
                    >
                      <div className="inline fields">
                        <div className="field">
                          <button
                            className="ui blue mini button"
                            onClick={() => this.validEditChapter(undefined, i)}
                            type="submit"
                          >
                            Enregistrer
                        </button>
                        </div>
                        <div className="field">
                          <button
                            type="button"
                            className="ui mini button"
                            onClick={() => this.cancelEditChapter(undefined, i)}
                          >
                            Annuler
                        </button>
                        </div>
                      </div>
                    </div>
                    : undefined)
                  : <span style={{ float: 'right' }}>
                    <button
                      title="Supprimer"
                      className="ui mini icon red button"
                      style={{ marginRight: '5px' }}
                      onClick={() => this.removeChapter(undefined, i)}
                    >
                      <i className="fa fa-remove" />
                    </button>
                    {i > 0 ?
                      <button
                        title="Monter"
                        className="ui mini icon blue button"
                        style={{ marginRight: '5px' }}
                        onClick={() => this.upChapter(undefined, i)}
                      >
                        <i className="fa fa-arrow-up">
                        </i>
                      </button>
                      : null}
                    {i < this.state.structure.chapters.length - 1 ?
                      <button
                        title="Descendre"
                        className="ui mini icon blue button"
                        style={{ marginRight: '5px' }}
                        onClick={() => this.downChapter(undefined, i)}
                      >
                        <i className="fa fa-arrow-down">
                        </i>
                      </button>
                      : null}
                  </span>
                }
              </td>
              : undefined}
          </tr>
        );
      });
    }

    return (
      <div>
        <ConfirmChangeStructure
          active={this.state.confirmChangeActive}
          confirmChange={this.confirmTwoLevelsChange}
          cancelChange={this.cancelTwoLevelsChange}
        />
        <div className="ui form">
          <div className="inline fields">
            <label>Type de structure</label>
            <div className="field">
              <Checkbox
                radio
                checked={!this.state.twoLevels}
                onClick={this.handleTwoLevelsChange}
                label="Chapitres (un niveau)"
              />
            </div>
            <div className="field">
              <Checkbox
                radio
                checked={this.state.twoLevels}
                onClick={this.handleTwoLevelsChange}
                label="Parties et chapitres (deux niveaux)"
              />
            </div>
          </div>
          <div className="field">
            <div className="ui basic segment"
              style={{ paddingBottom: '20px', maxHeight: '400px', overflowX: 'scroll', overflowY: 'scroll' }}>
              {this.state.twoLevels ?
                <div>
                  <table className="ui celled structured table">
                    <thead>
                      <tr>
                        <th>Partie</th>
                        <th>Chapitre</th>
                        {inEditMode
                          || this.state.structure.parts.length > 1
                          || this.state.structure.parts[0].chapters.length > 1 ?
                          <th></th>
                          : undefined}
                      </tr>
                    </thead>
                    <tbody>
                      {parts}
                    </tbody>
                  </table>
                  <div>
                    <button className="ui right floated mini button" onClick={this.newChapter}>
                      Ajouter un chapitre
                      </button>
                    <button className="ui right floated mini button" onClick={this.newPart}>
                      Ajouter une partie
                  </button>
                  </div>
                </div>
                :
                <div>
                  <table className="ui celled table">
                    <thead>
                      <tr>
                        <th>Chapitre</th>
                        {inEditMode
                          || this.state.structure.chapters.length > 1 ?
                          <th></th>
                          : undefined}
                      </tr>
                    </thead>
                    <tbody>
                      {chapters}
                    </tbody>
                  </table>
                  <button className="ui right floated small button" onClick={this.newChapter}>
                    Ajouter un chapitre
                    </button>
                </div>
              }
            </div>
          </div>
          <div style={{ float: 'right', marginTop: '10px' }}>
            <button className="ui blue mini button" onClick={this.createNewText}>Commencer !</button>
            <button className="ui mini button" onClick={() => this.props.afterSave(undefined)}>Annuler</button>
          </div>
        </div>
      </div>
    );
  }
}
