import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface SettingsPageEmailsProps {
  store: Store;
}

@inject('store') @observer
export default class SettingsPageEmails extends React.Component<SettingsPageEmailsProps, {}> {
  static fetchData(store: Store) {
    return store.loadSettingsEmails();
  }
  constructor(props: SettingsPageEmailsProps) {
    super(props);
    this.handleChangeCommunications = this.handleChangeCommunications.bind(this);
    this.handleChangeNewsletter = this.handleChangeNewsletter.bind(this);
    this.handleChangeTips = this.handleChangeTips.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  @action handleChangeCommunications() {
    this.props.store.mainSettings.emails.communicationsEmail =
      !this.props.store.mainSettings.emails.communicationsEmail;
  }
  @action handleChangeNewsletter() {
    this.props.store.mainSettings.emails.newsletterEmail =
      !this.props.store.mainSettings.emails.newsletterEmail;
  }
  @action handleChangeTips() {
    this.props.store.mainSettings.emails.tipsEmail =
      !this.props.store.mainSettings.emails.tipsEmail;
  }
  postForm(event: any) {
    event.preventDefault();
    this.props.store.saveSettingsEmails();
  }
  render() {
    const form = this.props.store.mainSettings.emails;
    const connected = this.props.store.connected;
    return (
      <div>
        {this.props.store.mainSettings.loading ?
          <Loader message="Chargement..." />
          :
          <form onSubmit={this.postForm} className="ui equal width form">
            <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.communicationsEmail}
                onClick={this.handleChangeCommunications}
                label="Communications Atelier des Auteurs"
                />
            </div>
            {connected && connected.premium && connected.premium.premium ?
              undefined
            : 
              <div className="field" style={{ maxWidth: '600px' }}>
                <Checkbox
                  checked={form.newsletterEmail}
                  onClick={this.handleChangeNewsletter}
                  label="Newsletter"
                  />
              </div>
            }
            <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.tipsEmail}
                onClick={this.handleChangeTips}
                label="Conseils d'écriture"
                />
            </div>
            <button className="ui submit primary right floated button"
              type="submit" style={{ marginTop: '25px' }}>
              Sauvegarder les modifications
            </button>
          </form>
        }
      </div>
    );
  }
}
