const forbiddenVerbs = {
  'être': [
    'être',
    'es',
    'est',
    'eu',
    'eurent',
    'eus',
    'eusse',
    'eussent',
    'eusses',
    'eussiez',
    'eussions',
    'eut',
    'eûmes',
    'eût',
    'eûtes',
    'furent',
    'fus',
    'fusse',
    'fussent',
    'fusses',
    'fussiez',
    'fussions',
    'fut',
    'fûmes',
    'fût',
    'fûtes',
    'ont',
    'sera',
    'serai',
    'seraient',
    'serais',
    'serait',
    'seras',
    'serez',
    'seriez',
    'serions',
    'serons',
    'seront',
    'soient',
    'sois',
    'soit',
    'sommes',
    'sont',
    'soyez',
    'soyons',
    'suis',
    'étaient',
    'étais',
    'était',
    'étiez',
    'étions',
    'êtes'
  ],
  avoir: [
    'avoir',
    'a',
    'a',
    'ai',
    'aie',
    'aient',
    'aies',
    'ait',
    'as',
    'aura',
    'aurai',
    'auraient',
    'aurais',
    'aurait',
    'auras',
    'aurez',
    'auriez',
    'aurions',
    'aurons',
    'auront',
    'avaient',
    'avais',
    'avait',
    'avez',
    'aviez',
    'avions',
    'avons',
    'ayez',
    'ayons',
    'eurent',
    'eus',
    'eusse',
    'eussent',
    'eusses',
    'eussiez',
    'eussions',
    'eut',
    'eûmes',
    'eût',
    'eûtes',
    'ont'
  ],
  pouvoir: [
    'pouvoir',
    'peux',
    'peut',
    'pouvons',
    'pouvez',
    'peuvent',
    'pu',
    'pouvais',
    'pouvait',
    'pouvions',
    'pouviez',
    'pouvaient',
    'pus',
    'put',
    'pûmes',
    'pûtes',
    'purent',
    'pourrai',
    'pourras',
    'pourra',
    'pourrons',
    'pourrez',
    'pourront',
    'pourrais',
    'pourrait',
    'pourrions',
    'pourriez',
    'pourraient',
    'puisse',
    'puisses',
    'puissions',
    'puissiez',
    'puissent',
    'pusse',
    'pusses',
    'pût',
    'pussions',
    'pussiez',
    'pussent',
    'pouvant'
  ],
  faire: [
    'faire',
    'fais',
    'faisaient',
    'faisais',
    'faisait',
    'faisiez',
    'faisions',
    'faisons',
    'fait',
    'faites',
    'fasse',
    'fassent',
    'fasses',
    'fassiez',
    'fassions',
    'fera',
    'ferai',
    'feraient',
    'ferais',
    'ferait',
    'feras',
    'ferez',
    'feriez',
    'ferions',
    'ferons',
    'feront',
    'firent',
    'fis',
    'fisse',
    'fissent',
    'fisses',
    'fissiez',
    'fissions',
    'fit',
    'font',
    'fîmes',
    'fît',
    'fîtes',
    'faisant'
  ],
  aller: [
    'aller',
    'vais',
    'vas',
    'va',
    'allons',
    'allez',
    'vont',
    'allé',
    'allés',
    'allés',
    'allés',
    'allais',
    'allait',
    'allions',
    'alliez',
    'allaient',
    'allai',
    'allas',
    'alla',
    'allâmes',
    'allâtes',
    'allèrent',
    'irai',
    'iras',
    'ira',
    'irons',
    'irez',
    'iront',
    'irais',
    'irait',
    'irions',
    'iriez',
    'iraient',
    'aille',
    'ailles',
    'allions',
    'alliez',
    'aillent',
    'allasse',
    'allasses',
    'allât',
    'allassions',
    'allassiez',
    'allassent',
    'allant',
  ],
  prendre: [
    'prendre',
    'prends',
    'prend',
    'prenons',
    'prenez',
    'prennent',
    'pris',
    'prenais',
    'prenait',
    'prenions',
    'preniez',
    'prenaient',
    'prit',
    'prîmes',
    'prîtes',
    'prirent',
    'prendrai',
    'prendras',
    'prendra',
    'prendrons',
    'prendrez',
    'prendront',
    'aurons pris',
    'aurez pris',
    'auront pris',
    'prendrais',
    'prendrait',
    'prendrions',
    'prendriez',
    'prendraient',
    'prenne',
    'prennes',
    'prenions',
    'preniez',
    'prennent',
    'prisse',
    'prisses',
    'prît',
    'prissions',
    'prissiez',
    'prissent',
    'prenant',
  ],
  devoir: [
    'dois',
    'doit',
    'devons',
    'devez',
    'doivent',
    'dû',
    'devais',
    'devais',
    'devait',
    'devions',
    'deviez',
    'devaient',
    'dus',
    'dus',
    'dut',
    'dûmes',
    'dûtes',
    'durent',
    'devrai',
    'devras',
    'devra',
    'devrons',
    'devrez',
    'devront',
    'devrais',
    'devrait',
    'devrions',
    'devriez',
    'devraient',
    'doive',
    'doives',
    'devions',
    'deviez',
    'doivent',
    'dusse',
    'dusses',
    'dût',
    'dussions',
    'dussiez',
    'dussent',
    'devant'
  ],
  voir: [
    'voir',
    'vois',
    'voit',
    'voyons',
    'voyez',
    'voient',
    'vu',
    'voyais',
    'voyait',
    'voyions',
    'voyiez',
    'voyaient',
    'vis',
    'vit',
    'vîmes',
    'vîtes',
    'virent',
    'verrai',
    'verras',
    'verra',
    'verrons',
    'verrez',
    'verront',
    'verrais',
    'verrait',
    'verrions',
    'verriez',
    'verraient',
    'voie',
    'voies',
    'voyions',
    'voyiez',
    'voient',
    'visse',
    'visses',
    'vît',
    'vissions',
    'vissiez',
    'vissent',
    'voyant'
  ],
  vouloir: [
    'vouloir',
    'veux',
    'veut',
    'voulons',
    'voulez',
    'veulent',
    'voulu',
    'voulais',
    'voulais',
    'voulait',
    'voulions',
    'vouliez',
    'voulaient',
    'voulus',
    'voulut',
    'voulûmes',
    'voulûtes',
    'voulurent',
    'voudrai',
    'voudras',
    'voudra',
    'voudrons',
    'voudrez',
    'voudront',
    'voudrais',
    'voudrait',
    'voudrions',
    'voudriez',
    'voudraient',
    'veuille',
    'veuilles',
    'voulions',
    'vouliez',
    'veuillent',
    'voulusse',
    'voulusses',
    'voulût',
    'voulussions',
    'voulussiez',
    'voulussent',
    'voulant'
  ],
  mettre: [
    'mettre',
    'mets',
    'met',
    'mettons',
    'mettez',
    'mettent',
    'mis',
    'mettais',
    'mettait',
    'mettions',
    'mettiez',
    'mettaient',
    'mit',
    'mîmes',
    'mîtes',
    'mirent',
    'mettrai',
    'mettras',
    'mettra',
    'mettrons',
    'mettrez',
    'mettront',
    'mettrais',
    'mettrait',
    'mettrions',
    'mettriez',
    'mettraient',
    'mette',
    'mettes',
    'mettions',
    'mettiez',
    'mettent',
    'misse',
    'misses',
    'mît',
    'missions',
    'missiez',
    'missent',
    'mettant',
  ],
  dire: [
    'dire',
    'dira',
    'dirai',
    'diraient',
    'dirais',
    'dirait',
    'diras',
    'dire',
    'dirent',
    'direz',
    'diriez',
    'dirions',
    'dirons',
    'diront',
    'dis',
    'disaient',
    'disais',
    'disait',
    'disant',
    'dise',
    'disent',
    'dises',
    'disiez',
    'disions',
    'disons',
    'disse',
    'dissent',
    'disses',
    'dissiez',
    'dissions',
    'dit',
    'dites',
    'dîmes',
    'dît',
    'dîtes'
  ],
  savoir: [
    'savoir',
    'sais',
    'sait',
    'savons',
    'savez',
    'savent',
    'su',
    'savais',
    'savait',
    'savions',
    'saviez',
    'savaient',
    'sus',
    'sut',
    'sûmes',
    'sûtes',
    'surent',
    'saurai',
    'sauras',
    'saura',
    'saurons',
    'saurez',
    'sauront',
    'saurais',
    'saurait',
    'saurions',
    'sauriez',
    'sauraient',
    'sache',
    'saches',
    'sachions',
    'sachiez',
    'sachent',
    'susse',
    'susses',
    'sût',
    'sussions',
    'sussiez',
    'sussent',
    'sachons',
    'sachez',
    'sachant'
  ]
};

export default forbiddenVerbs; 
