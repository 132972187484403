import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import Loader from '../../../misc/Loader';
import { Line } from 'react-chartjs-2';

interface LengthProps {
  store?: Store;
  chapterId?: number;
}

@inject('store') @observer
export default class Length extends React.Component<LengthProps, {}> {
  render() {
    const currentText = this.props.store.write.currentText;
    const statistics = currentText.statisticsWrite;
    const rows = statistics.map((statistic) => {
      if (statistic.stat && statistic.stat.lengthStatistics !== undefined && statistic.stat.lengthStatistics !== null && statistic.exists && statistic.chapter.title !== "") {
        return (
          <tr key={`stat_length_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId == statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td className="right aligned">
              <span>{statistic.stat.lengthStatistics.avgSentencesPerParagraph}</span>
            </td>
            <td className="right aligned">
              <span>{statistic.stat.lengthStatistics.avgWordsPerSentence}</span>
            </td>
            <td className="right aligned">
              <span>{Math.round(statistic.stat.lengthStatistics.avgCharactersPerWord * 10) / 10}</span>
            </td>
          </tr>
        );
      } else if (statistic.chapter.title !== ""){
        return (
          <tr key={`stat_length_${statistic.chapter.id}`}
            className={this.props.chapterId && this.props.chapterId === statistic.chapter.id ? 'active' : ''}
          >
            <td>
              <a href={`/text/${currentText.id}/${currentText.infos.urlText}/chapter/${statistic.chapter.id}/edit`}
                target="_blank" rel="noopener noreferrer" className="item">
                {statistic.chapter.title}
              </a>
            </td>
            <td colSpan={3}>
              {statistic.stat && statistic.exists ?
                <span><span className="ui small active inline loader" style={{ marginRight: '10px' }} /> Calcul en cours...</span>
                : <span>-</span>
              }
            </td>
          </tr>
        );
      }
      else {
        return(undefined)
      }
    });
    const stillLoading = statistics.filter(stat => (!stat.stat || stat.stat.lengthStatistics == null || stat.stat.lengthStatistics === null) && (stat.stat && stat.exists)).length > 0;
    const withValues = statistics.filter(stat => stat.stat && stat.stat.lengthStatistics && stat.exists).length > 0;
    if (!stillLoading) {
      const values = statistics.filter(stat => stat.stat && stat.stat.lengthStatistics && stat.exists);
      if (values.length > 0) {
        const sumAvgSentencesPerParagraph = values.map(s => s.stat.lengthStatistics.avgSentencesPerParagraph).reduce((a, b) => a + b);
        const sumAvgWordsPerSentence = values.map(s => s.stat.lengthStatistics.avgWordsPerSentence).reduce((a, b) => a + b);
        const sumAvgCharactersPerWord = values.map(s => s.stat.lengthStatistics.avgCharactersPerWord).reduce((a, b) => a + b);
        rows.push(
          <tr key="stat_length_total">
            <td><b>Moyenne</b></td>
            <td className="right aligned">
              <span><b>{Math.round(sumAvgSentencesPerParagraph / values.length)}</b></span>
            </td>
            <td className="right aligned">
              <span><b>{Math.round(sumAvgWordsPerSentence / values.length)}</b></span>
            </td>
            <td className="right aligned">
              <span><b>{Math.round(sumAvgCharactersPerWord / values.length * 10) / 10}</b></span>
            </td>
          </tr>
        );
      } else {
        rows.push(
          <tr key="stat_dur_total">
            <td><b>Moyenne</b></td>
            <td colSpan={3}>
              -
            </td>
          </tr>
        );
      }
    }

    let data = {};
    let options = {};
    if (!stillLoading && withValues) {
      const filteredStats = statistics.filter(stat => stat.stat && stat.stat.lengthStatistics && stat.exists && stat.chapter.title !== "")
      const labels = filteredStats.map(stat => `${stat.chapter.title.substring(0, 25)}`);
      const avgSentencesPerParagraph = filteredStats.map(stat => stat.stat.lengthStatistics.avgSentencesPerParagraph);
      const avgWordsPerSentence = filteredStats.map(stat => stat.stat.lengthStatistics.avgWordsPerSentence);
      const avgCharactersPerWord = filteredStats.map(stat => stat.stat.lengthStatistics.avgCharactersPerWord);
      data = {
        labels: labels,
        datasets: [
          {
            label: 'Phrases par paragraphe',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            fill: false,
            data: avgSentencesPerParagraph
          },
          {
            label: 'Mots par phrase',
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            fill: false,
            data: avgWordsPerSentence
          },
          {
            label: 'Caractères par mot',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            fill: false,
            data: avgCharactersPerWord
          }
        ]
      };
    }

    return (
      <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
        <div className="seven wide column" style={{ paddingBottom: '80px' }}>
          <div className="ui grid centered stackable">
            <div className="twelve wide column">
              <table className="ui center aligned celled table">
                <thead>
                  <tr>
                    <th>-</th>
                    <th>Phrases par paragraphe</th>
                    <th>Mots par phrase</th>
                    <th>Caractères par mot</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
              <div className="ui info message">
                <div className="header">Pourquoi cet indicateur est important</div>
                Les longueurs moyennes de vos paragraphes, de vos phrases et de vos mots sont des indicateurs de l'accessibilité de vos écrits. Des paragraphes trop longs peuvent réduire la lisibilité de votre récit, par exemple. Bien entendu, ces aspects dépendent du style et des choix propres à chaque auteur.
              </div>
            </div>
          </div>
        </div>
        <div className="nine wide column">
          <div className="ui grid stackable">
            <div className="fourteen wide column" style={{ textAlign: 'left', paddingTop: '45px' }}>
              {stillLoading ?
                <Loader message="Calcul des statistiques en cours..." />
                : (withValues ?
                  <Line data={data} options={options} />
                  : <div className="ui message">
                    Pas de graphique (chapitres vides)
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
