const fs = require('fs');

if (fs.readFileSync) {
  const path3 = require('path');

  const adj = JSON.parse(fs.readFileSync(path3.join('./dict', 'adj.json'), 'utf8'));
  const adv = JSON.parse(fs.readFileSync(path3.join('./dict', 'adv.json'), 'utf8'));
  const art = JSON.parse(fs.readFileSync(path3.join('./dict', 'art.json'), 'utf8'));
  const con = JSON.parse(fs.readFileSync(path3.join('./dict', 'conj.json'), 'utf8'));
  const nom = JSON.parse(fs.readFileSync(path3.join('./dict', 'nom.json'), 'utf8'));
  const ono = JSON.parse(fs.readFileSync(path3.join('./dict', 'ono.json'), 'utf8'));
  const pre = JSON.parse(fs.readFileSync(path3.join('./dict', 'pre.json'), 'utf8'));
  const ver = JSON.parse(fs.readFileSync(path3.join('./dict', 'ver.json'), 'utf8'));
  const pro = JSON.parse(fs.readFileSync(path3.join('./dict', 'pro.json'), 'utf8'));

  const adjIndex = adj.lexi.map((w: any) => w.word);
  const advIndex = adv.lexi.map((w: any) => w.word);
  const nomIndex = nom.lexi.map((w: any) => w.word);
  const verIndex = ver.lexi.map((w: any) => w.word);

  module.exports = {
    all: {
      adj,
      adjIndex,
      adv,
      advIndex,
      art,
      con,
      nom,
      nomIndex,
      ono,
      pre,
      ver,
      verIndex,
      pro
    }
  };
}
