import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../../../stores/Store';
import StructureModel from '../../../../models/write/StructureModel';
import TextStructureForm from '../../TextStructureForm';
import TextWithParcoursActiveStructure from '../../TextWithParcoursActiveStructure';

interface StructureProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  textId: number;
  urlText: string;
  published: boolean;
}

@inject('store') @observer
export default class Structure
  extends React.Component<StructureProps, {}> {
  constructor(props: StructureProps) {
    super(props);
    this.reloadStructure = this.reloadStructure.bind(this);
  }
  reloadStructure() {
    return this.props.store.loadTextStructure(this.props.textId);
  }
  render() {
    const text = this.props.store.write.currentText;
    const premium = this.props.store.connected && this.props.store.connected.premium.premium;
    return (
      <div>
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">Structure</div>
          <div className="content" style={{ paddingBottom: '50px' }}>
            {premium && text.infos.parcours && text.infos.parcours.active ?
              <TextWithParcoursActiveStructure
                afterSave={this.props.hide}
                textId={this.props.textId}
                urlText={this.props.urlText}
                structure={text.structure as any}
                parcours={text.infos.parcours}
                scrollable
              />
              :
              <TextStructureForm
                textId={this.props.textId}
                urlText={this.props.urlText}
                published={this.props.published}
                structure={text.structure as StructureModel}
                reload={this.reloadStructure}
                scrollable
              />
            }
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
        </Modal>
      </div>
    );
  }
}
