import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Store from '../../stores/Store';
import NewDraftModel from '../../models/write/NewDraftModel';
import DefiRemove from './DefiRemove';

interface DefiPageProps {
  store?: Store;
}

interface DefiPageState {
  activeRemoveDefiModal: boolean;
  activeConfirmInterpretation: boolean;
  title: string;
}

@inject('store') @observer
export default class DefiPageAuthor extends React.Component<DefiPageProps, DefiPageState> {
  constructor(props: DefiPageProps) {
    super(props);
    this.state = {
      activeRemoveDefiModal: false,
      activeConfirmInterpretation: false,
      title: ''
    };
    this.openDefi = this.openDefi.bind(this);
    this.closeDefi = this.closeDefi.bind(this);
    this.showRemoveDefiModal = this.showRemoveDefiModal.bind(this);
    this.cancelDefiModal = this.cancelDefiModal.bind(this);
    this.removeDefi = this.removeDefi.bind(this);
    this.giveInterpretationDefi = this.giveInterpretationDefi.bind(this);
    this.confirmGiveInterpretationDefi = this.confirmGiveInterpretationDefi.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
  }
  openDefi() {
    this.props.store.openDefi();
  }
  closeDefi() {
    this.props.store.closeDefi();
  }
  giveInterpretationDefi() {
    this.setState({
      activeConfirmInterpretation: true,
      title: ''
    });
  }
  confirmGiveInterpretationDefi() {
    this.props.store.giveInterpretationDefi(this.state.title).then((draft: NewDraftModel) => {
      browserHistory.push({
        pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
        state: {
          newPart: false,
          showCongratulationsChapter: false
        }
      });
    });
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  showRemoveDefiModal() {
    this.setState({ activeRemoveDefiModal: true });
  }
  cancelDefiModal() {
    this.setState({
      activeRemoveDefiModal: false,
      activeConfirmInterpretation: false,
      title: ''
    });
  }
  removeDefi() {
    this.props.store.removeDefi().then(() => {
      browserHistory.push({
        pathname: '/defis'
      });
    });
  }
  render() {
    const defi = this.props.store.mainDefi.defi;
    let reponses: any[];
    if (defi.inprogress) {
      reponses = defi.reponsesObj.map((r: any) => {
        return (
          <li key={`resp_${Math.random() * 1000}`}>
            <Link to={`/author/${r.author.id}/${r.author.urlIdentity}`}>{r.author.identity}</Link> (jusqu'au {r.deadline})
          </li>
        );
      });
    }

    return (
      <div className="ui stackable center aligned basic segment container">
        <div className="ui message warning">
          {defi.available ?
            <span>Ce défi est <b>disponible</b>.</span>
            : <span>Ce défi est <b>indisponible</b>.</span>
          }
          {defi.inprogress ?
            <div style={{ textAlign: 'left' }}>
              Ce défi est actuellement relevé par :
              <ul>
                {reponses}
              </ul>
            </div>
            : undefined}
        </div>
        <div className="ui stackable secondary menu">
          <button onClick={() => browserHistory.push({
            pathname: `/defis/defi/${defi.id}/${defi.urlTitle}/edit`
          })} className="ui fluid button">
            Modifier
            </button>
          {defi.available ?
            <button className="ui fluid orange button" onClick={this.closeDefi}>Désactiver</button>
            : <button className="ui fluid green button" onClick={this.openDefi}>Activer</button>}
          {!defi.inprogress && defi.reponsesObj.length === 0 && defi.texts.length === 0 ?
            <button className="ui fluid red button" onClick={this.showRemoveDefiModal}>
              Supprimer
            </button>
            : undefined}
          {!defi.textExample ?
            <button className="ui fluid button" onClick={this.giveInterpretationDefi}>
              Donner mon interprétation du défi
            </button>
            : undefined}
        </div>
        <DefiRemove
          delete={false}
          modalActive={this.state.activeRemoveDefiModal}
          handleRemove={this.removeDefi}
          hideModal={this.cancelDefiModal}
        />
        <Modal
          open={this.state.activeConfirmInterpretation}
          onClose={() => { }}
          size="small"
        >
          <div className="header">Confirmation</div>
          <div className="content">
            <div>
              <div className="ui form">
                <div className="ui info message">
                  Vous avez la possibilité de donner votre interprétation de ce défi en publiant vous aussi un texte en réponse.
                    </div>
                <div className="field">
                  <label>Quel sera le titre de votre œuvre ?</label>
                  <input
                    type="text"
                    onChange={this.handleTitleChange}
                    placeholder={`Réponse à "${defi.title}"`}
                    value={this.state.title}
                  />
                  <div className="ui message">
                    Si vous ne connaissez pas encore le titre, laissez le champ vide, vous pourrez y revenir plus tard !
                      </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <button className="ui blue approve button" onClick={this.confirmGiveInterpretationDefi}>
              Commencer le défi
            </button>
            <button className="ui cancel button" onClick={this.cancelDefiModal}>Annuler</button>
          </div>
        </Modal>
      </div >
    );
  }
}
