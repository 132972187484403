import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../stores/Store';

interface ResetPasswordParams {
  email: string;
  token: string;
}

interface ResetPasswordProps {
  store?: Store;
  params: ResetPasswordParams;
}

interface ResetPasswordState {
  password: string;
  passwordError: boolean;
  confirmPassword: string;
  confirmPasswordError: boolean;
  matchingPasswordsError: boolean;
  unknownError: boolean;
  sent: boolean;
}

@inject('store') @observer
export default class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      password: '',
      passwordError: false,
      confirmPassword: '',
      confirmPasswordError: false,
      matchingPasswordsError: false,
      unknownError: false,
      sent: false
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  handlePasswordChange(e: any) {
    const passwordError = this.state.passwordError && e.target.value.trim().length === 0;
    this.setState({
      password: e.target.value,
      passwordError: passwordError,
      confirmPassword: this.state.confirmPassword,
      confirmPasswordError: this.state.confirmPasswordError,
      matchingPasswordsError: false,
      unknownError: this.state.unknownError,
      sent: this.state.sent
    });
  }
  handleConfirmPasswordChange(e: any) {
    const confirmPasswordError = this.state.confirmPasswordError && e.target.value.trim().length === 0;
    this.setState({
      password: this.state.password,
      passwordError: this.state.passwordError,
      confirmPassword: e.target.value,
      confirmPasswordError: confirmPasswordError,
      matchingPasswordsError: false,
      unknownError: this.state.unknownError,
      sent: this.state.sent
    });
  }
  postForm(event: any) {
    event.preventDefault();
    const passwordError = this.state.password.trim().length === 0;
    const confirmPasswordError = this.state.confirmPassword.trim().length === 0;
    const matchingPasswordsError = !passwordError && !confirmPasswordError &&
      this.state.password !== this.state.confirmPassword;

    if (!passwordError && !confirmPasswordError && !matchingPasswordsError) {
      const params = this.props.params;
      this.props.store.resetPassword(params.email, params.token, this.state.password).then((r: any) => {
        this.setState({
          password: this.state.password,
          passwordError: this.state.passwordError,
          confirmPassword: this.state.confirmPassword,
          confirmPasswordError: this.state.confirmPasswordError,
          matchingPasswordsError: this.state.matchingPasswordsError,
          sent: true,
          unknownError: false
        });
      }, (err: any) => {
        this.setState({
          password: this.state.password,
          passwordError: this.state.passwordError,
          confirmPassword: this.state.confirmPassword,
          confirmPasswordError: this.state.confirmPasswordError,
          matchingPasswordsError: this.state.matchingPasswordsError,
          unknownError: true,
          sent: this.state.sent
        });
      });
    } else {
      this.setState({
        password: this.state.password,
        passwordError: passwordError,
        confirmPassword: this.state.confirmPassword,
        confirmPasswordError: confirmPasswordError,
        matchingPasswordsError: matchingPasswordsError,
        unknownError: this.state.unknownError,
        sent: this.state.sent
      });
    }
  }
  render() {
    return (
      <div className="ui container">
        <Title title="Réinitialiser le mot de passe " />
        <h1 className="ui header">Nouveau mot de passe</h1>
        {this.state.sent ?
          <div className="ui basic segment">
            <p>Votre mot de passe est modifié ! Vous pouvez maintenant dès à présent vous connecter.</p>
            <Link to="/login" className="ui submit primary right floated button">
              Connexion
            </Link>
          </div>
          : <div className="ui basic segment">
            <form onSubmit={this.postForm} className="ui form">
              {this.state.passwordError || this.state.confirmPasswordError ?
                <div className="ui error message">
                  <div className="header">Les champs marqués en rouge sont obligatoires.</div>
                </div>
                : (this.state.matchingPasswordsError ?
                  <div className="ui error message">
                    <div className="header">Le mot de passe et sa confirmation sont différents.</div>
                  </div>
                  : (this.state.unknownError ?
                    <div className="ui error message">
                      <div className="header">Une erreur s'est produite. Veuillez réessayer.</div>
                    </div>
                    : undefined
                  )
                )
              }
              <div className={'field' + (this.state.passwordError ? ' error' : '')}>
                <label>Mot de passe</label>
                <input
                  type="password"
                  placeholder="Mot de passe"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  />
              </div>
              <div className={'field' + (this.state.confirmPasswordError ? ' error' : '')}>
                <label>Confirmez votre mot de passe</label>
                <input
                  type="password"
                  placeholder="Confirmation du mot de passe"
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  />
              </div>
              <button className="ui submit primary right floated button" type="submit">Valider</button>
            </form>
          </div>
        }
      </div>
    );
  }
}
