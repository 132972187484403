import * as React from 'react';
import Title from '../misc/Title';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Store from '../../stores/Store';
import RelatedTalkModel from '../../models/talk/RelatedTalkModel';
import TalkPageForm from '../talk/TalkPageForm';
import TextTalkParams from './TextTalkParams';
import Loader from '../misc/Loader';

interface EditTextTalkPageProps {
  store?: Store;
  params: TextTalkParams;
}

@inject('store') @observer
export default class EditTextTalkPage extends React.Component<EditTextTalkPageProps, {}> {
  static fetchData(store: Store, params: TextTalkParams) {
    const p: any[] = [];
    p.push(store.loadMeta(params.textId));
    p.push(store.loadTalk(params.talkId, params.textId, undefined));
    return Promise.all(p);
  }
  constructor(props: EditTextTalkPageProps) {
    super(props);
    this.updateDiscussion = this.updateDiscussion.bind(this);
  }
  updateDiscussion(title: string, message: string, tags: string[], linked: string[]) {
    const meta = this.props.store.text.meta;
    this.props.store.updateDiscussion(
      this.props.params.talkId, title, message, tags, linked, this.props.params.textId, undefined
    ).then((t: RelatedTalkModel) => {
      browserHistory.push({
        pathname: `/text/${meta.id}/${meta.urlText}/talks/${t.id}/${t.urlTitle}`
      });
    });
  }
  render() {
    const meta = this.props.store.text.meta;
    const talk = this.props.store.mainTalk;
    return (
      <div className="ui text container" style={{ paddingBottom: '80px' }}>
        {meta && talk && talk.talk && talk.talk.id ?
          <div>
            <Title title={`Modifier la discussion ${talk.talk.title} `} />
            <h2 className="ui header">Modifier la discussion <i>{talk.talk.title}</i></h2>
            <TalkPageForm submit={this.updateDiscussion} talk={talk.talk}
              linkedTalks={talk.linkedTalks} text={{ id: meta.id, urlText: meta.urlText }} />
          </div>
          : <Loader message="Chargement..." />
        }
      </div>
    );
  }
}
