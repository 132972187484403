import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const DropzoneComponent = require('react-dropzone-component');
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

interface ProfileAvatarProps {
  store?: Store;
}

interface ProfileAvatarState {
  activeAvatarModal: boolean;
  loading: boolean;
  replaceFacebookImage: boolean;
  replaceImageWithFacebook: boolean;
}

declare const dropzone: any;
declare const file: any;

@inject('store') @observer
export default class ProfileAvatar extends React.Component<ProfileAvatarProps, ProfileAvatarState> {
  constructor(props: ProfileAvatarProps) {
    super(props);
    this.showAvatarModal = this.showAvatarModal.bind(this);
    this.hideAvatarModal = this.hideAvatarModal.bind(this);
    this.handleAddAvatar = this.handleAddAvatar.bind(this);
    this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
    this.saveAvatar = this.saveAvatar.bind(this);
    this.replaceFacebookImage = this.replaceFacebookImage.bind(this);
    this.replaceImageWithFacebook = this.replaceImageWithFacebook.bind(this);
    this.state = {
      activeAvatarModal: false,
      loading: false,
      replaceFacebookImage: false,
      replaceImageWithFacebook: false
    };
  }
  showAvatarModal() {
    this.props.store.profile.user.avatarTemp = this.props.store.profile.user.avatar;
    this.setState({
      loading: false,
      activeAvatarModal: true,
      replaceFacebookImage: false,
      replaceImageWithFacebook: false
    });
  }
  hideAvatarModal() {
    this.setState({
      loading: false,
      activeAvatarModal: false,
      replaceFacebookImage: false,
      replaceImageWithFacebook: false
    });
  }
  @action
  handleAddAvatar(f: File) {
    this.props.store.profile.user.avatarFileTemp = f;
  }
  @action
  handleRemoveAvatar() {
    this.props.store.profile.user.avatarTemp = undefined;
    this.props.store.profile.user.avatarFileTemp = undefined;
  }
  saveAvatar() {
    this.setState({
      loading: true,
      activeAvatarModal: true,
      replaceFacebookImage: this.state.replaceFacebookImage,
      replaceImageWithFacebook: this.state.replaceImageWithFacebook
    });
    this.props.store.updateProfileAvatar(this.state.replaceImageWithFacebook).then((d: any) => {
      this.hideAvatarModal();
    });
  }
  replaceFacebookImage() {
    this.setState({
      loading: this.state.loading,
      activeAvatarModal: this.state.activeAvatarModal,
      replaceFacebookImage: true,
      replaceImageWithFacebook: false
    });
  }
  replaceImageWithFacebook() {
    this.props.store.profile.user.avatarTemp =
      `https://graph.facebook.com/${this.props.store.profile.user.facebookUser}/picture?width=150&height=150`;
    this.setState({
      loading: this.state.loading,
      activeAvatarModal: this.state.activeAvatarModal,
      replaceFacebookImage: false,
      replaceImageWithFacebook: true
    });
  }
  render() {
    const that = this;
    const profile = this.props.store.profile;
    const connected = this.props.store.connected;
    const isAuthor = profile && profile.user
      && connected && connected.user && connected.user.id
      && connected.user.id === profile.user.id;
    const componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.gif'],
      showFiletypeIcon: true,
      postUrl: 'no-url',
      maxFiles: 1,
    };
    const eventHandlers = {
      init(dropzone: any) {
        if (profile.user.avatarTemp && profile.user.avatarTemp.indexOf('http') === - 1) {
          const mockFile = { name: 'Image de profil', size: 30000 };
          dropzone.emit('addedfile', mockFile);
          dropzone.emit('thumbnail', mockFile,
            `https://s3-eu-west-1.amazonaws.com/scribay/avatars/${profile.user.avatarTemp}.jpg`
          );
          dropzone.emit('complete', mockFile);
          dropzone.options.maxFiles = 1;
        }
      },
      addedfile: that.handleAddAvatar
    };
    const djsConfig = {
      maxFiles: (profile.user.avatarTemp ? 0 : 1),
      acceptedFiles: 'image/jpg,image/jpeg,image/png,image/gif',
      thumbnailWidth: 100,
      thumbnailHeight: 160,
      autoProcessQueue: false,
      resizefile() {
        const resizeInfo = {
          srcX: 0,
          srcY: 0,
          trgX: 0,
          trgY: 0,
          srcWidth: file.width,
          srcHeight: file.height,
          trgWidth: this.options.thumbnailWidth,
          trgHeight: this.options.thumbnailHeight,
        };
        return resizeInfo;
      },
      reset(f: any) {
        that.handleRemoveAvatar();
      },
      uploadMultiple: false,
      addRemoveLinks: true,
      dictDefaultMessage: 'Cliquez ici ou glissez-déposez des fichiers dans cet emplacement.',
      dictFallbackMessage: 'Votre navigateur ne supporte pas le glisser-déposer.',
      dictRemoveFile: 'Supprimer',
      dictCancelUpload: 'Annuler',
      dictCancelUploadConfirmation: 'Confirmez-vous l\'arrêt du transfert ?',
      dictMaxFilesExceeded: 'Une seule photo de profil maximum est autorisée.',
    };
    return (
      <div className="ui image">
        {isAuthor ?
          <Modal
            open={this.state.activeAvatarModal}
          >
            <div className="header">Image de profil</div>
            {this.state.loading ?
              <Loader message="Chargement..." />
              : <div className="content">
                {profile.user.facebookUser && !this.state.replaceFacebookImage ?
                  <div>
                    {profile.user.avatarTemp && profile.user.avatarTemp.indexOf('http') !== - 1 ?
                      <div className="ui info message">
                        Votre image de profil est votre photo de profil Facebook.
                        {!this.state.replaceImageWithFacebook ?
                          <div className="ui basic center aligned segment">
                            <button className="ui small orange button" onClick={this.replaceFacebookImage}>
                              Remplacer cette photo
                          </button>
                          </div>
                          : undefined}
                      </div>
                      : <div className="ui info message" style={{ marginBottom: '15px' }}>
                        {profile.user.avatarTemp ?
                          <div>
                            Votre image de profil est une image que vous avez importée.
                        </div>
                          :
                          <div>
                            Vous n'avez pas d'image de profil.
                          </div>
                        }
                        <div className="ui basic center aligned segment">
                          <button className="ui small orange button" onClick={this.replaceImageWithFacebook}>
                            {profile.user.avatarTemp ?
                              <span>Remplacer cette image par ma photo de profil Facebook</span>
                              : <span>Afficher ma photo de profil Facebook</span>
                            }
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                  : undefined
                }
                {!profile.user.facebookUser || !profile.user.avatarTemp ||
                  profile.user.avatarTemp.indexOf('http') === - 1 || this.state.replaceFacebookImage ?
                  <div>
                    <div className="ui message warning">
                      L'image doit être au format 150x150 pixels. Si elle ne respecte pas ce format, elle sera automatiquement redimensionnée.
                    </div>
                    <DropzoneComponent
                      key={profile.user.avatarTemp || Math.random() * 1000}
                      config={componentConfig}
                      eventHandlers={eventHandlers}
                      djsConfig={djsConfig}
                    />
                  </div>
                  : undefined}
              </div>
            }
            <div className="actions">
              <button className="ui blue approve button" onClick={this.saveAvatar}>
                Enregistrer
          </button>
              <button className="ui cancel button" onClick={this.hideAvatarModal}>
                Annuler
          </button>
            </div>
          </Modal>
          : undefined}
        {profile.user.avatar ?
          (profile.user.avatar.indexOf('http') !== - 1 ?
            <img
              alt={`Image de profil de ${profile.user.facebookUser}`}
              className="ui circular image"
              src={`https://graph.facebook.com/${profile.user.facebookUser}/picture?width=150&height=150`}
              style={{ borderRadius: '500rem' }}
            />
            :
            <img
              alt={`Image de profil de ${profile.user.facebookUser}`}
              className="ui circular image"
              src={`https://s3-eu-west-1.amazonaws.com/scribay/avatars/${profile.user.avatar}.jpg`}
              style={{ borderRadius: '500rem' }}
            />
          )
          : <img
            alt={`Image de profil de ${profile.user.facebookUser}`}
            className="ui circular image"
            src="https://s3-eu-west-1.amazonaws.com/scribay/assets/noavatar.png"
            style={{ borderRadius: '500rem' }}
          />
        }
        {isAuthor ?
          <button className="ui orange fluid label" onClick={this.showAvatarModal} style={{ marginTop: '10px' }}>
            {profile.user.avatar ? 'Modifier' : 'Ajouter une image de profil'}
          </button>
          : undefined}
      </div>
    );
  }
}
