import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import ParcoursPageParams from './ParcoursPageParams';
import ParcoursPageContent from './ParcoursPageContent';
import Title from '../../../misc/Title';

interface ParcoursPageProps {
    store?: Store;
    params: ParcoursPageParams;
  }
interface ParcoursPageState {
  enableComponents: boolean;
}


@inject('store') @observer
export default class DefiPage extends React.Component<ParcoursPageProps, ParcoursPageState> {
  constructor(props: ParcoursPageProps) {
    super(props);
    this.state = {
      enableComponents: false
    };
  }
  
  componentDidMount() {
    this.props.store.loadCurrentParcoursText(this.props.params.textId)
  }
  
  render() {
    const connected = this.props.store.connected;
    const pinnedPrologue = connected && connected.premium && connected.premium.pinnedPrologue;
    return (
      <div style={{ backgroundColor: '#fff', minHeight: '90vh'}}>
        <Title title="Parcours" scripts={[{
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            "@context": "http://schema.org",
            '@type': "Organization",
            "url": "https://www.atelierdesauteurs.com",
            "logo": {
              "@type": "ImageObject",
              "url": "https://scribay.s3.amazonaws.com/assets/logo_square.png"
            }
          })
        }]} />
          <ParcoursPageContent
/*               parcoursId={this.props.params.parcoursId}
 */              text={{ id: this.props.params.textId, title: this.props.params.urlText, chapter: undefined }}
                
              pinnedPrologue={pinnedPrologue}
              withHeader={true}
            />
      </div>
    );
  }
}



