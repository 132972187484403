import { observable } from 'mobx';
import UserModel from '../UserModel';
import LightStructureModel from './LightStructureModel';

export default class MetaTextModel {

  @observable id: number;
  @observable title: string;
  @observable urlText: string;
  @observable author: UserModel;
  @observable structure: LightStructureModel;
  @observable backgroundColor: string;
  @observable textColor: string;
  @observable linkColor: string;
  @observable sensibleContent: boolean;
  @observable openToComments: boolean;
  @observable openToExport: boolean;
  @observable openedToPublishers: boolean;
  @observable showOldVersions: boolean;
  @observable summary: string;
  @observable cover: string;
  @observable published: boolean;
  @observable inReadingList: boolean;
  @observable pub: boolean;
  @observable users: { label: string, value: string }[];

  @observable oldBackgroundColor: string;
  @observable oldTextColor: string;
  @observable oldLinkColor: string;

  constructor(id: number, title: string, urlText: string, author: UserModel, structure: LightStructureModel,
    backgroundColor: string, textColor: string, linkColor: string, sensibleContent: boolean,
    openToComments: boolean, openToExport: boolean, openedToPublishers: boolean, showOldVersions: boolean, summary: string, cover: string,
    published: boolean, inReadingList: boolean, pub: boolean, users: { label: string, value: string }[]) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.author = author;
    this.structure = structure;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.linkColor = linkColor;
    this.sensibleContent = sensibleContent;
    this.openToComments = openToComments;
    this.openToExport = openToExport;
    this.openedToPublishers = openedToPublishers;
    this.showOldVersions = showOldVersions;
    this.summary = summary;
    this.cover = cover;
    this.published = published;
    this.inReadingList = inReadingList;
    this.pub = pub;
    this.users = users;

    this.oldBackgroundColor = backgroundColor;
    this.oldTextColor = textColor;
    this.oldLinkColor = linkColor;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      author: this.author.toJS(),
      structure: this.structure.toJS(),
      backgroundColor: this.backgroundColor,
      textColor: this.textColor,
      linkColor: this.linkColor,
      sensibleContent: this.sensibleContent,
      openToComments: this.openToComments,
      openToExport: this.openToExport,
      openedToPublishers: this.openedToPublishers,
      showOldVersions: this.showOldVersions,
      summary: this.summary,
      cover: this.cover,
      published: this.published,
      inReadingList: this.inReadingList,
      pub: this.pub,
      users: this.users
    };
  }

  static fromJS(object: any) {
    if (object) {
      const structure = LightStructureModel.fromJS(object.structure);
      return new MetaTextModel(object.id, object.title, object.urlText, object.author,
        structure, object.backgroundColor, object.textColor, object.linkColor, object.sensibleContent,
        object.openToComments, object.openToExport, object.openedToPublishers, object.showOldVersions, object.summary, object.cover,
        object.published, object.inReadingList, object.pub, object.users);
    }
    return undefined;
  }
}
