import * as React from 'react';

import { Link } from 'react-router';
import UserModel from '../../models/UserModel';

interface RelationsProps {
  name?: string;
  count: number;
  users: UserModel[];
  seeAll?: any;
}

export default class Relations extends React.Component<RelationsProps, {}> {
  render() {
    let users: any[] = [];
    if (this.props.users) {
      users = this.props.users.map(u =>
        <div key={u.id} className="item">
          <img
            className="ui tiny rounded image"
            src={u.avatarUrl}
            alt={`Image de profil de ${u.identity}`}
          />
          <div className="content">
            <div className="header">
              <Link to={`/author/${u.id}/${u.urlIdentity}`}>
                {u.identity}
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={!this.props.seeAll ? 'ui basic segment' : 'column'}>
        {this.props.name ?
          <h2 className="ui section horizontal large divider">
            <Link
              to={this.props.seeAll}
              data-tooltip="Voir la liste complète" data-inverted="" data-position="bottom center"
            >
              {this.props.name}&nbsp;({this.props.count})
            </Link>
          </h2>
          : undefined
        }
        <div className="ui middle aligned list">
          {users}
        </div>
      </div>
    );
  }
}
