import { observable } from 'mobx';
import FullTextModel from '../FullTextModel';
import ReadingListLightModel from '../readinglist/ReadingListLightModel';

export default class ReadModel {

  @observable texts: FullTextModel[];
  @observable lists: ReadingListLightModel[];
  @observable popularTags: string[];
  @observable loading: boolean;
  @observable page: number;
  @observable end: boolean;

  constructor(texts: FullTextModel[], lists: ReadingListLightModel[], popularTags: string[],
    loading: boolean, page: number, end: boolean) {
    this.texts = texts || [];
    this.lists = lists || [];
    this.popularTags = popularTags || [];
    this.loading = loading;
    this.page = page;
    this.end = end;
  }

  setTexts(texts: FullTextModel[]) {
    if (this.page === 0) {
      this.texts = texts;
    } else {
      this.texts = this.texts.concat(texts);
    }
    this.page = this.page + 1;
    this.end = (texts.length < 12);
    this.loading = false;
  }

  resetTexts() {
    this.texts = [];
    this.loading = true;
    this.page = 0;
    this.end = false;
  }

  setLists(lists: ReadingListLightModel[]) {
    this.lists = lists;
  }

  setPopularTags(popularTags: string[]) {
    this.popularTags = popularTags;
  }

  toJS() {
    return {
      texts: this.texts.map(t => t.toJS()),
      lists: this.lists.map(l => l.toJS()),
      popularTags: this.popularTags,
      count: this.page,
      end: this.end
    };
  }

  static fromJS(object: any) {
    if (object) {
      const texts = object.texts.map((t: any) => FullTextModel.fromJS(t));
      const lists = object.lists.map((l: any) => ReadingListLightModel.fromJS(l));
      return new ReadModel(texts, lists, object.popularTags, object.loading, object.count, object.end);
    }
    return undefined;
  }
}
