import { observable } from 'mobx';
import ParcoursQuestionModel from './ParcoursQuestionModel';
import ParcoursSummaryModel from './ParcoursSummaryModel';
import ParcoursSummaryExampleModel from './ParcoursSummaryExampleModel';
import VideoModel from '../write/VideoModel';

export default class ParcoursModel {

  @observable id: number;
  @observable name: string;
  @observable projectName: boolean;
  @observable description: string;
  @observable shortDescription: string;
  @observable image: string;
  @observable active: boolean;
  @observable marketIdentifier: number;
  @observable parcoursPrice: number;
  @observable stripeIdentifier: string;  
  @observable parcoursOrder: number;
  @observable titleDescription: string;
  @observable objective: string;
  @observable estimatedLength: string;
  @observable videosDescription: string;
  @observable authorsLink: string;
  @observable authorsDescription: string;
  @observable marketDescription: string;
  @observable currentPrice: number;
  @observable promotionPercentage: number;
  @observable promoCode: string;
  @observable parcoursVideoUrl: string;
  @observable shortName: string;
  @observable shortImage: string;

  @observable questions: ParcoursQuestionModel[];
  @observable filteredQuestions: ParcoursQuestionModel[];
  @observable currentQuestion: ParcoursQuestionModel;

  @observable videos: VideoModel[];
  @observable currentVideo: VideoModel;

  @observable summary: ParcoursSummaryModel;
  @observable summaryExamples: ParcoursSummaryExampleModel[];
  @observable currentSummary: boolean;

  constructor(id: number, name: string, description: string, image: string, active: boolean, marketIdentifier: number, 
    parcoursPrice: number, stripeIdentifier: string, parcoursOrder: number, titleDescription: string, 
    objective: string, estimatedLength: string, videosDescription: string, authorsLink: string, 
    authorsDescription: string, marketDescription: string, currentPrice: number, promotionPercentage: number, promoCode: string, parcoursVideoUrl: string,
    shortName: string, shortImage: string,
    questions: ParcoursQuestionModel[], currentQuestion: ParcoursQuestionModel,
    videos: VideoModel[], currentVideo: VideoModel,
    summary: ParcoursSummaryModel, summaryExamples: ParcoursSummaryExampleModel[], projectName?: boolean, shortDescription?: string) {
    this.id = id;
    this.name = name;
    this.projectName = projectName ? projectName : true;
    this.description = description;
    this.shortDescription = shortDescription ? shortDescription : "";
    this.image = image;
    this.active = active;
    this.marketIdentifier = marketIdentifier;
    this.parcoursPrice = parcoursPrice;
    this.stripeIdentifier = stripeIdentifier;
    this.parcoursOrder = parcoursOrder;
    this.titleDescription = titleDescription;
    this.objective = objective;
    this.estimatedLength = estimatedLength;
    this.videosDescription = videosDescription;
    this.authorsLink = authorsLink;
    this.authorsDescription = authorsDescription;
    this.marketDescription = marketDescription;
    this.currentPrice = currentPrice;
    this.promotionPercentage = promotionPercentage;
    this.promoCode = promoCode;
    this.parcoursVideoUrl = parcoursVideoUrl;
    this.shortName = shortName;
    this.shortImage = shortImage;
    this.questions = questions || [];
    this.filteredQuestions = ParcoursModel.filterQuestions(questions);
    this.currentQuestion = currentQuestion;
    this.videos = videos || [];
    this.currentVideo = currentVideo;
    this.summary = summary;
    this.summaryExamples = summaryExamples || [];
    this.currentSummary = false;
  }

  static filterQuestions(questions: ParcoursQuestionModel[]) {
    if (questions && questions.length > 0) {
      const allTriggers: { trigger: number, selected: boolean }[] =
        [].concat.apply([], questions.map(q => q.answers.map(a => {
          return { trigger: a.trigger, selected: q.answerId && q.answerId === a.id };
        })));
      return questions.filter(q => {
        return allTriggers.map(t => t.trigger).indexOf(q.id) === -1
          || allTriggers.filter(t => t.trigger === q.id && t.selected).length > 0;
      });
    } else {
      return [];
    };
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      projectName: this.projectName,
      description: this.description,
      shortDescription: this.shortDescription,
      image: this.image,
      active: this.active,
      marketIdentifier: this.marketIdentifier,
      parcoursPrice: this.parcoursPrice,
      stripeIdentifier: this.stripeIdentifier,
      parcoursOrder: this.parcoursOrder,
      titleDescription:this.titleDescription,
      objective: this.objective,
      estimatedLength:this.estimatedLength,
      videosDescription:this.videosDescription,
      authorsLink:this.authorsLink,
      authorsDescription:this.authorsDescription,
      marketDescription:this.marketDescription,
      currentPrice:this.currentPrice,
      promotionPercentage:this.promotionPercentage,
      promoCode: this.promoCode,
      parcoursVideoUrl: this.parcoursVideoUrl,
      shortName: this.shortName,
      shortImage: this.shortImage,
      questions: this.questions.map(q => q.toJS()),
      currentQuestion: this.currentQuestion.toJS(),
      videos: this.videos.map(v => v.toJS()),
      currentVideo: this.currentVideo.toJS(),
      summary: this.summary.toJS(),
      summaryExamples: this.summaryExamples.map(s => s.toJS())
    };
  }

  static fromJS(object: any) {
    if (object) {
      const questions = object.questions ? object.questions.map((q: any) => ParcoursQuestionModel.fromJS(q)) : [];
      const currentQuestion = ParcoursQuestionModel.fromJS(object.currentQuestion);
      const videos = object.videos ? object.videos.map((q: any) => VideoModel.fromJS(q)) : [];
      const currentVideo = VideoModel.fromJS(object.currentVideo);
      const summary = ParcoursSummaryModel.fromJS(object.summary);
      const summaryExamples = object.summaryExamples ? object.summaryExamples.map((q: any) => ParcoursSummaryExampleModel.fromJS(q)) : [];
      return new ParcoursModel(
        object.id, object.name, object.description, object.image, object.active, object.marketIdentifier, object.parcoursPrice,
        object.stripeIdentifier, object.parcoursOrder, object.titleDescription, object.objective, object.estimatedLength, 
        object.videosDescription, object.authorsLink, object.authorsDescription, object.marketDescription, object.currentPrice,
        object.promotionPercentage, object.promoCode, object.parcoursVideoUrl, object.shortName, object.shortImage, questions, currentQuestion, 
        videos, currentVideo, summary, summaryExamples, object.projectName,  object.shortDescription);
    }
    return undefined;
  }
}
