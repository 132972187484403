import * as bowser from 'bowser';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { browserHistory, Link } from 'react-router';
import Store from '../../stores/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup'


function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
    className="fa fa-angle-left hide-tablet ada-carousel-left"
      style={{ ...style, marginLeft: "-15px" }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="fa fa-angle-right hide-tablet ada-carousel-right"
      style={{ ...style, marginRight: "-15px" }}
      onClick={onClick}
    />
  );
}



interface ResponsiveAuthorsProps {
  showAuthorModal: Function | any;
}

export default class ResponsiveAuthors extends React.Component<ResponsiveAuthorsProps, {}> {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    var settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      arrows: true,
      autoplaySpeed: 3000,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    

    return (
      <div style={{marginTop: "30px", marginBottom: "75px", width: "96%"}}>
        
          <span className="hide-tablet">
            <Slider {...settings}>
            <div>
          <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/thilliez-carousel-2.jpg"
            alt="thilliez" width="100%"  onClick={() => this.props.showAuthorModal(1)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/soulas-carousel2.jpg"
              alt="soulas" width="100%"  onClick={() => this.props.showAuthorModal(6)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/lelait-carousel.jpg"
              alt="dixen" width="100%"  onClick={() => this.props.showAuthorModal(7)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/chauveau-carousel.jpg"
              alt="chaveau" width="100%"  onClick={() => this.props.showAuthorModal(9)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/villarroel-carousel2.jpg"
              alt="villarroel" width="100%"  onClick={() => this.props.showAuthorModal(2)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/bry-carousel.jpg"
              alt="david bry" width="100%" onClick={() => this.props.showAuthorModal(5)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/ponte-carousel.jpg"
              alt="ponte" width="100%"  onClick={() => this.props.showAuthorModal(10)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/galien-carousel2.jpg"
              alt="galien" width="100%"   onClick={() => this.props.showAuthorModal(8)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/dixen-carousel2.jpg"
              alt="dixen" width="100%"  onClick={() => this.props.showAuthorModal(3)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/benassaya-carousel.jpg"
              alt="benassaya" width="100%" onClick={() => this.props.showAuthorModal(4)}/>
          </div>
            </Slider>
          </span>
          <span className="hide-tablet-large">
            <Slider {...settings2}>
            <div>
          <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/thilliez-carousel-2.jpg"
            alt="thilliez" width="100%"  onClick={() => this.props.showAuthorModal(1)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/soulas-carousel2.jpg"
              alt="soulas" width="100%"  onClick={() => this.props.showAuthorModal(6)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/lelait-carousel.jpg"
              alt="dixen" width="100%"  onClick={() => this.props.showAuthorModal(7)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/chauveau-carousel.jpg"
              alt="chaveau" width="100%"  onClick={() => this.props.showAuthorModal(9)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/villarroel-carousel2.jpg"
              alt="villarroel" width="100%"  onClick={() => this.props.showAuthorModal(2)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/bry-carousel.jpg"
              alt="david bry" width="100%" onClick={() => this.props.showAuthorModal(5)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/ponte-carousel.jpg"
              alt="ponte" width="100%"  onClick={() => this.props.showAuthorModal(10)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/galien-carousel2.jpg"
              alt="galien" width="100%"   onClick={() => this.props.showAuthorModal(8)}/>
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/dixen-carousel2.jpg"
              alt="dixen" width="100%"  onClick={() => this.props.showAuthorModal(3)} />
          </div>
          <div>
            <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/benassaya-carousel.jpg"
              alt="benassaya" width="100%" onClick={() => this.props.showAuthorModal(4)}/>
          </div>
            </Slider>
          </span>
{/*         <div style={{ width: 240 }}>
 */}{/*           <div className="ada-carousel-bio">
 */}          {/* <img className="ada-carousel-bio" src="https://scribay.s3.amazonaws.com/assets/thilliez-carousel.png"
            alt="thilliez"  width="100%"  onClick={() => this.props.showAuthorModal(1)}/> */}
            {/* <span className="imgtext">
            <h1>THIS IS A TITLE</h1>
            <p>and this is a description</p>
          </span>
          </div> */}
{/*         </div>
 */}          
          
      </div>
    );
  }
}
