import * as React from 'react';
const CompositeDecorator = require('draft-js').CompositeDecorator;
import PopupWarning from './PopupWarning';
import { getVerbesTernesRegexp } from '../../../../../../utils/relecture/lisibilite';

function verbTerneStrategy(contentBlock: any, callback: any) {
  const text = contentBlock.getText();
  const regex = getVerbesTernesRegexp();
  let matchArr: RegExpExecArray;
  let start: number;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const verbeTerneSpan = (props: any) => (
  <PopupWarning
    body="Verbe terne"
    spanClass="write-warning"
    children={props.children}
    />
);

const decorator = new CompositeDecorator([
  {
    strategy: verbTerneStrategy,
    component: verbeTerneSpan,
  },
]);

export default decorator;
