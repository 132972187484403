import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';

declare const __CLIENT__: any;

interface SettingsPagePreferencesProps {
  store: Store;
}

@inject('store') @observer
export default class SettingsPagePreferences
  extends React.Component<SettingsPagePreferencesProps, {}> {
  static fetchData(store: Store) {
    return store.loadSettingsPreferences();
  }
  constructor(props: SettingsPagePreferencesProps) {
    super(props);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeFontSpaced = this.handleChangeFontSpaced.bind(this);
    this.handleChangeSensibleContent = this.handleChangeSensibleContent.bind(this);
    this.handleChangeAnnotations = this.handleChangeAnnotations.bind(this);
    this.postForm = this.postForm.bind(this);
  }
  componentDidMount() {
    if (__CLIENT__) {
      const WebFont = require('webfontloader');
      WebFont.load({
        google: {
          families: ['Libre Baskerville', 'Droid Sans', 'Droid Serif']
        }
      });
    }
  }
  @action
  handleChangeFontFamily(fontFamily: string) {
    this.props.store.mainSettings.preferences.fontFamily = fontFamily;
  }
  @action
  handleChangeFontSize(fontSize: string) {
    this.props.store.mainSettings.preferences.fontSize = fontSize;
  }
  @action
  handleChangeFontSpaced(fontSpaced: string) {
    this.props.store.mainSettings.preferences.fontSpaced = fontSpaced;
  }
  @action handleChangeSensibleContent() {
    this.props.store.mainSettings.preferences.defaultSensibleContent =
      !this.props.store.mainSettings.preferences.defaultSensibleContent;
  }
  @action handleChangeAnnotations() {
    this.props.store.mainSettings.preferences.annotations =
      !this.props.store.mainSettings.preferences.annotations;
  }
  postForm(event: any) {
    event.preventDefault();
    this.props.store.saveSettingsPreferences();
  }
  render() {
    const form = this.props.store.mainSettings.preferences;
    return (
      <div>
        {this.props.store.mainSettings.loading ?
          <Loader message="Chargement..." />
          :
          <form onSubmit={this.postForm} className="ui equal width form">
            <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.defaultSensibleContent}
                onClick={this.handleChangeSensibleContent}
                label="Accéder aux œuvres avec un contenu sensible"
                />
            </div>
            <div className="field" style={{ maxWidth: '600px' }}>
              <Checkbox
                checked={form.annotations}
                onClick={this.handleChangeAnnotations}
                label="Afficher par défaut les annotations"
                />
            </div>
            <h4>Style par défaut</h4>
            <div className="ui two column stackable grid">
              <div className="ui column">
                <div className="field" style={{ maxWidth: '600px' }}>
                  <label>Police</label>
                  <Dropdown
                    selection
                    value={form.fontFamily}
                    onChange={(e: any, { value }) => this.handleChangeFontFamily(String(value))}
                    options={
                      [
                        {
                          value: 'OpenSans',
                          text: 'Open Sans'
                        },
                        {
                          value: 'LibreBaskerville',
                          text: 'Libre Baskerville'
                        },
                        {
                          value: 'DroidSans',
                          text: 'Droid Sans'
                        },
                        {
                          value: 'DroidSerif',
                          text: 'Droid Serif'
                        }
                      ]
                    }
                    />
                </div>
                <div className="field" style={{ maxWidth: '600px' }}>
                  <label>Taille du texte</label>
                  <Dropdown
                    selection
                    value={form.fontSize}
                    onChange={(e: any, { value }) => this.handleChangeFontSize(String(value))}
                    options={
                      [
                        {
                          value: 'small',
                          text: 'Petite'
                        },
                        {
                          value: 'medium',
                          text: 'Moyenne'
                        },
                        {
                          value: 'big',
                          text: 'Grande'
                        },
                      ]
                    }
                    />
                </div>
                <div className="field" style={{ maxWidth: '600px' }}>
                  <label>Interligne</label>
                  <Dropdown
                    selection
                    value={form.fontSpaced}
                    onChange={(e: any, { value }) => this.handleChangeFontSpaced(String(value))}
                    options={
                      [
                        {
                          value: 'small',
                          text: 'Petite'
                        },
                        {
                          value: 'medium',
                          text: 'Moyenne'
                        },
                        {
                          value: 'big',
                          text: 'Grande'
                        },
                      ]
                    }
                    />
                </div>
              </div>
              <div className="ui column">
                <div style={{
                  fontFamily: form.computedFontFamily,
                  fontSize: form.computedFontSize,
                  lineHeight: form.computedFontSpaced
                }}>
                  Longtemps, je me suis couché de bonne heure.&nbsp;
                Parfois, à peine ma bougie éteinte, mes yeux se fermaient si vite que je&nbsp;
                n’avais pas le temps de me dire: " Je m’endors. " Et, une demi-heure après,&nbsp;
                la pensée qu’il était temps de chercher le sommeil m’éveillait; je voulais poser&nbsp;
                le volume que je croyais avoir encore dans les mains et souffler ma lumière;&nbsp;
                je n’avais pas cessé en dormant de faire des réflexions sur ce que je venais&nbsp;
                de faire, mais ces réflexions avaient pris un tour un peu particulier; il me&nbsp;
                semblait que j’étais moi-même ce dont parlait l’ouvrage: une église, un quatuor,&nbsp;
                la rivalité de François Ier et de Charles Quint.
                </div>
              </div>
            </div>
            <button className="ui submit primary right floated button"
              type="submit" style={{ marginTop: '25px' }}>
              Sauvegarder les modifications
            </button>
          </form>
        }
      </div>
    );
  }
}
