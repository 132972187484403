import CommentModel from './CommentModel';
import { observable, computed } from 'mobx';

export default class CommentListModel {
  @observable comments: CommentModel[];
  @observable commentType: string;
  @observable authorText: number;
  @observable authorDefi: number;
  @observable loading: boolean;

  constructor(comments: CommentModel[], commentType: string,
    authorText: number, authorDefi: number) {
    this.comments = comments || [];
    this.commentType = commentType;
    this.authorText = authorText;
    this.authorDefi = authorDefi;
    this.loading = false;
  }

  restPath(): string {
    switch (this.commentType) {
      case 'diary':
        return '/profile/diaries';
      case 'text':
        return '/read/comments';
      case 'defi':
        return '/defis/comments';
      case 'talk':
        return '/talks/comments';
      default:
        return '';
    }
  }

  private flatComments(): { comment: CommentModel, parentId?: number }[] {
    const allComments: { comment: CommentModel, parentId?: number }[] = [];
    this.comments.forEach((c) => {
      allComments.push({ comment: c });
      c.childs.forEach(c2 => allComments.push({ comment: c2, parentId: c.id }));
    });
    return allComments;
  }

  private findCommentIndex(commentId: number): number {
    const allComments = this.flatComments();
    return allComments.map(c => c.comment.id).indexOf(commentId);
  }

  findComment(commentId: number): { comment: CommentModel, parentId?: number } {
    const allComments = this.flatComments();
    const index = this.findCommentIndex(commentId);
    return allComments[index];
  }

  toJS() {
    return {
      comments: this.comments.map(c => c.toJS()),
      commentType: this.commentType,
      authorText: this.authorText,
      authorDefi: this.authorDefi
    };
  }

  static fromJS(object: any) {
    if (object) {
      const comments = object.comments.map((c: any) => CommentModel.fromJS(c));
      return new CommentListModel(comments, object.commentType,
        object.authorText, object.authorDefi);
    }
    return undefined;
  }
}
