import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
const Megadraft = require('megadraft');
const MegadraftEditor = Megadraft.MegadraftEditor;
import Store from '../../../../stores/Store';
const Infinite = require('react-infinite');
import actions from '../../../editor/actions';
import styleMap from '../../../editor/styleMap';
import blockStyles from '../../../editor/blockStyles';
import "./customNotesCss.css"


interface NotePadProps {
  store?: Store;
  textId: number;
  active: boolean;
  hide: Function;
}

interface NotePadState {
  activeRemove: boolean;
  selectedNote: number;
  activeNote: number;
  isBeingModified: boolean;
  errorModificationsNotSaved: boolean;
  ongoingAction: any;
}

@inject('store') @observer
export default class NotePad extends React.Component<NotePadProps, NotePadState> {
  constructor(props: NotePadProps) {
    super(props);
    this.hideNotes = this.hideNotes.bind(this);
    this.enableNewMode = this.enableNewMode.bind(this);
    this.openNote = this.openNote.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.confirmNotSaved = this.confirmNotSaved.bind(this);
    this.confirmSave = this.confirmSave.bind(this);
    this.cancelRemove = this.cancelRemove.bind(this);
/*     this.startPolling = this.startPolling.bind(this);
 *//*     this.onBlur = this.onBlur.bind(this);*/    
    this.onNoteOpen = this.onNoteOpen.bind(this);
    this.onHideNotes = this.onHideNotes.bind(this);
    this.setSaveError = this.setSaveError.bind(this);
    this.resumeAction = this.resumeAction.bind(this);
    this.state = { 
      activeRemove: false, 
      selectedNote: undefined, 
      activeNote: undefined, 
      isBeingModified : false, 
      errorModificationsNotSaved: false,
      ongoingAction: {action: "", parameter: ""}
    };
  }
  /* componentDidMount() {
    this.startPolling();
  } */
  
  @action
  save(withReload: boolean) {
    const currentText = this.props.store.write.currentText;
    const currentNote = currentText.currentNote;
    if (currentNote) {
      if (currentNote.id) {
        if (withReload) {
          this.props.store.updateNote(this.props.textId, currentNote.id, currentNote.title, currentNote.message)
            .then((n: any) => {
              this.props.store.loadNotes(this.props.textId);
              this.setState({isBeingModified: false});
            });
        } else {
          this.props.store.updateNote(this.props.textId, currentNote.id, currentNote.title, currentNote.message)
          .then((n: any) => {
            this.setState({isBeingModified: false});
          });
        }
      } else {
        this.props.store.createNote(this.props.textId, currentNote.title, currentNote.message).then((n: any) => {
          currentNote.id = n.id;
          currentNote.updatedAt = n.updatedAt;
          this.props.store.loadNotes(this.props.textId);
          this.setState({isBeingModified: false});
        });
      }
    }
  }
  /* startPolling() {
    const that = this;
    setTimeout(function () {
      that._timer = setInterval(that.save.bind(self), 10000);
    }, 1000);
  } */
  onHideNotes() {
    if (this.state.isBeingModified)
      this.setSaveError("onHideNotes", null);
    else {
      this.hideNotes();
    }
  }
  onNoteOpen(noteId: number) {
    if (this.state.isBeingModified)
      this.setSaveError("onNoteOpen", noteId);
    else 
      this.openNote(noteId);
  }
  onNewNote() {
    if (this.state.isBeingModified)
      this.setSaveError("onNewNote", null);
    else{
      this.enableNewMode();
    }
  }
  @action
  enableNewMode() {
    this.props.store.write.currentText.initNote();
  }
  openNote(noteId: number) {
    this.props.store.loadNote(this.props.textId, noteId).then((d: any) => {
    this.setState({
      ...this.state, 
      activeNote: noteId})
    });
  }
  hideNotes() {
    this.props.store.write.currentText.resetCurrentNote();
    this.setState({ 
      activeRemove: false, 
      selectedNote: undefined, 
      activeNote: undefined, 
      isBeingModified : false, 
      errorModificationsNotSaved: false,
      ongoingAction: {action: "", parameter: ""}
    });
    this.props.hide();
  }
  setSaveError(ongoingAction: String, parameter: any)
  {
    this.setState({errorModificationsNotSaved: true, ongoingAction: {"action": ongoingAction, "parameter": parameter}})
  }
  resumeAction() {
    let action = this.state.ongoingAction["action"];
    let parameter = this.state.ongoingAction["parameter"];
    this.setState({ongoingAction: {"action": "", "parameter": ""}})
    switch(action){
      case "onNoteOpen":
        this.openNote(parameter);
        break;
      case "onHideNotes":
        this.hideNotes();
        break;
      case "onNewNote":
        this.enableNewMode();
        break;
    }
    this.setState({isBeingModified: false, errorModificationsNotSaved: false})
  }
  @action
  onChange(value: any) {
    this.setState({...this.state, isBeingModified: true});
    this.props.store.write.currentText.currentNote.updateMessageState(value);
  }
  @action
  handleTitleChange(e: any) {
    this.setState({...this.state, isBeingModified: true});
    this.props.store.write.currentText.currentNote.title = e.target.value;
  }
  remove(noteId: number) {
    this.setState({
      activeRemove: true,
      selectedNote: noteId
    });
  }
  @action
  confirmRemove() {
    this.props.store.removeNote(this.props.textId, this.state.selectedNote).then((d: any) => {
      const currentNote = this.props.store.write.currentText.currentNote;
      if (currentNote && currentNote.id && this.state.selectedNote === currentNote.id) {
        this.props.store.write.currentText.currentNote = undefined;
      }
      this.setState({
        activeRemove: false,
        selectedNote: undefined
      });
    });
  }
  @action
  confirmNotSaved() {
    this.resumeAction()
  }
  @action
  confirmSave() {
    this.save(true)
    this.resumeAction()
  }
  cancelRemove() {
    this.setState({
      activeRemove: false,
      selectedNote: undefined
    });
  }
  /* onBlur(e: any) {
    const t = this;
    const currentTarget = e.currentTarget;

    setTimeout(function () {
      if (!currentTarget.contains(document.activeElement)) {
        t.save(true);
      }
    }, 0);
  } */
  render() {
    const notes = this.props.store.write.currentText.notes;
    const notesEl = notes.map((n, k) => {
      return (
        
        <div className="row customNotesRow" key={n.id}>
          <div className="twelve wide column">
          <Menu.Item id={n.id} name={'note ' + k} active={this.state.activeNote === n.id}
            onClick={() => this.onNoteOpen(n.id)}>
              <h4 className="ui header">
                  <div>{n.title && n.title.trim().length > 0 ? n.title : n.message + '...'}</div>
                  <div className="sub header">{n.updatedAt}</div>
              </h4>
          </Menu.Item>
          </div>
          <div className="mini four wide column customNotesContainer">
            <button className="ui centered basic icon button" onClick={() => this.remove(n.id)}>
              <i className="fa fa-remove" />
            </button>
          </div>
        </div>
      );
    });
    const currentNote = this.props.store.write.currentText.currentNote;
    return (
      <div>
        <Modal
          open={this.state.activeRemove}
          onClose={() => { }}
        >
          <div className="content">
            Confirmez-vous la suppression de cette note ?
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.confirmRemove}
            >
              Oui
          </button>
            <button className="ui cancel button" onClick={this.cancelRemove}>Non</button>
          </div>
        </Modal>
        <Modal
          open={this.state.errorModificationsNotSaved}
          onClose={() => { }}
        >
          <div className="content">
          <i className="fa fa-exclamation-triangle"/>  Attention, vos modifications n'ont pas été sauvegardées
          </div>
          <div className="actions">
            <button
              className="ui blue approve button" onClick={this.confirmSave}
            >
              Sauvegarder les modifications
          </button>
            <button className="ui red button" onClick={this.confirmNotSaved}>
              Abandonner les modifications
            </button>
          </div>
        </Modal>
        <Modal
          open={this.props.active}
          onClose={this.onHideNotes}
        >
          <div className="header">Notes</div>
          <div className="content" >
            <div className="ui two column centered grid stackable">
              <div className="six wide column">
                <div style={{ textAlign: 'center', marginBottom: "10px" }}>
                  <button
                    className="ui button"
                    onClick={() => this.onNewNote()}
                    style={{ width: '90%' }}
                  >
                    Nouvelle note
                  </button>
                </div>
                {notes.length > 0 ?
                <div style={{height: "300px", overlowX: "none",overflowY:"auto"}}>
                  <Menu secondary fluid vertical
                    activeIndex={this.state.activeNote}
                  >
                    
                    <div style={{width:"98%"}} className="ui two column grid">
                      {notesEl}
                    </div>
                  </Menu>
                </div>
                  : <div />
                }
              </div>
              <div className="ten wide column">
                {currentNote != undefined ?
                  <div className="ui form" style={{ paddingRight: '20px' }}>
                    <div className="field">
                      <input
                        type="text"
                        placeholder="Titre"
                        aria-label="Titre"
                        onChange={this.handleTitleChange}
                        value={this.props.store.write.currentText.currentNote.title}
                      />
                    </div>
                    <div className="embedded small">
                      <div className="ui segment" style={{ height: '300px', overflowY: 'scroll' }}>
                        <div className="no-overflow">
                          <MegadraftEditor
                            editorState={this.props.store.write.currentText.currentNote.messageState}
                            actions={actions}
                            placeholder="Votre note"
                            onChange={this.onChange}
                            sidebarRendererFn={() => { return <div />; }}
                            customStyleMap={styleMap}
                            blockStyles={blockStyles}
                            spellcheck={false}
                          />
                        </div>
                      </div>
                    </div>
                    <button className={`${this.state.isBeingModified ? "positive": "disabled"} ui button right floated`} onClick={() => this.save(false)} style={{marginTop: "10px"}}>Sauvegarder</button>
                  </div>
                  : <div className="ui very padded center aligned segment"
                    style={{ marginTop: '100px' }}>
                    <h3>Aucune note sélectionnée...</h3>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={() => this.onHideNotes()}>
              Quitter
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
