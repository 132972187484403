import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Store from '../../stores/Store';

interface ProfileFormWhereProps {
  store?: Store;
}

interface ProfileFormWhereState {
  editMode: boolean;
  websiteError: boolean;
  facebookError: boolean;
  twitterError: boolean;
  localisation: string;
  website: string;
  facebook: string;
  twitter: string;
}

@inject('store') @observer
export default class ProfileFormWhere extends React.Component<ProfileFormWhereProps, ProfileFormWhereState> {
  constructor(props: ProfileFormWhereProps) {
    super(props);
    this.startEdit = this.startEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.handleLocalisationChange = this.handleLocalisationChange.bind(this);
    this.handleWebsiteChange = this.handleWebsiteChange.bind(this);
    this.handleFacebookChange = this.handleFacebookChange.bind(this);
    this.handleTwitterChange = this.handleTwitterChange.bind(this);

    this.state = {
      editMode: false,
      websiteError: false,
      facebookError: false,
      twitterError: false,
      localisation: undefined,
      website: undefined,
      facebook: undefined,
      twitter: undefined
    };
  }
  startEdit() {
    const profile = this.props.store.profile.user;
    this.setState({
      editMode: true,
      websiteError: false,
      facebookError: false,
      twitterError: false,
      localisation: profile.localisation,
      website: profile.website,
      facebook: profile.facebook,
      twitter: profile.twitter
    });
  }
  cancelEdit() {
    this.setState({
      editMode: false,
      websiteError: false,
      facebookError: false,
      twitterError: false,
      localisation: undefined,
      website: undefined,
      facebook: undefined,
      twitter: undefined
    });
  }
  saveEdit(e: any) {
    e.preventDefault();
    const profileId = this.props.store.profile.id;
    this.props.store.updateProfileWhere(profileId, this.state.localisation,
      this.state.website, this.state.twitter, this.state.facebook).then((d: any) => {
        this.props.store.profile.user.localisation = this.state.localisation;
        this.props.store.profile.user.website = this.state.website;
        this.props.store.profile.user.twitter = this.state.twitter;
        this.props.store.profile.user.facebook = this.state.facebook;

        this.setState({
          editMode: false,
          websiteError: false,
          facebookError: false,
          twitterError: false,
          localisation: undefined,
          website: undefined,
          facebook: undefined,
          twitter: undefined
        });
      });
  }
  handleLocalisationChange(e: any) {
    this.setState({
      editMode: this.state.editMode,
      websiteError: this.state.websiteError,
      facebookError: this.state.facebookError,
      twitterError: this.state.twitterError,
      localisation: e.target.value,
      website: this.state.website,
      facebook: this.state.facebook,
      twitter: this.state.twitter
    });
  }
  handleWebsiteChange(e: any) {
    const websiteError = this.state.websiteError && e.target.value.trim().length === 0;
    this.setState({
      editMode: this.state.editMode,
      websiteError: websiteError,
      facebookError: this.state.facebookError,
      twitterError: this.state.twitterError,
      localisation: this.state.localisation,
      website: e.target.value,
      facebook: this.state.facebook,
      twitter: this.state.twitter
    });
  }
  handleTwitterChange(e: any) {
    const twitterError = this.state.twitterError && e.target.value.trim().length === 0;
    this.setState({
      editMode: this.state.editMode,
      websiteError: this.state.websiteError,
      facebookError: this.state.facebookError,
      twitterError: twitterError,
      localisation: this.state.localisation,
      website: this.state.website,
      facebook: this.state.facebook,
      twitter: e.target.value
    });
  }
  handleFacebookChange(e: any) {
    const facebookError = this.state.facebookError && e.target.value.trim().length === 0;
    this.setState({
      editMode: this.state.editMode,
      websiteError: this.state.websiteError,
      facebookError: facebookError,
      twitterError: this.state.twitterError,
      localisation: this.state.localisation,
      website: this.state.website,
      facebook: e.target.value,
      twitter: this.state.twitter
    });
  }
  render() {
    const connected = this.props.store.connected;
    const profile = this.props.store.profile;
    const user = profile.user;
    const isAuthor = profile && profile.user
      && connected && connected.user && connected.user.id
      && connected.user.id === profile.user.id;
    return (
      <div>
        {isAuthor && this.state.editMode ?
          <div className="ui segment" style={{ paddingBottom: '40px', color: 'rgba(0, 0, 0, 0.87)' }}>
            <form onSubmit={this.saveEdit} className="ui equal width form">
              <div className="field">
                <label>Localisation</label>
                <input type="text" placeholder="Localisation"
                  value={this.state.localisation} onChange={this.handleLocalisationChange} />
              </div>
              <div className={'field' + (this.state.websiteError ? ' error' : '')} style={{ maxWidth: '600px' }}>
                <label>Site internet / Blog</label>
                <input type="text" placeholder="Site internet / Blog"
                  value={this.state.website} onChange={this.handleWebsiteChange} />
                {this.state.websiteError ?
                  <div className="ui error message">
                    <div className="header">L'adresse est invalide.</div>
                  </div>
                  : undefined
                }
              </div>
              <div className={'field' + (this.state.twitterError ? ' error' : '')} style={{ maxWidth: '600px' }}>
                <label>Twitter</label>
                <input type="text" placeholder="https://twitter.com/atelierdesauteurs"
                  value={this.state.twitter} onChange={this.handleTwitterChange} />
                {this.state.twitterError ?
                  <div className="ui error message">
                    <div className="header">L'adresse est invalide.</div>
                  </div>
                  : undefined
                }
              </div>
              <div className={'field' + (this.state.facebookError ? ' error' : '')} style={{ maxWidth: '600px' }}>
                <label>Facebook</label>
                <input type="text" placeholder="https://www.facebook.com/atelierdesauteurs.officiel"
                  value={this.state.facebook} onChange={this.handleFacebookChange} />
                {this.state.facebookError ?
                  <div className="ui error message">
                    <div className="header">L'adresse est invalide.</div>
                  </div>
                  : undefined
                }
              </div>
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelEdit}
                  >
                  Annuler
                </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveEdit}
                  type="submit"
                  >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
          :
          <div
            className="meta medium text"
            style={{ color: user.textColor }}
            >
            {user.localisation ?
              <div>
                {user.localisation ? `${user.localisation}. ` : undefined}
              </div>
              : undefined}
            {user.website || user.twitter || user.facebook ?
              <div>
                Retrouvez-moi sur&nbsp;
            {user.website ?
                  <a href={user.website} style={{ color: user.linkColor }} target="_blank" rel="noopener noreferrer">
                    {user.website}
                  </a>
                  : undefined}
                {user.twitter ?
                  <span>
                    {user.website ?
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                        |
                </span>
                      : undefined}
                    <a href={user.twitter} style={{ color: user.linkColor }} target="_blank" rel="noopener noreferrer">
                      Twitter
                </a>
                  </span>
                  : undefined}
                {user.facebook ?
                  <span>
                    {user.website || user.twitter ?
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                        |
                </span>
                      : undefined}
                    <a href={user.facebook} style={{ color: user.linkColor }} target="_blank" rel="noopener noreferrer">
                      Facebook
                </a>
                  </span>
                  : undefined}
              </div>
              : undefined}
            {isAuthor ?
              <button className="ui small orange label" onClick={this.startEdit}>
                Localisation, Twitter, Facebook,...
                </button>
              : undefined
            }
          </div>
        }
      </div>
    );
  }
}
