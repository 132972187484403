import * as React from 'react';
import Title from '../misc/Title';

export default class SensibleContent extends React.Component<{}, {}> {
  render() {
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ minHeight: '90vh', paddingTop: '30px' }}>
          <Title title="Contenu sensible " />
          <h1>Contenu sensible</h1>
          <div className="ui basic segment">
            <h2>Qu'est-ce qu'un "contenu sensible" ?</h2>
            <p>Il s'agit de tous les types de contenus pouvant potentiellement choquer ou gêner un large lectorat : textes érotiques, violents touchant à des sujets choquants, douloureux ou traumatisants.</p>
            <p>La qualification d'un contenu "sensible" doit être appréciée par l'auteur du texte et son lectorat.</p>
            <p>Automatiquement, tous les textes érotiques sont classés dans la catégorie « sensible ».</p>

            <h2>Pourquoi ne pas avoir pris une limite d’âge ?</h2>
            <p>En France, la littérature générale ne connaît pas de classification selon les âges, contrairement au cinéma ou aux jeux vidéo. Vous pouvez avoir 15 ans et acheter librement des œuvres de Boris Vian, Rabelais ou Sade dans une librairie… lorsque ces auteurs ne sont pas étudiés à l’école !</p>

            <h2>J'estime qu'un contenu est sensible alors qu'il n'est pas noté comme tel, que dois-je faire ?</h2>
            <p>En premier lieu, n'hésitez pas à commenter ce texte ou à contacter directement l'auteur.</p>
            <p>Si vous pensez qu'un texte devrait être classé comme "contenu sensible" et si son auteur ne répond pas à vos demandes, nous vous invitons à contacter la modération.</p>
            <p>Pour cela, rendez-vous sur la page de présentation de l’œuvre en question, cliquez sur le bouton "Signaler à la modération". Une boîte de dialogue s'ouvrira dans laquelle vous pourrez exposer votre demande.</p>
          </div>
        </div>
      </div>
    );
  }
}
