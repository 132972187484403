const blocksToHTML = {
  'paragraph': {
    start: '<p>',
    end: '</p>',
    empty: '<br>'
  },
  'align-left': {
    start: '<p style="text-align: left">',
    end: '</p>',
    empty: '<br>'
  },
  'align-center': {
    start: '<p style="text-align: center">',
    end: '</p>',
    empty: '<br>'
  },
  'align-right': {
    start: '<p style="text-align: right">',
    end: '</p>',
    empty: '<br>'
  },
  'align-justify': {
    start: '<p style="text-align: justify; white-space: pre-line">',
    end: '</p>',
    empty: '<br>'
  }
};
export default blocksToHTML;
