import * as React from 'react';
import { Link } from 'react-router';

interface FooterProps {
  premiumUi?: boolean;
}

export default class Footer extends React.Component<FooterProps, {}> {
  render() {
    return (<div>
      <div className="ada-background-editis-beige-foncé" style={{minHeight: "10px"}}></div>
      <div className={"ui vertical footer segment " + 
      (this.props.premiumUi ? "ada-background-editis-beige-1 ada-text-editis-bleu-nuit" 
      : "ada-background-editis-bleu-nuit ada-text-editis-white")}>
        
        <div className="ui center aligned text container">
          <div className="ui stackable grid">

            <div className="four wide column">
              {this.props.premiumUi ?
                <img src="/logo-ada.png" style={{width: "100%" }}/>
              :
                <img src="/logo-ada-blanc.png" style={{width: "100%" }}/>
              }
            </div>
            <div className="twelve wide column">
              <div className="ui stackable inverted divided grid">
                <div className="left aligned five wide column">
                  <h4 className={"ui header ada-museo-500 " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Atelier des auteurs</h4>
                  <div className="ui link list">
                    {/* R */}
                    <Link to="/faq" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>FAQ</Link>
                    <Link to="/partenaires" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Nos partenaires</Link>
                    {/* <Link to="/presse" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Presse</Link> */}
                    {/* <Link to="/partenariats" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Partenariats</Link> */}
                    <Link to="/intproperty" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Propriété intellectuelle</Link>
                    <Link to="/moderation" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Modération</Link>
                    <Link to="/sensiblecontent" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Contenu sensible</Link>
                  </div>
                </div>
                <div className="left aligned  eleven wide column">
                  <h4 className={"ui header ada-museo-500 " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Retrouvez-nous sur</h4>
                  <div className="ui link list">
                    {/* <a href="/blog" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>
                      <b>Un mot après l'autre</b>, notre blog dédié à l'écriture
                    </a> */}
                    <a href="https://www.facebook.com/atelierdesauteurs"
                      target="_blank" rel="noopener noreferrer" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Facebook</a>
                    <a href="https://www.twitter.com/atelier_auteurs"
                      target="_blank" rel="noopener noreferrer" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Twitter</a>
                  </div>
                </div>
              </div>
              <div className="ui section divider" style={{marginTop: '1em', marginBottom: '0px'}}></div>
              <div className="ui horizontal small divided link list">
                <Link to="/contact" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Contact</Link>
                <Link to="/mentions" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Mentions légales</Link>
                <Link to="/cgu" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Conditions générales d'utilisation</Link>
                <Link to="/cgv" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Conditions générales de vente</Link>
                <Link to="/privacy" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Politique de protection des données personnelles</Link>
{/*                 <Link to="/cookies" className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")}>Cookies</Link>
 */}                <a className={"item " + (this.props.premiumUi ? "ada-text-editis-bleu-nuit" : "ada-text-editis-white")} href="javascript:Didomi.preferences.show()">Paramètres des cookies</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
