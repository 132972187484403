import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion, Icon } from 'semantic-ui-react'
import Store from '../../stores/Store';
import ParcoursProductPartModel from '../../models/product/ParcoursProductPartModel';

interface LandingPageAccordionParcoursPartState {
  activeIndex: number;
  maxSteps: boolean;
}

interface LandingPageAccordionParcoursPartProps {
  formatedParcoursParts: ParcoursProductPartModel[];
}

@inject('store') @observer
export default class LandingPageAccordionParcoursPart extends React.Component<LandingPageAccordionParcoursPartProps, LandingPageAccordionParcoursPartState> {
  constructor(props: void) {
    super();
    this.state = {
       activeIndex: 0,
       maxSteps: true
      }
  }
  handleClick = (titleProps: any) => {
    const index = titleProps
    const activeIndex = this.state.activeIndex
    const newIndex = activeIndex === index ? -1 : index

    this.setState({activeIndex: newIndex})
  }

  toggleMoreSteps = () => {
    this.setState({maxSteps: !this.state.maxSteps})
  }

  render() {
    const accordion: JSX.Element[] = this.props.formatedParcoursParts ? Object.keys(this.props.formatedParcoursParts).map((key, index) => { 
      return (
        (!this.state.maxSteps || index < 5) ?
        <div key={'accordion-pp-'+key}>
          <div className={'title ' + (this.state.activeIndex === parseInt(key) ? 'active' : '')} onClick={() => this.handleClick(parseInt(key))}>
            <i className="dropdown icon"></i>
            {this.props.formatedParcoursParts[parseInt(key)].partName}
          </div>
          <div className={'content ' + (this.state.activeIndex === parseInt(key) ? 'active' : '')}>
          <p className={'transition ' + (this.state.activeIndex === parseInt(key) ? '' : 'hidden')}>
            {this.props.formatedParcoursParts[parseInt(key)].partDescription}
          </p>
        </div>
      </div>
      : undefined
      )
    })

    : undefined ;
    return (
        <div className="ui styled fluid accordion">
          {accordion}
          {this.props.formatedParcoursParts.length > 5 ?
            <div className="title" style={{textAlign: "center"}} onClick={() => this.toggleMoreSteps()}>
              {this.state.maxSteps ? "Afficher tout" : "Réduire"}
            </div>
          :
            undefined}
        </div>
        
    )
  }
}