import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Store from '../../../../stores/Store';
import Loader from '../../../misc/Loader';

interface AllUserParcoursPageContentProps {
  store?: Store;
  withHeader?: boolean;
}

interface AllUserParcoursPageContentState {
  message: string;
}

@inject('store') @observer
export default class AllUserParcoursPageContent extends React.Component<AllUserParcoursPageContentProps, AllUserParcoursPageContentState> {
  constructor(props: AllUserParcoursPageContentProps) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.state = {
      message: ''
    };
  }
  loadMore() {
    this.props.store.loadAllUserParcours();
  }
  render() {
    const allUserParcours = this.props.store.allUserParcours; 
    const allUserParcoursElement = allUserParcours.allParcours.map((parcours, i) => {
      return (
        <div key={i} style={{ marginBottom: "30px", marginTop: "30px", justifyContent: "center"}} className="ui column">
            {/* <div className="ui tiny rounded image">
                <img src={`${parcours.parcoursImage}`} />
            </div> */}
            <div className={(i % 3 == 2) ||  (i % 3 == 1) ? "ui centered grid ada-border-left" : "ui centered grid"} 
            style={{ fontSize:"1.4rem", textAlign: "center"}}>
                  <div style={{width:"70%"}}>
              <div style={{minHeight: "60px"}}>
                <div style={{verticalAlign:"center", lineHeight: "2rem"}} className="ada-text editis-bleu-nuit ada-museo-500 ada-font-600">
                  {parcours.parcoursName}
                </div>
              </div>
            {parcours.textId && parcours.textTitle && parcours.projectName?
            <div>
              <div style={{paddingTop: "15px", borderTop: "1px solid", borderColor: "color: rgb(0, 28, 54)"}}>
                <p className="ada-text editis-bleu-nuit ada-font-200" >Vous suivez ce cours </p>
                <p style={{marginTop: "-15px"}} className="ada-text editis-bleu-nuit ada-font-200" >avec votre roman</p>
                <p style={{marginTop: "-15px"}} className="ada-text editis-bleu-nuit ada-font-600" >{parcours.textTitle}</p>
                <Link to={`/parcours/${parcours.textId}/${parcours.textUrl}`}>
                <button
                  className="ui button ada-background editis-bleu" 
                  style={{ marginTop: "10px", cursor: 'pointer', color: "white"}}
                >
                  Reprendre mon cours
                </button>
                </Link>
              </div>
              {/* {parcours.textId && parcours.textUrl && parcours.chapterId ?
                <div className="sub header" style={{paddingTop: "5px"}}>
                  <Link to={`/text/${parcours.textId}/${parcours.textUrl}/chapter/${parcours.chapterId}/edit`}>
                    Aller à l'éditeur
                </Link>
                </div>
              : undefined} */}
            </div>
            : parcours.textId && parcours.textTitle ?
            <div>
              <div style={{paddingTop: "15px", borderTop: "1px solid", borderColor: "color: rgb(0, 28, 54)"}}>
                <Link to={`/parcours/${parcours.textId}/${parcours.textUrl}`}>
                <button
                  className="ui button ada-background editis-bleu" 
                  style={{ marginTop: "10px", cursor: 'pointer', color: "white"}}
                >
                  Reprendre mon cours
                </button>
                </Link>
              </div>
            </div>
            : undefined}
            </div>
            </div>
        </div>
      );
    });
    return (
      <div>
        {allUserParcours.isEmpty() ?
          <Loader message="Chargement..." />
        :
        <div className="ui three column relaxed grid stackable">
          {allUserParcoursElement}
        </div>
        }
      </div>
    );
  }
}
