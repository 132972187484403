import * as React from 'react';
import { getTextTime, getChapterTime } from '../../utils/utils';

interface DurationProps {
  words: number;
  chaptersCount?: number;
}

export default class Duration extends React.Component<DurationProps, {}> {
  render() {
    let t: string;
    if (this.props.chaptersCount) {
      t = getTextTime(this.props.words, this.props.chaptersCount);
    } else {
      t = getChapterTime(this.props.words);
    }
    return (
      <div style={{ fontSize: '1.2em' }}>
        <i className="fa fa-clock-o" /> {t}
      </div>
    );
  }
}
