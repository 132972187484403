import * as React from 'react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

interface DefiRemoveProps {
  delete: boolean;
  modalActive: boolean;
  handleRemove: any;
  hideModal: any;
}

export default class DefiRemove extends React.Component<DefiRemoveProps, {}> {
  constructor(props: DefiRemoveProps) {
    super(props);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleRemove() {
    this.props.handleRemove();
  }
  render() {
    return (
      <div>
          <Modal onClose={this.props.hideModal} open={this.props.modalActive}>
            <div className="header">Confirmation</div>
            <div className="content">
              Confirmez-vous la suppression de ce défi ? {this.props.delete ? "Tous les textes associés seront également supprimés !" : ""}
            </div>
            <div className="actions">
              <button className="ui blue approve button" onClick={this.handleRemove}>Oui</button>
              <button className="ui cancel button" onClick={this.props.hideModal}>Non</button>
            </div>
          </Modal>
      </div>
    );
  }
}
