import { observable } from 'mobx';
import UserModel from '../UserModel';

export default class ReadingListModel {

  @observable id: number;
  @observable name: string;
  @observable pub: boolean;
  @observable ordered: boolean;
  @observable user: UserModel;
  @observable urlName: string;
  @observable cover: string;
  @observable texts: string;
  @observable createdAt: string;
  @observable selected: boolean;

  constructor(id: number, name: string, pub: boolean, ordered: boolean,
    user: UserModel, urlName: string, cover: string, texts: string,
    createdAt: string, selected: boolean) {
    this.id = id;
    this.name = name;
    this.pub = pub;
    this.ordered = ordered;
    this.user = user;
    this.urlName = urlName;
    this.cover = cover;
    this.texts = texts;
    this.createdAt = createdAt;
    this.selected = selected;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      pub: this.pub,
      ordered: this.ordered,
      user: this.user.toJS(),
      urlName: this.urlName,
      cover: this.cover,
      texts: this.texts,
      createdAt: this.createdAt,
      selected: this.selected
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ReadingListModel(
        object.id, object.name, object.pub, object.ordered,
        UserModel.fromJS(object.user), object.urlName, object.cover,
        object.texts, object.createdAt, object.selected
      );
    }
    return undefined;
  }
}
