import { observable } from 'mobx';

export default class CharacterModel {

  @observable id: number;
  @observable name: string;
  @observable image: string;
  @observable chapters: number[];
  @observable undeletable: boolean;
  @observable inChapter: boolean;

  constructor(id: number, name: string, image: string, chapters: number[],
    undeletable: boolean, inChapter: boolean) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.chapters = chapters;
    this.undeletable = undeletable;
    this.inChapter = inChapter;
  }

  toJS() {
    return {
      id: this.id,
      name: this.name,
      image: this.image,
      chapters: this.chapters,
      undeletable: this.undeletable,
      inChapter: this.inChapter
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CharacterModel(object.id, object.name, object.image,
        object.chapters, object.undeletable, object.inChapter);
    }
    return undefined;
  }
}
