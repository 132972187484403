import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory, Link } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
const Accordion = require('semantic-ui-react/dist/commonjs/modules/Accordion').default;
const Select = require('semantic-ui-react/dist/commonjs/addons/Select').default;
const Progress = require('semantic-ui-react/dist/commonjs/modules/Progress').default;
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import Title from '../misc/Title';
import Store from '../../stores/Store';
import WritingProgramPeriodModel from '../../models/writingprogram/WritingProgramPeriodModel';
import AlertModel from '../../models/AlertModel';

interface WritingProgramPageProps {
  store?: Store;
}

interface WritingProgramPageState {
  loading: boolean;
}

interface WritingProgramEditProps {
  day?: number;
  objectives: {
    words?: number;
    /* defis?: number;
    texts?: number;
    trainings?: number;
    chapters?: number; */
    linkedTextId?: number;
  }[];
  addObjective: Function;
  editObjective: Function;
  removeObjective: Function;
  allTexts: { id: number, title: string, urlText: string }[];
}

class WritingProgramEdit
  extends React.Component<WritingProgramEditProps, {}> {
  removeButton(day: number, objType: string) {
    return (
      <button
        title="Supprimer"
        className="ui mini icon compact button"
        data-tooltip="Supprimer" data-inverted="" data-position="bottom center"
        style={{ marginRight: '10px' }}
        onClick={() => this.props.removeObjective(day, objType)}
      >
        <i className="fa fa-remove" />
      </button>
    );
  }
  render() {
    const objectives: JSX.Element[] = [];

    this.props.objectives.forEach((objective) => {
      if (objective.words) {
        objectives.push(
          <div key={`words_${this.props.day}`} className="item">
            {this.removeButton(this.props.day, 'words')}
            Écrire  <Select
              compact
              value={objective.words}
              options={[{ key: 250, value: 250, text: '250' }, { key: 500, value: 500, text: '500' },
              { key: 750, value: 750, text: '750' }, { key: 1000, value: 1000, text: '1000' }, { key: 1250, value: 1250, text: '1250' },
              { key: 1500, value: 1500, text: '1500' }, { key: 1750, value: 1750, text: '1750' }, { key: 2000, value: 2000, text: '2000' }]}
              onChange={(e: any, { value }: any) => {
                this.props.editObjective(this.props.day, 'words', value);
              }}
            /> mots
          </div>
        );
      }
      /* if (objective.defis) {
        objectives.push(
          <div key={`defis_${this.props.day}`} className="item">
            {this.removeButton(this.props.day, 'defis')}
            Répondre à <Select
              compact
              value={objective.defis}
              options={[{ key: 1, value: 1, text: '1' }, { key: 2, value: 2, text: '2' },
              { key: 3, value: 3, text: '3' }, { key: 4, value: 4, text: '4' }, { key: 5, value: 5, text: '5' },
              { key: 6, value: 6, text: '6' }, { key: 7, value: 7, text: '7' }]}
              onChange={(e: any, { value }: any) => {
                this.props.editObjective(this.props.day, 'defis', value);
              }}
            /> défi(s)
          </div>
        );
      }
      if (objective.texts) {
        objectives.push(
          <div key={`texts_${this.props.day}`} className="item">
            {this.removeButton(this.props.day, 'texts')}
            Publier <Select
              compact
              value={objective.texts}
              options={[{ key: 1, value: 1, text: '1' }, { key: 2, value: 2, text: '2' },
              { key: 3, value: 3, text: '3' }, { key: 4, value: 4, text: '4' }, { key: 5, value: 5, text: '5' },
              { key: 6, value: 6, text: '6' }, { key: 7, value: 7, text: '7' }]}
              onChange={(e: any, { value }: any) => {
                this.props.editObjective(this.props.day, 'texts', value);
              }}
            /> œuvre(s)
          </div>
        );
      }
      if (objective.chapters) {
        objectives.push(
          <div key={`chapters_${this.props.day}`} className="item">
            {this.removeButton(this.props.day, 'chapters')}
            Terminer <Select
              compact
              value={objective.chapters}
              options={[{ key: 1, value: 1, text: '1' }, { key: 2, value: 2, text: '2' },
              { key: 3, value: 3, text: '3' }, { key: 4, value: 4, text: '4' }, { key: 5, value: 5, text: '5' },
              { key: 6, value: 6, text: '6' }, { key: 7, value: 7, text: '7' }]}
              onChange={(e: any, { value }: any) => {
                this.props.editObjective(this.props.day, 'chapters', value, objective.linkedTextId);
              }}
            /> chapitre(s) dans l'œuvre
            <span style={{ marginLeft: '5px' }}>
              <Select
                compact
                value={objective.linkedTextId}
                options={this.props.allTexts.map((t) => {
                  return { key: t.id, value: t.id, text: t.title };
                })}
                onChange={(e: any, { value }: any) => {
                  this.props.editObjective(this.props.day, 'chapters', objective.chapters, value);
                }}
              />
            </span>
          </div>
        );
      }
      if (objective.trainings) {
        objectives.push(
          <div key={`trainings_${this.props.day}`} className="item">
            {this.removeButton(this.props.day, 'trainings')}
            Faire  <Select
              compact
              value={objective.trainings}
              options={[{ key: 1, value: 1, text: '1' }, { key: 2, value: 2, text: '2' },
              { key: 3, value: 3, text: '3' }, { key: 4, value: 4, text: '4' }, { key: 5, value: 5, text: '5' },
              { key: 6, value: 6, text: '6' }, { key: 7, value: 7, text: '7' }]}
              onChange={(e: any, { value }: any) => {
                this.props.editObjective(this.props.day, 'trainings', value);
              }}
            /> entraînement(s)
          </div>
        );
      } */
    });
    let day: string;
    if (this.props.day) {
      switch (this.props.day) {
        case 1:
          day = 'Lundi';
          break;
        case 2:
          day = 'Mardi';
          break;
        case 3:
          day = 'Mercredi';
          break;
        case 4:
          day = 'Jeudi';
          break;
        case 5:
          day = 'Vendredi';
          break;
        case 6:
          day = 'Samedi';
          break;
        case 7:
          day = 'Dimanche';
          break;
      }
    }

    const items: JSX.Element[] = [];
    if (this.props.objectives.filter(o => o.words).length === 0) {
      items.push(
        <Dropdown.Item
          key="new_words"
          text="Mots"
          onClick={() => {
            this.props.addObjective(this.props.day, 'words');
          }} />
      );
    }
    /* if (this.props.objectives.filter(o => o.defis).length === 0) {
      items.push(
        <Dropdown.Item
          key="new_defis"
          text="Défi"
          onClick={() => {
            this.props.addObjective(this.props.day, 'defis');
          }}
        />
      );
    }
    if (this.props.objectives.filter(o => o.texts).length === 0) {
      items.push(
        <Dropdown.Item
          key="new_texts"
          text="Œuvre"
          onClick={() => {
            this.props.addObjective(this.props.day, 'texts');
          }} />
      );
    }
    if (this.props.objectives.filter(o => o.chapters).length === 0 && this.props.allTexts.length > 0) {
      items.push(
        <Dropdown.Item
          key="new_chapters"
          text="Chapitre"
          onClick={() => {
            this.props.addObjective(this.props.day, 'chapters');
          }} />
      );
    }
    if (this.props.objectives.filter(o => o.trainings).length === 0) {
      items.push(
        <Dropdown.Item
          key="new_trainings"
          text="Entraînement"
          onClick={() => {
            this.props.addObjective(this.props.day, 'trainings');
          }} />
      );
    } */

    return (
      <div className={this.props.day ? 'item' : ''}>
        {day ?
          <div className="header">{day}</div>
          : undefined}
        <div className="ui middle relaxed aligned list">
          {objectives.length > 0 ?
            objectives
            : undefined
          }
          {/* {items.length > 0 ?
            <div className="item">
              <Dropdown
                text="Ajouter un objectif"
                button
              >
                <Dropdown.Menu>
                  {items}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            : undefined} */}
          {this.props.objectives.filter(o => o.words).length === 0 ? 
            <button className="ui submit button" onClick={() => {
              this.props.addObjective(this.props.day, 'words');
            }}>Ajouter un objectif</button> 
          : undefined}
        </div>
      </div>
    );
  }
}

interface WritingProgramObjectiveProps {
  period: WritingProgramPeriodModel;
  nolabel?: boolean;
}

class WritingProgramObjective
  extends React.Component<WritingProgramObjectiveProps, {}> {
  constructor(props: WritingProgramObjectiveProps) {
    super(props);
    this.progressBar = this.progressBar.bind(this);
  }
  calcRatio(value: number, objective: number) {
    if (value) {
      const ratio = Math.round(value / objective * 100);
      return ratio > 100 ? 100 : ratio;
    }
    return 0;
  }
  progressBar(ratio: number, label: string) {
    return (
      <Progress percent={ratio} progress className={(ratio === 100 ? 'success' : 'warning') + (!this.props.period.day ? ' large' : '')}>
        {label}
      </Progress>
    );
  }
  render() {
    const period = this.props.period;
    const objectives: JSX.Element[] = [];

    period.objectives.forEach((obj) => {
      if (obj.objWords) {
        const ratio = this.calcRatio(obj.words, obj.objWords);
        objectives.push(
          <div key={`words_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Écrire ${obj.objWords} mots (${(obj.words ? obj.words : 0)}/${obj.objWords})`)}
          </div >
        );
      }
      /* if (obj.objDefis) {
        const ratio = this.calcRatio(obj.defis, obj.objDefis);
        objectives.push(
          <div key={`defis_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Répondre à ${obj.objDefis} défi${obj.objDefis > 1 ? 's' : ''} (${(obj.defis ? obj.defis : 0)}/${obj.objDefis})`)}
          </div>
        );
      }
      if (obj.objTexts) {
        const ratio = this.calcRatio(obj.texts, obj.objTexts);
        objectives.push(
          <div key={`texts_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Publier ${obj.objTexts} œuvre${obj.objTexts > 1 ? 's' : ''} (${(obj.texts ? obj.texts : 0)}/${obj.objTexts})`)}
          </div>
        );
      }
      if (obj.objChapters) {
        const ratio = this.calcRatio(obj.chapters, obj.objChapters);
        objectives.push(
          <div key={`chapters_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Terminer ${obj.objChapters} chapitre${obj.objChapters > 1 ? 's' : ''} (${(obj.chapters ? obj.chapters : 0)}/${obj.objChapters}) dans l'œuvre "${obj.linkedText.title}"`)}
          </div>
        );
      }
      if (obj.objTrainings) {
        const ratio = this.calcRatio(obj.trainings, obj.objTrainings);
        objectives.push(
          <div key={`trainings_${period.day ? period.day : 'week'}`} className="item">
            {this.progressBar(ratio, `Faire ${obj.objTrainings} entraînement${obj.objTrainings > 1 ? 's' : ''} (${(obj.trainings ? obj.trainings : 0)}/${obj.objTrainings})`)}
          </div>
        );
      } */
    });
    let day: string;
    if (period.day) {
      switch (period.day) {
        case 1:
          day = 'Lundi';
          break;
        case 2:
          day = 'Mardi';
          break;
        case 3:
          day = 'Mercredi';
          break;
        case 4:
          day = 'Jeudi';
          break;
        case 5:
          day = 'Vendredi';
          break;
        case 6:
          day = 'Samedi';
          break;
        case 7:
          day = 'Dimanche';
          break;
      }
    }
    return (
      <div className={period.day ? 'item' : ''}>
        {day && !this.props.nolabel ?
          <div className="header">{day}</div>
          : undefined}
        <div className="ui middle relaxed aligned list">
          {objectives.length > 0 ?
            objectives
            : <div className="item">
              <div className="content">
                <i className="fa fa-square-o" style={{ marginRight: '10px' }} />
                Aucun objectif
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

@inject('store') @observer
export default class WritingProgramPage
  extends React.Component<WritingProgramPageProps, WritingProgramPageState> {
  static fetchData(store: Store) {
    return store.loadCurrentWritingProgram().then((r: any) => {
      return store.loadProgramAllTexts();
    });
  }
  constructor(props: WritingProgramPageProps) {
    super(props);
    this.validNewProgram = this.validNewProgram.bind(this);
    this.validEditProgram = this.validEditProgram.bind(this);
    this.addObjective = this.addObjective.bind(this);
    this.editObjective = this.editObjective.bind(this);
    this.removeObjective = this.removeObjective.bind(this);
    this.state = {
      loading: false
    };
  }
  validNewProgram() {
    const objectives = this.props.store.mainWritingProgram.newWp;
    this.props.store.createNewWritingProgram(objectives).then((d: any) => {
      this.props.store.loadCurrentWritingProgram().then((w: any) => {
        this.props.store.addAlert(new AlertModel(
          'cancelProgram',
          'Votre programme d\'écriture est lancé, bon courage !',
          '',
          'success',
          5
        ));
        browserHistory.push({
          pathname: '/'
        });
      });
    });
  }
  validEditProgram() {
    const objectives = this.props.store.mainWritingProgram.newWp;
    this.props.store.cancelWritingProgram().then((c: any) => {
      this.props.store.createNewWritingProgram(objectives).then((d: any) => {
        this.props.store.loadCurrentWritingProgram().then((w: any) => {
          this.props.store.addAlert(new AlertModel(
            'editProgram',
            'Votre programme d\'écriture est modifié.',
            '',
            'success',
            5
          ));
          browserHistory.push({
            pathname: '/'
          });
        });
      });
    });
  }
  addObjective(day: number, objType: string) {
    const objectives = this.props.store.mainWritingProgram.newWp;
    objectives.push({
      day: day,
      words: objType === 'words' ? 250 : undefined,
      /* defis: objType === 'defis' ? 1 : undefined,
      texts: objType === 'texts' ? 1 : undefined,
      chapters: objType === 'chapters' ? 1 : undefined, */
      linkedTextId: objType === 'chapters' ? this.props.store.mainWritingProgram.allTexts[0].id : undefined
/*       trainings: objType === 'trainings' ? 1 : undefined
 */    });
    this.props.store.mainWritingProgram.newWp = objectives;
  }
  editObjective(day: number, objType: string, value: number, linkedTextId?: number) {
    const objectives = this.props.store.mainWritingProgram.newWp.filter((o) => {
      return o.day !== day || (objType === 'words' ? o.words == null /* :
        (objType === 'defis' ? o.defis == null :
          (objType === 'texts' ? o.texts == null :
            (objType === 'chapters' ? o.chapters == null :
              (objType === 'trainings' ? o.trainings == null  */: false)/* )))) */;
    });

    objectives.push({
      day: day,
      words: objType === 'words' ? value : undefined
     /*  defis: objType === 'defis' ? value : undefined,
      texts: objType === 'texts' ? value : undefined,
      chapters: objType === 'chapters' ? value : undefined,
      linkedTextId: objType === 'chapters' ? linkedTextId : undefined,
      trainings: objType === 'trainings' ? value : undefined */
    });
    this.props.store.mainWritingProgram.newWp = objectives;
  }
  removeObjective(day: number, objType: string) {
    const newObjectives = this.props.store.mainWritingProgram.newWp.filter((o) => {
      return o.day !== day || (objType === 'words' ? o.words == null /* :
        (objType === 'defis' ? o.defis == null :
          (objType === 'texts' ? o.texts == null :
            (objType === 'chapters' ? o.chapters == null :
              (objType === 'trainings' ? o.trainings == null */ : false)/* )))) */;
    });

    this.props.store.mainWritingProgram.newWp = newObjectives;
  }
  render() {
    const wp = this.props.store.mainWritingProgram;
    const objectives = wp && wp.newWp ? wp.newWp : [];
    return (
      <div className="ui read container" style={{ paddingBottom: '80px' }}>
        <Title title="Mon programme d'écriture" />
        <h2 className="ui header">Mon programme d'écriture</h2>
        <div>
          <div className="ui warning message">
            <div className="header">Votre progression en cours de la semaine sera annulée.</div>
          </div>
          <div className="ui form">
            {/* <div className="ui header">
              Objectif hebdomadaire
                    </div>
            <WritingProgramEdit objectives={objectives.filter(r => !r.day)}
              removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
              allTexts={wp.allTexts} /> */}
            <div className="ui header">
              Objectifs quotidiens
                    </div>
            <div className="ui middle very relaxed aligned list">
              <WritingProgramEdit day={1} objectives={objectives.filter(r => r.day === 1)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={2} objectives={objectives.filter(r => r.day === 2)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={3} objectives={objectives.filter(r => r.day === 3)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={4} objectives={objectives.filter(r => r.day === 4)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={5} objectives={objectives.filter(r => r.day === 5)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={6} objectives={objectives.filter(r => r.day === 6)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
              <WritingProgramEdit day={7} objectives={objectives.filter(r => r.day === 7)}
                removeObjective={this.removeObjective} addObjective={this.addObjective} editObjective={this.editObjective}
                allTexts={wp.allTexts} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <button className="ui primary submit right floated button" onClick={this.validEditProgram}>Modifier le programme</button>
        </div>
        <div style={{ marginTop: '70px' }}>
          <Link to="/" className="ui cancel right floated button">Retour à l'accueil</Link>
        </div>
      </div >
    );
  }
}
