import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import NewDraftModel from '../../../models/write/NewDraftModel';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import NewTextStructureModal from './NewTextStructureModal';
import CannotCanvasModal from './CannotCanvasModal';
import CannotParcoursModal from './CannotParcoursModal';
import TitleModal from './TitleModal';
import ParcoursModal from './ParcoursModal';
import NewCanvasModal from './NewCanvasModal';
import ParcoursModel from '../../../models/parcours/ParcoursModel';
import CanvasModel from '../../../models/canvas/CanvasModel';
import Store from '../../../stores/Store';

interface NewTextModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
}

interface NewTextModalState {
  activeStructure: boolean;
  activeCanvas: CanvasModel;
  activeParcours: boolean;
  activeTitle: boolean;
  activeCannotParcours: boolean;
  activeCannotCanvas: boolean;
  title: string;
  activeLinkProgram: boolean;
  linkedToProgram: boolean;
  afterTitle: Function;
  selectedParcoursTitle: string;
  selectedParcoursDescription: string;
}

@inject('store') @observer
export default class NewTextModal
  extends React.Component<NewTextModalProps, NewTextModalState> {
  constructor(props: NewTextModalProps) {
    super(props);
    this.createFromStructure = this.createFromStructure.bind(this);
    this.showStructure = this.showStructure.bind(this);
    this.hideStructure = this.hideStructure.bind(this);
    this.showCanvas = this.showCanvas.bind(this);
    this.hideCanvas = this.hideCanvas.bind(this);
    this.createNewBlankText = this.createNewBlankText.bind(this);
    this.confirmCreateNewBlankText = this.confirmCreateNewBlankText.bind(this);
    this.createNewTextFromCanvas = this.createNewTextFromCanvas.bind(this);
    this.confirmCreateNewTextFromCanvas = this.confirmCreateNewTextFromCanvas.bind(this);
    this.showParcours = this.showParcours.bind(this);
    this.hideParcours = this.hideParcours.bind(this);
    this.showCannotParcours = this.showCannotParcours.bind(this);
    this.hideCannotParcours = this.hideCannotParcours.bind(this);
    this.startNewCanvasText = this.startNewCanvasText.bind(this);
    this.showCannotCanvas = this.showCannotCanvas.bind(this);
    this.hideCannotCanvas = this.hideCannotCanvas.bind(this);
    this.cancelTitle = this.cancelTitle.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);

    this.state = {
      activeStructure: false,
      activeCanvas: undefined,
      activeParcours: false,
      activeTitle: false,
      activeCannotParcours: false,
      activeCannotCanvas: false,
      title: '',
      afterTitle: undefined,
      activeLinkProgram: false,
      linkedToProgram: false,
      selectedParcoursTitle: undefined,
      selectedParcoursDescription: undefined
    };
  }
  createFromStructure() {
    const premium = this.props.store.connected.premium.premium;
    this.setState({
      activeTitle: true,
      title: '',
      afterTitle: this.showStructure
    });
  }
  showStructure() {
    this.setState({
      activeStructure: true
    });
  }
  hideStructure() {
    this.setState({
      activeStructure: false
    });
  }
  showCanvas(canvas: CanvasModel) {
    this.setState({
      activeCanvas: canvas
    });
  }
  hideCanvas() {
    this.setState({
      activeCanvas: undefined
    });
  }
  showParcours(parcours: ParcoursModel) {
    this.props.store.loadParcoursPrerequisites(parcours.id).then((r: any) => {
      this.setState({
        activeParcours: true
      });
    });
  }
  hideParcours() {
    this.props.store.mainParcours.currentParcours = undefined;
    this.setState({
      activeParcours: false
    });
  }
  showCannotCanvas() {
    this.setState({
      activeCannotCanvas: true
    });
  }
  hideCannotCanvas() {
    this.setState({
      activeCannotCanvas: false
    });
  }
  showCannotParcours(parcours: ParcoursModel) {
    this.setState({
      activeCannotParcours: true,
      selectedParcoursTitle: parcours.name,
      selectedParcoursDescription: parcours.description
    });
  }
  hideCannotParcours() {
    this.setState({
      activeCannotParcours: false,
      selectedParcoursTitle: undefined,
      selectedParcoursDescription: undefined
    });
  }
  cancelTitle() {
    this.setState({
      activeTitle: false,
      title: ''
    });
  }
  handleTitleChange(e: any) {
    this.setState({
      title: e.target.value
    });
  }
  createNewBlankText() {
    const premium = this.props.store.connected.premium.premium;
    this.setState({
      activeTitle: true,
      title: '',
      afterTitle: this.confirmCreateNewBlankText
    });
  }
  startNewCanvasText() {
    const premium = this.props.store.connected.premium.premium;
    this.setState({
      activeTitle: true,
      title: this.state.activeCanvas.title,
      afterTitle: this.confirmCreateNewTextFromCanvas
    });
  }
  confirmCreateNewBlankText() {
    this.props.store.createNewTextBlank(this.state.title).then((draft: NewDraftModel) => {
      if (this.state.linkedToProgram) {
        const objectives = [
          { chapters: 1, linkedTextId: draft.textId },
          { day: 1, words: 250 },
          { day: 2, words: 250 },
          { day: 3, words: 250 },
          { day: 4, words: 250 },
          { day: 5, words: 250 },
          { day: 6, words: 250 },
          { day: 7, words: 250 }
        ];
        this.props.store.cancelWritingProgram().then((c: any) => {
          this.props.store.createNewWritingProgram(objectives).then((d: any) => {
            browserHistory.push({
              pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
              state: {
                newPart: false,
                showCongratulationsChapter: false
              }
            });
          });
        });
      } else {
        browserHistory.push({
          pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
          state: {
            newPart: false,
            showCongratulationsChapter: false
          }
        });
      }
    });
  }
  createNewTextFromCanvas() {
    const premium = this.props.store.connected.premium.premium;
    this.setState({
      activeTitle: true,
      title: '',
      afterTitle: this.confirmCreateNewTextFromCanvas
    });
  }
  confirmCreateNewTextFromCanvas() {
    this.props.store.createNewTextFromCanvas(this.state.activeCanvas.id, this.state.title).then((draft: NewDraftModel) => {
      if (this.state.linkedToProgram) {
        const objectives = [
          { chapters: 1, linkedTextId: draft.textId },
          { day: 1, words: 250 },
          { day: 2, words: 250 },
          { day: 3, words: 250 },
          { day: 4, words: 250 },
          { day: 5, words: 250 },
          { day: 6, words: 250 },
          { day: 7, words: 250 }
        ];
        this.props.store.cancelWritingProgram().then((c: any) => {
          this.props.store.createNewWritingProgram(objectives).then((d: any) => {
            browserHistory.push({
              pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
              state: {
                newPart: false,
                showCongratulationsChapter: false
              }
            });
          });
        });
      } else {
        browserHistory.push({
          pathname: `/text/${draft.textId}/${draft.urlText}/chapter/${draft.chapterId}/edit`,
          state: {
            newPart: false,
            showCongratulationsChapter: false
          }
        });
      }
    });
  }
  render() {
    const premium = this.props.store.connected.premium.premium;
    const canvas = this.props.store.mainCanvas.allCanvas.map((c) => {
      return (
        premium ?
        <div key={`canvas_${c.id}`} className="card">
          <div className={'ui ' + (!premium ? 'disabled ' : '') + 'image'}>
            <img src={c.image} onClick={() => premium ? this.showCanvas(c) : this.showCannotCanvas()} style={{ cursor: 'pointer' }}
              alt={`Image du canevas ${c.title}`} title={c.title} />
          </div>
          <div className="extra" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} title={c.title}>
            {c.title}
          </div>
        </div>
        : undefined
      );
    });
    /* const parcours = this.props.store.mainParcours.allParcours.map((p) => {
      return (
        premium ? 
        <div key={`parcours_${p.id}`} className="card">
          <div className="ui top right attached orange label" style={{ zIndex: 5 }}>Parcours</div>
          <div className={'ui ' + (!premium ? 'disabled ' : '') + 'image'}>
            <img src={p.image} onClick={() => premium ? this.showParcours(p) : this.showCannotParcours(p)} style={{ cursor: 'pointer' }}
              alt={`Image du parcours ${p.name}`} />
          </div>
          <div className="extra">
            {p.name}
          </div>
        </div>
        : undefined
      );
    }); */
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.hide}
      >
        <div className="header">Nouvelle œuvre</div>
        <div className="content" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
          <TitleModal
            active={this.state.activeTitle}
            title={this.state.title}
            handleTitleChange={this.handleTitleChange}
            cancel={this.cancelTitle}
            next={this.state.afterTitle}
          />
          <NewTextStructureModal
            active={this.state.activeStructure}
            hide={this.hideStructure}
            title={this.state.title}
            linkedToProgram={this.state.linkedToProgram}
          />
          <ParcoursModal active={this.state.activeParcours} hide={this.hideParcours} />
          {/* <NewCanvasModal activeCanvas={this.state.activeCanvas} hide={this.hideCanvas}
            startCanvas={this.startNewCanvasText} />
          <CannotCanvasModal
            active={this.state.activeCannotCanvas}
            hide={this.hideCannotCanvas}
          /> */}{/* 
          <CannotParcoursModal
            active={this.state.activeCannotParcours}
            hide={this.hideCannotParcours}
            title={this.state.selectedParcoursTitle}
            description={this.state.selectedParcoursDescription}
          /> */}
          <div className="ui stackable centered four cards">
            <div className="card">
              <div className="image">
                <img
                  onClick={this.createNewBlankText}
                  src="https://scribay.s3.amazonaws.com/assets/whitepage.png"
                  style={{ cursor: 'pointer' }}
                  alt="Page blanche"
                />
              </div>
              <div className="extra">
                Page blanche
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img
                  onClick={this.createFromStructure}
                  src="https://scribay.s3.amazonaws.com/assets/structure.png"
                  style={{ cursor: 'pointer' }}
                  alt="Structure"
                />
              </div>
              <div className="extra">
                Structure
              </div>
            </div>
            {/* {premium ? parcours : undefined} */}
          </div>{/* 
          <div>
            {premium ? 
            <h3 className="ui horizontal divider" style={{ marginTop: '30px' }}>Canevas</h3>
            : undefined }
            <div className="ui basic segment" style={{ paddingBottom: '40px' }}>
              <div className="ui stackable four cards">
                {canvas}
              </div>
            </div>
          </div> */}
        </div>
        <div className="actions">
          <button className="ui cancel button" onClick={this.props.hide}>Annuler</button>
        </div>
      </Modal>
    );
  }
}
