export default class ProfileHeatMap {
  date: string;
  count: number;

  constructor(date: string, count: number) {
    this.date = date;
    this.count = count;
  }

  toJS() {
    return {
      date: this.date,
      count: this.count
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new ProfileHeatMap(object.date, object.count);
    }
    return undefined;
  }
}
