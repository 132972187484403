import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Title from '../misc/Title';
import * as superagent from 'superagent';
const Iframe = require('react-iframe').default;
import Loader from '../misc/Loader';
import Store from '../../stores/Store';
import { Link } from 'react-router';

interface PaymentConfirmationPageProps {
  store?: Store;
}

interface PaymentConfirmationPageState {
  loading: boolean;
} 

declare const __CLIENT__: any;

@inject('store') @observer
export default class PaymentConfirmationPage extends React.Component<PaymentConfirmationPageProps, PaymentConfirmationPageState> {
  static fetchData(store: Store) {
    return store.loadConnected();
  }
  constructor(props: PaymentConfirmationPageProps) {
    super(props);
    this.state = {
      loading: true
    };
  }
  goToHomePage() {
    window.location.href = "/?m=w"
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false});
    }, 5000);
  }

  stateChange() {
    
}

  render() {
    return (
      <div>
        <Title title="Paiement réussi !"/>
        <div className="ui grid centered stackable">
          <div className="ten wide column">
            <div className="ui info message" style={{marginBottom: "15px"}}>
              <p>Félicitations, votre inscription est finalisée !</p>
              <p>Veuillez patienter quelques secondes, nous finissons de configurer votre compte !</p>
              
                <button onClick={() => this.goToHomePage()} className={'ui button' + (this.state.loading ? ' loading disabled' : '')} style={{marginBottom: "10px"}}>Page d’accueil de l’école</button>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}
