import { observable } from 'mobx';

class LightChapterModel {
  @observable id: number;
  @observable index: number;
  @observable title: string;
}

export default class CurrentTextModel {

  @observable id: number;
  @observable title: string;
  @observable urlText: string;
  @observable cover: string;
  @observable chapters: LightChapterModel[];
  @observable lastModificationAgo: string;

  constructor(id: number, title: string, urlText: string, cover: string,
    chapters: LightChapterModel[], lastModificationAgo: string) {
    this.id = id;
    this.title = title;
    this.urlText = urlText;
    this.cover = cover;
    this.chapters = chapters;
    this.lastModificationAgo = lastModificationAgo;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      cover: this.cover,
      chapters: this.chapters.map,
      lastModificationAgo: this.lastModificationAgo
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CurrentTextModel(object.id, object.title, object.urlText,
        object.cover, object.chapters, object.lastModificationAgo);
    }
    return undefined;
  }
}
