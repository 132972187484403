import * as React from 'react';
import { inject, observer } from 'mobx-react';
const escapeStringRegexp = require('escape-string-regexp');
import MentionList from './MentionList';
import UserModel from '../../models/UserModel';
import Store from '../../stores/Store';

const KEYS = {
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  UP: 38,
};

interface MentionProps {
  store?: Store;
  handleTextChange: Function;
  author: UserModel;
  text: string;
  focus: boolean;
  remaining: number;
}

interface MentionState {
  isAutocomplete: boolean;
  autocomplete: string;
  selectables: { value: string, label: string }[];
  selectedIndex: number;
}

@inject('store') @observer
export default class Mention extends React.Component<MentionProps, MentionState> {
  refs: {
    [string: string]: any;
    textarea: any;
  };

  constructor(props: MentionProps) {
    super(props);
    this.state = {
      autocomplete: '',
      isAutocomplete: false,
      selectables: [],
      selectedIndex: 0
    };
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.strip = this.strip.bind(this);
    this.insertSelected = this.insertSelected.bind(this);
  }
  componentDidMount() {
    if (this.props.focus) {
      this.refs.textarea.focus();
    }
  }
  setSelectedItem(keyCode: number) {
    let selectedIdx: number;

    if (keyCode === KEYS.UP) {
      selectedIdx = this.state.selectedIndex === 0 ?
        this.state.selectables.length - 1 :
        this.state.selectedIndex - 1;
    } else if (keyCode === KEYS.DOWN) {
      selectedIdx = this.state.selectedIndex === this.state.selectables.length - 1 ?
        0 :
        this.state.selectedIndex + 1;
    }

    this.setState({
      autocomplete: this.state.autocomplete,
      isAutocomplete: this.state.isAutocomplete,
      selectables: this.state.selectables,
      selectedIndex: selectedIdx
    });
  }
  handleTextChange(e: any) {
    this.props.handleTextChange(e.target.value, 9000 - e.target.value.length);
  }
  handleKeyDown(e: any) {
    if (e.which === KEYS.DOWN || e.which === KEYS.UP) {
      if (this.state.selectables.length !== 0) {
        e.preventDefault();
      }
    }
  }
  handleKeyPress(e: any) {
    if (e.which === KEYS.UP || e.which === KEYS.DOWN) {
      return this.setSelectedItem(e.which);
    }

    if (e.which === 27 || e.which === 13) { // Press enter or escape
      this.setState({
        autocomplete: '',
        isAutocomplete: false,
        selectables: [],
        selectedIndex: 0
      });
    } else if (String.fromCharCode(e.which) === '@') {
      this.setState({
        autocomplete: '',
        isAutocomplete: !this.state.autocomplete,
        selectables: [],
        selectedIndex: 0
      });
    } else {
      if (this.state.isAutocomplete) {
        const acp = this.state.autocomplete + String.fromCharCode(e.which);

        if (acp.length >= 3) {
          this.props.store.getRelations(acp.trim()).then((r: { value: string, label: string }[]) => {
            const selectables = r.filter(item => {
              return (item.label.toLowerCase() as any).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                .indexOf((acp.trim().toLowerCase() as any).normalize('NFD').replace(/[\u0300-\u036f]/g, "")) !== -1;
            });
            this.setState({
              autocomplete: acp,
              isAutocomplete: this.state.isAutocomplete,
              selectables: selectables,
              selectedIndex: this.state.selectedIndex
            });
          });
        } else {
          this.setState({
            autocomplete: acp,
            isAutocomplete: this.state.isAutocomplete,
            selectables: [],
            selectedIndex: this.state.selectedIndex
          });
        }
      }
    }
  }
  strip(html: any) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
  insertSelected(itemId: number, itemIdentity: string, word: string) {
    const regexp = new RegExp(`@(?:^|\\s)${escapeStringRegexp(word)}(?:^|\\s)`, 'g');
    const text = this.props.text.replace(regexp, `@${itemIdentity}@`);
    this.props.handleTextChange(text, 9000 - this.strip(text).length);
    this.setState({
      autocomplete: '',
      isAutocomplete: false,
      selectables: [],
      selectedIndex: 0
    });
  }
  render() {
    return (
      <div className={(this.props.remaining < 0 ? 'error' : '') + ' field'}>
        <textarea
          ref="textarea"
          rows={5}
          placeholder="Votre texte"
          onChange={this.handleTextChange}
          value={this.props.text}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          aria-label="Votre texte"
        />

        <MentionList
          isAutocomplete={this.state.isAutocomplete}
          selectedIdx={this.state.selectedIndex}
          data={this.state.selectables}
          word={this.state.autocomplete}
          insertFn={this.insertSelected}
        />

        {this.props.remaining < 0 ?
          <span style={{ color: '#912d2b' }}>{this.props.remaining} caractères</span>
          : undefined}
      </div>
    );
  }
}
