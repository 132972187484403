import { observable } from 'mobx';

export default class CanvasModel {

  @observable id: number;
  @observable title: string;
  @observable description: string;
  @observable image: string;

  constructor(id: number, title: string, description: string, image: string) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.image = image;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new CanvasModel(object.id, object.title, object.description, object.image);
    }
    return undefined;
  }
}
