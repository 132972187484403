import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import Notification from './Notification';
import Store from '../../stores/Store';

interface NotificationsListProps {
  store?: Store;
}

interface NotificationsListState {
  active: boolean;
}

@inject('store') @observer
export default class NotificationsList extends React.Component<NotificationsListProps, NotificationsListState> {
  constructor(props: NotificationsListProps) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      active: false
    };
  }
  // componentDidMount() {
  //   if (this.props.store.socket) {
  //     this.props.store.socket.on('new notification ack', (e: any) => {
  //       this.props.store.connected.increaseNotificationsCount();
  //     });
  //   }
  // }
  open() {
    this.props.store.loadNotifications().then((d: any) => {
      this.setState({
        active: true
      });
    });
  }
  @action
  close() {
    this.props.store.connected.notificationsCount = 0;
    this.setState({
      active: false
    });
  }
  render() {
    const connected = this.props.store.connected;
    const notifs = connected.notifications.map(n => <Notification key={n.id} userId={connected.user.id} notif={n} />);
    return (
      <span>
        {connected.notificationsCount > 0 ?
          <Popup
            position="bottom right"
            on="click"
            flowing
            open={this.state.active}
            onOpen={this.open}
            onClose={this.close}
            trigger={
              <div
                className={'ui label' + (connected.notificationsCount > 0 ? ' orange' : '')}
                style={{ cursor: 'pointer', marginRight: '10px' }}
                title={`${connected.notificationsCount} non lues`}
              >
                <i
                  className="fa fa-bell"
                  style={{ marginRight: '5px' }}
                />
                {connected.notificationsCount}
              </div>
            }
          >
            {notifs.length > 0 ?
              <div className="ui small feed" style={{ marginBottom: '20px' }}>
                {notifs}
              </div>
              : undefined}
            <Link to="/notifications">Toutes les notifications</Link>
          </Popup>
          :
          <Link to="/notifications" className="ui label" title="Notifications"
            style={{ marginRight: '10px' }}>
            <i
              className="fa fa-bell"
              style={{ marginRight: '5px' }}
            />
            {connected.notificationsCount}
          </Link>
        }
      </span>
    );
  }
}
