import * as React from 'react';
import { Link } from 'react-router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Select, { AsyncCreatable } from 'react-select';
const throttle = require('throttle-debounce').throttle;
import { FormattedMessage } from 'react-intl';
import Store from '../../stores/Store';

interface TextGenresTagsProps {
  store?: Store;
  strangerMode: boolean;
}

interface TextGenresTagsState {
  modalErotiqueActive: boolean;
  genresTagsEditMode: boolean;
  genres: string[];
  tags: { label: string, value: string }[];
  allGenres: { label: string, value: string }[];
}

require('react-select/dist/react-select.min.css');

@inject('store') @observer
export default class TextGenresTags extends React.Component<TextGenresTagsProps, TextGenresTagsState> {
  constructor(props: TextGenresTagsProps) {
    super(props);
    this.startGenresTagsEdit = this.startGenresTagsEdit.bind(this);
    this.cancelGenresTagsEdit = this.cancelGenresTagsEdit.bind(this);
    this.handleSelectGenreChange = this.handleSelectGenreChange.bind(this);
    this.fetchTags = throttle(1000, this.fetchTags.bind(this));
    this.handleSelectTagChange = this.handleSelectTagChange.bind(this);
    this.saveGenresTagsEdit = this.saveGenresTagsEdit.bind(this);
    this.hideModalErotiqueActive = this.hideModalErotiqueActive.bind(this);
    this.state = {
      modalErotiqueActive: false,
      genresTagsEditMode: false,
      genres: [],
      tags: [],
      allGenres: []
    };
  }
  fetchTags(input: string, callback: Function) {
    this.props.store.getReadTagsBeginningWith(input).then((tags: any) => {
      const options = tags.map((t: string) => ({
        value: t,
        label: t,
      }));

      callback(null, { options });
    });
  }
  startGenresTagsEdit() {
    const genres = this.props.store.text.infos.genres;
    const tags = this.props.store.text.infos.tags;
    this.props.store.loadAllGenres().then((g: any) => {
      this.setState({
        genresTagsEditMode: true,
        genres: genres.map(g => String(g)),
        tags: tags.map(v => {
          return { label: v, value: v };
        }),
        allGenres: g.allGenres
      });
    });
  }
  cancelGenresTagsEdit() {
    this.setState({
      genresTagsEditMode: false,
      genres: [],
      tags: [],
      allGenres: []
    });
  }
  handleSelectGenreChange(value: { label: string, value: string }[]) {
    const notDefaultSensibleContent = !this.props.store.connected.preferences
      || !this.props.store.connected.preferences.defaultSensibleContent;

    if (value.map(v => v.value).indexOf('7') !== - 1 && notDefaultSensibleContent) {
      this.setState({
        modalErotiqueActive: true
      });
    } else {
      if (value.length > 3) {
        value.shift();
      }
      const newValues = value.map(v => v.value);
      this.setState({
        genresTagsEditMode: this.state.genresTagsEditMode,
        genres: newValues,
        tags: this.state.tags,
        allGenres: this.state.allGenres
      });
    }
  }
  handleSelectTagChange(value: [{ create: boolean, label: string, value: string }]) {
    if (value.length > 5) {
      value.shift();
    }
    const tags = value.map(v => {
      return { label: v.label, value: v.value };
    });
    this.setState({
      genresTagsEditMode: this.state.genresTagsEditMode,
      genres: this.state.genres,
      tags: tags,
      allGenres: this.state.allGenres
    });
  }
  @action
  saveGenresTagsEdit(e: any) {
    e.preventDefault();
    const tags = this.state.tags.map(t => t.value.trim()).filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
    this.props.store.saveTextGenresTags(this.state.genres, tags).then((er: any) => {
      if (this.state.genres.indexOf('7') !== - 1) {
        this.props.store.text.meta.sensibleContent = true;
      }
      this.setState({
        genresTagsEditMode: false,
        genres: [],
        tags: [],
        allGenres: []
      });
    });
  }
  hideModalErotiqueActive() {
    this.setState({
      modalErotiqueActive: false
    });
  }
  render() {
    const text = this.props.store.text;
    const infos = text.infos;
    const connected = this.props.store.connected;
    const isAuthor = text && text.meta && text.meta.author
      && connected && connected.user && connected.user.id
      && connected.user.id === text.meta.author.id;

    const genres = infos.genres.map(g => {
      return (
        <span key={g} className="ui label" style={{ marginBottom: '5px' }}>
          <FormattedMessage
            id={`genre.${g}`}
            defaultMessage="Genre"
          />
        </span>
      );
    });
    const tags = infos.tags.map(t => {
      return (
        <span key={Math.random()} className="ui label" style={{ marginBottom: '5px' }}>
          {t}
        </span>
      );
    });
    return (
      <div>
        {isAuthor && !this.props.strangerMode && this.state.genresTagsEditMode ?
          <div className="ui segment" style={{ paddingBottom: '40px' }}>
            <Modal
              open={this.state.modalErotiqueActive}
              size="small"
            >
              <div className="content">
                Pour sélectionner le genre Érotique, vous devez au préalable
                      cocher la case <b>Accéder aux œuvres avec un contenu sensible</b> dans
                      &nbsp;<Link to="/settings">Paramètres > Préférences</Link>.
              </div>
              <div className="actions">
                <Link className="ui primary button" to="/settings">Modifier mes préférences</Link>
                <button className="ui cancel button" onClick={this.hideModalErotiqueActive}>Annuler</button>
              </div>
            </Modal>
            <form
              className="ui form"
              onSubmit={this.saveGenresTagsEdit}
            >
              <div className="ui message warning">
                Sélectionnez jusqu'à trois genres et aidez votre œuvre à mieux trouver son lectorat !
            </div>
              <Select
                options={this.state.allGenres}
                multi={true}
                value={this.state.genres.map(g => this.state.allGenres.filter(ag => String(ag.value) === String(g))[0])}
                onChange={this.handleSelectGenreChange}
                placeholder="Aucun genre sélectionné"
                noResultsText="Aucun résultat"
                ignoreCase={true}
                ignoreAccents={false}
                aria-label="Genres"
              />
              {this.state.genres.indexOf('7') !== - 1 ?
                <div>
                  <i>La sélection du genre Érotique activera automatiquement l'option "Contenu sensible".</i>
                </div>
                : undefined}
              <div className="ui message warning">
                Des mots-clés bien choisis favoriseront la présence de
            votre œuvre au sein de la Bibliothèque (5 maximum).
            </div>
              <AsyncCreatable
                loadOptions={this.fetchTags}
                multi={true}
                value={this.state.tags}
                onChange={this.handleSelectTagChange}
                promptTextCreator={(label: string) => `Ajouter mot-clé: ${label}`}
                placeholder="Aucun mot-clé"
                noResultsText="Aucun mot-clé"
                loadingPlaceholder="Chargement..."
                ignoreCase={true}
                ignoreAccents={false}
                aria-label="Mots-clés"
                searchPromptText="Entrez un mot-clé"
              />
              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  className="ui mini right floated button"
                  onClick={this.cancelGenresTagsEdit}
                >
                  Annuler
              </button>
                <button
                  className="ui blue right floated mini button"
                  onClick={this.saveGenresTagsEdit}
                  type="submit"
                >
                  Enregistrer
            </button>
              </div>
            </form>
          </div>
          :
          <div style={{ overflow: 'auto' }}>
            {genres}
            {tags}
            {isAuthor && !this.props.strangerMode ?
              <button className="ui right floated orange small label" onClick={this.startGenresTagsEdit}>
                {genres.length === 0 && tags.length === 0
                  ? 'Ajouter des genres et mots-clés'
                  : 'Modifier les genres et mots-clés'}
              </button>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
