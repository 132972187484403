import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../stores/Store';

interface LandingPageAuthorsBioModalProps {
  store?: Store;
  active: boolean;
  hide: Function | any;
  bio: string;
  name: string;
}

interface LandingPageAuthorsBioModalState {
  constraint: boolean;
}

@inject('store') @observer
export default class LandingPageAuthorsBioModal
  extends React.Component<LandingPageAuthorsBioModalProps, LandingPageAuthorsBioModalState> {
  constructor(props: LandingPageAuthorsBioModalProps) {
    super(props);
    this.state = {
      constraint: false
    };
  }
  
  render() {
      return (
        <Modal
          open={this.props.active}
          onClose={this.props.hide}
        >
          <div className="header">{this.props.name}</div>
          <div className="content" style={{maxHeight: '400px' }}>
          <span dangerouslySetInnerHTML={{ __html: this.props.bio }}></span>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.hide}>Fermer</button>
          </div>
      </Modal>
      ); 
  }
}
