import * as React from 'react';
import List from 'semantic-ui-react/dist/commonjs/elements/List/List';
import ListItem from 'semantic-ui-react/dist/commonjs/elements/List/ListItem';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';

interface RepetitionPopupProps {
  decoratedText: string;
}

interface RepetitionPopupState {
  synonyms: string[];
}

export default class RepetitionPopup extends React.Component<RepetitionPopupProps, RepetitionPopupState> {
  constructor(props: RepetitionPopupProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { synonyms: [] };
  }
  handleClick() {
    // const that = this;
    // const post = JSON.stringify({ word: this.props.decoratedText });
    // $.ajax({
    //   url: '/write/synonyms/',
    //   contentType: 'application/json',
    //   type: 'POST',
    //   data: post,
    //   success(synonyms) {
    //     that.setState({ synonyms });
    //     that.refs.repetitionPopup.show();
    //   },
    //   error(xhr, status, err) {
    //     console.error(err.toString());
    //   },
    // });
  }
  render() {
    const synonyms = this.state.synonyms.map(s =>
      <div className="item">
        {s}
      </div>
    );
    return (
      <span>
        <Popup
          trigger={
            <span
              title="Avertissement : cliquez pour afficher"
              className="write-warning"
              >
              {this.props.children}
            </span>
          }
          position="bottom center"
          size="large"
          >
          Le terme <strong>{this.props.decoratedText}</strong> est une répétition.
            {synonyms.length > 0 ?
            <div>
              Vous pouvez le remplacer avec un des termes suivants:
              <div className="ui list">
                {synonyms}
              </div>
            </div>
            : ''
          }
        </Popup>
      </span>
    );
  }
}
