import { observable } from 'mobx';
import DraftModel from './DraftModel';
import LightChapterModel from './LightChapterModel';

export default class DraftDefiModel extends DraftModel {

  @observable defiTitle: string;
  @observable deadline: string;

  constructor(id: number, title: string, urlText: string, chapters: LightChapterModel[],
    lastModificationDate: string, defiTitle: string, deadline: string) {
    super(id, title, urlText, chapters, lastModificationDate);
    this.defiTitle = defiTitle;
    this.deadline = deadline;
  }

  toJS() {
    return {
      id: this.id,
      title: this.title,
      urlText: this.urlText,
      chapters: this.chapters,
      lastModificationDate: this.lastModificationDate,
      defiTitle: this.defiTitle,
      deadline: this.deadline
    };
  }

  static fromJS(object: any) {
    if (object) {
      return new DraftDefiModel(object.id, object.title, object.urlText, object.chapters,
        object.lastModificationDate, object.defiTitle, object.deadline);
    }
    return undefined;
  }
}
