import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Store from '../../stores/Store';
import Loader from '../misc/Loader';
import AlertModel from '../../models/AlertModel';

interface SettingsPageAccountProps {
  store: Store;
}

interface SettingsPageAccountState {
  emailError: boolean;
  globalPasswordError: boolean;
  strongPasswordError: boolean;
  matchingPasswordError: boolean;
  currentPasswordError: boolean;
  emailAlreadyExists: boolean;
}

@inject('store') @observer
export default class SettingsPageAccount extends React.Component<SettingsPageAccountProps, SettingsPageAccountState> {
  static fetchData(store: Store) {
    return store.loadSettingsAccount();
  }
  constructor(props: SettingsPageAccountProps) {
    super(props);
    this.state = {
      emailError: false,
      globalPasswordError: false,
      strongPasswordError: false,
      matchingPasswordError: false,
      currentPasswordError: false,
      emailAlreadyExists: false
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCurrentPasswordChange = this.handleCurrentPasswordChange.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.sendConfirmChangeEmail = this.sendConfirmChangeEmail.bind(this);
    this.cancelChangeEmail = this.cancelChangeEmail.bind(this);
    this.postFormPassword = this.postFormPassword.bind(this);
    this.postFormEmail = this.postFormEmail.bind(this);
  }
  @action
  handleEmailChange(e: any) {
    const emailError = this.state.emailError && e.target.value.trim().length === 0;
    this.props.store.mainSettings.account.email = e.target.value;
    this.setState({
      emailError: emailError,
      emailAlreadyExists: false,
      globalPasswordError: this.state.globalPasswordError,
      strongPasswordError: this.state.strongPasswordError,
      matchingPasswordError: this.state.matchingPasswordError,
      currentPasswordError: this.state.currentPasswordError,
    });
  }
  @action
  handleCurrentPasswordChange(e: any) {
    const globalPasswordError = this.state.globalPasswordError && e.target.value.trim().length === 0;
    const strongPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    const matchingPasswordError = this.state.matchingPasswordError && e.target.value.trim().length === 0;
    const currentPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    this.props.store.mainSettings.account.currentPassword = e.target.value;
    this.setState({
      emailError: this.state.emailError,
      globalPasswordError: globalPasswordError,
      strongPasswordError: strongPasswordError,
      matchingPasswordError: matchingPasswordError,
      currentPasswordError: currentPasswordError
    });
  }
  @action
  handleNewPasswordChange(e: any) {
    const globalPasswordError = this.state.globalPasswordError && e.target.value.trim().length === 0;
    const strongPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    const matchingPasswordError = this.state.matchingPasswordError && e.target.value.trim().length === 0;
    const currentPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    this.props.store.mainSettings.account.newPassword = e.target.value;
    this.setState({
      emailError: this.state.emailError,
      globalPasswordError: globalPasswordError,
      strongPasswordError: strongPasswordError,
      matchingPasswordError: matchingPasswordError,
      currentPasswordError: currentPasswordError
    });
  }
  @action
  handleConfirmPasswordChange(e: any) {
    const globalPasswordError = this.state.globalPasswordError && e.target.value.trim().length === 0;
    const strongPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    const matchingPasswordError = this.state.matchingPasswordError && e.target.value.trim().length === 0;
    const currentPasswordError = this.state.strongPasswordError && e.target.value.trim().length === 0;
    this.props.store.mainSettings.account.confirmPassword = e.target.value;
    this.setState({
      emailError: this.state.emailError,
      globalPasswordError: globalPasswordError,
      strongPasswordError: strongPasswordError,
      matchingPasswordError: matchingPasswordError,
      currentPasswordError: currentPasswordError
    });
  }
  @action
  emptyPasswordFields() {
    this.props.store.mainSettings.account.currentPassword = '';
    this.props.store.mainSettings.account.newPassword = '';
    this.props.store.mainSettings.account.confirmPassword = '';
  }
  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  postFormEmail(event: any) {
    event.preventDefault();
    const form = this.props.store.mainSettings.account;
    const emailError = !form.email || !this.validateEmail(form.email);
      if (!this.state.emailAlreadyExists && !emailError) {
      this.props.store.saveSettingsAccountEmail().then(
        (r: any) => {
          this.props.store.loadConnected().then((r2: any) => {
            form.disableLoading();
            this.props.store.addAlert(new AlertModel(
              'saveAccount',
              'Sauvegardé !',
              '',
              'success',
              5
            ));
          });
        },
        (err: any) => {
          form.disableLoading();
          if (err.status === 409) {
            this.setState({
              emailAlreadyExists: true
            });
          }
          else
            this.setState({
              emailError: emailError
            });
        });
    } else {
      this.setState({
        emailError: emailError
      });
    }
  }
  postFormPassword(event: any) {
    event.preventDefault();
    const form = this.props.store.mainSettings.account;
    const globalPasswordError = (form.currentPassword.trim() !== ''
      && (form.newPassword.trim() === '' || form.confirmPassword.trim() === ''))
      || (form.newPassword.trim() !== ''
        && (form.currentPassword.trim() === '' || form.confirmPassword.trim() === ''))
      || (form.confirmPassword.trim() !== ''
        && (form.currentPassword.trim() === '' || form.newPassword.trim() === ''));

    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    const strongPasswordError = form.newPassword !== undefined
      && form.newPassword.length !== 0 && !strongPassword.test(form.newPassword);
    const matchingPasswordError =
      (form.newPassword.trim().length > 0 && form.confirmPassword.trim().length === 0)
      || (form.newPassword.trim().length === 0 && form.confirmPassword.trim().length > 0)
      || form.newPassword !== form.confirmPassword;

      if (!globalPasswordError && !strongPasswordError && !matchingPasswordError) {
      this.props.store.saveSettingsAccountPassword().then(
        (r: any) => {
          this.props.store.loadConnected().then((r2: any) => {
            form.disableLoading();
            this.props.store.addAlert(new AlertModel(
              'saveAccount',
              'Sauvegardé !',
              '',
              'success',
              5
            ));
          });
        },
        (err: any) => {
          form.disableLoading();
          this.setState({
            globalPasswordError: globalPasswordError,
            strongPasswordError: strongPasswordError,
            matchingPasswordError: matchingPasswordError,
            currentPasswordError: true
          });
        });
    } else {
      this.setState({
        globalPasswordError: globalPasswordError,
        strongPasswordError: strongPasswordError,
        matchingPasswordError: matchingPasswordError,
        currentPasswordError: false
      });
    }
  }
  sendConfirmChangeEmail() {
    this.props.store.sendConfirmChangeEmail();
  }
  cancelChangeEmail() {
    this.props.store.cancelChangeEmail().then(
      (r: any) => {
        this.props.store.loadConnected().then((r2: any) => {
          this.props.store.addAlert(new AlertModel(
            'saveAccount',
            'Sauvegardé !',
            '',
            'success',
            5
          ));
        });
      });
  }
  render() {
    const form = this.props.store.mainSettings.account;
    const newEmail = this.props.store.connected.newEmail;
    const longPassword = new RegExp('(?=.{8,})');
    const upperCasePassword = new RegExp('(?=.*[A-Z])');
    const lowerCasePassword = new RegExp('(?=.*[a-z])');
    const digitPassword = new RegExp('(?=.*[0-9])');
    const specialCharacterPassword = new RegExp('([^A-Za-z0-9])');
    return (
      <div>
        {form.loading ?
          <Loader message="Chargement..." />
          :
          <div>
            <div>
          <form onSubmit={this.postFormEmail} className="ui equal width form">
            <div className="ui info message">
              {form.facebookUser ?
                <div>Vous vous êtes inscrit avec Facebook.</div>
                : <div>
                  Vous vous êtes inscrit avec un identifiant et un mot de passe.
                  <br />Votre identifiant est <b>{form.identifiant}</b>.
                </div>
              }
            </div>
            <div className={'field' + (this.state.emailError ? ' error' : '')} style={{ maxWidth: '600px' }}>
              <label>Adresse email</label>
              {newEmail ?
                <div className="ui success message">
                  <p>Un email de confirmation vous a été envoyé à <i>{newEmail}</i>.</p>
                  <p>Si vous ne voyez pas d'email dans votre boîte de réception,
                  consultez votre boîte Spam / Courrier indésirable.</p>
                  <button className="ui button" style={{ marginTop: '10px' }}
                    onClick={this.cancelChangeEmail} type="button">
                    Annuler la modification
                  </button>
                  <button className="ui button" style={{ marginTop: '10px' }}
                    onClick={this.sendConfirmChangeEmail} type="button">
                    Recevoir un nouvel email (à l'adresse <i>{newEmail}</i>).
                  </button>
                </div>
                : undefined}
              <input type="text" placeholder="Email"
                value={form.email} onChange={this.handleEmailChange} />
              <div className="ui message">
                Votre adresse email n'est pas visible des autres membres.
                Elle vous permet de recevoir vos notifications.
              </div>
              {this.state.emailError ?
                <div className="ui error message">
                  <div className="header">L'adresse email est invalide.</div>
                </div>
                : undefined
              }
              {this.state.emailAlreadyExists ?
                <div className="ui error message">
                  <div className="header">Cette adresse email est déjà utilisée</div>
                </div>
                : undefined
              }
            </div>
            <button className="ui submit primary right button" type="submit" style={{ marginTop: '25px' }}>
              Sauvegarder les modifications
            </button>
          </form>
          </div>
          <div>
          <form onSubmit={this.postFormPassword} className="ui equal width form">
          {!form.facebookUser ?
              <div>
                <div className="ui divider" />
                <h4>Mot de passe</h4>
                <div style={{ maxWidth: '600px' }}>
                  {this.state.globalPasswordError ?
                    <div className="ui error message">
                      <div className="header">Les trois champs doivent être renseignés.</div>
                    </div>
                    : (this.state.strongPasswordError ?
                        <div className="ui error message">
                          <div className="header">Le mot de passe ne remplit pas les prérequis :</div>
                          {longPassword.test(form.newPassword) ? undefined : 
                            <div>- La longueur minimale du mot de passe est de 8 caractères.</div>
                          }
                          {upperCasePassword.test(form.newPassword) ? undefined : 
                            <div>- Le mot de passe doit comporter au moins une majuscule.</div>
                          }
                          {lowerCasePassword.test(form.newPassword) ? undefined : 
                            <div>- Le mot de passe doit comporter au moins une minuscule.</div>
                          }
                          {digitPassword.test(form.newPassword) ? undefined : 
                            <div>- Le mot de passe doit comporter au moins un chiffre.</div>
                          }
                          {specialCharacterPassword.test(form.newPassword) ? undefined : 
                            <div>- Le mot de passe doit comporter au moins un caractère spécial.</div>
                          }
                      </div>
                      : (this.state.matchingPasswordError ?
                        <div className="ui error message">
                          <div className="header">Le mot de passe et sa confirmation sont différents.</div>
                        </div>
                        : this.state.currentPasswordError ?
                          <div className="ui error message">
                            <div className="header">Le mot de passe actuel est incorrect.</div>
                          </div>
                          : undefined
                      )
                    )
                  }
                  <div className={'field' +
                    (this.state.globalPasswordError || this.state.strongPasswordError
                      || this.state.matchingPasswordError || this.state.currentPasswordError ?
                      ' error' : '')} style={{ maxWidth: '600px' }}>
                    <label>Mot de passe actuel</label>
                    <input type="password" placeholder="Mot de passe actuel" style={{ maxWidth: '300px' }}
                      value={form.currentPassword} onChange={this.handleCurrentPasswordChange} />
                  </div>
                  <div className={'field' +
                    (this.state.globalPasswordError || this.state.strongPasswordError
                      || this.state.matchingPasswordError || this.state.currentPasswordError ?
                      ' error' : '')} style={{ maxWidth: '600px' }}>
                    <label>Nouveau mot de passe</label>
                    <input type="password" placeholder="Nouveau mot de passe" style={{ maxWidth: '300px' }}
                      value={form.newPassword} onChange={this.handleNewPasswordChange} />
                  </div>
                  <div className={'field' +
                    (this.state.globalPasswordError || this.state.strongPasswordError
                      || this.state.matchingPasswordError || this.state.currentPasswordError ?
                      ' error' : '')} style={{ maxWidth: '600px' }}>
                    <label>Confirmer le mot de passe</label>
                    <input type="password" placeholder="Confirmer le mot de passe" style={{ maxWidth: '300px' }}
                      value={form.confirmPassword} onChange={this.handleConfirmPasswordChange} />
                  </div>
                </div>
              </div>
              : undefined}
            <button className="ui submit primary right button" type="submit" style={{ marginTop: '25px' }}>
              Sauvegarder les modifications
            </button>
          </form>
          </div>
          </div>
        }
      </div>
    );
  }
}
