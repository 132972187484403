import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import * as superagent from 'superagent';
import Store from '../../../../stores/Store';
import TextParams from '../TextParams';
import ReadIndicator from './ReadIndicator';
import Loader from '../../../misc/Loader';
import Title from '../../../misc/Title';

interface TextStatisticsReadProps {
  store?: Store;
  params: TextParams;
  location: {
    query: {
      chapter: number,
      stat: string
    }
  };
}

declare const __CLIENT__: any;

@inject('store') @observer
export default class TextStatisticsRead extends React.Component<TextStatisticsReadProps, {}> {
  timerCompute: any;
  timerReload: any;

  static fetchData(store: Store, params: TextParams, query: { chapter: number, stat: string }) {
    const stat = query && query.stat ? query.stat : 'views';
    const chapterId = query && query.chapter ? query.chapter : undefined;
    return store.loadTextInfos(params.textId).then((d: any) => {
      const p: any[] = [];
      p.push(store.loadTextStatisticsRead(stat, chapterId));
      p.push(store.loadTextStatisticsWrite('duration'));
      return Promise.all(p);
    });
  }
  render() {
    const selectedStat = this.props.location && this.props.location.query && this.props.location.query.stat ?
      this.props.location.query.stat
      : 'views';

    const currentText = this.props.store.write.currentText;
    const statisticsRead = currentText.statisticsRead;
    const statisticsWrite = currentText.statisticsWrite;
    const chapterId = this.props.location && this.props.location.query && this.props.location.query.chapter ?
      this.props.location.query.chapter : undefined;

    const selectedChapterDropdown = chapterId ? String(chapterId) : 'text';
    let chapterDropdownList: { text: string, value: string }[] = [];
    if (statisticsWrite && statisticsWrite.length > 0) {
      chapterDropdownList = statisticsWrite.map((stat) => {
        return { text: `Chapitre ${stat.chapter.index}`, value: String(stat.chapter.id) };
      });
      chapterDropdownList.unshift({ text: 'Œuvre', value: 'text' });
    }

    return (
      <div>
        {this.props.store.write.loading || !currentText || !statisticsRead || !statisticsWrite ?
          <Loader message="Chargement..." />
          :
          <div>
            {currentText && currentText.infos ?
              <Title title={`${currentText.infos.title} - Statistiques`} />
              : undefined}
            <div className="ui two column stackable mobile reversed grid" style={{ marginTop: '-30px' }}>
              <div className="seven wide column">
                <div className="ui grid centered stackable">
                  <div className="twelve wide column">
                    {currentText && currentText.infos ?
                      <div className="ui large form">
                        <div className="inline fields">
                          <div className="field">
                            <label>Indicateur</label>
                            <Dropdown
                              selection
                              value={selectedStat}
                              onChange={(e: any, { value }) => {
                                const query = this.props.location && this.props.location.query && this.props.location.query.chapter ?
                                  { chapter: this.props.location.query.chapter, stat: value }
                                  : { stat: value };
                                browserHistory.push({
                                  pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/read`, query: query
                                });
                              }}
                              options={[
                                { text: 'Lectures', value: 'views' },
                                { text: 'J\'aime', value: 'favorites' },
                                { text: 'Commentaires', value: 'comments' },
                                { text: 'Annotations', value: 'annotations' }
                              ]}
                            />
                          </div>
                          <div className="field">
                            <label>Chapitre</label>
                            <Dropdown
                              selection
                              value={selectedChapterDropdown}
                              onChange={(e: any, { value }) => {
                                const query = value === 'text' ?
                                  { stat: selectedStat }
                                  : {
                                    chapter: value,
                                    stat: selectedStat
                                  };
                                browserHistory.push({
                                  pathname: `/text/${currentText.id}/${currentText.infos.urlText}/statistics/read`,
                                  query: query
                                });
                              }}
                              options={chapterDropdownList}
                            />
                          </div>
                        </div>
                      </div>
                      : undefined}
                  </div>
                </div>
              </div>
            </div>
            {currentText && currentText.infos ?
              <div style={{ paddingBottom: '80px' }}>
                <div className="ui section hidden divider" />
                {selectedStat === 'views' ?
                  <ReadIndicator title="Lectures" chapterId={chapterId} />
                  : selectedStat === 'favorites' ?
                    <ReadIndicator title="J'aime" chapterId={chapterId} />
                    : selectedStat === 'comments' ?
                      <ReadIndicator title="Commentaires" chapterId={chapterId} />
                      : selectedStat === 'annotations' ?
                        <ReadIndicator title="Annotations" chapterId={chapterId} />
                        : undefined}
              </div>
              : undefined}
          </div>
        }
      </div>
    );
  }
}
