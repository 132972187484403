import * as React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import Store from '../../../../../stores/Store';

interface ChapterChecksProps {
  store?: Store;
  active: boolean;
  showChecksWarning: boolean;
  loading: boolean;
  open: Function | any;
  close: Function | any;
  nextChapter: Function | any;
}

@inject('store') @observer
export default class ChapterChecks extends React.Component<ChapterChecksProps, {}> {
  constructor(props: ChapterChecksProps) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }
  @action
  handleCheck(checkId: number, answerId: number) {
    this.props.store.checkParcoursChapter(answerId).then((d: any) => {
      const check = this.props.store.write.currentText.currentChapter.checks
        .filter(c => c.id === checkId)[0];
      check.answers.filter(a => a.id === answerId)[0].checked
        = !check.answers.filter(a => a.id === answerId)[0].checked;
    });
  }
  render() {
    const chapter = this.props.store.write.currentText.currentChapter;
    const checks = chapter.checks;
    const notChecks = checks.filter(c => c.answers.filter(a => a.checked === false).length > 0).length > 0;
    const allChecks = checks.map(check => {
      const values = check.answers.map(answer => {
        return (
          <div key={`check_answer_${answer.id}`} className="field">
            <Checkbox
              checked={answer.checked}
              onClick={() => this.handleCheck(check.id, answer.id)}
              label={answer.label}
            />
          </div>
        );
      });
      return (
        <div key={`check_${check.id}`} className="ui form">
          <div className="grouped fields">
            <label>{check.label}</label>
            {values}
          </div>
        </div>
      );
    });
    return (
      <div>
        <Modal onClose={this.props.close} open={this.props.active} size="small">
          <div className="content">
            {allChecks}
            {notChecks && this.props.showChecksWarning ?
              <div className="ui error message">
                Vous devez tout cocher pour pouvoir accéder au chapitre suivant !
              </div>
              : undefined}
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.props.close}>Fermer</button>
            {chapter.lastChapter ?
              !notChecks ?
              <button className={'ui green  ' + (this.props.loading ? ' loading' : '') + ' button'} onClick={this.props.nextChapter}>Passer à la suite</button>
            : 
              <button className="ui disabled button" disabled >Passer à la suite</button>
              :
              undefined }
          </div>
        </Modal>
      </div >
    );
  }
}

