const steps = [
  {
    title: 'Barre supérieure',
    text: '<p>La barre supérieure de l\'éditeur de texte contient les informations liées à votre œuvre.</p>',
    selector: '.text-bar',
    isFixed: true,
    position: 'bottom'
  },
  {
    title: 'Titre de l\'œuvre',
    text: '<p>Votre chapitre fait partie d\'une œuvre.<br/>En cliquant sur ce lien, vous pouvez vous rendre sur sa page de présentation. Vous pourrez y modifier sa couverture, sa présentation ou même ses règles d\'accessibilité.</p>',
    selector: '.text-title',
    isFixed: true,
    position: 'bottom'
  },
  {
    title: 'Statut de l\'œuvre',
    text: '<p>Cet indicateur vous rappelle si votre œuvre est publiée ou non-publiée.</p><p><u>Pour information</u><br/>Bien entendu, votre pouvez travailler sur des chapitres non-publiés même si votre œuvre est publiée. Cela vous permet d\'écrire à votre rythme un roman et de le publier un chapitre après l\'autre, par exemple.</p> ',
    selector: '.text-status',
    isFixed: true,
    position: 'bottom'
  },
  {
    title: 'Structure de l\'œuvre',
    text: '<p>Vous pourrez directement lancer un nouveau chapitre dans ce menu ou même réorganiser le plan de votre œuvre.</p>',
    selector: '.text-structure',
    isFixed: true,
    position: 'bottom'
  },
  {
    title: 'Sauvegarder et quitter',
    text: '<p>En cliquant sur ce bouton, vous sauvegarderez votre texte et vous rendrez sur la page de présentation de votre œuvre.</p>',
    selector: '.save-and-quit',
    isFixed: true,
    position: 'bottom'
  },
  {
    title: 'Titre du chapitre',
    text: '<p>Cet espace est dédié au titre de votre chapitre. Vous pouvez ainsi le modifier quand vous le voulez.</p>',
    selector: '.chapter-title',
    position: 'bottom'
  },
  {
    title: 'Corps de texte',
    text: '<p>Écrivez votre texte dans cet espace. Il vous suffit de surligner une partie de votre texte pour changer son style :<img src="https://scribay.s3.amazonaws.com/assets/screen_editor.png" style="max-width: 90%" /></p>',
    selector: '.write-editor',
    position: 'bottom'
  },
  {
    title: 'Barre inférieure',
    text: '<p>La barre inférieure de l\'éditeur de texte contient les options et informations liées au chapitre.</p>',
    selector: '.chapter-bar',
    isFixed: true,
    position: 'top'
  },
  {
    title: 'Avancement du texte',
    text: '<p>En cliquant sur ce bouton, vous pourrez changer le statut de votre chapitre... et les retours que pourront vous faire vos lecteurs !</p>',
    selector: '.chapter-status',
    isFixed: true,
    position: 'top'
  },
  {
    title: 'Compteur de mots',
    text: '<p>Ce compteur vous permet de savoir combien de mots vous avez tapé dans ce chapitre... et le temps de lecture moyen de celui-ci, en minutes.</p><p><u>Conseil</u><br/>Pour améliorer la lisibilité d\'une œuvre, écrivez des chapitres à la durée de lecture inférieure à 6 minutes.<br/>',
    selector: '#chapter-time',
    isFixed: true,
    position: 'top'
  },
  {
    title: 'Suppression du chapitre',
    text: '<p>Ce bouton permet de supprimer votre chapitre.</p><p><u>Information rassurante</u><br/>Nous demandons toujours une validation avant une suppression.N\'ayez donc pas peur de cliquer dessus sans faire exprès.</p>',
    selector: '.chapter-remove',
    isFixed: true,
    position: 'top'
  },
  {
    title: 'Sauvegarde',
    text: '<p>Votre texte est sauvegardé automatiquement toutes les 30 secondes. Vous pouvez cliquer sur ce bouton si ce délai est trop long pour vous !</p>',
    selector: '.chapter-save',
    isFixed: true,
    position: 'top'
  }
];

export default steps;