import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../../stores/Store';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import { FormattedMessage } from 'react-intl'

interface TextDefiProps {
  store?: Store;
}

interface TextDefiState {
  modalActive: boolean;
}

@inject('store') @observer
export default class TextDefi extends React.Component<TextDefiProps, TextDefiState> {
  constructor(props: TextDefiProps) {
    super(props);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = {
      modalActive: false
    };
  }
  showModal() {
    this.setState({
      modalActive: true
    });
  }
  hideModal() {
    this.setState({
      modalActive: false
    });
  }
  render() {
    const userId = this.props.store.connected.user.id;
    const text = this.props.store.write.currentText;
    const defi = text.infos.defi;
    return (
      <div
        className="ui item basic button"
        onClick={this.showModal}
        title="Défi associé"
      >
        <Modal
          open={this.state.modalActive}
          size="small"
          onClose={() => { }}
        >
          <div className="header">Défi associé</div>
          <div className="content">
            <div className="ui items">
              <div className="item">
                <div className="content">
                  <div className="header">
                    <a href={`/defis/defi/${defi.id}/${defi.urlTitle}`} target="_blank" rel="noopener noreferrer">
                      {defi.title}
                    </a>
                  </div>
                  {userId !== defi.author.id ?
                    <div className="meta">
                      Lancé par&nbsp;
                      <a href={`/author/${defi.author.id}/${defi.author.urlIdentity}`} target="_blank" rel="noopener noreferrer">
                        {defi.author.identity}
                      </a>
                    </div>
                    : undefined}
                  {defi.defiType ?
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: defi.description.split('<<<<<>>>>>')[1] }} />
                    : <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: defi.description }} />
                  }
                  {defi.genre ?
                    <div className="extra">
                      <div className="ui label">
                        Genre imposé :&nbsp;
                          <FormattedMessage
                          id={`genre.${defi.genre}`}
                          defaultMessage="Genre"
                        />
                      </div>
                    </div>
                    : undefined}
                </div>
              </div>
              {defi.author.id === userId ?
                <div className="ui info message">
                  Cette œuvre est l'interprétation de ce défi que vous avez lancé.
                    </div>
                :
                <div>
                  {defi.active ?
                    <div className="ui warning message">
                      Vous avez jusqu'au {defi.deadline} à 23h59 pour répondre à ce défi.
                    </div>
                    : <div className="ui success message">
                      Vous avez réussi ce défi !
                      </div>
                  }
                </div>
              }
            </div>
          </div>
          <div className="actions">
            <button className="ui cancel button" onClick={this.hideModal}>Fermer</button>
          </div>
        </Modal>
        Défi associé
      </div>
    );
  }
}
