import * as React from 'react';
import Title from '../misc/Title';

interface ModerationState {
  showEmails: boolean;
}

export default class Moderation extends React.Component<{}, ModerationState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const adminEmail = this.state.showEmails ? 'admin@atelierdesauteurs.com' : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
      <div className="ui text read container" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
        <Title title="Modération " />
        <h1>Modération</h1>
        <div className="ui basic segment">
          <h3>Comment faire appel à la modération ?</h3>
          <p>Un bouton « Signaler » se trouve sur chaque page de présentation d’une œuvre.</p>
          <p>Vous pouvez aussi nous envoyer un email à {adminEmail}.</p>

          <h3>Quand faire appel à la modération ?</h3>
          <p>Si un comportement ou une œuvre entrent manifestement en contradiction avec
                les CGU ou la loi française, n’hésitez surtout pas à nous contacter.</p>
          <p>Si vous avez des doutes, nous vous invitons à dialoguer avec le membre concerné
                avant de contacter la modération du site.</p>

          <h3>Quelles sont les règles de modération ?</h3>
          <p>Nos CGU sont claires à ce sujet : un contenu ou un compte de membre se posant 
                en contradiction des Conditions Générales d’Utilisation ou enfreignant la loi française 
                seront supprimés.</p>
          <p>Au-delà de ces règles basiques, nous souhaitons rappeler que l'Atelier des auteurs est avant 
                tout une plateforme destinée au travail, à l’apprentissage et 
                à l’amélioration de textes littéraires. En conséquence, la politique de modération de la plateforme reflète cet objectif.</p>
          <p>Pour préserver un climat serein dans les échanges entre les membres, nous 
                ne nous limiterons pas à appliquer uniquement la loi. Nous n’hésiterons pas à modérer tous les 
                propos, les contenus ou à supprimer des comptes de membres dont l’unique objet sera 
                la polémique, l’insulte, la provocation stérile, la propagande ou le prosélytisme.</p>
          <p>Sans qu’une telle liste soit limitative, les contenus suivants seront soumis à modération :</p>
          <ul>
            <li>Les textes érotiques mettant en scène des personnages en-dessous de 18 ans. Par extension, nous modérerons les histoires contournant ou jouant avec cette règle, en utilisant des personnages majeurs mais lycéens par exemple.</li>

            <li>Les commentaires, messages, textes insultants envers la communauté.</li>

            <li>Les contenus dénigrant la communauté, la plateforme ou ses partenaires.</li>

            <li>Les contenus destinés à débattre des évolutions de la plateforme.</li>

            <li>Les défis littéraires dont la thématique est le suicide.</li>

            <li>La publicité non-autorisée par l’administration du site.</li>

            <li>Le spam et les contenus dupliqués.</li>

            <li>Les contenus destinés à contourner le blocage d’un membre par un autre membre. Par exemple, si le membre A vient de bloquer le membre B ; le membre B n’a pas à publier un texte destiné à attirer l’attention ou citer le membre A.</li>

            <li>Les contenus publics destinés à réaliser une remontée d’anomalie (« bugs ») ou un problème communautaire. La messagerie privée des administrateurs est ouverte pour cela.</li>

            <li>Les contenus destinés à discuter des actions de modération.</li>
          </ul>

          <p>Le compte d’un membre peut également être suspendu ou supprimé à la suite d’une ou plusieurs actions de modération.</p>

          <h3>Mon texte pourrait choquer les âmes sensibles pour des raisons morales, politiques,
                religieuses… que dois-je faire ?</h3>
          <p>Nous vous invitons à faire preuve de pédagogie et à être prêt à échanger avec les
                membres le souhaitant. Vous êtes également incité (comme tous les membres) à rester
                courtois et à l’écoute des remarques que l’on peut vous faire.</p>
          <p>En cas de signalement de l’une de vos œuvres, votre attitude et celle du membre
                signalant votre texte seront prises en compte dans la réaction de l’administration du site.</p>
          <p>Ajouter une présentation à votre œuvre destinée à parler de votre travail sur ce texte,
                de vos intentions ou du but recherché peut être très positif. Cela peut permettre à
                vos lecteurs de prendre du recul sur votre texte et d’échanger avec vous même s’ils sont choqués.</p>
          <p>Vous êtes également incité à lire la page sur les Contenus Sensibles.</p>

          <h3>Et si l’essence de mon texte est de choquer et provoquer mon public ?</h3>
          <p>L'Atelier des auteurs est destiné au développement des auteurs et à l’amélioration des œuvres avant
                d’être un espace de libre publication.</p>
          <p>Quel que soit le contenu de votre œuvre, vous pouvez travailler sur celle-ci en
                échangeant avec les autres membres sur le plan technique, sur l’effet provoqué ou même
                les procédés à utiliser. Mais si votre unique but est de choquer les autres membres de
                l'Atelier des auteurs, nous vous invitons à trouver un espace de publication qui sera plus adapté à vos besoins.</p>
          <p>Votre texte peut être provoquant, sans pour autant que vous vous comportiez comme un
                « troll » ou un provocateur. Votre œuvre peut comporter des éléments grossiers et violents
                sans que vous soyez vous-même grossier et violent.</p>
          <p>L’attitude et les intentions publiques de chaque auteur, comme de chaque commentateur,
                entrera toujours en compte lors d’une action de modération.</p>

          <h3>Existe-il la possibilité de discuter d’une action de modération ?</h3>
          <p>Dans toutes les situations où nous avons un doute, nous discuterons avec le(s)
                membre(s) ayant demandé une action de modération et le(s) membre(s) concerné(s) par celle-ci.</p>

          <p>Voici une liste non-exhaustive de ce qui nous enlève tout doute :</p>
          <ul>
            <li>Une infraction explicite aux CGU ou à la loi française.</li>
            <li>Un comportement violent, insultant ou provocateur sur la plateforme, quel
                    que soit le contenu des œuvres publiées.</li>
            <li>Un refus de dialoguer et d’échanger avec des membres posant des questions sur
                    les intentions de l’auteur.</li>
            <li>Une volonté explicite de jouer avec les limites de la modération ou avec la
                    sensibilité des membres.</li>
          </ul>

          <p>Dans ces conditions, une action de modération sera engagée sans discussion préalable.</p>

          <h3>Je souhaite publier un défi / un texte / un message et j’ai des doutes sur son 
                respect des règles de modération. Que faire ?</h3>

            <p>Si vous avez le moindre doute, nous vous invitons à ne pas publier ce défi, ce texte ou ce message.</p>

            <p>Nous invitons le plus souvent les membres à se poser plusieurs questions :</p>

            <ul>
                  <li>Est-ce que je me comporterais de cette façon si mon identité publique était révélée ?</li>

                  <li>Mon action serait-elle la même si je me trouvais physiquement dans un atelier d’écriture classique ?</li>

                  <li>Suis-je prêt à prendre toute responsabilité juridique de mes contenus ?</li>
            </ul>
        </div>
      </div>
      </div>
    );
  }
}
